import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { ChooseSubscriptionComponent } from 'src/app/modules/subscription/components/choose-subscription/choose-subscription.component';
import { CustomerAgreementComponent } from 'src/app/modules/shared/components/customer-agreement/customer-agreement.component';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

@Component({
  selector: 'ark-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  password = true;
  credentials = false;
  isCheck = false;
  errorMessage = null;

  loginForm: FormGroup;

  dialogConfig = new MatDialogConfig;
  dialogRef: any;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private _router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.clearStorage();
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
    this.getRememberDetail();
  }

  login() {
    let data = this.loginForm.value;

    if(this.credentials) {
      localStorage.setItem('Remember', 'true');
      localStorage.setItem('Password',this.loginForm.controls['password'].value);
      localStorage.setItem('Email',this.loginForm.controls['email'].value);
    }

    this.auth.login(data).subscribe(response => {
      if(response) {
        this.dialogConfig.disableClose = true;
        this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
        let dialogRef = this.dialog.open(CustomerAgreementComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
          if(trigger) {
            this.auth.setAuthUser(response);
            this.auth.setAuthToken(response);
            if(response.last_login == null && response.role_id != 'Owner/Dentist') {
              this._router.navigate(["/update-password"]);
            } else {
              this._router.navigate(["/dashboard"]);
            }
          }
        });
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Sorry!',
        message: err.error.message,
        button: 'Okay',
        event: this.close
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      this.errorMessage = err.error.errors;
      this.triggerErrors(err.error.errors);
    }));
  }
  

  toggle(e) {
    if(e.checked) {
      this.credentials = true;
      localStorage.setItem('Remember', e.checked);
      localStorage.setItem('Password',this.loginForm.controls['password'].value);
      localStorage.setItem('Email',this.loginForm.controls['email'].value);
    } else {
      this.credentials = false;
      localStorage.removeItem('Remember');
      localStorage.removeItem('Password');
      localStorage.removeItem('Email');
    }
  }

  triggerErrors(err){
    Object.keys(this.loginForm.controls).forEach(key => {
      if (err[key]) {
        this.loginForm.controls[key].setErrors({'invalid': true});
        this.loginForm.controls[key].markAsTouched();
        this.loginForm.controls[key].markAsDirty();
      }
    });
  }

  getRememberDetail() {
    let isRemember = localStorage.getItem('Remember');
    let email = localStorage.getItem('Email');
    let password = localStorage.getItem('Password');
    if(isRemember) {
      this.credentials = true;
      this.loginForm.controls['email'].setValue(email);
      this.loginForm.controls['password'].setValue(password);
      this.isCheck = true;
    } 
  }

  clearStorage(){
    localStorage.removeItem('authUser');
    localStorage.removeItem('isOwner');
    localStorage.removeItem('token');
  }

  subscribe() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogRef = this.dialog.open(ChooseSubscriptionComponent, this.dialogConfig);
  }
  close() {
    this.dialogRef.close();
  }
}