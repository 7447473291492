import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from '../../services/loader/loader.service';

@Component({
  selector: 'ark-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  isLoading: Subject<boolean> = this.loader.isLoading;

  constructor(
    private loader: LoaderService
  ) { }

  ngOnInit() {
  }

}
