import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ark-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit {

  @Output() toggle = new EventEmitter<string>();

  customCollapsedHeight: string = '40px';
  customExpandedHeight: string = '40px';

  constructor() { }

  ngOnInit() {
  }

  onExpand() {
    this.toggle.emit('expanded');
  }

  onCollapse() {
    this.toggle.emit('collapsed')
  }

}
