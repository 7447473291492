import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material';
import { UserService } from '../../services/user/user.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';

@Component({
  selector: 'ark-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent implements OnInit {

  errorMessage = '';
  success = false;
  loading: boolean = false
  formGroup: FormGroup;
  isChecked = false;
  label = '';

  dialogConfig = new MatDialogConfig();

  role = [
    { id : '2', name: 'Dentist' },
    { id : '3', name: 'Assistant' }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    public dialogRef: MatDialogRef<UserAddComponent>, 
    public dialog: MatDialog,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      role_id: ['', [Validators.required]]
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.label = 'Next'
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  onCheck(e) {
    this.isChecked = e.checked;
    this.label = e.checked ? 'Save' : 'Next';
  }

  addUser() {
    if(this.isChecked) {
      this.userService.addUserCheck(this.auth.getAuthUser().user_clinic.id, this.auth.getAuthUser().id, this.formGroup.value.role_id).subscribe(res => {
        if(res) {
          const data = {
            first_name: this.formGroup.value.first_name,
            last_name: this.formGroup.value.last_name,
            email: this.formGroup.value.email,
            role_id: this.formGroup.value.role_id,
            user_clinic_id: this.auth.getAuthUser().user_clinic.id
          }
          this.userService.addUser(data).subscribe(res => {
            if(res) {
              this.success = true;
              this.dialogRef.close(true);
              this.dialogConfig.data = {
                title: 'Success!',
                message: 'New user has been successfully added.',
                button: 'Okay',
                event: this.closeOnClick
              };
              let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
            }
          },(err => {
            this.errorMessage = err.error.errors;
            this.triggerErrors(err.error.errors);
          }));
        } 
      },(err => {
        this.dialogConfig.data = {
          title: 'Sorry!',
          message: err.error.message,
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }));
    } else {
      this.dialogConfig.data = {
        title: 'Sorry!',
        message: 'Please indicate that you have read and accept the Additional Fee.',
        button: 'Okay',
        event: this.closeOnClick
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }
}
