import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ark-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

  @Input() label: string;
  @Input() type: string;
  @Input() isDisabled: boolean;
  @Input() btn_type: string = 'button';
  @Output() onClick = new EventEmitter<any>();

  constructor() { }

  onClickButton(event) {
    this.onClick.emit(event);
  }

  ngOnInit() {
  }

}
