import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { PatientsDirectoryService } from '../../services/patients-directory/patients-directory.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';

@Component({
  selector: 'ark-tooth-chart',
  templateUrl: './tooth-chart.component.html',
  styleUrls: ['./tooth-chart.component.scss']
})
export class ToothChartComponent implements OnInit, OnChanges {

  @Input() newChart: any;
  success: boolean = false;
  isNoChart: boolean = true;
  date: any;
  data: any;
  dateOption = [];
  filter = [];
  toggle = 'expanded';
  clinic = null;
  patientId = '';

  constructor(
    private patientsDirectory: PatientsDirectoryService,
    private route: ActivatedRoute,
    private auth: AuthService
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.route.params.subscribe(param => {
      this.patientId = param.id
    });
    this.getPatientCharts();
  }

  ngOnInit() {
    
  }

  ngOnChanges() {
    if(this.newChart) {
      this.setFilterValues(this.newChart);
    }
  }

  getNewChart(data) {
    this.setFilterValues(data);
  }
  
  getPatientCharts() {
    this.patientsDirectory.getPatientCharts(this.patientId, this.clinic).subscribe(response => {
      this.setFilterValues(response);
    });
  }

  setFilterValues(response) {
    if(response) {
      this.dateOption = [];
      response.forEach(element => {
        this.dateOption.push({
          name: Object.values(element.date_created)[0],
          value: Object.keys(element.date_created)[0],
        });
        this.filter = this.dateOption[0].name;
        this.date = this.dateOption[0].value;
      });
    }

    if(this.filter.length > 0) {
      this.isNoChart = false;
    }
  } 

  getChartHistory(option) {
    this.filter = option.name;
    this.date = option.value;
  }

  onToggle(toggle) {
    setTimeout(() => {
      this.toggle = toggle;
    });
  }
}
