import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatAutocompleteTrigger, MatDialogConfig, MatDialog } from '@angular/material';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard/dashboard.service';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { DentalFormsService } from 'src/app/modules/dental-office/services/dental-forms/dental-forms.service';
import { UserService } from 'src/app/modules/account-settings/services/user/user.service';
import { startWith, map } from 'rxjs/operators';
import * as moment from 'moment';
import { keypressValidateNumber } from 'src/app/common/helper';
import { AddToothNumberComponent } from './add-tooth-number/add-tooth-number.component';
import { ToothDetailsComponent } from './tooth-details/tooth-details.component';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { ESignatureComponent } from 'src/app/modules/shared/components/e-signature/e-signature.component';
import { ConfirmDeletionComponent } from 'src/app/modules/shared/components/confirm-deletion/confirm-deletion.component';

export interface Dentist {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
}

export interface Patient {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
}

@Component({
  selector: 'ark-crown-authorization',
  templateUrl: './crown-authorization.component.html',
  styleUrls: ['./crown-authorization.component.scss']
})
export class CrownAuthorizationComponent implements OnInit {

  formGroup: FormGroup;
  errorMessage = null;
  clinic = null;
  clinicName = null;
  patientId = '';
  dentistId = '';
  doctorId = '';
  patientName = '';
  dentistName = '';
  doctorName = '';
  patientControl = new FormControl();
  dentistControl = new FormControl();
  doctorControl = new FormControl();
  patients: Patient[] = [];
  dentists: Dentist[] = [];
  doctors: Dentist[] = [];
  filteredPatients: Observable<Patient[]>;
  filteredDentists: Observable<Dentist[]>;
  filteredDoctors: Observable<Dentist[]>;
  @ViewChild('patient') patient: MatAutocompleteTrigger;
  @ViewChild('dentist') dentist: MatAutocompleteTrigger;
  @ViewChild('doctor') doctor: MatAutocompleteTrigger;

  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  tooth_details = [];

  facialTypes = [
    { id : '1', name: 'Square' },
    { id : '2', name: 'Triangular' },
    { id : '3', name: 'Rectangular' },
    { id : '4', name: 'Ovoid' },
  ];
  genders = [
    { id : '1', name: 'Male' },
    { id : '2', name: 'Female' }
  ];
  receiverSignature;
  dentistSignature;
  hasReceiverSignature = false;
  hasDentistSignature = false;
  signature1 = 'Receiver\'s Signature (tap icon to add)';
  signature2 = 'Dentist\'s Signature (tap icon to add)';

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private dashboard: DashboardService,
    private patientDirectory: PatientsDirectoryService,
    private forms: DentalFormsService,
    public dialog: MatDialog,
    private userService: UserService,
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic;
    this.dashboard.getPatientList(this.clinic.id, this.clinic.id).subscribe(res => {
      res.data.forEach(element => {
        let obj: Patient = {
          id: element.id, 
          firstName: element.first_name, 
          middleName: element.middle_name ? element.middle_name : '', 
          lastName: element.last_name
        };
        this.patients.push(obj);
      });
    });
    this.dashboard.getDentistList(this.clinic.id).subscribe(res => {
      res.forEach(element => {
        let obj: Dentist = {
          id: element.id, 
          firstName: element.first_name,
          lastName: element.last_name,
          middleName: element.middle_name ? element.middle_name : '', 
        };
        this.dentists.push(obj);
        this.doctors.push(obj);
      });
    });
    this.formGroup = this.formBuilder.group({
      lab_name: ['', [Validators.required]],
      form_date: ['', [Validators.required]],
      telephone_number: [''],
      mobile_number: [''],
      clinic_address: ['', [Validators.required]],
      doctor_name: [''],
      doctor_id: new FormControl('', [Validators.required]),
      patient_name: [''],
      patient_id: new FormControl('', [Validators.required]),
      age: [''],
      gender: [''],
      date_regarded: ['', [Validators.required]],
      special_notes: [''],
      facial_type: ['', [Validators.required]],
      dentist_name: [''],
      dentist_id: new FormControl(''),
      dentist_signature: [''],
      date_signed: [''],
      received_by: [''],
      received_date: [''],
      receivers_signature: [''],
      prc_license_no: [''],
    });
  }

  ngOnInit() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.filteredPatients = this.formGroup.get('patient_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterPatient(name) : this.patients.slice()),
      );
    this.filteredDentists = this.formGroup.get('dentist_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterDentist(name) : this.dentists.slice())
      );
    this.filteredDoctors = this.formGroup.get('doctor_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterDoctor(name) : this.doctors.slice())
      );
  }

  getPatient(event) {
    this.patientId = event.option.value.id;
    this.patientName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.patientDirectory.getPatientInfo(this.clinic.id, event.option.value.id, this.clinic.id).subscribe(res => {
      if(res) {
        let age = moment().diff(res.data[0].patient_private_information.birthdate, 'years');
        let gender = '';
        if(res['data'][0].patient_private_information.gender == '1') {
          gender = 'Male'
        } else if(res['data'][0].patient_private_information.gender == '2') {
          gender = 'Female'
        } else {
          gender = '';
        }
        this.formGroup.get('gender').setValue(gender);
        this.formGroup.get('age').setValue(age);
      }
    });
    this.formGroup.get('patient_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName
    });
  }

  getDentist(event) {
    this.dentistId = event.option.value.id;
    this.dentistName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.dashboard.getDentistInfo(event.option.value.id, this.clinic.id).subscribe(res => {
      if(res) {
        this.formGroup.get('prc_license_no').setValue(res[0].user.prc_license_no ? res[0].user.prc_license_no : '');
      }
    });
    this.formGroup.get('dentist_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
  }

  getDoctor(event) {
    this.doctorId = event.option.value.id;
    this.doctorName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.userService.getClinic(this.clinic.id, this.clinic.id).subscribe(res => {
      if(res) {
        this.formGroup.get('clinic_address').setValue(res['data'].address ? res['data'].address : '');
        this.formGroup.get('mobile_number').setValue(res['data'].mobile_number ? res['data'].mobile_number : '');
        this.formGroup.get('telephone_number').setValue(res['data'].contact_number ? res['data'].contact_number : '');
        this.formGroup.get('form_date').setValue(new Date());
      }
    });
    this.formGroup.get('doctor_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
    this.formGroup.get('dentist_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
    this.dentistId = event.option.value.id;
    this.dentistName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.dashboard.getDentistInfo(event.option.value.id, this.clinic.id).subscribe(res => {
      if(res) {
        this.formGroup.get('prc_license_no').setValue(res[0].user.prc_license_no ? res[0].user.prc_license_no : '');
      }
    });
  }

  private _filterPatient(name: string): Patient[] {
    const filterValue = name.toLowerCase();
    return this.patients.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1 || option.middleName.toLowerCase().indexOf(filterValue) > -1);
  }

  private _filterDentist(name: string): Dentist[] {
    const filterValue = name.toLowerCase();
    return this.dentists.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1);
  }

  private _filterDoctor(name: string): Dentist[] {
    const filterValue = name.toLowerCase();
    return this.doctors.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1);
  }

  displayDentist(dentist?: any) : string | undefined {
    return dentist ? 'Dr. ' + dentist.firstName + (dentist.middleName ? ' ' + dentist.middleName + ' ' : ' ') + dentist.lastName : undefined;
  }

  displayDoctor(doctor?: any) : string | undefined {
    return doctor ? 'Dr. ' + doctor.firstName + (doctor.middleName ? ' ' + doctor.middleName + ' ' : ' ') + doctor.lastName : undefined;
  }

  displayPatient(patient?: any) : string | undefined {
    return patient ? patient.lastName + ', ' + patient.firstName + (patient.middleName ? ' ' + patient.middleName : ' ') : undefined;
  }

  onFocusPatient() {
    this.patient._onChange("");
    this.patient.openPanel();
  }

  onFocusDentist() {
    this.dentist._onChange(""); 
    this.dentist.openPanel();
  }

  onFocusDoctor() {
    this.doctor._onChange(""); 
    this.doctor.openPanel();
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }

  getReceiverSignature() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogConfig.data = {
      title: 'Receiver\'s Signature'
    }
    this.dialogRef = this.dialog.open(ESignatureComponent, this.dialogConfig).afterClosed().subscribe(res => {
      if(res) {
        this.hasReceiverSignature = true;
        this.receiverSignature = res;
        this.signature1 = 'Receiver\'s Signature';
        this.formGroup.get('received_date').setValue(new Date());
      }
    });
  }

  getDentistSignature() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogConfig.data = {
      title: 'Dentist\'s Signature'
    }
    this.dialogRef = this.dialog.open(ESignatureComponent, this.dialogConfig).afterClosed().subscribe(res => {
      if(res) {
        this.hasDentistSignature = true;
        this.dentistSignature = res;
        this.signature2 = 'Dentist\'s Signature';
        this.formGroup.get('date_signed').setValue(new Date());
      }
    });
  }

  addToothNumber() {
    let char = '';
    this.dialogRef = this.dialog.open(AddToothNumberComponent, this.dialogConfig).afterClosed().subscribe(res => {
      if(res) {
        this.dialogConfig.data = {
          tooth_no: res
        }
        this.dialogConfig.disableClose = true;
        this.dialogConfig.autoFocus = true;
        this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
        this.dialogRef = this.dialog.open(ToothDetailsComponent, this.dialogConfig).afterClosed().subscribe(res => {
          if(res) {
            this.tooth_details.push(res[0]);
          }
        });
      }
    });
  }

  editToothCrown(obj, index) {
    this.dialogConfig.data = {
      data: obj,
      index: index
    }
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogRef = this.dialog.open(ToothDetailsComponent, this.dialogConfig).afterClosed().subscribe(res => {
      if(res) {
        this.tooth_details[index] = res[0];
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Tooth detail has been successfully updated.',
          button: 'Okay',
          event: this.close
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    });
  }

  deleteToothCrown(index) {
    this.dialogConfig.data = {
      subject: 'delete this tooth detail'
    };
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    let dialogRef = this.dialog.open(ConfirmDeletionComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
      if(trigger) {
        this.tooth_details.splice(index, 1);
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Tooth detail has been successfully deleted.',
          button: 'Okay',
          event: this.close
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  download() {
    const form_date = this.formGroup.value.form_date ? moment(this.formGroup.value.form_date).format('MM/DD/YYYY') : '';
    const date_regarded = this.formGroup.value.date_regarded ? moment(this.formGroup.value.date_regarded).format('MM/DD/YYYY') : '';
    const received_date = this.formGroup.value.received_date ? moment(this.formGroup.value.received_date).format('MM/DD/YYYY') : '';
    const date_signed = this.formGroup.value.date_signed ? moment(this.formGroup.value.date_signed).format('MM/DD/YYYY') : '';
    const gender = this.formGroup.value.gender == 1 ? 'Male' : 'Female';
    const FORM = {
      user_clinic_id: this.clinic.id,
      lab_name: this.formGroup.value.lab_name,
      form_date: form_date,
      telephone_number: this.formGroup.value.telephone_number,
      mobile_number: this.formGroup.value.mobile_number,
      clinic_address: this.formGroup.value.clinic_address,
      doctor_name: this.doctorName,
      patient_id: this.patientId,
      patient_name: this.patientName,
      age: this.formGroup.value.age,
      gender: gender,
      date_regarded: date_regarded,
      special_notes: this.formGroup.value.special_notes,
      facial_type: this.formGroup.value.facial_type,
      dentist_name: this.dentistName,
      dentist_signature: this.dentistSignature,
      date_signed: date_signed,
      received_by: this.formGroup.value.received_by,
      received_date: received_date,
      receivers_signature: this.receiverSignature,
      prc_license_no: this.formGroup.value.prc_license_no, 
      tooth_details: this.tooth_details
    };
    this.forms.printAndDownloadCrownWorkAuth(FORM).subscribe(res => {
      if(res) {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(res);
        a.download = moment().format("YYYYMMDD") + '-WORK AUTHORIZATION CROWN.pdf';
        document.body.appendChild(a);
        a.click();
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Exporting of PDF failed. Please fill-in all the required fields.',
        button: 'Okay',
        event: this.close
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      const FACIAL_TYPE_REQUIRED = this.formGroup.value.facial_type == '' ? 'The facial type field is required.' : '';
      const CLINIC_ADDRESS_REQUIRED = this.formGroup.value.clinic_address == '' ? 'The clinic address field is required.' : '';
      const LAB_NAME_REQUIRED = this.formGroup.value.lab_name == '' ? 'The lab name field is required.' : '';
      const FORM_DATE_REQUIRED = this.formGroup.value.form_date == '' ? 'The date field field is required.' : '';
      const DATE_REGARDED_REQUIRED = this.formGroup.value.date_regarded == '' ? 'The date field field is required.' : '';
      const DOCTOR_REQUIRED = this.formGroup.get('doctor_id').value == '' ? 'The dentist name field is required.' : '';
      const PATIENT_REQUIRED = this.formGroup.get('patient_id').value == '' ? 'The patient name field is required.' : '';
      const DOCTOR_INVALID = typeof this.formGroup.get('doctor_id').value == 'string' ? 'The dentist name is invalid.' : '';
      const PATIENT_INVALID = typeof this.formGroup.get('patient_id').value == 'string' ? 'The patient name is invalid.' : '';
      const DENTIST_INVALID = typeof this.formGroup.get('dentist_id').value == 'string' && this.formGroup.get('dentist_id').value !== '' ? 'The dentist name is invalid.' : '';
      this.errorMessage = {
        facial_type: FACIAL_TYPE_REQUIRED,
        clinic_address: CLINIC_ADDRESS_REQUIRED,
        lab_name: LAB_NAME_REQUIRED,
        form_date: FORM_DATE_REQUIRED,
        date_regarded: DATE_REGARDED_REQUIRED,
        doctor_id: DOCTOR_REQUIRED == '' ? DOCTOR_INVALID : DOCTOR_REQUIRED,
        patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
        dentist_id: DENTIST_INVALID,
      };
      this.triggerErrors({
        facial_type: FACIAL_TYPE_REQUIRED,
        clinic_address: CLINIC_ADDRESS_REQUIRED,
        lab_name: LAB_NAME_REQUIRED,
        form_date: FORM_DATE_REQUIRED,
        date_regarded: DATE_REGARDED_REQUIRED,
        doctor_id: DOCTOR_REQUIRED == '' ? DOCTOR_INVALID : DOCTOR_REQUIRED,
        patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
        dentist_id: DENTIST_INVALID,
      });
    }));
  }

  print() {
    const form_date = this.formGroup.value.form_date ? moment(this.formGroup.value.form_date).format('MM/DD/YYYY') : '';
    const date_regarded = this.formGroup.value.date_regarded ? moment(this.formGroup.value.date_regarded).format('MM/DD/YYYY') : '';
    const received_date = this.formGroup.value.received_date ? moment(this.formGroup.value.received_date).format('MM/DD/YYYY') : '';
    const date_signed = this.formGroup.value.date_signed ? moment(this.formGroup.value.date_signed).format('MM/DD/YYYY') : '';
    const gender = this.formGroup.value.gender == 1 ? 'Male' : 'Female';
    const FORM = {
      user_clinic_id: this.clinic.id,
      lab_name: this.formGroup.value.lab_name,
      form_date: form_date,
      telephone_number: this.formGroup.value.telephone_number,
      mobile_number: this.formGroup.value.mobile_number,
      clinic_address: this.formGroup.value.clinic_address,
      doctor_name: this.doctorName,
      patient_id: this.patientId,
      patient_name: this.patientName,
      age: this.formGroup.value.age,
      gender: gender,
      date_regarded: date_regarded,
      special_notes: this.formGroup.value.special_notes,
      facial_type: this.formGroup.value.facial_type,
      dentist_name: this.dentistName,
      dentist_signature: this.dentistSignature,
      date_signed: date_signed,
      received_by: this.formGroup.value.received_by,
      received_date: received_date,
      receivers_signature: this.receiverSignature,
      prc_license_no: this.formGroup.value.prc_license_no, 
      tooth_details: this.tooth_details
    };
    this.forms.printAndDownloadCrownWorkAuth(FORM).subscribe(res => {
      if(res) {
        const url = URL.createObjectURL(res);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = url;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Print Failed. Please fill-in all the required fields.',
        button: 'Okay',
        event: this.close
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      const FACIAL_TYPE_REQUIRED = this.formGroup.value.facial_type == '' ? 'The facial type field is required.' : '';
      const CLINIC_ADDRESS_REQUIRED = this.formGroup.value.clinic_address == '' ? 'The clinic address field is required.' : '';
      const LAB_NAME_REQUIRED = this.formGroup.value.lab_name == '' ? 'The lab name field is required.' : '';
      const FORM_DATE_REQUIRED = this.formGroup.value.form_date == '' ? 'The date field field is required.' : '';
      const DATE_REGARDED_REQUIRED = this.formGroup.value.date_regarded == '' ? 'The date field field is required.' : '';
      const DOCTOR_REQUIRED = this.formGroup.get('doctor_id').value == '' ? 'The dentist name field is required.' : '';
      const PATIENT_REQUIRED = this.formGroup.get('patient_id').value == '' ? 'The patient name field is required.' : '';
      const DOCTOR_INVALID = typeof this.formGroup.get('doctor_id').value == 'string' ? 'The dentist name is invalid.' : '';
      const PATIENT_INVALID = typeof this.formGroup.get('patient_id').value == 'string' ? 'The patient name is invalid.' : '';
      const DENTIST_INVALID = typeof this.formGroup.get('dentist_id').value == 'string' && this.formGroup.get('dentist_id').value !== '' ? 'The dentist name is invalid.' : '';
      this.errorMessage = {
        facial_type: FACIAL_TYPE_REQUIRED,
        clinic_address: CLINIC_ADDRESS_REQUIRED,
        lab_name: LAB_NAME_REQUIRED,
        form_date: FORM_DATE_REQUIRED,
        date_regarded: DATE_REGARDED_REQUIRED,
        doctor_id: DOCTOR_REQUIRED == '' ? DOCTOR_INVALID : DOCTOR_REQUIRED,
        patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
        dentist_id: DENTIST_INVALID,
      };
      this.triggerErrors({
        facial_type: FACIAL_TYPE_REQUIRED,
        clinic_address: CLINIC_ADDRESS_REQUIRED,
        lab_name: LAB_NAME_REQUIRED,
        form_date: FORM_DATE_REQUIRED,
        date_regarded: DATE_REGARDED_REQUIRED,
        doctor_id: DOCTOR_REQUIRED == '' ? DOCTOR_INVALID : DOCTOR_REQUIRED,
        patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
        dentist_id: DENTIST_INVALID,
      });
    }));
  }
  
  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }
}
