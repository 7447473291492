import { Injectable } from '@angular/core';
import { loginUrl, requestResetPasswordUrl, resetPasswordUrl, logoutUrl, tokenUrl, changePasswordUrl } from 'src/app/common/api';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { tokenNotExpired } from 'angular2-jwt';
import { HttpService } from 'src/app/modules/shared/services/http/http.service';

@Injectable()
export class AuthService extends HttpService {

  constructor(
    http: HttpClient,
    private _router: Router
  ) { super('', http); }

  public getToken(): string {
  	return localStorage.getItem('token');
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
  	return tokenNotExpired(null, token);
  }

  getAuthToken() {
  	return localStorage.getItem('token');
  }

  getAuthUser() {
  	return JSON.parse(localStorage.getItem('authUser'));
  }

  setAuthUser(user) {
  	const userDetail = Object.assign({}, user);
  	localStorage.setItem('authUser', JSON.stringify(userDetail));
  }

  setAuthToken(user) {
    localStorage.setItem('token', user.token);
  }

  verifyToken(id) {
    return this.http.get(tokenUrl + '?token=' + id).map(res => {
      return res['data'];
    });
  }

  login(data) {
  	return this.http.post(loginUrl, data).map(res => {
      return res['data'];
    });
  }

  updatePassword(){
    let user = this.getAuthUser();
    let token = this.getToken();

    if(user == null && token == null) return false;

    if(user.last_login == null || token == null) {
      return true;
    }
    return false;
  }

  requestPassword(data) {
    return this.http.put(requestResetPasswordUrl, data).map(res => {
      return res['data'];
    });
  }

  resetPassword(data) {
    return this.http.post(resetPasswordUrl, data).map(res => {
      return res['data'];
    });
  }

  changePassword(data) {
    return this.http.post(changePasswordUrl + '/' + data.id, data).map(res => {
      return res['data'];
    });
  }


  logout(user) {
  	return this.http.post(logoutUrl, user).map(res => {
      const username = localStorage.getItem('Email');
      const remember = localStorage.getItem('Remember');
      localStorage.clear();
      if (username !== null || remember !== null) {
        localStorage.setItem('Email', username);
        localStorage.setItem('Remember', remember);
      }
      // return res['data'];
    });
  }

  isOwnerLogin() {
    let data = this.getAuthUser();
    return (data.role_id == "Owner/Dentist")?  true : false;
  }
}
