import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { MatFormFieldModule, MatInputModule, MatCheckboxModule, MatButtonModule, MatIconModule, MatAutocompleteModule } from '@angular/material';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { UpdatePasswordComponent } from './components/update-password/update-password.component';
import { AuthService } from './services/auth/auth.service';
import { AuthGuard, LogGuard } from './services/guards/auth/auth.guard';
import { RoleGuard } from './services/guards/role/role.guard';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptorService } from './services/interceptors/auth-interceptor.service';
import { AlertMessageComponent } from '../shared/components/alert-message/alert-message.component';
import { CustomerAgreementComponent } from '../shared/components/customer-agreement/customer-agreement.component';
import { VerifyAccountComponent } from './components/verify-account/verify-account.component';
import { TermsAndConditionsComponent } from '../shared/components/terms-and-conditions/terms-and-conditions.component';

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UpdatePasswordComponent,
    VerifyAccountComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  exports: [
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    RoleGuard,
    LogGuard,
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: AuthInterceptorService, 
      multi: true 
    }
  ],
  entryComponents: [
    AlertMessageComponent,
    CustomerAgreementComponent,
    TermsAndConditionsComponent
  ]
})
export class LoginModule { }
