import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';

@Component({
  selector: 'ark-e-signature',
  templateUrl: './e-signature.component.html',
  styleUrls: ['./e-signature.component.scss']
})
export class ESignatureComponent implements OnInit {

  dialogConfig = new MatDialogConfig();
  clinic = null;
  signatureImage;
  header = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ESignatureComponent>,
    private auth: AuthService,
    public dialog: MatDialog,
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic;
  }

  ngOnInit() {
    this.header = this.data.title;
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }
    
  closeOnClick() {
    this.dialogRef.close(false);
  }

  saveSignature(data) {
    this.dialogRef.close(data);
  }
}