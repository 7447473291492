import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'ark-remove',
  templateUrl: './remove.component.html',
  styleUrls: ['./remove.component.scss']
})
export class RemoveComponent implements OnInit {

  @Output() onClick = new EventEmitter<any>();

  constructor() { }

  onClickButton(event) {
    this.onClick.emit(event);
  }

  ngOnInit() {
  }
}
