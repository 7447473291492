import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatAutocompleteTrigger, MatDialogConfig, MatDialog } from '@angular/material';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard/dashboard.service';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { DentalFormsService } from 'src/app/modules/dental-office/services/dental-forms/dental-forms.service';
import { startWith, map } from 'rxjs/operators';
import * as moment from 'moment';
import { keypressValidateNumberOnly, keypressToothNumbers } from 'src/app/common/helper';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { ESignatureComponent } from 'src/app/modules/shared/components/e-signature/e-signature.component';
import { UserService } from 'src/app/modules/account-settings/services/user/user.service';

export interface Dentist {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
}

export interface Patient {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
}

export interface Procedure {
  id: number;
  name: string;
}

@Component({
  selector: 'ark-full-denture-authorization',
  templateUrl: './full-denture-authorization.component.html',
  styleUrls: ['./full-denture-authorization.component.scss']
})
export class FullDentureAuthorizationComponent implements OnInit {

  formGroup: FormGroup;
  errorMessage = null;
  clinic = null;
  clinicName = null;
  patientId = '';
  dentistId = '';
  doctorId = '';
  patientName = '';
  dentistName = '';
  doctorName = '';
  selectedProcedure = null;
  unlistedProcedure = null;
  previousDate = new Date();
  patientControl = new FormControl();
  dentistControl = new FormControl();
  procedureControl = new FormControl();
  doctorControl = new FormControl();
  patients: Patient[] = [];
  dentists: Dentist[] = [];
  procedures: Procedure[] = [];
  doctors: Dentist[] = [];
  filteredPatients: Observable<Patient[]>;
  filteredDentists: Observable<Dentist[]>;
  filteredProcedures: Observable<Procedure[]>;
  filteredDoctors: Observable<Dentist[]>;
  @ViewChild('patient') patient: MatAutocompleteTrigger;
  @ViewChild('dentist') dentist: MatAutocompleteTrigger;
  @ViewChild('procedure') procedure: MatAutocompleteTrigger;
  @ViewChild('doctor') doctor: MatAutocompleteTrigger;

  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  facialTypes = [
    { id : '1', name: 'Square' },
    { id : '2', name: 'Triangular' },
    { id : '3', name: 'Rectangular' },
    { id : '4', name: 'Ovoid' },
  ];
  genders = [
    { id : '1', name: 'Male' },
    { id : '2', name: 'Female' }
  ];
  timeRange = [
    { name: '06:00 AM', value: '06:00' },
    { name: '06:30 AM', value: '06:30' },
    { name: '07:00 AM', value: '07:00' },
    { name: '07:30 AM', value: '07:30' },
    { name: '08:00 AM', value: '08:00' },
    { name: '08:30 AM', value: '08:30' },
    { name: '09:00 AM', value: '09:00' },
    { name: '09:30 AM', value: '09:30' },
    { name: '10:00 AM', value: '10:00' },
    { name: '10:30 AM', value: '10:30' },
    { name: '11:00 AM', value: '11:00' },
    { name: '11:30 AM', value: '11:30' },
    { name: '12:00 PM', value: '12:00' },
    { name: '12:30 PM', value: '12:30' },
    { name: '01:00 PM', value: '13:00' },
    { name: '01:30 PM', value: '13:30' },
    { name: '02:00 PM', value: '14:00' },
    { name: '02:30 PM', value: '14:30' },
    { name: '03:00 PM', value: '15:00' },
    { name: '03:30 PM', value: '15:30' },
    { name: '04:00 PM', value: '16:00' },
    { name: '04:30 PM', value: '16:30' },
    { name: '05:00 PM', value: '17:00' },
    { name: '05:30 PM', value: '17:30' },
    { name: '06:00 PM', value: '18:00' },
    { name: '06:30 PM', value: '18:30' },
    { name: '07:00 PM', value: '19:00' },
    { name: '07:30 PM', value: '19:30' },
    { name: '08:00 PM', value: '20:00' },
    { name: '08:30 PM', value: '20:30' },
    { name: '09:00 PM', value: '21:00' },
    { name: '09:30 PM', value: '21:30' },
    { name: '10:00 PM', value: '22:00' },
    { name: '10:30 PM', value: '22:30' },
    { name: '11:00 PM', value: '23:00' },
    { name: '11:30 PM', value: '23:30' },
    { name: '12:00 AM', value: '24:00' },
  ];
  implantCrown = [
    {
      name: 'Acrylic Base', 
      hasInput: false,
      content: [
        { id: 1, name: 'Upper', is_checked: 0 },
        { id: 2, name: 'Lower', is_checked: 0 },
      ]
    },
    {
      name: 'Gold Mesh', 
      hasInput: false,
      content: [
        { id: 1, name: 'Upper', is_checked: 0 },
        { id: 2, name: 'Lower', is_checked: 0 },
      ]
    },
    {
      name: 'Implanted Retained', 
      hasInput: false,
      content: [
        { id: 1, name: 'Upper', is_checked: 0 },
        { id: 2, name: 'Lower', is_checked: 0 },
      ]
    },
    {
      name: 'Other', 
      content: [],
      hasInput: true
    }
  ]
  toothSelection = [
    { id: 1, name: 'Natura Tone', is_checked: 0, hasInput: false },
    { id: 3, name: 'Leeformatron', is_checked: 0, hasInput: false },
    { id: 2, name: 'New Ace', is_checked: 0, hasInput: false },
    { id: 4, name: 'Vita', is_checked: 0, hasInput: false },
    { id: 5, name: 'Other', is_checked: 0, hasInput: true },
  ]
  enclosed = [
    {
      name: 'Enclosed',
      content: [
        { id: 1, name: 'Articulator', is_checked: 0, hasInput: false },
        { id: 2, name: 'Impression Tray_pcs', is_checked: 0, hasInput: false },
        { id: 3, name: 'Old Crown', is_checked: 0, hasInput: false },
        { id: 4, name: 'Shade Guide', is_checked: 0, hasInput: false },
        { id: 5, name: 'Bite Registration', is_checked: 0, hasInput: false },
        { id: 6, name: 'Lower Cast_pcs', is_checked: 0, hasInput: true },
        { id: 7, name: 'Photos', is_checked: 0, hasInput: false },
        { id: 8, name: 'Upper Cast_pcs', is_checked: 0, hasInput: true },
      ]
    }
  ]
  dentureDesign = [
    { id: 1, name: 'Upper Arch', value: 'upper_arch', src: '../../../../../../../../assets/image/crown/denture-upper-arch.svg', is_checked: 0 },
    { id: 2, name: 'Lower Arch', value: 'lower_arch', src: '../../../../../../../../assets/image/crown/denture-lower-arch.svg', is_checked: 0 },
  ]

  receiverSignature;
  dentistSignature;
  hasReceiverSignature = false;
  hasDentistSignature = false;
  signature1 = 'Receiver\'s Signature (tap icon to add)';
  signature2 = 'Dentist\'s Signature (tap icon to add)';

  data = {
    user_clinic_id: "",
    lab_name: "",
    form_date: "",
    telephone_number: "",
    mobile_number: "",
    clinic_address: "",
    doctor_name: "",
    patient_id: "",
    patient_name: "",
    gender: "",
    age: "",
    facial_type: "",
    procedure: "",
    required_on: "",
    required_at: "",
    implant_crown: {
      acrylic_base: [],
      gold_mesh: [],
      implanted_retained: [],
      others: ''
    },
    tooth_selection: [],
    denture_design: {
      upper_arch: '',
      lower_arch: '',
    },
    tooth_no: "",
    shade: "",
    instructions: "",
    enclosed: [],
    dentist_signature: "",
    dentist_name: "",
    prc_license_no: "",
    date_signed: "",
    receivers_signature: "",
    received_by: "",
    received_date: ""
  }

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private dashboard: DashboardService,
    private patientDirectory: PatientsDirectoryService,
    private forms: DentalFormsService,
    public dialog: MatDialog,
    private userService: UserService,
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic;
    this.dashboard.getPatientList(this.clinic.id, this.clinic.id).subscribe(res => {
      res.data.forEach(element => {
        let obj: Patient = {
          id: element.id, 
          firstName: element.first_name, 
          middleName: element.middle_name ? element.middle_name : '', 
          lastName: element.last_name
        };
        this.patients.push(obj);
      });
    });
    this.dashboard.getDentistList(this.clinic.id).subscribe(res => {
      res.forEach(element => {
        let obj: Dentist = {
          id: element.id, 
          firstName: element.first_name,
          lastName: element.last_name,
          middleName: element.middle_name ? element.middle_name : '', 
        };
        this.dentists.push(obj);
        this.doctors.push(obj);
      });
    });
    this.dashboard.getProcedures(this.clinic.id).subscribe(res => {
      res.forEach(element => {
        let obj: Procedure = {
          id: element.id, 
          name: element.title
        };
        this.procedures.push(obj);
      });
    });
    this.formGroup = this.formBuilder.group({
      lab_name: ['', [Validators.required]],
      form_date: ['', [Validators.required]],
      telephone_number: [''],
      mobile_number: [''],
      clinic_address: ['', [Validators.required]],
      doctor_name: [''],
      doctor_id: new FormControl('', [Validators.required]),
      patient_name: [''],
      patient_id: new FormControl('', [Validators.required]),
      age: [''],
      gender: [''],
      required_on: [''],
      time: [''],
      procedure: new FormControl('', [Validators.required]),
      instructions: [''],
      facial_type: ['', [Validators.required]],
      tooth_no: ['', [Validators.required]],
      shade: ['', [Validators.required]],
      dentist_name: [''],
      dentist_id: new FormControl(''),
      dentist_signature: [''],
      received_by: [''],
      received_date: [''],
      receivers_signature: [''],
      prc_license_no: [''],
      implantCrownOthers: [''],
      other_value: [''],
      enclosed_value_6: [''],
      enclosed_value_8: [''],
      date_signed: [''],
    });
  }

  ngOnInit() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.filteredPatients = this.formGroup.get('patient_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterPatient(name) : this.patients.slice()),
      );
    this.filteredDentists = this.formGroup.get('dentist_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterDentist(name) : this.dentists.slice())
      );
    this.filteredProcedures = this.formGroup.get('procedure').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterProcedure(name) : this.procedures.slice())
      );
    this.filteredDoctors = this.formGroup.get('doctor_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterDoctor(name) : this.doctors.slice())
      );
  }

  getPatient(event) {
    this.patientId = event.option.value.id;
    this.patientName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.patientDirectory.getPatientInfo(this.clinic.id, event.option.value.id, this.clinic.id).subscribe(res => {
      if(res) {
        let age = moment().diff(res.data[0].patient_private_information.birthdate, 'years');
        let gender = '';
        if(res['data'][0].patient_private_information.gender == '1') {
          gender = 'Male'
        } else if(res['data'][0].patient_private_information.gender == '2') {
          gender = 'Female'
        } else {
          gender = '';
        }
        this.formGroup.get('gender').setValue(gender);
        this.formGroup.get('age').setValue(age);
      }
    });
    this.formGroup.get('patient_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
  }

  getDentist(event) {
    this.dentistId = event.option.value.id;
    this.dentistName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.dashboard.getDentistInfo(event.option.value.id, this.clinic.id).subscribe(res => {
      if(res) {
        this.formGroup.get('prc_license_no').setValue(res[0].user.prc_license_no ? res[0].user.prc_license_no : '');
      }
    });
    this.formGroup.get('dentist_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
  }

  getDoctor(event) {
    this.doctorId = event.option.value.id;
    this.doctorName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.userService.getClinic(this.clinic.id, this.clinic.id).subscribe(res => {
      if(res) {
        this.formGroup.get('clinic_address').setValue(res['data'].address ? res['data'].address : '');
        this.formGroup.get('mobile_number').setValue(res['data'].mobile_number ? res['data'].mobile_number : '');
        this.formGroup.get('telephone_number').setValue(res['data'].contact_number ? res['data'].contact_number : '');
        this.formGroup.get('form_date').setValue(new Date());
      }
    });
    this.formGroup.get('doctor_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
    this.formGroup.get('dentist_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
    this.dentistId = event.option.value.id;
    this.dentistName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.dashboard.getDentistInfo(event.option.value.id, this.clinic.id).subscribe(res => {
      if(res) {
        this.formGroup.get('prc_license_no').setValue(res[0].user.prc_license_no ? res[0].user.prc_license_no : '');
      }
    });
  }

  getProcedure(event) {
    this.selectedProcedure = event.option.value.name;
    this.formGroup.get('procedure').setValue({
      id: event.option.value.id, 
      name: event.option.value.name
    });
  }

  getNewProcedure(e) {
    this.unlistedProcedure = e;
  }

  private _filterPatient(name: string): Patient[] {
    const filterValue = name.toLowerCase();
    return this.patients.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1 || option.middleName.toLowerCase().indexOf(filterValue) > -1);
  }

  private _filterDentist(name: string): Dentist[] {
    const filterValue = name.toLowerCase();
    return this.dentists.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1);
  }

  private _filterProcedure(name: string): Procedure[] {
    const filterValue = name.toLowerCase();
    return this.procedures.filter(option => option.name.toLowerCase().indexOf(filterValue) > -1);
  }

  private _filterDoctor(name: string): Dentist[] {
    const filterValue = name.toLowerCase();
    return this.doctors.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1);
  }

  displayDentist(dentist?: any) : string | undefined {
    return dentist ? 'Dr. ' + dentist.firstName + (dentist.middleName ? ' ' + dentist.middleName + ' ' : ' ') + dentist.lastName : undefined;
  }

  displayDoctor(doctor?: any) : string | undefined {
    return doctor ? 'Dr. ' + doctor.firstName + (doctor.middleName ? ' ' + doctor.middleName + ' ' : ' ') + doctor.lastName : undefined;
  }

  displayPatient(patient?: any) : string | undefined {
    return patient ? patient.lastName + ', ' + patient.firstName + (patient.middleName ? ' ' + patient.middleName : ' ') : undefined;
  }

  displayProcedure(procedure?: any) : string | undefined {
    return procedure ? procedure.name : undefined;
  }

  onFocusProcedure() {
    this.procedure._onChange(""); 
    this.procedure.openPanel();
  }

  onFocusPatient() {
    this.patient._onChange("");
    this.patient.openPanel();
  }

  onFocusDentist() {
    this.dentist._onChange(""); 
    this.dentist.openPanel();
  }

  onFocusDoctor() {
    this.doctor._onChange(""); 
    this.doctor.openPanel();
  }

  numberTypeValidation(e) {
    return keypressToothNumbers(e);
  }

  numberValidation(e) {
    return keypressValidateNumberOnly(e);
  }
  
  getReceiverSignature() {
      this.dialogConfig.disableClose = true;
      this.dialogConfig.autoFocus = false;
      this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
      this.dialogConfig.data = {
        title: 'Receiver\'s Signature'
      }
      this.dialogRef = this.dialog.open(ESignatureComponent, this.dialogConfig).afterClosed().subscribe(res => {
        if(res) {
          this.hasReceiverSignature = true;
          this.receiverSignature = res;
          this.signature1 = 'Receiver\'s Signature';
          this.formGroup.get('received_date').setValue(new Date());
        }
      });
    }
  
  getDentistSignature() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogConfig.data = {
      title: 'Dentist\'s Signature'
    }
    this.dialogRef = this.dialog.open(ESignatureComponent, this.dialogConfig).afterClosed().subscribe(res => {
      if(res) {
        this.hasDentistSignature = true;
        this.dentistSignature = res;
        this.signature2 = 'Dentist\'s Signature';
        this.formGroup.get('date_signed').setValue(new Date());
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  onCheckEnclosed(e, obj) {
    obj.is_checked = (e.checked) ? 1 : 0;
    if(obj.is_checked) {
      this.data.enclosed.push(obj.name);
    } else {
      if (obj.name == 'Lower Cast_pcs' || obj.name == 'Upper Cast_pcs') {
        const selection = this.data.enclosed.filter(item => item.includes(obj.name));
        this.data.enclosed.splice(this.data.enclosed.indexOf(selection[0]), 1);
      } else {
        this.data.enclosed.splice(this.data.enclosed.indexOf(obj.name), 1);
      }
    }
  }

  onCheckDentureDesign(e, obj, index) {
    if(index == 0) {
      obj.is_checked = (e.checked) ? 1 : 0;
      if(obj.is_checked) {
        this.data.denture_design['upper_arch'] = obj.value;
      } else {
        this.data.denture_design['upper_arch'] = '';
      }
    } else {
      obj.is_checked = (e.checked) ? 1 : 0;
      if(obj.is_checked) {
        this.data.denture_design['lower_arch'] = obj.value
      } else {
        this.data.denture_design['lower_arch'] = '';
      }
    }
  }

  onCheckImplantCrown(index, e, obj,) {
    if(index == 0) {
      obj.is_checked = (e.checked) ? 1 : 0;
      if(obj.is_checked) {
        this.data.implant_crown.acrylic_base.push(obj.name);
      } else {
        this.data.implant_crown.acrylic_base.splice(this.data.implant_crown.acrylic_base.indexOf(obj.name), 1);
      }
    } else if(index == 1) {
      obj.is_checked = (e.checked) ? 1 : 0;
      if(obj.is_checked) {
        this.data.implant_crown.gold_mesh.push(obj.name);
      } else {
        this.data.implant_crown.gold_mesh.splice(this.data.implant_crown.gold_mesh.indexOf(obj.name), 1);
      }
    } else if(index == 2) {
      obj.is_checked = (e.checked) ? 1 : 0;
      if(obj.is_checked) {
        this.data.implant_crown.implanted_retained.push(obj.name);
      } else {
        this.data.implant_crown.implanted_retained.splice(this.data.implant_crown.implanted_retained.indexOf(obj.name), 1);
      }
    } 
  }

  onCheckToothSelection(e, obj) {
    obj.is_checked = (e.checked) ? 1 : 0;
    if(obj.is_checked) {
      this.data.tooth_selection.push(obj.name);
    } else {
      if (obj.name == 'Other') {
        const selection = this.data.tooth_selection.filter(item => item.includes(obj.name));
        this.data.tooth_selection.splice(this.data.tooth_selection.indexOf(selection[0]), 1);
      } else {
        this.data.tooth_selection.splice(this.data.tooth_selection.indexOf(obj.name), 1);
      }
    }
  }

  download() {
    const gender = this.formGroup.value.gender == 1 ? 'Male' : 'Female';
    const received_date = this.formGroup.value.received_date ? moment(this.formGroup.value.received_date).format('MM/DD/YYYY') : '';
    const form_date = this.formGroup.value.form_date ? moment(this.formGroup.value.form_date).format('MM/DD/YYYY') : '';
    const required_on = this.formGroup.value.required_on ? moment(this.formGroup.value.required_on).format('MM/DD/YYYY') : '';
    const date_signed = this.formGroup.value.date_signed ? moment(this.formGroup.value.date_signed).format('MM/DD/YYYY') : '';
    this.data.implant_crown.others = this.formGroup.value.implantCrownOthers;

    this.data.user_clinic_id = this.clinic.id;
    this.data.lab_name = this.formGroup.value.lab_name,
    this.data.form_date = form_date,
    this.data.telephone_number = this.formGroup.value.telephone_number,
    this.data.mobile_number = this.formGroup.value.mobile_number,
    this.data.clinic_address = this.formGroup.value.clinic_address,
    this.data.doctor_name = this.doctorName,
    this.data.patient_id = this.patientId;
    this.data.patient_name = this.patientName;
    this.data.gender = gender;
    this.data.age = this.formGroup.value.age;
    this.data.facial_type = this.formGroup.value.facial_type;
    this.data.procedure = this.selectedProcedure ? this.selectedProcedure : this.unlistedProcedure;
    this.data.required_on = required_on;
    this.data.required_at = this.formGroup.value.time;
    this.data.tooth_no = this.formGroup.value.tooth_no;
    this.data.shade = this.formGroup.value.shade;
    this.data.instructions = this.formGroup.value.instructions;
    this.data.dentist_signature = this.dentistSignature;
    this.data.dentist_name = this.dentistName;
    this.data.prc_license_no = this.formGroup.value.prc_license_no;
    this.data.receivers_signature = this.receiverSignature;
    this.data.received_by = this.formGroup.value.received_by;
    this.data.received_date = received_date; 
    this.data.date_signed = date_signed;

    let selection = this.data.tooth_selection.filter(item => item.includes('Other'));
    if(selection.length > 0) {
      let x = this.data.tooth_selection.indexOf(selection[0]);

      if (this.formGroup.value.other_value != '' && this.formGroup.value.other_value != null && this.formGroup.value.other_value != 'null') {
        this.data.tooth_selection[x] = 'Other: '+ this.formGroup.value.other_value;
      } else {
        this.data.tooth_selection[x] = 'Other';
      }
    }

    let enclosed = this.data.enclosed.filter(item => item.includes('Lower Cast_pcs'));
    if(enclosed.length > 0) {
      let x = this.data.enclosed.indexOf(enclosed[0]);

      if (this.formGroup.value.enclosed_value_6 != '' && this.formGroup.value.enclosed_value_6 != null && this.formGroup.value.enclosed_value_6 != 'null') {
        this.data.enclosed[x] = 'Lower Cast_pcs: '+ this.formGroup.value.enclosed_value_6;
      } else {
        this.data.enclosed[x] = 'Lower Cast_pcs';
      }
    }

    let upperEnclosed = this.data.enclosed.filter(item => item.includes('Upper Cast_pcs'));
    if(upperEnclosed.length > 0) {
      let x = this.data.enclosed.indexOf(upperEnclosed[0]);

      if (this.formGroup.value.enclosed_value_6 != '' && this.formGroup.value.enclosed_value_6 != null && this.formGroup.value.enclosed_value_6 != 'null') {
        this.data.enclosed[x] = 'Upper Cast_pcs: '+ this.formGroup.value.enclosed_value_8;
      } else {
        this.data.enclosed[x] = 'Upper Cast_pcs';
      }
    }

    this.forms.printAndDownloadDenturesWorkAuth(this.data).subscribe(res => {
      if(res) {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(res);
        a.download = moment().format("YYYYMMDD") + '-WORK AUTHORIZATION FULL DENTURES.pdf';
        document.body.appendChild(a);
        a.click();
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Exporting of PDF failed. Please fill-in all the required fields.',
        button: 'Okay',
        event: this.close
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      const FACIAL_TYPE_REQUIRED = this.formGroup.value.facial_type == '' ? 'The facial type field is required.' : '';
      const CLINIC_ADDRESS_REQUIRED = this.formGroup.value.clinic_address == '' ? 'The clinic address field is required.' : '';
      const LAB_NAME_REQUIRED = this.formGroup.value.lab_name == '' ? 'The lab name field is required.' : '';
      const FORM_DATE_REQUIRED = this.formGroup.value.form_date == '' ? 'The date field field is required.' : '';
      const TOOTH_NO_REQUIRED = this.formGroup.value.tooth_no == '' ? 'The tooth number field is required.' : '';
      const SHADE_REQUIRED = this.formGroup.value.shade == '' ? 'The shade field is required.' : '';
      const PROCEDURE_REQUIRED = this.formGroup.get('procedure').value == '' ? 'The procedure field is required.' : '';
      const DOCTOR_REQUIRED = this.formGroup.get('doctor_id').value == '' ? 'The dentist name field is required.' : '';
      const PATIENT_REQUIRED = this.formGroup.get('patient_id').value == '' ? 'The patient name field is required.' : '';
      const PROCEDURE_INVALID = typeof this.formGroup.get('procedure').value == 'string' ? 'The procedure is invalid.' : '';
      const DOCTOR_INVALID = typeof this.formGroup.get('doctor_id').value == 'string' ? 'The dentist name is invalid.' : '';
      const PATIENT_INVALID = typeof this.formGroup.get('patient_id').value == 'string' ? 'The patient name is invalid.' : '';
      const DENTIST_INVALID = typeof this.formGroup.get('dentist_id').value == 'string' && this.formGroup.get('dentist_id').value !== '' ? 'The dentist name is invalid.' : '';
      this.errorMessage = {
        facial_type: FACIAL_TYPE_REQUIRED,
        clinic_address: CLINIC_ADDRESS_REQUIRED,
        lab_name: LAB_NAME_REQUIRED,
        form_date: FORM_DATE_REQUIRED,
        shade: SHADE_REQUIRED,
        date_regarded: TOOTH_NO_REQUIRED,
        procedure: PROCEDURE_REQUIRED == '' ? PROCEDURE_INVALID : PROCEDURE_REQUIRED,
        doctor_id: DOCTOR_REQUIRED == '' ? DOCTOR_INVALID : DOCTOR_REQUIRED,
        patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
        dentist_id: DENTIST_INVALID,
      };
      this.triggerErrors({
        facial_type: FACIAL_TYPE_REQUIRED,
        clinic_address: CLINIC_ADDRESS_REQUIRED,
        lab_name: LAB_NAME_REQUIRED,
        form_date: FORM_DATE_REQUIRED,
        tooth_no: TOOTH_NO_REQUIRED,
        shade: SHADE_REQUIRED,
        procedure: PROCEDURE_REQUIRED == '' ? PROCEDURE_INVALID : PROCEDURE_REQUIRED,
        doctor_id: DOCTOR_REQUIRED == '' ? DOCTOR_INVALID : DOCTOR_REQUIRED,
        patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
        dentist_id: DENTIST_INVALID,
      });
    }));
  }

  print() {
    const gender = this.formGroup.value.gender == 1 ? 'Male' : 'Female';
    const received_date = this.formGroup.value.received_date ? moment(this.formGroup.value.received_date).format('MM/DD/YYYY') : '';
    const form_date = this.formGroup.value.form_date ? moment(this.formGroup.value.form_date).format('MM/DD/YYYY') : '';
    const required_on = this.formGroup.value.required_on ? moment(this.formGroup.value.required_on).format('MM/DD/YYYY') : '';
    const date_signed = this.formGroup.value.date_signed ? moment(this.formGroup.value.date_signed).format('MM/DD/YYYY') : '';
    this.data.implant_crown.others = this.formGroup.value.implantCrownOthers;

    this.data.user_clinic_id = this.clinic.id;
    this.data.lab_name = this.formGroup.value.lab_name,
    this.data.form_date = form_date,
    this.data.telephone_number = this.formGroup.value.telephone_number,
    this.data.mobile_number = this.formGroup.value.mobile_number,
    this.data.clinic_address = this.formGroup.value.clinic_address,
    this.data.doctor_name = this.doctorName,
    this.data.patient_id = this.patientId;
    this.data.patient_name = this.patientName;
    this.data.gender = gender;
    this.data.age = this.formGroup.value.age;
    this.data.facial_type = this.formGroup.value.facial_type;
    this.data.procedure = this.selectedProcedure ? this.selectedProcedure : this.unlistedProcedure;
    this.data.required_on = required_on;
    this.data.required_at = this.formGroup.value.time;
    this.data.tooth_no = this.formGroup.value.tooth_no;
    this.data.shade = this.formGroup.value.shade;
    this.data.instructions = this.formGroup.value.instructions;
    this.data.dentist_signature = this.dentistSignature;
    this.data.dentist_name = this.dentistName;
    this.data.prc_license_no = this.formGroup.value.prc_license_no;
    this.data.receivers_signature = this.receiverSignature;
    this.data.received_by = this.formGroup.value.received_by;
    this.data.received_date = received_date;
    this.data.date_signed = date_signed;

    let selection = this.data.tooth_selection.filter(item => item.includes('Other'));
    if(selection.length > 0) {
      let x = this.data.tooth_selection.indexOf(selection[0]);

      if (this.formGroup.value.other_value != '' && this.formGroup.value.other_value != null && this.formGroup.value.other_value != 'null') {
        this.data.tooth_selection[x] = 'Other: '+ this.formGroup.value.other_value;
      } else {
        this.data.tooth_selection[x] = 'Other';
      }
    }

    let enclosed = this.data.enclosed.filter(item => item.includes('Lower Cast_pcs'));
    if(enclosed.length > 0) {
      let x = this.data.enclosed.indexOf(enclosed[0]);

      if (this.formGroup.value.enclosed_value_6 != '' && this.formGroup.value.enclosed_value_6 != null && this.formGroup.value.enclosed_value_6 != 'null') {
        this.data.enclosed[x] = 'Lower Cast_pcs: '+ this.formGroup.value.enclosed_value_6;
      } else {
        this.data.enclosed[x] = 'Lower Cast_pcs';
      }
    }

    let upperEnclosed = this.data.enclosed.filter(item => item.includes('Upper Cast_pcs'));
    if(upperEnclosed.length > 0) {
      let x = this.data.enclosed.indexOf(upperEnclosed[0]);

      if (this.formGroup.value.enclosed_value_6 != '' && this.formGroup.value.enclosed_value_6 != null && this.formGroup.value.enclosed_value_6 != 'null') {
        this.data.enclosed[x] = 'Upper Cast_pcs: '+ this.formGroup.value.enclosed_value_8;
      } else {
        this.data.enclosed[x] = 'Upper Cast_pcs';
      }
    }

    this.forms.printAndDownloadDenturesWorkAuth(this.data).subscribe(res => {
      if(res) {
        const url = URL.createObjectURL(res);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = url;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Print Failed. Please fill-in all the required fields.',
        button: 'Okay',
        event: this.close
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      const FACIAL_TYPE_REQUIRED = this.formGroup.value.facial_type == '' ? 'The facial type field is required.' : '';
      const CLINIC_ADDRESS_REQUIRED = this.formGroup.value.clinic_address == '' ? 'The clinic address field is required.' : '';
      const LAB_NAME_REQUIRED = this.formGroup.value.lab_name == '' ? 'The lab name field is required.' : '';
      const FORM_DATE_REQUIRED = this.formGroup.value.form_date == '' ? 'The date field field is required.' : '';
      const TOOTH_NO_REQUIRED = this.formGroup.value.tooth_no == '' ? 'The tooth number field is required.' : '';
      const SHADE_REQUIRED = this.formGroup.value.shade == '' ? 'The shade field is required.' : '';
      const PROCEDURE_REQUIRED = this.formGroup.get('procedure').value == '' ? 'The procedure field is required.' : '';
      const DOCTOR_REQUIRED = this.formGroup.get('doctor_id').value == '' ? 'The dentist name field is required.' : '';
      const PATIENT_REQUIRED = this.formGroup.get('patient_id').value == '' ? 'The patient name field is required.' : '';
      const PROCEDURE_INVALID = typeof this.formGroup.get('procedure').value == 'string' ? 'The procedure is invalid.' : '';
      const DOCTOR_INVALID = typeof this.formGroup.get('doctor_id').value == 'string' ? 'The dentist name is invalid.' : '';
      const PATIENT_INVALID = typeof this.formGroup.get('patient_id').value == 'string' ? 'The patient name is invalid.' : '';
      const DENTIST_INVALID = typeof this.formGroup.get('dentist_id').value == 'string' && this.formGroup.get('dentist_id').value !== '' ? 'The dentist name is invalid.' : '';
      this.errorMessage = {
        facial_type: FACIAL_TYPE_REQUIRED,
        clinic_address: CLINIC_ADDRESS_REQUIRED,
        lab_name: LAB_NAME_REQUIRED,
        form_date: FORM_DATE_REQUIRED,
        shade: SHADE_REQUIRED,
        date_regarded: TOOTH_NO_REQUIRED,
        procedure: PROCEDURE_REQUIRED == '' ? PROCEDURE_INVALID : PROCEDURE_REQUIRED,
        doctor_id: DOCTOR_REQUIRED == '' ? DOCTOR_INVALID : DOCTOR_REQUIRED,
        patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
        dentist_id: DENTIST_INVALID,
      };
      this.triggerErrors({
        facial_type: FACIAL_TYPE_REQUIRED,
        clinic_address: CLINIC_ADDRESS_REQUIRED,
        lab_name: LAB_NAME_REQUIRED,
        form_date: FORM_DATE_REQUIRED,
        tooth_no: TOOTH_NO_REQUIRED,
        shade: SHADE_REQUIRED,
        procedure: PROCEDURE_REQUIRED == '' ? PROCEDURE_INVALID : PROCEDURE_REQUIRED,
        doctor_id: DOCTOR_REQUIRED == '' ? DOCTOR_INVALID : DOCTOR_REQUIRED,
        patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
        dentist_id: DENTIST_INVALID,
      });
    }));
  }
  
  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }
}
