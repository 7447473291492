import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';

@Injectable()
export class HttpService {

  constructor(
    public url: string,
    public http: HttpClient
  ) { }

  get(customUrl?) {
    const url = customUrl ? customUrl : this.url;

    return this.http.get(url).catch(this.handleErrorCatch);
  }

  getAll() {
    return this.http.get(this.url).catch(this.handleErrorCatch);
  }

  post(payload: any) {
    return this.http.post(this.url, payload).catch(this.handleErrorCatch);
  }

  delete(payload: any) {
    return this.http.delete(this.url).catch(this.handleErrorCatch);
  }

  put(payload: any) {
    return this.http.put(this.url, payload).catch(this.handleErrorCatch);
  }

  private handleErrorCatch(error: Response) {
    console.log(error);
    if (error.status == 401) {
      window.location.reload();
    }
    return Observable.throw(error);
  }
}
