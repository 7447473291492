import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, ViewChild, Pipe, PipeTransform } from '@angular/core';
import { MatDialogConfig, MatDialog, MatAutocompleteTrigger } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { DatePipe } from '@angular/common';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import * as moment from 'moment';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { keypressValidateNumberDecimal, keypressValidateNumber, formatNumber } from 'src/app/common/helper'
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard/dashboard.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AddPatientBillingComponent } from '../add-patient-billing/add-patient-billing.component';
import { ViewPatientBillingComponent } from '../view-patient-billing/view-patient-billing.component';
import { AddPatientBillComponent } from '../add-patient-bill/add-patient-bill.component';
import { ViewPatientBillComponent } from '../view-patient-bill/view-patient-bill.component';
import { ConfirmBillingAdditionComponent } from 'src/app/modules/shared/components/confirm-billing-addition/confirm-billing-addition.component';

export interface Dentist {
  id: number;
  firstName: string;
  lastName: string;
}

@Component({
  selector: 'ark-patient-billing-statement',
  templateUrl: './patient-billing-statement.component.html',
  styleUrls: ['./patient-billing-statement.component.scss']
})
export class PatientBillingStatementComponent implements OnInit, OnChanges, OnDestroy {

  @Input() currentVisitId: any = null; 
  @Input() previousVisitId: any = null; 
  @Input() attendingDentist: any = null; 
  @Input('selectedIndex') selectedIndex: number; 
  @Output('selectedIndexChange') selectedIndexChange: EventEmitter<any> = new EventEmitter;
  @ViewChild('dentist') dentist: MatAutocompleteTrigger;
  errorMessage;
  success: boolean = false;
  newBilling: boolean = false;
  isNoResult: boolean = true;
  isCurrent: boolean = true;
  newData: boolean = false;
  patientId = '';
  dentistId: any;
  dialogConfig = new MatDialogConfig();
  dialogRef: any;
  date = new Date();
  datePipe = new DatePipe('en-US');
  today = '';
  customCollapsedHeight: string = '20px';
  customExpandedHeight: string = '20px';
  visits = [];
  patientBilling: any;
  patientBalance: any;
  formGroup: FormGroup;
  total: number = 0;
  difference: number = 0;
  clinic = null;
  hasBalance: boolean = false;
  hasBilling: boolean = false;
  billingStatementNumber = null;
  visitId = null;
  dentistControl = new FormControl();
  dentists: Dentist[] = [];
  filteredDentists: Observable<Dentist[]>;
  patientBirthdate = null;
  patientEmail = null;
  patientMobile = null;
  patientRecallVisit = null;
  label = 'Save';
  reviewerDetails: any;
  OPTION_1: boolean = false;
  OPTION_2: boolean = false;
  amountPaidReadOnly: boolean = false;

  BILLING_ID;
  BILLING_DETAILS = [];
  PREVIOUS_BALANCE;
  SUBTOTAL;
  DISCOUNT;
  DOWNPAYMENT;
  CURRENT_BALANCE;
  AMOUNT_PAID;

  constructor(
    public dialog: MatDialog,
    private patientsDirectory: PatientsDirectoryService,
    private dashboard: DashboardService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private _router: Router,
  ) {
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.today = this.datePipe.transform(this.date, 'EEEE, MMMM d, y, h:mm a');
    this.route.params.subscribe(param => {
      this.patientId = param.id;
    });
    this.dashboard.getDentistList(this.clinic).subscribe(res => {
      res.forEach(element => {
        let obj: Dentist = {
          id: element.id, 
          firstName: element.first_name,
          lastName: element.last_name
        };
        this.dentists.push(obj);
      });
    });
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      previous_balance: [''],
      subtotal: [''],
      downpayment: [''],
      available_credits: [''],
      discount: [''],
      amount_paid: [''],
      total_amount_due: [''],
      balance: [''],
      clinical_notes: [''],
      reviewer: ['', [Validators.required]],
    });
    this.formGroup.controls['previous_balance'].disable();
    this.formGroup.controls['subtotal'].disable();
    this.formGroup.controls['downpayment'].disable();
    this.formGroup.controls['discount'].disable();
    this.formGroup.controls['balance'].disable();
    this.filteredDentists = this.dentistControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterDentist(name) : this.dentists.slice())
      );
  }

  ngOnChanges() {
    if(this.currentVisitId) {
      this.isCurrent = true;
      this.getPatientBillingStatementSummary(this.currentVisitId);
    } else if(this.previousVisitId) {
      this.isCurrent = false;
      this.getPreviousBillingStatementSummary(this.previousVisitId);
    }

    if(this.attendingDentist) {
      this.dentistId = this.attendingDentist;
      this.dashboard.getDentistInfo(this.dentistId, this.clinic).subscribe(res => {
        if(res) {
          this.dentistControl.setValue(
            {
              id: res[0].id, 
              firstName: res[0].first_name, 
              lastName: res[0].last_name
            }
          );
        }
      });
    }
  }

  ngOnDestroy() {
    if(this.previousVisitId) {
      this.patientsDirectory.getSpecificVisitInfo(this.patientId, this.previousVisitId, this.clinic).subscribe();
    }
  }

  private _filterDentist(name: string): Dentist[] {
    const filterValue = name.toLowerCase();
    return this.dentists.filter(option => option.lastName.toLowerCase().indexOf(filterValue) === 0 || option.firstName.toLowerCase().indexOf(filterValue) === 0);
  }

  displayDentist(dentist?: any) : string | undefined {
    return dentist ? 'Dr. ' + dentist.firstName + ' ' + dentist.lastName : undefined;
  }

  onFocusDentist() {
    this.dentist._onChange(""); 
    this.dentist.openPanel();
  }

  getDentist(event) {
    this.dentistId = event.option.value.id;
  }

  dataChecker(event) {
    // this.dentistId = '';
  }

  getPatientBillingStatementSummary(id) {
    this.patientsDirectory.getBillingSummary(this.clinic, this.patientId, id).subscribe(res => {
      if(res.length > 0) {
        this.success = true;
        this.isNoResult = false;
        this.billingStatementNumber = res[0].statement_number;
        this.patientBilling = res[0];
        this.BILLING_ID = res[0].id;
        this.BILLING_DETAILS = [];
        if(res[0].option == 'Procedure') {
          this.OPTION_1 = true;
          this.OPTION_2 = false;
          res.forEach(element => {
            element.billing_details.forEach(x => {
              this.BILLING_DETAILS.push(x);
            });
          });
        } else if(res[0].option == 'Payment') {
          this.OPTION_1 = false;
          this.OPTION_2 = true;
          res.forEach(element => {
            this.BILLING_DETAILS.push(element);
          });
        }

        if(this.isCurrent === true) {
          this.label = 'Save';
        } else if(this.isCurrent  === false) {
          this.label = 'Update';
        }

        this.PREVIOUS_BALANCE = res[0].previous_balance;
        this.SUBTOTAL = res[0].subtotal;
        this.DISCOUNT = res[0].discount;
        this.DOWNPAYMENT = res[0].downpayment;
        this.CURRENT_BALANCE = res[0].current_balance;
        this.AMOUNT_PAID = res[0].amount_paid
        this.formGroup.get('previous_balance').setValue(parseFloat(this.PREVIOUS_BALANCE).toFixed(2));
        this.formGroup.get('subtotal').setValue(parseFloat(this.SUBTOTAL).toFixed(2));
        this.formGroup.get('discount').setValue(parseFloat(this.DISCOUNT).toFixed(2));
        this.formGroup.get('downpayment').setValue(parseFloat(this.DOWNPAYMENT).toFixed(2));
        this.formGroup.get('balance').setValue(parseFloat(this.CURRENT_BALANCE).toFixed(2));
        this.formGroup.get('amount_paid').setValue(parseFloat(this.AMOUNT_PAID).toFixed(2));
        this.formGroup.get('clinical_notes').setValue(this.patientBilling.clinical_notes);
        if(this.AMOUNT_PAID !== '0.00' && this.AMOUNT_PAID != 0) {
          this.amountPaidReadOnly = true;
        }

        if(res[0].billing_details.length == 0 && res[0].option == 'Procedure') {
          this.isNoResult = true;
        }
      } else {
        this.isNoResult = true;
      }
    });
  }

  getPreviousBillingStatementSummary(id) {
    this.patientsDirectory.getSpecificVisitInfo(this.patientId, id, this.clinic).subscribe(res => {
      if(res.length > 0) {
        this.success = true;
        this.isNoResult = false;
        this.billingStatementNumber = res[0].patient_billing.statement_number;
        this.reviewerDetails = res[0].attending_dentist;
        this.patientBilling = res[0].patient_billing;
        this.BILLING_ID = res[0].patient_billing.id;
        this.BILLING_DETAILS = [];
        if(res[0].patient_billing.option == 'Procedure') {
          this.OPTION_1 = true;
          this.OPTION_2 = false;
          res[0].patient_billing.billing_details.forEach(x => {
            this.BILLING_DETAILS.push(x);
          });
        } else if(res[0].patient_billing.option == 'Payment') {
          this.OPTION_1 = false;
          this.OPTION_2 = true;
          res.forEach(element => {
            this.BILLING_DETAILS.push(element);
          });
        }

        if(this.isCurrent === true) {
          this.label = 'Save';
        } else if(this.isCurrent  === false) {
          this.label = 'Update';
        }

        /** Set reviewer using attending dentist data visit information  details */
        if(this.reviewerDetails) {
          this.dentistControl.setValue(
            {
              id: this.reviewerDetails.id, 
              firstName: this.reviewerDetails.first_name, 
              lastName: this.reviewerDetails.last_name
            }
          );
        }

        this.PREVIOUS_BALANCE = res[0].patient_billing.previous_balance;
        this.SUBTOTAL = res[0].patient_billing.subtotal;
        this.DISCOUNT = res[0].patient_billing.discount;
        this.DOWNPAYMENT = res[0].patient_billing.downpayment;
        this.CURRENT_BALANCE = res[0].patient_billing.current_balance;
        this.AMOUNT_PAID = res[0].patient_billing.amount_paid
        this.formGroup.get('previous_balance').setValue(parseFloat(this.PREVIOUS_BALANCE).toFixed(2));
        this.formGroup.get('subtotal').setValue(parseFloat(this.SUBTOTAL).toFixed(2));
        this.formGroup.get('discount').setValue(parseFloat(this.DISCOUNT).toFixed(2));
        this.formGroup.get('downpayment').setValue(parseFloat(this.DOWNPAYMENT).toFixed(2));
        this.formGroup.get('balance').setValue(parseFloat(this.CURRENT_BALANCE).toFixed(2));
        this.formGroup.get('amount_paid').setValue(parseFloat(this.AMOUNT_PAID).toFixed(2));
        this.formGroup.get('clinical_notes').setValue(this.patientBilling.clinical_notes);
        // if(this.AMOUNT_PAID !== '0.00' && this.AMOUNT_PAID != 0) {
          this.amountPaidReadOnly = true;
        // }

        if(this.patientBilling.length == 0) {
          this.isNoResult = true;
        } else {
          this.isNoResult = false;
        }
      } else {
        this.isNoResult = true;
      }
    });
  }

  addPatientBill() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    if(this.BILLING_ID) {
      this.patientsDirectory.checkPermission(this.clinic, this.patientId, this.BILLING_ID).subscribe(res => {
        if(res) {
          if(this.BILLING_DETAILS.length > 0) {
            let dialogRef = this.dialog.open(ConfirmBillingAdditionComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
              if(trigger) {
                this.newBilling = true; // Pass true for newly added billing
                this.dialogConfig.data = {
                  id: this.patientId,
                  billing_id: this.BILLING_ID ? this.BILLING_ID : '',
                  new_billing: this.newBilling
                }
                this.dialogRef = this.dialog.open(AddPatientBillComponent, this.dialogConfig).afterClosed().subscribe(res => {
                  if(res) {
                    this.getPatientBillingStatementSummary(res.data[0].id);
                    this.amountPaidReadOnly = false; // Allow user to input amount paid again
                  }
                });
              }
            });
          } else {
            this.newBilling = false;  // Pass false if its the first time to add a billing
            this.dialogConfig.data = {
              id: this.patientId,
              billing_id: this.BILLING_ID ? this.BILLING_ID : '',
              new_billing: this.newBilling
            }
            this.dialogRef = this.dialog.open(AddPatientBillComponent, this.dialogConfig).afterClosed().subscribe(res => {
              if(res) {
                this.getPatientBillingStatementSummary(res.data[0].id);
                this.amountPaidReadOnly = false; // Allow user to input amount paid again
              }
            });
          }
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: err.error.message,
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }));
    } else {
      if(this.BILLING_DETAILS.length > 0) {
        let dialogRef = this.dialog.open(ConfirmBillingAdditionComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
          if(trigger) {
            this.dialogConfig.data = {
              id: this.patientId,
              billing_id: this.BILLING_ID ? this.BILLING_ID : '',
            }
            this.dialogRef = this.dialog.open(AddPatientBillComponent, this.dialogConfig).afterClosed().subscribe(res => {
              if(res) {
                this.getPatientBillingStatementSummary(res.data[0].id);
              }
            });
          }
        });
      } else {
        this.dialogConfig.data = {
          id: this.patientId,
          billing_id: this.BILLING_ID ? this.BILLING_ID : '',
        }
        this.dialogRef = this.dialog.open(AddPatientBillComponent, this.dialogConfig).afterClosed().subscribe(res => {
          if(res) {
            this.getPatientBillingStatementSummary(res.data[0].id);
          }
        });
      }
    }
  }

  viewPatientBill(data, option) {
    this.newData = false;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogConfig.data = {
      data: data,
      option: option,
      id: this.patientId
    }
    this.dialogRef = this.dialog.open(ViewPatientBillComponent, this.dialogConfig).afterClosed().subscribe(res => {
      if(res) {
        if(res.length > 0) {
          this.getPatientBillingStatementSummary(res[0].id);
        } else {
          this.isNoResult = true;
          this.billingStatementNumber = null;
        }

        if(res[0].billing_details.find(x => x.id === data.id)) {
          this.newData = false;
        } else {
          this.newData = true;
        }
      }
    });
  }

  downloadPatientBill() {
    this.patientsDirectory.downloadBillingStatement(this.clinic, this.patientId, this.previousVisitId).subscribe(res => {
      if(res) {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(res);
        a.download = moment(this.date).format("YYYYMMDD") + '-PATIENT BILLING STATEMENT.pdf';
        document.body.appendChild(a);
        a.click();
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Download Failed. Please add and save patient bill first before downloading.',
        button: 'Okay',
        event: this.closeOnClick
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }));
  }

  printPatientBill() {
    this.patientsDirectory.downloadBillingStatement(this.clinic, this.patientId, this.previousVisitId).subscribe(res => {
      if(res) {
        const url = URL.createObjectURL(res);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = url;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Print Failed. Please add and save patient bill first before printing.',
        button: 'Okay',
        event: this.closeOnClick
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }));
  }

  saveBillingStatement() {
    let data = {
      user_clinic_id: this.clinic,
      patient_id: this.patientId,
      reviewer: this.reviewerDetails ? this.reviewerDetails.id : this.dentistId,
      amount_paid: +this.formGroup.value.amount_paid,
      clinical_notes: this.formGroup.value.clinical_notes,
    }
    if(this.dentistId !== undefined || this.reviewerDetails !== undefined) {
      this.patientsDirectory.submitBillingSummary(this.BILLING_ID, data).subscribe(res => {
        if(res) {
          this.success = true;
          if(this.label == 'Save') {
            this.dialogConfig.data = {
              title: 'Success!',
              message: 'Patient billing statement has been successfully saved. \nE-receipt email has been sent to patient\'s email.',
              button: 'Okay',
              event: this.closeOnClick
            };
            let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => {
              this.redirectTo(this._router.url);
            });
          } else if(this.label == 'Update') {
            if(this.newData) {
              this.dialogConfig.data = {
                title: 'Success!',
                message: 'Patient billing statement has been successfully updated. \nE-receipt email has been sent to patient\'s email.',
                button: 'Okay',
                event: this.closeOnClick
              };
              let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => {
                this.redirectTo(this._router.url);
              });
            } else {
              this.dialogConfig.data = {
                title: 'Success!',
                message: 'Patient billing statement has been successfully updated.',
                button: 'Okay',
                event: this.closeOnClick
              };
              let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => {
                this.redirectTo(this._router.url);
              });
            }
          }
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: err.error.message,
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        this.errorMessage = err.error.errors;
        this.triggerErrors(err.error.errors);
      }));
    } else {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Attending dentist field is required.',
        button: 'Okay',
        event: this.closeOnClick
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      this.errorMessage = {reviewer: 'Attending dentist field is required.'};
      this.triggerErrors({reviewer: 'Attending dentist field is required.'});
    }
  }

  goBack() {
    const tab = this.selectedIndex = this.selectedIndex - 1;
    this.selectedIndexChange.emit(tab);
  }

  cancel() {
    this.redirectTo(this._router.url);
  }

  redirectTo(uri: string) {
    this._router.navigateByUrl('/login', {skipLocationChange: true}).then(()=>
    this._router.navigate([uri]));
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }

  numberTypeValidation(e) {
    return keypressValidateNumberDecimal(e);
  }

  formatNumber(num) {
    return formatNumber(num);
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  setTwoNumberDecimal(el) {
    if(el.target.value !== '') {
      el.target.value = isNaN(parseFloat(el.target.value)) ? parseFloat('0').toFixed(2) : parseFloat(el.target.value).toFixed(2);
    }
  }

  calculateCurrentBalance(e) {
    let SUBTOTAL = this.formGroup.get('subtotal').value ? parseFloat(this.formGroup.get('subtotal').value) : 0;
    let DISCOUNT = this.formGroup.get('discount').value ? parseFloat(this.formGroup.get('discount').value) : 0;
    let DOWNPAYMENT = this.formGroup.get('downpayment').value ? parseFloat(this.formGroup.get('downpayment').value) : 0;
    let AMOUNT_PAID = e.target.value ? parseFloat(e.target.value) : 0;
    let NEW_BALANCE, FLOAT_AMOUNT_PAID;

    FLOAT_AMOUNT_PAID = isNaN(AMOUNT_PAID) ? 0 : AMOUNT_PAID;
    NEW_BALANCE = SUBTOTAL - (DISCOUNT + DOWNPAYMENT) - FLOAT_AMOUNT_PAID;
    this.formGroup.get('balance').setValue(parseFloat(NEW_BALANCE).toFixed(2));
  }
}