import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { keypressToothNumbers } from 'src/app/common/helper';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

@Component({
  selector: 'ark-add-tooth-number',
  templateUrl: './add-tooth-number.component.html',
  styleUrls: ['./add-tooth-number.component.scss']
})
export class AddToothNumberComponent implements OnInit {

  dialogConfig = new MatDialogConfig();
  errorMessage = null;
  formGroup: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddToothNumberComponent>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      tooth_no: ['', [Validators.required]],
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  cancel() {
    this.dialogRef.close(false);
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  add() {
    if(this.formGroup.value.tooth_no == '' || this.formGroup.value.tooth_no == null) {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Tooth number cannot be empty.',
        button: 'Okay',
        event: this.closeOnClick
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    } else {
      this.dialogRef.close(this.formGroup.value.tooth_no);
    }
  }

  onKey(event) {
    const inputValue = event.target.value;
  }

  numberTypeValidation(e) {
    return keypressToothNumbers(e);
  }
}