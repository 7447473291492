import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { InventoryService } from 'src/app/modules/front-desk/service/inventory/inventory.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { openDialog } from 'src/app/modules/shared/components/functional/dialogFunction';

@Component({
  selector: 'ark-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.scss']
})
export class AddItemComponent implements OnInit {
  itemsForm: FormGroup;
  clinic = null;
  noCategory = true;
  isEmpty = false;
  cat_items = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private auth: AuthService,
              public dialogRef: MatDialogRef<AddItemComponent>, private fb: FormBuilder,
              private api: InventoryService, private dialog: MatDialog) {
    this.itemsForm = fb.group({
      category: ['', [Validators.required]],
      brand: ['', []],
      name: ['', [Validators.required]],
      description: ['', []]
    });
    if(this.data !== '') {
      this.isEmpty = false;
    } else {
      this.isEmpty = true;
    }
  }

  ngOnInit() {
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.api.getAPI('category-directory?user_clinic_id=', this.clinic).subscribe(response => {
      if (response.status_code == 200 && response.data.data.length == 0) {
        const dialogConfig = {
          title: 'Attention!',
          message: 'Please add an item category before adding an Item.',
          button: 'Okay',
        };
        this.noCategory = true;
        openDialog(AlertMessageComponent, dialogConfig, this.dialog).afterClosed().subscribe(() => {
          this.dialogRef.close('noCategory');
        });
      } else if (response.status_code == 200) {
        this.noCategory = false;
        this.cat_items = response.data.data;

        if (this.data !== '') {
          const cat = this.cat_items.filter(item => item.name === this.data.category)[0];
          this.itemsForm.setValue({
            category: cat.id,
            brand: this.data.brandname,
            name: this.data.item_name,
            description: this.data.description,
          });
        }
      }
    });
  }

  closeDialog(){
    this.dialogRef.close(false);
  }

  addItem(event) {
    event.preventDefault();
    const itemsPayload = Object.assign({}, this.itemsForm.value);
    const data = {
      name: itemsPayload.name,
      brandname: itemsPayload.brand,
      item_category_id: itemsPayload.category,
      description: itemsPayload.description,
      user_clinic_id: this.clinic
    };
    if (this.data !== '') {
      data['item_id'] = this.data.item_id;
    }
    if (this.itemsForm.valid) {
      this.api.accessAPI('post', 'item-directory', data).subscribe(res => {
        if (res.status_code == 200) {
           const dialogConfig = {
              title: 'Successful!',
              message: this.data !== '' ? 'Item has been successfully updated.' : 'Item has been successfully saved.',
              button: 'Okay',
            };
            openDialog(AlertMessageComponent, dialogConfig, this.dialog).afterClosed().subscribe(() => {
              this.dialogRef.close(true);
            });
        }
      });
    }
  }

}
