import { Component, OnInit, OnChanges, Output, EventEmitter, Input } from '@angular/core';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';

@Component({
  selector: 'ark-previous-visits',
  templateUrl: './previous-visits.component.html',
  styleUrls: ['./previous-visits.component.scss']
})
export class PreviousVisitsComponent implements OnInit, OnChanges {

  @Input('selectedIndex') selectedIndex: number; 
  @Input('newVisitInformation') isNew: number = 0;
  @Output('selectedIndexChange') selectedIndexChange: EventEmitter<any> = new EventEmitter;
  @Output() id: EventEmitter<any> = new EventEmitter;
  @Output() isDisabled: EventEmitter<any> = new EventEmitter;
  patientId = null;
  previousVisits = [];
  isNoResult: boolean = true;
  clinic = null;

  constructor(
    private patientsDirectory: PatientsDirectoryService,
    private route: ActivatedRoute,
    private auth: AuthService
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.route.params.subscribe(param => {
      this.patientId = param.id;
    });
  }

  ngOnInit() {
    this.getPreviousVisits();
  }
  
  ngOnChanges() {
    if(this.isNew == 1) {
      this.getPreviousVisits();
    }
  }

  getPreviousVisits() {
    this.patientsDirectory.getPreviousVisits(this.patientId, this.clinic).subscribe(res => {
      if(res) {
        this.previousVisits = res;
        if(this.previousVisits.length > 0) {
          this.isNoResult = false;
        } else {
          this.isNoResult = true;
        }
      }
    });
  }

  getVisitId(id) {
    this.id.emit(id);
    this.selectedIndexChange.emit(1);
    this.isDisabled.emit(false);
  }

  formatDate(obj) {
    return obj.toString().replace(/\s/g, "T");
  }
}
