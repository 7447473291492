import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/modules/shared/services/http/http.service';
import { getPatient, toothChart, getLatestChart, addNewToothChart, getPatientCharts, patientPhoto, updatePatientPrivateInfo, updatePatientEmergencyContact, updateDentalHistory, patientRadiograph, visitInfo, addBillingDetail, itemInventory, addBillingStatement, patientBill, patientClinicalNotes, patientDentalForm, patientClinicalNotesPdf, patientBilling, patientBillingStatement } from 'src/app/common/api';
import { Observable } from 'rxjs';

@Injectable()
export class PatientsDirectoryService extends HttpService {

  public data: any = [];

  constructor(http: HttpClient, private _router: Router) { 
    super('', http);
  }

  setData(value) {
    this.data.push(value);
  }
  
  getData() {
    return this.data;
  }

  removeData() {
    return this.data = [];
  }

  getPatientInfo(id, patient_id, user_clinic_id) {
    return this.get(getPatient + '/filter/' + id + '?patient_id=' + patient_id + '&user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }

  searchPatient(id, name, user_clinic_id) {
    return this.get(getPatient + '/filter/' + id + '?name=' + name + '&user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }

  getPatientList(id, last_name, user_clinic_id) {
    return this.get(getPatient + '/filter/' + id + '?last_name=' + last_name + '&user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }

  storeChart(data) {
    return this.http.post(toothChart, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  bridgeTooth(data) {
    return this.http.post(toothChart + '/bridge', data).map((dataResponse) => {
      return dataResponse;
    });
  }

  getLatestChart(patient_id, user_clinic_id) {
    return this.get(getLatestChart + '?patient_id=' + patient_id + '&user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }

  addNewChart(data) {
    return this.http.post(addNewToothChart, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  getToothChartHistory(id, patient_id, user_clinic_id, date){
    return this.get(getPatient + '/filter/' + id + '?patient_id=' + patient_id + '&user_clinic_id=' + user_clinic_id + '&date=' + date).map(res => {
      return res['data'];
    });
  }

  getPatientCharts(patient_id, user_clinic_id) {
    return this.get(getPatientCharts + '?patient_id=' + patient_id + '&user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }

  uploadPhoto(id, user_clinic_id, data) {
    return this.http.post(patientPhoto + '/' + id + '?user_clinic_id=' + user_clinic_id, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  editPhoto(id, user_clinic_id, data) {
    return this.http.put(patientPhoto + '/' + id + '?user_clinic_id=' + user_clinic_id, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  downloadPhoto(id, photo_id, user_clinic_id) {
    return this.http.get(patientPhoto + '/download/' + id + '?photo_id=' + photo_id + '&user_clinic_id=' + user_clinic_id, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  bulkDownloadPhotos(patient_id, user_clinic_id) {
    return this.http.get(patientPhoto + '/bulk' + '?patient_id=' + patient_id + '&user_clinic_id=' + user_clinic_id, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')})
    });
  }

  deletePhoto(photo_id, user_clinic_id, data) {
    return this.http.delete(patientPhoto + '/delete/' + photo_id + '?user_clinic_id=' + user_clinic_id, data).map(res => {
      return res['data'];
    });
  }

  updatePatientPrivateInfo(id, user_clinic_id, data) {
    return this.http.put(updatePatientPrivateInfo + '/' + id + '?user_clinic_id=' + user_clinic_id, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  updatePatientEmergencyContact(id, user_clinic_id,  data) {
    return this.http.put(updatePatientEmergencyContact + '/' + id + '?user_clinic_id=' + user_clinic_id, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  updateDentalHistory(id, user_clinic_id,  history) {
    return this.http.put(updateDentalHistory + '/' + id + '?user_clinic_id=' + user_clinic_id, history).map((dataResponse) => {
      return dataResponse;
    });
  }

  updatePatientProfile(id, user_clinic_id, data){
    return this.http.post(getPatient + '/' + id + '?user_clinic_id=' + user_clinic_id, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  uploadRadiograph(id, user_clinic_id, data) {
    return this.http.post(patientRadiograph + '/' + id + '?user_clinic_id=' + user_clinic_id, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  editRadiograph(id, user_clinic_id, data) {
    return this.http.put(patientRadiograph + '/' + id + '?user_clinic_id=' + user_clinic_id, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  downloadRadiograph(id, radiograph_id, user_clinic_id) {
    return this.http.get(patientRadiograph + '/download/' + id + '?radiograph_id=' + radiograph_id + '&user_clinic_id=' + user_clinic_id, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  bulkDownloadRadiograph(patient_id, user_clinic_id) {
    return this.http.get(patientRadiograph + '/bulk' + '?patient_id=' + patient_id + '&user_clinic_id=' + user_clinic_id, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')})
    });
  }

  deleteRadiograph(radiograph_id, user_clinic_id) {
    return this.http.delete(patientRadiograph + '/delete/' + radiograph_id + '?radiograph_id=' + radiograph_id + '&user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }

  addVisitInfo(data, user_clinic_id) {
    return this.http.post(visitInfo + '?user_clinic_id=' + user_clinic_id, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  updateVisitInfo(id, user_clinic_id, data) {
    return this.http.put(visitInfo + '/' + id + '?user_clinic_id=' + user_clinic_id, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  getVisitInfos() {
    return this.get(visitInfo).map(res => {
      return res['data'];
    });
  }

  checkVisitInfo(patient_id, email, mobile_number, birthdate, recall_visit) {
    return this.http.get(visitInfo + '/check' + '?patient_id=' + patient_id + '&email=' + email + '&mobile_number=' + mobile_number + '&birthdate=' + birthdate + '&recall_visit=' + recall_visit).map(res => {
      return res['data'];
    });
  }

  getSpecificVisitInfo(patient_id, visit_info_id, user_clinic_id) {
    return this.http.get(visitInfo + '?patient_id=' + patient_id + '&visit_info_id=' + visit_info_id + '&user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }

  getPreviousVisits(patient_id, user_clinic_id) {
    return this.http.get(visitInfo + '?patient_id=' + patient_id + '&user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }
  getRecallVisits(month, user_clinic_id) {
    return this.http.get(visitInfo + '?month=' + month + '&user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }

  deleteRecallVisit(id, user_clinic_id, month) {
    return this.http.delete(visitInfo + '/' + id  + '?user_clinic_id=' + user_clinic_id + '&month=' + month).map(res => {
      return res['data'];
    });
  }

  addPatientBilling(data) {
    return this.http.post(addBillingDetail, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  getItemDirectoryList(user_clinic_id) {
    return this.get(itemInventory + '?user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }

  addBillingStatement(data, user_clinic_id) {
    return this.http.post(addBillingStatement + '?user_clinic_id=' + user_clinic_id, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  downloadBillingStatement(user_clinic_id, patient_id, visit_info_id) {
    return this.http.get(visitInfo + '-download' + '?user_clinic_id=' + user_clinic_id + '&patient_id=' + patient_id + '&visit_info_id=' + visit_info_id, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }


  updatePatientBill(id, user_clinic_id, data) {
    return this.http.put(patientBill + '/' + id + '?user_clinic_id=' + user_clinic_id, data).map((dataResponse) => {
      return dataResponse;
    })
  }

  deletePatientBill(id, user_clinic_id) {
    return this.http.delete(patientBilling + '/detail/' + id + '?user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }

  getPatientClinicalNotes(clinic_id, patient_id) {
    return this.get(patientClinicalNotes + '?user_clinic_id=' + clinic_id+ '&patient_id=' + patient_id).map(res => {
      return res['data'];
    });
  }
  addPatientClinicalNotes(data) {
    return this.http.post(patientClinicalNotes, data).map(res => {
      return res['data'];
    });
  }

  updatePatientClinicalNotes(id,data) {
    return this.http.put(patientClinicalNotes + id, data).map(res => {
      return res['data'];
    });
  }

  deleteClinicalNote(id, user_clinic_id, patient_id) {
    return this.http.delete(patientClinicalNotes + id + '?user_clinic_id=' + user_clinic_id + '&patient_id=' + patient_id).map(res => {
      return res['data'];
    });
  }
  
  getPatientDentalFormRecords(clinic_id, patient_id) {
    return this.get(patientDentalForm + '?user_clinic_id=' + clinic_id+ '&patient_id=' + patient_id).map(res => {
      return res['data'];
    });
  }
  downloadPatientDentalFormRecords(clinic_id, filename, path) {
    return this.http.get(patientClinicalNotesPdf + "?user_clinic_id=" + clinic_id  + "&filename=" + filename + "&path=" + path, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  deletePatientDentalFormRecord(id, user_clinic_id) {
    return this.http.delete(patientDentalForm + '/' + id + '?user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }

  //New patient billing implementation
  addPatientBill(data) {
    return this.http.post(patientBillingStatement, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  editPatientBill(id, data) {
    return this.http.put(patientBillingStatement + '/detail/' +  id, data).map(res => {
      return res['data'];
    });
  }

  removePatientBill(id) {
    return this.http.delete(patientBillingStatement + '/' + id).map(res => {
      return res['data'];
    });
  }

  getBillingSummary(user_clinic_id, patient_id, id) {
    return this.get(patientBillingStatement + '?user_clinic_id=' + user_clinic_id + '&patient_id=' + patient_id + '&id=' + id).map(res => {
      return res['data'];
    });
  }

  checkPermission(user_clinic_id, patient_id, id) {
    return this.get(patientBillingStatement  + '/permission/' + id +'?user_clinic_id=' + user_clinic_id + '&patient_id=' + patient_id).map(res => {
      return res['data'];
    });
  }

  submitBillingSummary(id, data) {
    return this.http.put(patientBillingStatement + '/final/' +  id, data).map(res => {
      return res['data'];
    });
  }

  addPayment(data) {
    return this.http.post(patientBillingStatement + '/payment', data).map((dataResponse) => {
      return dataResponse;
    });
  }

  editPayment(id, data) {
    return this.http.put(patientBillingStatement + '/payment/' +  id, data).map(res => {
      return res['data'];
    });
  }
}
