import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, Output, EventEmitter, OnChanges, AfterViewChecked } from '@angular/core';
import * as moment from 'moment';
@Component({
  selector: 'ark-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, AfterViewChecked, OnChanges {

  @Input() Config: Object;
  @Input() tableData: any;
  @Output() ClickEvent : EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('contentsContainer') private contentsContainer: ElementRef;
  @ViewChild('contentsTable') private contentsTable: ElementRef;

  HEAD_TITLE = [];
  ELEMENT_DATA = [];
  ALIGN = [];
  OBJ_KEYS = null;
  hasScroll = false;
  ACTION_KEYS = {};
  isEmpty = null;
  loading = true;
  condition = null;
  /* TABLE CONFIGURATION SAMPLE
  {
    titles: ['ID', 'Name', 'Address'],
    data: [
      {
        id : 123,
        name : 'onyn',
        addr : 'cebu'
      },
      {
        id : 321,
        name : 'test',
        addr : 'cebu'
      }
    ],
    keys: ['id', 'name', 'addr'],
    align: ['left', 'left', 'left'],
  }
  END */
  constructor(private cdref: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.HEAD_TITLE = this.Config['titles'];
    this.ELEMENT_DATA = this.tableData;
    this.OBJ_KEYS = this.Config['keys'];
    this.ALIGN = this.Config['align'];
    this.ACTION_KEYS = this.Config['action'];
    this.isEmpty = this.Config['empty'];
    this.loading = false;
    this.condition = this.Config['conditional'];
  }

  ngOnChanges() {
    if (this.tableData) {
      this.ELEMENT_DATA = this.tableData;
      this.OBJ_KEYS = this.Config['keys'];
      this.HEAD_TITLE = this.Config['titles'];
      this.ALIGN = this.Config['align'];
      this.loading = false;
    }
  }

  ngAfterViewChecked() {
    const container = this.contentsContainer.nativeElement.offsetHeight;
    const contents = this.contentsTable.nativeElement.offsetHeight;
    if (container < contents) {
      this.hasScroll = true;
    } else {
      this.hasScroll = false;
    }

    this.cdref.detectChanges();
  }

  action($event) {
    this.ClickEvent.emit($event);
  }

  checkAction(obj, key) {
    if (obj) {
      if (obj[key] !== undefined){
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  formatDate(d) {
    if (d) {
      return moment(d).format('LL');
    }
  }

  getCondition(d){
    if (d) {
      return !!d;
    }
  }

}
