import { Component, OnInit, Output, EventEmitter, Input, OnChanges, ChangeDetectorRef, AfterViewInit, SimpleChanges } from '@angular/core';
import { openDialog } from 'src/app/modules/shared/components/functional/dialogFunction';
import { InventoryService } from 'src/app/modules/front-desk/service/inventory/inventory.service';
import { MatDialog } from '@angular/material';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { AddCheckOutComponent } from './add-check-out/add-check-out.component';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import * as moment from 'moment';
import { ExpensesService } from 'src/app/modules/front-desk/service/expenses/expenses.service';
@Component({
  selector: 'ark-check-out',
  templateUrl: './check-out.component.html',
  styleUrls: ['./check-out.component.scss']
})
export class CheckOutComponent implements OnInit, OnChanges {
  config = {};
  raw_data = [];
  element_data = [];

  isRecorded = false;
  user_id = '';
  clinic = '';
  checkout_id = '';

  orderNumber = '';
  createdAt = '';
  itemName = '';
  brandName = '';

  @Input() orderNum : any = null;
  @Output() checkoutUpdated : EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private api: InventoryService,
     private dialog: MatDialog, 
     private auth: AuthService, 
     private cdref: ChangeDetectorRef,
     private expenses: ExpensesService,
     ) {
    /* TABLE CONFIGURATION */
    this.config = {
      titles: ['Item Name - Brand Name - Description', 'Quantity (pcs)', 'Remarks'],
      data: [],
      keys: ['item_name', 'quantity', 'remark'],
      align: ['left', 'center', 'left'],
      empty: 'No Items Found.'
    };
    /* END */

    this.user_id = this.auth.getAuthUser().id;
    this.clinic = this.auth.getAuthUser().user_clinic.id;
  }

  ngOnInit() {
    this.createdAt = moment().format('LL');
    this.element_data = [];
  }

  ngOnChanges() {
    if (this.orderNum) {
      this.getCheckouts(this.orderNum);
    }
    this.cdref.detectChanges();
  }

  addItems() {
    this.orderNum = null;
    openDialog(AddCheckOutComponent, '', this.dialog).afterClosed().subscribe( trigger => {
      // console.log(trigger);
      // this.raw_data = trigger;
      if (trigger){
        trigger.forEach(item => {
          const index = this.element_data.findIndex(e => e.name == item.data.name);
          if (index == -1) {
            this.element_data.push({
              item_name: item.data.name  +  (item.data.brandname ? ' - ' + item.data.brandname : ' ') +  (item.data.description? ' - ' + item.data.description: ' ') ,
              quantity: item.quantity,
              remark: item.remark,
              id: item.data.id
            });
          } else {
            this.element_data[index].quantity = item.quantity;
            this.element_data[index].remark = item.remark;
          }
        });
      }
    });
  }

  cancelCheckout() {
    this.element_data = [];
  }

  saveCheckout() {
    const data = {
      user_clinic_id: this.clinic,
      user_id: this.user_id,
      items: []
    }

    this.element_data.forEach(item => {
      data.items.push({
        item_id: item.id,
        quantity: item.quantity,
        remark: item.remark
      });
    });
    this.api.accessAPI('post', 'item-inventory-checkout', data).subscribe(res => {
      if (res.status_code == 200) {
        // console.log(res);
        this.orderNumber = res.data.id;
        this.checkout_id = res.data.id;
        this.createdAt = moment(res.data.created_at).format('LL');
        const dialogConfig = {
          title: 'Successful!',
          message: 'You have successfully checked-out the item(s).',
          button: 'Okay',
        };
        openDialog(AlertMessageComponent, dialogConfig, this.dialog).afterClosed().subscribe(() => {
          this.isRecorded = true;
          this.checkoutUpdated.emit(true);
        });
      }
    });
  }

  getCheckouts(id) {
    const filter = 'item-inventory-checkout?user_clinic_id=' + this.clinic + '&id=' + id;
    this.api.getAPI(filter, '').subscribe(response => {
      if (response.status_code == 200) {
        this.isRecorded = true;
        this.checkout_id = response.data.result.id;
        this.element_data = response.data.result['item_inventory_log_details'];
        this.orderNumber = response.data.result.id;
        this.createdAt = moment(response.data.result.created_at).format('LL');
        response.data.result['item_inventory_log_details'].forEach(element => {
          this.itemName = element['item_name'];
          this.brandName = element['item_brandname'];
          element['item_name'] = element['item_name'] + (element['item_brandname'] ? " - " + element['item_brandname'] : '') + (element['item_description'] ? " - " + element['item_description'] : '');
        });
      }
    });
  }

  addNew() {
    this.isRecorded = false;
    this.orderNumber = '';
    this.createdAt = moment().format('LL');
    this.cancelCheckout();
    this.addItems();
  }

  downloadContents(){
    if(this.checkout_id){
      this.expenses.downloadInventoryCheckout(this.clinic, this.checkout_id).subscribe(res => {
        if(res) {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(res);
          a.download = name;
          a.download = moment().format("YYYYMMDD") + '-' + this.itemName + '-' + this.brandName + '.pdf';
          document.body.appendChild(a);
          a.click();
        }
      });
    }
  }

}
