/**
 * Tooth chart status
 * @param status 
 */
export function mapToothStatus(status) {
  let mapStatus = '';

  switch(status) {
    case 'ok' :
      mapStatus = '✓';
      break;
    case 'missing' :
      mapStatus = 'M';
      break;
    case 'root_fragment' :
      mapStatus = 'RF';
      break;
    case 'implant' :
      mapStatus = '';
      break;
    case 'impacted' :
      mapStatus = 'IMP';
      break;
    case 'pfm' :
      mapStatus = 'PFM';
      break;
    case 'ceramic' :
      mapStatus = 'CER';
      break;
    case 'gold' :
      mapStatus = 'GC';
      break;
    case 'metal' :
      mapStatus = 'MC';
      break;
    case 'ceramic-inlay' :
      mapStatus = 'CER I/O';
      break;
    case 'gold-inlay' :
      mapStatus = 'G I/O';
      break;
    case 'metal-inlay' :
      mapStatus = 'M I/O';
      break;
  }
  return mapStatus;
} 

/**
 * Number validation on input fields
 */  
export function keypressValidateNumber(e) {
  const pattern = new RegExp(/^\d*\.?\d{0,2}$/g);
  return pattern.test(e.key);
}

/**
 * Number only validation on input fields
 */  
export function keypressValidateNumberOnly(e) {
  const pattern = new RegExp(/^[0-9]*$/g);
  return pattern.test(e.key);
}

/**
   * Number validation on input fields
   */  
  export function keypressValidateNumberDecimal(e) {
    const pattern = new RegExp(/^\d*\.?\d{0,2}$/g);
    const rgx = /^[0-9]*\.?[0-9]*$/;
    if ((e.key == '.' && e.target.value.includes('.')) || !pattern.test(e.key)) {
      return false;
    }
    return (e.target.value.match(rgx)) ? true : false;
  }

/**
 * Number formating (adding thousand separator)
 */
export function formatNumber(num) {
  let val = Number(num);
  return val.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

/**
 * Currency formatting
 */
export function currencyFormat(num) {
  let val = Number(num);
  return val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

/**
 * Number validation on tooth numbers
 */  
export function keypressToothNumbers(e) {
  const pattern = new RegExp(/^[0-9, ]+$/g);
  return pattern.test(e.key);
}

export const titleCase = (str: any) => {
  // Step 1. Lowercase the string
  str = str.toLowerCase() // str = "i'm a little tea pot";

  // Step 2. Split the string into an array of strings
  .split(' ') // str = ["i'm", "a", "little", "tea", "pot"];

  // Step 3. Map over the array
  .map(function(word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
    /* Map process
    1st word: "i'm"    => (word.charAt(0).toUpperCase() + word.slice(1));
                          "i'm".charAt(0).toUpperCase() + "i'm".slice(1);
                                "I"                     +     "'m";
                          return "I'm";
    2nd word: "a"      => (word.charAt(0).toUpperCase() + word.slice(1));
                          "a".charAt(0).toUpperCase()   + "".slice(1);
                                "A"                     +     "";
                          return "A";
    3rd word: "little" => (word.charAt(0).toUpperCase()    + word.slice(1));
                          "little".charAt(0).toUpperCase() + "little".slice(1);
                                "L"                        +     "ittle";
                          return "Little";
    4th word: "tea"    => (word.charAt(0).toUpperCase() + word.slice(1));
                          "tea".charAt(0).toUpperCase() + "tea".slice(1);
                                "T"                     +     "ea";
                          return "Tea";
    5th word: "pot"    => (word.charAt(0).toUpperCase() + word.slice(1));
                          "pot".charAt(0).toUpperCase() + "pot".slice(1);
                                "P"                     +     "ot";
                          return "Pot";
    End of the map() method */
  });

  // Step 4. Return the output
  return str.join(' '); // ["I'm", "A", "Little", "Tea", "Pot"].join(' ') => "I'm A Little Tea Pot"
};