import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { PatientService } from '../../../service/patient/patient.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import * as moment from 'moment';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { Router } from '@angular/router';
import { DentistShareComponent } from 'src/app/modules/shared/components/dentist-share/dentist-share.component';
import { MatDialog, MatDialogConfig } from '@angular/material';

@Component({
  selector: 'ark-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit, OnChanges {

  @Input() dentist_id: any = null; 
  @Input() from: any = null; 
  @Input() to: any = null; 
  @Input() search: any = null; 
  billings = [];
  clinic = null;
  start = '';
  end = '';
  isNoResult: boolean = true;
  isDentist: boolean = false;
  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  constructor(
    private auth: AuthService,
    private billing: PatientService,
    private patient: PatientsDirectoryService,
    private _router: Router,
    public dialog: MatDialog
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
  }

  ngOnInit() {
    
  }

  ngOnChanges() {
    if(this.dentist_id || this.from || this.to || this.search) {
      this.start =  moment(this.from).format("YYYY-MM-DD");
      this.end =  moment(this.to).format("YYYY-MM-DD");
      this.getList();
    }
  }

  getList() {
    this.billing.getPatientBillingList(this.clinic, this.dentist_id, this.start, this.end, this.search).subscribe(res => {
      if(res) {
        this.billings = res.patient_info.data;
        if(this.billings.length > 0) {
          this.isNoResult = false;
        } else {
          this.isNoResult = true;
        }

        if(this.dentist_id) {
          this.isDentist = true;
        } else {
          this.isDentist = false;
        }
      }
    });
  }

  openPatientDetails(res) {
    this.patient.removeData();
    this._router.navigate(['/patient-details', res.id]);
    const name = {
      first_name: res.first_name,
      last_name: res.last_name,
      middle_name: res.middle_name ? res.middle_name : '',
      email: res.email,
      mobile_number: res.mobile_number,
      birthdate: res.birthdate ? moment(res.birthdate).format("YYYY-MM-DD") : '',
      allergy: res.allergies
    };
    const billing = {
      id: res.visit_info_id,
      tab: 1,
      disabled: false,
    }
    localStorage.setItem('patientName', JSON.stringify(name));
    localStorage.setItem('visitInfo', JSON.stringify(billing));
  }

  openDentistShare(res, option) {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogConfig.data = { 
      option: option,
      data: res,
      dentistId: this.dentist_id,
      from: this.start,
      to: this.end
    }
    this.dialogRef = this.dialog.open(DentistShareComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(res => {
      if(res) {
        this.getList();
      }
    });
  }
}
