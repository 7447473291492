import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { FinanceService } from '../../../services/finance/finance.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'ark-monthly-income-statement',
  templateUrl: './monthly-income-statement.component.html',
  styleUrls: ['./monthly-income-statement.component.scss']
})
export class MonthlyIncomeStatementComponent implements OnInit {

  dialogConfig = new MatDialogConfig();
  incomeStatement = [];
  year = null;
  month = null;
  today = new Date();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MonthlyIncomeStatementComponent>,
    public dialog: MatDialog,
    private finance: FinanceService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.incomeStatement.push(this.data.data);
    this.year = this.data.year;
    this.month = this.data.data.id;
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  close() {
    this.dialogRef.close();
  }

  generatePDF() {
    const clinic = this.auth.getAuthUser().user_clinic.id;
    this.finance.downloadMonthlySummary(clinic, this.month, this.year).subscribe(res => {
      if(res) {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(res);
        a.download = moment(this.today).format("YYYYMMDD") + '-MONTHY INCOME STATEMENT (' + (moment(this.month, 'M').format('MMMM').toUpperCase()) + ' ' + this.year + ').pdf';
        document.body.appendChild(a);
        a.click();
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Exporting of PDF failed.',
        button: 'Okay',
        event: this.close
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }));
  }

  sendEmail() {
    const clinic = this.auth.getAuthUser().user_clinic.id;
    this.finance.emailMonthlySummary(clinic, this.month, this.year).subscribe(res => {
      if(res) {
        this.dialogRef.close(res);
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Income statement has been emailed successfully.',
          button: 'Okay',
          event: this.close
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    });
  }
}