import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/modules/shared/services/http/http.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { userClinicMember, payrollSettings, payroll, payrollRecord } from 'src/app/common/api';

@Injectable()
export class EmployeesService extends HttpService {

  constructor(http: HttpClient, private _router: Router) {
    super('',http);
  }

  getEmployees(id, filter) {
    return this.get(userClinicMember + '/dentist/list' + '?user_clinic_id=' + id + '&filter=' + filter + '&status=active').map(res => {
      return res['data'];
    });
  }

  getPayroll(user_clinic_id, id, year, month, period) {
    return this.get(payroll + '?user_clinic_id=' + user_clinic_id + '&employee_id=' + id + '&year=' + year + '&month=' + month + '&period=' + period).map(res => {
      return res['data'];
    });
  }

  savePayroll(data) {
    return this.http.post(payroll, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  getPayrollSettings(user_clinic_id, id) {
    return this.get(payrollSettings + '?user_clinic_id=' + user_clinic_id + '&employee_id=' + id).map(res => {
      return res['data'];
    });
  }

  savePayrollSettings(data) {
    return this.http.post(payrollSettings, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  getPayrollRecord(user_clinic_id, id, month, year, period) {
    return this.get(payrollRecord + '?user_clinic_id=' + user_clinic_id + '&employee_id=' + id + '&month=' + month + '&year=' + year + '&period=' + period).map(res => {
      return res['data'];
    });
  }

  savePayrollRecord(data) {
    return this.http.post(payrollRecord, data).map((dataResponse) => {
      return dataResponse;
    });
  }
}