import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import * as moment from 'moment';
import { observable, Subscription } from 'rxjs';

@Component({
  selector: 'ark-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {

  keyword = null;
  clinic = null;
  loading : boolean = false;
  isNoResult : boolean = true;
  patients = [];
  timeout: any;
  observable: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private patient: PatientsDirectoryService,
    private _router: Router
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.route.queryParams.subscribe(param => {
      this.keyword = param['keyword'];
      this.searchPatient();
    });
  }

  ngOnInit() {
    
  }

  searchPatient() {
    clearTimeout(this.timeout);
    if (this.observable.length > 0){
      this.observable[0].unsubscribe();
    }
    this.timeout = setTimeout(() => {
      const obs_data = this.patient.searchPatient(this.clinic, this.keyword, this.clinic).subscribe(res => {
        if(res) {
          this.patients = res.data;

          if(this.patients.length > 0) {
            this.isNoResult = false;
          } else {
            this.isNoResult = true;
          }
        }
      });
      this.observable[0] = obs_data;
    }, 1500);
  }

  openPatientDetails(res) {
    this.patient.removeData();
    this._router.navigate(['/patient-details', res.id]);
    const name = {
      first_name: res.first_name,
      last_name: res.last_name,
      middle_name: res.middle_name ? res.middle_name : '',
      email: res.email,
      mobile_number: res.mobile_number,
      birthdate: moment(res.patient_private_information.birthdate).format("YYYY-MM-DD"),
      allergy: res.allergies
    };
    localStorage.setItem('patientName', JSON.stringify(name));
  }
}
