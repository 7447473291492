import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertMessageComponent } from '../alert-message/alert-message.component';
import { PatientService } from 'src/app/modules/front-desk/service/patient/patient.service';
import { keypressValidateNumber } from 'src/app/common/helper';
import * as moment from 'moment';

@Component({
  selector: 'ark-dentist-share',
  templateUrl: './dentist-share.component.html',
  styleUrls: ['./dentist-share.component.scss']
})
export class DentistShareComponent implements OnInit {

  formGroup: FormGroup;
  dialogConfig = new MatDialogConfig();
  clinic = null;
  doctorFee = null;
  signedDate = null;
  errorMessage = null;
  signatureImage = null;
  isView: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DentistShareComponent>,
    public dialog: MatDialog,
    private frontDesk: PatientService,
    private formBuilder: FormBuilder,
    private auth: AuthService,
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic;
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      amount_received: [''],
      date: [''],
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.doctorFee = this.data.data.doctors_fee;

    if(this.data.option == 'view') {
      this.isView = true;
      this.signatureImage = this.data.data.dentist_signature;
      this.formGroup.get('amount_received').setValue(parseFloat(this.data.data.amount_received).toFixed(2));
      this.formGroup.get('date').setValue(new Date(this.data.data.date)); 
      this.formGroup.controls['amount_received'].disable(); 
      this.formGroup.controls['date'].disable();
    } else {
      this.isView = false;
    }
  }

  getDate(date) {
    this.signedDate = date.value;
  }
    
  closeOnClick() {
    this.dialogRef.close(false);
  }

  saveSignature(data) {
    this.signatureImage = data;
    this.formGroup.get('date').setValue(new Date());
  }

  savePayment() {
    let data = {
      user_clinic_id: this.clinic.id,
      dentist_id: this.data.dentistId,
      visit_info_id: this.data.data.visit_info_id,
      doctors_fee: this.data.data.doctors_fee,
      amount_received: this.formGroup.value.amount_received,
      dentist_signature: this.signatureImage,
      signed_date: moment(this.formGroup.value.date).format("YYYY-MM-DD"),
      from: this.data.from,
      to: this.data.to,
      patient_name: (this.data.data.first_name ? this.data.data.first_name : '') + ' ' + (this.data.data.middle_name ? this.data.data.middle_name + ' ' + (this.data.data.last_name ? this.data.data.last_name : '') : (this.data.data.last_name ? this.data.data.last_name : ''))

    }
    const blank = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAAB9CAYAAABUHpEuAAAFUUlEQVR4Xu3VAQ0AAAjDMPBvGh0sxcHLk+84AgQIECBA4L3Avk8gAAECBAgQIDAGXQkIECBAgEBAwKAHnigCAQIECBAw6DpAgAABAgQCAgY98EQRCBAgQICAQdcBAgQIECAQEDDogSeKQIAAAQIEDLoOECBAgACBgIBBDzxRBAIECBAgYNB1gAABAgQIBAQMeuCJIhAgQIAAAYOuAwQIECBAICBg0ANPFIEAAQIECBh0HSBAgAABAgEBgx54oggECBAgQMCg6wABAgQIEAgIGPTAE0UgQIAAAQIGXQcIECBAgEBAwKAHnigCAQIECBAw6DpAgAABAgQCAgY98EQRCBAgQICAQdcBAgQIECAQEDDogSeKQIAAAQIEDLoOECBAgACBgIBBDzxRBAIECBAgYNB1gAABAgQIBAQMeuCJIhAgQIAAAYOuAwQIECBAICBg0ANPFIEAAQIECBh0HSBAgAABAgEBgx54oggECBAgQMCg6wABAgQIEAgIGPTAE0UgQIAAAQIGXQcIECBAgEBAwKAHnigCAQIECBAw6DpAgAABAgQCAgY98EQRCBAgQICAQdcBAgQIECAQEDDogSeKQIAAAQIEDLoOECBAgACBgIBBDzxRBAIECBAgYNB1gAABAgQIBAQMeuCJIhAgQIAAAYOuAwQIECBAICBg0ANPFIEAAQIECBh0HSBAgAABAgEBgx54oggECBAgQMCg6wABAgQIEAgIGPTAE0UgQIAAAQIGXQcIECBAgEBAwKAHnigCAQIECBAw6DpAgAABAgQCAgY98EQRCBAgQICAQdcBAgQIECAQEDDogSeKQIAAAQIEDLoOECBAgACBgIBBDzxRBAIECBAgYNB1gAABAgQIBAQMeuCJIhAgQIAAAYOuAwQIECBAICBg0ANPFIEAAQIECBh0HSBAgAABAgEBgx54oggECBAgQMCg6wABAgQIEAgIGPTAE0UgQIAAAQIGXQcIECBAgEBAwKAHnigCAQIECBAw6DpAgAABAgQCAgY98EQRCBAgQICAQdcBAgQIECAQEDDogSeKQIAAAQIEDLoOECBAgACBgIBBDzxRBAIECBAgYNB1gAABAgQIBAQMeuCJIhAgQIAAAYOuAwQIECBAICBg0ANPFIEAAQIECBh0HSBAgAABAgEBgx54oggECBAgQMCg6wABAgQIEAgIGPTAE0UgQIAAAQIGXQcIECBAgEBAwKAHnigCAQIECBAw6DpAgAABAgQCAgY98EQRCBAgQICAQdcBAgQIECAQEDDogSeKQIAAAQIEDLoOECBAgACBgIBBDzxRBAIECBAgYNB1gAABAgQIBAQMeuCJIhAgQIAAAYOuAwQIECBAICBg0ANPFIEAAQIECBh0HSBAgAABAgEBgx54oggECBAgQMCg6wABAgQIEAgIGPTAE0UgQIAAAQIGXQcIECBAgEBAwKAHnigCAQIECBAw6DpAgAABAgQCAgY98EQRCBAgQICAQdcBAgQIECAQEDDogSeKQIAAAQIEDLoOECBAgACBgIBBDzxRBAIECBAgYNB1gAABAgQIBAQMeuCJIhAgQIAAAYOuAwQIECBAICBg0ANPFIEAAQIECBh0HSBAgAABAgEBgx54oggECBAgQMCg6wABAgQIEAgIGPTAE0UgQIAAAQIGXQcIECBAgEBAwKAHnigCAQIECBAw6DpAgAABAgQCAgY98EQRCBAgQICAQdcBAgQIECAQEDDogSeKQIAAAQIEDLoOECBAgACBgIBBDzxRBAIECBAgYNB1gAABAgQIBAQMeuCJIhAgQIAAAYOuAwQIECBAICBwUYwAfi0kl54AAAAASUVORK5CYII='
    if(this.signatureImage == blank) {
      if(this.formGroup.value.amount_received == '' || this.formGroup.value.amount_received == null) {
        this.dialogConfig.data = {
          title: 'Sorry!',
          message: 'The given data was invalid.',
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        this.errorMessage = {amount_received: 'The amount received field is required.'};
        this.triggerErrors({amount_received: 'The amount received field is required.'});
      } else {
        this.dialogConfig.data = {
          title: 'Sorry!',
          message: 'Doctor\'s signature is required.',
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    } else {
      this.frontDesk.saveDentistShare(data).subscribe(res => {
        if(res) {
          this.dialogRef.close(res);
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Doctor\'s share has been successfully submitted.',
            button: 'Okay',
            event: this.closeOnClick
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Sorry!',
          message: err.error.message,
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        this.errorMessage = err.error.errors;
        this.triggerErrors(err.error.errors);
      }));
    }
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }

  setTwoNumberDecimal(el) {
    if(el.target.value !== '') {
      el.target.value = parseFloat(el.target.value).toFixed(2);
    }
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }
}
