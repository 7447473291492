import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig, MatAutocomplete } from '@angular/material';
import { keypressValidateNumber } from 'src/app/common/helper';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard/dashboard.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

export interface Dentist {
  id: number;
  firstName: string;
  lastName: string;
}

export interface Item {
  id: number;
  name: string;
  brandname: string;
  description: string;
}

export interface Treatment {
  id: number;
  name: string;
}

@Component({
  selector: 'ark-add-patient-billing',
  templateUrl: './add-patient-billing.component.html',
  styleUrls: ['./add-patient-billing.component.scss']
})

export class AddPatientBillingComponent implements OnInit {

  dialogConfig = new MatDialogConfig();
  clinic: any;
  itemFee: number = 0;
  treatmentFee: number = 0;
  totalBilling: number = 0;
  amount_due: number = 0;

  inventories: Item[] = [];
  dentists: Dentist[] = [];
  treatments: Treatment[] = [];
  @ViewChild('inventory') matAutocompleteInventory: MatAutocomplete;
  @ViewChild('dentist') matAutocompleteDentist: MatAutocomplete;

  items = [];
  procedures = [];
  searchTreatment = [];
  searchDentist = [];
  searchItem = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddPatientBillingComponent>,
    public dialog: MatDialog,
    private auth: AuthService,
    private patients: PatientsDirectoryService,
    private dashboard: DashboardService,
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.patients.getItemDirectoryList(this.clinic).subscribe(res => {
      res.forEach(element => {
        let obj: Item = {
          id: element.items.id, 
          name: element.items.name,
          brandname: element.items.brandname,
          description: element.items.description
        };
        this.inventories.push(obj);
      });
    });
    this.dashboard.getDentistList(this.clinic).subscribe(res => {
      res.forEach(element => {
        let obj: Dentist = {
          id: element.id, 
          firstName: element.first_name,
          lastName: element.last_name
        };
        this.dentists.push(obj);
      });
    });
    this.dashboard.getProcedures(this.clinic).subscribe(res => {
      res.forEach(element => {
        let obj: Treatment = {
          id: element.id, 
          name: element.title
        };
        this.treatments.push(obj);
      });
    });
  }

  ngOnInit() {
    this.initProcedure();
    this.searchTreatment = this._treatments('');
    this.searchDentist = this._dentists('');
    this.searchItem = this._items('');
  }

  private _treatments(name: string): Treatment[] {
    const filterValue = name.toLowerCase();
    return this.treatments.filter(option => option.name.toLowerCase().indexOf(filterValue) > -1);
  }

  private _dentists(name: string): Dentist[] {
    const filterValue = name.toLowerCase();
    return this.dentists.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1);
  }

  private _items(name: string): Item[] {
    const filterValue = name.toLowerCase();
    return this.inventories.filter(option => option.name.toLowerCase().indexOf(filterValue) > -1);
  }

  filterTreatment(name: string) {
    this.searchTreatment = this._treatments(name);
  }

  filterDentist(name: string) {
    this.searchDentist = this._dentists(name);
  }

  filterItem(name: string) {
    this.searchItem = this._items(name);
  }

  displayDentist(dentist?: any) : string | undefined {
    return this.matAutocompleteDentist.options.filter(x => x.value === dentist).map(x => x.viewValue)[0] ? this.matAutocompleteDentist.options.filter(x => x.value === dentist).map(x => x.viewValue)[0] : dentist;
  }

  displayItem(item?: any) : string | undefined {
    return this.matAutocompleteInventory.options.filter(x => x.value === item).map(x => x.viewValue)[0] ? this.matAutocompleteInventory.options.filter(x => x.value === item).map(x => x.viewValue)[0] : item;
  }

  initItem() {
    return this.items[0] = [{
      item_id: '',
      item_qty: '',
      item_amount: '',
      item_total: ''
    }];
  }
  
  initProcedure() {
    this.procedures = [{
      tooth_no: '',
      procedure: '',
      dentist_id: '',
      doctors_fee: '',
      procedure_fee: '',
      total_fees: '',
      sub_total: '',
      items: this.initItem(),
    }];
  }

  addProcedure() {
    this.procedures.push({
      tooth_no: '',
      procedure: '',
      dentist_id: '',
      doctors_fee: '',
      procedure_fee: '',
      total_fees: '',
      sub_total: '',
      items: this.initItem(),
    });
  }

  addItem(i) {
    this.procedures[i].items.push({
      item_id: '',
      item_qty: '',
      item_amount: '',
      item_total: ''
    });
  }

  removeProcedure(index) {
    this.procedures.splice(index, 1);

    /**
     * 
     * Update total amount
     */
    this.totalBilling = 0;
    this.procedures.forEach(element => {
      this.totalBilling += element.sub_total;
    });
    this.amount_due = +this.totalBilling
  }

  removeItem(e) {
    this.procedures[e[0]].items.splice([e[1]], 1);

    /**
     * 
     * Update subtotal and total amount
     */
    this.itemFee = 0;
    this.treatmentFee = 0;
    this.totalBilling = 0;
    
    const doctor = this.procedures[e[0]].doctors_fee;
    const procedure = this.procedures[e[0]].procedure_fee;
    this.treatmentFee = +doctor + +procedure;
    this.procedures[e[0]].total_fees = this.treatmentFee;

    if(e.length == 2) {
      this.procedures[e[0]].items.forEach(element => {
        this.itemFee += element.item_total;
      });
      this.procedures[e[0]].sub_total = +this.treatmentFee + +this.itemFee;
    } else {
      this.procedures[e[0]].sub_total = +this.treatmentFee;
    }
    
    this.procedures.forEach(element => {
      this.totalBilling += element.sub_total;
    });
    this.amount_due = +this.totalBilling
  }

  getTotalBill(e) {
    this.itemFee = 0;
    this.treatmentFee = 0;
    this.totalBilling = 0;

    /** Get sum of doctor fee and treatment fee */
    const doctor = this.procedures[e[0]].doctors_fee;
    const procedure = this.procedures[e[0]].procedure_fee;
    this.treatmentFee = +doctor + +procedure;
    this.procedures[e[0]].total_fees = this.treatmentFee;

    /** Get product of item used */
    if(e.length == 2) {
      const quantity = this.procedures[e[0]].items[e[1]].item_qty;
      const amount = this.procedures[e[0]].items[e[1]].item_amount;
      const product = +quantity * +amount;
      this.procedures[e[0]].items[e[1]].item_total = product;
    }

    /** Set value for billing sub total */
    if(e.length == 2) {
      this.procedures[e[0]].items.forEach(element => {
        this.itemFee += element.item_total;
      });
      this.procedures[e[0]].sub_total = +this.treatmentFee + +this.itemFee;
    } else {
      this.procedures[e[0]].items.forEach(element => {
        this.itemFee += element.item_total;
      });
      this.procedures[e[0]].sub_total = +this.treatmentFee + +this.itemFee;
    }
    
    /** Set value for billing grand total */
    this.procedures.forEach(element => {
      this.totalBilling += element.sub_total;
    });
    this.amount_due = +this.totalBilling
  }

  close() {
    this.dialogRef.close();
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }

  getUnlistedTreatment(event, i) {
    this.procedures[i].procedure = event;
  }

  submitBilling() {
    let data = {
      patient_id: this.data.id,
      visit_info_id: this.data.visitId,
      user_clinic_id: this.clinic,
      procedures: this.procedures,
    }
    this.patients.addPatientBilling(data).subscribe(res => {
      if(res) {
        this.dialogRef.close(res);
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Patient bill has been successfully added.',
          button: 'Okay',
          event: this.close
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Sorry!',
        message: err.error.message,
        button: 'Okay',
        event: this.close
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }));
  }
}