import { Component, OnInit, OnChanges, ViewChild } from '@angular/core';
import { PatientsDirectoryService } from '../../services/patients-directory/patients-directory.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { EditPhotoComponent } from './edit/edit-photo/edit-photo.component';
import { ConfirmDeletionComponent } from 'src/app/modules/shared/components/confirm-deletion/confirm-deletion.component';
import { ViewPhotoComponent } from './view/view-photo/view-photo.component';
import { GalleryItem, ImageItem, Gallery, ImageSize, ThumbnailsPosition, GalleryConfig } from '@ngx-gallery/core';

@Component({
  selector: 'ark-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class PhotosComponent implements OnInit, OnChanges {

  @ViewChild('file') file: any; 
  
  patient = '';
  patientFirstname = '';
  patientId = '';
  patientPhotos = [];
  photoId = '';
  isNoResult: boolean = true;
  loading: boolean = false;
  success = false;
  myFiles: string [] = [];
  fileData = null;
  clinic = null;
  items: GalleryItem[];

  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  constructor(
    private patientsDirectory: PatientsDirectoryService,
    private route: ActivatedRoute,
    private auth: AuthService,
    public dialog: MatDialog,
    public gallery: Gallery
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.route.params.subscribe(param => {
      this.patientId = param.id
    });
    this.viewPhotos();
  }

  ngOnInit() {

  }

  ngOnChanges() {
    this.viewPhotos();
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  viewPhotos() {
    const config: GalleryConfig = {
      loadingMode: "indeterminate"
    };

    this.patientsDirectory.getPatientInfo(this.clinic, this.patientId, this.clinic).subscribe(response =>{
      this.patient = response['data'][0].last_name;
      this.patientFirstname = response['data'][0].first_name;
      this.patientPhotos = response['data'][0].photos;
      if(this.patientPhotos.length > 0) {
        this.isNoResult = false;
        /** Lightbox */
        this.items = this.patientPhotos.map(item =>
          new ImageItem({ src: item.photo_url, thumb: item.photo_url , id: item.id, photo_url: item.photo_url, name: item.name})
        );
        // Load items into the lightbox
        this.gallery.ref().setConfig(config);
        this.gallery.ref().load(this.items);
      } else {
        this.isNoResult = true;
      }
    });
  }

  upload(event) {
    this.fileData = <File>event.target.files;
    const file = this.fileData;
    const formData = new FormData();
    if(file.length > 0) {
      for(let i= 0; i < file.length; i++){
        formData.append('photos[]', file.item(i), file.item(i).name);
      }
      this.patientsDirectory.uploadPhoto(this.patientId, this.clinic, formData).subscribe(res => {
        if(res) {
          this.success = true;
          this.viewPhotos();
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Photos has been successfully uploaded.',
            button: 'Okay',
            event: this.closeOnClick
          };
          this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Sorry!',
          message: err.error.errors['photos.0'][0],
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }));
    }
  }

  editPhoto(id, url, name) {
    this.dialogConfig.data = {
      id: id,
      patient_id: this.patientId,
      url: url,
      name: name,
      clinic_id: this.clinic
    };
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogRef = this.dialog.open(EditPhotoComponent, this.dialogConfig).afterClosed().subscribe(response => {
      if(response) {
        this.success = true;
        this.viewPhotos();
      }
    });
  }

  delete(id) {
    this.dialogConfig.data = {
      subject: 'delete this photo'
    };
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    let dialogRef = this.dialog.open(ConfirmDeletionComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
      if(trigger) {
         let data = { 
          user_clinic_id: this.auth.getAuthUser().user_clinic.id,
          patient_id: this.patientId
        }
        this.patientsDirectory.deletePhoto(id, this.clinic, data).subscribe(res => {
          if(res) {
            this.success = true;
            this.viewPhotos();
            this.dialogConfig.data = {
              title: 'Success!',
              message: 'Photo has been successfully deleted.',
              button: 'Okay',
              event: this.closeOnClick
            };
            this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
          }
        });
      }
    });
  }

  download(id, name) {
    this.patientsDirectory.downloadPhoto(id, id, this.clinic).subscribe(res => {
      if(res) {
        const str = res.type;
        const n = str.lastIndexOf('/');
        const ext = str.substring(n + 1);

        const a = document.createElement('a');
        a.href = URL.createObjectURL(res);
        a.download = name + '.' + ext;
        document.body.appendChild(a);
        a.click();
      }
    });
  }

  downloadAll() {
    this.patientsDirectory.bulkDownloadPhotos(this.patientId, this.clinic).subscribe(res => {
      if(res) {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(res);
        a.download = this.patient + '_' + this.patientFirstname + '_photos' + '.zip';
        document.body.appendChild(a);
        a.click();
        
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(URL.createObjectURL(res));
          a.remove();
        }, 500);
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Bulk download failed.',
        button: 'Okay',
        event: this.closeOnClick
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }));
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  openImage(url) {
    this.dialogConfig.data = {
      url: url
    };
    this.dialogConfig.disableClose = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogRef = this.dialog.open(ViewPhotoComponent, this.dialogConfig);
  }

}
