import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

@Component({
  selector: 'ark-edit-photo',
  templateUrl: './edit-photo.component.html',
  styleUrls: ['./edit-photo.component.scss']
})
export class EditPhotoComponent implements OnInit {

  formGroup: FormGroup
  errorMessage = null;
  success: boolean = false;
  detail: any;

  dialogConfig = new MatDialogConfig();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditPhotoComponent>,
    private patientsDirectory: PatientsDirectoryService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.detail = this.data;
    this.formGroup = this.formBuilder.group({
      name: ['', [Validators.required]]
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.formGroup.get('name').setValue(this.detail.name);
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }

  updatePhoto() {
    let data = {
      patient_id: this.detail.patient_id,
      name: this.formGroup.controls['name'].value
    }
    this.patientsDirectory.editPhoto(this.detail.id, this.detail.clinic_id, data).subscribe(res => {
      if(res) {
        this.success = true;
        this.dialogRef.close(res);
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Photo has been renamed sucessfully.',
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    });
  }
}
