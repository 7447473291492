import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ExpensesService } from 'src/app/modules/front-desk/service/expenses/expenses.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

@Component({
  selector: 'ark-edit-operational-expenses',
  templateUrl: './edit-operational-expenses.component.html',
  styleUrls: ['./edit-operational-expenses.component.scss']
})
export class EditOperationalExpensesComponent implements OnInit {

  formGroup: FormGroup;
  dialogConfig = new MatDialogConfig();
  errorMessage = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private expenses: ExpensesService,
    public dialogRef: MatDialogRef<EditOperationalExpensesComponent>,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      name: ['', [Validators.required]]
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.formGroup.get('name').setValue(this.data.name);
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  save() {
    let data = {
      id: this.data.id,
      user_clinic_id: this.data.clinic,
      name: this.formGroup.value.name,
      month: this.data.month,
      year: this.data.year
    }
    this.expenses.updateOperationalExpenses(data).subscribe(res => {
      if(res) {
        this.dialogRef.close(res);
        this.dialogRef.close(res);
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Operational expense has been successfully updated.',
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Sorry!',
        message: err.error.message,
        button: 'Okay',
        event: this.closeOnClick
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      this.errorMessage = err.error.errors;
      this.triggerErrors(err.error.errors);
    }));
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }
}
