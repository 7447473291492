import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { InventoryService } from 'src/app/modules/front-desk/service/inventory/inventory.service';
import * as moment from 'moment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { openDialog } from 'src/app/modules/shared/components/functional/dialogFunction';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

@Component({
  selector: 'ark-inventory-control',
  templateUrl: './inventory-control.component.html',
  styleUrls: ['./inventory-control.component.scss']
})
export class InventoryControlComponent implements OnInit {
  clinic = null;
  user_id = null;

  cat_items = [];
  ELEMENT_DATA = [];

  phyCount = {};
  remarks = {};
  notMatch = {};
  physical_count_array = {};
  remark_count_array = {};

  hasCategory = false;
  hasScroll = false;
  formGroup: FormGroup;
  today = moment().format('YYYY-MM-DD');
  hasError = false;
  historyDates = [];
  hasHistory = false;
  ctrId = null;
  ctr = 0;

  errMsg = null;

  @ViewChild('contentsContainer') private contentsContainer: ElementRef;
  @ViewChild('contentsTable') private contentsTable: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private auth: AuthService,
  public dialogRef: MatDialogRef<InventoryControlComponent>,
  private api: InventoryService, private dialog: MatDialog, private fb: FormBuilder) {

    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.user_id = this.auth.getAuthUser().id;
    this.api.getAPI('category-directory?user_clinic_id=', this.clinic).subscribe(response => {
      if (response.status_code == 200) {
        this.cat_items = response.data.data;
      }
    });
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      selected_history: [null, []],
      selected_category: ['', [Validators.required]]
    });

    this.formGroup.get('selected_history').setValue(this.today);
    const filter = 'item-inventory-control-date?user_clinic_id=' + this.clinic;
    this.api.getAPI(filter, '').subscribe(response => {
      if (response.status_code == 200) {
        this.historyDates = response.data;
      }
    });
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  addInvControl(event) {
    const fg = this.formGroup;
    if (fg.valid) {
      this.ELEMENT_DATA = [];
      const formLoad = Object.assign({}, fg.value);
      const filter = 'category-item-inventory?user_clinic_id=' + this.clinic + '&category_id=' + formLoad.selected_category;
      this.api.getAPI(filter, '').subscribe(response => {
        if (response.status_code == 200) {
          this.hasError = false;
          this.ELEMENT_DATA = response.data;
          this.getHistory();
        }
      });
      if(this.ELEMENT_DATA){
        this.ELEMENT_DATA.forEach(element => {
          this.physical_count_array[this.ctr] = false;
          this.remark_count_array[this.ctr] = false;
          this.ctr++;
        });
      }
      const container = this.contentsContainer.nativeElement.offsetHeight;
      const contents = this.contentsTable.nativeElement.offsetHeight;
      if (container < contents) {
        this.hasScroll = true;
      } else {
        this.hasScroll = false;
      }
    }
    
      this.physical_count_array = [];
      this.remark_count_array = [];
   
  }

  getHistory() {
    const fg = this.formGroup;
    const formLoad = Object.assign({}, fg.value);
    const date = moment(this.formGroup.get('selected_history').value).format('YYYY-MM-DD');
    const access = 'item-inventory-control?user_clinic_id=' + this.clinic +
    '&category=' + formLoad.selected_category + '&date=' + date;
    if (formLoad.selected_category) {
      this.api.getAPI(access, '').subscribe(result => {
        if (result.status_code == 200) {
          if (date !== this.today) {
            this.hasHistory = true;
          } else {
            this.hasHistory = false;
          }

          if (result.data.length > 0 ) {
            this.ELEMENT_DATA = [];
            if (result.data[0].id) {
              this.ctrId = result.data[0].id;
            }
            const log_data = result.data[0].item_inventory_log_details;
            this.ELEMENT_DATA = log_data;
            for (let i of this.ELEMENT_DATA) {
              this.phyCount[i.item_id] = i.physical_count;
              this.remarks[i.item_id] = i.remark;
            }
          }
        }
      });
    }
  }

  saveCtrl() {
    const item = [];
    this.hasError = false;
    this.ELEMENT_DATA.forEach((element, i) => {
      this.physical_count_array[i] = false;
      this.remark_count_array[i] = false;
    });
    for (let i of this.ELEMENT_DATA) {
      item.push({
        item_id: i.item_id,
        item_name: i.item_name,
        remarks: this.remarks[i.item_id] ? this.remarks[i.item_id] : '',
        physical_count: this.phyCount[i.item_id] ?
        this.phyCount[i.item_id] : this.phyCount[i.item_id] === 0 ? this.phyCount[i.item_id] : '',
        quantity:  i.quantity
      });
    }

    item.forEach((element, i) => {
      if(element.physical_count == ""){
          this.physical_count_array[i] = true;
        }
        else{
          this.physical_count_array[i] = false;
          if(element.physical_count != element.quantity){
            if(!element.remarks){
              this.hasError = true;
              this.remark_count_array[i] = true;
            }
          }
        }
    });
    const data = {
      user_clinic_id: this.clinic,
      user_id: this.user_id,
      item_category_id: this.formGroup.get('selected_category').value,
      items: item
    }

      if (this.ctrId !== null) {
        data['id']= this.ctrId;
      }
      this.api.accessAPI('post', 'item-inventory-control', data, true, false, false).subscribe(res => {
        if (res.status_code == 200) {
          const dialogConfig = {
            title: 'Successful!',
            message: (this.ctrId !== null) ? 'Inventory control has been successfully updated.' : 'Inventory control has been successfully saved.',
            button: 'Okay',
          };
          openDialog(AlertMessageComponent, dialogConfig, this.dialog).afterClosed().subscribe(() => {
            this.dialogRef.close(true);
          });
        }

        if (res.error) {
          this.hasError = true;
          this.errMsg = res.error.message;
        }
      });
  
  }

  getPhysicalCount(e) {
    return this.physical_count_array[e];
  }
  onChangeQuantity(e){
    this.physical_count_array[e] = false;
  }
  getRemarkCount(e) {
    return this.remark_count_array[e];
  }
  onChangeRemark(e){
    this.remark_count_array[e] = false;
  }
}
