import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { keypressValidateNumber } from 'src/app/common/helper';

@Component({
  selector: 'ark-pedo',
  templateUrl: './pedo.component.html',
  styleUrls: ['./pedo.component.scss']
})
export class PedoComponent implements OnInit {

  clinic = null;
  dosages = [
    { id: 1, name: '15 mg/kg' },
    { id: 2, name: '20 mg/kg' },
    { id: 3, name: '50 mg/kg' }
  ];
  dosage: number = 1
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.form = this.formBuilder.group({
      dosage: [''],
      amox: [''],
      clinda: [''],
      paracetamol: [''],
      amox_child_weight: [''],
      clinda_child_weight: [''],
      paracetamol_child_weight: [''],
      amox_strength_required: [''],
      clinda_strength_required: [''],
      paracetamol_strength_required: [''],
      amox_stock_strength: [''],
      clinda_stock_strength: [''],
      paracetamol_stock_strength: [''],
      amox_ml: [''],
      clinda_ml: [''],
      paracetamol_ml: [''],
      amox_tid: [''],
      clinda_tid: [''],
      paracetamol_tid: [''],
      amox_qid: [''],
      clinda_qid: [''],
      paracetamol_qid: [''],
    });
    this.form.get('dosage').setValue(1);
    this.form.get('amox').setValue('15 mg/kg');
    this.form.get('clinda').setValue('15 mg/kg');
    this.form.get('paracetamol').setValue('15 mg/kg');
    this.form.get('amox_stock_strength').setValue('125 mg/5 ml');
    this.form.get('clinda_stock_strength').setValue('125 mg/5 ml');
    this.form.get('paracetamol_stock_strength').setValue('125 mg/5 ml');
    this.disableInputs();
    this.calculatePedo();
  }

  ngOnInit() {

  }

  disableInputs() {
    this.form.disable();
    this.form.controls['dosage'].enable();
    this.form.controls['amox_child_weight'].enable();
    this.form.controls['clinda_child_weight'].enable();
    this.form.controls['paracetamol_child_weight'].enable();
  }

  getDosage(val) {
    this.dosage = val.value;
    if(this.dosage == 1) {
      this.form.get('amox').setValue('15 mg/kg');
      this.form.get('clinda').setValue('15 mg/kg');
      this.form.get('paracetamol').setValue('15 mg/kg');
      this.form.get('amox_stock_strength').setValue('125 mg/5 ml');
      this.form.get('clinda_stock_strength').setValue('125 mg/5 ml');
      this.form.get('paracetamol_stock_strength').setValue('125 mg/5 ml');
      this.calculatePedo();
    } else if(this.dosage == 2) {
      this.form.get('amox').setValue('20 mg/kg');
      this.form.get('clinda').setValue('20 mg/kg');
      this.form.get('paracetamol').setValue('20 mg/kg');
      this.form.get('amox_stock_strength').setValue('250 mg/5 ml');
      this.form.get('clinda_stock_strength').setValue('250 mg/5 ml');
      this.form.get('paracetamol_stock_strength').setValue('250 mg/5 ml');
      this.calculatePedo();
    } else {
      this.form.get('amox').setValue('50 mg/kg');
      this.form.get('clinda').setValue('50 mg/kg');
      this.form.get('paracetamol').setValue('50 mg/kg');
      this.form.get('amox_stock_strength').setValue('500 mg/5 ml');
      this.form.get('clinda_stock_strength').setValue('500 mg/5 ml');
      this.form.get('paracetamol_stock_strength').setValue('500 mg/5 ml');
      this.calculatePedo();
    }
  }

  calculatePedo() {
    this.calculateAmox();
    this.calculateClinda();
    this.calculateParacetamol();
  }

  calculateAmox() {
    if(this.dosage == 1) {
      const multiplier = this.form.get('amox_child_weight').value;
      const product = (15 * Number(multiplier));
      this.form.get('amox_strength_required').setValue(Math.round(product * 100) / 100);
      
      const ml = (Number(product) / 125) * 5;
      this.form.get('amox_ml').setValue(Math.round(ml * 100) / 100);
  
      const tid = (Number(ml) / 3);
      this.form.get('amox_tid').setValue(Math.round(tid * 100) / 100);
  
      const qid = (Number(ml) / 4);
      this.form.get('amox_qid').setValue(Math.round(qid * 100) / 100);
    } else if(this.dosage == 2){
      const multiplier = this.form.get('amox_child_weight').value;
      const product = (20* Number(multiplier));
      this.form.get('amox_strength_required').setValue(Math.round(product * 100) / 100);
      
      const ml = (Number(product) / 250) * 5;
      this.form.get('amox_ml').setValue(Math.round(ml * 100) / 100);
  
      const tid = (Number(ml) / 3);
      this.form.get('amox_tid').setValue(Math.round(tid * 100) / 100);
  
      const qid = (Number(ml) / 4);
      this.form.get('amox_qid').setValue(Math.round(qid * 100) / 100);
    } else {
      const multiplier = this.form.get('amox_child_weight').value;
      const product = (50* Number(multiplier));
      this.form.get('amox_strength_required').setValue(Math.round(product * 100) / 100);
      
      const ml = (Number(product) / 500) * 5;
      this.form.get('amox_ml').setValue(Math.round(ml * 100) / 100);
  
      const tid = (Number(ml) / 3);
      this.form.get('amox_tid').setValue(Math.round(tid * 100) / 100);
  
      const qid = (Number(ml) / 4);
      this.form.get('amox_qid').setValue(Math.round(qid * 100) / 100);
    }
  }

  calculateClinda() {
    if(this.dosage == 1) {
      const multiplier = this.form.get('clinda_child_weight').value;
      const product = (15 * Number(multiplier));
      this.form.get('clinda_strength_required').setValue(Math.round(product * 100) / 100);
      
      const ml = (Number(product) / 125) * 5;
      this.form.get('clinda_ml').setValue(Math.round(ml * 100) / 100);
  
      const tid = (Number(ml) / 3);
      this.form.get('clinda_tid').setValue(Math.round(tid * 100) / 100);
  
      const qid = (Number(ml) / 4);
      this.form.get('clinda_qid').setValue(Math.round(qid * 100) / 100);
    } else if(this.dosage == 2) {
      const multiplier = this.form.get('clinda_child_weight').value;
      const product = (20 * Number(multiplier));
      this.form.get('clinda_strength_required').setValue(Math.round(product * 100) / 100);
      
      const ml = (Number(product) / 250) * 5;
      this.form.get('clinda_ml').setValue(Math.round(ml * 100) / 100);
  
      const tid = (Number(ml) / 3);
      this.form.get('clinda_tid').setValue(Math.round(tid * 100) / 100);
  
      const qid = (Number(ml) / 4);
      this.form.get('clinda_qid').setValue(Math.round(qid * 100) / 100);
    } else {
      const multiplier = this.form.get('clinda_child_weight').value;
      const product = (50 * Number(multiplier));
      this.form.get('clinda_strength_required').setValue(Math.round(product * 100) / 100);
      
      const ml = (Number(product) / 500) * 5;
      this.form.get('clinda_ml').setValue(Math.round(ml * 100) / 100);
  
      const tid = (Number(ml) / 3);
      this.form.get('clinda_tid').setValue(Math.round(tid * 100) / 100);
  
      const qid = (Number(ml) / 4);
      this.form.get('clinda_qid').setValue(Math.round(qid * 100) / 100);
    }
  }

  calculateParacetamol() {
    if(this.dosage == 1) {
      const multiplier = this.form.get('paracetamol_child_weight').value;
      const product = (15 * Number(multiplier));
      this.form.get('paracetamol_strength_required').setValue(Math.round(product * 100) / 100);
      
      const ml = (Number(product) / 125) * 5;
      this.form.get('paracetamol_ml').setValue(Math.round(ml * 100) / 100);

      const tid = (Number(ml) / 3);
      this.form.get('paracetamol_tid').setValue(Math.round(tid * 100) / 100);

      const qid = (Number(ml) / 4);
      this.form.get('paracetamol_qid').setValue(Math.round(qid * 100) / 100);
    } else if(this.dosage == 2) {
      const multiplier = this.form.get('paracetamol_child_weight').value;
      const product = (20 * Number(multiplier));
      this.form.get('paracetamol_strength_required').setValue(Math.round(product * 100) / 100);
      
      const ml = (Number(product) / 250) * 5;
      this.form.get('paracetamol_ml').setValue(Math.round(ml * 100) / 100);

      const tid = (Number(ml) / 3);
      this.form.get('paracetamol_tid').setValue(Math.round(tid * 100) / 100);

      const qid = (Number(ml) / 4);
      this.form.get('paracetamol_qid').setValue(Math.round(qid * 100) / 100);
    } else {
      const multiplier = this.form.get('paracetamol_child_weight').value;
      const product = (50 * Number(multiplier));
      this.form.get('paracetamol_strength_required').setValue(Math.round(product * 100) / 100);
      
      const ml = (Number(product) / 500) * 5;
      this.form.get('paracetamol_ml').setValue(Math.round(ml * 100) / 100);

      const tid = (Number(ml) / 3);
      this.form.get('paracetamol_tid').setValue(Math.round(tid * 100) / 100);

      const qid = (Number(ml) / 4);
      this.form.get('paracetamol_qid').setValue(Math.round(qid * 100) / 100);
    }
  }

  clearPedo() {
    this.form.get('amox_child_weight').setValue('');
    this.form.get('clinda_child_weight').setValue('');
    this.form.get('paracetamol_child_weight').setValue('');
    this.calculatePedo();
    // this.form.get('amox_strength_required').setValue('');
    // this.form.get('clinda_strength_required').setValue('');
    // this.form.get('paracetamol_strength_required').setValue('');
    // this.form.get('amox_ml').setValue('');
    // this.form.get('clinda_ml').setValue('');
    // this.form.get('paracetamol_ml').setValue('');
    // this.form.get('amox_tid').setValue('');
    // this.form.get('clinda_tid').setValue('');
    // this.form.get('paracetamol_tid').setValue('');
    // this.form.get('amox_qid').setValue('');
    // this.form.get('clinda_qid').setValue('');
    // this.form.get('paracetamol_qid').setValue('');
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }
}
