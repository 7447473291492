import { Component, OnInit, EventEmitter, Input, Output, ViewChild, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogConfig, MatDialog, MatAutocomplete } from '@angular/material';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { DatePipe } from '@angular/common';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard/dashboard.service';
import { startWith, map } from 'rxjs/operators';
import { ConfirmUpdateComponent } from 'src/app/modules/shared/components/confirm-update/confirm-update.component';
import { keypressValidateNumber } from 'src/app/common/helper';

export interface Dentist {
  id: number;
  firstName: string;
  lastName: string;
}

@Component({
  selector: 'ark-visit-info-details',
  templateUrl: './visit-info-details.component.html',
  styleUrls: ['./visit-info-details.component.scss']
})
export class VisitInfoDetailsComponent implements OnInit, OnChanges {

  @Input() previousVisitId: any = null; 
  @Input('selectedIndex') selectedIndex: number; 
  @Output('selectedIndexChange') selectedIndexChange: EventEmitter<any> = new EventEmitter;
  @Output() id: EventEmitter<any> = new EventEmitter;
  @Output() isDisabled: EventEmitter<any> = new EventEmitter;
  @Output() attendingDentist: EventEmitter<any> = new EventEmitter;
  @Output() isNew: EventEmitter<any> = new EventEmitter;

  currentDate = new Date(); 
  errorMessage = null;
  formGroup: FormGroup;
  selected = 0;
  index = null;
  scheduleVisits = [
    { name: '3 months', value: 3 },
    { name: '6 months', value: 6 },
    { name: '12 months', value: 12 },
  ];
  success: boolean = false;
  isToggled: boolean = false
  patientId = '';
  dialogConfig = new MatDialogConfig();
  dialogRef: any;
  date = new Date();
  datePipe = new DatePipe('en-US');
  today = '';
  clinic = '';
  visitId = null;
  dentistId = null;
  customCollapsedHeight: string = '20px';
  customExpandedHeight: string = '20px';

  patient = null;
  inputtedString = '';

  dentistControl = new FormControl();
  dentists: Dentist[] = [];
  filteredDentists: Observable<Dentist[]>;
  @ViewChild('dentist') matAutocompleteDentist: MatAutocomplete;

  constructor(
    private formBuilder: FormBuilder,
    private patientsDirectory: PatientsDirectoryService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private _router: Router,
    public dialog: MatDialog,
    private dashboard: DashboardService
  ) {
    this.route.params.subscribe(param => {
      this.patientId = param.id;
    });
    this.today = this.datePipe.transform(this.date, 'MMMM d, yyyy');
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.formGroup = this.formBuilder.group({
      email: ['', Validators.email],
      mobile_number: ['', [Validators.required]],
      birthdate: ['', [Validators.required]],
      recall_visit: ['', [Validators.required]],
      dentist_id: new FormControl('', [Validators.required]),
    }); 
    if(localStorage.getItem('patientName')) {
      this.patient = JSON.parse(localStorage.getItem('patientName'));
      this.formGroup.get('email').setValue(this.patient.email);
      this.formGroup.get('mobile_number').setValue(this.patient.mobile_number);
      this.formGroup.get('birthdate').setValue(this.patient.birthdate);
    }
    this.dashboard.getDentistList(this.clinic).subscribe(res => {
      res.forEach(element => {
        let obj: Dentist = {
          id: element.id, 
          firstName: element.first_name,
          lastName: element.last_name
        };
        this.dentists.push(obj);
      });
    }); 
  }

  ngOnInit() {
    this.getVisitInfo();
    this.isNew.emit(0);
    this.filteredDentists = this.formGroup.get('dentist_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.filterDentist(name) : this.dentists.slice())
      );
    if(this.formGroup.get('email').value !== null) {
      this.formGroup.controls['email'].disable();
    } else {
      this.formGroup.controls['email'].enable();
    }

    if(this.formGroup.get('mobile_number').value !== null) {
      this.formGroup.controls['mobile_number'].disable();
    } else {
      this.formGroup.controls['mobile_number'].enable();
    }

    if(this.formGroup.get('birthdate').value == 'Invalid date') {
      this.formGroup.controls['birthdate'].enable();
    } else {
      this.formGroup.controls['birthdate'].disable();
    }
  }

  ngOnChanges() {
    if(this.previousVisitId) {
      this.visitId = this.previousVisitId;
      this.getVisitInfo();
    }
  }

  getVisitInfo() {
    this.patientsDirectory.getSpecificVisitInfo(this.patientId, this.visitId, this.clinic).subscribe(res => {
      if(res.length > 0) {
        this.visitId = res[0].id;
        this.dentistId = res[0].attending_dentist.id;
        this.formGroup.get('dentist_id').setValue({
          id: res[0].attending_dentist.id, 
          firstName: res[0].attending_dentist.first_name, 
          lastName: res[0].attending_dentist.last_name
        });
        this.formGroup.get('recall_visit').setValue(res[0].recall_visit);
        this.selected = res[0].recall_visit;
        if(this.selected === 0) {
          this.isToggled = false;
        } else {
          this.isToggled = true;
        }
      }
    })
  }

  filterDentist(name: string): Dentist[] {
    const filterValue = name.toLowerCase();
    return this.dentists.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1);
  }

  displayDentist(dentist?: any) : string | undefined {
    return dentist ? 'Dr. ' + dentist.firstName + ' ' + dentist.lastName : undefined;
  }

  onRecallVisit(value) {
    if(this.isToggled) {
      this.selected = value;
    } else {
      this.selected = 0;
    }
  }

  onToggle(toggle) {
    if(toggle.checked == true) {
      this.selected = null;
      this.isToggled = true;
    } else {
      this.selected = 0;
      this.isToggled = false;
    }
  } 

  getDentist(event) {
    this.dentistId = event.option.value.id;
  }

  goToPatientDirectory() {
    this._router.navigate(["/patients"]);
  }

  save() {
    const data = {
      patient_id: this.patientId,
      email: this.patient.email,
      mobile_number: this.patient.mobile_number,
      birthdate: this.patient.birthdate,
      recall_visit: this.selected,
      dentist_id: this.dentistId
    }
    if(this.dentistId == '') {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'The attending dentist field is invalid.',
        button: 'Okay',
        event: this.closeOnClick
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      this.errorMessage = {dentist_id: 'Attending dentist field is required.'};
      this.triggerErrors({dentist_id: 'Attending dentist field is required.'});
    } else {
      if (this.formGroup.value.birthdate > this.currentDate) {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'The birthdate field is invalid.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      } else {
        this.patientsDirectory.addVisitInfo(data, this.clinic).subscribe(res => {
          if(res) {
            this.visitId = res['data'][0].id;
            this.id.emit(this.visitId);
            this.attendingDentist.emit(this.dentistId);
            this.isDisabled.emit(false);
            this.isNew.emit(1);
            if(this.selected > 0) {
              this.success = true;
              this.dialogConfig.data = {
                title: 'Success!',
                message: 'Visit information details has been successfully saved. \nRecall visit date: ' + moment(this.date).add(this.selected, 'month').format("MMMM DD, YYYY"),
                button: 'Okay',
                event: this.closeOnClick
              };
              this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => {
                const tab = this.selectedIndex = this.selectedIndex + 1;
                this.selectedIndexChange.emit(tab);
              });
            } else {
              this.success = true;
              this.dialogConfig.data = {
                title: 'Success!',
                message: 'Visit information details has been successfully saved.',
                button: 'Okay',
                event: this.closeOnClick
              };
              this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => {
                const tab = this.selectedIndex = this.selectedIndex + 1;
                this.selectedIndexChange.emit(tab);
              });
              
            }
          }
        },(err => {
          this.dialogConfig.data = {
            title: 'Oops!',
            message: err.error.message,
            button: 'Okay',
            event: this.closeOnClick
          };
          this.errorMessage = err.error.errors;
          this.triggerErrors(err.error.errors);
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }));
      }
    }
  }

  update() {
    const data = {
      patient_id: this.patientId,
      email: this.patient.email,
      mobile_number: this.patient.mobile_number.toString(),
      birthdate: this.patient.birthdate,
      recall_visit: this.selected,
      dentist_id: this.dentistId
    }
    if(this.dentistId == '') {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'The attending dentist field is invalid.',
        button: 'Okay',
        event: this.closeOnClick
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      this.errorMessage = {dentist_id: 'Attending dentist field is required.'};
      this.triggerErrors({dentist_id: 'Attending dentist field is required.'});
    } else {
      this.dialogConfig.data = {
        subject: 'create a new one'
      };
      this.dialogConfig.disableClose = true;
      this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
      let dialogRef = this.dialog.open(ConfirmUpdateComponent, this.dialogConfig).afterClosed().subscribe(update => {
        if(update) {
          this.patientsDirectory.updateVisitInfo(this.visitId, this.clinic, data).subscribe(res => {
            if(res) {
              this.visitId = res['data'][0].id;
              if(this.selected > 0) {
                this.success = true;
                this.dialogConfig.data = {
                  title: 'Success!',
                  message: 'You have successfully updated your visit information details. \nRecall visit date: ' + moment(this.date).add(this.selected, 'month').format("MMMM DD, YYYY"),
                  button: 'Okay',
                  event: this.closeOnClick
                };
                this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
              } else {
                this.success = true;
              }
            }
          },(err => {
            this.dialogConfig.data = {
              title: 'Oops!',
              message: err.error.message,
              button: 'Okay',
              event: this.closeOnClick
            };
            this.errorMessage = err.error.errors;
            this.triggerErrors(err.error.errors);
            let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
          }));
        } else {
          this.patientsDirectory.addVisitInfo(data, this.clinic).subscribe(res => {
            if(res) {
              this.visitId = res['data'][0].id;
              this.id.emit(this.visitId);
              this.attendingDentist.emit(this.dentistId);
              this.isNew.emit(1);
              if(this.selected > 0) {
                this.success = true;
                this.dialogConfig.data = {
                  title: 'Success!',
                  message: 'Visit information details has been successfully saved. \nRecall visit date: ' + moment(this.date).add(this.selected, 'month').format("MMMM DD, YYYY"),
                  button: 'Okay',
                  event: this.closeOnClick
                };
                this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => {
                  const tab = this.selectedIndex = this.selectedIndex + 1;
                  this.selectedIndexChange.emit(tab);
                });
              } else {
                this.success = true;
                const tab = this.selectedIndex = this.selectedIndex + 1;
                this.selectedIndexChange.emit(tab);
              }
            }
          },(err => {
            this.dialogConfig.data = {
              title: 'Oops!',
              message: err.error.message,
              button: 'Okay',
              event: this.closeOnClick
            };
            this.errorMessage = err.error.errors;
            this.triggerErrors(err.error.errors);
            let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
          }));
        }
      });
    }
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }

  onKeydown(event) {
    if(event.keyCode == 8 || event.keyCode == 46) {
      this.inputtedString = '';
      this.dentistId = '';
    } else {
      this.inputtedString = null;
    }
  }
}
