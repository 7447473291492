import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { PatientService } from '../../../../service/patient/patient.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';

@Component({
  selector: 'ark-direct-cost',
  templateUrl: './direct-cost.component.html',
  styleUrls: ['./direct-cost.component.scss']
})
export class DirectCostComponent implements OnInit, OnChanges {

  @Input() month: any = null; 
  @Input() year: any = null; 
  @Input() search: any = null; 
  directCosts = [];
  clinic = null;
  selectedYear = '';
  selectedMonth = '';
  keyword = '';
  isNoResult: boolean = true;

  constructor(
    private auth: AuthService,
    private patient: PatientService
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
  }

  ngOnInit() {
    
  }

  ngOnChanges() {
    if(this.month || this.year || this.search) {
      this.selectedMonth =  this.month;
      this.selectedYear =  this.year;
      this.keyword = this.search
      this.getList();
    }
  }

  getList() {
    this.patient.getDirectCost(this.clinic, this.selectedMonth, this.year, this.keyword).subscribe(res => {
      if(res) {
        this.directCosts = res['direct-cost'].data;
        if(this.directCosts.length > 0) {
          this.isNoResult = false;
        } else {
          this.isNoResult = true;
        }
      }
    });
  }

  formatDate(obj) {
    return obj.toString().replace(/\s/g, "T");
  }
}
