import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

@Component({
  selector: 'ark-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  oldPassword =  true;
  newPassword =  true;
  confirmPassword =  true;
  errorMessage = null;
  success = false;

  formGroup: FormGroup;

  dialogConfig = new MatDialogConfig;
  dialogRef: any;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      current_password: ['', [Validators.required]],
      password: ['', [Validators.required]],
      password_confirmation: ['', [Validators.required]]
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  changePassword() {
    let user = this.auth.getAuthUser();
    let data = {
      id: user.id,
      current_password: this.formGroup.value.current_password,
      password: this.formGroup.value.password,
      password_confirmation: this.formGroup.value.password_confirmation
    };
    this.auth.changePassword(data).subscribe(response => {
      if(response) {
        this.success = true;
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Your password has been successfully updated.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    },(err => {
      this.errorMessage = err.error.errors;
      this.triggerErrors(err.error.errors);
    }));
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }

  closeOnClick() {
    this.dialogRef.close();
  }
}
