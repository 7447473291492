import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

@Component({
  selector: 'ark-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

  oldPassword =  true;
  newPassword =  true;
  confirmPassword =  true;
  currentPasswordError = '';
  passwordError = '';
  passwordConfirmationError = '';
  success = false;

  loginForm: FormGroup;

  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private _router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      current_password: ['', [Validators.required]],
      password: ['', [Validators.required]],
      password_confirmation: ['', [Validators.required]]
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  changePassword() {
    let user = this.auth.getAuthUser();
    let data = {
      id: user.id,
      current_password: this.loginForm.value.current_password,
      password: this.loginForm.value.password,
      password_confirmation: this.loginForm.value.password_confirmation
    };
    this.auth.changePassword(data).subscribe(response => {
      if (response) {
        this.success = true;
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'You have successfully changed your password. \nYou can now use your new password in your succeeding login.',
          button: 'Back to Login',
          event: this.backToLogin
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => this.backToLogin());
      }
    },(err => {
      if (err.error.errors === undefined) {
        this.currentPasswordError = err.error.message;
        this.passwordError = err.error.message;
        this.passwordConfirmationError = err.error.message;
      } else {
        this.currentPasswordError = err.error.errors.current_password;
        this.passwordError = err.error.errors.password;
        this.passwordConfirmationError = err.error.errors.password_confirmation;
      }
      this.triggerErrors();
    }));
  }

  triggerErrors() {
    this.loginForm.controls['current_password'].setErrors({'invalid': true});
    this.loginForm.controls['current_password'].markAsTouched();
    this.loginForm.controls['current_password'].markAsDirty();

    this.loginForm.controls['password'].setErrors({'invalid': true});
    this.loginForm.controls['password'].markAsTouched();
    this.loginForm.controls['password'].markAsDirty();

    this.loginForm.controls['password_confirmation'].setErrors({'invalid': true});
    this.loginForm.controls['password_confirmation'].markAsTouched();
    this.loginForm.controls['password_confirmation'].markAsDirty();
  }

  backToLogin() {
    this._router.navigate(["/login"]);
  }
}
