import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ark-save',
  templateUrl: './save.component.html',
  styleUrls: ['./save.component.scss']
})
export class SaveComponent implements OnInit {

  @Output() onClick = new EventEmitter<any>();

  constructor() { }

  onClickButton(event) {
    this.onClick.emit(event);
  }

  ngOnInit() {
  }

}
