import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SiteLayoutComponent } from "./layouts/site-layout/site-layout.component";
import { AppLayoutComponent } from "./layouts/app-layout/app-layout.component";
import { CoreModule } from "./modules/core/core.module";
import { SharedModule } from "./modules/shared/shared.module";
import { LoginModule } from "./modules/login/login.module";
import { DashboardModule } from "./modules/dashboard/dashboard.module";
import { AccountSettingsModule } from "./modules/account-settings/account-settings.module";
import { FrontDeskModule } from "./modules/front-desk/front-desk.module";
import { PatientsModule } from "./modules/patients/patients.module";
import { DentalOfficeModule } from "./modules/dental-office/dental-office.module";
import { DailyTimeRecordModule } from "./modules/daily-time-record/daily-time-record.module";
import { TreatmentDemoModule } from "./modules/treatment-demo/treatment-demo.module";
import { SubscriptionModule } from "./modules/subscription/subscription.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

@NgModule({
  declarations: [AppComponent, SiteLayoutComponent, AppLayoutComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    LoginModule,
    DashboardModule,
    AccountSettingsModule,
    FrontDeskModule,
    PatientsModule,
    DentalOfficeModule,
    DailyTimeRecordModule,
    TreatmentDemoModule,
    SubscriptionModule,
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
