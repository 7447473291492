import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatAutocompleteTrigger } from '@angular/material';
import { Observable } from 'rxjs';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard/dashboard.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { startWith, map } from 'rxjs/operators';
import { DentalFormsService } from 'src/app/modules/dental-office/services/dental-forms/dental-forms.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import * as moment from 'moment';

export interface Patient {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
}

@Component({
  selector: 'ark-email-to-patient',
  templateUrl: './email-to-patient.component.html',
  styleUrls: ['./email-to-patient.component.scss']
})
export class EmailToPatientComponent implements OnInit {

  formGroup: FormGroup;
  dialogConfig = new MatDialogConfig();
  errorMessage = null;
  clinic = null;
  patientId = '';

  patientControl = new FormControl();
  patients: Patient[] = [];
  filteredPatients: Observable<Patient[]>;
  @ViewChild('patient') patient: MatAutocompleteTrigger;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EmailToPatientComponent>,
    private auth: AuthService,
    private dashboard: DashboardService,
    private forms: DentalFormsService,
    public dialog: MatDialog,
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic;
    this.dashboard.getPatientList(this.clinic.id, this.clinic.id).subscribe(res => {
      res.data.forEach(element => {
        let obj: Patient = {
          id: element.id, 
          firstName: element.first_name, 
          middleName: element.middle_name ? element.middle_name : '', 
          lastName: element.last_name
        };
        this.patients.push(obj);
      });
    });
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      patient_id: ['', [Validators.required]]
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.filteredPatients = this.patientControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterPatient(name) : this.patients.slice()),
      );
  }

  getPatient(event) {
    this.patientId = event.option.value.id;
  }

  private _filterPatient(name: string): Patient[] {
    const filterValue = name.toLowerCase();
    return this.patients.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1 || option.middleName.toLowerCase().indexOf(filterValue) > -1);
  }

  displayPatient(patient?: any) : string | undefined {
    return patient ? patient.lastName + ', ' + patient.firstName + ' ' + patient.middleName : undefined;
  }

  onFocusPatient() {
    this.patient._onChange("");
    this.patient.openPanel();
  }
    

  closeOnClick() {
    this.dialogRef.close();
  }

  sendEmail() {
    const date = this.data.data.appointment_date ? moment(this.data.data.appointment_date).format('MM/DD/YYYY') : '';
    const FORM_1 = {
      user_clinic_id: this.clinic.id,
      address: this.data.data.address, 
      telephone_no: this.data.data.telephone_no.toString(), 
      mobile_no: this.data.data.mobile_no.toString(),
      clinic_hours: this.data.data.clinic_hours,
      website: this.data.data.website,
      email: this.data.data.email,
      patient_id: this.patientId,
      print: ''
    };
    const FORM_2 = {
      user_clinic_id: this.clinic.id,
      address: this.data.data.address, 
      telephone_no: this.data.data.telephone_no.toString(), 
      mobile_no: this.data.data.mobile_no.toString(),
      clinic_hours: this.data.data.clinic_hours,
      website: this.data.data.website,
      email: this.data.data.email,
      appointment_date: date, 
      appointment_time: this.data.data.appointment_time, 
      appointment_procedure: this.data.data.appointment_procedure, 
      patient_id: this.patientId,
      print: ''
    };
    const FORM_3 = {
      user_clinic_id: this.clinic.id,
      address: this.data.data.address, 
      telephone_no: this.data.data.telephone_no.toString(), 
      mobile_no: this.data.data.mobile_no.toString(),
      clinic_hours: this.data.data.clinic_hours,
      website: this.data.data.website,
      email: this.data.data.email,
      patient_id: this.patientId,
      print: '',
      form: true
    };
    const FORM_4 = {
      user_clinic_id: this.clinic.id,
      address: this.data.data.address, 
      telephone_no: this.data.data.telephone_no.toString(), 
      mobile_no: this.data.data.mobile_no.toString(),
      clinic_hours: this.data.data.clinic_hours,
      website: this.data.data.website,
      email: this.data.data.email,
      patient_id: this.patientId,
      print: '',
      form: ''
    };
    if(this.data.data.form == 1) {
      this.forms.emailPOIDentureCare(FORM_1).subscribe(res => {
        if(res) {
          this.dialogRef.close(res);
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Post operative instruction has been emailed successfully.',
            button: 'Okay',
            event: this.closeOnClick
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Sorry!',
          message: err.error.message,
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        this.errorMessage = err.error.errors;
        this.triggerErrors(err.error.errors);
      }));
    } else if(this.data.data.form == 2) {
      this.forms.emailPOISurgery(FORM_2).subscribe(res => {
        if(res) {
          this.dialogRef.close(res);
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Post operative instruction has been emailed successfully.',
            button: 'Okay',
            event: this.closeOnClick
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Sorry!',
          message: err.error.message,
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        this.errorMessage = err.error.errors;
        this.triggerErrors(err.error.errors);
      }));
    } else if(this.data.data.form == 3) {
      this.forms.emailPOITeethWhiteningCustom(FORM_3).subscribe(res => {
        if(res) {
          this.dialogRef.close(res);
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Post operative instruction has been emailed successfully.',
            button: 'Okay',
            event: this.closeOnClick
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Sorry!',
          message: err.error.message,
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        this.errorMessage = err.error.errors;
        this.triggerErrors(err.error.errors);
      }));
    } else if(this.data.data.form == 4) {
      this.forms.emailPOITeethWhiteningPreFormed(FORM_4).subscribe(res => {
        if(res) {
          this.dialogRef.close(res);
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Post operative instruction has been emailed successfully.',
            button: 'Okay',
            event: this.closeOnClick
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Sorry!',
          message: err.error.message,
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        this.errorMessage = err.error.errors;
        this.triggerErrors(err.error.errors);
      }));
    }
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }
}
