import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../services/user/user.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { MyProfileCameraComponent } from './my-profile-camera/my-profile-camera.component';
import { Router } from '@angular/router';

@Component({
  selector: 'ark-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  errorMessage = null;
  success = false;
  formGroup: FormGroup;
  dialogConfig = new MatDialogConfig();
  dialogRef: any;
  img : any;
  strImage: any;
  isOwner: boolean = (localStorage.getItem('isOwner') == 'true'? true : false );
  isAssistant: boolean = false;
  id = null;
  clinic = null;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private auth: AuthService,
    public dialog: MatDialog,
    private _router: Router
  ) { 
    this.formGroup = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      middle_name: [''],
      last_name: ['', [Validators.required]],
      prc_license_no: [''],
      ptr_no: [''],
      email: ['', [Validators.required, Validators.email]],
      address: ['', [Validators.required]],
      role: ['', [Validators.required]],
      profile_image: ['', [Validators.required]]
    });
    this.id = this.auth.getAuthUser().id;
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    if(this.auth.getAuthUser().role_id == 'Assistant') {
      this.isAssistant = true;
    } else {
      this.isAssistant = false;
    }
  }

  ngOnInit() {
    this.getUser();
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.formGroup.controls['role'].disable();
  }

  getUser() {
    this.userService.getUser(this.id, this.clinic).subscribe(res => {
      let user = res['data'];
      this.formGroup.get('first_name').setValue(user.first_name);
      this.formGroup.get('middle_name').setValue(user.middle_name ? user.middle_name : '');
      this.formGroup.get('last_name').setValue(user.last_name);
      this.formGroup.get('prc_license_no').setValue(user.prc_license_no ? user.prc_license_no : '');
      this.formGroup.get('ptr_no').setValue(user.ptr_no ? user.ptr_no : '');
      this.formGroup.get('email').setValue(user.email);
      this.formGroup.get('role').setValue(user.role_id);
      this.formGroup.get('profile_image').setValue('');
      
      let image = user.profile_image ? user.profile_image : "../../../../../assets/image/image-thumbnail.jpg";
      document.getElementById("profile_image")['src'] = image;
    });
  }

  updateUser() {
    let formData = new FormData();
    for (const key in this.formGroup.value) {
      if (this.formGroup.value.hasOwnProperty(key)) {
        const element = this.formGroup.value[key];
        formData.append(key, element);
      }
    }
    this.userService.updateUser(this.id, this.clinic, formData).subscribe(res => {
      if (res) {
        this.success = true;
        this.auth.setAuthUser(res['data']);
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Your profile has been successfully updated.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => {
          this.redirectTo(this._router.url);
        });
      } 
    },(err => {
      this.errorMessage = err.error.errors;
      this.triggerErrors(err.error.errors);
    }));
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  onChangeProfilePhoto(event) {
    const file = event.target.files;
    if (file && file[0]) {
      var reader = new FileReader();
      reader.onload = function(e) {
        document.getElementById("profile_image")['src'] = e.target['result'];
      }
      reader.readAsDataURL(file[0]);
      this.formGroup.get('profile_image').setValue(file[0]);
    }
  }

  openCamera() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogRef = this.dialog.open(MyProfileCameraComponent, this.dialogConfig).afterClosed().subscribe(res => {
      if(res) {
        if(res['type'] == 1) {
          if(res['image']) {
            var reader = new FileReader();
            reader.readAsDataURL(res['image']);
            reader.onload = (_event) => { 
              document.getElementById("profile_image")['src'] = reader.result;
            }
            this.formGroup.get('profile_image').setValue(res['image']);
          }
        } else {
          document.getElementById("profile_image")['src'] = res;
          this.formGroup.get('profile_image').setValue(res);
        }
       
      }
    });
  }

  redirectTo(uri: string) {
    this._router.navigateByUrl('/login', {skipLocationChange: true}).then(()=>
    this._router.navigate([uri]));
  }
}
