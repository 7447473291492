import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { InventoryService } from 'src/app/modules/front-desk/service/inventory/inventory.service';
import { MatDialog } from '@angular/material';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'ark-history-checkout',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnChanges {
  config = {};
  element_data = [];
  clinic = '';

  @Input() hasUpdate: boolean = false;
  @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>();
  constructor(private api: InventoryService, private dialog: MatDialog, private auth: AuthService) {
    /* TABLE CONFIGURATION */
    this.config = {
      titles: ['Order Number', 'Date'],
      data: [],
      keys: ['id', 'date'],
      align: ['left', 'left'],
      empty: 'No History Found.',
      action: { 'id' : true}
    };
    /* END */
    this.clinic = this.auth.getAuthUser().user_clinic.id;
  }

  ngOnInit() {
    this.reloadHistory();
  }

  ngOnChanges() {
    if (this.hasUpdate) {
      this.reloadHistory();
    }
  }

  getOrderNumber(orderNum) {
    this.clickEvent.emit(orderNum.id);
  }

  reloadHistory() {
    const filter = 'item-inventory-checkout?user_clinic_id=' + this.clinic;
    this.api.getAPI(filter, '').subscribe(response => {
      if (response.status_code == 200) {
        this.element_data = response.data['check-out-history'].data;
      }
    });
  }

}
