import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { PatientService } from '../../../service/patient/patient.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'ark-annual-expenses-summary',
  templateUrl: './annual-expenses-summary.component.html',
  styleUrls: ['./annual-expenses-summary.component.scss']
})
export class AnnualExpensesSummaryComponent implements OnInit, OnChanges {

  today = new Date(); 
  formGroup : FormGroup;
  clinic = null;
  summary = null;
  @Input() selectedYear: any = null; 

  constructor(
    private formBuilder: FormBuilder,
    private patient: PatientService,
    private auth: AuthService
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.formGroup = this.formBuilder.group({
      year: ['']
    });
  }

  ngOnInit() {
    this.formGroup.controls['year'].disable();
    this.formGroup.controls['year'].setValue(moment(this.today).format('YYYY'));
    this.getSummary();
  }

  ngOnChanges() {
    if(this.selectedYear) {
      this.formGroup.controls['year'].setValue(this.selectedYear);
      this.getSummary();
    }
  }

  getSummary() {
    this.formGroup.controls['year'].setValue(this.selectedYear);
    this.patient.getAnnualSummaryExpenses(this.clinic, this.selectedYear).subscribe(res => {
      if(res) {
        this.summary = res;
      }
    })
  }

}
