import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { InventoryService } from 'src/app/modules/front-desk/service/inventory/inventory.service';
import * as moment from 'moment';

@Component({
  selector: 'ark-log-details',
  templateUrl: './log-details.component.html',
  styleUrls: ['./log-details.component.scss']
})
export class LogDetailsComponent implements OnInit {
  TITLE = '';
  DATE = '';
  UPDATE = '';
  config = {};
  element_data = []
  selected_id = '';
  clinic = '';
  DENTIST_NAME = null;
  PROCESS = null;
  PATIENT_NAME = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<LogDetailsComponent>,
  private api: InventoryService, private dialog: MatDialog, private auth: AuthService) {
    /* TABLE CONFIGURATION */
    this.config = {
      titles: ['Item - Brand Name - Description', 'Qty (pcs)'],
      data: [],
      keys: ['custom_name', 'quantity'],
      align: ['left', 'center'],
      empty: 'No Items Found.'
    };
    /* END */
    this.clinic = this.auth.getAuthUser().user_clinic.id;
  }

  ngOnInit() {
    setTimeout(() => {
      this.selected_id = this.data['id'];
      const filter = 'item-inventory-in?user_clinic_id=' + this.clinic + '&id=' + this.selected_id + '&action=' + this.data['action'];
      this.api.getAPI(filter, '').subscribe(response => {
        
        if (response.status_code == 200) {
          this.TITLE = response.data.action;
          this.DATE = moment(response.data.created_at).format('LL');
          this.UPDATE = this.data.first_name;
          this.PROCESS = response.data.description;
          this.DENTIST_NAME = null;
          this.PATIENT_NAME = null;
          if (this.data['action'] == 'Inventory Control') {
            this.config['titles'] = ['Item - Brand Name - Description', 'Qty (pcs)', 'Physical Count', 'Remarks'];
            this.config['keys'] = ['custom_name', 'quantity', 'physical_count', 'remark'];
            this.config['align'] = ['left', 'center', 'center', 'left'];
          }
          const res_data = response.data.item_inventory_log_details;
          let custom_data = [];
          res_data.forEach(element => {
            const custom_name = element.item_name + (element.item_brandname ? ' - ' + element.item_brandname : ' ') + (element.item_description ? ' - ' +  element.item_description : ' ');
            element['custom_name'] = custom_name;
            custom_data.push(element);
          });
          this.element_data = custom_data;
        }
      });
  }, 100);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

}
