import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ark-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {
  selectedIndex: number = 0;
  hasHistoryUpdate = false;
  orderNumber = null;
  constructor() { }

  ngOnInit() {
  }

  selectedIndexChange(val) {
    this.selectedIndex = val;
  }

  checkHistoryUpdate(e) {
    this.hasHistoryUpdate = false;
    setTimeout(() => {
      this.hasHistoryUpdate = e;
    }, 100);
  }

  getOrederNum(e) {
    this.orderNumber = null;
    setTimeout(() => {
      this.orderNumber = e;
    }, 100);
  }

}
