import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/modules/shared/services/http/http.service';
import { userClinic, userClinicMember, user, newUserClinic } from 'src/app/common/api';

@Injectable()
export class UserService extends HttpService{

  constructor(http: HttpClient, private _router: Router) { 
    super('',http);
  }

  getUser(id, user_clinic_id) {
  	return this.http.get(user + '/show/' + id + '?user_clinic_id=' + user_clinic_id).map(res => res);
  }

  updateUser(id, user_clinic_id, userData) {
    return this.http.post(user + '/update/' + id + '?user_clinic_id=' + user_clinic_id, userData).map((dataResponse) => {
      return dataResponse;
    });
  }

  addUser(data) {
    return this.http.post(userClinicMember + '/store', data).map((dataResponse) => {
      return dataResponse;
    });
  }

  addUserCheck(user_clinic_id, id, role) {
  	return this.http.get(userClinicMember + '/check' + '?user_clinic_id=' + user_clinic_id + '&user_id=' + id + '&role=' + role).map(res => res);
  }

  viewUser(user_clinic_id) {
    return this.get(userClinicMember + '?user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }

  userAction(id, user_clinic_id, userData){
    return this.http.put(userClinicMember + '/update/' + id + '?user_clinic_id=' + user_clinic_id, userData).map((dataResponse) => {
      return dataResponse;
    });
  }

  getClinic(id, user_clinic_id) {
  	return this.http.get(userClinic + '/show/' + id + '?user_clinic_id=' + user_clinic_id).map(res => res);
  }

  updateClinic(id, user_clinic_id, userData) {
    return this.http.put(userClinic + '/update/' + id + '?user_clinic_id=' + user_clinic_id, userData).map((dataResponse) => {
      return dataResponse;
    });
  }

  addClinic(data, only_clinic = false) {
    return this.http.post(userClinic + '/store' + '?only_clinic=' + only_clinic, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  checkClinic(data) {
    return this.http.put(userClinic + '/check', data).map((dataResponse) => {
      return dataResponse;
    });
  }

  viewClinics() {
    return this.http.get(userClinic + '/view').map(res => res);
  }

  addnewClinic(data, only_clinic = false) {
    return this.http.post(newUserClinic + '?only_clinic=' + only_clinic, data).map((dataResponse) => {
      return dataResponse;
    });
  }
}
