import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AddClinicalNoteComponent } from './add-clinical-note/add-clinical-note.component';
import * as moment from 'moment';
import { EditClinicalNoteComponent } from './edit-clinical-note/edit-clinical-note.component';
@Component({
  selector: 'ark-previous-clinical-notes',
  templateUrl: './previous-clinical-notes.component.html',
  styleUrls: ['./previous-clinical-notes.component.scss']
})
export class PreviousClinicalNotesComponent implements OnInit {

  isNoResult: boolean = true;
  ClinicalNotesDirectory = [];
  dialogConfig = new MatDialogConfig();
  dialogRef: any;
  clinic : null;
  patient_id : null;

  constructor(
    private patient: PatientsDirectoryService,
    private auth: AuthService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private _router: Router
  ) {
    this.route.params.subscribe(param => {
      this.patient_id = param.id;
    });
    this.clinic = this.auth.getAuthUser().user_clinic.id;
   
  }

  ngOnInit() {
    this.getPatientClinicalNotes();
  }

  getPatientClinicalNotes() {
    this.patient.getPatientClinicalNotes(this.clinic, this.patient_id).subscribe(res => { 
      if(res) {
        this.ClinicalNotesDirectory = res.data;
        if(this.ClinicalNotesDirectory.length > 0) {
          this.isNoResult = false;
        } else {
          this.isNoResult = true;
        }
      }
    });
  }

  addClinicalNote() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogConfig.data = {
      patient_id : this.patient_id
    }
    this.dialogRef = this.dialog.open(AddClinicalNoteComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(res => {
      if(res) {
        this.getPatientClinicalNotes();
      }
    });
  }

  openPatientClinicalNote(res) {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogConfig.data = {
      data : res
    }
    this.dialogRef = this.dialog.open(EditClinicalNoteComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(res => {
      if(res) {
        this.getPatientClinicalNotes();
      }
    });
  }

}
