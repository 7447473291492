import { environment } from 'src/environments/environment';

export let baseUrl = environment.apiBase;

//Login
export const loginUrl = baseUrl + '/api/login';
export const logoutUrl = baseUrl + '/api/logout';
export const tokenUrl = baseUrl + '/api/verify'
export const resetPasswordUrl = baseUrl + '/api/reset';
export const requestResetPasswordUrl = baseUrl + '/api/request?';
export const changePasswordUrl = baseUrl + '/api/change-password';

//Account Settings
export const user = baseUrl + '/api/user';
export const userClinic = baseUrl + '/api/user-clinic';
export const userClinicMember = baseUrl + '/api/user-clinic-member';
export const newUserClinic = baseUrl + '/api/new-user-clinic/store';

//Front Desk
export const addPatient = baseUrl + '/api/front-desk/store';
export const getCountry = baseUrl + '/api/countries';
export const getProvince = baseUrl + '/api/provinces';
export const getCity = baseUrl + '/api/cities';
export const checkPatient = baseUrl + '/api/patient/check-new';
export const checkPatientPrivateInfo = baseUrl + '/api/patient-pi/check';
export const checkEmergencyContact = baseUrl + '/api/patient-ec/check';
export const getMedicalAndDentalHistory = baseUrl + '/api/medical-dental-history';
export const getPatientBillingList = baseUrl + '/api/billing/patient/filter';
export const getPatientBalanceList = baseUrl + '/api/billing/patient-balance/filter';
export const getOperationalExpenses = baseUrl + '/api/operational-expenses';
export const getDirectCost = baseUrl + '/api/direct-cost';
export const getDoctorFee = baseUrl + '/api/doctor-fee';
export const operationalExpenses = baseUrl + '/api/expenses-category-directory';
export const dentistDirectory = baseUrl + '/api/dentist-directory';
export const medicalDoctorDirectory = baseUrl + '/api/medicalDoctor-directory';
export const supplierDirectory = baseUrl + '/api/supplier-directory';
export const laboratoryDirectory = baseUrl + '/api/laboratory-directory';
export const diagnosticCenterDirectory = baseUrl + '/api/diagnosticCenter-directory';
export const dentistShare = baseUrl + '/api/v2/doctors-fee';


//Patients
export const getPatient = baseUrl + '/api/patient';
export const toothChart = baseUrl + '/api/tooth-chart';
export const getLatestChart = baseUrl + '/api/tooth-chart/latest';
export const addNewToothChart = baseUrl + '/api/tooth-chart/new';
export const getPatientCharts = baseUrl + '/api/tooth-chart/charts';
export const patientPhoto = baseUrl + '/api/patient-photo';
export const patientRadiograph = baseUrl + '/api/patient-radiograph'
export const updatePatientPrivateInfo = baseUrl + '/api/patient-pi';
export const updatePatientEmergencyContact = baseUrl + '/api/patient-ec';
export const updateDentalHistory = baseUrl + '/api/medical-dental-history';
export const visitInfo = baseUrl + '/api/patient-visit-info';
export const addBillingDetail = baseUrl + '/api/billing/patient/detail';
export const itemInventory = baseUrl + '/api/item-inventory';
export const addBillingStatement = baseUrl + '/api/billing/patient-new';
export const patientBilling = baseUrl + '/api/billing/patient';
export const patientBalances = baseUrl + '/api/billing/patient-balance';
export const patientBill = baseUrl + '/api/patient-billing-detail';
export const patientClinicalNotes = baseUrl + '/api/clinical_note/';
export const patientDentalForm = baseUrl + '/api/patient-pdf';
export const patientClinicalNotesPdf = baseUrl + '/api/download-pdf';

//Dashboard
export const patientAppointment = baseUrl + '/api/patient-appointment';
export const dailyReport = baseUrl + '/api/daily-report';
export const getProcedures = baseUrl + '/api/procedures';

//Dental Office
export const incomeStatementSummary = baseUrl + '/api/summary-of-income-statement';
export const financeGraph = baseUrl + '/api/income-vs-expense';
export const setPrescription = baseUrl + '/api/pre-filled-perscription'
export const savePrescription = baseUrl + '/api/prescription'
export const dentalCertificate = baseUrl + '/api/dental-certificate'
export const postOperationInstruction = baseUrl + '/api/poi'
export const orthodonticConsent = baseUrl + '/api/orthodontic-treatment-consent'
export const informedConsent = baseUrl + '/api/informed-consent'
export const tmjConsent = baseUrl + '/api/tmj-treatment-consent'
export const waiverConsent = baseUrl + '/api/waiver'
export const releaseConsent = baseUrl + '/api/release'
export const bracesRemovalConsent = baseUrl + '/api/braces-removal-consent'
export const workAuthToothDetails = baseUrl + '/api/work-auth/teeth'
export const workAuthCrown = baseUrl + '/api/work-auth/crowns'
export const workAuthDentures = baseUrl + '/api/work-auth/partial-dentures'
export const workAuthOrthodontics = baseUrl + '/api/work-auth/orthodontics'
export const covidForm = baseUrl + '/api/covid-form'


//Daily Time Record
export const getTimeRecord = baseUrl + '/api/time-record';

//Laboratory Works
export const laboratoryWorks = baseUrl + '/api/labwork';

//Employees
export const payroll = baseUrl + '/api/payroll';
export const payrollSettings = baseUrl + '/api/payroll-setting';
export const payrollRecord = baseUrl + '/api/payroll-record';
export const printPayroll = baseUrl + '/api/payroll-pdf/';

//Inventory
export const getCheckOut = baseUrl + '/api/item-inventory-checkout-pdf';

//Subcription 
export const register = baseUrl + '/api/register/user';
export const verifyUser = baseUrl + '/api/save-verification/user';
export const termsAndConditions = baseUrl + '/api/terms-and-conditions';
export const unsubscribe = baseUrl + '/api/clinic-unsubscribe';
export const subscribe = baseUrl + '/api/clinic-subscribe';
export const subscriptionBilling = baseUrl + '/api/subscription-billing';

//New Patient Billing
export const patientBillingStatement = baseUrl + '/api/v2/billing';