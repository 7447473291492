import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ark-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss']
})
export class ReferralComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
