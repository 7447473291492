import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import * as _ from "lodash";

@Component({
  selector: "ark-perio-chart",
  templateUrl: "./perio-chart.component.html",
  styleUrls: ["./perio-chart.component.scss"],
})
export class PerioChartComponent implements OnInit {
  today = new Date();
  sections = [
    { id: 1, name: "Upper" },
    { id: 2, name: "Lower" },
  ];
  section = "";
  date = "";
  perioDetails = [];

  detailsData = [
    {
      name: "Note",
      data: [
        { id: 1, value: "", is_disabled: false },
        { id: 2, value: "", is_disabled: false },
        { id: 3, value: "", is_disabled: false },
        { id: 4, value: "", is_disabled: false },
        { id: 5, value: "", is_disabled: false },
        { id: 6, value: "", is_disabled: false },
        { id: 7, value: "", is_disabled: false },
        { id: 8, value: "", is_disabled: false },
        { id: 9, value: "", is_disabled: false },
        { id: 10, value: "", is_disabled: false },
        { id: 11, value: "", is_disabled: false },
        { id: 12, value: "", is_disabled: false },
        { id: 13, value: "", is_disabled: false },
        { id: 14, value: "", is_disabled: false },
        { id: 15, value: "", is_disabled: false },
        { id: 16, value: "", is_disabled: false },
      ],
    },
    {
      name: "Missing",
      data: [
        { id: 1, is_checked: 0, is_disabled: false },
        { id: 2, is_checked: 0, is_disabled: false },
        { id: 3, is_checked: 0, is_disabled: false },
        { id: 4, is_checked: 0, is_disabled: false },
        { id: 5, is_checked: 0, is_disabled: false },
        { id: 6, is_checked: 0, is_disabled: false },
        { id: 7, is_checked: 0, is_disabled: false },
        { id: 8, is_checked: 0, is_disabled: false },
        { id: 9, is_checked: 0, is_disabled: false },
        { id: 10, is_checked: 0, is_disabled: false },
        { id: 11, is_checked: 0, is_disabled: false },
        { id: 12, is_checked: 0, is_disabled: false },
        { id: 13, is_checked: 0, is_disabled: false },
        { id: 14, is_checked: 0, is_disabled: false },
        { id: 15, is_checked: 0, is_disabled: false },
        { id: 16, is_checked: 0, is_disabled: false },
      ],
    },
    {
      name: "Implant",
      data: [
        { id: 1, is_implant: false, is_disabled: false },
        { id: 2, is_implant: false, is_disabled: false },
        { id: 3, is_implant: false, is_disabled: false },
        { id: 4, is_implant: false, is_disabled: false },
        { id: 5, is_implant: false, is_disabled: false },
        { id: 6, is_implant: false, is_disabled: false },
        { id: 7, is_implant: false, is_disabled: false },
        { id: 8, is_implant: false, is_disabled: false },
        { id: 9, is_implant: false, is_disabled: false },
        { id: 10, is_implant: false, is_disabled: false },
        { id: 11, is_implant: false, is_disabled: false },
        { id: 12, is_implant: false, is_disabled: false },
        { id: 13, is_implant: false, is_disabled: false },
        { id: 14, is_implant: false, is_disabled: false },
        { id: 15, is_implant: false, is_disabled: false },
        { id: 16, is_implant: false, is_disabled: false },
      ],
    },
  ];

  upperBuccalData = [
    {
      name: "Furcation",
      data: [
        { id: 1, value: "0", is_disabled: false },
        { id: 2, value: "0", is_disabled: false },
        { id: 3, value: "0", is_disabled: false },
        { id: 4, value: "0", is_disabled: false },
        { id: 5, value1: "0", value2: "0", is_disabled: false },
        { id: 6, value: "0", is_disabled: false },
        { id: 7, value: "0", is_disabled: false },
        { id: 8, value: "0", is_disabled: false },
        { id: 9, value: "0", is_disabled: false },
        { id: 10, value: "0", is_disabled: false },
        { id: 11, value: "0", is_disabled: false },
        { id: 12, value1: "0", value2: "0", is_disabled: false },
        { id: 13, value: "0", is_disabled: false },
        { id: 14, value: "0", is_disabled: false },
        { id: 15, value: "0", is_disabled: false },
        { id: 16, value: "0", is_disabled: false },
      ],
    },
    {
      name: "Bleeding on Probing",
      data: [
        { id: 1, is_checked: 0, is_disabled: false },
        { id: 2, is_checked: 0, is_disabled: false },
        { id: 3, is_checked: 0, is_disabled: false },
        { id: 4, is_checked: 0, is_disabled: false },
        { id: 5, is_checked: 0, is_disabled: false },
        { id: 6, is_checked: 0, is_disabled: false },
        { id: 7, is_checked: 0, is_disabled: false },
        { id: 8, is_checked: 0, is_disabled: false },
        { id: 9, is_checked: 0, is_disabled: false },
        { id: 10, is_checked: 0, is_disabled: false },
        { id: 11, is_checked: 0, is_disabled: false },
        { id: 12, is_checked: 0, is_disabled: false },
        { id: 13, is_checked: 0, is_disabled: false },
        { id: 14, is_checked: 0, is_disabled: false },
        { id: 15, is_checked: 0, is_disabled: false },
        { id: 16, is_checked: 0, is_disabled: false },
      ],
    },
    {
      name: "Plaque Index",
      data: [
        { id: 1, value: "0", is_disabled: false },
        { id: 2, value: "0", is_disabled: false },
        { id: 3, value: "0", is_disabled: false },
        { id: 4, value: "0", is_disabled: false },
        { id: 5, value: "0", is_disabled: false },
        { id: 6, value: "0", is_disabled: false },
        { id: 7, value: "0", is_disabled: false },
        { id: 8, value: "0", is_disabled: false },
        { id: 9, value: "0", is_disabled: false },
        { id: 10, value: "0", is_disabled: false },
        { id: 11, value: "0", is_disabled: false },
        { id: 12, value: "0", is_disabled: false },
        { id: 13, value: "0", is_disabled: false },
        { id: 14, value: "0", is_disabled: false },
        { id: 15, value: "0", is_disabled: false },
        { id: 16, value: "0", is_disabled: false },
      ],
    },
    {
      name: "Mobility Initial",
      data: [
        { id: 1, value: "0", is_disabled: false },
        { id: 2, value: "0", is_disabled: false },
        { id: 3, value: "0", is_disabled: false },
        { id: 4, value: "0", is_disabled: false },
        { id: 5, value: "0", is_disabled: false },
        { id: 6, value: "0", is_disabled: false },
        { id: 7, value: "0", is_disabled: false },
        { id: 8, value: "0", is_disabled: false },
        { id: 9, value: "0", is_disabled: false },
        { id: 10, value: "0", is_disabled: false },
        { id: 11, value: "0", is_disabled: false },
        { id: 12, value: "0", is_disabled: false },
        { id: 13, value: "0", is_disabled: false },
        { id: 14, value: "0", is_disabled: false },
        { id: 15, value: "0", is_disabled: false },
        { id: 16, value: "0", is_disabled: false },
      ],
    },
    {
      name: "Recession Initial",
      data: [
        { id: 1, value: "0", is_disabled: false },
        { id: 2, value: "0", is_disabled: false },
        { id: 3, value: "0", is_disabled: false },
        { id: 4, value: "0", is_disabled: false },
        { id: 5, value: "0", is_disabled: false },
        { id: 6, value: "0", is_disabled: false },
        { id: 7, value: "0", is_disabled: false },
        { id: 8, value: "0", is_disabled: false },
        { id: 9, value: "0", is_disabled: false },
        { id: 10, value: "0", is_disabled: false },
        { id: 11, value: "0", is_disabled: false },
        { id: 12, value: "0", is_disabled: false },
        { id: 13, value: "0", is_disabled: false },
        { id: 14, value: "0", is_disabled: false },
        { id: 15, value: "0", is_disabled: false },
        { id: 16, value: "0", is_disabled: false },
      ],
    },
    {
      name: "Probing Depth Initial",
      data: [
        { id: 1, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 2, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 3, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 4, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 5, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 6, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 7, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 8, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 9, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 10, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 11, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 12, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 13, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 14, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 15, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 16, value1: "0", value2: "0", value3: "0", is_disabled: false },
      ],
    },
  ];

  upperLingualData = [
    {
      name: "Furcation",
      data: [
        { id: 1, value: "0", is_disabled: false },
        { id: 2, value: "0", is_disabled: false },
        { id: 3, value: "0", is_disabled: false },
        { id: 4, value: "0", is_disabled: false },
        { id: 5, value1: "0", value2: "0", is_disabled: false },
        { id: 6, value: "0", is_disabled: false },
        { id: 7, value: "0", is_disabled: false },
        { id: 8, value: "0", is_disabled: false },
        { id: 9, value: "0", is_disabled: false },
        { id: 10, value: "0", is_disabled: false },
        { id: 11, value: "0", is_disabled: false },
        { id: 12, value1: "0", value2: "0", is_disabled: false },
        { id: 13, value: "0", is_disabled: false },
        { id: 14, value: "0", is_disabled: false },
        { id: 15, value: "0", is_disabled: false },
        { id: 16, value: "0", is_disabled: false },
      ],
    },
    {
      name: "Bleeding on Probing",
      data: [
        { id: 1, is_checked: 0, is_disabled: false },
        { id: 2, is_checked: 0, is_disabled: false },
        { id: 3, is_checked: 0, is_disabled: false },
        { id: 4, is_checked: 0, is_disabled: false },
        { id: 5, is_checked: 0, is_disabled: false },
        { id: 6, is_checked: 0, is_disabled: false },
        { id: 7, is_checked: 0, is_disabled: false },
        { id: 8, is_checked: 0, is_disabled: false },
        { id: 9, is_checked: 0, is_disabled: false },
        { id: 10, is_checked: 0, is_disabled: false },
        { id: 11, is_checked: 0, is_disabled: false },
        { id: 12, is_checked: 0, is_disabled: false },
        { id: 13, is_checked: 0, is_disabled: false },
        { id: 14, is_checked: 0, is_disabled: false },
        { id: 15, is_checked: 0, is_disabled: false },
        { id: 16, is_checked: 0, is_disabled: false },
      ],
    },
    {
      name: "Plaque Index",
      data: [
        { id: 1, value: "0", is_disabled: false },
        { id: 2, value: "0", is_disabled: false },
        { id: 3, value: "0", is_disabled: false },
        { id: 4, value: "0", is_disabled: false },
        { id: 5, value: "0", is_disabled: false },
        { id: 6, value: "0", is_disabled: false },
        { id: 7, value: "0", is_disabled: false },
        { id: 8, value: "0", is_disabled: false },
        { id: 9, value: "0", is_disabled: false },
        { id: 10, value: "0", is_disabled: false },
        { id: 11, value: "0", is_disabled: false },
        { id: 12, value: "0", is_disabled: false },
        { id: 13, value: "0", is_disabled: false },
        { id: 14, value: "0", is_disabled: false },
        { id: 15, value: "0", is_disabled: false },
        { id: 16, value: "0", is_disabled: false },
      ],
    },
    {
      name: "Mobility Initial",
      data: [
        { id: 1, value: "0", is_disabled: false },
        { id: 2, value: "0", is_disabled: false },
        { id: 3, value: "0", is_disabled: false },
        { id: 4, value: "0", is_disabled: false },
        { id: 5, value: "0", is_disabled: false },
        { id: 6, value: "0", is_disabled: false },
        { id: 7, value: "0", is_disabled: false },
        { id: 8, value: "0", is_disabled: false },
        { id: 9, value: "0", is_disabled: false },
        { id: 10, value: "0", is_disabled: false },
        { id: 11, value: "0", is_disabled: false },
        { id: 12, value: "0", is_disabled: false },
        { id: 13, value: "0", is_disabled: false },
        { id: 14, value: "0", is_disabled: false },
        { id: 15, value: "0", is_disabled: false },
        { id: 16, value: "0", is_disabled: false },
      ],
    },
    {
      name: "Recession Initial",
      data: [
        { id: 1, value: "0", is_disabled: false },
        { id: 2, value: "0", is_disabled: false },
        { id: 3, value: "0", is_disabled: false },
        { id: 4, value: "0", is_disabled: false },
        { id: 5, value: "0", is_disabled: false },
        { id: 6, value: "0", is_disabled: false },
        { id: 7, value: "0", is_disabled: false },
        { id: 8, value: "0", is_disabled: false },
        { id: 9, value: "0", is_disabled: false },
        { id: 10, value: "0", is_disabled: false },
        { id: 11, value: "0", is_disabled: false },
        { id: 12, value: "0", is_disabled: false },
        { id: 13, value: "0", is_disabled: false },
        { id: 14, value: "0", is_disabled: false },
        { id: 15, value: "0", is_disabled: false },
        { id: 16, value: "0", is_disabled: false },
      ],
    },
    {
      name: "Probing Depth Initial",
      data: [
        { id: 1, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 2, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 3, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 4, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 5, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 6, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 7, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 8, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 9, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 10, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 11, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 12, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 13, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 14, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 15, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 16, value1: "0", value2: "0", value3: "0", is_disabled: false },
      ],
    },
  ];

  lowerBuccalData = [
    {
      name: "Furcation",
      data: [
        { id: 1, value: "0", is_disabled: false },
        { id: 2, value: "0", is_disabled: false },
        { id: 3, value: "0", is_disabled: false },
        { id: 4, value: "0", is_disabled: false },
        { id: 5, value1: "0", value2: "0", is_disabled: false },
        { id: 6, value: "0", is_disabled: false },
        { id: 7, value: "0", is_disabled: false },
        { id: 8, value: "0", is_disabled: false },
        { id: 9, value: "0", is_disabled: false },
        { id: 10, value: "0", is_disabled: false },
        { id: 11, value: "0", is_disabled: false },
        { id: 12, value1: "0", value2: "0", is_disabled: false },
        { id: 13, value: "0", is_disabled: false },
        { id: 14, value: "0", is_disabled: false },
        { id: 15, value: "0", is_disabled: false },
        { id: 16, value: "0", is_disabled: false },
      ],
    },
    {
      name: "Bleeding on Probing",
      data: [
        { id: 1, is_checked: 0, is_disabled: false },
        { id: 2, is_checked: 0, is_disabled: false },
        { id: 3, is_checked: 0, is_disabled: false },
        { id: 4, is_checked: 0, is_disabled: false },
        { id: 5, is_checked: 0, is_disabled: false },
        { id: 6, is_checked: 0, is_disabled: false },
        { id: 7, is_checked: 0, is_disabled: false },
        { id: 8, is_checked: 0, is_disabled: false },
        { id: 9, is_checked: 0, is_disabled: false },
        { id: 10, is_checked: 0, is_disabled: false },
        { id: 11, is_checked: 0, is_disabled: false },
        { id: 12, is_checked: 0, is_disabled: false },
        { id: 13, is_checked: 0, is_disabled: false },
        { id: 14, is_checked: 0, is_disabled: false },
        { id: 15, is_checked: 0, is_disabled: false },
        { id: 16, is_checked: 0, is_disabled: false },
      ],
    },
    {
      name: "Plaque Index",
      data: [
        { id: 1, value: "0", is_disabled: false },
        { id: 2, value: "0", is_disabled: false },
        { id: 3, value: "0", is_disabled: false },
        { id: 4, value: "0", is_disabled: false },
        { id: 5, value: "0", is_disabled: false },
        { id: 6, value: "0", is_disabled: false },
        { id: 7, value: "0", is_disabled: false },
        { id: 8, value: "0", is_disabled: false },
        { id: 9, value: "0", is_disabled: false },
        { id: 10, value: "0", is_disabled: false },
        { id: 11, value: "0", is_disabled: false },
        { id: 12, value: "0", is_disabled: false },
        { id: 13, value: "0", is_disabled: false },
        { id: 14, value: "0", is_disabled: false },
        { id: 15, value: "0", is_disabled: false },
        { id: 16, value: "0", is_disabled: false },
      ],
    },
    {
      name: "Mobility Initial",
      data: [
        { id: 1, value: "0", is_disabled: false },
        { id: 2, value: "0", is_disabled: false },
        { id: 3, value: "0", is_disabled: false },
        { id: 4, value: "0", is_disabled: false },
        { id: 5, value: "0", is_disabled: false },
        { id: 6, value: "0", is_disabled: false },
        { id: 7, value: "0", is_disabled: false },
        { id: 8, value: "0", is_disabled: false },
        { id: 9, value: "0", is_disabled: false },
        { id: 10, value: "0", is_disabled: false },
        { id: 11, value: "0", is_disabled: false },
        { id: 12, value: "0", is_disabled: false },
        { id: 13, value: "0", is_disabled: false },
        { id: 14, value: "0", is_disabled: false },
        { id: 15, value: "0", is_disabled: false },
        { id: 16, value: "0", is_disabled: false },
      ],
    },
    {
      name: "Recession Initial",
      data: [
        { id: 1, value: "0", is_disabled: false },
        { id: 2, value: "0", is_disabled: false },
        { id: 3, value: "0", is_disabled: false },
        { id: 4, value: "0", is_disabled: false },
        { id: 5, value: "0", is_disabled: false },
        { id: 6, value: "0", is_disabled: false },
        { id: 7, value: "0", is_disabled: false },
        { id: 8, value: "0", is_disabled: false },
        { id: 9, value: "0", is_disabled: false },
        { id: 10, value: "0", is_disabled: false },
        { id: 11, value: "0", is_disabled: false },
        { id: 12, value: "0", is_disabled: false },
        { id: 13, value: "0", is_disabled: false },
        { id: 14, value: "0", is_disabled: false },
        { id: 15, value: "0", is_disabled: false },
        { id: 16, value: "0", is_disabled: false },
      ],
    },
    {
      name: "Probing Depth Initial",
      data: [
        { id: 1, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 2, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 3, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 4, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 5, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 6, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 7, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 8, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 9, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 10, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 11, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 12, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 13, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 14, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 15, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 16, value1: "0", value2: "0", value3: "0", is_disabled: false },
      ],
    },
  ];

  lowerLingualData = [
    {
      name: "Furcation",
      data: [
        { id: 1, value: "0", is_disabled: false },
        { id: 2, value: "0", is_disabled: false },
        { id: 3, value: "0", is_disabled: false },
        { id: 4, value: "0", is_disabled: false },
        { id: 5, value1: "0", value2: "0", is_disabled: false },
        { id: 6, value: "0", is_disabled: false },
        { id: 7, value: "0", is_disabled: false },
        { id: 8, value: "0", is_disabled: false },
        { id: 9, value: "0", is_disabled: false },
        { id: 10, value: "0", is_disabled: false },
        { id: 11, value: "0", is_disabled: false },
        { id: 12, value1: "0", value2: "0", is_disabled: false },
        { id: 13, value: "0", is_disabled: false },
        { id: 14, value: "0", is_disabled: false },
        { id: 15, value: "0", is_disabled: false },
        { id: 16, value: "0", is_disabled: false },
      ],
    },
    {
      name: "Bleeding on Probing",
      data: [
        { id: 1, is_checked: 0, is_disabled: false },
        { id: 2, is_checked: 0, is_disabled: false },
        { id: 3, is_checked: 0, is_disabled: false },
        { id: 4, is_checked: 0, is_disabled: false },
        { id: 5, is_checked: 0, is_disabled: false },
        { id: 6, is_checked: 0, is_disabled: false },
        { id: 7, is_checked: 0, is_disabled: false },
        { id: 8, is_checked: 0, is_disabled: false },
        { id: 9, is_checked: 0, is_disabled: false },
        { id: 10, is_checked: 0, is_disabled: false },
        { id: 11, is_checked: 0, is_disabled: false },
        { id: 12, is_checked: 0, is_disabled: false },
        { id: 13, is_checked: 0, is_disabled: false },
        { id: 14, is_checked: 0, is_disabled: false },
        { id: 15, is_checked: 0, is_disabled: false },
        { id: 16, is_checked: 0, is_disabled: false },
      ],
    },
    {
      name: "Plaque Index",
      data: [
        { id: 1, value: "0", is_disabled: false },
        { id: 2, value: "0", is_disabled: false },
        { id: 3, value: "0", is_disabled: false },
        { id: 4, value: "0", is_disabled: false },
        { id: 5, value: "0", is_disabled: false },
        { id: 6, value: "0", is_disabled: false },
        { id: 7, value: "0", is_disabled: false },
        { id: 8, value: "0", is_disabled: false },
        { id: 9, value: "0", is_disabled: false },
        { id: 10, value: "0", is_disabled: false },
        { id: 11, value: "0", is_disabled: false },
        { id: 12, value: "0", is_disabled: false },
        { id: 13, value: "0", is_disabled: false },
        { id: 14, value: "0", is_disabled: false },
        { id: 15, value: "0", is_disabled: false },
        { id: 16, value: "0", is_disabled: false },
      ],
    },
    {
      name: "Mobility Initial",
      data: [
        { id: 1, value: "0", is_disabled: false },
        { id: 2, value: "0", is_disabled: false },
        { id: 3, value: "0", is_disabled: false },
        { id: 4, value: "0", is_disabled: false },
        { id: 5, value: "0", is_disabled: false },
        { id: 6, value: "0", is_disabled: false },
        { id: 7, value: "0", is_disabled: false },
        { id: 8, value: "0", is_disabled: false },
        { id: 9, value: "0", is_disabled: false },
        { id: 10, value: "0", is_disabled: false },
        { id: 11, value: "0", is_disabled: false },
        { id: 12, value: "0", is_disabled: false },
        { id: 13, value: "0", is_disabled: false },
        { id: 14, value: "0", is_disabled: false },
        { id: 15, value: "0", is_disabled: false },
        { id: 16, value: "0", is_disabled: false },
      ],
    },
    {
      name: "Recession Initial",
      data: [
        { id: 1, value: "0", is_disabled: false },
        { id: 2, value: "0", is_disabled: false },
        { id: 3, value: "0", is_disabled: false },
        { id: 4, value: "0", is_disabled: false },
        { id: 5, value: "0", is_disabled: false },
        { id: 6, value: "0", is_disabled: false },
        { id: 7, value: "0", is_disabled: false },
        { id: 8, value: "0", is_disabled: false },
        { id: 9, value: "0", is_disabled: false },
        { id: 10, value: "0", is_disabled: false },
        { id: 11, value: "0", is_disabled: false },
        { id: 12, value: "0", is_disabled: false },
        { id: 13, value: "0", is_disabled: false },
        { id: 14, value: "0", is_disabled: false },
        { id: 15, value: "0", is_disabled: false },
        { id: 16, value: "0", is_disabled: false },
      ],
    },
    {
      name: "Probing Depth Initial",
      data: [
        { id: 1, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 2, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 3, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 4, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 5, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 6, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 7, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 8, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 9, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 10, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 11, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 12, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 13, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 14, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 15, value1: "0", value2: "0", value3: "0", is_disabled: false },
        { id: 16, value1: "0", value2: "0", value3: "0", is_disabled: false },
      ],
    },
  ];

  constructor() {}

  ngOnInit() {
    this.section = "Upper";
    this.date = moment(this.today).format("YYYY-MM-DD");
  }

  getSection(val) {
    this.section = val.value;
  }

  // This will get the data from the perio details component
  getPerioDetails(val) {
    const valClone = _.clone(val);
    this.perioDetails = valClone;
  }

  // This will get the data from the perio data component
  getPerioData(val, dataType) {
    const valClone = _.clone(val);

    if (dataType === "upperBuccal") {
      this.upperBuccalData = valClone;
    } else if (dataType === "upperLingual") {
      this.upperLingualData = valClone;
    } else if (dataType === "lowerBuccal") {
      this.lowerBuccalData = valClone;
    } else if (dataType === "lowerLingual") {
      this.lowerLingualData = valClone;
    }
  }
}
