import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { keypressValidateNumber } from 'src/app/common/helper'
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { EmailToPatientComponent } from './email-to-patient/email-to-patient.component';
import * as moment from 'moment';
import { DentalFormsService } from '../../../services/dental-forms/dental-forms.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

@Component({
  selector: 'ark-post-operative-instruction',
  templateUrl: './post-operative-instruction.component.html',
  styleUrls: ['./post-operative-instruction.component.scss']
})
export class PostOperativeInstructionComponent implements OnInit {

  formGroup: FormGroup;
  isEmpty: boolean = false;
  clinic = null;
  forms = [
    { id: 1, name: 'Denture Care' },
    { id: 2, name: 'Surgery' },
    { id: 3, name: 'Teeth Whitening (Custom)' },
    { id: 4, name: 'Teeth Whitening (Pre-formed)' }
  ];
  form: number = 1;
  header = '';
  postInstructions:any;

  dialogConfig = new MatDialogConfig();
  dialogRef: any;


  dentureCare = {
    instructions: [
      {
        id: 1,
        header: 'Welcome!',
        content: {
          id:  1,
          name: 'Whether you’ve worn dentures in the past or are wearing them for the first time, we’re sure you’ll find this a useful guide. Like millions of others like you, the same questions are being asked. By answering these questions, we hope this guide will help you adjust to your new dentures.',
          hasSubContent: false
        }
      },
      {
        id: 2,
        header: 'What are dentures?',
        content: {
          id:  2,
          name: 'Dentures are worn in replacement of your lost/missing teeth. They are usually made from lifelike resin teeth bonded to a plastic or metal base. You’ll either be fitted with complete dentures to replace all your teeth, or a partial denture (usually held in place by metal clasps that fit around your remaining teeth).',
          hasSubContent: false
        }
      },
      {
        id: 3,
        header: 'Will anyone be able to tell them from my natural teeth?',
        content: {
          id:  3,
          name: 'Not easily. With today’s technology, dentures can be made to look so natural, people can’t tell who’s wearing them. Dental professionals custom make dentures to fit in the mouth, so they’ll take on the character of your original teeth, leaving a natural appearance. Teeth color is carefully selected to match either the remaining natural teeth or to simply look as natural as possible.',
          hasSubContent: false
        }
      },
      {
        id: 4,
        header: 'How easy is it to adjust to wearing them?',
        content: {
          id:  4,
          name: 'While your dentures have been custom made, they may initially feel strange, or even a mouthful, however, rest assured that they only feel that way, you yourself won’t look any different. You may find you produce more saliva than normal but this should settle down soon enough. The time it takes to adjust to wearing new dentures is different for everyone, but you’ll soon learn how to eat, talk and smile as you would with natural teeth. Some people find it very helpful to practice speaking or reading in front of a mirror to help them get used to the position and feel of the denture in the mouth.',
          hasSubContent: false
        }
      },
      {
        id: 5,
        header: 'Can I take my dentures out at night?',
        content: {
          id:  5,
          name: 'It may help you adjust to your new dentures if you keep them in place for the first few nights, allowing them to “settle in”. If you decide to keep them overnight after that, it is important that you clean them thoroughly before you go to bed, just as you would your natural teeth. If you don’t mind leaving them out at night, your mouth and gums will have time to recover from the effort of supporting your dentures during the day and will experience the soothing effects of your saliva flow.',
          hasSubContent: false
        }
      },
      {
        id: 6,
        header: 'If my dentures hurt, what do I do?',
        content: {
          id:  6,
          name: 'If you have pain whatsoever, you should visit your dentist as soon as possible, don’t wait for your regular six-month visit. Pain shouldn’t occur and probably means something’s not quite right. Don’t take your dentures out though, leave them in at least 24 hours, that way, when you visit your dentist, he/she will be able to see where it’s sore and sort the problem out quicker.',
          hasSubContent: false
        }
      },
      {
        id: 7,
        header: 'Do I still need to visit my dentist after getting my new dentures?',
        content: {
          id:  7,
          name: 'Yes. Your dentures are made from materials that are softer than your natural teeth, so they’ll eventually need replacing. Also, your mouth changes shape after your teeth have been removed and even losing weight can affect the shape of your mouth. So, to make sure that your dentures continue to fit properly, it is important that you visit your dentist every six months to have them checked. Dentures usually need replacement after 2-3 years.',
          hasSubContent: false
        }
      },
      {
        id: 8,
        header: 'So how do I clean my dentures?',
        content: {
          id:  8,
          name: 'When cleaning your dentures always remove them from your mouth beforehand to clean them properly. As a general guide, remember that dentures are fragile and need to be handled carefully when out of the mouth. The main purpose of cleaning is to remove the dental plaque which builds up on the dentures, as well as the teeth, which can harm the gums. To do so, brush your denture with a denture toothbrush using liquid soap. In addition, clean dentures with effervescent denture tablets in water (follow manufacturer’s instructions) regularly. These cleansing denture tablets help in removing the following: stains, bacterial plaque (to promote healthy gums), and odor-causing film, leaving dentures fresh and clean.',
          hasSubContent: false
        }
      },
      {
        id: 9,
        header: 'So what are denture adhesives?',
        content: {
          id:  9,
          name: 'Using denture adhesives should dispel many of the doubts and fears you may have. An adhesive will help in many ways, whether you’ve just had your dentures fitted, or you’ve had them for a long time. An adhesive acts as a supporting layer between your dentures and your gums, preventing them from rubbing or dislodging. It also helps increase bite force and allows you to enjoy a wider range of foods.',
          hasSubContent: false
        }
      }
    ],
    note: 'If you have any questions or other concerns about your denture, please feel free to contact us. Thank you.'
  }

  surgery = {
    instructions: [
      {
        id: 1,
        header: 'Day 1 (first 24 hours)',
        content: {
          id: 1,
          name:  'Things to remember:',
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '1. Bite on Gauze Pack for 20-30 mins after tooth extraction. DO NOT REPLACE with anything else.',
              hasOthers: false,
            },
            {
              id: 2,
              name: '2. Do not rinse or spit forcefully, just allow saliva to drip, and then wipe the mouth with tissue or cloth, to avoid dislodgement of the blood clot.',
              hasOthers: false,
            },
            {
              id: 3,
              name: '3. AVOID the following:',
              hasOthers: true,
              others : [
                {
                  id: 1,
                  name: 'a. drinking through a straw or taking in hot foods/liquids'
                },
                {
                  id: 2,
                  name: 'b. physical exertion/strenuous exercise'
                },
                {
                  id: 3,
                  name: 'c. smoking/drinking alcoholic beverages'
                },
                {
                  id: 4,
                  name: 'd. touching/playing with the wound area using the tongue or fingers (DO NOT use toothpicks, Q-tips, or other objects to remove food impacted in the wound. Please rinse carefully.)'
                },
                {
                  id: 5,
                  name: 'e. sneezing, coughing, or blowing/clearing the nose.'
                }
              ]
            },
            {
              id: 4,
              name: '4. When sleeping, keep head elevated by using two pillows.',
              hasOthers: false,
            },
            {
              id: 5,
              name: '5. Eat soft solids. If possible, take in foods and liquids that are cold. Taking in hot foods and beverages may cause bleeding.',
              hasOthers: false,
            },
            {
              id: 6,
              name: '6. Brush teeth normally using extra care. Avoid area near the wound.',
              hasOthers: false,
            },
            {
              id: 7,
              name: '7. Apply COLD COMPRESS on the skin area over the extraction site for 20 mins every hour.',
              hasOthers: false,
            },
          ]
        }
      },
      {
        id: 2,
        header: 'Day 2 (next 24 hours until 48-72 hours)',
        content: {
          id: 2,
          name:  'Things to remember:',
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '1. Gargle Saline Solution for one minute, 3x a day for 3-5 days (use ½ tsp salt dissolved in ½ cup warm water). Do this after brushing.',
              hasOthers: false,
            },
            {
              id: 2,
              name: '2. Optional: Gargle undiluted 1% Oral Betadine for one minute in the evening after brushing teeth. Do this before bedtime.',
              hasOthers: false,
            },
            {
              id: 3,
              name: '3. Apply WARM COMPRESS on the skin area over the extraction site for 20 mins every hour.',
              hasOthers: false,
            }
          ]
        }
      },
      {
        id: 3,
        header: 'IMPORTANT REMINDERS:',
        content: {
          id: 3,
          name:  '', 
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '1. Please take your medications as prescribed. If you have any questions or other concerns about the recent dental procedure, feel free to contact us. If there should be unusual bleeding or swelling, please inform us immediately. Thank you.',
              hasOthers: true,
              others : [
                {
                  id: 1,
                  name: 'Your next recall appointment will be on:',
                  hasResultNeeded: true,
                  name2: 'at',
                  name3: 'for the following procedure'
                }
              ]
            },
            {
              id: 2,
              name: '2. To prevent any complications or infection from occurring, the reminders stated above must be strictly followed and medications must be taken as prescribed.',
              hasOthers: false
            },
            {
              id: 3,
              name: '3. AVOID the following:',
              hasOthers: true,
              others : [
                {
                  id: 1,
                  name: 'a. Mild swelling'
                },
                {
                  id: 2,
                  name: 'b. Pain or Sensitivity'
                },
                {
                  id: 3,
                  name: 'c. Hematoma'
                },
                {
                  id: 4,
                  name: 'd. Light bleeding'
                }
              ]
            },
            {
              id: 4,
              name: '4. Possible complications:',
              hasOthers: true,
              others : [
                {
                  id: 1,
                  name: 'a. Dry socket'
                },
                {
                  id: 2,
                  name: 'b. Paresthesia (loss of sensation)'
                },
                {
                  id: 3,
                  name: 'c. Infection'
                },
                {
                  id: 4,
                  name: 'd. Severe swelling'
                }
              ]
            }
          ]
        }
      }
    ],
    note: 'If possible, wait at least 2 weeks before traveling by airplane. Changes in cabin pressure during the flight may cause pain on the surgical site, though this is a temporary reaction only. A pain medication may be taken before the flight.'
  }

  teethWhiteningPreFormed = {
    instructions: [
      {
        id: 1,
        header: 'Instructions on Teeth Whitening for Pre-formed Trays',
        content: {
          id: 1,
          name:  '', 
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '1. Brush and Floss teeth before starting with the Take-Home Whitening Kit.',
              hasOthers: false,
            },
            {
              id: 2,
              name: '2. Open the pack and insert whitening tray accordingly into upper/lower jaw and allow to seat firmly and correctly over teeth by pressing with your fingers.',
              hasOthers: false,
            },
            {
              id: 3,
              name: '3. Then remove the colored rubber tray leaving the transparent silicon film over the teeth. Close lips and press lips against teeth to allow the whitening gel to spread over the teeth.',
              hasOthers: false,
            },
            {
              id: 4,
              name: '4. Remove any excess whitening gel that spills out from the whitening tray using a Q-tip. This is to avoid any irritation along the gum line. Wear this for 1 hour, unless otherwise advised. Note that tooth sensitivity may be experienced while teeth whitening. This is normal.'
            },
            {
              id: 5,
              name: '5. After one hour, remove trays and discard. Rinse well and brush teeth to remove any whitening gel left on the teeth.',
              hasOthers: false,
            },
            {
              id: 6,
              name: '6. Repeat steps 1-5 again every day for 5-10 days unless otherwise advised.',
              hasOthers: false,
            }
          ]
        }
      },
      {
        id: 2,
        header: '',
        content: {
          id: 1,
          name:  'Things to remember:', 
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '1. Avoid any colored foods and beverages during the time you are whitening your teeth, until 14 days after your last day of whitening. (e.g. Coffee, Tea, Red Wine, Tomato-based products, etc.) Note that if these cannot be avoided during certain occasions, please rinse well with a lot water after eating/drinking any of these.',
              hasOthers: false,
            },
            {
              id: 2,
              name: '2. Always brush after meals to remove any food particles that can cause staining of your whitened teeth.',
              hasOthers: false,
            },
            {
              id: 3,
              name: '3. Use a whitening-toothpaste to maintain longer lasting-effect.',
              hasOthers: false,
            }
          ]
        }
      },
    ],
    note: 'Please follow instructions accordingly. If you have any questions or other concerns, please feel free to contact us. Thank you.'
  }

  teethWhiteningCustom = {
    instructions: [
      {
        id: 1,
        header: 'Instructions on Teeth Whitening for Custom-made Trays',
        content: {
          id: 1,
          name:  '', 
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '1. Brush and Floss teeth before starting with the Take-Home Whitening Kit.',
              hasOthers: false,
            },
            {
              id: 2,
              name: '2. Apply a small pea-size amount on the inner front surfaces of the whitening tray, as instructed.',
              hasOthers: false,
            },
            {
              id: 3,
              name: '3. Insert whitening tray accordingly into upper/lower jaw and allow to seat firmly and correctly over teeth.',
              hasOthers: false,
            },
            {
              id: 4,
              name: '4. Remove any excess whitening gel that spills out from the whitening tray using a Q-tip. This is to avoid any irritation along the gum line since you will be wearing it overnight. Wear this for 6-8 hour every night, unless otherwise advised. Note that tooth sensitivity may be experienced while teeth whitening. This is normal.',
              hasOthers: false,
            },
            {
              id: 5,
              name: '5. In the morning after waking up, remove trays and set aside. Rinse well and brush teeth to remove any whitening gel leftover. Then clean tray with a separate toothbrush, using liquid soap. Then rinse them well to remove any whitening gel remaining. Dry and store in a container.',
              hasOthers: false,
            },
            {
              id: 6,
              name: '6. Repeat steps 1-5 again every day for 7-14 days or as instructed.',
              hasOthers: false,
            }
          ]
        }
      },
      {
        id: 2,
        header: '',
        content: {
          id: 2,
          name:  'Things to remember:', 
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '1. Avoid any colored foods and beverages during the time you are whitening your teeth, until 14 days after your last day of whitening. (e.g. Coffee, Tea, Red Wine, Tomato-based products, etc.) Note that if these cannot be avoided during certain occasions, please rinse well with a lot water after eating/drinking any of these.',
              hasOthers: false,
            },
            {
              id: 2,
              name: '2. Always brush after meals to remove any food particles that can cause staining of your whitened teeth.',
              hasOthers: false,
            },
            {
              id: 3,
              name: '3. Use a whitening-toothpaste to maintain longer lasting-effect.',
              hasOthers: false,
            }
          ]
        }
      }
    ],
    note: 'Please follow instructions accordingly. If you have any questions or other concerns, please feel free to contact us. Thank you.'
  }

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private dentalForms: DentalFormsService,
    public dialog: MatDialog,
  ) {
    this.header = 'Post Operative Instructions and Denture Care';
    this.clinic = this.auth.getAuthUser().user_clinic;
    this.formGroup = this.formBuilder.group({
      form: [''],
      address: [''],
      telephone_no: [''],
      mobile_no: [''],
      clinic_hours: [''],
      website: [''],
      email: [''],
      appointment_date: [''],
      appointment_time: [''],
      appointment_procedure: [''],
    });
    this.formGroup.get('form').setValue(1);
    this.formGroup.get('address').setValue(this.clinic.address ? this.clinic.address : '');
    this.formGroup.get('telephone_no').setValue(this.clinic.contact_number ? this.clinic.contact_number : '');
    this.formGroup.get('mobile_no').setValue(this.clinic.mobile_number ? this.clinic.mobile_number : '');
    this.formGroup.get('clinic_hours').setValue(this.clinic.clinic_hours ? this.clinic.clinic_hours : '');
    this.formGroup.get('website').setValue(this.clinic.website ? this.clinic.website : '');
    this.formGroup.get('email').setValue(this.clinic.email ? this.clinic.email : '');
    
    if(this.formGroup.value.website == null) {
      this.isEmpty = true;
    } else {
      this.isEmpty = false;
    }
  }

  ngOnInit() {
    this.postInstructions = this.dentureCare;
  }

  getForm(val) {
    this.form = val.value;
    if(this.form == 1) {
      this.header = 'Post Operative Instructions and Denture Care';
      this.postInstructions = this.dentureCare;
    } else if(this.form == 2) {
      this.header = 'Post Operative Instructions'
      this.postInstructions = this.surgery;
    } else if(this.form == 3) {
      this.header = 'Instructions on Teeth Whitening for Custom-made Trays'
      this.postInstructions = this.teethWhiteningCustom;
    } else {
      this.header = 'Instructions on Teeth Whitening for Pre-formed Trays'
      this.postInstructions = this.teethWhiteningPreFormed;
    }

    this.formGroup.get('form').setValue(this.form);
    this.formGroup.get('address').setValue(this.clinic.address ? this.clinic.address : '');
    this.formGroup.get('telephone_no').setValue(this.clinic.contact_number ? this.clinic.contact_number : '');
    this.formGroup.get('mobile_no').setValue(this.clinic.mobile_number ? this.clinic.mobile_number : '');
    this.formGroup.get('clinic_hours').setValue(this.clinic.clinic_hours ? this.clinic.clinic_hours : '');
    this.formGroup.get('website').setValue(this.clinic.website ? this.clinic.website : '');
    this.formGroup.get('email').setValue(this.clinic.email ? this.clinic.email : '');
    this.formGroup.get('appointment_date').setValue('');
    this.formGroup.get('appointment_time').setValue('');
    this.formGroup.get('appointment_procedure').setValue('');
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  download() {
    this.dialogConfig.data = {
      data: this.formGroup.value
    };
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogRef = this.dialog.open(EmailToPatientComponent, this.dialogConfig);
  }

  print() {
    const date = this.formGroup.value.appointment_date ? moment(this.formGroup.value.appointment_date).format('MM/DD/YYYY') : '';
    const patientId = 0;
    const FORM_1 = {
      user_clinic_id: this.clinic.id,
      address: this.formGroup.value.address, 
      telephone_no: this.formGroup.value.telephone_no.toString(), 
      mobile_no: this.formGroup.value.mobile_no.toString(),
      clinic_hours: this.formGroup.value.clinic_hours,
      website: this.formGroup.value.website,
      email: this.formGroup.value.email,
      patient_id: patientId,
      print: true
    };
    const FORM_2 = {
      user_clinic_id: this.clinic.id,
      address: this.formGroup.value.address, 
      telephone_no: this.formGroup.value.telephone_no.toString(), 
      mobile_no: this.formGroup.value.mobile_no.toString(),
      clinic_hours: this.formGroup.value.clinic_hours,
      website: this.formGroup.value.website,
      email: this.formGroup.value.email,
      appointment_date: date, 
      appointment_time: this.formGroup.value.appointment_time, 
      appointment_procedure: this.formGroup.value.appointment_procedure, 
      patient_id: patientId,
      print: true
    };
    const FORM_3 = {
      user_clinic_id: this.clinic.id,
      address: this.formGroup.value.address, 
      telephone_no: this.formGroup.value.telephone_no.toString(), 
      mobile_no: this.formGroup.value.mobile_no.toString(),
      clinic_hours: this.formGroup.value.clinic_hours,
      website: this.formGroup.value.website,
      email: this.formGroup.value.email,
      patient_id: patientId,
      print: true,
      form: 1
    };
    const FORM_4 = {
      user_clinic_id: this.clinic.id,
      address: this.formGroup.value.address, 
      telephone_no: this.formGroup.value.telephone_no.toString(), 
      mobile_no: this.formGroup.value.mobile_no.toString(),
      clinic_hours: this.formGroup.value.clinic_hours,
      website: this.formGroup.value.website,
      email: this.formGroup.value.email,
      patient_id: patientId,
      print: true,
      form: ''
    };
    if(this.form == 1) {
      this.dentalForms.printPOIDentureCare(FORM_1).subscribe(res => {
        if(res) {
          const url = URL.createObjectURL(res);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = url;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'Print Failed.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }));
    } else if(this.form == 2) {
      this.dentalForms.printPOISurgery(FORM_2).subscribe(res => {
        if(res) {
          const url = URL.createObjectURL(res);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = url;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'Print Failed.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }));
    } else if(this.form == 3) {
      this.dentalForms.printPOITeethWhiteningCustom(FORM_3).subscribe(res => {
        if(res) {
          const url = URL.createObjectURL(res);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = url;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'Print Failed.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }));
    } else if(this.form == 4) {
      this.dentalForms.printPOITeethWhiteningPreFormed(FORM_4).subscribe(res => {
        if(res) {
          const url = URL.createObjectURL(res);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = url;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'Print Failed.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }));
    }
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }
}
