import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAddComponent } from './components/user-add/user-add.component';
import { ClinicSetupComponent } from './components/clinic-setup/clinic-setup.component';
import { SharedModule } from '../shared/shared.module';
import { MatFormFieldModule, MatInputModule, MatCheckboxModule, MatButtonModule, MatIconModule, MatAutocompleteModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { ClinicComponent } from './components/clinic/clinic.component';
import { UserAccountsComponent } from './components/user-accounts/user-accounts.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { UserService } from './services/user/user.service';
import { AlertMessageComponent } from '../shared/components/alert-message/alert-message.component';
import { AddUserClinicComponent } from './components/clinic/add-user-clinic/add-user-clinic.component';
import { MyProfileCameraComponent } from './components/my-profile/my-profile-camera/my-profile-camera.component';
import { WebcamModule } from 'ngx-webcam';
import { CameraComponent } from './components/my-profile/my-profile-camera/camera/camera.component';
import { UnsubscribeClinicComponent } from './components/clinic/unsubscribe-clinic/unsubscribe-clinic.component';
import { AccountConfigurationComponent } from '../shared/components/account-configuration/account-configuration.component';
import { BillingDetailsComponent } from './components/billing-details/billing-details.component';
import { SubscribeClinicComponent } from './components/clinic/subscribe-clinic/subscribe-clinic.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  // suppressScrollX: true
}
@NgModule({
  declarations: [
    AccountSettingsComponent,
    UserAddComponent, 
    ClinicSetupComponent, 
    MyProfileComponent, 
    ClinicComponent, 
    UserAccountsComponent, 
    ChangePasswordComponent, 
    AddUserClinicComponent, 
    MyProfileCameraComponent, 
    CameraComponent, 
    UnsubscribeClinicComponent, 
    BillingDetailsComponent, 
    SubscribeClinicComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    WebcamModule,
    PerfectScrollbarModule
  ],
  exports: [
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    SubscribeClinicComponent
  ],
  providers: [
    UserService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  entryComponents: [
    AlertMessageComponent,
    UserAddComponent,
    ClinicSetupComponent,
    AddUserClinicComponent,
    MyProfileCameraComponent,
    UnsubscribeClinicComponent,
    AccountConfigurationComponent
  ]
})
export class AccountSettingsModule { }
