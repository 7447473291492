import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PatientService } from 'src/app/modules/front-desk/service/patient/patient.service';
import { keypressValidateNumber } from 'src/app/common/helper';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';

@Component({
  selector: 'ark-emergency-contact',
  templateUrl: './emergency-contact.component.html',
  styleUrls: ['./emergency-contact.component.scss']
})
export class EmergencyContactComponent implements OnInit {
  
  @Input('selectedIndex') selectedIndex: number; 
  @Output('selectedIndexChange') selectedIndexChange: EventEmitter<any> = new EventEmitter;
  @Output() isDisabled: EventEmitter<any> = new EventEmitter;

  errorMessage = null;
  name = '';
  relationship = '';
  ec_mobile_number = '';
  ec_landline_number = '';
  success = false;
  formGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private auth: AuthService
  ) { 
    this.formGroup = this.formBuilder.group({
      name: [''],
      relationship: [''],
      ec_mobile_number: [''],
      ec_landline_number: ['']
    });
  }

  ngOnInit() {

  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }

  goToDentalHistory() {
    this.name = this.formGroup.value.name;
    this.relationship = this.formGroup.value.relationship;
    this.ec_mobile_number = this.formGroup.value.ec_mobile_number;
    this.ec_landline_number = this.formGroup.value.ec_landline_number;
    this.patientService.checkEmergencyContact(this.name, this.relationship, this.ec_mobile_number.toString(), this.ec_landline_number.toString(), this.auth.getAuthUser().user_clinic.id).subscribe(response => {
      if (response) {
        this.success = true;
        this.patientService.setData(this.formGroup.value);
        const tab = this.selectedIndex = this.selectedIndex + 1;
        this.selectedIndexChange.emit(tab);
      }
      this.isDisabled.emit(false);
    },(err =>{
      this.errorMessage = err.error.errors;
      this.triggerErrors(err.error.errors);
      this.isDisabled.emit(true);
    }));
  }

  goToPrivateInfo() {
    const tab = this.selectedIndex = this.selectedIndex - 1;
    this.selectedIndexChange.emit(tab);
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }
}
