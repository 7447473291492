import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ClinicSetupComponent } from '../account-settings/components/clinic-setup/clinic-setup.component';
import { SharedModule } from '../shared/shared.module';
import { UserService } from '../account-settings/services/user/user.service';
import { CreateAppointmentComponent } from './components/create-appointment/create-appointment.component';
import { RecallVisitComponent } from './components/recall-visit/recall-visit.component';
import { DailyReportComponent } from './components/daily-report/daily-report.component';
import { MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatDatepickerModule, MatNativeDateModule, MatSelectModule, MatTooltipModule } from '@angular/material';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ViewAppointmentComponent } from './components/view-appointment/view-appointment.component';
import { ViewFilterComponent } from './components/view-filter/view-filter.component';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { DashboardService } from './services/dashboard/dashboard.service';
import { ConfirmDeletionComponent } from '../shared/components/confirm-deletion/confirm-deletion.component';
import { DayViewComponent } from './components/calendar/day-view/day-view.component';
import { WeekViewComponent } from './components/calendar/week-view/week-view.component';
import { MonthViewComponent } from './components/calendar/month-view/month-view.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { RecallVisitListsComponent } from './components/recall-visit/recall-visit-lists/recall-visit-lists.component';
import { SuiModule } from 'ng2-semantic-ui';
import { DailyReportSettingsComponent } from './components/daily-report/daily-report-settings/daily-report-settings.component';
import { DailyReportService } from './services/daily-report/daily-report.service';
import { SubscribeClinicComponent } from '../account-settings/components/clinic/subscribe-clinic/subscribe-clinic.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  // suppressScrollX: true
}
@NgModule({
  declarations: [
    DashboardComponent,
    CreateAppointmentComponent,
    RecallVisitComponent,
    DailyReportComponent,
    ViewAppointmentComponent,
    ViewFilterComponent,
    DateFilterComponent,
    DayViewComponent,
    WeekViewComponent,
    MonthViewComponent,
    RecallVisitListsComponent,
    DailyReportSettingsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    BrowserAnimationsModule,
    SuiModule,
    PerfectScrollbarModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  exports: [
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule
  ],
  providers: [
    UserService,
    DashboardService,
    DailyReportService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  entryComponents: [
    ClinicSetupComponent,
    CreateAppointmentComponent,
    ViewAppointmentComponent,
    ConfirmDeletionComponent,
    RecallVisitListsComponent,
    DailyReportSettingsComponent,
    SubscribeClinicComponent
  ]
})
export class DashboardModule { }
