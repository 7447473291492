import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

@Component({
  selector: 'ark-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  newPassword =  true;
  confirmPassword = true;
  errorMessage = '';
  passwordError = '';
  confirmPasswordError = '';
  success = false;
  isExpired = false;

  loginForm: FormGroup;

  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private activedRoute: ActivatedRoute,
    private _router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.verifyToken();
    this.loginForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      password_confirmation: ['', Validators.required]
    });
    this.dialogConfig.width = '500px';
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }
  
  resetPassword() {
    let data = {
      token: this.activedRoute.params['value'].id,
      password: this.loginForm.value.password,
      password_confirmation: this.loginForm.value.password_confirmation
    };
    this.auth.resetPassword(data).subscribe(response => {
      if (response) {
        this.success = true;
        this.dialogConfig.data = {
          title: 'Congratulations!',
          message: 'You have successfully changed your password. \nPlease login to the system with your new password.',
          button: 'Back to Login',
          event: this.backToLogin
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => this.backToLogin());
      } 
    },(err => {
      if (err.error.errors === undefined) {
        this.errorMessage = err.error.message;
      } else {
        this.passwordError = err.error.errors.password;
        this.confirmPasswordError = err.error.errors.password_confirmation;
      }
      this.triggerErrors();
    }));
  }

  triggerErrors() {
    this.loginForm.controls['password'].setErrors({'invalid': true});
    this.loginForm.controls['password'].markAsTouched();
    this.loginForm.controls['password'].markAsDirty();

    this.loginForm.controls['password_confirmation'].setErrors({'invalid': true});
    this.loginForm.controls['password_confirmation'].markAsTouched();
    this.loginForm.controls['password_confirmation'].markAsDirty();
  }
  
  verifyToken(){
    this.auth.verifyToken(this.activedRoute.params['value'].id).subscribe(res => {
    },(err => {
        this.isExpired = true;
        this.dialogConfig.data = {
          title: 'Oops!',
          // message: err.error.errors.token,
          message: 'Reset password link has already been used or expired. \nGo back to login and click on Forgot Password to reset your password again. Thank you!',
          button: 'Back to Login',
          event: this.backToLogin
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => this.backToLogin());
    }));
  }

  backToLogin() {
    this._router.navigate(["/login"]);
  }
}
