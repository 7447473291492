import { Component, OnInit, Input, ElementRef, OnChanges } from '@angular/core';

@Component({
  selector: 'ark-donut-c',
  templateUrl: './donut-c.component.html',
  styleUrls: ['./donut-c.component.scss']
})
export class DonutCComponent implements OnInit, OnChanges {

  @Input() toothData: any;

  crown: any;
  surface: any;
  conditionColor = {
    'caries' : '#ed1b23',
    'composite' : '#3f47cc',
    'amalgam' : '#adadad',
    'recurrentCaries' : '#ed1b23'
  }
  crownColor = {
    'ceramic' : '#F7D8AA',
    'pfm' : '#F7D8AA',
    'gold' : '#ffc90b',
    'metal' : '#959595'
  }
  inlayColor = {
    'ceramic-inlay' : '#F7D8AA',
    'gold-inlay' : '#ffc90b',
    'metal-inlay' : '#959595'
  }

  constructor(
    private element: ElementRef
  ) { }

  ngOnInit() {
    
  }

  ngOnChanges() {
    // if(this.toothData) {
      this.onColorChange();
    // }
  }

  onColorChange() {
    this.crown = this.element.nativeElement.querySelector('[class=crown]').children[0];
    if(this.toothData !== '') {
      for (let element of this.crown.children) {
        if(!element.classList.contains('tooth-border')) {
          element.style.fill = "none";
        }
      }
      //Process Crown
      if(this.toothData.crown !== '') {
        for (let element of this.crown.children) {
          if(!element.classList.contains('tooth-border')) {
            if(this.crownColor[this.toothData['crown']] != undefined) {
              element.style.fill = this.crownColor[this.toothData['crown']];
            }
            if(this.toothData['crown'] == 'metal' && [].some.call(element.classList, c => /.*--stripe/.test(c))) {
              element.style.fill = 'white';
            }
          } 
        }
      }
      //Process Surfaces
      this.surface = this.toothData['surface'].forEach(surface => {
        const surfaceName = Object.keys(surface)[0];
        let hasRecurrentCaries = false;
        let hasMetalInlay = false;
        //Process Inlays
        if(surface[surfaceName].inlay !== '') {
          for (let element of this.crown.children) {
            if(!element.classList.contains('tooth-border')) {
              if(element.classList.contains(surfaceName) && this.inlayColor[surface[surfaceName].inlay] != undefined) {
                element.style.fill = this.inlayColor[surface[surfaceName].inlay]; 
              }
              if(surface[surfaceName].inlay == 'metal-inlay') {
                hasMetalInlay = true; 
              }
            }
          }
        }
        //Process Condition per Surface
        surface[surfaceName].condition.forEach(condition => {
          if(condition == 'recurrentCaries') {
            hasRecurrentCaries = true;
          } else {
            for (let element of this.crown.children) {
              if(element.classList.contains(surfaceName) && this.conditionColor[condition] != undefined) {
                element.style.fill = this.conditionColor[condition]; 
              }
            }
          }
        });
        //Process Surfaces with Recurrent Caries
        if(hasMetalInlay) {
          for (let element of this.crown.children) {
            if(element.classList.contains(surfaceName) && [].some.call(element.classList, c => /.*--stripe/.test(c))) {
              element.style.fill = 'white'; 
            }
          }
        }
        //Process Surfaces with Recurrent Caries
        if(hasRecurrentCaries) {
          for (let element of this.crown.children) {
            if(element.classList.contains(surfaceName) && [].some.call(element.classList, c => /.*--stripe/.test(c))) {
              element.style.fill = this.conditionColor['recurrentCaries']; 
            }
          }
        }
      });
    } else {
      for (let element of this.crown.children) {
        if(!element.classList.contains('tooth-border')) {
          element.style.fill = "none";
        }
      }
    }
  }
}