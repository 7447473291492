import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplashComponent } from './components/splash/splash.component';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { ButtonComponent } from './components/buttons/button/button.component';
import { MatButtonModule, MatMenuModule, MatIconModule, MatRadioModule, MatDialogModule, MatTabsModule, MatExpansionModule, MatProgressSpinnerModule, MatSidenavModule, MatSlideToggleModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { FooterComponent } from './components/footer/footer.component';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { UploadComponent } from './components/buttons/upload/upload.component';
import { ArchiveComponent } from './components/buttons/archive/archive.component';
import { DownloadComponent } from './components/buttons/download/download.component';
import { EditComponent } from './components/icons/edit/edit.component';
import { TrashComponent } from './components/icons/trash/trash.component';
import { SaveComponent } from './components/icons/save/save.component';
import { LoaderComponent } from './components/loader/loader.component';
import { CloseComponent } from './components/icons/close/close.component';
import { ConfirmDeletionComponent } from './components/confirm-deletion/confirm-deletion.component';
import { AddComponent } from './components/buttons/add/add.component';
import 'hammerjs';
import { PrintComponent } from './components/buttons/print/print.component';
import { ShowComponent } from './components/icons/show/show.component';
import { RemoveComponent } from './components/icons/remove/remove.component';
import { GeneratePdfComponent } from './components/buttons/generate-pdf/generate-pdf.component';
import { SendEmailComponent } from './components/buttons/send-email/send-email.component';
import { SettingsComponent } from './components/icons/settings/settings.component';
import { DownloadIconComponent } from './components/icons/download-icon/download-icon.component';
import { EmailIconComponent } from './components/icons/email-icon/email-icon.component';
import { TableComponent } from './components/table/table.component';
import { AddIconComponent } from './components/icons/add-icon/add-icon.component';
import { UndoButtonComponent } from './components/buttons/undo-button/undo-button.component';
import { ConfirmTimeoutComponent } from './components/confirm-timeout/confirm-timeout.component';
import { ConfirmClinicSwitchComponent } from './components/confirm-clinic-switch/confirm-clinic-switch.component';
import { LoaderService } from './services/loader/loader.service';
import { ConfirmUpdateComponent } from './components/confirm-update/confirm-update.component';
import { AccountConfigurationComponent } from './components/account-configuration/account-configuration.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { CustomerAgreementComponent } from './components/customer-agreement/customer-agreement.component';
import { ESignatureComponent } from './components/e-signature/e-signature.component';
import { SignaturePadModule } from '@ng-plus/signature-pad';
import { ConfirmBillingAdditionComponent } from './components/confirm-billing-addition/confirm-billing-addition.component';
import { DentistShareComponent } from './components/dentist-share/dentist-share.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    SplashComponent,
    CopyrightComponent,
    ButtonComponent,
    FooterComponent,
    AlertMessageComponent,
    UploadComponent,
    ArchiveComponent,
    DownloadComponent,
    EditComponent,
    TrashComponent,
    SaveComponent,
    LoaderComponent,
    CloseComponent,
    ConfirmDeletionComponent,
    AddComponent,
    PrintComponent,
    ShowComponent,
    RemoveComponent,
    GeneratePdfComponent,
    SendEmailComponent,
    SettingsComponent,
    DownloadIconComponent,
    EmailIconComponent,
    TableComponent,
    AddIconComponent,
    UndoButtonComponent,
    ConfirmTimeoutComponent,
    ConfirmClinicSwitchComponent,
    ConfirmUpdateComponent,
    AccountConfigurationComponent,
    TermsAndConditionsComponent,
    CustomerAgreementComponent,
    ESignatureComponent,
    ConfirmBillingAdditionComponent,
    DentistShareComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatRadioModule,
    MatDialogModule,
    MatTabsModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSlideToggleModule,
    SignaturePadModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    SplashComponent,
    CopyrightComponent,
    ButtonComponent,
    FooterComponent,
    AlertMessageComponent,
    UploadComponent,
    ArchiveComponent,
    DownloadComponent,
    EditComponent,
    TrashComponent,
    SaveComponent,
    LoaderComponent,
    CloseComponent,
    ConfirmDeletionComponent,
    AddComponent,
    PrintComponent,
    ShowComponent,
    RemoveComponent,
    GeneratePdfComponent,
    SendEmailComponent,
    SettingsComponent,
    DownloadIconComponent,
    EmailIconComponent,
    AddIconComponent,
    UndoButtonComponent,
    ConfirmTimeoutComponent,
    MatMenuModule,
    MatIconModule,
    MatRadioModule,
    MatDialogModule,
    MatTabsModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSlideToggleModule,
    TableComponent,
    AccountConfigurationComponent,
    TermsAndConditionsComponent,
    CustomerAgreementComponent,
    ESignatureComponent,
    ConfirmBillingAdditionComponent,
    DentistShareComponent
  ],
  providers: [
    LoaderService
  ]
})
export class SharedModule { }
