import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DailyReportSettingsComponent } from './daily-report-settings/daily-report-settings.component';
import * as moment from 'moment';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { Router } from '@angular/router';
import { DailyReportService } from '../../services/daily-report/daily-report.service';

@Component({
  selector: 'ark-daily-report',
  templateUrl: './daily-report.component.html',
  styleUrls: ['./daily-report.component.scss']
})
export class DailyReportComponent implements OnInit {

  dialogConfig = new MatDialogConfig();
  dialogRef: any;
  weekDate: any = null;
  days: any;
  clinic = null;
  today = new Date(); 
  hasCurrentDate = null;
  hasFutureDate = null;


  constructor(
    public dialog: MatDialog,
    private report: DailyReportService,
    private auth: AuthService,
    private _router: Router
  ) { }

  ngOnInit() {
    this.getDaysOfTheWeek();
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';

    this.hasCurrentDate = moment(this.today).format("YYYY-MM-DD");
  }

  goToSettings() {
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogRef = this.dialog.open(DailyReportSettingsComponent, this.dialogConfig);
  }

  getDaysOfTheWeek() {
    const currentDate = moment();
    const weekStart = currentDate.clone().startOf('week');
    const weekEnd = currentDate.clone().endOf('week');
    const days = [];
    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days').format("YYYY-MM-DD"));
    }
    this.days = days;
  }

  downloadPDF(date) {
    this.clinic = this.auth.getAuthUser().user_clinic.id
    this.report.downloadDailyReportPDF(this.clinic, date).subscribe(res => {
      if(res) {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(res);
        a.download = moment(this.today).format("YYYYMMDD") + '-DAILY REPORT (' + moment(date).format('MMMM DD, YYYY') + ').pdf';
        document.body.appendChild(a);
        a.click();
      }
    });
  }

  sendEmail(date) {
    this.clinic = this.auth.getAuthUser().user_clinic.id
    this.report.sendDailyReportEmail(this.clinic, date).subscribe(res => {
      if(res == true) {
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Daily report has been sent successfully.',
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      } else {
        this.dialogConfig.data = {
          title: 'Sorry!',
          message: 'No available reports for this day.',
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    });
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  goToBilling(val) {
    localStorage.setItem('dailyReportDate', val);
    this._router.navigate(["/patient-billing"]);
  }
}
