import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreatmentDemoComponent } from './components/treatment-demo/treatment-demo.component';
import { PlayDemoComponent, SafePipe } from './components/play-demo/play-demo.component';
import { MatDialogModule } from '@angular/material';

@NgModule({
  declarations: [
    TreatmentDemoComponent,
    PlayDemoComponent,
    SafePipe
  ],
  imports: [
    CommonModule,
    MatDialogModule
  ],
  entryComponents: [
    PlayDemoComponent
  ]
})
export class TreatmentDemoModule { }
