import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { DirectoryService } from 'src/app/modules/front-desk/service/directory/directory.service';
import { keypressValidateNumber } from 'src/app/common/helper';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

@Component({
  selector: 'ark-add-dentist-directory',
  templateUrl: './add-dentist-directory.component.html',
  styleUrls: ['./add-dentist-directory.component.scss']
})
export class AddDentistDirectoryComponent implements OnInit {

  errorMessage = null;
  clinic = '';
  dialogConfig = new MatDialogConfig();
  formGroup: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddDentistDirectoryComponent>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private directory: DirectoryService
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      name: [''],
      phone_number_1: [''],
      phone_number_2: [''],
      mobile_number_1: [''],
      mobile_number_2: ['']
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  createDirectory() {
    let data = {
      user_clinic_id: this.clinic,
      name: this.formGroup.value.name,
      phone_number_1: this.formGroup.value.phone_number_1,
      phone_number_2: this.formGroup.value.phone_number_2,
      mobile_number_1: this.formGroup.value.mobile_number_1,
      mobile_number_2: this.formGroup.value.mobile_number_2,
    }
    this.directory.saveDentistDirectory(data).subscribe(res => {
      if(res) {
        this.dialogRef.close(res);
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Dentist directory has been successfully saved.',
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Sorry!',
        message: err.error.message,
        button: 'Okay',
        event: this.closeOnClick
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      this.errorMessage = err.error.errors;
      this.triggerErrors(err.error.errors);
    }));
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }
}
