import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class SidebarService {

  sidebarState = new Subject<boolean>();

  constructor() { }

  setSidebarState(item) {
    this.sidebarState.next(item);
    localStorage.setItem('isSidebarMinimized', item);
  }

  getSidebarState() {
  	return localStorage.getItem('isSidebarMinimized');
  }
}