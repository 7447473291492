import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ark-patient-profile',
  templateUrl: './patient-profile.component.html',
  styleUrls: ['./patient-profile.component.scss']
})
export class PatientProfileComponent implements OnInit {

  selectedIndex: number = 0;
  data: any;
  TAB_B = true;
  TAB_C = true;
  TAB_D = true;

  constructor() { }

  ngOnInit() {
  }
  
  selectedIndexChange(val) {
    this.selectedIndex = val;
  }

  enableTabB(val) {
    this.TAB_B = val;
  }

  enableTabC(val) {
    this.TAB_C = val;
  }

  enableTabD(val) {
    this.TAB_D = val;
  }
}
