import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ark-finance-graph',
  templateUrl: './finance-graph.component.html',
  styleUrls: ['./finance-graph.component.scss']
})
export class FinanceGraphComponent implements OnInit {

  @Input() year: any = null;
  @Input() name: any = null;
  selectedYear = '';
  selectedView = '';
  

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    if(this.year || this.name) {
      this.selectedYear = this.year;
      if(this.name == 1) {
        this.selectedView = 'Income vs Expense';
      } else if(this.name == 2) {
        this.selectedView = 'Income';
      } else {
        this.selectedView = 'Expense';
      }
    }
  }
}
