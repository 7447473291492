import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PatientService } from 'src/app/modules/front-desk/service/patient/patient.service';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { keypressValidateNumber } from 'src/app/common/helper';

@Component({
  selector: 'ark-guardian-contact',
  templateUrl: './emergency-contact.component.html',
  styleUrls: ['./emergency-contact.component.scss']
})
export class EmergencyContactComponent implements OnInit {

  @Input() patient: any;

  errorMessage = null;
  patientId = '';
  success = false;
  formGroup: FormGroup;

  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  constructor(
    private formBuilder: FormBuilder,
    private patientsDirectory: PatientsDirectoryService,
    private route: ActivatedRoute,
    private auth: AuthService,
    public dialog: MatDialog
  ) { 
    this.route.params.subscribe(param => {
      this.patientId = param.id
    });
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      name: [''],
      relationship: [''],
      ec_mobile_number: [''],
      landline_number: ['']
    });
    let id = this.auth.getAuthUser().user_clinic.id;
    this.patientsDirectory.getPatientInfo(id, this.patientId, id).subscribe(response =>{
      let patient = response['data'][0].patient_emergency_contact;
      this.formGroup.get('name').setValue(patient.name);
      this.formGroup.get('relationship').setValue(patient.relationship);
      this.formGroup.get('ec_mobile_number').setValue(patient.mobile_number);
      this.formGroup.get('landline_number').setValue(patient.landline_number);
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  updatePatient() {
    let clinic = this.auth.getAuthUser().user_clinic.id;
    let data = {
      'name' : this.formGroup.value.name,
      'relationship' : this.formGroup.value.relationship,
      'ec_mobile_number' : this.formGroup.value.ec_mobile_number ? this.formGroup.value.ec_mobile_number.toString() : null,
      'landline_number' : this.formGroup.value.landline_number ? this.formGroup.value.landline_number.toString() : null,
    }
    this.patientsDirectory.getPatientInfo(clinic, this.patientId, clinic).subscribe(response =>{
      let id = response['data'][0].patient_emergency_contact.id;
      this.patientsDirectory.updatePatientEmergencyContact(id, clinic, data).subscribe(res => {
        if(res) {
          this.success = true;
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Patient\'s emergency contact has been successfully updated.',
            button: 'Okay',
            event: this.closeOnClick
          };
          this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }
      },(err => {
        this.errorMessage = err.error.errors;
        this.triggerErrors(err.error.errors);
      }));
    });
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }
}
