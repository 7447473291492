import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { InventoryService } from 'src/app/modules/front-desk/service/inventory/inventory.service';
import { openDialog } from 'src/app/modules/shared/components/functional/dialogFunction';
import { MatDialog } from '@angular/material';
import { AddItemComponent } from './functions/add-item/add-item.component';
import { ManageCategoriesComponent } from './functions/manage-categories/manage-categories.component';
import { AddStocksComponent } from './functions/add-stocks/add-stocks.component';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { InventoryControlComponent } from './functions/inventory-control/inventory-control.component';

@Component({
  selector: 'ark-master-file',
  templateUrl: './master-file.component.html',
  styleUrls: ['./master-file.component.scss']
})
export class MasterFileComponent implements OnInit, OnChanges {
  config = {}
  element_data = [];
  cat_items = [];
  clinic = null;
  user_id = null;
  item_category = '';
  item_name = '';

  @Input() hasCheckout: boolean = false;
  constructor(private api: InventoryService, private auth: AuthService, private dialog: MatDialog) {
    /* TABLE CONFIGURATION */
    this.config = {
      data: [],
      keys: ['custom_name', 'quantity', 'description', 'category', 'item_code'],
      align: ['left', 'center', 'left', 'center', 'center'],
      empty: 'No Items Found.',
      conditional: 'status',
      action: { 'custom_name' : true},
    };
    /* END */
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.user_id = this.auth.getAuthUser().id;
    this.api.getAPI('category-directory?user_clinic_id=', this.clinic).subscribe(response => {
      if (response.status_code == 200) {
        this.cat_items = response.data.data;
      }
    });
    this.reloadItems();
  }

  ngOnInit() {

  }

  ngOnChanges() {
    if (this.hasCheckout) {
      this.reloadItems();
    }
  }

  addItems(data = null) {
    const obj = data ? data : '';
    openDialog(AddItemComponent, obj, this.dialog).afterClosed().subscribe( trigger => {
      if (trigger == 'noCategory') {
        this.manageCategory();
      } else if (trigger) {
        this.reloadItems();
      }
    });
  }

  reloadItems(filter = null, isSearch = false, isNotFirst = false) {
    let access = '';
    if (isNotFirst) {
      if (isSearch) {
        this.item_name = filter;
        access = 'category-item-inventory?user_clinic_id=' + this.clinic + '&item=' + this.item_name + '&category_id=' + this.item_category;
      } else {
        this.item_category = filter;
        access = 'category-item-inventory?user_clinic_id=' + this.clinic + '&item=' + this.item_name + '&category_id=' + this.item_category;
      }
    } else {
      access = 'category-item-inventory?user_clinic_id=' + this.clinic;
    }
    this.api.getAPI(access, '').subscribe(response => {
      if (response.status_code == 200) {
        // this.element_data = response.data;
        this.element_data = [];
        response.data.forEach(element => {
          this.element_data.push({
            id : element.id,
            item_id : element.item_id,
            item_code : element.item_code,
            item_name : element.item_name,
            custom_name: element.brandname ? element.item_name + ' - ' + element.brandname : element.item_name,
            quantity : element.quantity,
            status : element.status,
            description: element.description,
            category: element.category,
            brandname: element.brandname
          });
        });
      }
    });
  }

  addStocks() {
    openDialog(AddStocksComponent, '', this.dialog).afterClosed().subscribe( trigger => {
      if (trigger) {
        const item = [];
        trigger.forEach(element => {
          if (element.data !== '' && element.data.id) {
            item.push({
              item_id: element.data.id,
              quantity: element.quantity,
            });
          }
        });

        const data = {
          user_clinic_id: this.clinic,
          items: item,
          user_id: this.user_id
        };

        if (item.length > 0) {
          this.api.accessAPI('post', 'category-item-inventory', data).subscribe(res => {
            if (res.status_code == 200) {
              // this.cat_array.push(res);
              const dialogConfig = {
                title: 'Successful!',
                message: 'Stock has been successfully updated.',
                button: 'Okay',
              };
              openDialog(AlertMessageComponent, dialogConfig, this.dialog).afterClosed().subscribe(() => {
                this.reloadItems();
              });
            }
          });
        }
      }
    });
  }

  inventoryCtrl() {
    openDialog(InventoryControlComponent, '', this.dialog).afterClosed().subscribe( trigger => {
      if(trigger) {
        this.reloadItems();
      }
    })
  }

  getItemDetails(event) {
    // console.log(event);
    this.addItems(event);
  }

  manageCategory() {
    openDialog(ManageCategoriesComponent, '', this.dialog).afterClosed().subscribe( trigger => {
      if (trigger) {
        const data = {
          user_clinic_id : this.clinic,
          categories: trigger,
        }
        this.api.accessAPI('post', 'category-directory', data).subscribe(res => {
          if (res.status_code == 200) {
            // this.cat_array.push(res);
            const dialogConfig = {
              title: 'Successful!',
              message: 'Categories have been successfully updated.',
              button: 'Okay',
            };
            openDialog(AlertMessageComponent, dialogConfig, this.dialog).afterClosed().subscribe(() => {
              this.cat_items = res.data.data;
              this.reloadItems();
            });
          }
        });
      }
    });
  }
}
