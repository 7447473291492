import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PatientService } from 'src/app/modules/front-desk/service/patient/patient.service';
import { Router } from '@angular/router';
import { keypressValidateNumber } from 'src/app/common/helper';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { PatientProfileCameraComponent } from './patient-profile-camera/patient-profile-camera.component';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';

@Component({
  selector: 'ark-patient-name',
  templateUrl: './patient-name.component.html',
  styleUrls: ['./patient-name.component.scss']
})
export class PatientNameComponent implements OnInit {

  @Input('selectedIndex') selectedIndex: number; 
  @Output('selectedIndexChange') selectedIndexChange: EventEmitter<any> = new EventEmitter;
  @Output() isDisabled: EventEmitter<any> = new EventEmitter;

  errorMessage = null;
  first_name = '';
  middle_name = '';
  last_name = '';
  email_address = '';
  mobile_number = '';
  patient_image = '';
  success = false;
  dialogConfig = new MatDialogConfig();
  dialogRef: any;
  formGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private _router: Router,
    public dialog: MatDialog,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      middle_name: [''],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.email]],
      mobile_number: ['', [Validators.required]],
      patient_image: ['', [Validators.required]]
    });
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }

  goToPrivateInfo() {
    let data = {
      first_name :this.formGroup.value.first_name,
      middle_name :this.formGroup.value.middle_name,
      last_name :this.formGroup.value.last_name,
      email :this.formGroup.value.email,
      mobile_number :this.formGroup.value.mobile_number,
      patient_image :this.formGroup.value.patient_image,
    }
    this.patientService.checkPatient(this.auth.getAuthUser().user_clinic.id, data).subscribe(response => {
      if (response) {
        this.success = true;
        this.patientService.setData(this.formGroup.value);
        const tab = this.selectedIndex = this.selectedIndex + 1;
        this.selectedIndexChange.emit(tab);
      }
      this.isDisabled.emit(false);
    },(err =>{
      this.errorMessage = err.error.errors;
      this.triggerErrors(err.error.errors);
      this.isDisabled.emit(true);
    }));
  }

  goToDashboard() {
    this._router.navigate(['/dashboard']);
  }

  onChangeProfilePhoto(event) {
    const file = event.target.files;
    if (file && file[0]) {
      var reader = new FileReader();
      reader.onload = function(e) {
        document.getElementById("patient_image")['src'] = e.target['result'];
      }
      reader.readAsDataURL(file[0]);
      this.formGroup.get('patient_image').setValue(file[0]);
    }
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }

  openCamera() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogRef = this.dialog.open(PatientProfileCameraComponent, this.dialogConfig).afterClosed().subscribe(res => {
      if(res) {
        if(res['type'] == 1) {
          if(res['image']) {
            var reader = new FileReader();
            reader.readAsDataURL(res['image']);
            reader.onload = (_event) => { 
              document.getElementById("patient_image")['src'] = reader.result;
            }
            this.formGroup.get('patient_image').setValue(res['image']);
          }
        } else {
          document.getElementById("patient_image")['src'] = res;
          this.formGroup.get('patient_image').setValue(res);
        }
      }
    });
  }
}
