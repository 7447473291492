import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { keypressValidateNumberOnly, keypressValidateNumber } from 'src/app/common/helper';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { DentalFormsService } from 'src/app/modules/dental-office/services/dental-forms/dental-forms.service';

@Component({
  selector: 'ark-tooth-details',
  templateUrl: './tooth-details.component.html',
  styleUrls: ['./tooth-details.component.scss']
})
export class ToothDetailsComponent implements OnInit {

  dialogConfig = new MatDialogConfig();
  errorMessage = null;
  formGroup: FormGroup;
  toothImage = '';
  label = '';

  toothTypes = [
    {
      id: 1,
      name: 'Crown',
      content: [
        { id: 1, name: 'Bridge Type', is_checked: 0 },
        { id: 2, name: 'Cantilever Type', is_checked: 0 },
        { id: 3, name: 'Individual Type', is_checked: 0 },
      ]
    },
    {
      id: 2,
      name: 'Implant Crown',
      content: [
        { id: 1, name: 'Ball Attachment', is_checked: 0 },
        { id: 2, name: 'Cemented Type', is_checked: 0 },
        { id: 3, name: 'Hybrid', is_checked: 0 },
        { id: 4, name: 'Screw-retained', is_checked: 0 },
      ]
    },
    {
      id: 3,
      name: 'Indirect Partial Restorations',
      content: [
        { id: 1, name: 'Inlay', is_checked: 0 },
        { id: 2, name: 'Onlay', is_checked: 0 },
        { id: 3, name: 'Veneer', is_checked: 0 },
      ]
    },
    {
      id: 4,
      name: 'Material',
      content: [
        { id: 1, name: 'Acrylic', is_checked: 0 },
        { id: 2, name: 'Ceramage', is_checked: 0 },
        { id: 3, name: 'E-Max', is_checked: 0 },
        { id: 4, name: 'Gold', is_checked: 0 },
        { id: 5, name: 'Metal', is_checked: 0 },
        { id: 6, name: 'PFG', is_checked: 0 },
        { id: 7, name: 'PFM', is_checked: 0 },
        { id: 8, name: 'Resin', is_checked: 0 },
        { id: 9, name: 'Stainless Steel', is_checked: 0 },
        { id: 10, name: 'Zirconia', is_checked: 0 },
      ]
    },
    {
      id: 5,
      name: 'Others',
      content: [
        { id: 1, name: 'Ceramic Post', is_checked: 0 },
        { id: 2, name: 'Metal Post', is_checked: 0 },
        { id: 3, name: 'PFM', is_checked: 0 },
      ]
    }
  ]
  
  metalMarginDesign = [
    {
      name: 'Anterior Teeth',
      content: [
        { id: 1, name: 'anterior_full_porcelain_anterior', title: 'Full Porcelain', url: '../../../../../../../../assets/image/crown/anterior-full-porcelain.svg', is_checked: 0 },
        { id: 2, name: 'anterior_1_4_lingual_metal', title: '1/4 Lingual Metal', url: '../../../../../../../../assets/image/crown/lingual-metal-1.svg', is_checked: 0 },
        { id: 3, name: 'anterior_3_4_lingual_metal', title: '3/4 Lingual Metal', url: '../../../../../../../../assets/image/crown/lingual-metal-2.svg', is_checked: 0 },
        { id: 4, name: 'anterior_full_lingual_metal', title: 'Full Lingual Metal', url: '../../../../../../../../assets/image/crown/full-lingual-metal.svg', is_checked: 0 },
      ]
    },
    {
      name: 'Posterior Teeth',
      content: [
        { id: 1, name: 'posterior_full_porcelain_posterior', title: 'Full Porcelain', url: '../../../../../../../../assets/image/crown/posterior-full-porcelain.svg', is_checked: 0 },
        { id: 2, name: 'posterior_1_2_cervical_metal_margin', title: '1/2 Cervical \n Metal Margin', url: '../../../../../../../../assets/image/crown/cervical-metal-margin.svg', is_checked: 0 },
        { id: 3, name: 'posterior_full_cervical_metal_margin', title: 'Full Cervical \n Metal Margin', url: '../../../../../../../../assets/image/crown/full-cervical-metal-margin.svg', is_checked: 0 },
        { id: 4, name: 'posterior_3_4_metal_occlusal_coverage', title: '3/4 Metal \n Occlusal Coverage', url: '../../../../../../../../assets/image/crown/metal-occlusal-coverage.svg', is_checked: 0 },
        { id: 5, name: 'posterior_full_metal_occlusal_coverage', title: 'Full Metal \n Occlusal Coverage', url: '../../../../../../../../assets/image/crown/full-metal-occlusal-coverage.svg', is_checked: 0 },
      ]
    }
  ]

  ponticDesign = [
    {
      name: 'Pontic Design',
      content: [
        { id: 1, name: 'pontic_sanitary_hygenic', title: 'Sanitary/Hygenic', url: '../../../../../../../../assets/image/crown/sanitary-hygenic.svg', is_checked: 0 },
        { id: 2, name: 'pontic_conical', title: 'Modified', url: '../../../../../../../../assets/image/crown/conical.svg', is_checked: 0 },
        { id: 3, name: 'pontic_ovate', title: 'Ovate', url: '../../../../../../../../assets/image/crown/ovate.svg', is_checked: 0 },
        { id: 4, name: 'pontic_modified_ridge_lap_type', title: 'Modified Ridge-Lap Type', url: '../../../../../../../../assets/image/crown/ridge-lab-type.svg', is_checked: 0 },
      ]
    }
  ]

  enclosed = [
    {
      name: 'Enclosed',
      content: [
        { id: 1, name: 'Articulator', is_checked: 0, hasInput: false },
        { id: 2, name: 'Impression Tray_pcs', is_checked: 0, hasInput: false },
        { id: 3, name: 'Old Crown', is_checked: 0, hasInput: false },
        { id: 4, name: 'Shade Guide', is_checked: 0, hasInput: false },
        { id: 5, name: 'Bite Registration', is_checked: 0, hasInput: false },
        { id: 6, name: 'Lower Cast_pcs', is_checked: 0, hasInput: true },
        { id: 7, name: 'Photos', is_checked: 0, hasInput: false },
        { id: 8, name: 'Upper Cast_pcs', is_checked: 0, hasInput: true },
      ]
    }
  ]

  noOcclusalClearance = [
    {
      name: 'No Occlusal Clearance',
      content: [
        { id: 1, name: 'Call Doctor', is_checked: 0 },
        { id: 2, name: 'Spot Opposing', is_checked: 0 },
        { id: 3, name: 'Metal Occlusion', is_checked: 0 },
      ]
    }
  ]

  tooth_details = [
    {
      tooth_no: "",
      shade: "",
      type: {
        crown: [],
        implant_crown: [],
        indirect_partial_restorations: [],
        material: [],
        others: []
      },
      design: {
        anterior: [],
        posterior: [],
        pontic: []
      },
      enclosed: [],
      no_occclusal: []
    }
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ToothDetailsComponent>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private forms: DentalFormsService
  ) {
    this.forms.getCrownDetails().subscribe(res => {
      this.toothImage = res;
    });
   }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      shade: ['', [Validators.required]],
      enclosed_value_6: [''],
      enclosed_value_8: ['']
    });
    this.label = 'Save';
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';

    if(this.data.data !== undefined) {
      this.label = 'Update'
      this.tooth_details.forEach(element => {
        element.tooth_no = this.data.data.tooth_no,
        element.shade = this.data.data.shade

        this.formGroup.get('shade').setValue(this.data.data.shade);

        this.data.data.type.crown.forEach(el => {
          element.type.crown.push(el);
          this.toothTypes[0].content.forEach(x => {
            if(x.name == el) {
              x.is_checked = 1;
            } 
          });
        });
        this.data.data.type.implant_crown.forEach(el => {
          element.type.implant_crown.push(el);
          this.toothTypes[1].content.forEach(x => {
            if(x.name == el) {
              x.is_checked = 1;
            } 
          });
        });
        this.data.data.type.indirect_partial_restorations.forEach(el => {
          element.type.indirect_partial_restorations.push(el);
          this.toothTypes[2].content.forEach(x => {
            if(x.name == el) {
              x.is_checked = 1;
            } 
          });
        });
        this.data.data.type.material.forEach(el => {
          element.type.material.push(el);
          this.toothTypes[3].content.forEach(x => {
            if(x.name == el) {
              x.is_checked = 1;
            } 
          });
        });
        this.data.data.type.others.forEach(el => {
          element.type.others.push(el);
          this.toothTypes[4].content.forEach(x => {
            if(x.name == el) {
              x.is_checked = 1;
            } 
          });
        });
        this.data.data.design.anterior.forEach(el => {
          element.design.anterior.push(el);
          this.metalMarginDesign[0].content.forEach(x => {
            if(x.id == el.id) {
              x.is_checked = 1;
            }  
          });
        });
        this.data.data.design.posterior.forEach(el => {
          element.design.posterior.push(el);
          this.metalMarginDesign[1].content.forEach(x => {
            if(x.id == el.id) {
              x.is_checked = 1;
            }  
          });
        });
        this.data.data.design.pontic.forEach(el => {
          element.design.pontic.push(el);
          this.ponticDesign[0].content.forEach(x => {
            if(x.id == el.id) {
              x.is_checked = 1;
            }  
          });
        });
        this.data.data.enclosed.forEach(el => {
          element.enclosed.push(el);
          this.enclosed[0].content.forEach(x => {
            if(el.includes(x.name)) {
              x.is_checked = 1;

              if (x.hasInput) {
                if (el.indexOf(':') !== -1) {
                  //split and get
                  var y = el.split(':')[1];
                  if (x.id == 6) {
                    this.formGroup.get('enclosed_value_6').setValue(y);
                  } else {
                    this.formGroup.get('enclosed_value_8').setValue(y);
                  }
                }
              }
            }  
          });
        });
        this.data.data.no_occclusal.forEach(el => {
          element.no_occclusal.push(el);
          this.noOcclusalClearance[0].content.forEach(x => {
            if(x.name == el) {
              x.is_checked = 1;
            }  
          });
        });
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  save() {
    if(this.tooth_details[0].type['crown'].length == 0 && this.tooth_details[0].type['implant_crown'].length == 0 && this.tooth_details[0].type['indirect_partial_restorations'].length == 0 && this.tooth_details[0].type['material'].length == 0 && this.tooth_details[0].type['others'].length == 0 && this.tooth_details[0].design['anterior'].length == 0 && this.tooth_details[0].design['posterior'].length == 0 && this.tooth_details[0].design['pontic'].length == 0 && this.tooth_details[0].enclosed.length == 0 && this.tooth_details[0].no_occclusal.length == 0) {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Tooth details cannot be empty.',
        button: 'Okay',
        event: this.closeOnClick
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    } else if(this.formGroup.value.shade == '' || this.formGroup.value.shade == null) {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'The shade field is required.',
        button: 'Okay',
        event: this.closeOnClick
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);

    } else {
      let enclosed = this.tooth_details[0].enclosed.filter(item => item.includes('Lower Cast_pcs'));
      if(enclosed.length > 0) {
        let x = this.tooth_details[0].enclosed.indexOf(enclosed[0]);
  
        if (this.formGroup.value.enclosed_value_6 != '' && this.formGroup.value.enclosed_value_6 != null && this.formGroup.value.enclosed_value_6 != 'null') {
          this.tooth_details[0].enclosed[x] = 'Lower Cast_pcs: '+ this.formGroup.value.enclosed_value_6;
        } else {
          this.tooth_details[0].enclosed[x] = 'Lower Cast_pcs';
        }
      }

      let upperEnclosed = this.tooth_details[0].enclosed.filter(item => item.includes('Upper Cast_pcs'));
      if(upperEnclosed.length > 0) {
        let x = this.tooth_details[0].enclosed.indexOf(upperEnclosed[0]);
  
        if (this.formGroup.value.enclosed_value_8 != '' && this.formGroup.value.enclosed_value_8 != null && this.formGroup.value.enclosed_value_8 != 'null') {
          this.tooth_details[0].enclosed[x] = 'Upper Cast_pcs: '+ this.formGroup.value.enclosed_value_8;
        } else {
          this.tooth_details[0].enclosed[x] = 'Upper Cast_pcs';
        }
      }

      this.tooth_details[0].tooth_no = this.data.tooth_no ? this.data.tooth_no : this.data.data.tooth_no;
      this.tooth_details[0].shade = this.formGroup.value.shade ? this.formGroup.value.shade : this.data.data.shade;

      this.dialogRef.close(this.tooth_details);
    }
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }

  numberValidation(e) {
    return keypressValidateNumberOnly(e);
  }

  onCheckType(e, obj, index) {
    obj.is_checked = (e.checked) ? 1 : 0;
    if(index == 0) {
      if(obj.is_checked) {
        this.tooth_details[0].type['crown'].push(obj.name);
      } else {
        this.tooth_details[0].type['crown'].splice(this.tooth_details[0].type['crown'].findIndex(item => item === obj.name), 1);
      }
    } else if(index == 1) {
      if(obj.is_checked) {
        this.tooth_details[0].type['implant_crown'].push(obj.name);
      } else {
        this.tooth_details[0].type['implant_crown'].splice(this.tooth_details[0].type['implant_crown'].findIndex(item => item === obj.name), 1);
      }
    } else if(index == 2) {
      if(obj.is_checked) {
        this.tooth_details[0].type['indirect_partial_restorations'].push(obj.name);
      } else {
        this.tooth_details[0].type['indirect_partial_restorations'].splice(this.tooth_details[0].type['indirect_partial_restorations'].findIndex(item => item === obj.name), 1);
      }
    } else if(index == 3) {
      if(obj.is_checked) {
        this.tooth_details[0].type['material'].push(obj.name);
      } else {
        this.tooth_details[0].type['material'].splice(this.tooth_details[0].type['material'].findIndex(item => item === obj.name), 1);
      }
    } else if(index == 4) {
      if(obj.is_checked) {
        this.tooth_details[0].type['others'].push(obj.name);
      } else {
        this.tooth_details[0].type['others'].splice(this.tooth_details[0].type['others'].findIndex(item => item === obj.name), 1);
      }
    }
  }

  onCheckMetal(e, obj, index) {
    obj.is_checked = (e.checked) ? 1 : 0;
    if(index == 0) {
      if(obj.is_checked) {
        obj.url = this.toothImage[obj.name];
        this.tooth_details[0].design['anterior'].push(obj);
      } else {
        this.tooth_details[0].design['anterior'].splice(this.tooth_details[0].design['anterior'].findIndex(item => item.id === obj.id), 1);
      }
    } else if(index == 1) {
      if(obj.is_checked) {
        obj.url = this.toothImage[obj.name];
        this.tooth_details[0].design['posterior'].push(obj);
      } else {
        this.tooth_details[0].design['posterior'].splice(this.tooth_details[0].design['posterior'].findIndex(item => item.id === obj.id), 1);
      }
    } 
  }

  onCheckPontic(e, obj) {
    obj.is_checked = (e.checked) ? 1 : 0;
    if(obj.is_checked) {
      obj.url = this.toothImage[obj.name];
      this.tooth_details[0].design['pontic'].push(obj);
    } else {
      this.tooth_details[0].design['pontic'].splice(this.tooth_details[0].design['pontic'].findIndex(item => item.id === obj.id), 1);
    }
  }

  onCheckEnclosed(e, obj) {
    obj.is_checked = (e.checked) ? 1 : 0;
    if(obj.is_checked) {
      this.tooth_details[0].enclosed.push(obj.name);
    } else {
      if (obj.name == 'Lower Cast_pcs' || obj.name == 'Upper Cast_pcs') {
        const selection = this.tooth_details[0].enclosed.filter(item => item.includes(obj.name));
        this.tooth_details[0].enclosed.splice(this.tooth_details[0].enclosed.indexOf(selection[0]), 1);
      } else {
        this.tooth_details[0].enclosed.splice(this.tooth_details[0].enclosed.indexOf(obj.name), 1);
      }
    }
  }

  onCheckNoClearance(e, obj) {
    obj.is_checked = (e.checked) ? 1 : 0;
    if(obj.is_checked) {
      this.tooth_details[0].no_occclusal.push(obj.name);
    } else {
      this.tooth_details[0].no_occclusal.splice(this.tooth_details[0].no_occclusal.findIndex(item => item === obj.name), 1);
    }
  }
}