import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'ark-unsubscribe-clinic',
  templateUrl: './unsubscribe-clinic.component.html',
  styleUrls: ['./unsubscribe-clinic.component.scss']
})
export class UnsubscribeClinicComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UnsubscribeClinicComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {}

  close(flag = false) {
    this.dialogRef.close(flag);
  }
}
