import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EmployeesService } from 'src/app/modules/dental-office/services/employees/employees.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { keypressValidateNumber } from 'src/app/common/helper';

@Component({
  selector: 'ark-dtr-settings',
  templateUrl: './dtr-settings.component.html',
  styleUrls: ['./dtr-settings.component.scss']
})
export class DtrSettingsComponent implements OnInit, OnChanges {

  @Input() month: any = null; 
  @Input() year: any = null; 
  periods = [
    { id: 1, name: '1-15' },
    { id: 2, name: '16-30' }
  ];
  isNoResult: boolean = true;
  today = new Date(); 
  formGroup: FormGroup;
  id = null;
  clinic = null;
  selectedPeriod: string = '1-15';
  dialogConfig = new MatDialogConfig();
  dialogRef : any;
  selectedMonth = '';
  selectedYear = '';

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    private payroll: EmployeesService
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.route.params.subscribe(param => {
      this.id = param.id
    });
    this.formGroup = this.formBuilder.group({
      period: [''],
      salary: [''],
      overtime: [''],
      holiday_sr_x_30: [''],
      holiday_sr_x_100: [''],
      absent: [''],
      late: [''],
      undertime: [''],
    });
    this.formGroup.get('period').setValue(this.selectedPeriod);
  }

  ngOnInit() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  ngOnChanges() {
    if(this.month || this.year) {
      this.selectedMonth =  this.month;
      this.selectedYear =  this.year;
      this.getPayrollRecord();
    }
  }

  getPayrollRecord() {
    this.payroll.getPayrollRecord(this.clinic, this.id, this.selectedMonth, this.selectedYear, this.selectedPeriod).subscribe(res => {
      if(res) {
        this.formGroup.get('salary').setValue(res.salary);
        this.formGroup.get('overtime').setValue(res.overtime);
        this.formGroup.get('holiday_sr_x_30').setValue(res.holiday_sr_x_30);
        this.formGroup.get('holiday_sr_x_100').setValue(res.holiday_sr_x_100);
        this.formGroup.get('absent').setValue(res.absent);
        this.formGroup.get('late').setValue(res.late);
        this.formGroup.get('undertime').setValue(res.undertime);
      }
    });
  }

  getPeriod(period) {
    this.formGroup.get('period').setValue(period.value);
    this.selectedPeriod = period.value;
    this.getPayrollRecord();
  }

  saveDTRSettings() {
    const data = {
      user_clinic_id: this.clinic,
      employee_id: this.id,
      month: this.selectedMonth,
      year: this.selectedYear,
      period: this.selectedPeriod,
      salary: this.formGroup.value.salary ? this.formGroup.value.salary : 0,
      overtime: this.formGroup.value.overtime ? this.formGroup.value.overtime : 0,
      holiday_sr_x_30: this.formGroup.value.holiday_sr_x_30 ? this.formGroup.value.holiday_sr_x_30 : 0,
      holiday_sr_x_100: this.formGroup.value.holiday_sr_x_100 ? this.formGroup.value.holiday_sr_x_100 : 0,
      absent: this.formGroup.value.absent ? this.formGroup.value.absent : 0,
      late: this.formGroup.value.late ? this.formGroup.value.late : 0,
      undertime: this.formGroup.value.undertime ? this.formGroup.value.undertime : 0,
    }
    this.payroll.savePayrollRecord(data).subscribe(res => {
      if(res) {
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Daily time record settings has been successfully saved.',
          button: 'Okay',
          event: this.close
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => {
          this.getPayrollRecord();
          this.goBackToEmployees();
        });
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Sorry!',
        message: err.error.message,
        button: 'Okay',
        event: this.close
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }));
  }

  close() {
    this.dialogRef.close();
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }

  goBackToEmployees() {
    history.go(-1);
  }
} 
