import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { TimeRecordService } from '../../services/time-record/time-record.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import * as moment from 'moment';
import { ConfirmTimeoutComponent } from 'src/app/modules/shared/components/confirm-timeout/confirm-timeout.component';

@Component({
  selector: 'ark-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss']
})
export class ClockComponent implements OnInit {

  @Output() data: EventEmitter<any> = new EventEmitter;
  timer;
  time = new Date();
  label = 'Time In';
  clinic = null;
  id = null;
  timeId = null;
  timeIn = null;
  userTimeIn: boolean = true;
  userTimeOut: boolean = false;
  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  constructor(
    private record: TimeRecordService,
    private auth: AuthService,
    public dialog: MatDialog
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.id = this.auth.getAuthUser().id;
    this.record.getTimeIn(this.clinic, this.id).subscribe(res => {
      if(res.length === 0) {
        this.label = 'Time In'
      } 
      if(res.time_out === null) {
        this.label = 'Time Out'
        this.timeId = res.id;
        this.timeIn = res.time_in;
      }
    });
  }

  ngOnInit() {
    this.timer = setInterval(() => {
      this.time = new Date();
    }, 1000);
  }

  setTimeIn() {
    const time = moment().format("HH:mm:ss");
    const data = {
      user_id: this.id,
      time_in: time,
      user_clinic_id: this.clinic
    }
    this.record.setTimeIn(data).subscribe(res => {
      if(res) {
        this.label = 'Time Out';
        this.timeId = res['data'].id;
        this.timeIn = res['data'].time_in;
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'You have successfully timed-in.\n Time-in: ' + moment().format("h:mm:ss A"),
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
      this.data.emit(res);
    });
  }

  setTimeOut() {
    const time = moment().format("HH:mm:ss");
    const data = {
      user_id: this.id,
      time_out: time,
      user_clinic_id: this.clinic
    }
    const diff = moment.utc(moment(time,"HH:mm:ss").diff(moment(this.timeIn,"HH:mm:ss"))).format("HH:mm:ss");
    if(diff === "09:00:00" || diff > "09:00:00") {
      this.record.setTimeOut(this.timeId, data).subscribe(res => {
        if(res) {
          this.label = 'Time In';
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'You have successfully timed-out \n and rendered 9 hours today.',
            button: 'Okay',
            event: this.closeOnClick
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }
        this.data.emit(res);
      });
    } else {
      this.record.setTimeOut(this.timeId, data).subscribe(res => {
        const hour = res['data'].hour > 1 ? res['data'].hour + ' hours, ' : res['data'].hour + ' hour, ';
        const minute = res['data'].minute > 1 ? res['data'].minute + ' minutes, ' : res['data'].minute + ' minute, ';
        const seconds = res['data'].second > 1 ? res['data'].second + ' seconds' : res['data'].second + ' second';
        this.dialogConfig.data = {
          subject: hour + minute + seconds
        };
        this.dialogConfig.disableClose = true;
        this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
        let dialogRef = this.dialog.open(ConfirmTimeoutComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
          if(trigger) {
            this.record.setTimeOutUndertime(this.timeId, data).subscribe(res => {
              if(res) {
                this.label = 'Time In';
                this.dialogConfig.data = {
                  title: 'Success!',
                  message: 'You have successfully timed-out.',
                  button: 'Okay',
                  event: this.closeOnClick
                };
                let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
              }
              this.data.emit(res);
            });
          }
        });
      });
    }
  }

  closeOnClick() {
    this.dialogRef.close();
  }
}
  