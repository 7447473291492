import { Component, OnInit, OnDestroy } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { PayrollSettingsComponent } from './payroll-settings/payroll-settings.component';
import { EmployeesService } from '../../../services/employees/employees.service';
import { keypressValidateNumber, currencyFormat } from 'src/app/common/helper';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { FinanceService } from '../../../services/finance/finance.service';
import { SidebarService } from 'src/app/modules/core/services/sidebar/sidebar.service';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
  },
};

export class AddOns {
  id: number;
  name: string;
  amount: string;
  division_of_time: string;
  no_of_unit: number;
}

export class Deductions {
  id: number;
  name: string;
  amount: string;
  division_of_time: string;
  no_of_unit: number;
}

@Component({
  selector: 'ark-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class PayrollComponent implements OnInit, OnDestroy {

  months = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
  ];
  periods = [
    { id: 1, name: '1-15' },
    { id: 2, name: '16-30' }
  ];
  isNoResult: boolean = true;
  hasPayroll: boolean = true;
  isSidebarMinimized: boolean = false;
  today = new Date(); 
  employeeId = null;
  payrollId = null;
  employeeName = null;
  clinic = null;
  records = null;
  payrollSettings = [];
  selectedYear = +moment().format("YYYY");
  selectedMonth = +moment().format("M");
  selectedPeriod: string = '1-15';
  dialogConfig = new MatDialogConfig();
  dialogRef : any;

  formGroup: FormGroup;
  id: FormControl;
  name: FormControl;
  amount: FormControl;
  division_of_time: FormControl;
  no_of_unit: FormControl;
  addons: AddOns = new AddOns();
  deduct: Deductions = new Deductions();
  

  arrayAddOns = null;
  arrayDeductions = null;

  listAddOns: AddOns[] = [
    { id: 1, name: 'Over Time (per hour)', amount: '0', division_of_time: null, no_of_unit: 0 },
    { id: 2, name: 'Holiday (SR x 30%)', amount: '0', division_of_time: null, no_of_unit: 0 },
    { id: 3, name: 'Holiday (SR x 100%)', amount: '0', division_of_time: null, no_of_unit: 0 },
    { id: 4, name: 'Meal Allowance', amount: '0', division_of_time: 'day', no_of_unit: 0 },
    { id: 5, name: 'Enter Add On', amount: '0', division_of_time: 'day', no_of_unit: 0 },
    { id: 6, name: 'Enter Add On', amount: '0', division_of_time: 'day', no_of_unit: 0 },
  ];
  listDeductions: Deductions[] = [
    { id: 1, name: 'Absent (per day)', amount: '0', division_of_time: null, no_of_unit: 0 },
    { id: 2, name: 'Late (per hour)', amount: '0', division_of_time: null, no_of_unit: 0 },
    { id: 3, name: 'Undertime (per hour)', amount: '0', division_of_time: null, no_of_unit: 0 },
    { id: 4, name: 'SSS', amount: '0', division_of_time: null, no_of_unit: 0 },
    { id: 5, name: 'Philhealth', amount: '0', division_of_time: null, no_of_unit: 0 },
    { id: 6, name: 'Pag-ibig', amount: '0', division_of_time: null, no_of_unit: 0 },
    { id: 7, name: 'Enter Deduction', amount: '0', division_of_time: 'day', no_of_unit: 0 },
    { id: 8, name: 'Enter Deduction', amount: '0', division_of_time: 'day', no_of_unit: 0 },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private financeDirectory: FinanceService,
    private route: ActivatedRoute,
    private auth: AuthService,
    public dialog: MatDialog,
    private payroll: EmployeesService,
    private sidebarService: SidebarService
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.route.params.subscribe(param => {
      this.employeeId = param.id
    });
    this.formGroup = this.formBuilder.group({
      month: [''],
      year: new FormControl(moment()),
      period: [''],
      salary_rate: ['0'],
      add_ons: this.formBuilder.array([]),
      deduction: this.formBuilder.array([]),
      overtime: ['0'],
      holiday_sr_x_30: ['0'],
      holiday_sr_x_100: ['0'],
      late: ['0'],
      absent: ['0'],
      undertime: ['0'],
      working_days: [''],
      sss: ['0'],
      philhealth: ['0'],
      pag_ibig: ['0'],
      meal_allowance: ['0'],
      sub_total1: ['0'],
      sub_total2: ['0'],
      total_salary: [''],   
      total_overtime: [''],   
      total_meal_allowance: [''],   
      total_holiday_sr_x_30: [''],   
      total_holiday_sr_x_100: [''],   
      total_absent: [''],   
      total_late: [''],   
      total_undertime: [''],   
      total_sss: [''],   
      total_philhealth: [''],   
      total_pag_ibig: [''],
      gross_pay: [''],
      total_deductions: [''],
      net_pay: [''],
      others1: ['0'],
      others2: ['0'],
      total_others1: [''],
      total_others2: [''],
      omit1: ['0'],
      omit2: ['0'],
      total_omit1: [''],
      total_omit2: [''],
    });
    this.formGroup.get('month').setValue(this.selectedMonth);
    this.formGroup.get('period').setValue(this.selectedPeriod);
    this.employeeName = localStorage.getItem('assistantName');
    this.arrayAddOns = this.formGroup.get('add_ons') as FormArray;
    this.arrayDeductions = this.formGroup.get('deduction') as FormArray;
    this.initAddOns(this.listAddOns);
    this.initDeductions(this.listDeductions);

    if(localStorage.getItem('isSidebarMinimized')) {
      this.isSidebarMinimized = JSON.parse(localStorage.getItem('isSidebarMinimized'));
    }
    this.sidebarService.sidebarState.subscribe((nextValue) => {
      this.isSidebarMinimized = nextValue
    });
  }

  ngOnInit() {
    this.disableInputs();
    this.getPayrollRecord();
    this.getPayrollSettings();
    this.getPayroll();
    this.total();

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  ngOnDestroy() {
    localStorage.removeItem('assistantName');
  }

  disableInputs() {
    this.formGroup.disable();
    this.formGroup.controls['month'].enable();
    this.formGroup.controls['year'].enable();
    this.formGroup.controls['period'].enable();
    this.formGroup.controls['sss'].enable();
    this.formGroup.controls['philhealth'].enable();
    this.formGroup.controls['pag_ibig'].enable();
    this.formGroup.controls['meal_allowance'].enable();
    this.formGroup.controls['others1'].enable();
    this.formGroup.controls['others2'].enable();
    this.formGroup.controls['omit1'].enable();
    this.formGroup.controls['omit2'].enable();
  }

  goBackToEmployees() {
    history.go(-1);
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }

  currencyFormat(e) {
    return currencyFormat(e);
  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const val = this.formGroup.value.year;
    val.year(normalizedYear.year());
    this.formGroup.get('year').setValue(val);
    datepicker.close();
    this.selectedYear = +moment(this.formGroup.value.year).format("YYYY");
    this.getPayrollRecord();
    this.getPayroll();
  }

  getMonth(month) {
    this.formGroup.get('month').setValue(month.value);
    this.selectedMonth = month.value;
    this.getPayrollRecord();
    this.getPayroll();
  }

  getPeriod(period) {
    this.formGroup.get('period').setValue(period.value);
    this.selectedPeriod = period.value;
    this.getPayrollRecord();
    this.getPayroll();
  }

  getPayroll() {
    this.payroll.getPayroll(this.clinic, this.employeeId, this.selectedYear, this.selectedMonth, this.selectedPeriod).subscribe(res => {
      if(res) {
        if(res.length > 0) {
          this.hasPayroll = true;
          this.payrollId = res[0].id;
          this.formGroup.get('salary_rate').setValue(res[0].salary_rate);
          this.formGroup.get('add_ons').patchValue(res[0].add_ons);
          this.formGroup.get('deduction').patchValue(res[0].deductions);
          this.formGroup.get('meal_allowance').setValue(res[0].add_ons[3].no_of_unit);
          this.formGroup.get('others1').setValue(res[0].add_ons[4].no_of_unit);
          this.formGroup.get('others2').setValue(res[0].add_ons[5].no_of_unit);
          this.formGroup.get('sss').setValue(res[0].deductions[3].no_of_unit);
          this.formGroup.get('philhealth').setValue(res[0].deductions[4].no_of_unit);
          this.formGroup.get('pag_ibig').setValue(res[0].deductions[5].no_of_unit);
          this.formGroup.get('omit1').setValue(res[0].deductions[6].no_of_unit);
          this.formGroup.get('omit2').setValue(res[0].deductions[7].no_of_unit);
          this.total();
        } else {
          this.hasPayroll = false;
          if(this.payrollSettings.length > 0) {
            this.formGroup.get('salary_rate').setValue(this.payrollSettings[0].salary_rate);
            this.formGroup.get('add_ons').patchValue(this.payrollSettings[0].add_ons);
            this.formGroup.get('deduction').patchValue(this.payrollSettings[0].deductions);
            this.total();
          } 
        }
      }
    });
  }

  getPayrollSettings() {
    this.payroll.getPayrollSettings(this.clinic, this.employeeId).subscribe(res => {
      if(res) {
        this.payrollSettings = res;
      }
    });
  }

  getPayrollRecord() {
    this.payroll.getPayrollRecord(this.clinic, this.employeeId, this.selectedMonth, this.selectedYear, this.selectedPeriod).subscribe(res => {
      if(res) {
        this.formGroup.get('working_days').patchValue(res.salary ? res.salary : '0');
        this.formGroup.get('overtime').patchValue(res.overtime ? res.overtime : '0');
        this.formGroup.get('holiday_sr_x_30').patchValue(res.holiday_sr_x_30 ? res.holiday_sr_x_30 : '0');
        this.formGroup.get('holiday_sr_x_100').patchValue(res.holiday_sr_x_100 ? res.holiday_sr_x_100 : '0');
        this.formGroup.get('absent').patchValue(res.absent ? res.absent : '0');
        this.formGroup.get('late').patchValue(res.late ? res.late : '0');
        this.formGroup.get('undertime').patchValue(res.undertime ? res.undertime : '0');
        this.total();
      }
    });
  }

  goToSettings() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogConfig.data = {
      id: this.employeeId,
      data: this.payrollSettings
    }
    this.dialogRef = this.dialog.open(PayrollSettingsComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(res => {
      if(res) {
        this.payrollSettings = res.data;
        if(this.payrollSettings.length > 0) {
          this.formGroup.get('salary_rate').setValue(this.payrollSettings[0].salary_rate);
          // Set amount 
          this.arrayAddOns.at(0)['controls'].amount.setValue(this.payrollSettings[0].add_ons[0].amount);
          this.arrayAddOns.at(1)['controls'].amount.setValue(this.payrollSettings[0].add_ons[1].amount);
          this.arrayAddOns.at(2)['controls'].amount.setValue(this.payrollSettings[0].add_ons[2].amount);
          this.arrayAddOns.at(3)['controls'].amount.setValue(this.payrollSettings[0].add_ons[3].amount);
          this.arrayAddOns.at(4)['controls'].amount.setValue(this.payrollSettings[0].add_ons[4].amount);
          this.arrayAddOns.at(5)['controls'].amount.setValue(this.payrollSettings[0].add_ons[5].amount);
          this.arrayDeductions.at(0)['controls'].amount.setValue(this.payrollSettings[0].deductions[0].amount);
          this.arrayDeductions.at(1)['controls'].amount.setValue(this.payrollSettings[0].deductions[1].amount);
          this.arrayDeductions.at(2)['controls'].amount.setValue(this.payrollSettings[0].deductions[2].amount);
          this.arrayDeductions.at(3)['controls'].amount.setValue(this.payrollSettings[0].deductions[3].amount);
          this.arrayDeductions.at(4)['controls'].amount.setValue(this.payrollSettings[0].deductions[4].amount);
          this.arrayDeductions.at(5)['controls'].amount.setValue(this.payrollSettings[0].deductions[5].amount);
          this.arrayDeductions.at(6)['controls'].amount.setValue(this.payrollSettings[0].deductions[6].amount);
          this.arrayDeductions.at(7)['controls'].amount.setValue(this.payrollSettings[0].deductions[7].amount);
          // Set name
          this.arrayAddOns.at(0)['controls'].name.setValue(this.payrollSettings[0].add_ons[0].name);
          this.arrayAddOns.at(1)['controls'].name.setValue(this.payrollSettings[0].add_ons[1].name);
          this.arrayAddOns.at(2)['controls'].name.setValue(this.payrollSettings[0].add_ons[2].name);
          this.arrayAddOns.at(3)['controls'].name.setValue(this.payrollSettings[0].add_ons[3].name);
          this.arrayAddOns.at(4)['controls'].name.setValue(this.payrollSettings[0].add_ons[4].name);
          this.arrayAddOns.at(5)['controls'].name.setValue(this.payrollSettings[0].add_ons[5].name);
          this.arrayDeductions.at(0)['controls'].name.setValue(this.payrollSettings[0].deductions[0].name);
          this.arrayDeductions.at(1)['controls'].name.setValue(this.payrollSettings[0].deductions[1].name);
          this.arrayDeductions.at(2)['controls'].name.setValue(this.payrollSettings[0].deductions[2].name);
          this.arrayDeductions.at(3)['controls'].name.setValue(this.payrollSettings[0].deductions[3].name);
          this.arrayDeductions.at(4)['controls'].name.setValue(this.payrollSettings[0].deductions[4].name);
          this.arrayDeductions.at(5)['controls'].name.setValue(this.payrollSettings[0].deductions[5].name);
          this.arrayDeductions.at(6)['controls'].name.setValue(this.payrollSettings[0].deductions[6].name);
          this.arrayDeductions.at(7)['controls'].name.setValue(this.payrollSettings[0].deductions[7].name);
          // Set division of time 
          this.arrayAddOns.at(0)['controls'].division_of_time.setValue(this.payrollSettings[0].add_ons[0].division_of_time);
          this.arrayAddOns.at(1)['controls'].division_of_time.setValue(this.payrollSettings[0].add_ons[1].division_of_time);
          this.arrayAddOns.at(2)['controls'].division_of_time.setValue(this.payrollSettings[0].add_ons[2].division_of_time);
          this.arrayAddOns.at(3)['controls'].division_of_time.setValue(this.payrollSettings[0].add_ons[3].division_of_time);
          this.arrayAddOns.at(4)['controls'].division_of_time.setValue(this.payrollSettings[0].add_ons[4].division_of_time);
          this.arrayAddOns.at(5)['controls'].division_of_time.setValue(this.payrollSettings[0].add_ons[5].division_of_time);
          this.arrayDeductions.at(0)['controls'].division_of_time.setValue(this.payrollSettings[0].deductions[0].division_of_time);
          this.arrayDeductions.at(1)['controls'].division_of_time.setValue(this.payrollSettings[0].deductions[1].division_of_time);
          this.arrayDeductions.at(2)['controls'].division_of_time.setValue(this.payrollSettings[0].deductions[2].division_of_time);
          this.arrayDeductions.at(3)['controls'].division_of_time.setValue(this.payrollSettings[0].deductions[3].division_of_time);
          this.arrayDeductions.at(4)['controls'].division_of_time.setValue(this.payrollSettings[0].deductions[4].division_of_time);
          this.arrayDeductions.at(5)['controls'].division_of_time.setValue(this.payrollSettings[0].deductions[5].division_of_time);
          this.arrayDeductions.at(6)['controls'].division_of_time.setValue(this.payrollSettings[0].deductions[6].division_of_time);
          this.arrayDeductions.at(7)['controls'].division_of_time.setValue(this.payrollSettings[0].deductions[7].division_of_time);
          this.total();
        } 
      }
    });
  }
  

  createAddOns(addons): FormGroup {
    let settings: FormGroup = new FormGroup(
      {
        id: new FormControl(addons.id),
        name: new FormControl(addons.name),
        amount: new FormControl(addons.amount),
        division_of_time: new FormControl(addons.division_of_time),
        no_of_unit: new FormControl(addons.no_of_unit)
      }
    );
    return settings;
  }

  createDeductions(deduct): FormGroup {
    let settings: FormGroup = new FormGroup(
      {
        id: new FormControl(deduct.id),
        name: new FormControl(deduct.name),
        amount: new FormControl(deduct.amount),
        division_of_time: new FormControl(deduct.division_of_time),
        no_of_unit: new FormControl(deduct.no_of_unit)
      }
    );
    return settings;
  }

  initAddOns(addons: AddOns[]) {
    const formArray = this.formGroup.get('add_ons') as FormArray;
    addons.map(item => {
      formArray.push(this.createAddOns(item));
    });
    this.formGroup.setControl('add_ons', formArray);
  }

  initDeductions(deduct: Deductions[]) {
    const formArray = this.formGroup.get('deduction') as FormArray;
    deduct.map(item => {
      formArray.push(this.createDeductions(item));
    });
    this.formGroup.setControl('deduction', formArray);
  }

  get add_ons() {
    return this.formGroup.get('add_ons') as FormArray;
  }

  get deduction() {
    return this.formGroup.get('deduction') as FormArray;
  }


  // Get total
  total() {
    const addend1 = this.formGroup.get('salary_rate').value;
    const addend2 = this.formGroup.get('working_days').value;
    const sum = (Number(addend1) * addend2);
    this.formGroup.get('total_salary').setValue(currencyFormat(sum));

    const amount1 = this.arrayAddOns.at(0).value.amount;
    const multiplier1 = this.formGroup.get('overtime').value;
    const totalOvertime = Number(amount1) * multiplier1;
    this.formGroup.get('total_overtime').setValue(currencyFormat(totalOvertime));

    const amount2 = this.arrayAddOns.at(1).value.amount;
    const multiplier2 = this.formGroup.get('holiday_sr_x_30').value;
    const totalHoliday30 = Number(amount2) * multiplier2;
    this.formGroup.get('total_holiday_sr_x_30').setValue(currencyFormat(totalHoliday30));

    const amount3 = this.arrayAddOns.at(2).value.amount;
    const multiplier3 = this.formGroup.get('holiday_sr_x_100').value;
    const totalHoliday100 = Number(amount3) * multiplier3;
    this.formGroup.get('total_holiday_sr_x_100').setValue(currencyFormat(totalHoliday100));
    
    const amount4 = this.arrayAddOns.at(3).value.amount;
    const multiplier4 = this.formGroup.get('meal_allowance').value;
    const totalMealAllowance = Number(amount4) * multiplier4;
    this.formGroup.get('total_meal_allowance').setValue(currencyFormat(totalMealAllowance));
    
    const amount5 = this.arrayAddOns.at(4).value.amount;
    const multiplier5 = this.formGroup.get('others1').value;
    const totalOthers1 = Number(amount5) * multiplier5;
    this.formGroup.get('total_others1').setValue(currencyFormat(totalOthers1));
    
    const amount6 = this.arrayAddOns.at(5).value.amount;
    const multiplier6 = this.formGroup.get('others2').value;
    const totalOthers2 = Number(amount6) * multiplier6;
    this.formGroup.get('total_others2').setValue(currencyFormat(totalOthers2));

    const grossPay = sum + totalOvertime + totalMealAllowance + totalHoliday30 + totalHoliday100 + totalOthers1 + totalOthers2;
    this.formGroup.get('sub_total1').setValue(currencyFormat(grossPay));
    this.formGroup.get('gross_pay').setValue(currencyFormat(grossPay));
  
    const amount7 = this.arrayDeductions.at(0).value.amount;
    const multiplier7 = this.formGroup.get('absent').value;
    const totalAbsent = Number(amount7) * multiplier7;
    this.formGroup.get('total_absent').setValue(currencyFormat(totalAbsent));

    const amount8 = this.arrayDeductions.at(1).value.amount;
    const multiplier8 = this.formGroup.get('late').value;
    const totalLate = Number(amount8) * multiplier8;
    this.formGroup.get('total_late').setValue(currencyFormat(totalLate));

    const amount9 = this.arrayDeductions.at(2).value.amount;
    const multiplier9 = this.formGroup.get('undertime').value;
    const totalUndertime = Number(amount9) * multiplier9;
    this.formGroup.get('total_undertime').setValue(currencyFormat(totalUndertime));

    const amount10 = this.arrayDeductions.at(3).value.amount;
    const multiplier10 = this.formGroup.get('sss').value;
    const totalSss = Number(amount10) * multiplier10;
    this.formGroup.get('total_sss').setValue(currencyFormat(totalSss));

    const amount11 = this.arrayDeductions.at(4).value.amount;
    const multiplier11 = this.formGroup.get('philhealth').value;
    const totalPhilhealth = Number(amount11) * multiplier11;
    this.formGroup.get('total_philhealth').setValue(currencyFormat(totalPhilhealth));

    const amount12 = this.arrayDeductions.at(5).value.amount;
    const multiplier12 = this.formGroup.get('pag_ibig').value;
    const totalPagibig = Number(amount12) * multiplier12;
    this.formGroup.get('total_pag_ibig').setValue(currencyFormat(totalPagibig));
    
    const amount13 = this.arrayDeductions.at(6).value.amount;
    const multiplier13 = this.formGroup.get('omit1').value;
    const totalOmit1 = Number(amount13) * multiplier13;
    this.formGroup.get('total_omit1').setValue(currencyFormat(totalOmit1));

    const amount14 = this.arrayDeductions.at(7).value.amount;
    const multiplier14 = this.formGroup.get('omit2').value;
    const totalOmit2= Number(amount14) * multiplier14;
    this.formGroup.get('total_omit2').setValue(currencyFormat(totalOmit2));

    const totalDeduction = totalAbsent + totalLate + totalUndertime + totalSss + totalPhilhealth + totalPagibig + totalOmit1 + totalOmit2;
    this.formGroup.get('sub_total2').setValue(currencyFormat(totalDeduction));
    this.formGroup.get('total_deductions').setValue(currencyFormat(totalDeduction));

    const grandTotal = grossPay - totalDeduction;
    this.formGroup.get('net_pay').setValue(currencyFormat(grandTotal));
  }

  savePayroll() {
    // Set no. of units | add ons
    this.arrayAddOns.at(0)['controls'].no_of_unit.setValue(+this.formGroup.get('overtime').value);
    this.arrayAddOns.at(1)['controls'].no_of_unit.setValue(+this.formGroup.get('holiday_sr_x_30').value);
    this.arrayAddOns.at(2)['controls'].no_of_unit.setValue(+this.formGroup.get('holiday_sr_x_100').value);
    this.arrayAddOns.at(3)['controls'].no_of_unit.setValue(+this.formGroup.get('meal_allowance').value);
    this.arrayAddOns.at(4)['controls'].no_of_unit.setValue(+this.formGroup.get('others1').value);
    this.arrayAddOns.at(5)['controls'].no_of_unit.setValue(+this.formGroup.get('others2').value);

    // Set no. of units | deductions
    this.arrayDeductions.at(0)['controls'].no_of_unit.setValue(+this.formGroup.get('absent').value);
    this.arrayDeductions.at(1)['controls'].no_of_unit.setValue(+this.formGroup.get('late').value);
    this.arrayDeductions.at(2)['controls'].no_of_unit.setValue(+this.formGroup.get('undertime').value);
    this.arrayDeductions.at(3)['controls'].no_of_unit.setValue(+this.formGroup.get('sss').value);
    this.arrayDeductions.at(4)['controls'].no_of_unit.setValue(+this.formGroup.get('philhealth').value);
    this.arrayDeductions.at(5)['controls'].no_of_unit.setValue(+this.formGroup.get('pag_ibig').value);
    this.arrayDeductions.at(6)['controls'].no_of_unit.setValue(+this.formGroup.get('omit1').value);
    this.arrayDeductions.at(7)['controls'].no_of_unit.setValue(+this.formGroup.get('omit2').value);

    const data = {
      user_clinic_id: this.clinic,
      employee_id: this.employeeId,
      period: this.selectedPeriod,
      month: this.selectedMonth,
      year: this.selectedYear,
      salary_rate: this.formGroup.get('salary_rate').value,
      no_of_days: this.formGroup.get('working_days').value,
      add_ons: this.formGroup.get('add_ons').value,
      deduction: this.formGroup.get('deduction').value,
    }
    this.payroll.savePayroll(data).subscribe(res => {
      if(res) {
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Employee payroll has been successfully saved.',
          button: 'Okay',
          event: this.close
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => {
          this.getPayroll();
          this.goBackToEmployees();
        });
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Sorry!',
        message: err.error.message,
        button: 'Okay',
        event: this.close
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }));
  }

  updatePayroll() {
    // Set no. of units | add ons
    this.arrayAddOns.at(0)['controls'].no_of_unit.setValue(+this.formGroup.get('overtime').value);
    this.arrayAddOns.at(1)['controls'].no_of_unit.setValue(+this.formGroup.get('holiday_sr_x_30').value);
    this.arrayAddOns.at(2)['controls'].no_of_unit.setValue(+this.formGroup.get('holiday_sr_x_100').value);
    this.arrayAddOns.at(3)['controls'].no_of_unit.setValue(+this.formGroup.get('meal_allowance').value);
    this.arrayAddOns.at(4)['controls'].no_of_unit.setValue(+this.formGroup.get('others1').value);
    this.arrayAddOns.at(5)['controls'].no_of_unit.setValue(+this.formGroup.get('others2').value);

    // Set no. of units | deductions
    this.arrayDeductions.at(0)['controls'].no_of_unit.setValue(+this.formGroup.get('absent').value);
    this.arrayDeductions.at(1)['controls'].no_of_unit.setValue(+this.formGroup.get('late').value);
    this.arrayDeductions.at(2)['controls'].no_of_unit.setValue(+this.formGroup.get('undertime').value);
    this.arrayDeductions.at(3)['controls'].no_of_unit.setValue(+this.formGroup.get('sss').value);
    this.arrayDeductions.at(4)['controls'].no_of_unit.setValue(+this.formGroup.get('philhealth').value);
    this.arrayDeductions.at(5)['controls'].no_of_unit.setValue(+this.formGroup.get('pag_ibig').value);
    this.arrayDeductions.at(6)['controls'].no_of_unit.setValue(+this.formGroup.get('omit1').value);
    this.arrayDeductions.at(7)['controls'].no_of_unit.setValue(+this.formGroup.get('omit2').value);

    const data = {
      id: this.payrollId,
      user_clinic_id: this.clinic,
      employee_id: this.employeeId,
      period: this.selectedPeriod,
      month: this.selectedMonth,
      year: this.selectedYear,
      salary_rate: this.formGroup.get('salary_rate').value,
      no_of_days: this.formGroup.get('working_days').value,
      add_ons: this.formGroup.get('add_ons').value,
      deduction: this.formGroup.get('deduction').value,
    }
    this.payroll.savePayroll(data).subscribe(res => {
      if(res) {
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Employee payroll has been successfully updated.',
          button: 'Okay',
          event: this.close
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => {
          this.getPayroll();
          this.goBackToEmployees();
        });
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Sorry!',
        message: err.error.message,
        button: 'Okay',
        event: this.close
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }));
  }

  close() {
    this.dialogRef.close();
  }

  printPayslip() {
    this.financeDirectory.downloadPayroll(this.clinic, this.employeeId, this.selectedYear,this.selectedMonth,this.selectedPeriod).subscribe(res => {
      if(res) {
        const url = URL.createObjectURL(res);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = url;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Print Failed. Please add and save the payroll first before printing.',
        button: 'Okay',
        event: this.close
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }));
  }
}
