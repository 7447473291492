import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { PatientService } from '../../../../service/patient/patient.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';

@Component({
  selector: 'ark-doctor-fee',
  templateUrl: './doctor-fee.component.html',
  styleUrls: ['./doctor-fee.component.scss']
})
export class DoctorFeeComponent implements OnInit, OnChanges {

  @Input() month: any = null; 
  @Input() year: any = null; 
  @Input() search: any = null; 
  doctorFees = [];
  clinic = null;
  selectedYear = '';
  selectedMonth = '';
  keyword = '';
  isNoResult: boolean = true;

  constructor(
    private auth: AuthService,
    private patient: PatientService
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
  }

  ngOnInit() {
    
  }

  ngOnChanges() {
    if(this.month || this.year || this.search) {
      this.selectedMonth =  this.month;
      this.selectedYear =  this.year;
      this.keyword = this.search;
      this.getList();
    }
  }

  getList() {
    this.patient.getDoctorFee(this.clinic, this.selectedMonth, this.selectedYear, this.keyword).subscribe(res => {
      if(res) {
        this.doctorFees = res['doctor-fee'].data;
        if(this.doctorFees.length > 0) {
          this.isNoResult = false;
        } else {
          this.isNoResult = true;
        }
      }
    });
  }

  formatDate(obj) {
    return obj.toString().replace(/\s/g, "T");
  }
}
