import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/modules/shared/services/http/http.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { dentistDirectory, medicalDoctorDirectory, supplierDirectory, laboratoryDirectory, diagnosticCenterDirectory } from 'src/app/common/api';

@Injectable()
export class DirectoryService extends HttpService {

  constructor(http: HttpClient, private _router: Router) {
    super('',http);
  }

  saveDentistDirectory(data) {
    return this.http.post(dentistDirectory, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  getDentistDirectory(id) {
    return this.get(dentistDirectory + '?user_clinic_id=' + id).map(res => {
      return res['data'];
    });
  }

  deleteDentistDirectory(id, user_clinic_id) {
    return this.http.delete(dentistDirectory + '/' + id + '?user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }

  updateDentistDirectory(id, data) {
    return this.http.put(dentistDirectory + '/' + id, data).map((dataResponse) => {
      return dataResponse;
    })
  }

  saveMedicalDoctorDirectory(data) {
    return this.http.post(medicalDoctorDirectory, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  getMedicalDoctorDirectory(id) {
    return this.get(medicalDoctorDirectory + '?user_clinic_id=' + id).map(res => {
      return res['data'];
    });
  }

  deleteMedicalDoctorDirectory(id, user_clinic_id) {
    return this.http.delete(medicalDoctorDirectory + '/' + id + '?user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }

  updateMedicalDoctorDirectory(id, data) {
    return this.http.put(medicalDoctorDirectory + '/' + id, data).map((dataResponse) => {
      return dataResponse;
    })
  }

  saveSupplierDirectory(data) {
    return this.http.post(supplierDirectory, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  getSupplierDirectory(id) {
    return this.get(supplierDirectory + '?user_clinic_id=' + id).map(res => {
      return res['data'];
    });
  }

  deleteSupplierDirectory(id, user_clinic_id) {
    return this.http.delete(supplierDirectory + '/' + id + '?user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }

  updateSupplierDirectory(id, data) {
    return this.http.put(supplierDirectory + '/' + id, data).map((dataResponse) => {
      return dataResponse;
    })
  }

  saveLaboratoryDirectory(data) {
    return this.http.post(laboratoryDirectory, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  getLaboratoryDirectory(id) {
    return this.get(laboratoryDirectory + '?user_clinic_id=' + id).map(res => {
      return res['data'];
    });
  }

  deleteLaboratoryDirectory(id, user_clinic_id) {
    return this.http.delete(laboratoryDirectory + '/' + id + '?user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }

  updateLaboratoryDirectory(id, data) {
    return this.http.put(laboratoryDirectory + '/' + id, data).map((dataResponse) => {
      return dataResponse;
    })
  }
  
  updatemedicalDoctorDirectory(id, data) {
    return this.http.put(medicalDoctorDirectory + '/' + id, data).map((dataResponse) => {
      return dataResponse;
    })
  }

  saveDiagnosticCenterDirectory(data) {
    return this.http.post(diagnosticCenterDirectory, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  getDiagnosticCenterDirectory(id) {
    return this.get(diagnosticCenterDirectory + '?user_clinic_id=' + id).map(res => {
      return res['data'];
    });
  }

  deleteDiagnosticCenterDirectory(id, user_clinic_id) {
    return this.http.delete(diagnosticCenterDirectory + '/' + id + '?user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }

  updateDiagnosticCenterDirectory(id, data) {
    return this.http.put(diagnosticCenterDirectory + '/' + id, data).map((dataResponse) => {
      return dataResponse;
    })
  }
}
