import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'ark-view-radiographs',
  templateUrl: './view-radiographs.component.html',
  styleUrls: ['./view-radiographs.component.scss']
})
export class ViewRadiographsComponent implements OnInit {

  detail: any;

  dialogConfig = new MatDialogConfig();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ViewRadiographsComponent>,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.detail = this.data;
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  close() {
    this.dialogRef.close();
  }

}
