import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubscribeService } from 'src/app/modules/subscription/services/subscribe/subscribe.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';

@Component({
  selector: 'ark-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.scss']
})
export class BillingDetailsComponent implements OnInit {

  formGroup: FormGroup;
  hasBilling: boolean = false;
  hasSubscription: boolean = false;
  clinic = null;
  user = null;
  billing = [];
  subscriptionPlan = null;
  clinicData = null;
  clinicMembers = '';
  additionalUsers = '';

  constructor(
    private formBuilder: FormBuilder,
    private subscription: SubscribeService,
    private auth: AuthService
  ) { 
    this.formGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      zipcode: ['', [Validators.required]],
      country: ['', [Validators.required]],
      card_number: ['', [Validators.required]],
      cardholder: ['', [Validators.required]],
      date: ['', [Validators.required]],
      cvv: ['', [Validators.required]],
    });
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.user = this.auth.getAuthUser().id;
  }

  ngOnInit() {
    this.formGroup.disable();
    this.subscription.subscriptionBilling(this.clinic, this.user).subscribe(res => {
      this.billing = res;
      if(this.billing.length > 0) {
        this.hasBilling = false;
      } else {
        this.hasBilling = true;
      }
    });
    
    this.subscription.subscriptionBillingDetails(this.clinic, this.user).subscribe(res => {
      this.subscriptionPlan = res;
      this.clinicData = res.base_subscription_plan.clinic;
      this.clinicMembers = res.base_subscription_plan.members;
      this.additionalUsers = res.add_ons;
      if(this.additionalUsers.length > 0) {
        this.hasSubscription = false;
      } else {
        this.hasSubscription = true;
      }
    });
  }

}
