import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogConfig, MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { SubscriptionFormComponent } from '../subscription-form/subscription-form.component';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

@Component({
  selector: 'ark-choose-subscription',
  templateUrl: './choose-subscription.component.html',
  styleUrls: ['./choose-subscription.component.scss']
})
export class ChooseSubscriptionComponent implements OnInit {

  dialogConfig = new MatDialogConfig();
  selectedPlan = null;
  plans = [
    // { 
    //   id: 1, 
    //   name: 'Premium Subscription Plan', 
    //   value: 1,
    //   amount: '1,299',
    //   description: 'Inclusive of 2 dentist with 5 assistants after a trial period.'
    // }, 
    { 
      id: 2, 
      name: '2-Month Trial Subscription Plan', 
      value: 2,
      amount: '599',
      description: 'Inclusive of 1 dentist with 2 assistants after a trial period.'
    }
  ];
  PLAN = '';
  PLAN_AMOUNT = '';
  PLAN_DESC = '';
  isSelected: boolean = false;
  selected = -1;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ChooseSubscriptionComponent>,
  ) { }

  ngOnInit() {
  }

  close () {
    this.dialogRef.close();
  }

  onCheck(event, obj) {
    if(event.checked) {
      this.isSelected = true;
      this.PLAN = obj;
      this.PLAN_AMOUNT = obj.amount;
      this.PLAN_DESC = obj.description;
    } else {
      this.isSelected = false;
      this.PLAN = '';
      this.PLAN_AMOUNT = '';
      this.PLAN_DESC = '';
    }
  }

  setUpAccount() {
    if(this.isSelected) {
      this.dialogRef.close();
      this.dialogConfig.disableClose = true;
      this.dialogConfig.autoFocus = false;
      this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
      this.dialogConfig.data = {
        data: this.PLAN
      }
      const dialogRef = this.dialog.open(SubscriptionFormComponent, this.dialogConfig);
    } else {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Please select a plan first.',
        button: 'Okay',
        event: this.close
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }
  }
}
