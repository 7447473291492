import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogConfig, MatDialog } from '@angular/material';

@Component({
  selector: 'ark-work-authorization',
  templateUrl: './work-authorization.component.html',
  styleUrls: ['./work-authorization.component.scss']
})
export class WorkAuthorizationComponent implements OnInit {

  formGroup: FormGroup;
  forms = [
    { id: 1, name: 'Crown' },
    { id: 2, name: 'Partial Dentures' },
    { id: 3, name: 'Full Dentures' },
    { id: 4, name: 'Orthodontics' },
  ];
  form: number = 1;
  header = '';
  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
  ) {
    this.formGroup = this.formBuilder.group({
      form: ['']
    });
    this.formGroup.get('form').setValue(1);
    this.header = 'Fixed Prosthesis Prescription';
  }

  ngOnInit() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  getForm(val) {
    this.form = val.value;
    if(this.form == 1) {
      this.header = 'Fixed Prosthesis Prescription';
    } else if(this.form == 2) {
      this.header = 'Removable Prosthesis Prescription';
    } else if(this.form == 3) {
      this.header = 'Removable Prosthesis Prescription';
    } else if(this.form == 4) {
      this.header = 'Removable Orthodontics Appliances';
    } 

    this.formGroup.get('form').setValue(this.form);
  }
}
