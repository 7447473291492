import { Component, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDeletionComponent } from 'src/app/modules/shared/components/confirm-deletion/confirm-deletion.component';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

@Component({
  selector: 'ark-dental-form-records',
  templateUrl: './dental-form-records.component.html',
  styleUrls: ['./dental-form-records.component.scss']
})
export class DentalFormRecordsComponent implements OnInit {

  isNoResult: boolean = true;
  DentalFormDirectory = [];
  dialogConfig = new MatDialogConfig();
  dialogRef: any;
  clinic = null;
  success = false;
  patient_id : null;
  
  constructor(
    private patient: PatientsDirectoryService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private _router: Router,
    public dialog: MatDialog
  ) { 
    this.route.params.subscribe(param => {
      this.patient_id = param.id;
    });
    this.clinic = this.auth.getAuthUser().user_clinic.id;
  }

  ngOnInit() {
    this.getPatientDentalFormRecords();
  }

  getPatientDentalFormRecords() {
    this.patient.getPatientDentalFormRecords(this.clinic, this.patient_id).subscribe(res => { 
      if(res) {
        this.DentalFormDirectory  = res.data;
        if(this.DentalFormDirectory .length > 0) {
          this.isNoResult = false;
        } else {
          this.isNoResult = true;
        }
      }
    });
  }

  download(filename, path) {
    this.patient.downloadPatientDentalFormRecords(this.clinic, filename, path).subscribe(res => {
      if(res) {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(res);
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
    });
  }

  delete(id) {
    this.dialogConfig.data = {
      subject: 'delete this dental form record'
    };
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    let dialogRef = this.dialog.open(ConfirmDeletionComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
      if(trigger) {
        this.patient.deletePatientDentalFormRecord(id, this.clinic).subscribe(res => {
          if(res) {
            this.success = true;
            this.getPatientDentalFormRecords();
            this.dialogConfig.data = {
              title: 'Success!',
              message: 'Dental form record has been successfully deleted.',
              button: 'Okay',
              event: this.closeOnClick
            };
            this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
          }
        });
      }
    });
  }

  closeOnClick() {
    this.dialogRef.close();
  }
}
