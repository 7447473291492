import { Component, OnInit, PipeTransform, Pipe, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PatientService } from 'src/app/modules/front-desk/service/patient/patient.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { DatePipe } from '@angular/common';
import { keypressValidateNumber } from 'src/app/common/helper';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { MatDialogConfig, MatDialog } from '@angular/material';

@Pipe({ name: 'keys',  pure: false })
export class KeysPipe implements PipeTransform {
  transform(value: any, args: any[] = null): any {
      return Object.keys(value)
  }
}

@Component({
  selector: 'ark-private-information',
  templateUrl: './private-information.component.html',
  styleUrls: ['./private-information.component.scss']
})
export class PrivateInformationComponent implements OnInit {
  
  @Input('selectedIndex') selectedIndex: number; 
  @Output('selectedIndexChange') selectedIndexChange: EventEmitter<any> = new EventEmitter;
  @Output() isDisabled: EventEmitter<any> = new EventEmitter;

  today = new Date(); 
  errorMessage = null;
  birthdate = '';
  civil_status = '';
  gender = '';
  landline_number = '';
  height = '';
  weight = '';
  blood_type = '';
  detailed_address = '';
  country = '';
  province = '';
  city = '';
  barangay = '';
  zip_code = '';
  referred_by = '';
  countries = [];
  provinces = [];
  cities = [];
  datePipe = new DatePipe('en-US');
  civilStatuses = [
    { id: 1, name: 'Single' },
    { id: 2, name: 'Married' },
    { id: 3, name: 'Widowed' },
    { id: 4, name: 'Divorced' },
    { id: 5, name: 'Separated' }
  ];
  bloodTypes = [
    { id: 1, name: 'Type A+' },
    { id: 2, name: 'Type O+' },
    { id: 3, name: 'Type B+' },
    { id: 4, name: 'Type AB+' },
    { id: 5, name: 'Type A-' },
    { id: 6, name: 'Type O-' },
    { id: 7, name: 'Type B-' },
    { id: 8, name: 'Type AB-' },
  ];
  genders = [
    { id : '1', name: 'Male' },
    { id : '2', name: 'Female' }
  ];

  formGroup: FormGroup;
  display = false;
  success = false;
  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  constructor(
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private auth: AuthService,
    public dialog: MatDialog,
  ) { 
    this.formGroup = this.formBuilder.group({
      birthdate: ['', [Validators.required]],
      civil_status: [''],
      gender: ['', [Validators.required]],
      landline_number: [''],
      height: [''],
      weight: [''],
      blood_type: [''],
      detailed_address: ['', [Validators.required]],
      country: ['', [Validators.required]],
      province: ['', [Validators.required]],
      city: ['', [Validators.required]],
      barangay: [''],
      zip_code: [''],
      referred_by: [''],
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.patientService.getCountry().subscribe(result => {
        this.countries = result;
      });
      this.formGroup.get('country').setValue('168');
      this.patientService.getProvince().subscribe(result => {
        this.provinces = result;
      });
    });
  }

  selectionChange(e) {
    if(e.value !== '168') {
      this.formGroup.controls['province'].disable();
      this.formGroup.controls['city'].disable();
      this.formGroup.controls['barangay'].disable();
      this.formGroup.controls['zip_code'].disable();

      this.formGroup.get('province').setValue('');
      this.formGroup.get('city').setValue('');
      this.formGroup.get('barangay').setValue('');
      this.formGroup.get('zip_code').setValue('');
    } else {
      this.formGroup.controls['province'].enable();
      this.formGroup.controls['city'].enable();
      this.formGroup.controls['barangay'].enable();
      this.formGroup.controls['zip_code'].enable();
    }
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }

  viewCity(id){
    this.patientService.getCity(id).subscribe(result => {
      this.cities = result;
    });
  }

  goToEmergencyContact() {
    let birthdateFormat = this.datePipe.transform(this.formGroup.value.birthdate, 'MM/dd/yyyy');
    let today = this.datePipe.transform(this.today, 'MM/dd/yyyy');

    this.birthdate = birthdateFormat ? birthdateFormat : '';
    this.civil_status = this.formGroup.value.civil_status ? this.formGroup.value.civil_status : '';
    this.gender = this.formGroup.value.gender;
    this.landline_number = this.formGroup.value.landline_number;
    this.height = this.formGroup.value.height;
    this.weight = this.formGroup.value.weight;
    this.blood_type = this.formGroup.value.blood_type;
    this.detailed_address = this.formGroup.value.detailed_address;
    this.country = this.formGroup.value.country;
    this.province = this.formGroup.value.province ? this.formGroup.value.province : '';
    this.city = this.formGroup.value.city ? this.formGroup.value.city : '';
    this.barangay = this.formGroup.value.barangay ? this.formGroup.value.barangay : '';
    this.zip_code = this.formGroup.value.zip_code ? this.formGroup.value.zip_code : '';
    this.referred_by = this.formGroup.value.referred_by;
    let data = {
      'birthdate' : this.birthdate,
      'civil_status' : this.civil_status,
      'gender' : this.gender,
      'landline_number' : this.landline_number.toString(),
      'height' : this.height,
      'weight' : this.weight,
      'blood_type' : this.blood_type,
      'detailed_address' : this.detailed_address,
      'country' : this.country,
      'province' : this.province,
      'city' : this.city,
      'barangay' : this.barangay,
      'zip_code' : this.zip_code,
      'referred_by' : this.referred_by
    }
    if (this.formGroup.value.birthdate > this.today) {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'The birthdate field is invalid.',
        button: 'Okay',
        event: this.close
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    } else {
      this.patientService.checkPatientPrivateInfo(this.auth.getAuthUser().user_clinic.id, data).subscribe(response => {
        if (response) {
          this.success = true;
          this.patientService.setData(this.formGroup.value);
          const tab = this.selectedIndex = this.selectedIndex + 1;
          this.selectedIndexChange.emit(tab);
        }
        this.isDisabled.emit(false);
      },(err =>{
        this.errorMessage = err.error.errors;
        this.triggerErrors(err.error.errors);
        this.isDisabled.emit(true);
      }));
    }
  }

  goToPatientName() {
    const tab = this.selectedIndex = this.selectedIndex - 1;
    this.selectedIndexChange.emit(tab);
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }

  close() {
    this.dialogRef.close();
  }
}
