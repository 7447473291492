import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { keypressValidateNumber } from 'src/app/common/helper';
import { MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatAutocompleteTrigger } from '@angular/material';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { Observable } from 'rxjs';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard/dashboard.service';
import { startWith, map } from 'rxjs/operators';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { LaboratoryWorksService } from '../../../service/laboratory-works/laboratory-works.service';
import { DatePipe } from '@angular/common';

export interface Patient {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
}

@Component({
  selector: 'ark-add-lab-works',
  templateUrl: './add-lab-works.component.html',
  styleUrls: ['./add-lab-works.component.scss']
})
export class AddLabWorksComponent implements OnInit {
  
  today = new Date(); 
  datePipe = new DatePipe('en-US');
  errorMessage = null;
  clinic = '';
  dialogConfig = new MatDialogConfig();
  formGroup: FormGroup;
  patientControl = new FormControl();
  patients: Patient[] = [];
  filteredPatients: Observable<Patient[]>;
  purposes = [
    { id: 1, name: 'Payment' },
    { id: 2, name: 'Pick-up' },
    { id: 3, name: 'Delivery' }
  ];
  patientId = null;
  patientName = null;
  @ViewChild('patient') patient: MatAutocompleteTrigger;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddLabWorksComponent>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private dashboard: DashboardService,
    private laboratory: LaboratoryWorksService
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.dashboard.getPatientList(this.clinic, this.clinic).subscribe(res => {
      res.data.forEach(element => {
        let obj: Patient = {
          id: element.id, 
          firstName: element.first_name, 
          middleName: element.middle_name ? element.middle_name : '', 
          lastName: element.last_name
        };
        this.patients.push(obj);
      });
    });
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      date: [''],
      patient_id: [''],
      case: [''],
      amount: [''],
      lab_name: [''],
      cheque_no: [''],
      purpose: [''],
      messenger: [''],
      received_by: ['']
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';

    this.filteredPatients = this.patientControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterPatient(name) : this.patients.slice()),
      );
  }

  private _filterPatient(name: string): Patient[] {
    const filterValue = name.toLowerCase();
    return this.patients.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1 || option.middleName.toLowerCase().indexOf(filterValue) > -1);
  }

  displayPatient(patient?: any) : string | undefined {
    return patient ? patient.lastName + ', ' + patient.firstName + ' ' + patient.middleName : undefined;
  }

  onFocusPatient() {
    this.patient._onChange("");
    this.patient.openPanel();
  }

  getPatient(event) {
    this.patientId = event.option.value.id;
    this.patientName = event.option.value.firstName + ' ' + (event.option.value.middleName ? event.option.value.middleName + ' ' : '') + event.option.value.lastName;
  }

  getVisitorName(e) {
    this.patientName = e;
  }

  createLaboratoryWorks() {
    let date = this.datePipe.transform(this.formGroup.value.date, 'yyyy-MM-dd');
    let data = {
      user_clinic_id: this.clinic,
      date: date,
      patient_id: this.patientId,
      case: this.formGroup.value.case,
      amount: this.formGroup.value.amount,
      lab_name: this.formGroup.value.lab_name,
      cheque_no: this.formGroup.value.cheque_no,
      purpose: this.formGroup.value.purpose,
      messenger: this.formGroup.value.messenger,
      received_by: this.formGroup.value.received_by
    }
    this.laboratory.saveLabWork(data).subscribe(res => {
      if(res) {
        this.dialogRef.close(res);
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Clinic log has been successfully saved.',
          button: 'Okay',
          event: this.close
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Sorry!',
        message: err.error.message,
        button: 'Okay',
        event: this.close
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      this.errorMessage = err.error.errors;
      this.triggerErrors(err.error.errors);
    }));
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }
}
