import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import * as moment from 'moment';
import { ExpensesService } from '../../../service/expenses/expenses.service';

@Component({
  selector: 'ark-monthly-expenses-summary',
  templateUrl: './monthly-expenses-summary.component.html',
  styleUrls: ['./monthly-expenses-summary.component.scss']
})
export class MonthlyExpensesSummaryComponent implements OnInit, OnChanges {

  today = new Date(); 
  formGroup : FormGroup;
  clinic = null;
  summary = null;
  months = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
  ];
  totalOperExpenses = null; 
  totalDirectCost = null; 
  totalDoctorFee = null;
  @Input() expensesSummary: any = null; 
  @Input() selectedMonth: any = null; 
  @Input() selectedYear: any = null; 

  constructor(
    private formBuilder: FormBuilder,
    private expenses: ExpensesService,
    private auth: AuthService
  ) { 
    this.formGroup = this.formBuilder.group({
      month: [''],
      year: [''],
    });
  }

  ngOnInit() {
    this.formGroup.get('month').setValue(moment(this.today).format("MMMM"));
    this.formGroup.get('year').setValue(moment(this.today).format("YYYY"));
    this.formGroup.controls['month'].disable();
    this.formGroup.controls['year'].disable();
    this.getSummary();
  }

  ngOnChanges() {
    if(this.expensesSummary) {
      this.summary = this.expensesSummary;
      this.totalOperExpenses = this.summary['total-operational-expenses'].total_amount_due;
      this.totalDirectCost = this.summary['total-direct-cost'].total_direct_cost;
      this.totalDoctorFee = this.summary['total-doctor-fee'].total_doctor_fee;
    }
    if(this.selectedMonth || this.selectedYear) {
      this.formGroup.get('month').setValue(moment(this.selectedMonth, 'M').format('MMMM'));
      this.formGroup.get('year').setValue(this.selectedYear);
      this.getSummary();
    }
  }

  getSummary() {
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.expenses.getMonthlySummaryExpenses(this.clinic, this.selectedMonth, this.selectedYear).subscribe(res => {
      if(res) {
        this.summary = res;
        this.totalOperExpenses = res['total-operational-expenses'].total_amount_due;
        this.totalDirectCost = res['total-direct-cost'].total_direct_cost;
        this.totalDoctorFee = res['total-doctor-fee'].total_doctor_fee;
      }
    })
  }
}
