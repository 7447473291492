import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'ark-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {

  title: string;
  message: string;

  label = '';

  private onClickFunction:() => void;


  constructor(
    private _router: Router,
    public dialogRef: MatDialogRef<AlertMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.title = this.data.title;
    this.message = this.data.message;
    this.label = this.data.button;
    // this.onClickFunction = this.data.event ? this.data.event : this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
