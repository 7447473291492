import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { FormBuilder } from '@angular/forms';
import { InventoryService } from 'src/app/modules/front-desk/service/inventory/inventory.service';

@Component({
  selector: 'ark-add-check-out',
  templateUrl: './add-check-out.component.html',
  styleUrls: ['./add-check-out.component.scss']
})
export class AddCheckOutComponent implements OnInit {
  item_array = [];
  filteredOptions = [];
  clinic = '';
  quanty_array = {};
  ndx = 0;
  ctr = 0;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private auth: AuthService,
  public dialogRef: MatDialogRef<AddCheckOutComponent>, private fb: FormBuilder,
  private api: InventoryService, private dialog: MatDialog) {
    this.clinic = this.auth.getAuthUser().user_clinic.id;
  }

  ngOnInit() {
  }

  getItems(val) {
    let filter = 'item-directory?user_clinic_id=' + this.clinic + '&item_name=' + val;
    this.api.getAPI(filter, '').subscribe(response => {
      if (response.status_code == 200) {
        this.filteredOptions = response.data.data;
      }
    });
  }

  addItems() {
    this.item_array.push({
      data: '',
      quantity: '',
      remark: ''
    });

    this.quanty_array['element-'+ this.ndx] = false;
    this.item_array['element-'+ this.ndx] = false;
    this.ndx++;
  }

  getFirst(arr, index) {
    const i = arr.findIndex( e => e === this.item_array[index].data);
    if (i === -1) {
      this.item_array[index].data = arr[0];
    }
  }

  displayFn(value) {
    return value ? value.name  + (value.brandname ? ' - ' +  value.brandname : ' ') + (value.description? ' - ' +  value.description: ' ') : value;
  }

  closeDialog(e){
    if(e){
      this.ctr = 0;
      e.forEach((element, i) => {
          this.quanty_array['element-' + i] = false;
          this.item_array['element-' + i] = false;
      });
      e.forEach((element, i) => {
        if(element.quantity == '' || element.quantity == null ){
          this.quanty_array['element-' + i] = true;
          this.ctr++
        }
        if(element.data == '' || element.data == null ){
          
          this.item_array['element-' + i] = true;
          this.ctr++
        }
      });
      
      if(this.ctr == 0){
        this.dialogRef.close(e);
      }
    }else{
      this.dialogRef.close(e);
    } 
  }

  removeItem(index) {
    this.item_array.splice(index, 1);
    delete(this.quanty_array['element-' + index]);
    delete(this.item_array['element-' + index]);
  }

  getValue(e) {
    return this.quanty_array['element-' + e];
  }

  getValueItem(e) {
    return this.item_array['element-' + e];
  }

  onEnter(ndx){
    this.item_array['element-'+ ndx] = false;
  }

  inputQuantity(ndx){
      this.quanty_array['element-'+ ndx] = false;
  }

}
