import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { PatientsDirectoryService } from '../../services/patients-directory/patients-directory.service';

@Component({
  selector: 'ark-patient-general-info',
  templateUrl: './patient-general-info.component.html',
  styleUrls: ['./patient-general-info.component.scss']
})
export class PatientGeneralInfoComponent implements OnInit {

  patientId = '';
  patientInfo: any;

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private patientsDirectory: PatientsDirectoryService
  ) { 
    this.route.params.subscribe(param => {
      this.patientId = param.id
    });
  }

  ngOnInit() {
    let id = this.auth.getAuthUser().user_clinic.id;
    this.patientsDirectory.getPatientInfo(id, this.patientId, id).subscribe(response =>{
      this.patientInfo = response['data'][0];
    });
  }
}
