import { Component, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { PlayDemoComponent } from '../play-demo/play-demo.component';

@Component({
  selector: 'ark-treatment-demo',
  templateUrl: './treatment-demo.component.html',
  styleUrls: ['./treatment-demo.component.scss']
})
export class TreatmentDemoComponent implements OnInit {

  dialogConfig = new MatDialogConfig();
  dialogRef: any;
  videos = [
    { 
      id: 1, 
      name: 'Braces', 
      url: 'https://player.vimeo.com/video/362297533', 
      photo: '/assets/image/braces.jpg', 
      duration: '01:53' 
    }, { 
      id: 2, 
      name: 'Bridge', 
      url: 'https://player.vimeo.com/video/362297736', 
      photo: '/assets/image/bridge.jpg', 
      duration: '01:50' 
    }, { 
      id: 3, 
      name: 'Hygiene', 
      url: 'https://player.vimeo.com/video/362297981', 
      photo: '/assets/image/hygiene.jpg', 
      duration: '01:54' 
    }, { 
      id: 4, 
      name: 'Perio', 
      url: 'https://player.vimeo.com/video/362298191', 
      photo: '/assets/image/perio.jpg', 
      duration: '01:34' 
    }, { 
      id: 5, 
      name: 'Sealant', 
      url: 'https://player.vimeo.com/video/364440929', 
      photo: '/assets/image/sealant.jpg', 
      duration: '01:11' 
    }, { 
      id: 6, 
      name: 'Wisdom Tooth', 
      url: 'https://player.vimeo.com/video/364440985', 
      photo: '/assets/image/wisdom-tooth.jpg', 
      duration: '01:38' 
    }, 
  ]

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  openVideo(data) {
    // this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogConfig.data = {
      data: data
    }
    this.dialogRef = this.dialog.open(PlayDemoComponent, this.dialogConfig);
  }
}
