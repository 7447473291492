import { Component, OnInit, Input, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { DashboardService } from '../../../services/dashboard/dashboard.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ViewAppointmentComponent } from '../../view-appointment/view-appointment.component';
import { SidebarService } from 'src/app/modules/core/services/sidebar/sidebar.service';

@Component({
  selector: 'ark-week-view',
  templateUrl: './week-view.component.html',
  styleUrls: ['./week-view.component.scss']
})
export class WeekViewComponent implements OnInit, OnChanges {

  @Input() dentistId: any = null;
  @Input() newAppointment: any = null;
  @Input() color: any = null;
  @Input() start: any = null;
  @Input() end: any = null;
  @Input() weekDays: any = null;
  @Input() weekDaysFormat: any = null;

  success: boolean = false;
  isSidebarMinimized: boolean = false;
  appointments = null;
  clinic = '';
  firstDay = null;
  lastDay = null;
  filter = 'week';
  timeRange = [
    { id: 0, name: ' ', value: ' ' },
    { id: 1, name: '06:00 AM', value: '06:00' },
    { id: 2, name: '06:30 AM', value: '06:30' },
    { id: 3, name: '07:00 AM', value: '07:00' },
    { id: 4, name: '07:30 AM', value: '07:30' },
    { id: 5, name: '08:00 AM', value: '08:00' },
    { id: 6, name: '08:30 AM', value: '08:30' },
    { id: 7, name: '09:00 AM', value: '09:00' },
    { id: 8, name: '09:30 AM', value: '09:30' },
    { id: 9, name: '10:00 AM', value: '10:00' },
    { id: 10, name: '10:30 AM', value: '10:30' },
    { id: 11, name: '11:00 AM', value: '11:00' },
    { id: 12, name: '11:30 AM', value: '11:30' },
    { id: 13, name: '12:00 PM', value: '12:00' },
    { id: 14, name: '12:30 PM', value: '12:30' },
    { id: 15, name: '01:00 PM', value: '13:00' },
    { id: 16, name: '01:30 PM', value: '13:30' },
    { id: 17, name: '02:00 PM', value: '14:00' },
    { id: 18, name: '02:30 PM', value: '14:30' },
    { id: 19, name: '03:00 PM', value: '15:00' },
    { id: 20, name: '03:30 PM', value: '15:30' },
    { id: 21, name: '04:00 PM', value: '16:00' },
    { id: 22, name: '04:30 PM', value: '16:30' },
    { id: 23, name: '05:00 PM', value: '17:00' },
    { id: 24, name: '05:30 PM', value: '17:30' },
    { id: 25, name: '06:00 PM', value: '18:00' },
    { id: 26, name: '06:30 PM', value: '18:30' },
    { id: 27, name: '07:00 PM', value: '19:00' },
    { id: 28, name: '07:30 PM', value: '19:30' },
    { id: 29, name: '08:00 PM', value: '20:00' },
    { id: 30, name: '08:30 PM', value: '20:30' },
    { id: 31, name: '09:00 PM', value: '21:00' },
    { id: 32, name: '09:30 PM', value: '21:30' },
    { id: 33, name: '10:00 PM', value: '22:00' },
    { id: 34, name: '10:30 PM', value: '22:30' },
    { id: 35, name: '11:00 PM', value: '23:00' },
    { id: 36, name: '11:30 PM', value: '23:30' },
    { id: 37, name: '12:00 AM', value: '24:00' },
  ];
  appointment: Object = {};
  data: Object = {};
  dates = [];
  days: any;  
  filler = {};
  details = {};

  constructor(
    private dashboard: DashboardService,
    private auth: AuthService,
    public dialog: MatDialog,
    private sidebarService: SidebarService
  ) {
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    if(localStorage.getItem('isSidebarMinimized')) {
      this.isSidebarMinimized = JSON.parse(localStorage.getItem('isSidebarMinimized'));
    }
    this.sidebarService.sidebarState.subscribe((nextValue) => {
      this.isSidebarMinimized = nextValue
    });
  }

  ngOnInit() {
    
  }

  ngOnChanges() {
    if(this.dentistId) {
      this.getDentistInfo();
      this.getWeeklyAppointments();
    } else if(this.newAppointment) {
      this.getWeeklyAppointments();
    }
  }

  getDentistInfo() {
    this.dashboard.getDentistInfo(this.dentistId, this.clinic).subscribe(res => {
      if(res) {
        this.data = res[0];
      }
    });
  }

  getWeeklyAppointments() {
    this.filler = {};
    this.dates = [];
    this.dashboard.viewWeeklyAppointments(this.clinic, this.dentistId, this.end, this.start).subscribe(res => {
      if(res) {
        this.success = true;
        this.appointments = res[0].patient_appointments;

        this.weekDaysFormat.forEach(date => {
          this.dates.push(date);
          this.appointment[date] = [];
          this.appointments.forEach(appointment => {
            if(appointment['appointment_date'] === date) {
              this.appointment[date].push(appointment);
            } 
          });
        });

        let stuff: number[] = [];
        let i = 0;

        for(let d of this.dates) {
          this.filler[d] = {};
          stuff = [];
          i = 0;
          for(let ap of this.appointment[d]) {
            this.filler[d][ap.id] = [];
            const start = moment(ap.appointment_start).format("HH:mm");
            const end = moment(ap.appointment_end).subtract(30, 'minutes').format("HH:mm");

            for(let time of this.timeRange) {
              i++;
              if(time.id !== 0) {
                if(time.value == start) {
                  stuff.push(time.id);
                } else if (time.value > start && time.value <= end) {
                  stuff.push(time.id);
                } 
              }

              if (this.timeRange.length == i) {
                this.filler[d][ap.id] = stuff;
                stuff = [];
                i = 0;
              }
            }
          }
        }

        // console.log(this.appointment);
        // console.log(this.dates);
        // console.log(this.filler);
        // console.log(this.data);

        let flag = false;
        for(let d of this.dates) {
          if(this.appointment[d].length > 0) {
            for(let item of this.appointment[d]) {
              const start = moment(item.appointment_start).format("HH:mm");
              for(let time of this.timeRange) {
                if(start.toString() == this.timeRange[time.id].value) {
                  flag = true;
                  this.details[item.id] = {};
                  this.details[item.id] = {
                    data: item,
                    id: item.id,
                    firstname: item.patient.first_name,
                    lastname: item.patient.last_name,
                    procedure: item.procedure
                  };
                  break;
                } else {
                  flag = false;
                }
              }
            }
          } 
        }
      }
    });
  }

  // View appointment on click
  viewAppointment(id, appointments, doctor, doctor_id) {
    const dialogConfig = new MatDialogConfig();
    if(id !== null) {
      dialogConfig.data = {
        appointment_id: id,
        doctor: doctor,
        doctor_id: doctor_id,
        patient: appointments.filter(_ => _.id == id)[0]
      };
      dialogConfig.disableClose = true;
      dialogConfig.backdropClass = 'mat-dialog-backdrop';
      let dialogRef = this.dialog.open(ViewAppointmentComponent, dialogConfig).afterClosed().subscribe(res => {
        if(res) {
          this.getWeeklyAppointments();
        }
      });
    }
  }

  // Check cell to be filled
  dataChecker(obj, date, time_id) {
    let flag = false;
    if(Object.keys(obj).length > 0) {
      for(let i of this.appointment[date]) {
        if(obj[i.id].includes(time_id)) {
          flag = true;
          break;
        } else {
          flag = false;
        }
      }
      return flag;
    } else {
      return false;
    }
  }

  // Check starting cell for appointment
  firstDataCheck(obj, time_id) {
    let flag = false;
    if(obj.length > 0) {
      for(let item of obj) {
        const start = moment(item.appointment_start).format("HH:mm");
        if(start.toString() == this.timeRange[time_id].value) {
          flag = true;
          break;
        } else {
          flag = false;
        }
      }
      return flag;
    } else {
      return false;
    }
  }

  // Check ending cell for appointment 
  lastDataCheck(obj, time_id) {
    let flag = false;
    if(obj.length > 0) {
      for(let item of obj) {
        const end = moment(item.appointment_end).subtract(30, 'minutes').format("HH:mm");
        if(end.toString() == this.timeRange[time_id].value) {
          flag = true;
          break;
        } else {
          flag = false;
        }
      }
      return flag;
    } else {
      return false;
    }
  }

  getAppointmentId(obj, time_id, appointments) {
    let flag = null;
    if(Object.keys(obj).length > 0) {
      for(let item of Object.keys(obj)) {
        const start = moment(appointments.filter(e => e.id == item)[0].appointment_start).format("HH:mm");
        if(Object.keys(obj).includes(item) && start.toString() == this.timeRange[time_id].value) {
          flag = item;
          break;
        }
      }
      return flag;
    } else {
      return null;
    }
  }

  getId(obj, time_id, appointments) {
    let flag = null;
    if(Object.keys(obj).length > 0) {
      for(let item of Object.keys(obj)) {
        const end = moment(appointments.filter(e => e.id == item)[0].appointment_end).subtract(30, 'minutes').format("HH:mm");
        const start = moment(appointments.filter(e => e.id == item)[0].appointment_start).format("HH:mm");
        if(Object.keys(obj).includes(item) && (this.timeRange[time_id].value <= end && this.timeRange[time_id].value >= start)) {
          flag = item;
          break;
        }
      }
      return flag;
    } else {
      return null;
    }
  }

  getDetails(id) {
    if(id) {
      const firstname = this.details[id].firstname;
      const lastname = this.details[id].lastname;
      const procedure = this.details[id].procedure;
      const start = moment(this.details[id].data.appointment_start).format("h:mm A");
      const end = moment(this.details[id].data.appointment_end).format("h:mm A");
      return firstname + ' ' + lastname + '\n' + procedure + ' ( ' + start + ' - ' + end + ' )';
    } else {
      return;
    }
  }
}
