import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import * as moment from 'moment';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { mapToothStatus } from 'src/app/common/helper';

@Component({
  selector: 'ark-tooth-condition',
  templateUrl: './tooth-condition.component.html',
  styleUrls: ['./tooth-condition.component.scss']
})
export class ToothConditionComponent implements OnInit {

  caries = [
    { id: 1, name: 'Occlusal', value: 'occlusal' },
    { id: 2, name: 'Mesial', value: 'mesial' },
    { id: 3, name: 'Distal', value: 'distal' },
    { id: 4, name: 'Buccal', value: 'buccal' },
    { id: 5, name: 'Lingual', value: 'lingual' }
  ]
  composite = [
    { id: 1, name: 'Occlusal', value: 'occlusal' },
    { id: 2, name: 'Mesial', value: 'mesial' },
    { id: 3, name: 'Distal', value: 'distal' },
    { id: 4, name: 'Buccal', value: 'buccal' },
    { id: 5, name: 'Lingual', value: 'lingual' }
  ]
  amalgam = [
    { id: 1, name: 'Occlusal', value: 'occlusal' },
    { id: 2, name: 'Mesial', value: 'mesial' },
    { id: 3, name: 'Distal', value: 'distal' },
    { id: 4, name: 'Buccal', value: 'buccal' },
    { id: 5, name: 'Lingual', value: 'lingual' }
  ]
  recurrentCaries = [
    { id: 1, name: 'Occlusal', value: 'occlusal' },
    { id: 2, name: 'Mesial', value: 'mesial' },
    { id: 3, name: 'Distal', value: 'distal' },
    { id: 4, name: 'Buccal', value: 'buccal' },
    { id: 5, name: 'Lingual', value: 'lingual' }
  ]
  inlayOnlay = [
    { id: 1, name: 'Occlusal', value: 'occlusal' },
    { id: 2, name: 'Mesial', value: 'mesial' },
    { id: 3, name: 'Distal', value: 'distal' },
    { id: 4, name: 'Buccal', value: 'buccal' },
    { id: 5, name: 'Lingual', value: 'lingual' }
  ]
  crown = [
    { id: 1, name: 'PFM', value: 'pfm' },
    { id: 2, name: 'Ceramic', value: 'ceramic' },
    { id: 3, name: 'Gold', value: 'gold' },
    { id: 4, name: 'Metal', value: 'metal' }
  ]
  inlay = [
    { id: 2, name: 'Ceramic', value: 'ceramic-inlay' },
    { id: 3, name: 'Gold', value: 'gold-inlay' },
    { id: 4, name: 'Metal', value: 'metal-inlay' }
  ]

  isData = {
    caries : {},
    composite : {},
    amalgam : {},
    recurrentCaries : {},
    inlayOnlay : {},
    inlay : '',
    crown : ''
  } 
  
  formStatus: FormGroup;
  dialogConfig = new MatDialogConfig();
  disableSelect = new FormControl(false);
  detail: any;
  checked: boolean = false;
  isDisabled: boolean = false;
  isCurrentDate: boolean = false;
  hasSurface: boolean = false;
  hasCrown: boolean = false;
  hasInlay: boolean = false;
  success = false;
  ok = false;
  missing = false;
  root_fragment = false;
  implant = false;
  impacted = false;
  cariesValue = [];
  compositeValue = [];
  amalgamValue = [];
  recurrentCariesValue = [];
  inlayOnlayValue = [];
  statusValue = '';
  checkedValue: any;
  patientId: any;
  chartData: any;
  chartId: any;
  toothData: any;
  toothStatus: any;
  toothQuadrant: any;
  today = new Date();
  chartDate = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ToothConditionComponent>, 
    private patientsDirectory: PatientsDirectoryService,
    private auth: AuthService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.detail = this.data.tooth.id;
    this.patientId = this.data.patient_id;
    this.chartId = this.data.chart_id;
    this.chartData = this.data.chart_data.quadrant[this.data.tooth.quadrant][this.detail];
    this.toothQuadrant = this.data.tooth.quadrant;
    this.chartDate = new Date(this.data.created_at);

    const chartDate = moment(this.chartDate).format("YYYY-MM-DD");
    const today = moment(this.today).format("YYYY-MM-DD");

    if(chartDate < today) {
      // Selected date is in the past
      this.isCurrentDate = false;
    } else {
      // Selected date is NOT in the past
      this.isCurrentDate = true;
    }

    if(this.chartData) {
      let test = this.chartData.surface.map((item, index) => {
        let key = Object.keys(item)[0];

        if(item[key].condition.includes('caries')) {
          this.isData.caries[key] = true;
        } else {
          this.isData.caries[key] = false;
        }

        if(item[key].condition.includes('composite')) {
          this.isData.composite[key] = true;
        } else {
          this.isData.composite[key] = false;
        }

        if(item[key].condition.includes('amalgam')) {
          this.isData.amalgam[key] = true;
        } else {
          this.isData.amalgam[key] = false;
        }

        if(item[key].condition.includes('recurrentCaries')) {
          this.isData.recurrentCaries[key] = true;
        } else {
          this.isData.recurrentCaries[key] = false;
        }

        if(item[key].condition.includes('inlayOnlay')) {
          this.isData.inlayOnlay[key] = true;
        } else {
          this.isData.inlayOnlay[key] = false;
        }

        if(item[key].inlay) {
          this.isData.inlay = item[key].inlay;
        }
      });
    }
    
    this.onSelect({value: this.isData.inlay});
    this.onCrownSelect({value: this.isData.crown});
    this.onChange(true, this.chartData.status);
    this.isData.crown = this.chartData.crown;
    this.formatData();

    if(this.isData.crown == undefined || this.isData.crown == '') {
      this.hasInlay = false;
    } else {
      this.hasInlay = true;
    } 
    
    if(this.isData.inlay == undefined || this.isData.inlay == '') {
      this.isDisabled = true;
      this.hasSurface = false;
      this.hasCrown = false;
    } else {
      this.isDisabled = false;
      this.hasSurface = true;
      this.hasCrown = true;
    }
  }

  onChange(e, source = null) {
    this.hasInlay = false;
    this.hasCrown = false;
    
    this.ok = false;
    this.missing = false;
    this.root_fragment = false;
    this.implant = false;
    this.impacted = false;

    switch(source) {
      case 'ok' : this.ok = e;
        this.statusValue = e ? 'ok' : '';
        this.clearConditions();
        break;
      case 'missing' : this.missing = e;
        this.statusValue = e ? 'missing' : '';
        this.clearConditions();
        break;
      case 'root_fragment' : this.root_fragment = e;
        this.statusValue = e ? 'root_fragment' : '';
        this.clearConditions();
        break;
      case 'implant' : this.implant = e;
        this.statusValue = e ? 'implant' : '';
        this.clearConditions();
        break;
      case 'impacted' : this.impacted = e;
        this.statusValue = e ? 'impacted' : '';
        this.clearConditions();
        break;
      default: 
        this.ok = false;
        this.missing = false;
        this.root_fragment = false;
        this.implant = false;
        this.impacted = false;
        break;
    }
    this.formatData();
  }

  onSelect(data) {
    if(data.value == undefined || data.value == '') {
      this.isDisabled = true;
      this.hasSurface = true;
      this.hasCrown = false;
    } else {
      this.isDisabled = false;
      this.hasSurface = false;
      this.hasCrown = true;
    }
    this.formatData();
  }

  onCrownSelect(data){
    this.clearStatus();
    if(data.value == undefined || data.value == '') {
      this.hasInlay = false;
    } else {
      this.hasInlay = true;
    }
    this.formatData();
  }

  onCheck(e, type) {
    this.checkedValue = e;
    if(e.checked) {
      this.checkedValue = e;
      this.hasSurface = true;
      this.clearStatus();
      if(type == 'caries') {
        this.cariesValue.push(this.checkedValue.source.name);
        this.isCaries();
      } else if(type == 'composite') {
        this.compositeValue.push(this.checkedValue.source.name);
        this.isComposite();
      } else if(type == 'amalgam') {
        this.amalgamValue.push(this.checkedValue.source.name);
        this.isAmalgam();
      } else if(type == 'recurrentCaries') {
        this.recurrentCariesValue.push(this.checkedValue.source.name);
        this.isRecurrentCaries();
      } else if(type == 'inlayOnlay') {
        this.inlayOnlayValue.push(this.checkedValue.source.name);
        this.isInlayOnlay();
      }
    } else {
      this.hasSurface = false;
      if(type == 'caries') {
        this.cariesValue = this.cariesValue.filter(item=> item != this.checkedValue.source.name);
      } else if(type == 'composite') {
        this.compositeValue = this.compositeValue.filter(item=> item != this.checkedValue.source.name);
      } else if(type == 'amalgam') {
        this.amalgamValue = this.amalgamValue.filter(item=> item != this.checkedValue.source.name);
      } else if(type == 'recurrentCaries') {
        this.recurrentCariesValue = this.recurrentCariesValue.filter(item=> item != this.checkedValue.source.name);
      } else if(type == 'inlayOnlay') {
        this.inlayOnlayValue = this.inlayOnlayValue.filter(item=> item != this.checkedValue.source.name);
      }
    }
    this.formatData();
  }

  formatData() {
    let occlusal = [];
    let mesial = [];
    let distal = [];
    let buccal = [];
    let lingual = [];
    let recurrentCaries = [];

    for (const key in this.isData) {
      if (this.isData.hasOwnProperty(key)) {
        const element = this.isData[key];
        for (const keys in element) {
          if (element.hasOwnProperty(keys)) {
            const elements = element[keys];
            if(keys == 'occlusal') {
              if(elements) {
                occlusal.push(key);
              }
            } else if(keys == 'mesial') {
              if(elements) {
                mesial.push(key);
              }
            } else if(keys == 'distal') {
              if(elements) {
                distal.push(key);
              }
            } else if(keys == 'recurrentCaries') {
              if(elements) {
                recurrentCaries.push(key);
              }
            } else if(keys == 'buccal') {
              if(elements) {
                buccal.push(key);
              }
            } else if(keys == 'lingual') {
              if(elements) {
                lingual.push(key);
              }
            } 
          }
        }
      }
    }

    let data = {
      quadrant: {
        [this.data.tooth.quadrant]: {
          [this.detail]: {
            status: this.statusValue || this.isData.crown || this.isData.inlay,
            crown: this.isData.crown ? this.isData.crown : '',
            surface: [
              {
                occlusal: {
                  inlay: this.isData.inlayOnlay['occlusal'] ? this.isData.inlay : '',
                  condition: occlusal
                }
              },
              {
                mesial: {
                  inlay: this.isData.inlayOnlay['mesial'] ? this.isData.inlay : '',
                  condition: mesial
                }
              },
              {
                distal: {
                  inlay: this.isData.inlayOnlay['distal'] ? this.isData.inlay : '',
                  condition: distal
                }
              },
              {
                buccal: {
                  inlay: this.isData.inlayOnlay['buccal'] ? this.isData.inlay : '',
                  condition: buccal
                }
              },
              {
                lingual: {
                  inlay: this.isData.inlayOnlay['lingual'] ? this.isData.inlay : '',
                  condition: lingual
                }
              }
            ],
            bridge: {
              end: this.chartData.bridge.end,
              middle: this.chartData.bridge.middle,
              start: this.chartData.bridge.start
            }
          }
        }
      }
    }
    
    this.toothStatus = mapToothStatus(data.quadrant[this.data.tooth.quadrant][this.detail].status);
    this.toothData = data.quadrant[this.data.tooth.quadrant][this.detail];
    return data;
  }

  saveToothChart() {
    let data = JSON.stringify(this.formatData());
    let toothData = {
      patient_id: this.patientId,
      user_clinic_id: this.auth.getAuthUser().user_clinic['id'],
      chart: data,
      toothchart_id: this.chartId
    }
    if(this.hasCrown) {
      if(!this.hasSurface) {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'Please select a surface for inlay/onlay condition.',
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      } else {
        this.patientsDirectory.storeChart(toothData).subscribe(res => {
          if(res) {
            this.success = true;
            this.dialogRef.close(res);
          }
        },(err => {
          this.dialogConfig.data = {
            title: 'Oops!',
            message: err.error.message,
            button: 'Okay',
            event: this.closeOnClick
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }));
      }
    } else {
      this.patientsDirectory.storeChart(toothData).subscribe(res => {
        if(res) {
          this.success = true;
          this.dialogRef.close(res);
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: err.error.message,
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }));
    }
  }

  clearToothChart() {
    this.clearStatus();
    this.clearConditions();
    this.toothData = '';
    this.toothStatus = '';
    this.hasCrown = false;
    this.hasInlay = false;
  }

  clearStatus() {
    this.statusValue = '';
    this.ok = false;
    this.missing = false;
    this.root_fragment = false;
    this.implant = false;
    this.impacted = false;
  }

  clearConditions() {
    this.checkedValue = '';
    this.cariesValue = [];
    this.compositeValue = [];
    this.amalgamValue = [];
    this.recurrentCariesValue = [];
    this.inlayOnlayValue = [];
    this.isData = {
      caries : {},
      composite : {},
      amalgam : {},
      recurrentCaries : {},
      inlayOnlay : {},
      inlay : '',
      crown : ''
    } 
  }

  closeOnClick() {
    this.dialogRef.close(false);
  }

  isCaries() {
    if(this.checkedValue.source.name == 'occlusal') {
      this.isData.caries['occlusal'] = true;
      this.isData.recurrentCaries['occlusal'] = false;
      this.isData.composite['occlusal'] = false;
      this.isData.amalgam['occlusal'] = false;
      this.isData.inlayOnlay['occlusal'] = false;
    } else if(this.checkedValue.source.name == 'mesial') {
      this.isData.caries['mesial'] = true;
      this.isData.recurrentCaries['mesial'] = false;
      this.isData.composite['mesial'] = false;
      this.isData.amalgam['mesial'] = false;
      this.isData.inlayOnlay['mesial'] = false;
    } else if(this.checkedValue.source.name == 'distal') {
      this.isData.caries['distal'] = true;
      this.isData.recurrentCaries['distal'] = false;
      this.isData.composite['distal'] = false;
      this.isData.amalgam['distal'] = false;
      this.isData.inlayOnlay['distal'] = false;
    } else if(this.checkedValue.source.name == 'buccal') {
      this.isData.caries['buccal'] = true;
      this.isData.recurrentCaries['buccal'] = false;
      this.isData.composite['buccal'] = false;
      this.isData.amalgam['buccal'] = false;
      this.isData.inlayOnlay['buccal'] = false;
    } else if(this.checkedValue.source.name == 'lingual') {
      this.isData.caries['lingual'] = true;
      this.isData.recurrentCaries['lingual'] = false;
      this.isData.composite['lingual'] = false;
      this.isData.amalgam['lingual'] = false;
      this.isData.inlayOnlay['lingual'] = false;
    } 
  }

  isComposite() {
    if(this.checkedValue.source.name == 'occlusal') {
      this.isData.caries['occlusal'] = false;
      this.isData.composite['occlusal'] = true;
      this.isData.amalgam['occlusal'] = false;
      this.isData.inlayOnlay['occlusal'] = false;
    } else if(this.checkedValue.source.name == 'mesial') {
      this.isData.caries['mesial'] = false;
      this.isData.composite['mesial'] = true;
      this.isData.amalgam['mesial'] = false;
      this.isData.inlayOnlay['mesial'] = false;
    } else if(this.checkedValue.source.name == 'distal') {
      this.isData.caries['distal'] = false;
      this.isData.composite['distal'] = true;
      this.isData.amalgam['distal'] = false;
      this.isData.inlayOnlay['distal'] = false;
    } else if(this.checkedValue.source.name == 'buccal') {
      this.isData.caries['buccal'] = false;
      this.isData.composite['buccal'] = true;
      this.isData.amalgam['buccal'] = false;
      this.isData.inlayOnlay['buccal'] = false;
    } else if(this.checkedValue.source.name == 'lingual') {
      this.isData.caries['lingual'] = false;
      this.isData.composite['lingual'] = true;
      this.isData.amalgam['lingual'] = false;
      this.isData.inlayOnlay['lingual'] = false;
    }
  }
  
  isAmalgam() {
    if(this.checkedValue.source.name == 'occlusal') {
      this.isData.caries['occlusal'] = false;
      this.isData.composite['occlusal'] = false;
      this.isData.amalgam['occlusal'] = true;
      this.isData.inlayOnlay['occlusal'] = false;
    } else if(this.checkedValue.source.name == 'mesial') {
      this.isData.caries['occmesiallusal'] = false;
      this.isData.composite['mesial'] = false;
      this.isData.amalgam['mesial'] = true;
      this.isData.inlayOnlay['mesial'] = false;
    } else if(this.checkedValue.source.name == 'distal') {
      this.isData.caries['distal'] = false;
      this.isData.composite['distal'] = false;
      this.isData.amalgam['distal'] = true;
      this.isData.inlayOnlay['distal'] = false;
    } else if(this.checkedValue.source.name == 'buccal') {
      this.isData.caries['buccal'] = false;
      this.isData.composite['buccal'] = false;
      this.isData.amalgam['buccal'] = true;
      this.isData.inlayOnlay['buccal'] = false;
    } else if(this.checkedValue.source.name == 'lingual') {
      this.isData.caries['lingual'] = false;
      this.isData.composite['lingual'] = false;
      this.isData.amalgam['lingual'] = true;
      this.isData.inlayOnlay['lingual'] = false;
    }
  }

  isRecurrentCaries() {
    if(this.checkedValue.source.name == 'occlusal') {
      this.isData.caries['occlusal'] = false;
      this.isData.recurrentCaries['occlusal'] = true;
    } else if(this.checkedValue.source.name == 'mesial') {
      this.isData.caries['mesial'] = false;
      this.isData.recurrentCaries['mesial'] = true;
    } else if(this.checkedValue.source.name == 'distal') {
      this.isData.caries['distal'] = false;
      this.isData.recurrentCaries['distal'] = true;
    } else if(this.checkedValue.source.name == 'buccal') {
      this.isData.caries['buccal'] = false;
      this.isData.recurrentCaries['buccal'] = true;
    } else if(this.checkedValue.source.name == 'lingual') {
      this.isData.caries['lingual'] = false;
      this.isData.recurrentCaries['lingual'] = true;
    } 
  }

  isInlayOnlay() {
    if(this.checkedValue.source.name == 'occlusal') {
      this.isData.caries['occlusal'] = false;
      this.isData.composite['occlusal'] = false;
      this.isData.amalgam['occlusal'] = false;
      this.isData.inlayOnlay['occlusal'] = true;
    } else if(this.checkedValue.source.name == 'mesial') {
      this.isData.caries['mesial'] = false;
      this.isData.composite['mesial'] = false;
      this.isData.amalgam['mesial'] = false;
      this.isData.inlayOnlay['mesial'] = true;
    } else if(this.checkedValue.source.name == 'distal') {
      this.isData.caries['distal'] = false;
      this.isData.composite['distal'] = false;
      this.isData.amalgam['distal'] = false;
      this.isData.inlayOnlay['distal'] = true;
    } else if(this.checkedValue.source.name == 'buccal') {
      this.isData.caries['buccal'] = false;
      this.isData.composite['buccal'] = false;
      this.isData.amalgam['buccal'] = false;
      this.isData.inlayOnlay['buccal'] = true;
    } else if(this.checkedValue.source.name == 'lingual') {
      this.isData.caries['lingual'] = false;
      this.isData.composite['lingual'] = false;
      this.isData.amalgam['lingual'] = false;
      this.isData.inlayOnlay['lingual'] = true;
    }
  }
}
