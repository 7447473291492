import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeesComponent } from './components/employees/employees.component';
import { DentalFormsComponent } from './components/dental-forms/dental-forms.component';
import { SharedModule } from '../shared/shared.module';
import { FinanceComponent } from './components/finance/finance.component';
import { IncomeStatementSummaryComponent } from './components/finance/income-statement-summary/income-statement-summary.component';
import { FinanceGraphComponent } from './components/finance/finance-graph/finance-graph.component';
import { MatFormFieldModule, MatIconModule, MatDatepickerModule, MatNativeDateModule, MatSelectModule, MatInputModule, MatAutocompleteModule, MatCheckboxModule } from '@angular/material';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FinanceService } from './services/finance/finance.service';
import { IncomeComponent } from './components/finance/finance-graph/income/income.component';
import { ExpenseComponent } from './components/finance/finance-graph/expense/expense.component';
import { IncomeVsExpenseComponent } from './components/finance/finance-graph/income-vs-expense/income-vs-expense.component';
import { PayrollComponent } from './components/employees/payroll/payroll.component';
import { DailyTimeRecordComponent, TimePipe } from './components/employees/daily-time-record/daily-time-record.component';
import { EmployeesService } from './services/employees/employees.service';
import { MonthlyIncomeStatementComponent } from './components/finance/monthly-income-statement/monthly-income-statement.component';
import { PayrollSettingsComponent } from './components/employees/payroll/payroll-settings/payroll-settings.component';
import { DtrSettingsComponent } from './components/employees/daily-time-record/dtr-settings/dtr-settings.component';
import { RxComponent } from './components/dental-forms/rx/rx.component';
import { ReferralComponent } from './components/dental-forms/referral/referral.component';
import { CertificatesComponent } from './components/dental-forms/certificates/certificates.component';
import { WorkAuthorizationComponent } from './components/dental-forms/work-authorization/work-authorization.component';
import { ConsentComponent } from './components/dental-forms/consent/consent.component';
import { PedoComponent } from './components/dental-forms/pedo/pedo.component';
import { PreFilledPrescriptionsComponent } from './components/dental-forms/rx/pre-filled-prescriptions/pre-filled-prescriptions.component';
import { DentalFormsService } from './services/dental-forms/dental-forms.service';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PostOperativeInstructionComponent } from './components/dental-forms/post-operative-instruction/post-operative-instruction.component';
import { EmailToPatientComponent } from './components/dental-forms/post-operative-instruction/email-to-patient/email-to-patient.component';
import { SignaturePadModule } from '@ng-plus/signature-pad';
import { CrownAuthorizationComponent } from './components/dental-forms/work-authorization/crown-authorization/crown-authorization.component';
import { PartialDentureAuthorizationComponent } from './components/dental-forms/work-authorization/partial-denture-authorization/partial-denture-authorization.component';
import { FullDentureAuthorizationComponent } from './components/dental-forms/work-authorization/full-denture-authorization/full-denture-authorization.component';
import { OrthodonticsAuthorizationComponent } from './components/dental-forms/work-authorization/orthodontics-authorization/orthodontics-authorization.component';
import { AddToothNumberComponent } from './components/dental-forms/work-authorization/crown-authorization/add-tooth-number/add-tooth-number.component';
import { ToothDetailsComponent } from './components/dental-forms/work-authorization/crown-authorization/tooth-details/tooth-details.component';
import { ESignatureComponent } from '../shared/components/e-signature/e-signature.component';
import { CovidFormComponent } from './components/dental-forms/covid-form/covid-form.component';
import { EmailFormComponent } from './components/dental-forms/covid-form/email-form/email-form.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  // suppressScrollX: true
}
@NgModule({
  declarations: [
    EmployeesComponent, 
    DentalFormsComponent,
    FinanceComponent,
    IncomeStatementSummaryComponent,
    FinanceGraphComponent,
    IncomeComponent,
    ExpenseComponent,
    IncomeVsExpenseComponent,
    PayrollComponent,
    DailyTimeRecordComponent,
    MonthlyIncomeStatementComponent,
    TimePipe,
    PayrollSettingsComponent,
    DtrSettingsComponent,
    RxComponent,
    ReferralComponent,
    CertificatesComponent,
    WorkAuthorizationComponent,
    ConsentComponent,
    PedoComponent,
    PreFilledPrescriptionsComponent,
    PostOperativeInstructionComponent,
    EmailToPatientComponent,
    CrownAuthorizationComponent,
    PartialDentureAuthorizationComponent,
    FullDentureAuthorizationComponent,
    OrthodonticsAuthorizationComponent,
    AddToothNumberComponent,
    ToothDetailsComponent,
    CovidFormComponent,
    EmailFormComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    FormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    PerfectScrollbarModule,
    SignaturePadModule
  ],
  exports: [
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    FormsModule,
    MatCheckboxModule
  ],
  providers: [
    FinanceService,
    EmployeesService,
    DentalFormsService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  entryComponents: [
    MonthlyIncomeStatementComponent,
    PayrollSettingsComponent,
    PreFilledPrescriptionsComponent,
    EmailToPatientComponent,
    AddToothNumberComponent,
    ToothDetailsComponent,
    ESignatureComponent,
    EmailFormComponent
  ]
})
export class DentalOfficeModule { }
