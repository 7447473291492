import { Component, OnInit, Inject } from '@angular/core';
import { keypressValidateNumber, currencyFormat } from 'src/app/common/helper';
import { MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { EmployeesService } from 'src/app/modules/dental-office/services/employees/employees.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

export class AddOns {
  id: number;
  name: string;
  amount: string;
  division_of_time: string;
}

export class Deductions {
  id: number;
  name: string;
  amount: string;
  division_of_time: string;
}

@Component({
  selector: 'ark-payroll-settings',
  templateUrl: './payroll-settings.component.html',
  styleUrls: ['./payroll-settings.component.scss']
})
export class PayrollSettingsComponent implements OnInit {

  errorMessage = '';
  clinic = null;
  payrollSettings = null;
  settingId = '';
  hasSettings: boolean = false;
  dialogConfig = new MatDialogConfig();
  types = [
    { id: 1, name: 'none', value: 'none' },
    { id: 2, name: 'minute', value: 'minute' },
    { id: 2, name: 'hour', value: 'hour' },
    { id: 3, name: 'day', value: 'day' }
  ];
  settings: FormGroup;
  id: FormControl;
  name: FormControl;
  amount: FormControl;
  division_of_time: FormControl;
  addons: AddOns = new AddOns();
  deduct: Deductions = new Deductions();

  arrayAddOns = null;
  arrayDeductions = null;

  listAddOns: AddOns[] = [
    { id: 1, name: 'Over Time (per hour)', amount: '0', division_of_time: null },
    { id: 2, name: 'Holiday (SR x 30%)', amount: '0', division_of_time: null },
    { id: 3, name: 'Holiday (SR x 100%)', amount: '0', division_of_time: null },
    { id: 4, name: 'Meal Allowance', amount: '0', division_of_time: 'day' },
    { id: 5, name: 'Enter Add On', amount: '0', division_of_time: 'day' },
    { id: 6, name: 'Enter Add On', amount: '0', division_of_time: 'day' },
  ];
  listDeductions: Deductions[] = [
    { id: 1, name: 'Absent (per day)', amount: '0', division_of_time: null },
    { id: 2, name: 'Late (per hour)', amount: '0', division_of_time: null },
    { id: 3, name: 'Undertime (per hour)', amount: '0', division_of_time: null },
    { id: 4, name: 'SSS', amount: '0', division_of_time: null },
    { id: 5, name: 'Philhealth', amount: '0', division_of_time: null },
    { id: 6, name: 'Pag-ibig', amount: '0', division_of_time: null },
    { id: 7, name: 'Enter Deduction', amount: '0', division_of_time: 'day' },
    { id: 8, name: 'Enter Deduction', amount: '0', division_of_time: 'day' },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PayrollSettingsComponent>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private payroll: EmployeesService
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.payrollSettings = this.data.data;
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.settings = this.formBuilder.group({
      salary_rate: ['0'],
      add_ons: this.formBuilder.array([]),
      deduction: this.formBuilder.array([])
    });
    this.arrayAddOns = this.settings.get('add_ons') as FormArray;
    this.arrayDeductions = this.settings.get('deduction') as FormArray;
    this.initAddOns(this.listAddOns);
    this.initDeductions(this.listDeductions);
  }

  ngOnInit() {
    if(this.payrollSettings.length > 0) {
      this.hasSettings = true;
      this.settings.get('salary_rate').setValue(this.payrollSettings[0].salary_rate);
      this.settings.get('add_ons').patchValue(this.payrollSettings[0].add_ons);
      this.settings.get('deduction').patchValue(this.payrollSettings[0].deductions);
    } else {
      this.hasSettings = false;
    }
  }

  createAddOns(addons): FormGroup {
    let formGroup: FormGroup = new FormGroup(
      {
        id: new FormControl(addons.id),
        name: new FormControl(addons.name),
        amount: new FormControl(addons.amount),
        division_of_time: new FormControl(addons.division_of_time)
      }
    );
    return formGroup;
  }

  createDeductions(deduct): FormGroup {
    let formGroup: FormGroup = new FormGroup(
      {
        id: new FormControl(deduct.id),
        name: new FormControl(deduct.name),
        amount: new FormControl(deduct.amount),
        division_of_time: new FormControl(deduct.division_of_time)
      }
    );
    return formGroup;
  }

  initAddOns(addons: AddOns[]) {
    const formArray = this.settings.get('add_ons') as FormArray;
    addons.map(item => {
      formArray.push(this.createAddOns(item));
    });
    this.settings.setControl('add_ons', formArray);
  }

  initDeductions(deduct: Deductions[]) {
    const formArray = this.settings.get('deduction') as FormArray;
    deduct.map(item => {
      formArray.push(this.createDeductions(item));
    });
    this.settings.setControl('deduction', formArray);
  }

  get add_ons() {
    return this.settings.get('add_ons') as FormArray;
  }

  get deduction() {
    return this.settings.get('deduction') as FormArray;
  }

  close() {
    this.dialogRef.close();
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }

  savePayrollSettings() {
    const data = {
      user_clinic_id: this.clinic,
      employee_id: +this.data.id,
      salary_rate: this.settings.value.salary_rate,
      add_ons: this.settings.value.add_ons,
      deduction: this.settings.value.deduction
    }
    this.payroll.savePayrollSettings(data).subscribe(res => {
      if(res) {
        this.dialogRef.close(res);
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Payroll settings has been successfully saved.',
          button: 'Okay',
          event: this.close
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Sorry!',
        message: err.error.message,
        button: 'Okay',
        event: this.close
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }));
  }

  updatePayrollSettings() {
    const data = {
      user_clinic_id: this.clinic,
      employee_id: +this.data.id,
      id: this.payrollSettings[0].id,
      salary_rate: this.settings.value.salary_rate,
      add_ons: this.settings.value.add_ons ? this.settings.value.add_ons : this.payrollSettings[0].add_ons,
      deduction: this.settings.value.deduction ? this.settings.value.deduction : this.payrollSettings[0].deductions
    }
    this.payroll.savePayrollSettings(data).subscribe(res => {
      if(res) {
        this.dialogRef.close(res);
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Payroll settings has been successfully updated.',
          button: 'Okay',
          event: this.close
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Sorry!',
        message: err.error.message,
        button: 'Okay',
        event: this.close
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }));
  }

  // diasableHolidays() {
  //   const x30 = (<FormArray>this.settings.controls['add_ons']).at(1);
  //   const x100 = (<FormArray>this.settings.controls['add_ons']).at(2);
  //   x30['controls'].amount.disable();
  //   x100['controls'].amount.disable();
  // }

  computeHolidays() {
    const salary = this.settings.value.salary_rate;
    const holiday1 = Number(salary) * .30;
    const holiday2 = Number(salary) * 1;
    const x30 = (<FormArray>this.settings.controls['add_ons']).at(1);
    const x100 = (<FormArray>this.settings.controls['add_ons']).at(2);
    x30['controls'].amount.setValue(holiday1);
    x100['controls'].amount.setValue(holiday2);
  }

  currencyFormat(e) {
    return currencyFormat(e);
  }
}
