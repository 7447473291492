import {
  Component,
  OnInit,
  OnChanges,
  Input,
  SimpleChanges,
} from "@angular/core";
import { fabric } from "fabric";

@Component({
  selector: "ark-lower-lingual",
  templateUrl: "./lower-lingual.component.html",
  styleUrls: ["./lower-lingual.component.scss"],
})
export class LowerLingualComponent implements OnInit, OnChanges {
  _canvas?: fabric.Canvas;

  @Input() perioData: [];
  @Input() perioDetails: [];

  //constants
  guideGap = 6;
  startingYAxis = 64;

  basePtY = 64;

  toothGap = 70;
  ptGap = 3;
  dividerGap = 25;
  toothWidth = 50;
  ptXArray = [];
  mainPtArray = [];

  ptX = 0;
  ptY = 64;

  allBluePts = [];
  allRedPts = [];
  allBlueLines = [];
  allRedLines = [];

  constructor() {}

  ngOnInit() {
    this._canvas = new fabric.Canvas("lower_lingual_canvas");

    //start with number of teeth
    for (let tooth = 0; tooth < 16; tooth++) {
      this.ptXArray.push(this.ptX);

      //follow with tooth dividers
      for (let divider = 0; divider < 2; divider++) {
        this.ptY = this.basePtY + this.ptGap;

        let ptArray = [];

        this.ptX = this.ptX + this.dividerGap;

        this.ptXArray.push(this.ptX);
      }

      this.ptX = this.ptX + this.toothGap;
    }

    let dividersArray = [];
    let toothCounter = 0;

    //createPtY
    for (let ptXIdx = 0; ptXIdx < this.ptXArray.length; ptXIdx++) {
      toothCounter++;

      this.ptY = this.basePtY + this.ptGap;

      let ptsArray = [];

      for (let ptYIdx = 0; ptYIdx < 17; ptYIdx++) {
        this.ptY = this.ptY - this.ptGap;

        ptsArray.push({ x: this.ptXArray[ptXIdx], y: this.ptY });
      }

      dividersArray.push(ptsArray);

      if (toothCounter === 3) {
        this.mainPtArray.push(dividersArray);
        dividersArray = [];
        toothCounter = 0;
      }
    }

    for (let data of this.perioData as Array<any>) {
      if (data.name === "Probing Depth Initial") {
        for (let toothData of data.data) {
          this.allBluePts.push(parseInt(toothData.value1));
          this.allBluePts.push(parseInt(toothData.value2));
          this.allBluePts.push(parseInt(toothData.value3));
        }
      }
      if (data.name === "Recession Initial") {
        for (let toothData of data.data) {
          this.allRedPts.push(0);
          this.allRedPts.push(parseInt(toothData.value));
          this.allRedPts.push(0);
        }
      }
    }

    this.initBlueLines();
    this.initRedLines();

    this.drawGuidlines();
    this.drawTeeth();

    this._canvas.renderAll();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.perioData && !changes.perioData.firstChange) {
      this.redrawLines();
    }
  }

  redrawLines = () => {
    console.log("should redraw");
    this.allBluePts = [];
    this.allRedPts = [];
    //update points
    for (let data of this.perioData as Array<any>) {
      if (data.name === "Probing Depth Initial") {
        for (let toothData of data.data) {
          this.allBluePts.push(parseInt(toothData.value1));
          this.allBluePts.push(parseInt(toothData.value2));
          this.allBluePts.push(parseInt(toothData.value3));
        }
      }
      if (data.name === "Recession Initial") {
        for (let toothData of data.data) {
          this.allRedPts.push(0);
          this.allRedPts.push(parseInt(toothData.value));
          this.allRedPts.push(0);
        }
      }
    }

    //remove all lines
    for (let i = 0; i < this.allBlueLines.length; i++) {
      this._canvas.remove(this.allBlueLines[i]);
    }

    for (let i = 0; i < this.allRedLines.length; i++) {
      this._canvas.remove(this.allRedLines[i]);
    }

    this.allBlueLines = [];
    this.allRedLines = [];

    this.initBlueLines();
    this.initRedLines();

    this.drawRedLines();
    this.drawBlueLines();
  };

  drawGuidlines = () => {
    //Add Lower Lingual Guidelines
    for (let i = 0; i < 9; i++) {
      let yAxis = this.startingYAxis - this.guideGap * i;

      let guideline = new fabric.Line([0, yAxis, 1870, yAxis], {
        stroke: "#E7E8EA",
        strokeWidth: 1,
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        hasControls: false,
        hasBorders: false,
      });

      this._canvas.add(guideline);
    }
  };

  drawTeeth = () => {
    const thisComponent = this;
    const thisCanvas = this._canvas;

    const gap = 120;

    fabric.loadSVGFromURL(
      "assets/image/perio/38l.svg",
      function (objects, options) {
        let obj = fabric.util.groupSVGElements(objects, options);
        obj.lockMovementX = true;
        obj.lockMovementY = true;
        obj.lockScalingX = true;
        obj.lockScalingY = true;
        obj.lockRotation = true;
        obj.hasControls = false;
        obj.hasBorders = false;

        thisCanvas.add(obj.set({ left: gap * 0, top: 0 }));

        fabric.loadSVGFromURL(
          "assets/image/perio/37l.svg",
          function (objects, options) {
            let obj = fabric.util.groupSVGElements(objects, options);
            obj.lockMovementX = true;
            obj.lockMovementY = true;
            obj.lockScalingX = true;
            obj.lockScalingY = true;
            obj.lockRotation = true;
            obj.hasControls = false;
            obj.hasBorders = false;
            thisCanvas.add(obj.set({ left: gap * 1, top: 0 }));

            fabric.loadSVGFromURL(
              "assets/image/perio/36l.svg",
              function (objects, options) {
                let obj = fabric.util.groupSVGElements(objects, options);
                obj.lockMovementX = true;
                obj.lockMovementY = true;
                obj.lockScalingX = true;
                obj.lockScalingY = true;
                obj.lockRotation = true;
                obj.hasControls = false;
                obj.hasBorders = false;
                thisCanvas.add(obj.set({ left: gap * 2, top: 0 }));

                fabric.loadSVGFromURL(
                  "assets/image/perio/35l.svg",
                  function (objects, options) {
                    let obj = fabric.util.groupSVGElements(objects, options);
                    obj.lockMovementX = true;
                    obj.lockMovementY = true;
                    obj.lockScalingX = true;
                    obj.lockScalingY = true;
                    obj.lockRotation = true;
                    obj.hasControls = false;
                    obj.hasBorders = false;
                    thisCanvas.add(obj.set({ left: gap * 3, top: 0 }));

                    fabric.loadSVGFromURL(
                      "assets/image/perio/34l.svg",
                      function (objects, options) {
                        let obj = fabric.util.groupSVGElements(
                          objects,
                          options
                        );
                        obj.lockMovementX = true;
                        obj.lockMovementY = true;
                        obj.lockScalingX = true;
                        obj.lockScalingY = true;
                        obj.lockRotation = true;
                        obj.hasControls = false;
                        obj.hasBorders = false;
                        thisCanvas.add(obj.set({ left: gap * 4, top: 0 }));

                        fabric.loadSVGFromURL(
                          "assets/image/perio/33l.svg",
                          function (objects, options) {
                            let obj = fabric.util.groupSVGElements(
                              objects,
                              options
                            );
                            obj.lockMovementX = true;
                            obj.lockMovementY = true;
                            obj.lockScalingX = true;
                            obj.lockScalingY = true;
                            obj.lockRotation = true;
                            obj.hasControls = false;
                            obj.hasBorders = false;
                            thisCanvas.add(obj.set({ left: gap * 5, top: 0 }));

                            fabric.loadSVGFromURL(
                              "assets/image/perio/32l.svg",
                              function (objects, options) {
                                let obj = fabric.util.groupSVGElements(
                                  objects,
                                  options
                                );
                                obj.lockMovementX = true;
                                obj.lockMovementY = true;
                                obj.lockScalingX = true;
                                obj.lockScalingY = true;
                                obj.lockRotation = true;
                                obj.hasControls = false;
                                obj.hasBorders = false;
                                thisCanvas.add(
                                  obj.set({ left: gap * 6, top: 0 })
                                );

                                fabric.loadSVGFromURL(
                                  "assets/image/perio/31l.svg",
                                  function (objects, options) {
                                    let obj = fabric.util.groupSVGElements(
                                      objects,
                                      options
                                    );
                                    obj.lockMovementX = true;
                                    obj.lockMovementY = true;
                                    obj.lockScalingX = true;
                                    obj.lockScalingY = true;
                                    obj.lockRotation = true;
                                    obj.hasControls = false;
                                    obj.hasBorders = false;
                                    thisCanvas.add(
                                      obj.set({ left: gap * 7, top: 0 })
                                    );

                                    fabric.loadSVGFromURL(
                                      "assets/image/perio/41l.svg",
                                      function (objects, options) {
                                        let obj = fabric.util.groupSVGElements(
                                          objects,
                                          options
                                        );
                                        obj.lockMovementX = true;
                                        obj.lockMovementY = true;
                                        obj.lockScalingX = true;
                                        obj.lockScalingY = true;
                                        obj.lockRotation = true;
                                        obj.hasControls = false;
                                        obj.hasBorders = false;
                                        thisCanvas.add(
                                          obj.set({ left: gap * 8, top: 0 })
                                        );

                                        fabric.loadSVGFromURL(
                                          "assets/image/perio/42l.svg",
                                          function (objects, options) {
                                            let obj =
                                              fabric.util.groupSVGElements(
                                                objects,
                                                options
                                              );
                                            obj.lockMovementX = true;
                                            obj.lockMovementY = true;
                                            obj.lockScalingX = true;
                                            obj.lockScalingY = true;
                                            obj.lockRotation = true;
                                            obj.hasControls = false;
                                            obj.hasBorders = false;
                                            thisCanvas.add(
                                              obj.set({ left: gap * 9, top: 0 })
                                            );

                                            fabric.loadSVGFromURL(
                                              "assets/image/perio/43l.svg",
                                              function (objects, options) {
                                                let obj =
                                                  fabric.util.groupSVGElements(
                                                    objects,
                                                    options
                                                  );
                                                obj.lockMovementX = true;
                                                obj.lockMovementY = true;
                                                obj.lockScalingX = true;
                                                obj.lockScalingY = true;
                                                obj.lockRotation = true;
                                                obj.hasControls = false;
                                                obj.hasBorders = false;
                                                thisCanvas.add(
                                                  obj.set({
                                                    left: gap * 10,
                                                    top: 0,
                                                  })
                                                );

                                                fabric.loadSVGFromURL(
                                                  "assets/image/perio/44l.svg",
                                                  function (objects, options) {
                                                    let obj =
                                                      fabric.util.groupSVGElements(
                                                        objects,
                                                        options
                                                      );
                                                    obj.lockMovementX = true;
                                                    obj.lockMovementY = true;
                                                    obj.lockScalingX = true;
                                                    obj.lockScalingY = true;
                                                    obj.lockRotation = true;
                                                    obj.hasControls = false;
                                                    obj.hasBorders = false;
                                                    thisCanvas.add(
                                                      obj.set({
                                                        left: gap * 11,
                                                        top: 0,
                                                      })
                                                    );

                                                    fabric.loadSVGFromURL(
                                                      "assets/image/perio/45l.svg",
                                                      function (
                                                        objects,
                                                        options
                                                      ) {
                                                        let obj =
                                                          fabric.util.groupSVGElements(
                                                            objects,
                                                            options
                                                          );
                                                        obj.lockMovementX =
                                                          true;
                                                        obj.lockMovementY =
                                                          true;
                                                        obj.lockScalingX = true;
                                                        obj.lockScalingY = true;
                                                        obj.lockRotation = true;
                                                        obj.hasControls = false;
                                                        obj.hasBorders = false;
                                                        thisCanvas.add(
                                                          obj.set({
                                                            left: gap * 12,
                                                            top: 0,
                                                          })
                                                        );

                                                        fabric.loadSVGFromURL(
                                                          "assets/image/perio/46l.svg",
                                                          function (
                                                            objects,
                                                            options
                                                          ) {
                                                            let obj =
                                                              fabric.util.groupSVGElements(
                                                                objects,
                                                                options
                                                              );
                                                            obj.lockMovementX =
                                                              true;
                                                            obj.lockMovementY =
                                                              true;
                                                            obj.lockScalingX =
                                                              true;
                                                            obj.lockScalingY =
                                                              true;
                                                            obj.lockRotation =
                                                              true;
                                                            obj.hasControls =
                                                              false;
                                                            obj.hasBorders =
                                                              false;
                                                            thisCanvas.add(
                                                              obj.set({
                                                                left: gap * 13,
                                                                top: 0,
                                                              })
                                                            );

                                                            fabric.loadSVGFromURL(
                                                              "assets/image/perio/47l.svg",
                                                              function (
                                                                objects,
                                                                options
                                                              ) {
                                                                let obj =
                                                                  fabric.util.groupSVGElements(
                                                                    objects,
                                                                    options
                                                                  );
                                                                obj.lockMovementX =
                                                                  true;
                                                                obj.lockMovementY =
                                                                  true;
                                                                obj.lockScalingX =
                                                                  true;
                                                                obj.lockScalingY =
                                                                  true;
                                                                obj.lockRotation =
                                                                  true;
                                                                obj.hasControls =
                                                                  false;
                                                                obj.hasBorders =
                                                                  false;
                                                                thisCanvas.add(
                                                                  obj.set({
                                                                    left:
                                                                      gap * 14,
                                                                    top: 0,
                                                                  })
                                                                );

                                                                fabric.loadSVGFromURL(
                                                                  "assets/image/perio/48l.svg",
                                                                  function (
                                                                    objects,
                                                                    options
                                                                  ) {
                                                                    let obj =
                                                                      fabric.util.groupSVGElements(
                                                                        objects,
                                                                        options
                                                                      );
                                                                    obj.lockMovementX =
                                                                      true;
                                                                    obj.lockMovementY =
                                                                      true;
                                                                    obj.lockScalingX =
                                                                      true;
                                                                    obj.lockScalingY =
                                                                      true;
                                                                    obj.lockRotation =
                                                                      true;
                                                                    obj.hasControls =
                                                                      false;
                                                                    obj.hasBorders =
                                                                      false;
                                                                    thisCanvas.add(
                                                                      obj.set({
                                                                        left:
                                                                          gap *
                                                                          15,
                                                                        top: 0,
                                                                      })
                                                                    );

                                                                    thisComponent.drawRedLines();
                                                                    thisComponent.drawBlueLines();
                                                                  }
                                                                );
                                                              }
                                                            );
                                                          }
                                                        );
                                                      }
                                                    );
                                                  }
                                                );
                                              }
                                            );
                                          }
                                        );
                                      }
                                    );
                                  }
                                );
                              }
                            );
                          }
                        );
                      }
                    );
                  }
                );
              }
            );
          }
        );
      }
    );
  };

  initRedLines = () => {
    let lineAttrs = {
      stroke: "red",
      strokeWidth: 1,
      lockMovementX: true,
      lockMovementY: true,
      lockScalingX: true,
      lockScalingY: true,
      lockRotation: true,
      hasControls: false,
      hasBorders: false,
    };

    //47
    for (let i = 0; i < this.allRedPts.length - 1; i++) {
      let j = i + 1;

      let toothPos1 = Math.floor(i / 3);
      let toothPos2 = Math.floor(j / 3);
      let dividerPos1 = i % 3;
      let dividerPos2 = j % 3;
      let redLine = new fabric.Line(
        [
          this.mainPtArray[toothPos1][dividerPos1][this.allRedPts[i]].x,
          this.mainPtArray[toothPos1][dividerPos1][this.allRedPts[i]].y,
          this.mainPtArray[toothPos2][dividerPos2][this.allRedPts[j]].x,
          this.mainPtArray[toothPos2][dividerPos2][this.allRedPts[j]].y,
        ],
        lineAttrs
      );

      this.allRedLines.push(redLine);
    }
  };

  initBlueLines = () => {
    let lineAttrs = {
      stroke: "#42A0D8",
      strokeWidth: 1,
      lockMovementX: true,
      lockMovementY: true,
      lockScalingX: true,
      lockScalingY: true,
      lockRotation: true,
      hasControls: false,
      hasBorders: false,
    };
    //47
    for (let i = 0; i < this.allBluePts.length - 1; i++) {
      let j = i + 1;

      let toothPos1 = Math.floor(i / 3);
      let toothPos2 = Math.floor(j / 3);
      let dividerPos1 = i % 3;
      let dividerPos2 = j % 3;

      let redLine = new fabric.Line(
        [
          this.mainPtArray[toothPos1][dividerPos1][this.allBluePts[i]].x,
          this.mainPtArray[toothPos1][dividerPos1][this.allBluePts[i]].y,
          this.mainPtArray[toothPos2][dividerPos2][this.allBluePts[j]].x,
          this.mainPtArray[toothPos2][dividerPos2][this.allBluePts[j]].y,
        ],
        lineAttrs
      );

      this.allBlueLines.push(redLine);
    }
  };

  drawRedLines = () => {
    for (let i = 0; i < this.allRedLines.length; i++) {
      this._canvas.add(this.allRedLines[i]);
    }
  };

  drawBlueLines = () => {
    for (let i = 0; i < this.allBlueLines.length; i++) {
      this._canvas.add(this.allBlueLines[i]);
    }
  };
}
