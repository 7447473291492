import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SiteLayoutComponent } from './layouts/site-layout/site-layout.component';
import { LoginComponent } from './modules/login/components/login/login.component';
import { ForgotPasswordComponent } from './modules/login/components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './modules/login/components/reset-password/reset-password.component';
import { UpdatePasswordComponent } from './modules/login/components/update-password/update-password.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { DashboardComponent } from './modules/dashboard/components/dashboard/dashboard.component';
import { LogGuard, AuthGuard } from './modules/login/services/guards/auth/auth.guard';
import { AccountSettingsComponent } from './modules/account-settings/components/account-settings/account-settings.component';
import { PatientProfileComponent } from './modules/front-desk/components/patient-profile/patient-profile.component';
import { PatientBillingComponent } from './modules/front-desk/components/patient-billing/patient-billing.component';
import { LaboratoryWorksComponent } from './modules/front-desk/components/laboratory-works/laboratory-works.component';
import { InventoryComponent } from './modules/front-desk/components/inventory/inventory.component';
import { DirectoryComponent } from './modules/front-desk/components/directory/directory.component';
import { PatientsComponent } from './modules/patients/components/patients/patients.component';
import { PatientDetailsComponent } from './modules/patients/components/patient-details/patient-details.component';
import { EmployeesComponent } from './modules/dental-office/components/employees/employees.component';
import { DentalFormsComponent } from './modules/dental-office/components/dental-forms/dental-forms.component';
import { ExpensesComponent } from './modules/front-desk/components/expenses/expenses.component';
import { FinanceComponent } from './modules/dental-office/components/finance/finance.component';
import { DailyTimeRecordComponent } from './modules/dental-office/components/employees/daily-time-record/daily-time-record.component';
import { PayrollComponent } from './modules/dental-office/components/employees/payroll/payroll.component';
import { TimeRecordComponent } from './modules/daily-time-record/components/time-record/time-record.component';
import { SearchResultsComponent } from './modules/core/components/topbar/search-results/search-results.component';
import { TreatmentDemoComponent } from './modules/treatment-demo/components/treatment-demo/treatment-demo.component';
import { VerifyAccountComponent } from './modules/login/components/verify-account/verify-account.component';

const routes: Routes = [
    //Site Layout Routes
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: '',
        component: SiteLayoutComponent,
        children: [
            { path: 'login', component: LoginComponent },
            { 
              path: 'update-password', 
              component: UpdatePasswordComponent,
              canActivate: [LogGuard]
            },
            { path: 'forgot-password', component: ForgotPasswordComponent },
            { path: 'reset-password/:id', component: ResetPasswordComponent },
            { path: 'verify-account/:id', component: VerifyAccountComponent }
        ]
    },

    //App Layout Routes
    {
        path: '',
        component: AppLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'dashboard', component: DashboardComponent },
            { path: 'account-settings', component: AccountSettingsComponent },
            { path: 'daily-time-record', component: TimeRecordComponent },
            { path: 'patient-profile', component: PatientProfileComponent },
            { path: 'billings', component: PatientBillingComponent },
            { path: 'laboratory-works', component: LaboratoryWorksComponent },
            { path: 'inventory', component: InventoryComponent },
            { path: 'directory', component: DirectoryComponent },
            { path: 'patients', component: PatientsComponent },
            { path: 'patient-details/:id', component: PatientDetailsComponent },
            { path: 'expenses', component: ExpensesComponent },
            { path: 'employees', component: EmployeesComponent },
            { path: 'dental-forms', component: DentalFormsComponent },
            { path: 'finance', component: FinanceComponent },
            { path: 'employees/daily-time-record/:id', component: DailyTimeRecordComponent },
            { path: 'employees/payroll/:id', component: PayrollComponent },
            { path: 'search', component: SearchResultsComponent },
            { path: 'treatment-demo', component: TreatmentDemoComponent },
        ]
    },
    //No Layout
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
