import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { PatientService } from 'src/app/modules/front-desk/service/patient/patient.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'ark-dental-history',
  templateUrl: './dental-history.component.html',
  styleUrls: ['./dental-history.component.scss']
})
export class DentalHistoryComponent implements OnInit, OnDestroy { 

  @Input('selectedIndex') selectedIndex: number; 
  @Output('selectedIndexChange') selectedIndexChange: EventEmitter<any> = new EventEmitter;
  @Output() isDisabled: EventEmitter<any> = new EventEmitter;

  isSubmitted = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  allergyCtrl = new FormControl();
  filteredAllergies: Observable<string[]>;
  allergies: string[] = [];
  allAllergies: string[] = [
    'Drug Allergy', 
    'Food Allergy', 
    'Insect Allergy', 
    'Latex Allergy', 
    'Pet Allergy',
    'Pollen Allergy'
  ];
  patient;

  @ViewChild('allergyInput') allergyInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  dentalHistories = [
    { id: 1, name: 'AIDS', is_checked: 0, value: null },
    { id: 2, name: 'Alcohol', is_checked: 0, value: null },
    { id: 3, name: 'Allergies', is_checked: 0, value: [] },
    { id: 4, name: 'Anemia', is_checked: 0, value: null },
    { id: 5, name: 'Arthritis', is_checked: 0, value: null },
    { id: 6, name: 'Asthma', is_checked: 0, value: null },
    { id: 7, name: 'Bleeding', is_checked: 0, value: null },
    { id: 8, name: 'Breastfeeding', is_checked: 0, value: null },
    { id: 9, name: 'Cancer', is_checked: 0, value: null },
    { id: 10, name: 'Diabetes', is_checked: 0, value: null },
    { id: 11, name: 'Dialysis', is_checked: 0, value: null },
    { id: 12, name: 'Endocrine', is_checked: 0, value: null },
    { id: 13, name: 'Epilepsy', is_checked: 0, value: null },
    { id: 14, name: 'Headaches', is_checked: 0, value: null },
    { id: 15, name: 'Heart Condition', is_checked: 0, value: '' },
    { id: 16, name: 'Hepatitis', is_checked: 0, value: null },
    { id: 17, name: 'HIV', is_checked: 0, value: null },
    { id: 18, name: 'Hospitalization', is_checked: 0, value: '' },
    { id: 19, name: 'Kidney Disease', is_checked: 0, value: null },
    { id: 20, name: 'Lung Condition', is_checked: 0, value: null },
    { id: 21, name: 'Peptic Ulcer', is_checked: 0, value: null },
    { id: 22, name: 'Pregnancy', is_checked: 0, value: null },
    { id: 23, name: 'Psychological Condition', is_checked: 0, value: null },
    { id: 24, name: 'Smoking', is_checked: 0, value: '' },
    { id: 25, name: 'Teeth Grinding', is_checked: 0, value: null },
    { id: 26, name: 'Thyroid Condition', is_checked: 0, value: null },
    { id: 27, name: 'Tuberculosis', is_checked: 0, value: null },
    { id: 28, name: 'Others', is_checked: 0, value: '' },
  ];
  
  dialogConfig = new MatDialogConfig();
  dialogRef: any;
  historyData: any = [];
  errorMessage = '';
  success = false;
  formGroup: FormGroup;
  formData: any;
  datePipe = new DatePipe('en-US');
  
  constructor(
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private auth: AuthService,
    public dialog: MatDialog,
    public router: Router
  ) { 
    this.formGroup = this.formBuilder.group({
      dentalHistories: new FormArray([])
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.filteredAllergies = this.allergyCtrl.valueChanges.pipe(
        startWith(null),
        map((allergy: string | null) => allergy ? this._filter(allergy) : this.allAllergies.slice()));
      this.addCheckboxes();
      this.formData = this.patientService.getData();
      this.dialogConfig.disableClose = true;
      this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    });
  }

  ngOnDestroy() {
    this.patientService.removeData();
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;
      
      if ((value || '').trim()) {
        this.allergies.push(value.trim());
        this.dentalHistories[2].value = this.allergies;
      }
      if (input) {
        input.value = '';
      }
      
      this.allergyCtrl.setValue(null);
    }
  }

  remove(allergy: string): void {
    const index = this.allergies.indexOf(allergy);
    if (index >= 0) {
      this.allergies.splice(index, 1);
      this.dentalHistories[2].value = this.allergies;
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.allergies.push(event.option.viewValue);
    this.allergyInput.nativeElement.value = '';
    this.allergyCtrl.setValue(null);
    
    this.dentalHistories[2].value = this.allergies;
  }

  smoking(event) {
    this.dentalHistories[23].value = event;
  }

  hospitalization(event) {
    this.dentalHistories[17].value = event;
  }

  heartCondition(event) {
    this.dentalHistories[14].value = event;
  }

  others(event) {
    this.dentalHistories[27].value = event;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allAllergies.filter(allergy => allergy.toLowerCase().indexOf(filterValue) === 0);
  }

  addCheckboxes() {
    this.dentalHistories.map((o, i) => {
      const control = new FormControl();
      (this.formGroup.controls.dentalHistories as FormArray).push(control);
    });
  }

  goToEmergencyContact() {
    const tab = this.selectedIndex = this.selectedIndex - 1;
    this.selectedIndexChange.emit(tab);
  }

  submit() {
    this.historyData = [];
    this.formGroup.value.dentalHistories.map((selected, i) => {
      if(selected == true) {
        this.dentalHistories[i].is_checked = 1;
        this.historyData.push(this.dentalHistories[i]);
      }
    });
    let id = this.auth.getAuthUser().user_clinic.id;
    let birthdateFormat = this.datePipe.transform(this.formData[1].birthdate, 'MM/dd/yyyy');
    let data = {
      patient_image: this.formData[0].patient_image,
      first_name: this.formData[0].first_name,
      middle_name: this.formData[0].middle_name,
      last_name: this.formData[0].last_name,
      email_address: this.formData[0].email,
      mobile_number: this.formData[0].mobile_number,
      birthdate: birthdateFormat ? birthdateFormat : '',
      civil_status: this.formData[1].civil_status ? this.formData[1].civil_status : '',
      gender: this.formData[1].gender,
      landline_number: this.formData[1].landline_number,
      height: this.formData[1].height,
      weight: this.formData[1].weight,
      blood_type: this.formData[1].blood_type,
      detailed_address: this.formData[1].detailed_address,
      country: this.formData[1].country,
      province: this.formData[1].province ? this.formData[1].province : '',
      city: this.formData[1].city ? this.formData[1].city : '',
      barangay: this.formData[1].barangay ? this.formData[1].barangay : '',
      zip_code: this.formData[1].zip_code ? this.formData[1].zip_code : '',
      referred_by: this.formData[1].referred_by,
      name: this.formData[2].name,
      relationship: this.formData[2].relationship,
      ec_mobile_number: this.formData[2].ec_mobile_number,
      ec_landline_number: this.formData[2].ec_landline_number,
      history: JSON.stringify(this.historyData),
      user_clinic_id: id
    }
    let formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const element = data[key];
        formData.append(key, element);
      }
    }
    this.patientService.addPatient(formData).subscribe(res => {
      if (res) {
        this.patient = res['data'].patients.slice(-1)[0];
        this.isSubmitted = true;
        this.success = true;
        setTimeout(() => {
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'New patient has been successfully added.',
            button: 'Okay',
            event: this.close
          };
          this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(() => {
            this.router.navigate(['/patient-details/' + this.patient.id]);
            const name = {
              first_name: this.patient.first_name,
              last_name: this.patient.last_name,
              middle_name: this.patient.middle_name ? this.patient.middle_name : '',
              email: this.patient.email,
              mobile_number: this.patient.mobile_number,
              birthdate: moment(this.patient.patient_private_information.birthdate).format("YYYY-MM-DD"),
              allergy: this.patient.allergies ? this.patient.allergies : ''
            };
            localStorage.setItem('patientName', JSON.stringify(name));
          });
        });
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: err.error.message,
        button: 'Okay',
        event: this.close
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      this.errorMessage = err.error.errors;
      this.triggerErrors(err.error.errors);
    }));
  }

  close() {
    this.dialogRef.close();
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }
}
