import { Component, OnInit, Inject, Pipe, PipeTransform } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
} 

@Component({
  selector: 'ark-play-demo',
  templateUrl: './play-demo.component.html',
  styleUrls: ['./play-demo.component.scss']
})
export class PlayDemoComponent implements OnInit {

  dialogConfig = new MatDialogConfig();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PlayDemoComponent>
  ) { }

  ngOnInit() {
  }

}
