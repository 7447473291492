import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/modules/shared/services/http/http.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { addPatient, getProvince, getCountry, getCity, checkPatient, checkPatientPrivateInfo, checkEmergencyContact, getMedicalAndDentalHistory, getPatientBillingList, getPatientBalanceList, getOperationalExpenses, getDirectCost, getDoctorFee, patientBilling, patientBalances, dentistShare } from 'src/app/common/api';

@Injectable()
export class PatientService extends HttpService {

  public data: any = [];

  constructor(http: HttpClient, private _router: Router) {
    super('',http);
  }

  setData(value) {
    this.data.push(value);
  }

  getData() {
    return this.data;
  }

  removeData() {
    return this.data = [];
  }

  addPatient(data) {
    return this.http.post(addPatient, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  checkPatient(user_clinic_id, data) {
    return this.http.post(checkPatient + '?user_clinic_id=' + user_clinic_id, data).map((dataResponse) => {
      return dataResponse['data'];
    });
  }

  checkPatientPrivateInfo(user_clinic_id, data) {
    return this.http.post(checkPatientPrivateInfo + '?user_clinic_id=' + user_clinic_id, data).map(res => {
      return res['data'];
    });
  }

  checkEmergencyContact(name, relationship, ec_mobile_number, ec_landline_number, user_clinic_id) {
    return this.get(checkEmergencyContact + '?name=' + name + '&relationship=' + relationship + '&ec_mobile_number=' + ec_mobile_number + '&ec_landline_number=' + ec_landline_number + '&user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }

  getCountry() {
    return this.get(getCountry).map(res => {
      return res['data'];
    });
  }

  getProvince() {
    return this.get(getProvince).map(res => {
      return res['data'];
    });
  }

  getCity(id) {
  	return this.http.get(getCity + "/" + id).map(res => {
      return res['data'];
    });
  }

  getDentalHistory() {
    return this.get(getMedicalAndDentalHistory).map(res => {
      return res['data'];
    });
  }

  getPatientBillingList(user_clinic_id, dentist_id, from, to, patient_name) {
    return this.http.get(getPatientBillingList + "?user_clinic_id=" + user_clinic_id  + "&dentist_id=" + dentist_id + "&from=" + from + "&to=" + to + "&patient_name=" + patient_name).map(res => {
      return res['data'];
    });
  }

  getPatientBalanceList(user_clinic_id, dentist_id, from, to, patient_name) {
    return this.http.get(getPatientBalanceList + "?user_clinic_id=" + user_clinic_id  + "&dentist_id=" + dentist_id + "&from=" + from + "&to=" + to + "&patient_name=" + patient_name).map(res => {
      return res['data'];
    });
  }

  getPatientBillingSummary(user_clinic_id, from, to) {
    return this.http.get(getPatientBillingList + "?user_clinic_id=" + user_clinic_id  + "&from=" + from + "&to=" + to).map(res => {
      return res['data'];
    });
  }

  getAnnualSummaryExpenses(user_clinic_id, year) {
    return this.http.get(getOperationalExpenses + "/annual" + "?user_clinic_id=" + user_clinic_id + "&year=" + year).map(res => {
      return res['data'];
    });
  }

  getDirectCost(user_clinic_id, month, year, patient_name) {
    return this.http.get(getDirectCost + "/monthly" + "?user_clinic_id=" + user_clinic_id + "&month=" + month + "&year=" + year + "&patient_name=" + patient_name).map(res => {
      return res['data'];
    });
  }

  getDoctorFee(user_clinic_id, month, year, patient_doctor_name) {
    return this.http.get(getDoctorFee + "/monthly" + "?user_clinic_id=" + user_clinic_id + "&month=" + month + "&year=" + year + "&patient_doctor_name=" + patient_doctor_name).map(res => {
      return res['data'];
    });
  }

  downloadPatientBilling(user_clinic_id, dentist_id, from, to, patient_name) {
    return this.http.get(patientBilling + "/filter/download" + "?user_clinic_id=" + user_clinic_id  + "&dentist_id=" + dentist_id + "&from=" + from + "&to=" + to + "&patient_name=" + patient_name, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  downloadPatientBalances(user_clinic_id, dentist_id, from, to, patient_name) {
    return this.http.get(patientBalances + "/filter/download" + "?user_clinic_id=" + user_clinic_id  + "&dentist_id=" + dentist_id + "&from=" + from + "&to=" + to + "&patient_name=" + patient_name, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  emailPatientBilling(user_clinic_id, dentist_id, from, to, patient_name) {
    return this.http.get(patientBilling + "/filter/email" + "?user_clinic_id=" + user_clinic_id  + "&dentist_id=" + dentist_id + "&from=" + from + "&to=" + to + "&patient_name=" + patient_name, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  emailPatientBalances(user_clinic_id, dentist_id, from, to, patient_name) {
    return this.http.get(patientBalances + "/filter/email" + "?user_clinic_id=" + user_clinic_id  + "&dentist_id=" + dentist_id + "&from=" + from + "&to=" + to + "&patient_name=" + patient_name, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  saveDentistShare(data) {
    return this.http.post(dentistShare + "/receive", data).map(res => {
      return res['data'];
    });
  }
}
