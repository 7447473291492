import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DashboardService } from '../../../services/dashboard/dashboard.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ViewAppointmentComponent } from '../../view-appointment/view-appointment.component';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { SidebarService } from 'src/app/modules/core/services/sidebar/sidebar.service';

@Component({
  selector: 'ark-day-view',
  templateUrl: './day-view.component.html',
  styleUrls: ['./day-view.component.scss']
})
export class DayViewComponent implements OnInit, OnChanges {

  success: boolean = false;
  isSidebarMinimized: boolean = false;
  appointments = null;
  user_clinic_id = '';

  timeRange = [
    { id: 0, name: ' ', value: ' ' },
    { id: 1, name: '06:00 AM', value: '06:00' },
    { id: 2, name: '06:30 AM', value: '06:30' },
    { id: 3, name: '07:00 AM', value: '07:00' },
    { id: 4, name: '07:30 AM', value: '07:30' },
    { id: 5, name: '08:00 AM', value: '08:00' },
    { id: 6, name: '08:30 AM', value: '08:30' },
    { id: 7, name: '09:00 AM', value: '09:00' },
    { id: 8, name: '09:30 AM', value: '09:30' },
    { id: 9, name: '10:00 AM', value: '10:00' },
    { id: 10, name: '10:30 AM', value: '10:30' },
    { id: 11, name: '11:00 AM', value: '11:00' },
    { id: 12, name: '11:30 AM', value: '11:30' },
    { id: 13, name: '12:00 PM', value: '12:00' },
    { id: 14, name: '12:30 PM', value: '12:30' },
    { id: 15, name: '01:00 PM', value: '13:00' },
    { id: 16, name: '01:30 PM', value: '13:30' },
    { id: 17, name: '02:00 PM', value: '14:00' },
    { id: 18, name: '02:30 PM', value: '14:30' },
    { id: 19, name: '03:00 PM', value: '15:00' },
    { id: 20, name: '03:30 PM', value: '15:30' },
    { id: 21, name: '04:00 PM', value: '16:00' },
    { id: 22, name: '04:30 PM', value: '16:30' },
    { id: 23, name: '05:00 PM', value: '17:00' },
    { id: 24, name: '05:30 PM', value: '17:30' },
    { id: 25, name: '06:00 PM', value: '18:00' },
    { id: 26, name: '06:30 PM', value: '18:30' },
    { id: 27, name: '07:00 PM', value: '19:00' },
    { id: 28, name: '07:30 PM', value: '19:30' },
    { id: 29, name: '08:00 PM', value: '20:00' },
    { id: 30, name: '08:30 PM', value: '20:30' },
    { id: 31, name: '09:00 PM', value: '21:00' },
    { id: 32, name: '09:30 PM', value: '21:30' },
    { id: 33, name: '10:00 PM', value: '22:00' },
    { id: 34, name: '10:30 PM', value: '22:30' },
    { id: 35, name: '11:00 PM', value: '23:00' },
    { id: 36, name: '11:30 PM', value: '23:30' },
    { id: 37, name: '12:00 AM', value: '24:00' },
  ];
  appointmentId = null;
  ppap: Object = {};
  doctors = [];
  filler = {};
  first_data = {};
  last_data = {};
  details = {};
  assignee: Object = {};
  @Input() newAppointment: any = null;
  @Input() date: any = null;
  todayDate = null;
  datePipe = new DatePipe('en-US');
  c_list = null;
  s_doctor = null;

  colorCollection = [
    '#FEA47F',
    '#25CBF7',
    '#EAB543',
    '#54E6C1',
    '#CAD3C8',
    '#F97F51',
    '#1B9CFC',
    '#F8EFB9',
    '#58B09F',
    '#2C3A47',
    '#B33771',
    '#3A3B98',
    '#FD7272',
    '#9AECDB',
    '#D6A2E8',
    '#6D204F',
    '#182B61',
    '#FC427B',
    '#BDC581',
    '#000000'
  ];

  constructor(
    private dashboard: DashboardService,
    private auth: AuthService,
    public dialog: MatDialog,
    private sidebarService: SidebarService
  ) {
    this.user_clinic_id = this.auth.getAuthUser().user_clinic.id;
    if(localStorage.getItem('isSidebarMinimized')) {
      this.isSidebarMinimized = JSON.parse(localStorage.getItem('isSidebarMinimized'));
    }
    this.sidebarService.sidebarState.subscribe((nextValue) => {
      this.isSidebarMinimized = nextValue
    });
    if (this.c_list == null) {
      this.dashboard.getDentistList(this.user_clinic_id).subscribe(res => {
        if (res) {
          localStorage.setItem('d-list', JSON.stringify(res));
          const colors = {}
          let i = 0;
          res.forEach( d => {
            if (i % 20 === 0) {
              i = 0;
            }
            colors[d.id] = '';
            colors[d.id] = this.colorCollection[i];
            i++;
          });
          localStorage.setItem('c-collections', JSON.stringify(colors));
          this.c_list = colors;
        }
      });
    } else {
      this.c_list = JSON.parse(localStorage.getItem('c-collections'));
    }
    if (localStorage.getItem('selectedApp')) {
      this.s_doctor = JSON.parse(localStorage.getItem('selectedApp'));
    }
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if(this.date) {
      this.todayDate = this.datePipe.transform(this.date, 'yyyy-MM-dd');
      this.viewTodayAppointment();
    } else if(this.newAppointment) {
      this.viewTodayAppointment();
    }
  }

  viewTodayAppointment() {
    this.filler = {};
    this.doctors = [];
    this.dashboard.viewAppointmentsToday(this.user_clinic_id, this.todayDate).subscribe(res => {
      if(res) {
        this.success = true;
        this.appointments = res;
        let selected = null;
        if (this.s_doctor) {
          selected = this.s_doctor['dentist'].id;
          const index = this.appointments.findIndex((item, i) => {
            return item.id === selected;
          });
          const data = this.appointments.filter(item => item.id == selected)[0];
          this.appointments.splice(index, 1);
          this.appointments.unshift(data);
        }

        this.appointments.forEach(element => {
          this.ppap[element.id] = element.patient_appointments;
          this.assignee[element.id] = element;
          this.doctors.push(element.id);
        });

        let stuff: number[] = [];
        let i = 0;

        for(let d of this.doctors) {
          this.filler[d] = {};
          stuff = [];
          i = 0;
          for(let ap of this.ppap[d]) {
            this.filler[d][ap.id] = [];
            const start = moment(ap.appointment_start).format("HH:mm");
            const end = moment(ap.appointment_end).subtract(30, 'minutes').format("HH:mm");

            for(let time of this.timeRange) {
              i++;
              if(time.id !== 0) {
                if(time.value == start) {
                  stuff.push(time.id);
                } else if (time.value > start && time.value <= end) {
                  stuff.push(time.id);
                }
              }

              if (this.timeRange.length == i) {
                this.filler[d][ap.id] = stuff;
                stuff = [];
                i = 0;
              }
            }
          }
        }
        // console.log(this.ppap);
        // console.log(this.doctors);
        // console.log(this.assignee);
        // console.log(this.filler);

        let flag = false;
        for(let d of this.doctors) {
          if(this.ppap[d].length > 0) {
            for(let item of this.ppap[d]) {
              const start = moment(item.appointment_start).format("HH:mm");
              for(let time of this.timeRange) {
                if(start.toString() == this.timeRange[time.id].value) {
                  flag = true;
                  this.details[item.id] = {};
                  this.details[item.id] = {
                    data: item,
                    id: item.id,
                    firstname: item.patient.first_name,
                    lastname: item.patient.last_name,
                    procedure: item.procedure
                  };
                  break;
                } else {
                  flag = false;
                }
              }
            }
          }
        }
      }
    });
  }

  // View appointment on click
  viewAppointment(id, appointments, doctor, doctor_id) {
    const dialogConfig = new MatDialogConfig();
    if(id !== null) {
      dialogConfig.data = {
        appointment_id: id,
        doctor: doctor,
        doctor_id: doctor_id,
        patient: appointments.filter(_ => _.id == id)[0]
      };
      dialogConfig.disableClose = true;
      dialogConfig.backdropClass = 'mat-dialog-backdrop';
      let dialogRef = this.dialog.open(ViewAppointmentComponent, dialogConfig).afterClosed().subscribe(res => {
        if(res) {
          this.viewTodayAppointment();
        }
      });
    }
  }

  // Check cell to be filled
  dataChecker(obj, doctor, time_id) {
    let flag = false;
    if(Object.keys(obj).length > 0) {
      for(let i of this.ppap[doctor]) {
        if(obj[i.id].includes(time_id)) {
          flag = true;
          break;
        } else {
          flag = false;
        }
      }
      return flag;
    } else {
      return false;
    }
  }

  // Check starting cell for appointment
  firstDataCheck(obj, time_id) {
    let flag = false;
    if(obj.length > 0) {
      for(let item of obj) {
        const start = moment(item.appointment_start).format("HH:mm");
        if(start.toString() == this.timeRange[time_id].value) {
          flag = true;
          break;
        } else {
          flag = false;
        }
      }
      return flag;
    } else {
      return false;
    }
  }

  // Check ending cell for appointment
  lastDataCheck(obj, time_id) {
    let flag = false;
    if(obj.length > 0) {
      for(let item of obj) {
        const end = moment(item.appointment_end).subtract(30, 'minutes').format("HH:mm");
        if(end.toString() == this.timeRange[time_id].value) {
          flag = true;
          break;
        } else {
          flag = false;
        }
      }
      return flag;
    } else {
      return false;
    }
  }

  getAppointmentId(obj, time_id, appointments) {
    let flag = null;
    if(Object.keys(obj).length > 0) {
      for(let item of Object.keys(obj)) {
        const start = moment(appointments.filter(e => e.id == item)[0].appointment_start).format("HH:mm");
        if(Object.keys(obj).includes(item) && start.toString() == this.timeRange[time_id].value) {
          flag = item;
          break;
        }
      }
      return flag;
    } else {
      return null;
    }
  }

  getId(obj, time_id, appointments) {
    let flag = null;
    if(Object.keys(obj).length > 0) {
      for(let item of Object.keys(obj)) {
        const end = moment(appointments.filter(e => e.id == item)[0].appointment_end).subtract(30, 'minutes').format("HH:mm");
        const start = moment(appointments.filter(e => e.id == item)[0].appointment_start).format("HH:mm");
        if(Object.keys(obj).includes(item) && (this.timeRange[time_id].value <= end && this.timeRange[time_id].value >= start)) {
          flag = item;
          break;
        }
      }
      return flag;
    } else {
      return null;
    }
  }

  getDetails(id) {
    if(id) {
      const firstname = this.details[id].firstname;
      const lastname = this.details[id].lastname;
      const procedure = this.details[id].procedure;
      const start = moment(this.details[id].data.appointment_start).format("h:mm A");
      const end = moment(this.details[id].data.appointment_end).format("h:mm A");
      return firstname + ' ' + lastname + '\n' + procedure + ' ( ' + start + ' - ' + end + ' )';
    } else {
      return;
    }
  }

  hexToRGB(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16),
          g = parseInt(hex.slice(3, 5), 16),
          b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    }
  }
}
