import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/modules/shared/services/http/http.service';
import { getTimeRecord } from 'src/app/common/api';

@Injectable()
export class TimeRecordService extends HttpService {

  constructor(http: HttpClient, private _router: Router) { 
    super('',http);
  }

  setTimeIn(data) {
    return this.http.post(getTimeRecord + "/time-in", data).map((dataResponse) => {
      return dataResponse;
    });
  }

  setTimeOut(id, data) {
    return this.http.put(getTimeRecord + '/time-out/' + id, data).map((dataResponse) => {
      return dataResponse;
    })
  }

  setTimeOutUndertime(id, data) {
    return this.http.put(getTimeRecord + '/time-out-undertime/' + id, data).map((dataResponse) => {
      return dataResponse;
    })
  }

  getTimeIn(user_clinic_id, user_id) {
    return this.get(getTimeRecord + '-check' + '?user_clinic_id=' + user_clinic_id + '&user_id=' + user_id).map(res => {
      return res['data'];
    });
  }

  getTimeRecords(user_clinic_id, user_id, month, year) {
    return this.get(getTimeRecord + '?user_clinic_id=' + user_clinic_id + '&user_id=' + user_id + '&month=' + month + '&year=' + year).map(res => {
      return res['data'];
    });
  }
}
