import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { ConfirmClinicSwitchComponent } from '../shared/components/confirm-clinic-switch/confirm-clinic-switch.component';
import { SearchResultsComponent } from './components/topbar/search-results/search-results.component';
import { FormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SidebarService } from './services/sidebar/sidebar.service';
import { MatListModule } from '@angular/material/list';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  // suppressScrollX: true
}
@NgModule({
  declarations: [
    SidebarComponent,
    TopbarComponent,
    SearchResultsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule,
    PerfectScrollbarModule,
    MatListModule
  ],
  providers: [
    SidebarService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
    
  ],
  exports: [
    SidebarComponent,
    TopbarComponent
  ],
  entryComponents: [
    ConfirmClinicSwitchComponent
  ]
})
export class CoreModule { }
