import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'ark-unbridge',
  templateUrl: './unbridge.component.html',
  styleUrls: ['./unbridge.component.scss']
})
export class UnbridgeComponent implements OnInit {

  SUBJECT_1: string;
  SUBJECT_2: string;
  DATE_CREATED: any;
  DATE_TODAY: any;
  isCurrentDate: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<UnbridgeComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.SUBJECT_1 = this.data.SUBJECT_1;
    this.SUBJECT_2 = this.data.SUBJECT_2;
    this.DATE_CREATED = this.data.DATE_CREATED;
    this.DATE_TODAY = this.data.DATE_TODAY;

    if(this.DATE_CREATED < this.DATE_TODAY) {
      // Selected date is in the past
      this.isCurrentDate = false;
    } else {
      // Selected date is NOT in the past
      this.isCurrentDate = true;
    }
  }

  close(flag = false) {
    this.dialogRef.close(flag);
  }

}
