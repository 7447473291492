import { Component, OnInit, OnDestroy, AfterViewInit, HostListener } from '@angular/core';
import { MatTabChangeEvent, MatDialogConfig, MatDialog } from '@angular/material';
import { PatientsDirectoryService } from '../../services/patients-directory/patients-directory.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

@Component({
  selector: 'ark-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss']
})
export class PatientDetailsComponent implements OnInit, OnDestroy, AfterViewInit {

  toothChartTab = false;
  hasPreviousVisits = true;
  hasSidebar = true;
  hasAllergy = false;
  success = false;
  patientId =  null;
  chart: any;
  patient = '';
  previousVisitId = null;
  tab = null;
  isDisabled = null;
  isNew = null;
  allergies = null;

  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  width: number = 0;

  constructor(
    private patientsDirectory: PatientsDirectoryService,
    private auth: AuthService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public router: Router,
  ) { 
    this.route.params.subscribe(param => {
      this.patientId = param.id
    });
    if(localStorage.getItem('patientName')) {
      const name = JSON.parse(localStorage.getItem('patientName'));
      /** Get patient name */
      this.patient = name.last_name + ', ' + name.first_name + ' ' + name.middle_name;

      /** Get first letter of the last name */
      const lastName = name.last_name.toUpperCase();
      const int = lastName.charCodeAt(0) - 65;
      localStorage.setItem('patientIndex', int.toString());

      /** Get allergies */
      if(name.allergy != '') {
        if(name.allergy.length > 0) {
          this.hasAllergy = true;
          const x = name.allergy.toString();
          
          this.allergies = x.replace(/,/g, ", ");
        } else {
          this.hasAllergy = false;
        }
      }
    } 
    if(localStorage.getItem('visitInfo')) {
      const visit = JSON.parse(localStorage.getItem('visitInfo'));
      this.tab = visit.tab;
      this.isDisabled = visit.disabled;
      this.previousVisitId = visit.id;
    }
  }

  ngOnInit() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  ngOnDestroy() {
    localStorage.removeItem('visitInfo');
  }

  // @HostListener('window:resize', ['$event'])
  //   onResize(event) {
  //     this.width = event.target.innerWidth;
  //     if(this.width < 1400) {
  //       const target = document.getElementsByClassName('mat-tab-header')[0];
  //       if (target) {
  //         target.classList.add('mat-tab-header-pagination-controls-enabled'); 
  //       } else {
  //         target.classList.add('mat-tab-header-pagination-controls-enabled'); 
  //       }
  //     }
  //   }

  ngAfterViewInit() {
    // if(this.width < 1400) {
    //   const target = document.getElementsByClassName('mat-tab-header')[0];
    //   if (target) {
    //     target.classList.add('mat-tab-header-pagination-controls-enabled'); 
    //   } else {
    //     target.classList.add('mat-tab-header-pagination-controls-enabled'); 
    //   }
    // }
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    if(tabChangeEvent.index == 1) {
      this.toothChartTab = true;
    } else {
      this.toothChartTab = false;
    }

    // if(tabChangeEvent.index == 2 || tabChangeEvent.index == 3 || tabChangeEvent.index == 4 || tabChangeEvent.index == 5) {
    if(tabChangeEvent.index == 5) {
      this.hasSidebar = true;
    } else {
      this.hasSidebar = false;
    }

    if(tabChangeEvent.index == 5) {
      this.hasPreviousVisits = true;
    } else {
      this.hasPreviousVisits = false;
    }
  }

  addNewChart() {
    let data = {
      patient_id: this.patientId,
      user_clinic_id: this.auth.getAuthUser().user_clinic['id'],
    }
    this.patientsDirectory.addNewChart(data).subscribe(res => {
      if(res) {
        this.success = true;
        this.chart = res['data'].data[0].toothcharts;
        this.dialogConfig.data = {
          title: 'Success!',
          message: "New tooth chart has been successfully added.",
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Sorry!',
        message: "You\'ve already added tooth chart for today. \nOnly one tooth chart can be generated per day.",
        button: 'Okay',
        event: this.closeOnClick
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }));
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  getVisitId(id) {
    this.previousVisitId = id;
  }

  visitInfoTab(tab) {
    this.tab = tab;
  }

  enableTab(val) {
    this.isDisabled = val;
  }

  goBackToPatientDirectory() {
    // history.go(-1);
    this.router.navigate(['/patients']);
  }

  newVisitInfo(val) {
    this.isNew = val;
  }
}
