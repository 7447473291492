import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { keypressValidateNumber } from 'src/app/common/helper';
import { PatientProfileCameraComponent } from './patient-profile-camera/patient-profile-camera.component';

@Component({
  selector: 'ark-patient-profile',
  templateUrl: './patient-profile.component.html',
  styleUrls: ['./patient-profile.component.scss']
})
export class PatientProfileComponent implements OnInit {

  @Input() patient: any;
  
  errorMessage = null;
  success = false;
  patientId = '';
  formGroup: FormGroup;
  imageSrc = null;
  patientData;

  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  constructor(
    private formBuilder: FormBuilder,
    private patientsDirectory: PatientsDirectoryService,
    private route: ActivatedRoute,
    private auth: AuthService,
    public dialog: MatDialog,
    private _router: Router
  ) {
    this.route.params.subscribe(param => {
      this.patientId = param.id
    });
   }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      middle_name: [''],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.email]],
      mobile_number: ['', [Validators.required]],
      patient_image: ['', [Validators.required]]
    });
    let id = this.auth.getAuthUser().user_clinic.id;
    this.patientsDirectory.getPatientInfo(id, this.patientId, id).subscribe(response =>{
      this.patientData = response['data'][0];
      this.formGroup.get('first_name').setValue(this.patientData.first_name);
      this.formGroup.get('middle_name').setValue(this.patientData.middle_name ? this.patientData.middle_name : '');
      this.formGroup.get('last_name').setValue(this.patientData.last_name);
      this.formGroup.get('email').setValue(this.patientData.email ? this.patientData.email : '');
      this.formGroup.get('mobile_number').setValue(this.patientData.mobile_number);
      this.formGroup.get('patient_image').setValue('');
      
      let image = this.patientData.patient_image ? this.patientData.patient_image : "../../../../../assets/image/image-thumbnail.jpg";
      this.imageSrc = image;
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  updatePatient() {
    let id = this.auth.getAuthUser().user_clinic.id;
    let formData = new FormData();
    for(const key in this.formGroup.value) {
      if(this.formGroup.value.hasOwnProperty(key)) {
        const element = this.formGroup.value[key];
        formData.append(key, element);
      }
    }
    this.patientsDirectory.updatePatientProfile(this.patientId, id, formData).subscribe(res => {
      if(res) {
        this.success = true;
        const name = JSON.parse(localStorage.getItem('patientName'));
        name['first_name'] = res['data'].data[0].first_name;
        name['last_name'] = res['data'].data[0].last_name;
        name['email'] = res['data'].data[0].email;
        name['mobile_number'] = res['data'].data[0].mobile_number;
        localStorage.setItem('patientName', JSON.stringify(name));

        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Patient\'s profile has been successfully updated.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => {
          this.redirectTo(this._router.url);
        });
      } 
    },(err => {
      this.errorMessage = err.error.errors;
      this.triggerErrors(err.error.errors);
    }));
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }

  redirectTo(uri: string) {
    this._router.navigateByUrl('/patients', {skipLocationChange: true}).then(()=>
    this._router.navigate([uri]));
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  onChangeProfilePhoto(event) {
    const file = event.target.files;
    if (file && file[0]) {
      var reader = new FileReader();
      reader.onload = function(e) {
        document.getElementById("patient_image")['src'] = e.target['result'];
      }
      reader.readAsDataURL(file[0]);
      this.formGroup.get('patient_image').setValue(file[0]);
    }
  }
  
  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }
  
  openCamera() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogRef = this.dialog.open(PatientProfileCameraComponent, this.dialogConfig).afterClosed().subscribe(res => {
      if(res) {
        if(res['type'] == 1) {
          if(res['image']) {
            var reader = new FileReader();
            reader.readAsDataURL(res['image']);
            reader.onload = (_event) => { 
              document.getElementById("patient_image")['src'] = reader.result;
            }
            this.formGroup.get('patient_image').setValue(res['image']);
          }
        } else {
          document.getElementById("patient_image")['src'] = res;
          this.formGroup.get('patient_image').setValue(res);
        }
      }
    });
  }
}
