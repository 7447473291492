import { Component, OnInit, PipeTransform, Pipe, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PatientService } from 'src/app/modules/front-desk/service/patient/patient.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import * as moment from 'moment';
import { keypressValidateNumber } from 'src/app/common/helper';

@Pipe({ name: 'keys',  pure: false })
export class KeysPipe implements PipeTransform {
  transform(value: any, args: any[] = null): any {
      return Object.keys(value)
  }
}

@Component({
  selector: 'ark-private-info',
  templateUrl: './private-info.component.html',
  styleUrls: ['./private-info.component.scss']
})
export class PrivateInfoComponent implements OnInit {
  
  @Input() patient: any;
  today = new Date(); 
  errorMessage = null;
  patientId = '';
  countries = [];
  provinces = [];
  cities = [];
  success = false;
  formGroup: FormGroup;
  display = false;
  dialogConfig = new MatDialogConfig();
  dialogRef: any;
  genders = [
    { id : '1', name: 'Male' },
    { id : '2', name: 'Female' }
  ];
  civilStatuses = [
    { id: 1, name: 'Single' },
    { id: 2, name: 'Married' },
    { id: 3, name: 'Widowed' },
    { id: 4, name: 'Divorced' },
    { id: 5, name: 'Separated' }
  ];
  bloodTypes = [
    { id: 1, name: 'Type A+' },
    { id: 2, name: 'Type O+' },
    { id: 3, name: 'Type B+' },
    { id: 4, name: 'Type AB+' },
    { id: 5, name: 'Type A-' },
    { id: 6, name: 'Type O-' },
    { id: 7, name: 'Type B-' },
    { id: 8, name: 'Type AB-' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private patientsDirectory: PatientsDirectoryService,
    private auth: AuthService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private _router: Router
  ) {
    this.route.params.subscribe(param => {
      this.patientId = param.id
    });
   }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      birthdate: ['', [Validators.required]],
      civil_status: [''],
      gender: ['', [Validators.required]],
      landline_number: [''],
      height: [''],
      weight: [''],
      blood_type: [''],
      detailed_address: ['', [Validators.required]],
      country: ['', [Validators.required]],
      province: ['', [Validators.required]],
      city: ['', [Validators.required]],
      barangay: [''],
      zip_code: [''],
      referred_by: [''],
    });
    this.patientService.getCountry().subscribe(result => {
      this.countries = result;
    });
    this.patientService.getProvince().subscribe(result => {
      this.provinces = result;
    });
    let id = this.auth.getAuthUser().user_clinic.id;
    this.patientsDirectory.getPatientInfo(id, this.patientId, id).subscribe(response =>{
      let patient = response['data'][0].patient_private_information;
      let date = moment(patient.birthdate).format("YYYY-MM-DD");
      if(patient.province !== null) {
        this.viewCity(patient.province);
      }
      this.formGroup.get('birthdate').setValue(date);
      this.formGroup.get('civil_status').setValue(patient.civil_status);
      this.formGroup.get('gender').setValue(patient.gender);
      this.formGroup.get('landline_number').setValue(patient.landline_number);
      this.formGroup.get('height').setValue(patient.height);
      this.formGroup.get('weight').setValue(patient.weight);
      this.formGroup.get('blood_type').setValue(patient.blood_type);
      this.formGroup.get('detailed_address').setValue(patient.detailed_address);
      this.formGroup.get('country').setValue(patient.country);
      this.formGroup.get('province').setValue(patient.province);
      this.formGroup.get('city').setValue(patient.city);
      this.formGroup.get('barangay').setValue(patient.barangay);
      this.formGroup.get('zip_code').setValue(patient.zip_code);
      this.formGroup.get('referred_by').setValue(patient.referred_by);
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  selectionChange(e) {
    if(e.value !== '168') {
      this.formGroup.controls['province'].disable();
      this.formGroup.controls['city'].disable();
      this.formGroup.controls['barangay'].disable();
      this.formGroup.controls['zip_code'].disable();

      this.formGroup.get('province').setValue('');
      this.formGroup.get('city').setValue('');
      this.formGroup.get('barangay').setValue('');
      this.formGroup.get('zip_code').setValue('');
    } else {
      this.formGroup.controls['province'].enable();
      this.formGroup.controls['city'].enable();
      this.formGroup.controls['barangay'].enable();
      this.formGroup.controls['zip_code'].enable();
    }
  }

  updatePatient() {
    let clinic = this.auth.getAuthUser().user_clinic.id;
    let data = {
      'birthdate' : this.formGroup.value.birthdate,
      'civil_status' : this.formGroup.value.civil_status,
      'gender' : this.formGroup.value.gender,
      'landline_number' : this.formGroup.value.landline_number ? this.formGroup.value.landline_number.toString() : null,
      'height' : this.formGroup.value.height,
      'weight' : this.formGroup.value.weight,
      'blood_type' : this.formGroup.value.blood_type,
      'detailed_address' : this.formGroup.value.detailed_address,
      'country' : this.formGroup.value.country,
      'province' : this.formGroup.value.province,
      'city' : this.formGroup.value.city,
      'barangay' : this.formGroup.value.barangay,
      'zip_code' : this.formGroup.value.zip_code,
      'referred_by' : this.formGroup.value.referred_by
    }
    this.patientsDirectory.getPatientInfo(clinic, this.patientId, clinic).subscribe(response =>{
      let id = response['data'][0].patient_private_information.id;
      if (this.formGroup.value.birthdate > this.today) {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'The birthdate field is invalid.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      } else {
        this.patientsDirectory.updatePatientPrivateInfo(id, clinic, data).subscribe(res => {
          if(res) {
            this.success = true;
            // const name = JSON.parse(localStorage.getItem('patientName'));
            // name['birthdate'] = moment(res['data'].data[0]['patient_private_information'].birthdate).format("YYYY-MM-DD");
            // localStorage.setItem('patientName', JSON.stringify(name));
  
            this.dialogConfig.data = {
              title: 'Success!',
              message: 'Patient\'s private info has been successfully updated.',
              button: 'Okay',
              event: this.closeOnClick
            };
            this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => {
              this.redirectTo(this._router.url);
            });
          } 
        },(err => {
          this.errorMessage = err.error.errors;
          this.triggerErrors(err.error.errors);
        }));
      }
    });
  }

  viewCity(id){
    this.patientService.getCity(id).subscribe(result => {
      this.cities = result;
    });
  }
  
  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }

  redirectTo(uri: string) {
    this._router.navigateByUrl('/patients', {skipLocationChange: true}).then(()=>
    this._router.navigate([uri]));
  }

  closeOnClick() {
    this.dialogRef.close();
  }
  
  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }
}

