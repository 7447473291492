import { Component, OnInit, Inject, ViewChild, Pipe, PipeTransform } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog, MatAutocompleteTrigger, MatAutocomplete } from '@angular/material';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard/dashboard.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { startWith, map } from 'rxjs/operators';
import { keypressValidateNumber } from 'src/app/common/helper';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { ConfirmDeletionComponent } from 'src/app/modules/shared/components/confirm-deletion/confirm-deletion.component';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

export interface Dentist {
  id: number;
  firstName: string;
  lastName: string;
}

export interface Treatment {
  id: number;
  name: string;
}

export interface Item {
  id: number;
  name: string;
  brandname: string;
  description: string;
  quantity: number;
}

@Pipe({name: 'removeThousandSeparator'})
export class RemoveThousandSeparatorPipe implements PipeTransform {
  transform(val: number): string {
    if (val !== undefined && val !== null) {
      // here we just remove the commas from value
      return val.toString().replace(/,/g, "");
    } else {
      return "";
    }
  }
}

@Component({
  selector: 'ark-view-patient-bill',
  templateUrl: './view-patient-bill.component.html',
  styleUrls: ['./view-patient-bill.component.scss']
})
export class ViewPatientBillComponent implements OnInit {
  
  formGroup: FormGroup;
  dialogConfig = new MatDialogConfig();
  billingOption = [
    { id : '1', name: 'Procedure' },
    { id : '2', name: 'Payment' }
  ];
  OPTION_1: boolean = false;
  OPTION_2: boolean = false;
  hasDentist: boolean = false;
  isNoResult: boolean = false;
  label = 'Update Patient Bill';
  button = 'Update';

  @ViewChild('dentist') dentist: MatAutocompleteTrigger;
  @ViewChild('inventory') matAutocompleteInventory: MatAutocomplete;
  @ViewChild('inventory') matAutocompleteTreatment: MatAutocomplete;
  clinic = null
  arrayIndex = 0;
  dentistId = '';
  dentistName = '';
  dentistControl = new FormControl();
  dentists: Dentist[] = [];
  treatments: Treatment[] = [];
  inventories: Item[] = [];
  filteredDentists: Observable<Dentist[]>;

  detail = [];
  patientBill = [];
  procedures = [];
  items = [];
  searchTreatment = [];
  searchItem = [];

  labFee: number = 0;
  discount: number = 0;
  totalFee: number = 0;
  subTotal: number = 0;
  doctorFee: number = 0;
  clinicShare: number = 0;
  itemCost: number = 0;
  totalItemCost: number = 0;
  selectedPercentage: number = 0;
  totalBill: number = 0;
  
  isAdd = true;
  isSubmit = false;
  isUpdate = false;

  sharePercentage = [
    { name: '5%', value: 0.05 },
    { name: '10%', value: 0.1 },
    { name: '15%', value: 0.15 },
    { name: '20%', value: 0.2 },
    { name: '25%', value: 0.25 },
    { name: '30%', value: 0.3 },
    { name: '35%', value: 0.35 },
    { name: '40%', value: 0.4 },
    { name: '45%', value: 0.45 },
    { name: '50%', value: 0.5 },
    { name: '55%', value: 0.55 },
    { name: '60%', value: 0.6 },
    { name: '65%', value: 0.65 },
    { name: '70%', value: 0.7 },
    { name: '75%', value: 0.75 },
    { name: '80%', value: 0.8 },
    { name: '85%', value: 0.85 },
    { name: '90%', value: 0.9 },
    { name: '95%', value: 0.95 },
    { name: '100%', value: 1 },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ViewPatientBillComponent>,
    public dialog: MatDialog,
    private dashboard: DashboardService,
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private patients: PatientsDirectoryService,
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic;
    this.dashboard.getDentistList(this.clinic.id).subscribe(res => {
      res.forEach(element => {
        let obj: Dentist = {
          id: element.id, 
          firstName: element.first_name,
          lastName: element.last_name
        };
        this.dentists.push(obj);
      });
    });
    this.dashboard.getProcedures(this.clinic.id).subscribe(res => {
      res.forEach(element => {
        let obj: Treatment = {
          id: element.id, 
          name: element.title
        };
        this.treatments.push(obj);
      });
    });
    this.patients.getItemDirectoryList(this.clinic.id).subscribe(res => {
      res.forEach(element => {
        let obj: Item = {
          id: element.items.id, 
          name: element.items.name,
          brandname: element.items.brandname,
          description: element.items.description,
          quantity: element.quantity
        };
        this.inventories.push(obj);
      });
    });
  }

  ngOnInit() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.filteredDentists = this.dentistControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterDentist(name) : this.dentists.slice())
      );

    this.searchTreatment = this._treatments('');
    this.searchItem = this._items('');

    this.formGroup = this.formBuilder.group({
      option: [''],
      dentist_id: [''],
      total_fees: [''],
      lab_fee: [''],
      discount: [''],
      sub_total: [''],
      doctors_fee: [''],
      clinic_share: [''],
      share_percentage: [''],
      total_item_cost: [''],
      selected_procedure: [''],
      balance: [''],
      payment_detail: [''],
      amount_paid: [''],
    });
    this.formGroup.controls['total_item_cost'].disable();
    this.formGroup.controls['selected_procedure'].disable();
    this.formGroup.controls['option'].disable();
    this.formGroup.controls['balance'].disable();

    if(this.data.option == 'procedure') {
      /** Set value to fields */
      this.hasDentist = true;
      this.OPTION_1 = true;
      this.OPTION_2 = false;
      this.isSubmit = false;
      this.isAdd = true;
      let total = 0;
      let itemCost = 0;
      let itemTotal = 0;
      let data = {};
      let item = {};
      let newItems =[];

      this.formGroup.get('option').setValue('Procedure');
      this.dashboard.getDentistInfo(this.data.data.dentist_id, this.clinic.id).subscribe(res => {
        if(res) {
          this.dentistControl.setValue(
            {
              id: res[0].id, 
              firstName: res[0].first_name, 
              lastName: res[0].last_name
            }
          );
        }
      });
      for(var i = 0; i < this.data.data.billing_detail_procedures.length; i++) {
        data = {
          id: this.data.data.billing_detail_procedures[i].patient_billing_detail_id,
          procedure_id: this.data.data.billing_detail_procedures[i].procedure_id,
          tooth_no: this.data.data.billing_detail_procedures[i].tooth_no,
          procedure: this.data.data.billing_detail_procedures[i].procedure,
          procedure_fee: this.data.data.billing_detail_procedures[i].procedure_fee,
          items: [],
          total_item_cost: ''
        }
        this.procedures.push(data);

        for(var j = 0; j < this.data.data.billing_detail_procedures[i].items.length; j++) {
          item = {
            id: this.data.data.billing_detail_procedures[i].items[j].id,
            item_id: this.data.data.billing_detail_procedures[i].items[j].item_id,
            item_name: this.data.data.billing_detail_procedures[i].items[j].item_name,
            item_brandname: this.data.data.billing_detail_procedures[i].items[j].item_brandname,
            item_description: this.data.data.billing_detail_procedures[i].items[j].item_description,
            item_used: '',
            item_qty: this.data.data.billing_detail_procedures[i].items[j].item_qty,
            item_amount: this.data.data.billing_detail_procedures[i].items[j].item_amount,
            item_total: '',
            qty: this.data.data.billing_detail_procedures[i].items[j].item_category_inventory.quantity,
          }
          if(this.procedures[i].id == this.data.data.billing_detail_procedures[i].patient_billing_detail_id) {
            this.procedures[i].items.push(item);
          }
        }

        /** Set item name instead of id for viewing */
        if(this.procedures[i].items.length !== 0) {
          this.procedures[i].items.forEach(el => {
            el.item_id = (el.item_name ? el.item_name : '') + (el.item_brandname ? ' - ' + el.item_brandname : '') + (el.item_description ? ' - ' + el.item_description : '');
          });
        }
      }

      this.procedures.forEach(element => {
        element.procedure_fee = parseFloat(element.procedure_fee).toFixed(2);
        total += Number(element.procedure_fee);

        element.items.forEach(x => {
          const quantity = x.item_qty;
          const amount = x.item_amount;
          const product = +quantity * +amount;
          x.item_total = product.toFixed(2);
          x.item_amount = parseFloat(x.item_amount).toFixed(2);
          itemCost += Number(x.item_total);
        });
        this.itemCost = +itemCost;
        element.total_item_cost = this.itemCost;
        itemTotal += Number(element.total_item_cost);
      });

      this.labFee = +this.data.data.lab_fee;
      this.discount = +this.data.data.discount;
      this.totalFee = +total;
      this.totalItemCost = +itemTotal;
      this.subTotal = +this.totalFee - +this.labFee - +this.discount;
      this.selectedPercentage = +this.data.data.total_shares_percent;
      this.dentistId = this.data.data.dentist_id;
      this.dentistName = this.data.data.dentist.first_name + (this.data.data.dentist.middle_name ? ' ' + this.data.data.dentist.middle_name + ' ' : ' ') + this.data.data.dentist.last_name;
      this.formGroup.get('lab_fee').setValue(this.labFee.toFixed(2));
      this.formGroup.get('discount').setValue(this.discount.toFixed(2));
      this.formGroup.get('share_percentage').setValue(Number(this.selectedPercentage));
      this.formGroup.get('total_fees').setValue(this.totalFee.toFixed(2));
      this.formGroup.get('total_item_cost').setValue(this.totalItemCost.toFixed(2));
      this.formGroup.get('sub_total').setValue(this.subTotal.toFixed(2));

      if(this.selectedPercentage !== 0) {
        this.doctorFee = this.subTotal * this.selectedPercentage;
        this.clinicShare = this.subTotal - this.doctorFee;
        this.formGroup.get('doctors_fee').setValue(this.doctorFee.toFixed(2));
        this.formGroup.get('clinic_share').setValue(this.clinicShare.toFixed(2));
      }

      if(!this.selectedPercentage) {
        this.doctorFee = +this.data.data.doctors_fee;
        this.clinicShare = +this.data.data.clinic_share;
        this.formGroup.get('doctors_fee').setValue(this.doctorFee.toFixed(2));
        this.formGroup.get('clinic_share').setValue(this.clinicShare.toFixed(2));
      }
    } else if(this.data.option == 'payment') {
      this.OPTION_1 = false;
      this.OPTION_2 = true;
      this.formGroup.get('option').setValue('Payment');

      this.patients.getPatientInfo(this.clinic.id, this.data.id, this.clinic.id).subscribe(res => {
        this.formGroup.get('balance').setValue(parseFloat(this.data.data.previous_balance).toFixed(2));
        this.formGroup.get('payment_detail').setValue(this.data.data.payment_detail);
        this.formGroup.get('amount_paid').setValue(parseFloat(this.data.data.amount_paid).toFixed(2));
      }); 
    }
  }

  initProcedure() {
    this.procedures = [{
      procedure_id: '',
      tooth_no: '',
      procedure: '',
      procedure_fee: '',
      items: [],
      total_item_cost: ''
    }];
  }

  initItem() {
    return this.items[0] = [{
      item_id: '',
      item_used: '',
      item_qty: '',
      item_amount: '',
      item_total: '',
      qty: ''
    }];
  }

  addProcedure() {
    this.procedures.push({
      procedure_id: '',
      tooth_no: '',
      procedure: '',
      procedure_fee: '',
      items: [],
      total_item_cost: ''
    });
  }

  addItem(i) {
    this.procedures[i].items.push({
      item_id: '',
      item_used: '',
      item_qty: '',
      item_amount: '',
      item_total: '',
      qty: ''
    });
  }

  removeProcedure(index) {
    if(this.button == 'Update') {
      this.dialogConfig.data = {
        subject: 'delete this procedure'
      };
      this.dialogConfig.disableClose = true;
      this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
      let dialogRef = this.dialog.open(ConfirmDeletionComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
        if(trigger) {
          this.procedures.splice(index, 1);
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Procedure has been successfully deleted.',
            button: 'Okay',
            event: this.close
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
          this.getTotalBill();
        }
      });
    } else {
      this.procedures.splice(index, 1);
      this.getTotalBill();
    }
  }

  removeItem(e) {
    if(this.button == 'Update') {
      this.dialogConfig.data = {
        subject: 'delete this item'
      };
      this.dialogConfig.disableClose = true;
      this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
      let dialogRef = this.dialog.open(ConfirmDeletionComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
        if(trigger) {
          this.procedures[e[0]].items.splice([e[1]], 1);
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Procedure has been successfully deleted.',
            button: 'Okay',
            event: this.close
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
  
          let total = 0;
          let itemCost = 0;
          if(e.length == 2) {
            const quantity = this.procedures[e[0]].items[e[1]].item_qty;
            const amount = this.procedures[e[0]].items[e[1]].item_amount;
            const product = +quantity * +amount;
            this.procedures[e[0]].items[e[1]].item_total = product.toFixed(2);
          }

          if(e.length == 2) {
            this.procedures[e[0]].items.forEach(element => {
              itemCost += element.item_total;
            });
            this.itemCost = +itemCost;
            this.procedures[e[0]].total_item_cost = this.itemCost;
          } 
          
          this.procedures.forEach(element => {
            total += element.total_item_cost;
          });
          this.totalItemCost = +total;
          this.formGroup.get('total_item_cost').setValue(this.totalItemCost.toFixed(2));
        }
      });
    } else {
      this.procedures[e[0]].items.splice([e[1]], 1);

      let total = 0;
      let itemCost = 0;

      if(this.procedures[e[0]].items.length > 0) {
        if(e.length == 2) {
          const quantity = this.procedures[e[0]].items[e[1]].item_qty;
          const amount = this.procedures[e[0]].items[e[1]].item_amount;
          const product = +quantity * +amount;
          this.procedures[e[0]].items[e[1]].item_total = product.toFixed(2);
          
          this.procedures[e[0]].items.forEach(element => {
            itemCost += element.item_total;
          });
          this.itemCost = +itemCost;
          this.procedures[e[0]].total_item_cost = this.itemCost;
        } 
      }
      
      this.procedures.forEach(element => {
        total += element.total_item_cost;
      });
      this.totalItemCost = +total;
      this.formGroup.get('total_item_cost').setValue(this.totalItemCost.toFixed(2));
    }
  }

  optionChange(e) {
    if(e.value == 'Procedure') {
      this.OPTION_1 = true;
      this.OPTION_2 = false;
    } else if(e.value == 'Payment') {
      this.OPTION_1 = false;
      this.OPTION_2 = true;

      this.patients.getPatientInfo(this.clinic.id, this.data.id, this.clinic.id).subscribe(res => {
        this.formGroup.get('balance').setValue(res.data[0]['balance'].balance ? res.data[0]['balance'].balance : 0.00);
      }); 
      this.formGroup.get('payment_detail').setValue('');
      this.formGroup.get('amount_paid').setValue('');
    } 

    this.resetFields();
  }

  getDentist(event) {
    this.dentistId = event.option.value.id;
    this.dentistName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.hasDentist = true;
    this.formGroup.controls['lab_fee'].enable();
    this.formGroup.controls['discount'].enable();
    this.formGroup.controls['share_percentage'].enable();
  }

  getUnlistedTreatment(event, i) {
    this.procedures[i].procedure = event;
  }

  getTotalBill() {
    let total = 0;

    this.procedures.forEach(element => {
      total += Number(element.procedure_fee);
    });
    this.totalFee = +total;
    this.labFee = this.formGroup.value.lab_fee ? this.formGroup.value.lab_fee : 0;
    this.discount = this.formGroup.value.discount ? this.formGroup.value.discount : 0;
    this.subTotal = +this.totalFee - +this.labFee - +this.discount;
    this.formGroup.get('total_fees').setValue(this.totalFee.toFixed(2));
    this.formGroup.get('sub_total').setValue(this.subTotal.toFixed(2));

    if(this.selectedPercentage !== 0) {
      this.doctorFee = this.subTotal * this.selectedPercentage;
      this.clinicShare = this.subTotal - this.doctorFee;
      this.formGroup.get('doctors_fee').setValue(this.doctorFee.toFixed(2));
      this.formGroup.get('clinic_share').setValue(this.clinicShare.toFixed(2));
    }

    if(!this.selectedPercentage) {
      this.doctorFee = +this.data.data.doctors_fee;
      this.clinicShare = +this.data.data.clinic_share;
      this.formGroup.get('doctors_fee').setValue(this.doctorFee.toFixed(2));
      this.formGroup.get('clinic_share').setValue(this.clinicShare.toFixed(2));
    }
  }

  getItemTotal(e) {
    let total = 0;
    let itemCost = 0;

    if(e.length == 2) {
      const quantity = this.procedures[e[0]].items[e[1]].item_qty;
      const amount = this.procedures[e[0]].items[e[1]].item_amount;
      const product = +quantity * +amount;
      this.procedures[e[0]].items[e[1]].item_total = product.toFixed(2);
    }

    if(e.length == 2) {
      this.procedures[e[0]].items.forEach(element => {
        itemCost += Number(element.item_total);
      });
      this.itemCost = +itemCost;
      this.procedures[e[0]].total_item_cost = this.itemCost;
    } 
    
    this.procedures.forEach(element => {
      total += Number(element.total_item_cost);
    });
    this.totalItemCost = +total;
    this.formGroup.get('total_item_cost').setValue(this.totalItemCost.toFixed(2));
  }

  displayDentist(dentist?: any) : string | undefined {
    return dentist ? 'Dr. ' + dentist.firstName + ' ' + dentist.lastName : undefined;
  }

  displayItem(item?: any) : string | undefined {
    return this.matAutocompleteInventory.options.filter(x => x.value === item).map(x => x.viewValue)[0] ? this.matAutocompleteInventory.options.filter(x => x.value === item).map(x => x.viewValue)[0] : item;
  }

  displayTreatment(treatment?: any) : string | undefined {
    return this.matAutocompleteTreatment.options.filter(x => x.value === treatment).map(x => x.viewValue)[0] ? this.matAutocompleteTreatment.options.filter(x => x.value === treatment).map(x => x.viewValue)[0] : treatment;
  }

  filterTreatment(name: string) {
    this.searchTreatment = this._treatments(name);
  }
  
  filterItem(name: string) {
    this.searchItem = this._items(name);
  }

  checkItemQty(quantity: number, i: number, j: number) {
    this.procedures[i].items[j].qty = quantity;
  }

  private _filterDentist(name: string): Dentist[] {
    const filterValue = name.toLowerCase();
    return this.dentists.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1);
  }

  private _treatments(name: string): Treatment[] {
    const filterValue = name.toLowerCase();
    // const filterValue = name;
    return this.treatments.filter(option => option.name.toLowerCase().indexOf(filterValue) > -1);
  }
  
  private _items(name: string): Item[] {
    const filterValue = name;
    return this.inventories.filter(option => option.name.toLowerCase().indexOf(filterValue) > -1);
  }

  onFocusDentist() {
    this.dentist._onChange(""); 
    this.dentist.openPanel();
  }

  onPercentageSelect(e) {
    this.selectedPercentage = +e.value;
    this.doctorFee = this.subTotal * this.selectedPercentage;
    this.clinicShare = this.subTotal - this.doctorFee;
    this.formGroup.get('doctors_fee').setValue(this.doctorFee.toFixed(2));
    this.formGroup.get('clinic_share').setValue(this.clinicShare.toFixed(2));
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }

  close() {
    this.dialogRef.close();
  }

  goBack() {
    if(this.formGroup.get('option').value == '') {
      this.dialogRef.close();
    } else {
      this.isAdd = false;
      this.isSubmit = true;
    }
  }

  add() {
    if(this.formGroup.get('option').value !== '') {
      if(this.hasDentist) {
        for(var i = 0; i < this.procedures.length; i++) {
          if(this.procedures[i].procedure == '' || this.procedures[i].procedure == null) {
            this.dialogConfig.data = {
              title: 'Oops!',
              message: 'Treatment/Procedure field should not be empty.',
              button: 'Okay',
              event: this.close
            };
            let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
            return false;
          } else if(this.procedures[i].procedure_fee == '' || this.procedures[i].procedure_fee == null) {
            this.dialogConfig.data = {
              title: 'Oops!',
              message: 'Treatment/Procedure fee should not be empty.',
              button: 'Okay',
              event: this.close
            };
            let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
            return false;
          }

          for(var j = 0; j < this.procedures[i].items.length; j++) {
            if(this.procedures[i].items[j].item_id == '' || this.procedures[i].items[j].item_id == null) {
              this.dialogConfig.data = {
                title: 'Oops!',
                message: 'Item used should not be empty.',
                button: 'Okay',
                event: this.close
              };
              let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
              return false;
            } else if(this.procedures[i].items[j].item_qty == '' || this.procedures[i].items[j].item_qty == null) {
              this.dialogConfig.data = {
                title: 'Oops!',
                message: 'Item quantity should not be empty.',
                button: 'Okay',
                event: this.close
              };
              let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
              return false;
            } else if(this.procedures[i].items[j].item_amount == '' || this.procedures[i].items[j].item_amount == null) {
              this.dialogConfig.data = {
                title: 'Oops!',
                message: 'Item amount should not be empty.',
                button: 'Okay',
                event: this.close
              };
              let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
              return false;
            } else if(this.procedures[i].items[j].qty <= 0 || (this.procedures[i].items[j].item_qty - this.data.data.billing_detail_procedures[i].items[j].item_qty) > this.procedures[i].items[j].qty) {
              this.dialogConfig.data = {
                title: 'Oops!',
                message: 'Item used is not available.',
                button: 'Okay',
                event: this.close
              };
              let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
              return false;
            }
          }

          /** Set item name instead of id for viewing */
          this.procedures[i].items.forEach(el => {
            if(typeof el.item_id === 'string') {
              this.data.data.billing_detail_procedures[i].items.forEach(x => {
                if(x.id == el.id) {
                  el.item_id = x.item_id;
                }
              });
            } 
          });
        }

        this.isSubmit = true;
        this.isAdd = false;
        this.formGroup.controls['option'].disable();
        this.label = 'Add Patient Bill';
      
        let total = 0;
        let data = {
          "dentist_id": this.dentistId,
          "dentist_name": this.dentistName,
          "procedure": this.procedures,
          "total_fee": this.formGroup.value.total_fees ? parseFloat(this.formGroup.value.total_fees).toFixed(2) : 0,
          "lab_fee": this.formGroup.value.lab_fee ? Number(this.formGroup.value.lab_fee) : 0,
          "discount": this.formGroup.value.discount ? Number(this.formGroup.value.discount) : 0,
          "total_shares_percent": this.formGroup.value.share_percentage ? Number(this.formGroup.value.share_percentage) : 0
        }
        if(this.isUpdate) {
          this.detail[this.arrayIndex] = data;
        } else {
          this.detail.push(data);
        }
  
        if(this.detail.length === 0) {
          this.isNoResult = true;
        } else {
          this.isNoResult = false;
        }
  
        this.detail.forEach(element => {
          total += Number(element.total_fee);
        });
        this.totalBill = +total;

        if(this.button == 'Save') {
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Procedure details have been successfully saved.',
            button: 'Okay',
            event: this.close
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        } else {
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Procedure details have been successfully updated.',
            button: 'Okay',
            event: this.close
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }

        return true; 
      } else {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'Please select a dentist first.',
          button: 'Okay',
          event: this.close
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    } else {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Please select an option.',
        button: 'Okay',
        event: this.close
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }
  }

  update() {
    if(this.data.option == 'procedure') {
      let data = {
        "id": this.data.data.patient_billing_id,
        "option": this.formGroup.get('option').value,
        "user_clinic_id": this.clinic.id,
        "patient_id": +this.data.id,
        "detail": this.detail,
        "billing_detail_id": this.data.data.id,
      }
      this.patients.editPatientBill(this.data.data.patient_billing_id, data).subscribe(res => {
        if(res) {
          this.dialogRef.close(res);
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Patient bill has been successfully updated.',
            button: 'Okay',
            event: this.close
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Sorry!',
          message: err.error.message,
          button: 'Okay',
          event: this.close
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }));
    } else if(this.data.option == 'payment') {
      let data = {
        "option": this.formGroup.get('option').value,
        "user_clinic_id": this.clinic.id,
        "patient_id": +this.data.id,
        "amount_paid": +this.formGroup.get('amount_paid').value,
        "payment_detail": this.formGroup.get('payment_detail').value
      }
      if(['', null, undefined].indexOf(this.formGroup.value.payment_detail) !== -1 && ['', null, undefined].indexOf(this.formGroup.value.amount_paid) !== -1) {
        this.dialogConfig.data = {
          title: 'Sorry!',
          message: 'Payment fields are required.',
          button: 'Okay',
          event: this.close
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        return false;
      } else if(this.formGroup.value.payment_detail == '' || this.formGroup.value.payment_detail == null || this.formGroup.value.payment_detail == undefined) {
        this.dialogConfig.data = {
          title: 'Sorry!',
          message: 'Payment detail field is required.',
          button: 'Okay',
          event: this.close
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        return false;
      } else if(this.formGroup.value.amount_paid == '' || this.formGroup.value.amount_paid == null || this.formGroup.value.amount_paid == undefined) {
        this.dialogConfig.data = {
          title: 'Sorry!',
          message: 'Amount paid field is required.',
          button: 'Okay',
          event: this.close
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        return false;
      }
  
      this.patients.editPayment(this.data.data.id, data).subscribe(res => {
        if(res) {
          this.dialogRef.close(res);
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Payment has been successfully updated.',
            button: 'Okay',
            event: this.close
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Sorry!',
          message: err.error.message,
          button: 'Okay',
          event: this.close
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }));
      return true;
    }
  }

  addDentist() {
    this.label = 'Add Patient Bill';
    this.button = 'Save';
    this.isAdd = true;
    this.isSubmit = false;
    this.isUpdate = false;
    this.dentistId = '';
    this.dentistName = '';

    this.resetFields();
  }
  
  edit(res, index) {
    let total = 0;
    let lab_fee;
    let discount;
    this.arrayIndex = index;
    this.label = 'Update Patient Bill';
    this.button = 'Update';
    this.isAdd = true;
    this.isSubmit = false;
    this.formGroup.controls['option'].disable();

    this.dashboard.getDentistInfo(res.dentist_id, this.clinic.id).subscribe(res => {
      if(res) {
        this.dentistControl.setValue(
          {
            id: res[0].id, 
            firstName: res[0].first_name, 
            lastName: res[0].last_name
          }
        );
        this.dentistId = res[0].id;
        this.dentistName = res[0].first_name + (res[0].middle_name ? ' ' + res[0].middle_name + ' ' : ' ') + res[0].last_name;
      }
    });
    this.procedures.forEach(element => {
      element.procedure_fee = parseFloat(element.procedure_fee).toFixed(2);
      total += Number(element.total_item_cost);

      element.items.forEach(x => {
        x.item_amount = parseFloat(x.item_amount).toFixed(2);
      });
    });
    this.totalItemCost = +total;
    this.procedures = res.procedure;
    this.selectedPercentage = res.total_shares_percent;
    lab_fee = parseFloat(res.lab_fee).toFixed(2);
    discount = parseFloat(res.discount).toFixed(2);
    this.formGroup.get('lab_fee').setValue(lab_fee);
    this.formGroup.get('discount').setValue(discount);
    this.formGroup.get('share_percentage').setValue(res.total_shares_percent);
    this.formGroup.get('total_item_cost').setValue(this.totalItemCost.toFixed(2));
    
    if(this.detail[index].dentist_id == res.dentist_id) {
      this.isUpdate = true;
    } else {
      this.isUpdate = false;
    } 
    this.getTotalBill();

    /** Set item name instead of id for viewing */
    for(var i = 0; i < this.procedures.length; i++) {
      if(this.procedures[i].items.length !== 0) {
        this.procedures[i].items.forEach(el => {
          el.item_id = (el.item_name ? el.item_name : '') + (el.item_brandname ? ' - ' + el.item_brandname : '') + (el.item_description ? ' - ' + el.item_description : '');
        });
      }
    }
  }

  delete(index) {
    this.dialogConfig.data = {
      subject: 'delete this procedure details'
    };
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    let dialogRef = this.dialog.open(ConfirmDeletionComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
      if(trigger) {
        this.detail.splice(index, 1);
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Procedure details have been successfully deleted.',
          button: 'Okay',
          event: this.close
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);

        let total = 0;
        if(this.detail.length === 0) {
          this.isNoResult = true;
        } else {
          this.isNoResult = false;
        }

        this.detail.forEach(element => {
          total += Number(element.total_fee);
        });
        this.totalBill = +total;
      }
    });
  }

  resetFields() {
    this.initProcedure();
    this.searchTreatment = this._treatments('');
    this.searchItem = this._items('');
    this.hasDentist = false;
    this.dentistId = '';
    this.dentistName = '';
    this.selectedPercentage = 0;
    this.dentistControl.setValue('');
    this.formGroup.get('dentist_id').setValue('');
    this.formGroup.get('total_fees').setValue('');
    this.formGroup.get('lab_fee').setValue('');
    this.formGroup.get('discount').setValue('');
    this.formGroup.get('sub_total').setValue('');
    this.formGroup.get('doctors_fee').setValue('');
    this.formGroup.get('clinic_share').setValue('');
    this.formGroup.get('share_percentage').setValue('');
    this.formGroup.get('total_item_cost').setValue('');
    this.formGroup.get('selected_procedure').setValue('');
  }

  setTwoNumberDecimal(el){
    el.target.value = parseFloat(el.target.value).toFixed(2);
  }

  deleteDetail() {
    if(this.data.option == 'procedure') {
      this.dialogConfig.data = {
        subject: 'delete this billing details'
      };
      this.dialogConfig.disableClose = true;
      this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
      let dialogRef = this.dialog.open(ConfirmDeletionComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
        if(trigger) {
          this.patients.removePatientBill(this.data.data.id + '?type=procedure').subscribe(res => {
            if(res) {
              this.dialogRef.close(res);
              this.dialogConfig.data = {
                title: 'Success!',
                message: 'Patient bill details have been successfully deleted.',
                button: 'Okay',
                event: this.close
              };
              let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
            }
          });
        }
      });
    } else if(this.data.option == 'payment') {
      this.dialogConfig.data = {
        subject: 'delete this payment details'
      };
      this.dialogConfig.disableClose = true;
      this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
      let dialogRef = this.dialog.open(ConfirmDeletionComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
        if(trigger) {
          this.patients.removePatientBill(this.data.data.patient_billing_id + '?type=payment').subscribe(res => {
            if(res) {
              this.dialogRef.close(res);
              this.dialogConfig.data = {
                title: 'Success!',
                message: 'Payment details have been successfully deleted.',
                button: 'Okay',
                event: this.close
              };
              let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
            }
          });
        }
      });
    }
  }
  
  onKeydown(event) {
    if(event.keyCode == 8 || event.keyCode == 46) {
      this.hasDentist = false;
    } 
  }
}
