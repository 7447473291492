import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'ark-confirm-update',
  templateUrl: './confirm-update.component.html',
  styleUrls: ['./confirm-update.component.scss']
})
export class ConfirmUpdateComponent implements OnInit {

  subject: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmUpdateComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.subject = this.data.subject;
  }

  close(flag = false) {
    this.dialogRef.close(flag);
  }
}
