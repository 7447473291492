import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { keypressValidateNumber } from 'src/app/common/helper'
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { Observable } from 'rxjs';
import { MatAutocompleteTrigger, MatDialogConfig, MatDialog } from '@angular/material';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard/dashboard.service';
import { startWith, map } from 'rxjs/operators';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import * as moment from 'moment';
import { DentalFormsService } from '../../../services/dental-forms/dental-forms.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

export interface Patient {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
}

export interface Dentist {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
}

@Component({
  selector: 'ark-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss']
})
export class CertificatesComponent implements OnInit {

  formGroup: FormGroup;
  isEmpty: boolean = false;
  errorMessage = null;
  clinic = null;
  clinicName = null;
  patientId = '';
  dentistId = '';
  patientName = '';
  dentistName = '';
  patientControl = new FormControl();
  dentistControl = new FormControl();
  patients: Patient[] = [];
  dentists: Dentist[] = [];
  filteredPatients: Observable<Patient[]>;
  filteredDentists: Observable<Dentist[]>;
  @ViewChild('patient') patient: MatAutocompleteTrigger;
  @ViewChild('dentist') dentist: MatAutocompleteTrigger;

  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private dashboard: DashboardService,
    private patientDirectory: PatientsDirectoryService,
    private forms: DentalFormsService,
    public dialog: MatDialog,
  ) {
    this.clinic = this.auth.getAuthUser().user_clinic;
    this.dashboard.getPatientList(this.clinic.id, this.clinic.id).subscribe(res => {
      res.data.forEach(element => {
        let obj: Patient = {
          id: element.id, 
          firstName: element.first_name, 
          middleName: element.middle_name ? element.middle_name : '', 
          lastName: element.last_name
        };
        this.patients.push(obj);
      });
    });
    this.dashboard.getDentistList(this.clinic.id).subscribe(res => {
      res.forEach(element => {
        let obj: Dentist = {
          id: element.id, 
          firstName: element.first_name,
          lastName: element.last_name,
          middleName: element.middle_name ? element.middle_name : '', 
        };
        this.dentists.push(obj);
      });
    });
    this.formGroup = this.formBuilder.group({
      address: [''],
      telephone_no: [''],
      mobile_no: [''],
      clinic_hours: [''],
      website: [''],
      email: [''],
      patient_name: [''],
      patient_id: new FormControl('', [Validators.required]),
      age: [''],
      doctor_name: [''],
      dentist_id: new FormControl('', [Validators.required]),
      prc_no: ['', [Validators.required]],
      ptr_no: [''],
      findings: [''],
      treatments: ['', [Validators.required]],
      recommendations: [''],
      date: [''],
    });
    this.formGroup.get('address').setValue(this.clinic.address ? this.clinic.address : '');
    this.formGroup.get('telephone_no').setValue(this.clinic.contact_number ? this.clinic.contact_number : '');
    this.formGroup.get('mobile_no').setValue(this.clinic.mobile_number ? this.clinic.mobile_number : '');
    this.formGroup.get('clinic_hours').setValue(this.clinic.clinic_hours ? this.clinic.clinic_hours : '');
    this.formGroup.get('website').setValue(this.clinic.website ? this.clinic.website : '');
    this.formGroup.get('email').setValue(this.clinic.email ? this.clinic.email : '');
    
    if(this.formGroup.value.website == null) {
      this.isEmpty = true;
    } else {
      this.isEmpty = false;
    }
    this.clinicName = this.clinic.name ? this.clinic.name : ''
  }

  ngOnInit() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.filteredPatients = this.formGroup.get('patient_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterPatient(name) : this.patients.slice()),
      );
    this.filteredDentists = this.formGroup.get('dentist_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterDentist(name) : this.dentists.slice())
      );
  }

  getPatient(event) {
    this.patientId = event.option.value.id;
    this.patientName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.patientDirectory.getPatientInfo(this.clinic.id, event.option.value.id, this.clinic.id).subscribe(res => {
      if(res) {
        let age = moment().diff(res.data[0].patient_private_information.birthdate, 'years');
        this.formGroup.get('age').setValue(age);
      }
    });
    this.formGroup.get('patient_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
  }

  getDentist(event) {
    this.dentistId = event.option.value.id;
    this.dentistName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.dashboard.getDentistInfo(event.option.value.id, this.clinic.id).subscribe(res => {
      if(res) {
        this.formGroup.get('prc_no').setValue(res[0].user.prc_license_no ? res[0].user.prc_license_no : '');
        this.formGroup.get('ptr_no').setValue(res[0].user.ptr_no ? res[0].user.ptr_no : '');  
      }
    });
    this.formGroup.get('dentist_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
  }

  private _filterPatient(name: string): Patient[] {
    const filterValue = name.toLowerCase();
    return this.patients.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1 || option.middleName.toLowerCase().indexOf(filterValue) > -1);
  }

  private _filterDentist(name: string): Dentist[] {
    const filterValue = name.toLowerCase();
    return this.dentists.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1);
  }

  displayDentist(dentist?: any) : string | undefined {
    return dentist ? 'Dr. ' + dentist.firstName + (dentist.middleName ? ' ' + dentist.middleName + ' ' : ' ') + dentist.lastName : undefined;
  }

  displayPatient(patient?: any) : string | undefined {
    return patient ? patient.lastName + ', ' + patient.firstName + (patient.middleName ? ' ' + patient.middleName : ' ') : undefined;
  }

  onFocusPatient() {
    this.patient._onChange("");
    this.patient.openPanel();
  }

  onFocusDentist() {
    this.dentist._onChange(""); 
    this.dentist.openPanel();
  }

  download() {
    const date = this.formGroup.value.date ? moment(this.formGroup.value.date).format('MM/DD/YYYY') : '';
    const FORM = {
      user_clinic_id: this.clinic.id,
      clinic_address: this.formGroup.value.address, 
      telephone_no: this.formGroup.value.telephone_no.toString(), 
      mobile_no: this.formGroup.value.mobile_no.toString(),
      clinic_hours: this.formGroup.value.clinic_hours,
      website: this.formGroup.value.website,
      email: this.formGroup.value.email,
      to: this.patientName,
      age: this.formGroup.value.age,
      date: date,
      findings: this.formGroup.value.findings,
      treatments: this.formGroup.value.treatments,
      recommendations: this.formGroup.value.recommendations,
      doctor_name: this.dentistName,
      prc_no: this.formGroup.value.prc_no,
      ptr_no: this.formGroup.value.ptr_no,
      patient_id: this.patientId,
      dentist_id: this.dentistId
    };
    this.forms.downloadCertificate(FORM).subscribe(res => {
      if(res) {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(res);
        a.download = moment().format("YYYYMMDD") + '-DENTAL CERTIFICATE.pdf';
        document.body.appendChild(a);
        a.click();
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Exporting of PDF failed. Please fill-in all the required fields.',
        button: 'Okay',
        event: this.close
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      const TREATMENTS_REQUIRED = this.formGroup.value.treatments == '' ? 'The treatments field is required.' : '';
      const PRC_REQUIRED = this.formGroup.value.prc_no == '' ? 'The prc number field is required.' : '';
      const DOCTOR_REQUIRED = this.formGroup.get('dentist_id').value == '' ? 'The dentist name field is required.' : '';
      const PATIENT_REQUIRED = this.formGroup.get('patient_id').value == '' ? 'The patient name field is required.' : '';
      const DOCTOR_INVALID = typeof this.formGroup.get('dentist_id').value == 'string' ? 'The dentist name is invalid.' : '';
      const PATIENT_INVALID = typeof this.formGroup.get('patient_id').value == 'string' ? 'The patient name is invalid.' : '';
      this.errorMessage = {
        treatments: TREATMENTS_REQUIRED,
        prc_no: PRC_REQUIRED,
        dentist_id: DOCTOR_REQUIRED == '' ? DOCTOR_INVALID : DOCTOR_REQUIRED,
        patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
      };
      this.triggerErrors({
        treatments: TREATMENTS_REQUIRED,
        prc_no: PRC_REQUIRED,
        dentist_id: DOCTOR_REQUIRED == '' ? DOCTOR_INVALID : DOCTOR_REQUIRED,
        patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
      });
    }));
  }

  print() {
    const date = this.formGroup.value.date ? moment(this.formGroup.value.date).format('MM/DD/YYYY') : '';
    const FORM = {
      user_clinic_id: this.clinic.id,
      clinic_address: this.formGroup.value.address, 
      telephone_no: this.formGroup.value.telephone_no.toString(), 
      mobile_no: this.formGroup.value.mobile_no.toString(),
      clinic_hours: this.formGroup.value.clinic_hours,
      website: this.formGroup.value.website,
      email: this.formGroup.value.email,
      to: this.patientName,
      age: this.formGroup.value.age,
      date: date,
      findings: this.formGroup.value.findings,
      treatments: this.formGroup.value.treatments,
      recommendations: this.formGroup.value.recommendations,
      doctor_name: this.dentistName,
      prc_no: this.formGroup.value.prc_no,
      ptr_no: this.formGroup.value.ptr_no,
      patient_id: this.patientId,
      dentist_id: this.dentistId
    };
    this.forms.downloadCertificate(FORM).subscribe(res => {
      if(res) {
        const url = URL.createObjectURL(res);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = url;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Print Failed. Please fill-in all the required fields.',
        button: 'Okay',
        event: this.close
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      const TREATMENTS_REQUIRED = this.formGroup.value.treatments == '' ? 'The treatments field is required.' : '';
      const PRC_REQUIRED = this.formGroup.value.prc_no == '' ? 'The prc number field is required.' : '';
      const DOCTOR_REQUIRED = this.formGroup.get('dentist_id').value == '' ? 'The dentist name field is required.' : '';
      const PATIENT_REQUIRED = this.formGroup.get('patient_id').value == '' ? 'The patient name field is required.' : '';
      const DOCTOR_INVALID = typeof this.formGroup.get('dentist_id').value == 'string' ? 'The dentist name is invalid.' : '';
      const PATIENT_INVALID = typeof this.formGroup.get('patient_id').value == 'string' ? 'The patient name is invalid.' : '';
      this.errorMessage = {
        treatments: TREATMENTS_REQUIRED,
        prc_no: PRC_REQUIRED,
        dentist_id: DOCTOR_REQUIRED == '' ? DOCTOR_INVALID : DOCTOR_REQUIRED,
        patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
      };
      this.triggerErrors({
        treatments: TREATMENTS_REQUIRED,
        prc_no: PRC_REQUIRED,
        dentist_id: DOCTOR_REQUIRED == '' ? DOCTOR_INVALID : DOCTOR_REQUIRED,
        patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
      });
    }));
  }

  close() {
    this.dialogRef.close();
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }

  dataChecker(event) {
    this.dentistId = '';
    this.formGroup.controls['prc_no'].setValue('');
    this.formGroup.controls['ptr_no'].setValue('');
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }
}

