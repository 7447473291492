import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { SubscribeService } from '../../services/subscribe/subscribe.service';

@Component({
  selector: 'ark-subscription-form',
  templateUrl: './subscription-form.component.html',
  styleUrls: ['./subscription-form.component.scss']
})
export class SubscriptionFormComponent implements OnInit {

  errorMessage = null;
  success = false;
  password = true;
  confirmPassword = true;
  loading: boolean = false
  formGroup: FormGroup;
  subscription: '';

  dialogConfig = new MatDialogConfig();

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SubscriptionFormComponent>, 
    public dialog: MatDialog,
    private register: SubscribeService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.subscription = this.data.data.name;
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      verify_password: ['', Validators.required]
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  close() {
    this.dialogRef.close();
  }

  registerUser() {
    const data = {
      first_name: this.formGroup.value.first_name,
      last_name: this.formGroup.value.last_name,
      email: this.formGroup.value.email,
      password: this.formGroup.value.password,
      verify_password: this.formGroup.value.verify_password,
      plan: this.data.data.value
    }
    this.register.registerUser(data).subscribe(res => {
      if(res) {
        this.success = true;
        this.dialogRef.close(res);
        this.dialogConfig.data = {
          title: 'Congratulations!',
          message: 'You have registered successfully.\n\n An email has been sent to your inbox. \nIf you don\'t see the email, please check your spam folder.',
          button: 'Okay',
          event: this.close
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: err.error.message,
        button: 'Okay',
        event: this.close
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      this.errorMessage = err.error.errors;
      this.triggerErrors(err.error.errors);
    }));
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }
}
