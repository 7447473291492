import { Component, OnInit } from '@angular/core';
import { WebcamImage } from 'ngx-webcam';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'ark-patient-profile-camera',
  templateUrl: './patient-profile-camera.component.html',
  styleUrls: ['./patient-profile-camera.component.scss']
})
export class PatientProfileCameraComponent implements OnInit {

  public webcamImage: WebcamImage = null;
  constructor(
    public dialogRef: MatDialogRef<PatientProfileCameraComponent>,
  ) { }

  ngOnInit() {
  }

  cameraImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
    this.dialogRef.close(this.webcamImage.imageAsDataUrl);
  }

  getUploadedImage(event) {
    let data = {
      image: event,
      type: 1
    }
    this.dialogRef.close(data);
  }

  close() {
    this.dialogRef.close();
  }

}
