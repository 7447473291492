import { Component, OnInit, Input, OnChanges, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { CalendarEvent, CalendarDateFormatter, CalendarMonthViewDay } from 'angular-calendar';
import { CustomDateFormatter } from 'src/app/common/calendar-date-formatter.provider';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { DashboardService } from '../../../services/dashboard/dashboard.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { Subject } from 'rxjs';
import { SidebarService } from 'src/app/modules/core/services/sidebar/sidebar.service';

@Component({
  selector: 'ark-month-view',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './month-view.component.html',
  styleUrls: ['./month-view.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class MonthViewComponent implements OnInit, OnChanges {

  @Input() newAppointment: any = null;
  @Input() date: any = null;
  @Output() selected : EventEmitter<any> = new EventEmitter<any>();
  isSidebarMinimized = false
  view: string = 'month';
  events: CalendarEvent[];
  selectedEvents = [];
  refresh: Subject<any> = new Subject();
  clinic = '';
  month = '';
  appointments = null;
  datePipe = new DatePipe('en-US');
  appointment: Object = {};
  doctors = [];
  response = [];
  d_list = [];
  c_list = {};
  colors = [
    '#FEA47F',
    '#25CBF7',
    '#EAB543',
    '#54E6C1',
    '#CAD3C8',
    '#F97F51',
    '#1B9CFC',
    '#F8EFB9',
    '#58B09F',
    '#2C3A47',
    '#B33771',
    '#3A3B98',
    '#FD7272',
    '#9AECDB',
    '#D6A2E8',
    '#6D204F',
    '#182B61',
    '#FC427B',
    '#BDC581',
    '#000000'
  ];

  constructor(
    private dashboard: DashboardService,
    private auth: AuthService,
    private sidebarService: SidebarService
  ) {
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    if(localStorage.getItem('isSidebarMinimized')) {
      this.isSidebarMinimized = JSON.parse(localStorage.getItem('isSidebarMinimized'));
    }
    this.sidebarService.sidebarState.subscribe((nextValue) => {
      this.isSidebarMinimized = nextValue
    });
    this.d_list = JSON.parse(localStorage.getItem('d-list'));
    this.c_list = JSON.parse(localStorage.getItem('c-collections'));
  }

  ngOnInit() {

  }

  ngOnChanges() {
    if(this.date) {
      this.month = moment(this.date, 'MMMM YYYY').format('MMMM');
      this.viewMonthlyAppointments();
    } else if(this.newAppointment) {
      this.viewMonthlyAppointments();
    }
  }

  getSchedule(data) {
    this.doctors = [];
    this.events = [];
    this.response = [];
    data.forEach(element => {
      this.appointment[element.id] = element.patient_appointments;
      this.doctors.push(element.id);
    });

    for(let d of this.doctors) {
      this.appointment[d].forEach(element => {
        this.response.push(element);
      });
    }

    let obj: Array<any> = [];
    for (let i = 0; i < this.response.length; i++) {
      this.events = [];
      const { dentist_id } = this.response[i];
      const dName = this.d_list.filter(d => d.id == dentist_id)[0];
      if (dName) {
        let event: Object = {
          title: this.response[i]['procedure'],
          dentist: {id: dentist_id , name: dName.last_name},
          color: {primary: this.c_list[dentist_id], secondary: 'transparent'},
          start: new Date(this.response[i]['appointment_date'])
        }
        const current = moment(event['start']).format('L');
        const include = obj.findIndex(item => {
          const start = moment(item.start).format('L');
          return item.dentist.id === dentist_id && start === current;
        });
        
        if (include == -1) {
          obj.push(event);
        } else if (obj[include]) {
          const date = moment(obj[include].start).format('L');
          if (date !== current) {
            obj.push(event);
          }
        }
      }
    }
    this.events = obj;
    // this.refresh.next();
  }

  viewMonthlyAppointments() {
    this.dashboard.viewMonthlyAppointments(this.clinic, this.month).subscribe(res => {
      if(res) {
        this.getSchedule(res);
      }
    });
  }


  eventHandler(e, more = false) {
    let current = null;
    if (more) {
      current = moment(e).format('L');
    } else {
      current = moment(e.start).format('L');
    }
    const aList = this.events.filter(a => {
      const start = moment(a.start).format('L');
      return start === current;
    });
    this.selectedEvents = aList;
  }

  getEvent(e) {
    localStorage.setItem('selectedApp', JSON.stringify(e));
    this.selected.emit(e);
  }
}
