import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/modules/shared/services/http/http.service';
import { patientAppointment, getPatient, getProcedures, userClinicMember } from 'src/app/common/api';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends HttpService{

  constructor(http: HttpClient, private _router: Router) { 
    super('', http);
  }

  createAppointment(data) {
    return this.http.post(patientAppointment, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  deleteAppointment(id, user_clinic_id) {
    return this.http.delete(patientAppointment + '/' + id + '?user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }

  updateAppointment(id, data) {
    return this.http.put(patientAppointment + '/' + id, data).map((dataResponse) => {
      return dataResponse;
    })
  }

  getDentistInfo(id, user_clinic_id) {
    return this.get(userClinicMember + '/dentist/' + id + '?user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }

  getDentistList(id) {
    return this.get(userClinicMember + '/dentist/list' + '?user_clinic_id=' + id + '&status=active').map(res => {
      return res['data'];
    });
  }

  getPatientName(id, name) {
    return this.get(getPatient + '/filter/' + id + '?name=' + name).map(res => {
      return res['data'];
    });
  }

  getPatientList(id, user_clinic_id) {
    return this.http.get(getPatient + '/filter/' + id + '?user_clinic_id=' + user_clinic_id + '&patients=all').map(res => {
      return res['data'];
    });
  }

  viewAppointmentsToday(id, date) {
    return this.get(patientAppointment + '?user_clinic_id=' + id + '&filter=date&date=' + date + '&status=active').map(res => {
      return res['data'];
    });
  }

  viewWeeklyAppointments(id, dentist_id, start_week, end_week) {
    return this.get(patientAppointment + '?user_clinic_id=' + id + '&filter=week&dentist_id=' + dentist_id + '&start_week=' + start_week + '&end_week=' + end_week).map(res => {
      return res['data'];
    });
  }

  viewMonthlyAppointments(id, month) {
    return this.get(patientAppointment + '?user_clinic_id=' + id + '&filter=month&month=' + month + '&status=active').map(res => {
      return res['data'];
    });
  }

  getCalendarView(id, filter) {
    return this.get(patientAppointment + '?user_clinic_id=' + id + '&filter=' + filter).map(res => {
      return res['data'];
    });
  }

  getProcedures(id) {
    return this.get(getProcedures + '?user_clinic_id=' + id).map(res => {
      return res['data'];
    });
  }
}
