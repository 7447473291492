import 'rxjs/add/operator/catch';

import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from 'src/app/modules/shared/services/loader/loader.service';
import { finalize, catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    public auth: AuthService, 
    public loader: LoaderService,
    private _router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        'Authorization': `Bearer ${this.auth.getToken()}`,
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/json'
      }
    });
    this.loader.show();
    return next.handle(request).pipe(
      finalize(() => this.loader.hide()),
      catchError(err => {
        return Observable.throw(err);
      })
    );
  }
}