import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientProfileComponent } from './components/patient-profile/patient-profile.component';
import { PatientBillingComponent } from './components/patient-billing/patient-billing.component';
import { LaboratoryWorksComponent } from './components/laboratory-works/laboratory-works.component';
import { InventoryComponent } from './components/inventory/inventory.component';
import { DirectoryComponent } from './components/directory/directory.component';
import { SharedModule } from '../shared/shared.module';
import { MatFormFieldModule, MatInputModule, MatCheckboxModule, MatButtonModule, MatIconModule, MatAutocompleteModule, MatRadioModule, MatDatepickerModule, MatNativeDateModule, MatSelectModule, MatChipsModule } from '@angular/material';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PatientService } from './service/patient/patient.service';
import { PatientNameComponent } from './components/patient-profile/general-info/patient-name/patient-name.component';
import { PrivateInformationComponent, KeysPipe } from './components/patient-profile/general-info/private-information/private-information.component';
import { EmergencyContactComponent } from './components/patient-profile/general-info/emergency-contact/emergency-contact.component';
import { DentalHistoryComponent } from './components/patient-profile/general-info/dental-history/dental-history.component';
import { AlertMessageComponent } from '../shared/components/alert-message/alert-message.component';
import { BillingComponent } from './components/patient-billing/billing/billing.component';
import { BalancesComponent } from './components/patient-billing/balances/balances.component';
import { SummaryReportComponent } from './components/patient-billing/summary-report/summary-report.component';
import { MasterFileComponent } from './components/inventory/tabs/master-file/master-file.component';
import { CheckOutComponent } from './components/inventory/tabs/check-out/check-out.component';
import { InventoryLogsComponent } from './components/inventory/tabs/inventory-logs/inventory-logs.component';
import { AddItemComponent } from './components/inventory/tabs/master-file/functions/add-item/add-item.component';
import { AddStocksComponent } from './components/inventory/tabs/master-file/functions/add-stocks/add-stocks.component';
import { InventoryControlComponent } from './components/inventory/tabs/master-file/functions/inventory-control/inventory-control.component';
import { ManageCategoriesComponent } from './components/inventory/tabs/master-file/functions/manage-categories/manage-categories.component';
import { HistoryComponent } from './components/inventory/tabs/check-out/history/history.component';
import { ExpensesComponent } from './components/expenses/expenses.component';
import { MonthlyExpensesComponent } from './components/expenses/monthly-expenses/monthly-expenses.component';
import { AnnualExpensesComponent } from './components/expenses/annual-expenses/annual-expenses.component';
import { AnnualExpensesSummaryComponent } from './components/expenses/annual-expenses-summary/annual-expenses-summary.component';
import { MonthlyExpensesSummaryComponent } from './components/expenses/monthly-expenses-summary/monthly-expenses-summary.component';
import { OperationalExpensesComponent } from './components/expenses/monthly-expenses/operational-expenses/operational-expenses.component';
import { DirectCostComponent } from './components/expenses/monthly-expenses/direct-cost/direct-cost.component';
import { DoctorFeeComponent } from './components/expenses/monthly-expenses/doctor-fee/doctor-fee.component';
import { AddCategoryComponent } from './components/inventory/tabs/master-file/functions/manage-categories/add-category/add-category.component';
import { ExpensesService } from './service/expenses/expenses.service';
import { AddOperationalExpensesComponent } from './components/expenses/monthly-expenses/operational-expenses/add-operational-expenses/add-operational-expenses.component';
import { EditOperationalExpensesComponent } from './components/expenses/monthly-expenses/operational-expenses/edit-operational-expenses/edit-operational-expenses.component';
import { LogDetailsComponent } from './components/inventory/tabs/inventory-logs/log-details/log-details.component';
import { AddCheckOutComponent } from './components/inventory/tabs/check-out/add-check-out/add-check-out.component';
import { DentistsComponent } from './components/directory/dentists/dentists.component';
import { MedicalDoctorsComponent } from './components/directory/medical-doctors/medical-doctors.component';
import { SuppliersComponent } from './components/directory/suppliers/suppliers.component';
import { LaboratoryComponent } from './components/directory/laboratory/laboratory.component';
import { DiagnosticCenterComponent } from './components/directory/diagnostic-center/diagnostic-center.component';
import { DirectoryService } from './service/directory/directory.service';
import { AddDentistDirectoryComponent } from './components/directory/dentists/add-dentist-directory/add-dentist-directory.component';
import { EditDentistDirectoryComponent } from './components/directory/dentists/edit-dentist-directory/edit-dentist-directory.component';
import { AddDiagnosticCenterDirectoryComponent } from './components/directory/diagnostic-center/add-diagnostic-center-directory/add-diagnostic-center-directory.component';
import { EditDiagnosticCenterDirectoryComponent } from './components/directory/diagnostic-center/edit-diagnostic-center-directory/edit-diagnostic-center-directory.component';
import { AddLaboratoryDirectoryComponent } from './components/directory/laboratory/add-laboratory-directory/add-laboratory-directory.component';
import { EditLaboratoryDirectoryComponent } from './components/directory/laboratory/edit-laboratory-directory/edit-laboratory-directory.component';
import { AddMedicalDoctorsDirectoryComponent } from './components/directory/medical-doctors/add-medical-doctors-directory/add-medical-doctors-directory.component';
import { EditMedicalDoctorsDirectoryComponent } from './components/directory/medical-doctors/edit-medical-doctors-directory/edit-medical-doctors-directory.component';
import { AddSuppliersNumberComponent } from './components/directory/suppliers/add-suppliers-number/add-suppliers-number.component';
import { EditSuppliersNumberComponent } from './components/directory/suppliers/edit-suppliers-number/edit-suppliers-number.component';
import { AddLabWorksComponent } from './components/laboratory-works/add-lab-works/add-lab-works.component';
import { LaboratoryWorksService } from './service/laboratory-works/laboratory-works.service';
import { EditLabWorksComponent } from './components/laboratory-works/edit-lab-works/edit-lab-works.component';
import { PatientProfileCameraComponent } from './components/patient-profile/general-info/patient-name/patient-profile-camera/patient-profile-camera.component';
import { WebcamModule } from 'ngx-webcam';
import { PatientCameraComponent } from './components/patient-profile/general-info/patient-name/patient-profile-camera/patient-camera/patient-camera.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DentistShareComponent } from '../shared/components/dentist-share/dentist-share.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  // suppressScrollX: true
}
@NgModule({
  declarations: [
    PatientProfileComponent,
    PatientBillingComponent,
    LaboratoryWorksComponent,
    InventoryComponent,
    DirectoryComponent,
    PatientNameComponent,
    PrivateInformationComponent,
    EmergencyContactComponent,
    DentalHistoryComponent,
    KeysPipe,
    ExpensesComponent,
    BillingComponent,
    BalancesComponent,
    SummaryReportComponent,
    MasterFileComponent,
    CheckOutComponent,
    InventoryLogsComponent,
    AddItemComponent,
    AddStocksComponent,
    InventoryControlComponent,
    ManageCategoriesComponent,
    HistoryComponent,
    ExpensesComponent,
    MonthlyExpensesComponent,
    AnnualExpensesComponent,
    AnnualExpensesSummaryComponent,
    MonthlyExpensesSummaryComponent,
    OperationalExpensesComponent,
    DirectCostComponent,
    DoctorFeeComponent,
    AddCategoryComponent,
    AddOperationalExpensesComponent,
    EditOperationalExpensesComponent,
    LogDetailsComponent,
    AddCheckOutComponent,
    DentistsComponent,
    MedicalDoctorsComponent,
    SuppliersComponent,
    LaboratoryComponent,
    DiagnosticCenterComponent,
    AddDentistDirectoryComponent,
    EditDentistDirectoryComponent,
    AddDiagnosticCenterDirectoryComponent,
    EditDiagnosticCenterDirectoryComponent,
    AddLaboratoryDirectoryComponent,
    EditLaboratoryDirectoryComponent,
    AddMedicalDoctorsDirectoryComponent,
    EditMedicalDoctorsDirectoryComponent,
    AddSuppliersNumberComponent,
    EditSuppliersNumberComponent,
    AddLabWorksComponent,
    EditLabWorksComponent,
    PatientProfileCameraComponent,
    PatientCameraComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatChipsModule,
    FormsModule,
    WebcamModule,
    PerfectScrollbarModule

  ],
  exports: [
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule
  ],
  providers: [
    PatientService,
    ExpensesService,
    DirectoryService,
    LaboratoryWorksService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
    
  ],
  entryComponents: [
    AlertMessageComponent,
    AddItemComponent,
    ManageCategoriesComponent,
    AddCategoryComponent,
    AddStocksComponent,
    LogDetailsComponent,
    AddCheckOutComponent,
    InventoryControlComponent,
    AddOperationalExpensesComponent,
    EditOperationalExpensesComponent,
    AddDentistDirectoryComponent,
    EditDentistDirectoryComponent,
    AddMedicalDoctorsDirectoryComponent,
    EditMedicalDoctorsDirectoryComponent,
    AddSuppliersNumberComponent,
    EditSuppliersNumberComponent,
    AddLaboratoryDirectoryComponent,
    EditLaboratoryDirectoryComponent,
    AddDiagnosticCenterDirectoryComponent,
    EditDiagnosticCenterDirectoryComponent,
    AddLabWorksComponent,
    EditLabWorksComponent,
    PatientProfileCameraComponent,
    DentistShareComponent
  ]
})
export class FrontDeskModule { }
