import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AddLabWorksComponent } from './add-lab-works/add-lab-works.component';
import { LaboratoryWorksService } from '../../service/laboratory-works/laboratory-works.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { ConfirmDeletionComponent } from 'src/app/modules/shared/components/confirm-deletion/confirm-deletion.component';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { EditLabWorksComponent } from './edit-lab-works/edit-lab-works.component';
import { SidebarService } from 'src/app/modules/core/services/sidebar/sidebar.service';

@Component({
  selector: 'ark-laboratory-works',
  templateUrl: './laboratory-works.component.html',
  styleUrls: ['./laboratory-works.component.scss']
})
export class LaboratoryWorksComponent implements OnInit {

  today = new Date(); 
  formGroup : FormGroup;
  start : any;
  end : any;
  dialogConfig = new MatDialogConfig();
  dialogRef : any;
  loading : boolean = false;
  isNoResult : boolean = true;
  isAssistant : boolean = false;
  isSidebarMinimized : boolean = false;
  clinic = null;
  searchKeyword = '';
  results = null;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private laboratory: LaboratoryWorksService,
    private auth: AuthService,
    private sidebarService: SidebarService
  ) { 
    if(this.auth.getAuthUser().role_id == 'Assistant') {
      this.isAssistant = true;
    } else {
      this.isAssistant = false;
    }

    if(localStorage.getItem('isSidebarMinimized')) {
      this.isSidebarMinimized = JSON.parse(localStorage.getItem('isSidebarMinimized'));
    }
    this.sidebarService.sidebarState.subscribe((nextValue) => {
      this.isSidebarMinimized = nextValue
    });
  }

  ngOnInit() {
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.formGroup = this.formBuilder.group({
      from: [''],
      to: [''],
    });
    this.formGroup.get('from').setValue(this.today);
    this.formGroup.get('to').setValue(this.today);
    this.start = moment(this.formGroup.value.from).format("YYYY-MM-DD");
    this.end = moment(this.formGroup.value.to).format("YYYY-MM-DD");
    this.getLabWorks();
  }

  getLabWorks() {
    this.laboratory.getLabWork(this.clinic, this.start, this.end, this.searchKeyword).subscribe(res => {
      if(res) {
        this.results = res;
        if(res.length > 0) {
          this.isNoResult = false;
        } else {
          this.isNoResult = true;
        }
      }
    })
  }

  getText(val) {
    this.searchKeyword = val;
    this.getLabWorks();
  }

  getStartDate(val) {
    this.start = moment(val.value).format("YYYY-MM-DD");
    this.getLabWorks();
  }

  getEndDate(val) {
    this.end =  moment(val.value).format("YYYY-MM-DD");
    this.getLabWorks();
  }

  addLabWorks() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogRef = this.dialog.open(AddLabWorksComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(res => {
      if(res) {
        this.getLabWorks();
      }
    });
  }

  deleteLabWorks(id) {
    this.dialogConfig.data = {
      subject: 'delete this clinic log'
    };
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    let dialogRef = this.dialog.open(ConfirmDeletionComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
      if(trigger) {
        if(this.isAssistant == true) {
          this.dialogConfig.data = {
            title: 'Oops!',
            message: 'Deleting this clinic log is restricted.',
            button: 'Okay',
            event: this.close
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        } else {
          this.laboratory.deleteLabWork(id, this.clinic).subscribe(res => {
            if(res) {
              this.getLabWorks();
              this.dialogConfig.data = {
                title: 'Success!',
                message: 'Clinic log has been successfully removed.',
                button: 'Okay',
                event: this.close
              };
              let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
            }
          },(err => {
            this.dialogConfig.data = {
              title: 'Oops!',
              message: 'Deleting this clinic log is restricted.',
              button: 'Okay',
              event: this.close
            };
            let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
          }));
        }
      }
    });
  }

  editLabWorks(data) {
    if(this.isAssistant == true) {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Editing this clinic log is restricted.',
        button: 'Okay',
        event: this.close
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    } else {
      this.dialogConfig.disableClose = true;
      this.dialogConfig.autoFocus = false;
      this.dialogConfig.data = {
        data: data
      }
      this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
      this.dialogRef = this.dialog.open(EditLabWorksComponent, this.dialogConfig);
      this.dialogRef.afterClosed().subscribe(res => {
        if(res) {
          this.getLabWorks();
        }
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
} 
