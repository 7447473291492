import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { trigger } from '@angular/animations';

@Component({
  selector: 'ark-view-filter',
  templateUrl: './view-filter.component.html',
  styleUrls: ['./view-filter.component.scss']
})
export class ViewFilterComponent implements OnInit, OnChanges {

  @Input() clinicId: any;
  @Input() trigger: boolean = false;
  @Output() onClick = new EventEmitter<any>();
  @Output() dentistId = new EventEmitter<any>();
  @Output() color = new EventEmitter<any>();
  todayFilter = [{ name: 'Day', value: 'today' }];
  weekFilter = [{ name: 'Week', value: 'week' }];
  monthFilter = [{ name: 'Month', value: 'month' }];
  clinic = '';
  doctors = [];
  selected: string = 'today';
  index = null;
  checker = false;
  colors = [
    '#FEA47F',
    '#25CBF7',
    '#EAB543',
    '#54E6C1',
    '#CAD3C8',
    '#F97F51',
    '#1B9CFC',
    '#F8EFB9',
    '#58B09F',
    '#2C3A47',
    '#B33771',
    '#3A3B98',
    '#FD7272',
    '#9AECDB',
    '#D6A2E8',
    '#6D204F',
    '#182B61',
    '#FC427B',
    '#BDC581',
    '#000000'
  ]

  constructor(
    private dashboard: DashboardService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    if(this.auth.getAuthUser().user_clinic !== null) {
      this.clinic = this.auth.getAuthUser().user_clinic.id;
      this.dashboard.getDentistList(this.clinic).subscribe(response => {
        if(response) {
          this.doctors = response;
        } 
      });
    }
  }

  ngOnChanges() {
    if(this.clinicId) {
      this.clinic = this.auth.getAuthUser().user_clinic.id;
      this.dashboard.getDentistList(this.clinic).subscribe(response => {
        if(response) {
          this.doctors = response;
        }
      });
    }

    if(this.trigger) {
      this.selected = 'today';
    }
  }

  onClickButton(value) {
    this.selected = value;
    this.onClick.emit(value);
    if(value == 'month') {
      localStorage.removeItem('selectedApp');
    }
  }

  onDoctorSelect(id, value, i) {
    this.index = i;
    this.selected = value;
    this.onClick.emit(value);
    this.dentistId.emit(id);
    this.color.emit(this.colors[i]);
  }
}
