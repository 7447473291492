import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserService } from '../../../services/user/user.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { Router } from '@angular/router';
import { keypressValidateNumber } from 'src/app/common/helper';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

@Component({
  selector: 'ark-add-user-clinic',
  templateUrl: './add-user-clinic.component.html',
  styleUrls: ['./add-user-clinic.component.scss']
})
export class AddUserClinicComponent implements OnInit {

  errorMessage = '';
  success = false;
  isChecked = false;
  name = '';
  email = '';
  address = '';
  contact_number = '';
  mobile_number = '';
  clinic_hours = '';
  website = '';
  label = '';

  formGroup: FormGroup;
  dialogConfig = new MatDialogConfig();

  role = [
    { id : '2', name: 'Dentist' },
    { id : '3', name: 'Assistant' }
  ];
  
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private auth: AuthService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddUserClinicComponent>, 
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      address: ['', [Validators.required]],
      contact_number: ['', [Validators.required]],
      mobile_number : '',
      clinic_hours : '',
      website : ''

    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.label = 'Next';
  }

  close() {
    this.dialogRef.close();
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }

  onCheck(e) {
    this.isChecked = e.checked;
    this.label = e.checked ? 'Save' : 'Next';
  }
  
  save() {
    if(this.isChecked) {
      this.userService.addnewClinic(this.formGroup.value, false).subscribe(res => {
        if (res) {
          this.success = true;
          this.dialogRef.close(res);
          this.auth.setAuthUser(res['data']);
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Clinic has been successfully added.',
            button: 'Okay',
            event: this.close
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => {
            this.redirectTo(this._router.url);
          });
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Sorry!',
          message: err.error.message,
          button: 'Okay',
          event: this.close
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        this.errorMessage = err.error.errors;
        this.triggerErrors(err.error.errors);
      }));
    } else {
      this.dialogConfig.data = {
        title: 'Sorry!',
        message: 'Please indicate that you have read and accept the Additional Fee.',
        button: 'Okay',
        event: this.close
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }
  }

  redirectTo(uri: string) {
    this._router.navigateByUrl('/login', {skipLocationChange: true}).then(()=>
    this._router.navigate([uri]));
  }
}
