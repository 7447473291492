import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeRecordComponent, TimePipe } from './components/time-record/time-record.component';
import { TimeRecordService } from './services/time-record/time-record.service';
import { SharedModule } from '../shared/shared.module';
import { ClockComponent } from './components/clock/clock.component';
import { MatFormFieldModule, MatIconModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, MatSelectModule } from '@angular/material';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ConfirmTimeoutComponent } from '../shared/components/confirm-timeout/confirm-timeout.component';

@NgModule({
  declarations: [
    TimeRecordComponent,
    ClockComponent,
    TimePipe
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    FormsModule
  ],
  providers: [
    TimeRecordService
  ],
  entryComponents: [
    ConfirmTimeoutComponent
  ]
})
export class DailyTimeRecordModule { }
