import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ark-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {

  isOwner: boolean = (localStorage.getItem('isOwner') == 'true'? true : false );

  constructor() { }

  ngOnInit() {
  }

}
