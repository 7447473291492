import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { FinanceService } from '../../../services/finance/finance.service';
import { MonthlyIncomeStatementComponent } from '../monthly-income-statement/monthly-income-statement.component';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { SidebarService } from 'src/app/modules/core/services/sidebar/sidebar.service';

@Component({
  selector: 'ark-income-statement-summary',
  templateUrl: './income-statement-summary.component.html',
  styleUrls: ['./income-statement-summary.component.scss']
})
export class IncomeStatementSummaryComponent implements OnInit, OnChanges {

  @Input() year: any = null;
  selectedYear: '';
  incomeStatement =[];
  summary = [];
  totalAmount = [];
  clinic = null;
  isNoResult: boolean = true;
  isSidebarMinimized: boolean = false;
  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  constructor(
    private auth: AuthService,
    private finance: FinanceService,
    public dialog: MatDialog,
    private sidebarService: SidebarService
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    if(localStorage.getItem('isSidebarMinimized')) {
      this.isSidebarMinimized = JSON.parse(localStorage.getItem('isSidebarMinimized'));
    }
    this.sidebarService.sidebarState.subscribe((nextValue) => {
      this.isSidebarMinimized = nextValue
    });
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if(this.year) {
      this.selectedYear = this.year;
      this.getList();
    }
  }

  getList() {
    this.incomeStatement =[];
    this.summary = [];
    this.totalAmount = [];
    this.finance.getIncomeStatementSummary(this.clinic, this.selectedYear).subscribe(res => {
      if(res) {
        let data = [
          {
            id: 1,
            month: 'January',
            total_income: res['1'].total_income,
            direct_cost: res['1'].direct_cost,
            doctor_fee: res['1'].doctor_fee,
            gross_income: res['1'].gross_income,
            operational_expense: res['1'].operation_expense,
            net_income: res['1'].net_income
          },{
            id: 2,
            month: 'February',
            total_income: res['2'].total_income,
            direct_cost: res['2'].direct_cost,
            doctor_fee: res['2'].doctor_fee,
            gross_income: res['2'].gross_income,
            operational_expense: res['2'].operation_expense,
            net_income: res['2'].net_income
          },{
            id: 3,
            month: 'March',
            total_income: res['3'].total_income,
            direct_cost: res['3'].direct_cost,
            doctor_fee: res['3'].doctor_fee,
            gross_income: res['3'].gross_income,
            operational_expense: res['3'].operation_expense,
            net_income: res['3'].net_income
          },{
            id: 4,
            month: 'April',
            total_income: res['4'].total_income,
            direct_cost: res['4'].direct_cost,
            doctor_fee: res['4'].doctor_fee,
            gross_income: res['4'].gross_income,
            operational_expense: res['4'].operation_expense,
            net_income: res['4'].net_income
          },{
            id: 5,
            month: 'May',
            total_income: res['5'].total_income,
            direct_cost: res['5'].direct_cost,
            doctor_fee: res['5'].doctor_fee,
            gross_income: res['5'].gross_income,
            operational_expense: res['5'].operation_expense,
            net_income: res['5'].net_income
          },{
            id: 6,
            month: 'June',
            total_income: res['6'].total_income,
            direct_cost: res['6'].direct_cost,
            doctor_fee: res['6'].doctor_fee,
            gross_income: res['6'].gross_income,
            operational_expense: res['6'].operation_expense,
            net_income: res['6'].net_income
          },{
            id: 7,
            month: 'July',
            total_income: res['7'].total_income,
            direct_cost: res['7'].direct_cost,
            doctor_fee: res['7'].doctor_fee,
            gross_income: res['7'].gross_income,
            operational_expense: res['7'].operation_expense,
            net_income: res['7'].net_income
          },{
            id: 8,
            month: 'August',
            total_income: res['8'].total_income,
            direct_cost: res['8'].direct_cost,
            doctor_fee: res['8'].doctor_fee,
            gross_income: res['8'].gross_income,
            operational_expense: res['8'].operation_expense,
            net_income: res['8'].net_income
          },{
            id: 9,
            month: 'September',
            total_income: res['9'].total_income,
            direct_cost: res['9'].direct_cost,
            doctor_fee: res['9'].doctor_fee,
            gross_income: res['9'].gross_income,
            operational_expense: res['9'].operation_expense,
            net_income: res['9'].net_income
          },{
            id: 10,
            month: 'October',
            total_income: res['10'].total_income,
            direct_cost: res['10'].direct_cost,
            doctor_fee: res['10'].doctor_fee,
            gross_income: res['10'].gross_income,
            operational_expense: res['10'].operation_expense,
            net_income: res['10'].net_income
          },{
            id: 11,
            month: 'November',
            total_income: res['11'].total_income,
            direct_cost: res['11'].direct_cost,
            doctor_fee: res['11'].doctor_fee,
            gross_income: res['11'].gross_income,
            operational_expense: res['11'].operation_expense,
            net_income: res['11'].net_income
          },{
            id: 12,
            month: 'December',
            total_income: res['12'].total_income,
            direct_cost: res['12'].direct_cost,
            doctor_fee: res['12'].doctor_fee,
            gross_income: res['12'].gross_income,
            operational_expense: res['12'].operation_expense,
            net_income: res['12'].net_income
          }
        ]
        this.incomeStatement.push(data);
        this.totalAmount.push(res.total);
        this.summary = this.incomeStatement[0];
        if(this.summary.length > 0) {
          this.isNoResult = false;
        } else {
          this.isNoResult = true;
        }
      }
    });
  }

  monthlyIncomeStatement(data) {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogConfig.data = {
      data: data,
      year: this.selectedYear
    }
    this.dialogRef = this.dialog.open(MonthlyIncomeStatementComponent, this.dialogConfig);
  }
}
