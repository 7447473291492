import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/modules/shared/services/http/http.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { operationalExpenses, getOperationalExpenses, getDirectCost, getDoctorFee, getCheckOut } from 'src/app/common/api';

@Injectable()
export class ExpensesService extends HttpService {

  constructor(http: HttpClient, private _router: Router) {
    super('',http);
  }

  addOperationalExpenses(data) {
    return this.http.post(operationalExpenses, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  getOperationalExpenses(id, month, year) {
    return this.get(getOperationalExpenses + '/monthly' + '?user_clinic_id=' + id + '&month=' + month + '&year=' + year).map(res => {
      return res['data'];
    });
  }

  updateOperationalExpenses(data) {
    return this.http.post(operationalExpenses, data).map((dataResponse) => {
      return dataResponse;
    });
  }
  
  deleteOperationalExpensesCategory(id, user_clinic_id, month, year) {
    return this.http.delete(operationalExpenses + '/' + id + '?user_clinic_id=' + user_clinic_id + '&month=' + month + '&year=' + year).map(res => {
      return res['data'];
    });
  }

  deleteOperationalExpensesItem(id, user_clinic_id, month, year) {
    return this.http.delete(getOperationalExpenses + '/' + id + '?user_clinic_id=' + user_clinic_id + '&month=' + month + '&year=' + year).map(res => {
      return res['data'];
    });
  }

  getAnnualExpenses(id, year) {
    return this.get(getOperationalExpenses + '/annual' + '?user_clinic_id=' + id + '&year=' + year).map(res => {
      return res['data'];
    });
  }

  saveOperationalExpensesItems(data) {
    return this.http.post(getOperationalExpenses, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  getMonthlySummaryExpenses(user_clinic_id, month, year) {
    return this.http.get(getOperationalExpenses + "/monthly" + "?user_clinic_id=" + user_clinic_id  + "&month=" + month + "&year=" + year).map(res => {
      return res['data'];
    });
  }

  downloadOperationalExpenses(user_clinic_id, month, year) {
    return this.http.get(getOperationalExpenses + "/download" + "?user_clinic_id=" + user_clinic_id  + "&month=" + month + "&year=" + year, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  downloadDirectCost(user_clinic_id, month, year) {
    return this.http.get(getDirectCost + "/download" + "?user_clinic_id=" + user_clinic_id  + "&month=" + month + "&year=" + year, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  downloadDoctorFee(user_clinic_id, month, year) {
    return this.http.get(getDoctorFee + "/download" + "?user_clinic_id=" + user_clinic_id  + "&month=" + month + "&year=" + year, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  downloadAnnualExpenses(user_clinic_id, year) {
    return this.http.get(getOperationalExpenses + "/annual" + "/download" + "?user_clinic_id=" + user_clinic_id  + "&year=" + year, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  emailOperationalExpenses(user_clinic_id, month, year) {
    return this.http.get(getOperationalExpenses + "/download" + "?user_clinic_id=" + user_clinic_id  + "&month=" + month + "&year=" + year + "&email=true", {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  emailDirectCost(user_clinic_id, month, year) {
    return this.http.get(getDirectCost + "/download" + "?user_clinic_id=" + user_clinic_id  + "&month=" + month + "&year=" + year + "&email=true", {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  emailDoctorFee(user_clinic_id, month, year) {
    return this.http.get(getDoctorFee + "/download" + "?user_clinic_id=" + user_clinic_id  + "&month=" + month + "&year=" + year + "&email=true", {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  emailAnnualExpenses(user_clinic_id, year) {
    return this.http.get(getOperationalExpenses + "/annual" + "/download" + "?user_clinic_id=" + user_clinic_id  + "&year=" + year + "&email=true", {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }
  downloadInventoryCheckout(user_clinic_id, id) {
    return this.http.get(getCheckOut + "?user_clinic_id=" + user_clinic_id  + "&id=" + id, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }
}
