import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'ark-tooth',
  templateUrl: './tooth.component.html',
  styleUrls: ['./tooth.component.scss']
})
export class ToothComponent implements OnInit {

  @Input() toothData: any;
  @Input() quadrant: any;
  @Output() onClick = new EventEmitter<any>();

  typeA = false;
  typeB = false;
  typeC = false;
  typeD = false;

  constructor() { }

  ngOnInit() {
    if(this.quadrant == '1' || this.quadrant == '5') {
      this.typeA = true;
      this.typeB = false;
      this.typeC = false;
      this.typeD = false;
    } else if(this.quadrant == '4' || this.quadrant == '8') {
      this.typeA = false;
      this.typeB = true;
      this.typeC = false;
      this.typeD = false;
    } else if(this.quadrant == '3' || this.quadrant == '7') {
      this.typeA = false;
      this.typeB = false;
      this.typeC = true;
      this.typeD = false;
    } else if(this.quadrant == '2' || this.quadrant == '6') {
      this.typeA = false;
      this.typeB = false;
      this.typeC = false;
      this.typeD = true;
    }
  }

  onClickButton(event) {
    this.onClick.emit(event);
  }
}
