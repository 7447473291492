import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UserService } from '../../services/user/user.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { UserAddComponent } from '../user-add/user-add.component';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { AccountConfigurationComponent } from 'src/app/modules/shared/components/account-configuration/account-configuration.component';
import { SidebarService } from 'src/app/modules/core/services/sidebar/sidebar.service';

@Component({
  selector: 'ark-user-accounts',
  templateUrl: './user-accounts.component.html',
  styleUrls: ['./user-accounts.component.scss']
})
export class UserAccountsComponent implements OnInit {

  userList = [];
  roleList = [
    { id: '2', name: 'Dentist' },
    { id: '3', name: 'Assistant' }
  ];

  success = false;
  isSidebarMinimized = false
  action = '';
  result: any;
  formGroup: FormGroup;
  clinic: any;

  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    public dialog: MatDialog,
    private auth: AuthService,
    private sidebarService: SidebarService
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    if(localStorage.getItem('isSidebarMinimized')) {
      this.isSidebarMinimized = JSON.parse(localStorage.getItem('isSidebarMinimized'));
    }
    this.sidebarService.sidebarState.subscribe((nextValue) => {
      this.isSidebarMinimized = nextValue
    });
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      first_name: ['', null],
      last_name: ['', null],
      email: ['', null],
      role_id: ['', null],
      status: ['', null],
      action: ['', null]
    });
    this.getList();
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  addUser() {
    this.dialogRef = this.dialog.open(UserAddComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.getList();
      }
    });
  }

  getList() {
    this.userService.viewUser(this.clinic).subscribe(res => {
      this.userList = res;
    });
  }

  userAction(id, status) {
    this.dialogConfig.data = {
      subject: (status == 'Active') ? 'Wait!' : 'Are you sure?',
      activation: (status == 'Active') ? false : true
    };
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    let dialogRef = this.dialog.open(AccountConfigurationComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
      if(trigger) {
        let data = {
          action: (status == 'Active') ? 'Deactivate' : 'Activate'
        }
        this.userService.userAction(id, this.clinic, data).subscribe(res => {
          if(res) {
            this.getList();
            this.dialogConfig.data = {
              title: 'Success!',
              message: "User\'s status has been successfully updated.",
              button: 'Okay',
              event: this.closeOnClick
            };
            this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
          }
        },(err => {
          this.dialogConfig.data = {
            title: 'Sorry!',
            message: err.error.message,
            button: 'Okay',
            event: this.closeOnClick
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }));
      }
    });
  }

  closeOnClick() {
    this.dialogRef.close();
  }
}
