import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { InventoryService } from 'src/app/modules/front-desk/service/inventory/inventory.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { openDialog } from 'src/app/modules/shared/components/functional/dialogFunction';
import { LogDetailsComponent } from './log-details/log-details.component';

@Component({
  selector: 'ark-inventory-logs',
  templateUrl: './inventory-logs.component.html',
  styleUrls: ['./inventory-logs.component.scss']
})
export class InventoryLogsComponent implements OnInit, OnChanges {
  config = {};
  config1 = {};
  element_data = [];
  detist_list = [];
  clinic = null;
  user_id = null;

  dateFrom = null;
  dateTo = null;
  updatedBy = '';
  selectedAction = '';
  filters = '' ;

  actions = [
    'Inventory In',
    'Inventory Out',
    'Inventory Control',
  ];

  @Input() trigger: boolean = false;
  constructor(private api: InventoryService, private auth: AuthService, private dialog: MatDialog) {
    /* TABLE CONFIGURATION */
    this.config = {
      data: [],
      keys: ['description', 'action', 'date', 'first_name'],
      action: { 'description' : true},
    };
    
    /* END */
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.user_id = this.auth.getAuthUser().id;
    const filter = 'user-clinic-member/dentist/list?user_clinic_id=' + this.clinic + '&filter=all';
    this.api.getAPI(filter, '').subscribe(res => {
      if (res.status_code == 200) {
        // console.log(res);
        this.detist_list = res.data;
      }
    });
  }

  filterChage(filter){
    this.filters = '&user_id=' +  this.updatedBy + '&action=' + this.selectedAction;
    if (this.dateFrom !== null) {
      this.filters += '&from=' + moment(this.dateFrom).format('YYYY-MM-DD');
    }

    if (this.dateTo !== null) {
      this.filters += '&to=' + moment(this.dateTo).format('YYYY-MM-DD');
    }

    this.reloadLogs();
  }

  ngOnInit() {
    this.reloadLogs();
  }

  ngOnChanges() {
    if (this.trigger){
      this.reloadLogs();
    }
  }

  getOrderNumber(orderNum) {
    openDialog(LogDetailsComponent, orderNum, this.dialog).afterClosed().subscribe( trigger => {
      if(trigger) {

      }
    })
  }

  reloadLogs() {
    const filter = 'item-inventory-log?user_clinic_id=' + this.clinic + this.filters;
    this.api.getAPI(filter, '').subscribe(response => {
      if (response.status_code == 200) {
        const arr_data = [];
        response.data.data.forEach(data => {
          arr_data.push({
            description: data.description,
            action: data.action,
            date: moment(data.date).format('LL'),
            first_name: data.first_name,
            id: data.id
          });
        });
        this.element_data = arr_data;
      }
    });
  }

}
