import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DashboardService } from '../../../services/dashboard/dashboard.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { DailyReportService } from '../../../services/daily-report/daily-report.service';

@Component({
  selector: 'ark-daily-report-settings',
  templateUrl: './daily-report-settings.component.html',
  styleUrls: ['./daily-report-settings.component.scss']
})
export class DailyReportSettingsComponent implements OnInit {

  dialogConfig = new MatDialogConfig();
  timeRange = [
    { name: '06:00 AM', value: '06:00:00' },
    { name: '06:30 AM', value: '06:30:00' },
    { name: '07:00 AM', value: '07:00:00' },
    { name: '07:30 AM', value: '07:30:00' },
    { name: '08:00 AM', value: '08:00:00' },
    { name: '08:30 AM', value: '08:30:00' },
    { name: '09:00 AM', value: '09:00:00' },
    { name: '09:30 AM', value: '09:30:00' },
    { name: '10:00 AM', value: '10:00:00' },
    { name: '10:30 AM', value: '10:30:00' },
    { name: '11:00 AM', value: '11:00:00' },
    { name: '11:30 AM', value: '11:30:00' },
    { name: '12:00 PM', value: '12:00:00' },
    { name: '12:30 PM', value: '12:30:00' },
    { name: '01:00 PM', value: '13:00:00' },
    { name: '01:30 PM', value: '13:30:00' },
    { name: '02:00 PM', value: '14:00:00' },
    { name: '02:30 PM', value: '14:30:00' },
    { name: '03:00 PM', value: '15:00:00' },
    { name: '03:30 PM', value: '15:30:00' },
    { name: '04:00 PM', value: '16:00:00' },
    { name: '04:30 PM', value: '16:30:00' },
    { name: '05:00 PM', value: '17:00:00' },
    { name: '05:30 PM', value: '17:30:00' },
    { name: '06:00 PM', value: '18:00:00' },
    { name: '06:30 PM', value: '18:30:00' },
    { name: '07:00 PM', value: '19:00:00' },
    { name: '07:30 PM', value: '19:30:00' },
    { name: '08:00 PM', value: '20:00:00' },
    { name: '08:30 PM', value: '20:30:00' },
    { name: '09:00 PM', value: '21:00:00' },
    { name: '09:30 PM', value: '21:30:00' },
    { name: '10:00 PM', value: '22:00:00' },
    { name: '10:30 PM', value: '22:30:00' },
    { name: '11:00 PM', value: '23:00:00' },
    { name: '11:30 PM', value: '23:30:00' },
    { name: '12:00 AM', value: '24:00:00' },
  ];
  formGroup: FormGroup;
  isAuto: number = 1;
  time = null;
  auto: boolean = false;
  checkedAuto: boolean = false;
  checkedManual: boolean = false;
  hasSettings: boolean = false;
  clinic = null;
  reportId = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DailyReportSettingsComponent>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private report: DailyReportService,
    private auth: AuthService
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.formGroup = this.formBuilder.group({
      time: ['', [Validators.required]]
    });
    this.formGroup.controls['time'].disable();
    this.report.getDailyReportSettings(this.clinic).subscribe(res => {
      if(res) {
        this.hasSettings = true;
        this.reportId = res.id;
        if(res.is_auto == 1) {
          this.isAuto = 1;
          this.auto = true;
          this.time = "22:30:00";
          this.checkedAuto = true;
        } else {
          this.isAuto = 0;
          this.checkedManual = true;
          this.formGroup.get('time').setValue(res.time);
          this.formGroup.controls['time'].enable();
        }
      } else {
        this.hasSettings = false;
      }
      localStorage.setItem('dailyReportConfig', JSON.stringify(res));
    });
  }

  ngOnInit() {
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  onToggleAuto(val) {
    if(val.checked == true) {
      this.isAuto = 1;
      this.auto = true;
      this.time = "22:30";
      this.checkedAuto = true;
      this.checkedManual = false;
      this.formGroup.controls['time'].disable();
    } else {
      this.formGroup.controls['time'].enable();
    }
  } 

  onToggleManual(val) {
    if(val.checked == true) {
      this.isAuto = 0;
      this.auto = false;
      this.formGroup.controls['time'].enable();
      this.checkedAuto = false;
      this.checkedManual = true;
    } else {
      this.formGroup.controls['time'].disable();
    }
  }

  saveSettings() {
    let data = {
      user_clinic_id: this.clinic,
      time: this.auto ? this.time : this.formGroup.value.time,
      is_auto: this.isAuto
    }
    this.report.saveDailyReportSettings(data).subscribe(res => {
      if(res) {
        this.dialogRef.close(res);
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'End of day report settings has been saved successfully.',
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        localStorage.setItem('dailyReportConfig', JSON.stringify(res['data']));
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Please specify time.',
        button: 'Okay',
        event: this.closeOnClick
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }));
  }

  updateSettings() {
    let data = {
      user_clinic_id: this.clinic,
      time: this.auto ? this.time : this.formGroup.value.time,
      is_auto: this.isAuto
    }
    this.report.updateDailyReportSettings(this.reportId, data).subscribe(res => {
      if(res) {
        this.dialogRef.close(res);
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'End of day report settings has been updated successfully.',
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        localStorage.setItem('dailyReportConfig', JSON.stringify(res['data']));
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: err.error.message,
        button: 'Okay',
        event: this.closeOnClick
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }));
  }
}
