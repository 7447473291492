import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'ark-confirm-clinic-switch',
  templateUrl: './confirm-clinic-switch.component.html',
  styleUrls: ['./confirm-clinic-switch.component.scss']
})
export class ConfirmClinicSwitchComponent implements OnInit {

  subject: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmClinicSwitchComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.subject = this.data.subject;
  }

  close(flag = false) {
    this.dialogRef.close(flag);
  }
}