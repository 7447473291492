import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'ark-screw',
  templateUrl: './screw.component.html',
  styleUrls: ['./screw.component.scss']
})
export class ScrewComponent implements OnInit, OnChanges {

  @Input() quadrant: any;
  invertedScrew = false;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    if(this.quadrant == '1' || this.quadrant == '2' || this.quadrant == '5' || this.quadrant == '6') {
      this.invertedScrew = false;
    } else {
      this.invertedScrew = true;
    }
  }
}
