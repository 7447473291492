import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatAutocompleteTrigger, MatDialogConfig, MatDialog } from '@angular/material';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard/dashboard.service';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { DentalFormsService } from 'src/app/modules/dental-office/services/dental-forms/dental-forms.service';
import { startWith, map } from 'rxjs/operators';
import * as moment from 'moment';
import { keypressValidateNumber } from 'src/app/common/helper';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { ESignatureComponent } from 'src/app/modules/shared/components/e-signature/e-signature.component';
import { UserService } from 'src/app/modules/account-settings/services/user/user.service';

export interface Dentist {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
}

export interface Patient {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
}

@Component({
  selector: 'ark-orthodontics-authorization',
  templateUrl: './orthodontics-authorization.component.html',
  styleUrls: ['./orthodontics-authorization.component.scss']
})
export class OrthodonticsAuthorizationComponent implements OnInit {

  formGroup: FormGroup;
  errorMessage = null;
  clinic = null;
  clinicName = null;
  previousDate = new Date();
  patientId = '';
  dentistId = '';
  doctorId = '';
  patientName = '';
  dentistName = '';
  doctorName = '';
  patientControl = new FormControl();
  dentistControl = new FormControl();
  doctorControl = new FormControl();
  patients: Patient[] = [];
  dentists: Dentist[] = [];
  doctors: Dentist[] = [];
  filteredPatients: Observable<Patient[]>;
  filteredDentists: Observable<Dentist[]>;
  filteredDoctors: Observable<Dentist[]>;
  @ViewChild('patient') patient: MatAutocompleteTrigger;
  @ViewChild('dentist') dentist: MatAutocompleteTrigger;
  @ViewChild('doctor') doctor: MatAutocompleteTrigger;

  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  retainers = [
    {
      name: 'Bow Solder to Clasps',
      content: [
        { id: 1, name: 'Upper', is_checked: 0 },
        { id: 2, name: 'Lower', is_checked: 0 },
      ]
    },
    {
      name: 'Flat Bow Labial Wire',
      content: [
        { id: 1, name: 'Upper', is_checked: 0 },
        { id: 2, name: 'Lower', is_checked: 0 },
      ]
    },
    {
      name: 'Invisible Retainer',
      content: [
        { id: 1, name: 'Upper', is_checked: 0 },
        { id: 2, name: 'Lower', is_checked: 0 },
      ]
    },
    {
      name: 'KD Overlay',
      content: [
        { id: 1, name: 'Upper', is_checked: 0 },
        { id: 2, name: 'Lower', is_checked: 0 },
      ]
    },
    {
      name: 'Pedo Partials',
      content: [
        { id: 1, name: 'Upper', is_checked: 0 },
        { id: 2, name: 'Lower', is_checked: 0 },
      ]
    },
    {
      name: 'Standard Design',
      content: [
        { id: 1, name: 'Upper', is_checked: 0 },
        { id: 2, name: 'Lower', is_checked: 0 },
      ]
    },
    {
      name: 'Wrap Around',
      content: [
        { id: 1, name: 'Upper', is_checked: 0 },
        { id: 2, name: 'Lower', is_checked: 0 },
      ]
    }
  ]

  springRetainers = [
    {
      name: 'Compromise Reset',
      content: [
        { id: 1, name: 'Upper', is_checked: 0 },
        { id: 2, name: 'Lower', is_checked: 0 },
      ]
    },
    {
      name: 'Do Not Reset Teeth',
      content: [
        { id: 1, name: 'Upper', is_checked: 0 },
        { id: 2, name: 'Lower', is_checked: 0 },
      ]
    },
    {
      name: 'Ext with Clasps or Rest',
      content: [
        { id: 1, name: 'Upper', is_checked: 0 },
        { id: 2, name: 'Lower', is_checked: 0 },
      ]
    },
    {
      name: 'Modified Spring Hawley',
      content: [
        { id: 1, name: 'Upper', is_checked: 0 },
        { id: 2, name: 'Lower', is_checked: 0 },
      ]
    },
    {
      name: 'Reset Teeth Ideally',
      content: [
        { id: 1, name: 'Upper', is_checked: 0 },
        { id: 2, name: 'Lower', is_checked: 0 },
      ]
    }
  ]

  acrylicDesigns = [
    { id: 1, name: 'Acrylic Bow', is_checked: 0 },
    { id: 2, name: 'Anterior Bite Plane', is_checked: 0 },
    { id: 3, name: 'Hi Trim Anteriors', is_checked: 0 },
    { id: 4, name: 'Horseshoe Palate', is_checked: 0 },
    { id: 5, name: 'Posterior Bite Plane', is_checked: 0 },
    { id: 6, name: 'Reverse Incline', is_checked: 0 },
    { id: 7, name: 'Scallop Anteriors', is_checked: 0 },
  ]

  acrylicOptions = [
    { id: 1, name: 'Add Pontic(s)', is_checked: 0, hasInput: false, isCheckbox: true },
    { id: 2, name: 'Clear', is_checked: 0, hasInput: false, isCheckbox: true },
    { id: 3, name: 'Color/Graphics', is_checked: 0, hasInput: false, isCheckbox: true },
    { id: 4, name: 'Pink', is_checked: 0, hasInput: false, isCheckbox: true },
    { id: 5, name: 'Sticker', is_checked: 0, hasInput: true, isCheckbox: true },
    { id: 6, name: 'Upper', is_checked: 0, hasInput: true, isCheckbox: false, value: 'upper' },
    { id: 7, name: 'Lower', is_checked: 0, hasInput: true, isCheckbox: false, value: 'lower' },
  ]

  claspOptions = [
    { id: 1, name: 'Adams Clasps', is_checked: 0 },
    { id: 2, name: 'Arrow Clasps', is_checked: 0 },
    { id: 3, name: 'Ball Clasps', is_checked: 0 },
    { id: 4, name: '\'C\' Clasps', is_checked: 0 },
    { id: 5, name: 'Finger Springs', is_checked: 0 },
    { id: 6, name: 'Rests', is_checked: 0 },
    { id: 7, name: 'Soldered \'C\'s', is_checked: 0 },
  ]

  springs = [
    { id: 1, name: 'Elastic Hooks/Soldered', is_checked: 0 },
    { id: 2, name: 'Helical Spring', is_checked: 0 },
    { id: 3, name: 'Interproximal Spurs', is_checked: 0 },
    { id: 4, name: 'Stabilizing Wire', is_checked: 0 },
    { id: 5, name: 'Z Spring', is_checked: 0 },
  ]

  mouthguards = [
    { id: 1, name: '2mm', is_checked: 0, hasInput: false },
    { id: 2, name: '3mm', is_checked: 0, hasInput: false },
    { id: 3, name: 'Chin Strap', is_checked: 0, hasInput: false },
    { id: 4, name: 'Clear', is_checked: 0, hasInput: false },
    { id: 5, name: 'Color', is_checked: 0, hasInput: true },
  ]

  splints = [
    { id: 1, name: 'Arcylic Bruxisam', is_checked: 0 },
    { id: 3, name: 'Gelb Splint', is_checked: 0 },
    { id: 2, name: 'Hard - Soft', is_checked: 0 },
    { id: 4, name: 'Hard Vacuum 3mm', is_checked: 0 },
  ]

  screws = [
    { id: 3, name: '3-Way', is_checked: 0 },
    { id: 1, name: '7mm - 12mm', is_checked: 0 },
    { id: 4, name: 'Fan Expansion', is_checked: 0 },
    { id: 2, name: 'Micro Screw', is_checked: 0 },
  ]

  bionatorCorrector = [
    { id: 1, name: 'Close', is_checked: 0 },
    { id: 2, name: 'Maintain Bite', is_checked: 0 },
    { id: 3, name: 'Open', is_checked: 0 },
    { id: 4, name: 'Screw', is_checked: 0 },
  ]

  transverseSagittals = [
    { id: 1, name: 'Occlusal Cover', is_checked: 0 },
    { id: 2, name: 'One Screw', is_checked: 0 },
    { id: 3, name: 'Two Screw', is_checked: 0 },
    { id: 4, name: 'Three Screw', is_checked: 0 },
  ]

  quadrant1 = [
    { id: 1, name: '1', is_checked: 0 },
    { id: 2, name: '2', is_checked: 0 },
    { id: 3, name: '3', is_checked: 0 },
  ]

  quadrant2 = [
    { id: 1, name: '1', is_checked: 0 },
    { id: 2, name: '2', is_checked: 0 },
    { id: 3, name: '3', is_checked: 0 },
  ]

  quadrant3 = [
    { id: 1, name: '1', is_checked: 0 },
    { id: 2, name: '2', is_checked: 0 },
    { id: 3, name: '3', is_checked: 0 },
  ]

  quadrant4 = [
    { id: 1, name: '1', is_checked: 0 },
    { id: 2, name: '2', is_checked: 0 },
    { id: 3, name: '3', is_checked: 0 },
  ]

  receiverSignature;
  dentistSignature;
  hasReceiverSignature = false;
  hasDentistSignature = false;
  signature1 = 'Receiver\'s Signature (tap icon to add)';
  signature2 = 'Dentist\'s Signature (tap icon to add)';

  data = {
    user_clinic_id: "",
    lab_name: "",
    clinic_address: "",
    patient_id: "",
    patient_name: "",
    doctor_name: "",
    mobile_number: "",
    telephone_number: "",
    due_date: "",
    retainers: {
      standard_design: [],
      wrap_around: [],
      bow_solder_to_casps: [],
      flat_bow_labial_wire: [],
      invisible_retainer: [],
      kd_overlay: [],
      pedo_partials: []
    },
    acrylic_options: [],
    acrylic_options_upper: "",
    acrylic_options_lower: "",
    acrylic_design: [],
    spring_retainers: {
      modified_spring_hawley: [],
      ext_with_clasps_or_rest: [],
      do_not_reset_teeh: [],
      reset_teeth_ideally: [],
      compromise_reset: []
    },
    clasp_options: [],
    springs: [],
    reset_teeth: {
      q1: [],
      q2: [],
      q3: [],
      q4: [],
    },
    mouthguards: [],
    splints: [],
    screws: [],
    bionator_corrector: [],
    transverse_sagittals: [],
    dentist_signature: "",
    dentist_name: "",
    prc_license_no: "",
    date_signed: "",
    receivers_signature: "",
    received_by: "",
    received_date: ""
  }

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private dashboard: DashboardService,
    private patientDirectory: PatientsDirectoryService,
    private forms: DentalFormsService,
    public dialog: MatDialog,
    private userService: UserService,
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic;
    this.dashboard.getPatientList(this.clinic.id, this.clinic.id).subscribe(res => {
      res.data.forEach(element => {
        let obj: Patient = {
          id: element.id, 
          firstName: element.first_name, 
          middleName: element.middle_name ? element.middle_name : '', 
          lastName: element.last_name
        };
        this.patients.push(obj);
      });
    });
    this.dashboard.getDentistList(this.clinic.id).subscribe(res => {
      res.forEach(element => {
        let obj: Dentist = {
          id: element.id, 
          firstName: element.first_name,
          lastName: element.last_name,
          middleName: element.middle_name ? element.middle_name : '', 
        };
        this.dentists.push(obj);
        this.doctors.push(obj);
      });
    });
    this.formGroup = this.formBuilder.group({
      doctor_id: new FormControl('', [Validators.required]),
      doctor_name: [''],
      patient_name: [''],
      patient_id: new FormControl('', [Validators.required]),
      clinic_address: ['', [Validators.required]],
      mobile_no: [''],
      telephone_no: [''],
      due_date: ['', [Validators.required]],
      dentist_name: [''],
      dentist_id: new FormControl(''),
      dentist_signature: [''],
      received_by: [''],
      received_date: [''],
      receivers_signature: [''],
      prc_license_no: [''],
      mouthguard_value_5: [''],
      acrylic_option_value_5: [''],
      acrylic_options_upper: [''],
      acrylic_options_lower: [''],
      date_signed: [''],
    });
  }

  ngOnInit() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.filteredPatients = this.formGroup.get('patient_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterPatient(name) : this.patients.slice()),
      );
    this.filteredDentists = this.formGroup.get('dentist_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterDentist(name) : this.dentists.slice())
      );
    this.filteredDoctors = this.formGroup.get('doctor_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterDoctor(name) : this.doctors.slice())
      );
  }

  getPatient(event) {
    this.patientId = event.option.value.id;
    this.patientName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.formGroup.get('patient_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
  }

  getDentist(event) {
    this.dentistId = event.option.value.id;
    this.dentistName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.dashboard.getDentistInfo(event.option.value.id, this.clinic.id).subscribe(res => {
      if(res) {
        this.formGroup.get('prc_license_no').setValue(res[0].user.prc_license_no ? res[0].user.prc_license_no : '');
      }
    });
    this.formGroup.get('dentist_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
  }

  getDoctor(event) {
    this.doctorId = event.option.value.id;
    this.doctorName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.userService.getClinic(this.clinic.id, this.clinic.id).subscribe(res => {
      if(res) {
        this.formGroup.get('clinic_address').setValue(res['data'].address ? res['data'].address : '');
        this.formGroup.get('mobile_no').setValue(res['data'].mobile_number ? res['data'].mobile_number : '');
        this.formGroup.get('telephone_no').setValue(res['data'].contact_number ? res['data'].contact_number : '');
      }
    });
    this.formGroup.get('doctor_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
    this.formGroup.get('dentist_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
    this.dentistId = event.option.value.id;
    this.dentistName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.dashboard.getDentistInfo(event.option.value.id, this.clinic.id).subscribe(res => {
      if(res) {
        this.formGroup.get('prc_license_no').setValue(res[0].user.prc_license_no ? res[0].user.prc_license_no : '');
      }
    });
  }

  private _filterPatient(name: string): Patient[] {
    const filterValue = name.toLowerCase();
    return this.patients.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1 || option.middleName.toLowerCase().indexOf(filterValue) > -1);
  }

  private _filterDentist(name: string): Dentist[] {
    const filterValue = name.toLowerCase();
    return this.dentists.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1);
  }

  private _filterDoctor(name: string): Dentist[] {
    const filterValue = name.toLowerCase();
    return this.doctors.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1);
  }

  displayDentist(dentist?: any) : string | undefined {
    return dentist ? 'Dr. ' + dentist.firstName + (dentist.middleName ? ' ' + dentist.middleName + ' ' : ' ') + dentist.lastName : undefined;
  }

  displayDoctor(doctor?: any) : string | undefined {
    return doctor ? 'Dr. ' + doctor.firstName + (doctor.middleName ? ' ' + doctor.middleName + ' ' : ' ') + doctor.lastName : undefined;
  }

  displayPatient(patient?: any) : string | undefined {
    return patient ? patient.lastName + ', ' + patient.firstName + (patient.middleName ? ' ' + patient.middleName : ' ') : undefined;
  }

  onFocusPatient() {
    this.patient._onChange("");
    this.patient.openPanel();
  }

  onFocusDentist() {
    this.dentist._onChange(""); 
    this.dentist.openPanel();
  }
  
  onFocusDoctor() {
    this.doctor._onChange(""); 
    this.doctor.openPanel();
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }
  
  getReceiverSignature() {
      this.dialogConfig.disableClose = true;
      this.dialogConfig.autoFocus = false;
      this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
      this.dialogConfig.data = {
        title: 'Receiver\'s Signature'
      }
      this.dialogRef = this.dialog.open(ESignatureComponent, this.dialogConfig).afterClosed().subscribe(res => {
        if(res) {
          this.hasReceiverSignature = true;
          this.receiverSignature = res;
          this.signature1 = 'Receiver\'s Signature';
          this.formGroup.get('received_date').setValue(new Date());
        }
      });
    }
  
  getDentistSignature() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogConfig.data = {
      title: 'Dentist\'s Signature'
    }
    this.dialogRef = this.dialog.open(ESignatureComponent, this.dialogConfig).afterClosed().subscribe(res => {
      if(res) {
        this.hasDentistSignature = true;
        this.dentistSignature = res;
        this.signature2 = 'Dentist\'s Signature';
        this.formGroup.get('date_signed').setValue(new Date());
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  onCheck(e, obj, index, name) {
    obj.is_checked = (e.checked) ? 1 : 0;
    if(name == 'retainers') {
      if(index == 5) {
        if(obj.is_checked) {
          this.data.retainers.standard_design.push(obj.name);
        } else {
          this.data.retainers.standard_design.splice(this.data.retainers.standard_design.indexOf(obj.name), 1);
        }
      } else if(index == 6) {
        if(obj.is_checked) {
          this.data.retainers.wrap_around.push(obj.name);
        } else {
          this.data.retainers.wrap_around.splice(this.data.retainers.wrap_around.indexOf(obj.name), 1);
        }
      } else if(index == 0) {
        if(obj.is_checked) {
          this.data.retainers.bow_solder_to_casps.push(obj.name);
        } else {
          this.data.retainers.bow_solder_to_casps.splice(this.data.retainers.bow_solder_to_casps.indexOf(obj.name), 1);
        }
      } else if(index == 1) {
        if(obj.is_checked) {
          this.data.retainers.flat_bow_labial_wire.push(obj.name);
        } else {
          this.data.retainers.flat_bow_labial_wire.splice(this.data.retainers.flat_bow_labial_wire.indexOf(obj.name), 1);
        }
      } else if(index == 2) {
        if(obj.is_checked) {
          this.data.retainers.invisible_retainer.push(obj.name);
        } else {
          this.data.retainers.invisible_retainer.splice(this.data.retainers.invisible_retainer.indexOf(obj.name), 1);
        }
      } else if(index == 3) {
        if(obj.is_checked) {
          this.data.retainers.kd_overlay.push(obj.name);
        } else {
          this.data.retainers.kd_overlay.splice(this.data.retainers.kd_overlay.indexOf(obj.name), 1);
        }
      } else {
        if(obj.is_checked) {
          this.data.retainers.pedo_partials.push(obj.name);
        } else {
          this.data.retainers.pedo_partials.splice(this.data.retainers.pedo_partials.indexOf(obj.name), 1);
        }
      }
    } else if(name == 'acrylic-options') {
      if(obj.is_checked) {
        this.data.acrylic_options.push(obj.name);
      } else {
        if (obj.name == 'Sticker') {
          this.formGroup.get('acrylic_option_value_5').setValue('');
          const selection = this.data.acrylic_options.filter(item => item.includes(obj.name));
          // obj.name = selection[0];
        }

        this.data.acrylic_options.splice(this.data.acrylic_options.indexOf(obj.name), 1);
      }
    } else if(name == 'acrylic-designs') {
      if(obj.is_checked) {
        this.data.acrylic_design.push(obj.name);
      } else {
        this.data.acrylic_design.splice(this.data.acrylic_design.indexOf(obj.name), 1);
      }
    } else if(name == 'spring-retainers') {
      if(index == 3) {
        if(obj.is_checked) {
          this.data.spring_retainers.modified_spring_hawley.push(obj.name);
        } else {
          this.data.spring_retainers.modified_spring_hawley.splice(this.data.spring_retainers.modified_spring_hawley.indexOf(obj.name), 1);
        }
      } else if(index == 2) {
        if(obj.is_checked) {
          this.data.spring_retainers.ext_with_clasps_or_rest.push(obj.name);
        } else {
          this.data.spring_retainers.ext_with_clasps_or_rest.splice(this.data.spring_retainers.ext_with_clasps_or_rest.indexOf(obj.name), 1);
        }
      } else if(index == 1) {
        if(obj.is_checked) {
          this.data.spring_retainers.do_not_reset_teeh.push(obj.name);
        } else {
          this.data.spring_retainers.do_not_reset_teeh.splice(this.data.spring_retainers.do_not_reset_teeh.indexOf(obj.name), 1);
        }
      } else if(index == 4) {
        if(obj.is_checked) {
          this.data.spring_retainers.reset_teeth_ideally.push(obj.name);
        } else {
          this.data.spring_retainers.reset_teeth_ideally.splice(this.data.spring_retainers.reset_teeth_ideally.indexOf(obj.name), 1);
        }
      } else {
        if(obj.is_checked) {
          this.data.spring_retainers.compromise_reset.push(obj.name);
        } else {
          this.data.spring_retainers.compromise_reset.splice(this.data.spring_retainers.compromise_reset.indexOf(obj.name), 1);
        }
      }
    } else if(name == 'quadrant1') {
      if(obj.is_checked) {
        this.data.reset_teeth.q1.push(obj.name);
      } else {
        this.data.reset_teeth.q1.splice(this.data.reset_teeth.q1.indexOf(obj.name), 1);
      }
    } else if(name == 'quadrant2') {
      if(obj.is_checked) {
        this.data.reset_teeth.q2.push(obj.name);
      } else {
        this.data.reset_teeth.q2.splice(this.data.reset_teeth.q2.indexOf(obj.name), 1);
      }
    } else if(name == 'quadrant3') {
      if(obj.is_checked) {
        this.data.reset_teeth.q3.push(obj.name);
      } else {
        this.data.reset_teeth.q3.splice(this.data.reset_teeth.q3.indexOf(obj.name), 1);
      }
    } else if(name == 'quadrant4') {
      if(obj.is_checked) {
        this.data.reset_teeth.q4.push(obj.name);
      } else {
        this.data.reset_teeth.q4.splice(this.data.reset_teeth.q4.indexOf(obj.name), 1);
      }
    } else if(name == 'clasp-options') {
      if(obj.is_checked) {
        this.data.clasp_options.push(obj.name);
      } else {
        this.data.clasp_options.splice(this.data.clasp_options.indexOf(obj.name), 1);
      }
    } else if(name == 'springs') {
      if(obj.is_checked) {
        this.data.springs.push(obj.name);
      } else {
        this.data.springs.splice(this.data.springs.indexOf(obj.name), 1);
      }
    } else if(name == 'splints') {
      if(obj.is_checked) {
        this.data.splints.push(obj.name);
      } else {
        this.data.splints.splice(this.data.splints.indexOf(obj.name), 1);
      }
    } else if(name == 'screws') {
      if(obj.is_checked) {
        this.data.screws.push(obj.name);
      } else {
        this.data.screws.splice(this.data.screws.indexOf(obj.name), 1);
      }
    } else if(name == 'mouthguards') {
      if(obj.is_checked) {
        this.data.mouthguards.push(obj.name);
      } else {
        if (obj.name == 'Color') {
          this.formGroup.get('mouthguard_value_5').setValue('');
          const selection = this.data.mouthguards.filter(item => item.includes(obj.name));
          // obj.name = selection[0];
        }

        this.data.mouthguards.splice(this.data.mouthguards.indexOf(obj.name), 1);
      }
    } else if(name == 'bionatorCorrector') {
      if(obj.is_checked) {
        this.data.bionator_corrector.push(obj.name);
      } else {
        this.data.bionator_corrector.splice(this.data.bionator_corrector.indexOf(obj.name), 1);
      }
    } else if(name == 'transverseSagittals') {
      if(obj.is_checked) {
        this.data.transverse_sagittals.push(obj.name);
      } else {
        this.data.transverse_sagittals.splice(this.data.transverse_sagittals.indexOf(obj.name), 1);
      }
    }
  }

  download() {
    const due_date = this.formGroup.value.due_date ? moment(this.formGroup.value.due_date).format('MM/DD/YYYY') : '';
    const received_date = this.formGroup.value.due_date ? moment(this.formGroup.value.due_date).format('MM/DD/YYYY') : '';
    const date_signed = this.formGroup.value.date_signed ? moment(this.formGroup.value.date_signed).format('MM/DD/YYYY') : '';

    this.data.user_clinic_id = this.clinic.id;
    this.data.patient_id = this.patientId;
    this.data.patient_name = this.patientName;
    this.data.due_date = due_date;
    this.data.clinic_address = this.formGroup.value.clinic_address;
    this.data.mobile_number = this.formGroup.value.mobile_no;
    this.data.telephone_number = this.formGroup.value.telephone_no;
    this.data.doctor_name = this.doctorName;
    this.data.dentist_signature = this.dentistSignature;
    this.data.dentist_name = this.dentistName;
    this.data.prc_license_no = this.formGroup.value.prc_license_no;
    this.data.receivers_signature = this.receiverSignature;
    this.data.received_by = this.formGroup.value.received_by;
    this.data.received_date = received_date; 
    this.data.date_signed = date_signed;

    let mouthguards = this.data.mouthguards.filter(item => item.includes('Color'));
    if(mouthguards.length > 0) {
      let x = this.data.mouthguards.indexOf(mouthguards[0]);

      if (this.formGroup.value.mouthguard_value_5 != '' && this.formGroup.value.mouthguard_value_5 != null) {
        this.data.mouthguards[x] = 'Color: '+ this.formGroup.value.mouthguard_value_5;
      } else {
        this.data.mouthguards[x] = 'Color';
      }
    }

    let acrylic_options = this.data.acrylic_options.filter(item => item.includes('Sticker'));
    if(acrylic_options.length > 0) {
      let x = this.data.acrylic_options.indexOf(acrylic_options[0]);

      if (this.formGroup.value.acrylic_option_value_5 != '' && this.formGroup.value.acrylic_option_value_5 != null) {
        this.data.acrylic_options[x] = 'Sticker: '+ this.formGroup.value.acrylic_option_value_5;
      } else {
        this.data.acrylic_options[x] = 'Sticker';
      }
    }

    this.data.acrylic_options_upper = this.formGroup.value.acrylic_options_upper;
    this.data.acrylic_options_lower = this.formGroup.value.acrylic_options_lower;

    this.forms.printAndDownloadOrthodonticsWorkAuth(this.data).subscribe(res => {
      if(res) {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(res);
        a.download = moment().format("YYYYMMDD") + '-WORK AUTHORIZATION ORTHODONTICS.pdf';
        document.body.appendChild(a);
        a.click();
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Exporting of PDF failed. Please fill-in all the required fields.',
        button: 'Okay',
        event: this.close
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      const CLINIC_ADDRESS_REQUIRED = this.formGroup.value.clinic_address == '' ? 'The clinic address field is required.' : '';
      const DUE_DATE_REQUIRED = this.formGroup.value.due_date == '' ? 'The date field field is required.' : '';
      const DOCTOR_REQUIRED = this.formGroup.get('doctor_id').value == '' ? 'The dentist name field is required.' : '';
      const PATIENT_REQUIRED = this.formGroup.get('patient_id').value == '' ? 'The patient name field is required.' : '';
      const DOCTOR_INVALID = typeof this.formGroup.get('doctor_id').value == 'string' ? 'The dentist name is invalid.' : '';
      const PATIENT_INVALID = typeof this.formGroup.get('patient_id').value == 'string' ? 'The patient name is invalid.' : '';
      const DENTIST_INVALID = typeof this.formGroup.get('dentist_id').value == 'string' && this.formGroup.get('dentist_id').value !== '' ? 'The dentist name is invalid.' : '';
      this.errorMessage = {
        clinic_address: CLINIC_ADDRESS_REQUIRED,
        due_date: DUE_DATE_REQUIRED,
        doctor_id: DOCTOR_REQUIRED == '' ? DOCTOR_INVALID : DOCTOR_REQUIRED,
        patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
        dentist_id: DENTIST_INVALID,
      };
      this.triggerErrors({
        clinic_address: CLINIC_ADDRESS_REQUIRED,
        due_date: DUE_DATE_REQUIRED,
        doctor_id: DOCTOR_REQUIRED == '' ? DOCTOR_INVALID : DOCTOR_REQUIRED,
        patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
        dentist_id: DENTIST_INVALID,
      });
    }));
  }

  print() {
    const due_date = this.formGroup.value.due_date ? moment(this.formGroup.value.due_date).format('MM/DD/YYYY') : '';
    const received_date = this.formGroup.value.due_date ? moment(this.formGroup.value.due_date).format('MM/DD/YYYY') : '';
    const date_signed = this.formGroup.value.date_signed ? moment(this.formGroup.value.date_signed).format('MM/DD/YYYY') : '';

    this.data.user_clinic_id = this.clinic.id;
    this.data.patient_id = this.patientId;
    this.data.patient_name = this.patientName;
    this.data.due_date = due_date;
    this.data.clinic_address = this.formGroup.value.clinic_address;
    this.data.mobile_number = this.formGroup.value.mobile_no;
    this.data.telephone_number = this.formGroup.value.telephone_no;
    this.data.doctor_name = this.doctorName;
    this.data.dentist_signature = this.dentistSignature;
    this.data.dentist_name = this.dentistName;
    this.data.prc_license_no = this.formGroup.value.prc_license_no;
    this.data.receivers_signature = this.receiverSignature;
    this.data.received_by = this.formGroup.value.received_by;
    this.data.received_date = received_date; 
    this.data.date_signed = date_signed;

    let mouthguards = this.data.mouthguards.filter(item => item.includes('Color'));
    if(mouthguards.length > 0) {
      let x = this.data.mouthguards.indexOf(mouthguards[0]);

      if (this.formGroup.value.mouthguard_value_5 != '' && this.formGroup.value.mouthguard_value_5 != null) {
        this.data.mouthguards[x] = 'Color: '+ this.formGroup.value.mouthguard_value_5;
      } else {
        this.data.mouthguards[x] = 'Color';
      }
    }

    let acrylic_options = this.data.acrylic_options.filter(item => item.includes('Sticker'));
    if(acrylic_options.length > 0) {
      let x = this.data.acrylic_options.indexOf(acrylic_options[0]);

      if (this.formGroup.value.acrylic_option_value_5 != '' && this.formGroup.value.acrylic_option_value_5 != null) {
        this.data.acrylic_options[x] = 'Sticker: '+ this.formGroup.value.acrylic_option_value_5;
      } else {
        this.data.acrylic_options[x] = 'Sticker';
      }
    }

    this.data.acrylic_options_upper = this.formGroup.value.acrylic_options_upper;
    this.data.acrylic_options_lower = this.formGroup.value.acrylic_options_lower;

    this.forms.printAndDownloadOrthodonticsWorkAuth(this.data).subscribe(res => {
      if(res) {
        const url = URL.createObjectURL(res);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = url;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Print Failed. Please fill-in all the required fields.',
        button: 'Okay',
        event: this.close
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      const CLINIC_ADDRESS_REQUIRED = this.formGroup.value.clinic_address == '' ? 'The clinic address field is required.' : '';
      const DUE_DATE_REQUIRED = this.formGroup.value.due_date == '' ? 'The date field field is required.' : '';
      const DOCTOR_REQUIRED = this.formGroup.get('doctor_id').value == '' ? 'The dentist name field is required.' : '';
      const PATIENT_REQUIRED = this.formGroup.get('patient_id').value == '' ? 'The patient name field is required.' : '';
      const DOCTOR_INVALID = typeof this.formGroup.get('doctor_id').value == 'string' ? 'The dentist name is invalid.' : '';
      const PATIENT_INVALID = typeof this.formGroup.get('patient_id').value == 'string' ? 'The patient name is invalid.' : '';
      const DENTIST_INVALID = typeof this.formGroup.get('dentist_id').value == 'string' && this.formGroup.get('dentist_id').value !== '' ? 'The dentist name is invalid.' : '';
      this.errorMessage = {
        clinic_address: CLINIC_ADDRESS_REQUIRED,
        due_date: DUE_DATE_REQUIRED,
        doctor_id: DOCTOR_REQUIRED == '' ? DOCTOR_INVALID : DOCTOR_REQUIRED,
        patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
        dentist_id: DENTIST_INVALID,
      };
      this.triggerErrors({
        clinic_address: CLINIC_ADDRESS_REQUIRED,
        due_date: DUE_DATE_REQUIRED,
        doctor_id: DOCTOR_REQUIRED == '' ? DOCTOR_INVALID : DOCTOR_REQUIRED,
        patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
        dentist_id: DENTIST_INVALID,
      });
    }));
  }
  
  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }
}
