import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { UserService } from '../../services/user/user.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { keypressValidateNumber } from 'src/app/common/helper';
import { Router } from '@angular/router';

@Component({
  selector: 'ark-clinic-setup',
  templateUrl: './clinic-setup.component.html',
  styleUrls: ['./clinic-setup.component.scss']
})
export class ClinicSetupComponent implements OnInit {

  errorMessage = '';
  success = false;
  loading: boolean = false
  name = '';
  email = '';
  address = '';
  contact_number = '';

  formGroup: FormGroup;
  dialogConfig = new MatDialogConfig();

  role = [
    { id : '2', name: 'Dentist' },
    { id : '3', name: 'Assistant' }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private auth: AuthService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ClinicSetupComponent>, 
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      address: ['', [Validators.required]],
      contact_number: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      user_email: ['', [Validators.required, Validators.email]],
      role_id: ['', [Validators.required]]
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  next(){
    const data = {
      name: this.formGroup.value.name,
      email: this.formGroup.value.email,
      address: this.formGroup.value.address,
      contact_number: this.formGroup.value.contact_number
    }
    this.userService.checkClinic(data).subscribe(response => {
      if (response) {
        this.success = true;
      }
    },(err =>{
      this.dialogConfig.data = {
        title: 'Oops!',
        message: err.error.message,
        button: 'Okay',
        event: this.closeOnClick
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      this.errorMessage = err.error.errors;
      this.triggerErrors(err.error.errors);
    }));
  }

  skip() {
    this.userService.addClinic(this.formGroup.value, true).subscribe(res => {
      if (res) {
        this.success = true;
        this.dialogRef.close(res);
        this.auth.setAuthUser(res['data']);
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Clinic has been successfully added.',
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => {
          this._router.navigateByUrl('/login', {skipLocationChange: true}).then(()=>
          this._router.navigate(['/dashboard']));
        });
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: err.error.message,
        button: 'Okay',
        event: this.closeOnClick
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      this.errorMessage = err.error.errors;
      this.triggerErrors(err.error.errors);
    }));
  }

  save() {
    this.userService.addClinic(this.formGroup.value, false).subscribe(res => {
      if (res) {
        this.success = true;
        this.dialogRef.close(res);
        this.auth.setAuthUser(res['data']);
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Clinic has been successfully added.',
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => {
          this._router.navigateByUrl('/login', {skipLocationChange: true}).then(()=>
          this._router.navigate(['/dashboard']));
        });
      }
    },(err =>{
      this.dialogConfig.data = {
        title: 'Oops!',
        message: err.error.message,
        button: 'Okay',
        event: this.closeOnClick
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      this.errorMessage = err.error.errors;
      this.triggerErrors(err.error.errors);
    }));
  }
  
  closeOnClick() {
    this.dialogRef.close();
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }
}
