import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ark-directory',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.scss']
})
export class DirectoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
