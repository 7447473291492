import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

@Component({
  selector: 'ark-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  errorMessage = '';
  success = false;

  loginForm: FormGroup;

  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService, 
    private _router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]]
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  forgotPassword() {
    let data = {
      email : this.loginForm.value.email,
    };
    this.auth.requestPassword(data).subscribe(response => {
      if (response) {
        this.success = true;
        this.dialogConfig.data = {
          title: 'Email Sent!',
          message: 'An email has been sent to your inbox. \nIf you don\'t see the email, please check your spam folder.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => this.backToLogin());
      }
    },(err => {
      if (err.error.errors === undefined) {
        this.errorMessage = err.error.message;
      } else {
        this.errorMessage = err.error.errors.email[0];
      }
      this.triggerErrors();
    }));
  }

  triggerErrors() {
    this.loginForm.controls['email'].setErrors({'invalid': true});
    this.loginForm.controls['email'].markAsTouched();
    this.loginForm.controls['email'].markAsDirty();
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  backToLogin() {
    this._router.navigate(["/login"]);
  }
}
