import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'ark-subscribe-clinic',
  templateUrl: './subscribe-clinic.component.html',
  styleUrls: ['./subscribe-clinic.component.scss']
})
export class SubscribeClinicComponent implements OnInit {

  error_message: string;
  isDeactivated: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SubscribeClinicComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.error_message = this.data.error_message;
    if(this.error_message == "You have been deactivated to Dental Pro App. If you wish to continue using our services, please subscribe again. Thank you!") {
      this.isDeactivated = true;
    } else {
      this.isDeactivated = false;
    }
  }

  close(flag = false) {
    this.dialogRef.close(flag);
  }
}
