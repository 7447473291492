import { Component, OnInit, OnChanges, ViewChild } from '@angular/core';
import { PatientsDirectoryService } from '../../services/patients-directory/patients-directory.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { EditRadiographComponent } from './edit/edit-radiograph/edit-radiograph.component';
import { ConfirmDeletionComponent } from 'src/app/modules/shared/components/confirm-deletion/confirm-deletion.component';
import { ViewRadiographsComponent } from './view/view-radiographs/view-radiographs.component';
import { GalleryItem, ImageItem, Gallery, GalleryConfig } from '@ngx-gallery/core';

@Component({
  selector: 'ark-radiographs',
  templateUrl: './radiographs.component.html',
  styleUrls: ['./radiographs.component.scss']
})
export class RadiographsComponent implements OnInit, OnChanges {

  @ViewChild('file') file: any; 
  
  patient = '';
  patientFirstname = '';
  patientId = '';
  patientRadiographs = [];
  photoId = '';
  isNoResult: boolean = true;
  success = false;
  myFiles: string [] = [];
  fileData = null;
  clinic = null;
  radiograph: GalleryItem[];

  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  constructor(
    private patientsDirectory: PatientsDirectoryService,
    private route: ActivatedRoute,
    private auth: AuthService,
    public dialog: MatDialog,
    public gallery: Gallery
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.route.params.subscribe(param => {
      this.patientId = param.id
    });
    this.viewRadioGraph();
  }

  ngOnInit() {

  }

  ngOnChanges() {
    this.viewRadioGraph();
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  viewRadioGraph() {
    const config: GalleryConfig = {
      loadingMode: "indeterminate"
    };
    let id = this.auth.getAuthUser().user_clinic.id;
    this.patientsDirectory.getPatientInfo(id, this.patientId, this.clinic).subscribe(response =>{
      this.patient = response['data'][0].last_name;
      this.patientFirstname = response['data'][0].first_name;
      this.patientRadiographs = response['data'][0].radiographs;
      if(this.patientRadiographs.length > 0) {
        this.isNoResult = false;
        /** Lightbox */
        this.radiograph = this.patientRadiographs.map(item =>
          new ImageItem({ src: item.photo_url, thumb: item.photo_url , id: item.id, photo_url: item.photo_url, name: item.name})
        );
        // Load items into the lightbox
        this.gallery.ref().setConfig(config);
        this.gallery.ref().load(this.radiograph);
      } else {
        this.isNoResult = true;
      }
    });
  }

  upload(fileInput: any) {
    this.fileData = <File>fileInput.target.files;
    const file = this.fileData;
    const formData = new FormData();
    if(file.length > 0) {
      for(let i= 0; i < file.length; i++){
        formData.append('radiographs[]', file.item(i), file.item(i).name);
      }
      this.patientsDirectory.uploadRadiograph(this.patientId, this.clinic, formData).subscribe(res => {
        if(res) {
          this.success = true;
          this.viewRadioGraph();
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Radiographs has been successfully uploaded.',
            button: 'Okay',
            event: this.closeOnClick
          };
          this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Sorry!',
          message: err.error.errors['radiographs.0'][0],
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }));
    }
  }

  editRadiograph(id, url, name) {
    this.dialogConfig.data = {
      id: id,
      patient_id: this.patientId,
      url: url,
      name: name,
      clinic_id: this.clinic
    };
    this.dialogRef = this.dialog.open(EditRadiographComponent, this.dialogConfig).afterClosed().subscribe(response => {
      if(response) {
        this.success = true;
        this.viewRadioGraph();
      }
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  delete(id) {
    this.dialogConfig.data = {
      subject: 'delete this radiograph'
    };
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    let dialogRef = this.dialog.open(ConfirmDeletionComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
      if(trigger) {
         let data = { 
          user_clinic_id: this.auth.getAuthUser().user_clinic.id,
          patient_id: this.patientId
        }
        this.patientsDirectory.deleteRadiograph(id, this.clinic).subscribe(res => {
          if(res) {
            this.success = true;
            this.viewRadioGraph();
            this.dialogConfig.data = {
              title: 'Success!',
              message: 'Radiograph has been successfully deleted.',
              button: 'Okay',
              event: this.closeOnClick
            };
            this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
          }
        });
      }
    });
  }

  download(id, name) {
    this.patientsDirectory.downloadRadiograph(id, id, this.clinic).subscribe(res => {
      if(res) {
        const str = res.type;
        const n = str.lastIndexOf('/');
        const ext = str.substring(n + 1);

        const a = document.createElement('a');
        a.href = URL.createObjectURL(res);
        a.download = name + '.' + ext;
        document.body.appendChild(a);
        a.click();
      }
    });
  }

  downloadAll() {
    this.patientsDirectory.bulkDownloadRadiograph(this.patientId, this.clinic).subscribe(res => {
      if(res) {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(res);
        a.download = this.patient + '_' + this.patientFirstname + '_radiographs' + '.zip';
        document.body.appendChild(a);
        a.click();

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(URL.createObjectURL(res));
          a.remove();
      }, 500);
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Bulk Download Failed. Patient has no photos yet.',
        button: 'Okay',
        event: this.closeOnClick
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }));
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  openImage(url) {
    this.dialogConfig.data = {
      url: url
    };
    this.dialogConfig.disableClose = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogRef = this.dialog.open(ViewRadiographsComponent, this.dialogConfig);
  }
}
