import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/modules/shared/services/http/http.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { laboratoryWorks } from 'src/app/common/api';

@Injectable()
export class LaboratoryWorksService extends HttpService {

  constructor(http: HttpClient, private _router: Router) {
    super('',http);
  }

  saveLabWork(data) {
    return this.http.post(laboratoryWorks, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  getLabWork(id, from, to, patient_name) {
    return this.get(laboratoryWorks + '?user_clinic_id=' + id + '&from=' + from + '&to=' + to + '&patient_name=' + patient_name).map(res => {
      return res['data'];
    });
  }

  deleteLabWork(id, user_clinic_id) {
    return this.http.delete(laboratoryWorks + '/' + id + '?user_clinic_id=' + user_clinic_id).map(res => {
      return res['data'];
    });
  }

  updateLabWork(id, data) {
    return this.http.put(laboratoryWorks + '/' + id, data).map((dataResponse) => {
      return dataResponse;
    })
  }
}