import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ark-patient-visit-info',
  templateUrl: './patient-visit-info.component.html',
  styleUrls: ['./patient-visit-info.component.scss']
})
export class PatientVisitInfoComponent implements OnInit, OnChanges {

  @Input() selectedTab: number; 
  @Input() previousVisitId: any = null; 
  @Input() isDisabled: any = null;
  @Output() isNew: EventEmitter<any> = new EventEmitter;
  selectedIndex: number = 0;
  previousInfoId: any;
  visitInfoId: any;
  dentistId: any;
  data: any;
  disable = true;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    if(this.previousVisitId) {
      this.previousInfoId = this.previousVisitId;
    }
    if(this.selectedTab || this.isDisabled) {
      this.selectedIndex = this.selectedTab;
      this.disable = this.isDisabled;
    }
  }
  
  selectedIndexChange(val) {
    this.selectedIndex = val;
  }

  getVisitId(id) {
    this.visitInfoId = id;
  }

  enableTab(val) {
    this.disable = val;
  }

  getDentistId(id) {
    this.dentistId = id;
  }

  newVisitInfo(val) {
    this.isNew.emit(val);
  }
}
