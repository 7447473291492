import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ark-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {

  @Output() onClick = new EventEmitter<any>();

  constructor() { }

  onClickButton(event) {
    this.onClick.emit(event);
  }

  ngOnInit() {
  }

}
