import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard/dashboard.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { PatientService } from '../../service/patient/patient.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import * as moment from 'moment';

@Component({
  selector: 'ark-patient-billing',
  templateUrl: './patient-billing.component.html',
  styleUrls: ['./patient-billing.component.scss']
})
export class PatientBillingComponent implements OnInit {

  filters = [
    { name: 'Patient Billings', value: 'billing' },
    { name: 'Patient Balances', value: 'balances' }
  ];
  dentists = [];
  selected: string = 'billing';
  header: string = 'Billings';
  billingView: boolean = true;
  balanceView: boolean = false;
  today = new Date(); 
  formGroup: FormGroup;
  clinic = null;
  start = '';
  end = '';
  dentist = '';
  val = '';
  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  constructor(
    private formBuilder: FormBuilder,
    private dashboard: DashboardService,
    private auth: AuthService,
    private patient: PatientService,
    public dialog: MatDialog
  ) { 
    this.formGroup = this.formBuilder.group({
      dentist_id: [''],
      from: [''],
      to: [''],
    });
    if(localStorage.getItem('dailyReportDate')) {
      const date = localStorage.getItem('dailyReportDate');

      this.formGroup.get('from').setValue(date);
      this.formGroup.get('to').setValue(date);
      localStorage.removeItem('dailyReportDate');
    } else {
      this.formGroup.get('from').setValue(this.today);
      this.formGroup.get('to').setValue(this.today);
    }
  }

  ngOnInit() {
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.dashboard.getDentistList(this.clinic).subscribe(res => {
      this.dentists = res;
      this.dentist = this.formGroup.value.dentist_id;
      this.start = this.formGroup.value.from;
      this.end = this.formGroup.value.to;
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  onClickButton(value) {
    this.selected = value;
    if(value == 'billing') {
      this.header = 'Billings';
      this.billingView = true;
      this.balanceView = false;
    } else if (value == 'balances') {
      this.header = 'Balances';
      this.billingView = false;
      this.balanceView = true;
    }
  }

  getStartDate(from) {
    this.start = from.value;
  }

  getEndDate(to) {
    this.end = to.value;
  }

  getDentist(value) {
    this.dentist = value.source.value;
  }

  getText(val) {
    if(val) {
      this.val = val;
    } else {
      this.val = '';
    }
  }

  generatePDF() {
    const from =  moment(this.start).format("YYYY-MM-DD");
    const to =  moment(this.end).format("YYYY-MM-DD");
    if(this.selected == 'billing') {
      this.patient.downloadPatientBilling(this.clinic, this.dentist, from, to, this.val).subscribe(res => {
        if(res) {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(res);
          a.download = moment(this.today).format("YYYYMMDD") + '-PATIENT BILLING (' + moment(from).format('MMM DD, YYYY') + ' to ' + moment(to).format('MMM DD, YYYY') + ').pdf';
          document.body.appendChild(a);
          a.click();
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'Exporting of PDF failed.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }));
    } else if(this.selected == 'balances') {
      this.patient.downloadPatientBalances(this.clinic, this.dentist, from, to, this.val).subscribe(res => {
        if(res) {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(res);
          a.download = moment(this.today).format("YYYYMMDD") + '-PATIENT BALANCES (' + moment(from).format('MMM DD, YYYY') + ' to ' + moment(to).format('MMM DD, YYYY') + ').pdf';
          document.body.appendChild(a);
          a.click();
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'Exporting of PDF failed.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }));
    }
  }

  sendEmail() {
    const from =  moment(this.start).format("YYYY-MM-DD");
    const to =  moment(this.end).format("YYYY-MM-DD");
    if(this.selected == 'billing') {
      this.patient.emailPatientBilling(this.clinic, this.dentist, from, to, this.val).subscribe(res => {
        if(res) {
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Patient billing has been emailed successfully.',
            button: 'Okay',
            event: this.closeOnClick
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }
      });
    } else if(this.selected == 'balances') {
      this.patient.emailPatientBalances(this.clinic, this.dentist, from, to, this.val).subscribe(res => {
        if(res) {
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Patient balances has been emailed successfully.',
            button: 'Okay',
            event: this.closeOnClick
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }
      });
    }
  }

  closeOnClick() {
    this.dialogRef.close();
  }
}
