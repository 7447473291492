import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { keypressValidateNumber } from 'src/app/common/helper'
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { MatDialogConfig, MatDialog, MatAutocompleteTrigger } from '@angular/material';
import { DentalFormsService } from '../../../services/dental-forms/dental-forms.service';
import { Observable } from 'rxjs';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard/dashboard.service';
import { startWith, map } from 'rxjs/operators';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import * as moment from 'moment';
import { ESignatureComponent } from 'src/app/modules/shared/components/e-signature/e-signature.component';

export interface Patient {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
}

export interface Dentist {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
}

@Component({
  selector: 'ark-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {

  treatmentConsent = {
    instructions: [
      {
        id: 1,
        header: 'It is the patient’s responsibility to ensure that he/she fully understands and is comfortable with the benefits, risks, and potential complications associated with the proposed treatment.',
        hasContent: false
      },
      {
        id: 2,
        header: 'Diagnostic procedures may involve the following:',
        hasContent: true,
        content: {
          id: 1,
          name: '',
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '• Dental X-rays - to examine the patient’s teeth and jaw',
              hasOthers: false,
            },
            {
              id: 2,
              name: '• CT scan - to provide detailed images of the bones involved in the joint',
              hasOthers: false,
            },
            {
              id: 3,
              name: '• MRI - to reveal problems with the joint\'s disk or surrounding soft tissue',
              hasOthers: false,
            },
            {
              id: 4,
              name: '• TMJ arthroscopy - is used to view the area and to help determine a diagnosis',
              hasOthers: false,
            }
          ]
        }
      },
      {
        id: 3,
        header: 'These medication options may help relieve the pain associated with TMJ disorders. Patient shall inform the doctor for known allergies to the following:',
        hasContent: true,
        content: {
          id: 1,
          name: '',
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '• Pain relievers and anti-inflammatories',
              hasOthers: false,
            },
            {
              id: 2,
              name: '• Tricyclic antidepressants- for pain relief, bruxism control and sleeplessness',
              hasOthers: false,
            },
            {
              id: 3,
              name: '• Muscle relaxants',
              hasOthers: false,
            }
          ]
        }
      },
      {
        id: 4,
        header: 'To relax various groups of muscles, to restore normal function as best as possible, and to provide a degree of pain relief the treatment may involve the following:',
        hasContent: true,
        content: {
          id: 1,
          name: '', 
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '• Fabrication and maintenance of various appliances (the long-time wearing of one of these may be detrimental, professional guidance is necessary)',
              hasOthers: false,
            },
            {
              id: 2,
              name: '• Physical therapy modalities (at the office or by a physical therapist)',
              hasOthers: false,
            },
            {
              id: 3,
              name: '• Exercises',
              hasOthers: false,
            },
            {
              id: 4,
              name: '• Medication',
              hasOthers: false,
            },
            {
              id: 5,
              name: '• Stress management - since stress is commonly a contributing factor',
              hasOthers: false,
            }
          ]
        }
      },
      {
        id: 5,
        header: 'The following complications are rare but may happen:',
        hasContent: true,
        content: {
          id: 1,
          name: '', 
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '• Minor tooth movement',
              hasOthers: false,
            },
            {
              id: 2,
              name: '• Loosened teeth or dental restorations',
              hasOthers: false,
            },
            {
              id: 3,
              name: '• Sore mouth',
              hasOthers: false,
            },
            {
              id: 4,
              name: '• Periodontal problems',
              hasOthers: false,
            },
            {
              id: 5,
              name: '• Muscle spasms',
              hasOthers: false,
            },
            {
              id: 6,
              name: '• Ear and neck pain',
              hasOthers: false,
            },
            {
              id: 7,
              name: '• Prolonged numbness of the area, nerve and tissue damage, hematomas and discomfort - upon administration of anesthetics',
              hasOthers: false,
            },
            {
              id: 8,
              name: '• Shifts in the position of the teeth or the relationship of the upper and lower jaw',
              hasOthers: false,
            }
          ]
        }
      },
      {
        id: 6,
        header: 'CONSENT',
        hasContent: true,
        content: {
          id: 1,
          name: 'By signing this consent form, I acknowledge that I have read it completely and understand the treatment to be performed, the risks, and the alternatives to surgery. I have had all of my questions answered to my satisfaction.',
          hasSubContent: false
        }
      },
      {
        id: 7,
        header: '',
        hasContent: true,
        content: {
          id: 1,
          name: 'I was under no pressure to sign this form and have made a voluntary choice to proceed with the treatment.',
          hasSubContent: false
        }
      }
    ]
  }

  orthodonticConsent = {
    instructions: [
      {
        id: 1,
        title: 'The patient understands and is aware that orthodontic treatment has limitations and potential risks, to wit:',
        header: '',
        hasContent: false,
        isTitle: true
      },
      {
        id: 2,
        header: 'RISKS',
        hasContent: true,
        content: {
          id: 1,
          name: '',
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '• Allergies',
              hasOthers: false,
            },
            {
              id: 2,
              name: '• General health problems',
              hasOthers: false,
            },
            {
              id: 3,
              name: '• Decalcification and dental caries',
              hasOthers: false,
            },
            {
              id: 4,
              name: '• Root resorption',
              hasOthers: false,
            },
            {
              id: 5,
              name: '• Nerve damage',
              hasOthers: false,
            },
            {
              id: 6,
              name: '• Periodontal disease',
              hasOthers: false,
            },
            {
              id: 7,
              name: '• Injury from orthodontic appliance',
              hasOthers: false,
            },
            {
              id: 8,
              name: '• Temporo-mandibular joint dysfunction',
              hasOthers: false,
            },
            {
              id: 9,
              name: '• Impacted, ankylosed, unerupted teeth',
              hasOthers: false,
            }
          ]
        }
      },
      {
        id: 3,
        header: 'TREATMENT TIME',
        hasContent: true,
        content: {
          id: 1,
          name: 'The total time for treatment can be delayed beyond the estimate. The following lengthen the treatment time and affect the quality of the result:',
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '• Lack of cooperation',
              hasOthers: false
            },
            {
              id: 2,
              name: '• Broken appliances',
              hasOthers: false
            },
            {
              id: 3,
              name: '• Missed appointments',
              hasOthers: false
            }
          ]
        }
      },
      {
        id: 4,
        header: 'RELAPSE',
        hasContent: true,
        content: {
          id: 1,
          name: 'Full co-operation in wearing these appliances is vital. We will make our correction to the highest standards and in many cases overcorrect in order to accommodate the rebound tendencies.', 
          hasSubContent: false
        }
      },
      {
        id: 5,
        header: 'EXPECTATIONS',
        hasContent: true,
        content: {
          id: 1,
          name: 'All orthodontic patients can expect improvement with their particular problem but in many cases, absolute perfection is impossible due to:', 
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '• lack of muscle balance',
              hasOthers: false
            },
            {
              id: 2,
              name: '• tooth shapes and sizes',
              hasOthers: false
            },
            {
              id: 3,
              name: '• patient compliance',
              hasOthers: false
            },
            {
              id: 4,
              name: '• heredity aspects that affect everyone’s specific treatment results',
              hasOthers: false
            }
          ]
        }
      },
      {
        id: 6,
        header: 'PATIENT’S RESPONSIBILITIES',
        hasContent: true,
        content: {
          id: 1,
          name: '',
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '1. Maintain good oral hygiene by brushing and flossing',
              hasOthers: false,
            },
            {
              id: 2,
              name: '2. Attend regular appointments',
              hasOthers: false,
            },
            {
              id: 3,
              name: '3. Maintaining a low sugar diet',
              hasOthers: false,
            },
            {
              id: 4,
              name: '4. Wear retainers following treatment as specified',
              hasOthers: false,
            }
          ]
        }
      },
      {
        id: 7,
        header: 'TERMINATION',
        hasContent: true,
        content: {
          id: 1,
          name: 'If termination is necessary, the patient will be given ample time to locate another dentist to continue treatment or the braces will be removed. It is understood that treatment can be terminated by the following:',
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '• Failure to cooperate',
              hasOthers: false
            },
            {
              id: 2,
              name: '• Missing appointments',
              hasOthers: false
            },
            {
              id: 3,
              name: '• Not wearing appliances',
              hasOthers: false
            },
            {
              id: 4,
              name: '• Excessive breakage',
              hasOthers: false
            },
            {
              id: 5,
              name: '• Failure to keep financial commitments',
              hasOthers: false
            },
            {
              id: 6,
              name: '• Relocation',
              hasOthers: false
            },
            {
              id: 7,
              name: '• Personal conflicts',
              hasOthers: false
            },
            {
              id: 8,
              name: '• Any other reason the doctor feels necessary',
              hasOthers: false
            }
          ]
        }
      },
      {
        id: 8,
        header: 'COST AND PAYMENT SCHEDULE FOR ORTHODONTIC TREATMENT',
        hasContent: true,
        content: {
          id: 1,
          name: '',
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: 'Installation Fee:',
              hasOthers: true,
              others : [
                {
                  id: 1,
                  name: 'Upper PHP',
                  hasResultNeeded: true,
                  isUpper: true,
                },
                {
                  id: 1,
                  name: 'Lower PHP',
                  hasResultNeeded: true,
                  isLower: true,
                },
                {
                  id: 1,
                  name: 'Total PHP',
                  hasResultNeeded: true,
                  isTotal: true,
                }
              ]
            },
            {
              id: 2,
              name: 'Adjustment Fee:',
              hasOthers: true,
              others : [
                {
                  id: 1,
                  name: 'PHP',
                  hasResultNeeded: true,
                  name2: '/adjustment',
                  isFee: true,
                }
              ]
            }
          ]
        }
      },
      {
        id: 9,
        header: 'ADDITIONAL CHARGES WILL APPLY FOR THE FOLLOWING',
        hasContent: true,
        content: {
          id: 1,
          name: '',
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '1. Lost / broken / replacement of the brackets / wire / buccal tubes/ molar bands /appliance',
              hasOthers: false,
            },
            {
              id: 2,
              name: '2. Recementation of brackets / molar bands / buccal tubes / appliance',
              hasOthers: false,
            },
            {
              id: 3,
              name: '3. Other orthodontic treatment auxiliaries such as elastics and mini-screws (TADS)',
              hasOthers: false,
            },
            {
              id: 4,
              name: '4. Retainers (Fixed lingual retainers and removable retainer)',
              hasOthers: false,
            },
            {
              id: 5,
              name: '5. Other dental procedures not included in orthodontic treatment such as oral prophylaxis (cleaning), fillings, etc.',
              hasOthers: false,
            }
          ]
        }
      },
      {
        id: 10,
        header: 'CONSENT TO UNDERGO ORTHODONTIC TREATMENT',
        hasContent: true,
        content: {
          id: 1,
          name: 'I certify that I have read and understand the contents of this form. I understand and realize the risks and limitations involved in orthodontic treatment, and I consent to orthodontic treatment.',
          hasSubContent: false
        }
      },
      {
        id: 11,
        header: 'AUTHORIZATION FOR RELEASE OF PATIENT INFORMATION',
        hasContent: true,
        content: {
          id: 1,
          name: 'I hereby authorize the doctors to provide other health care providers with information regarding the above individual’s orthodontic care as deemed appropriate. I understand that once released, the doctors and staff have no responsibility for any further release by the individual receiving this information.',
          hasSubContent: false
        }
      },
      {
        id: 12,
        header: 'CONSENT TO USE OF RECORDS',
        hasContent: true,
        content: {
          id: 1,
          name: 'I hereby give my permission for the use of orthodontic records, including photographs, made in the process of examinations, treatment, and retention for purposes of professional consultations, research, education, or publication of professional journals.',
          hasSubContent: false
        }
      }
    ]
  }

  informedConsent = {
    instructions: [
      {
        id: 1,
        header: 'WORK TO BE DONE',
        hasContent: true,
        content: {
          id: 1,
          name: 'I understand the following treatments to perform:',
          hasSubContent: true,
          hasOptions: true,
        }
      },
      {
        id: 2,
        header: 'CHANGES IN TREATMENT PLAN',
        hasContent: true,
        content: {
          id: 1,
          name: 'I understand that during treatment it may be inevitable to change or add procedures because of the conditions found while working on the teeth which were not discovered during examination. I give my permission to the dentist to make any/all changes and additions as necessary.',
          hasSubContent: false
        }
      },
      {
        id: 3,
        header: 'RADIOGRAPH',
        hasContent: true,
        content: {
          id: 1,
          name: 'I give consent for dental radiograph as needed and I understand that:', 
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '• All necessary precautions will be taken to ensure exposure is minimal',
              hasOthers: false
            },
            {
              id: 2,
              name: '• The dental office reserves the right to dismiss any patient or family that denies necessary dental x-rays as this limits the ability of the dentist to identify and diagnose any dental conditions',
              hasOthers: false
            },
            {
              id: 3,
              name: '• Radiograph remains the property of the clinic',
              hasOthers: false
            }
          ]
        }
      },
      {
        id: 4,
        header: 'DRUGS AND MEDICATIONS',
        hasContent: true,
        content: {
          id: 1,
          name: 'I declare that I have no known allergies, I understand that analgesics, antibiotics and other medications can cause allergic reactions like:', 
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '• Mild skin rash',
              hasOthers: false
            },
            {
              id: 2,
              name: '• Itching',
              hasOthers: false
            },
            {
              id: 3,
              name: '• Difficulty in breathing',
              hasOthers: false
            },
            {
              id: 4,
              name: '• Facial swelling',
              hasOthers: false
            },
            {
              id: 5,
              name: '• Anaphylaxis (severe allergic reaction)',
              hasOthers: false
            },
            {
              id: 6,
              name: '• Soft stools or short-term diarrhea',
              hasOthers: false
            },
            {
              id: 7,
              name: '• Upset stomach',
              hasOthers: false
            },
            {
              id: 8,
              name: '• Pain',
              hasOthers: false
            }
          ]
        }
      },
      {
        id: 5,
        header: 'LOCAL ANESTHETICS',
        hasContent: true,
        content: {
          id: 1,
          name: 'I understand the risks involved in local anesthetic administration such as, but not limited to:',
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '• Prolonged numbness beyond normal',
              hasOthers: false
            },
            {
              id: 2,
              name: '• Nerve damage',
              hasOthers: false
            },
            {
              id: 3,
              name: '• Bruising or Hematoma',
              hasOthers: false
            },
            {
              id: 4,
              name: '• Allergic reaction',
              hasOthers: false
            }
          ]
        }
      },
      {
        id: 6,
        header: 'ORAL SURGERY, DENTAL EXTRACTION AND DENTAL IMPLANTS',
        hasContent: true,
        content: {
          id: 1,
          name: 'I understand that oral surgery dental implants, and/or dental extractions include inherent risks such as, but not limited to the following:',
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '• Injury to the nerve',
              hasOthers: false
            },
            {
              id: 2,
              name: '• Bleeding, bruising and swelling',
              hasOthers: false
            },
            {
              id: 3,
              name: '• Dry Socket',
              hasOthers: false
            },
            {
              id: 4,
              name: '• Sinus involvement',
              hasOthers: false
            },
            {
              id: 5,
              name: '• Post-surgical infection',
              hasOthers: false
            },
            {
              id: 6,
              name: '• Fractured jaw, roots, bone fragments, or instruments',
              hasOthers: false
            },
            {
              id: 7,
              name: '• Injury to adjacent teeth or fillings',
              hasOthers: false
            },
            {
              id: 8,
              name: '• Bacterial endocarditis',
              hasOthers: false
            },
            {
              id: 9,
              name: '• Unusual reactions to medications',
              hasOthers: false
            },
            {
              id: 10,
              name: '• Paresthesia – numbness of the lips, chin and tongue',
              hasOthers: false
            }
          ]
        }
      },
      {
        id: 7,
        header: 'RESTORATIVE FILLING',
        hasContent: true,
        content: {
          id: 1,
          name: 'I agree that there is the possibility of failure to achieve the results expected and I agree to assume these risks which may occur even with care:',
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '• Injury to the nerve, particularly in administering local anesthesia',
              hasOthers: false
            },
            {
              id: 2,
              name: '• Shrinkage in time, may require replacement',
              hasOthers: false
            },
            {
              id: 3,
              name: '• Discoloration',
              hasOthers: false
            },
            {
              id: 4,
              name: '• Sensitivity to temperature change',
              hasOthers: false
            },
            {
              id: 5,
              name: '• Filling may not exactly match the shade of the tooth',
              hasOthers: false
            },
            {
              id: 6,
              name: '• Excavation of diseased or compromised tooth structure may result to trauma to underlying pulp tissue requiring Root Canal Treatment',
              hasOthers: false
            },
            {
              id: 7,
              name: '• Breakage, dislodgement or bond failure',
              hasOthers: false
            },
            {
              id: 8,
              name: '• Feel high and you may need to come back to have the filling adjusted',
              hasOthers: false
            }
          ]
        }
      },
      {
        id: 8,
        header: 'FLUORIDE TREATMENT',
        hasContent: true,
        content: {
          id: 1,
          name: 'I understand the benefits of fluoride treatment and the risks involved are as follows:',
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '• Allergic reaction',
              hasOthers: false
            },
            {
              id: 2,
              name: '• Sores in the mouth',
              hasOthers: false
            },
            {
              id: 3,
              name: '• Nausea or vomiting if swallowed',
              hasOthers: false
            },
            {
              id: 4,
              name: '• Tiredness',
              hasOthers: false
            }
          ]
        }
      },
      {
        id: 9,
        header: 'TEETH WHITENING',
        hasContent: true,
        content: {
          id: 1,
          name: 'I attest and understand the following:',
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '• There is no guarantee as to the degree of whitening of my teeth',
              hasOthers: false
            },
            {
              id: 2,
              name: '• Smoking, colored food and drinks will hinder progress of the whitening',
              hasOthers: false
            },
            {
              id: 3,
              name: '• No known sensitivity or allergies to any bleaching products',
              hasOthers: false
            },
            {
              id: 4,
              name: '• Not pregnant or breastfeeding',
              hasOthers: false
            },
            {
              id: 5,
              name: '• Bleaching procedure may need to be stopped due to sensitivity',
              hasOthers: false
            },
            {
              id: 6,
              name: '• Following the completion of whitening, pigments found in food and drinks will re-stain teeth \'bleaching relapse\'',
              hasOthers: false
            },
            {
              id: 7,
              name: '• White spots may occur when the whitening solution gets in contact with the soft tissues',
              hasOthers: false
            },
            {
              id: 8,
              name: '• Will not whiten caps, crowns, veneers, fillings, dentures or any other type of dental treatment',
              hasOthers: false
            }
          ]
        }
      },
      {
        id: 10,
        header: 'PERIODONTAL TREATMENT',
        hasContent: true,
        content: {
          id: 1,
          name: 'I understand that the following are true and correct:',
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '• Bleeding, pain, bruising and swelling may be experienced after treatment',
              hasOthers: false
            },
            {
              id: 2,
              name: '• Some conditions caused by periodontal disease are irreversible',
              hasOthers: false
            },
            {
              id: 3,
              name: '• Maintaining regular periodontal cleaning is essential',
              hasOthers: false
            },
            {
              id: 4,
              name: '• Future re-treatment like scaling and root planing may be necessary',
              hasOthers: false
            },
            {
              id: 5,
              name: '• Own efforts with home care are just as important as my professional treatment',
              hasOthers: false
            }
          ]
        }
      },
      {
        id: 11,
        header: 'ROOT CANAL TREATMENT',
        hasContent: true,
        content: {
          id: 1,
          name: 'I understand that the following are unlikely but may occur and may include but not limited to:',
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '• Instrument separation in the canal',
              hasOthers: false
            },
            {
              id: 2,
              name: '• Blocked canals that cannot ideally be completed',
              hasOthers: false
            },
            {
              id: 3,
              name: '• Canal perforation with instruments',
              hasOthers: false
            },
            {
              id: 4,
              name: '• Post-operative infection, pain or discomfort requiring medications',
              hasOthers: false
            },
            {
              id: 5,
              name: '• Reactions to chemicals, anesthetic or medications',
              hasOthers: false
            },
            {
              id: 6,
              name: '• Tooth and or root fracture that may require extraction',
              hasOthers: false
            },
            {
              id: 7,
              name: '• Incomplete healing',
              hasOthers: false
            },
            {
              id: 8,
              name: '• Chipping of tooth or loosening of the existing crown',
              hasOthers: false
            },
            {
              id: 9,
              name: '• Temporary or permanent numbness',
              hasOthers: false
            },
            {
              id: 10,
              name: '• TMJ problems or TMD due to prolonged mouth opening',
              hasOthers: false
            },
            {
              id: 11,
              name: '• Treatment failure due to the untimely placement of final restoration',
              hasOthers: false
            },
            {
              id: 12,
              name: '• Treatment failure due to poor patient compliance',
              hasOthers: false
            },
            {
              id: 13,
              name: '• Tooth discoloration',
              hasOthers: false
            },
            {
              id: 14,
              name: '• May require Apicoectomy or root-end surgery',
              hasOthers: false
            }
          ]
        }
      }, 
      {
        id: 12,
        header: 'CROWNS, VENEERS, INLAYS, ONLAYS AND BRIDGES',
        hasContent: true,
        content: {
          id: 1,
          name: 'I agree to assume these risks, possible unsuccessful results and/or failures associated with but not limited to the following:',
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '• Sensitivity of prepared teeth',
              hasOthers: false
            },
            {
              id: 2,
              name: '• Abutments may require root canal treatment subsequently',
              hasOthers: false
            },
            {
              id: 3,
              name: '• Chipping or breakage is possible',
              hasOthers: false
            },
            {
              id: 4,
              name: '• No guarantee can be made on its longevity',
              hasOthers: false
            },
            {
              id: 5,
              name: '• Reactions to chemicals, anesthetic or medications',
              hasOthers: false
            },
            {
              id: 6,
              name: '• Matching the color of the artificial with the natural teeth may be impossible',
              hasOthers: false
            },
            {
              id: 7,
              name: '• Temporary crowns are essential and must be worn with care',
              hasOthers: false
            },
            {
              id: 8,
              name: '• Changes in the final restoration must be done prior to cementation',
              hasOthers: false
            },
            {
              id: 9,
              name: '• Excessive delays on final cementation may necessitate a remake and additional charges',
              hasOthers: false
            },
            {
              id: 10,
              name: '• Improper oral hygiene may lead to treatment failure',
              hasOthers: false
            }
          ]
        }
      }, 
      {
        id: 13,
        header: 'DENTURES',
        hasContent: true,
        content: {
          id: 1,
          name: 'I understand the possible risks which include, but are not limited to:',
          hasSubContent: true,
          sub_content: [
            {
              id: 1,
              name: '• Sore spots, altered speech and difficulty in chewing in the first few days',
              hasOthers: false
            },
            {
              id: 2,
              name: '• Loose dentures due to changes in supporting gum tissues may require relining and additional charges',
              hasOthers: false
            },
            {
              id: 3,
              name: '• Allergies to dental materials used during the processing of the denture',
              hasOthers: false
            },
            {
              id: 4,
              name: '• Excessive gagging reflexes',
              hasOthers: false
            },
            {
              id: 5,
              name: '• General physical or psychological problems interfering with success',
              hasOthers: false
            }
          ]
        }
      }, 
      {
        id: 14,
        header: 'AMALGAM REMOVAL',
        hasContent: true,
        content: {
          id: 1,
          name: 'I have been given the opportunity to ask any questions regarding the nature and purpose of Mercury Removal procedure and have received answers to my satisfaction. I do voluntarily assume all possible risks. No guarantees or promises have been made to me concerning my recovery and the results of the treatment rendered to me. I hereby authorize my dentist to remove my amalgam fillings and other non-precious metal restorations.',
          hasSubContent: false
        }
      }, 
      {
        id: 15,
        header: 'CONSENT',
        hasContent: true,
        content: {
          id: 1,
          name: 'I understand that dentistry is not an exact science therefore, reputable practitioners cannot properly guarantee results. I understand the nature, benefits, risks and duration of the treatment as provided and discussed by the treating dentist. Alternative treatments have been explained to me but I have chosen the one thing that best suits my needs. I will not hold the doctor liable to any untoward complications of the treatment or medications rendered due to deception and/or incomplete disclosure of information on my part.',
          hasSubContent: false
        }
      },
      {
        id: 16,
        header: '',
        hasContent: true,
        content: {
          id: 1,
          name: 'I authorize any of the doctors of dental auxiliaries to proceed with, and perform the dental procedures and treatments as explained to me. I understand that regardless of any dental insurance that I have, I am responsible for payment of my dental fees. I agree to pay any attorney’s fees, collection fees, or court costs that may be incurred to satisfy this obligation.',
          hasSubContent: false
        }
      },
      {
        id: 17,
        header: '',
        hasContent: true,
        content: {
          id: 1,
          name: 'I agree/consent to RELEASE/FREE the Clinic, Its shareholders, corporators, incorporators, stockholders, members, owners, employees, agents, representatives, officials, successors and assigns from and against all claims, actions, cost of action, costs, expenses and demands of any nature or kind whatsoever in respect to death, injury, loss or damage to my person or property, wherever or howsoever cause arising out of, or in connection with the performed procedure(s) or treatment(s).',
          hasSubContent: false
        }
      },
      {
        id: 18,
        header: '',
        hasContent: true,
        content: {
          id: 1,
          name: 'I hereby agree that I have read this CONSENT and understood it and further agree that this shall bind my heirs, next of kin, executors, administrators and assigns.',
          hasSubContent: false
        }
      }
    ]
  }

  diagnosticRecords = [
    { id: 1, name: 'periapical radiograph', checked: false, is_checked: 0, hasInput: false },
    { id: 2, name: 'panoramic radiograph', checked: false, is_checked: 0, hasInput: false },
    { id: 3, name: 'transcranial radiograph', checked: false, is_checked: 0, hasInput: false },
    { id: 4, name: 'photographs', checked: false, is_checked: 0, hasInput: false },
    { id: 5, name: 'dental cast models', checked: false, is_checked: 0, hasInput: false },
    { id: 6, name: 'CBCT scans', checked: false, is_checked: 0, hasInput: false },
    { id: 7, name: 'others', checked: false, is_checked: 0, hasInput: true },
  ];

  performedTreatments = [
    { id: 1, name: 'Fillings', checked: false, is_checked: 0 },
    { id: 2, name: 'Bridges', checked: false, is_checked: 0 },
    { id: 3, name: 'Crowns', checked: false, is_checked: 0 },
    { id: 4, name: 'Surgery', checked: false, is_checked: 0 },
    { id: 5, name: 'Inlays / Onlays', checked: false, is_checked: 0 },
    { id: 6, name: 'Veneers', checked: false, is_checked: 0 },
    { id: 7, name: 'Simple Extraction', checked: false, is_checked: 0 },
    { id: 8, name: 'Dental Implant', checked: false, is_checked: 0 },
    { id: 9, name: 'Impacted Tooth Removal', checked: false, is_checked: 0 },
    { id: 10, name: 'Root Canal Treatment', checked: false, is_checked: 0 },
    { id: 11, name: 'Dentures', checked: false, is_checked: 0 },
    { id: 12, name: 'Flouride Application', checked: false, is_checked: 0 },
    { id: 13, name: 'X-rays', checked: false, is_checked: 0 },
    { id: 14, name: 'Amalgam Removal', checked: false, is_checked: 0 },
    { id: 15, name: 'Oral Prophylaxis', checked: false, is_checked: 0 },
    { id: 16, name: 'Teeth Whitening', checked: false, is_checked: 0 },
  ]

  formGroup: FormGroup;
  isEmpty: boolean = false;
  clinic = null;
  errorMessage = null;
  forms = [
    { id: 1, name: 'Informed Consent' },
    { id: 2, name: 'Orthodontic Treatment Consent' },
    { id: 3, name: 'TMJ Treatment Consent' },
    { id: 4, name: 'Release' },
    { id: 5, name: 'Release and Waiver' },
    { id: 6, name: 'Braces Removal Consent' },
  ];
  form: number = 1;
  header = '';
  consent = {};
  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  patientId = '';
  dentistId = '';
  treatmentDoctorId = '';
  dischargingPersonId = '';
  releasedPatientId = '';
  patientName = '';
  dentistName = '';
  treatmentDoctorName = '';
  dischargingPersonName = '';
  releasedPatientName = '';
  patientControl = new FormControl();
  dentistControl = new FormControl();
  treatmentDoctorControl = new FormControl();
  dischargingPersonControl = new FormControl();
  releasedPatientControl = new FormControl();
  patients: Patient[] = [];
  dentists: Dentist[] = [];
  treatmentDoctors: Dentist[] = [];
  dischargingPersons: Dentist[] = [];
  releasedPatients: Patient[] = [];
  filteredPatients: Observable<Patient[]>;
  filteredDentists: Observable<Dentist[]>;
  filteredTreatmentDoctor: Observable<Dentist[]>;
  filteredDischargingPerson: Observable<Dentist[]>;
  filteredReleasedPatient: Observable<Patient[]>;
  @ViewChild('patient') patient: MatAutocompleteTrigger;
  @ViewChild('dentist') dentist: MatAutocompleteTrigger;
  @ViewChild('treatmentDoctor') treatmentDoctor: MatAutocompleteTrigger;
  @ViewChild('dischargingPerson') dischargingPerson: MatAutocompleteTrigger;
  @ViewChild('releasedPatient') releasedPatient: MatAutocompleteTrigger;

  patientSignature;
  dentistSignature;
  witnessSignature;
  hasPatientSignature = false;
  hasDentistSignature = false;
  hasWitnessSignature = false;
  signature1 = 'Patient\'s Signature (tap icon to add)';
  signature2 = 'Dentist\'s Signature (tap icon to add)';
  signature3 = 'Witness\'s Signature (tap icon to add)';
  signature4 = 'Parent\'s or Guardian\'s Signature (tap icon to add)';

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private dashboard: DashboardService,
    private dentalForms: DentalFormsService,
    public dialog: MatDialog,
  ) {
    this.header = 'Informed Consent';
    this.clinic = this.auth.getAuthUser().user_clinic;
    this.dashboard.getPatientList(this.clinic.id, this.clinic.id).subscribe(res => {
      res.data.forEach(element => {
        let obj: Patient = {
          id: element.id, 
          firstName: element.first_name, 
          middleName: element.middle_name ? element.middle_name : '', 
          lastName: element.last_name
        };
        this.patients.push(obj);
        this.releasedPatients.push(obj)
      });
    });
    this.dashboard.getDentistList(this.clinic.id).subscribe(res => {
      res.forEach(element => {
        let obj: Dentist = {
          id: element.id, 
          firstName: element.first_name,
          middleName: element.middle_name ? element.middle_name : '', 
          lastName: element.last_name
        };
        this.dentists.push(obj);
        this.treatmentDoctors.push(obj);
        this.dischargingPersons.push(obj);
      });
    });
    this.formGroup = this.formBuilder.group({
      form: [''],
      address: [''],
      telephone_no: [''],
      mobile_no: [''],
      clinic_hours: [''],
      website: [''],
      email: [''],
      patient_id: new FormControl('', [Validators.required]),
      dentist_id: new FormControl('', [Validators.required]),
      discharge_by_id: new FormControl(''),
      treatment_doctor_id: new FormControl(''),
      released_patient_id: new FormControl(''),
      patient_date: ['', [Validators.required]],
      dentist_date: ['', [Validators.required]],
      patient_signature: ['', [Validators.required]],
      dentist_signature: ['', [Validators.required]],
      witness: ['', [Validators.required]],
      witness_date: ['', [Validators.required]],
      witness_signature: ['', [Validators.required]],
      upper_php: [''],
      lower_php: [''],
      total_php: [''],
      adjustment_fee: [''],
      discharge_by: [''],
      treatment_doctor: [''],
      released_date: [''],
      released_patient: [''],
      released_address: [''],
      others_comments: ['']
    });
    this.formGroup.get('form').setValue(1);
    this.formGroup.get('address').setValue(this.clinic.address ? this.clinic.address : '');
    this.formGroup.get('telephone_no').setValue(this.clinic.contact_number ? this.clinic.contact_number : '');
    this.formGroup.get('mobile_no').setValue(this.clinic.mobile_number ? this.clinic.mobile_number : '');
    this.formGroup.get('clinic_hours').setValue(this.clinic.clinic_hours ? this.clinic.clinic_hours : '');
    this.formGroup.get('website').setValue(this.clinic.website ? this.clinic.website : '');
    this.formGroup.get('email').setValue(this.clinic.email ? this.clinic.email : '');
    
    if(this.formGroup.value.website == null) {
      this.isEmpty = true;
    } else {
      this.isEmpty = false;
    }
  }

  ngOnInit() {
    this.consent = this.informedConsent;
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.filteredPatients = this.formGroup.get('patient_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterPatient(name) : this.patients.slice()),
      );
    this.filteredDentists = this.formGroup.get('dentist_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterDentist(name) : this.dentists.slice())
      );
    this.filteredTreatmentDoctor = this.formGroup.get('treatment_doctor_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterTreatmentDoctor(name) : this.treatmentDoctors.slice())
      );
    this.filteredDischargingPerson = this.formGroup.get('discharge_by_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterDischargingPerson(name) : this.dischargingPersons.slice())
      );
    this.filteredReleasedPatient = this.formGroup.get('released_patient_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterReleasedPatient(name) : this.releasedPatients.slice())
      );
  }

  getForm(val) {
    this.form = val.value;
    if(this.form == 1) {
      this.header = 'Informed Consent';
      this.consent = this.informedConsent;
    } else if(this.form == 2) {
      this.header = 'Orthodontic Treatment Consent';
      this.consent = this.orthodonticConsent;
    } else if(this.form == 3) {
      this.header = 'TMJ Treatment Consent';
      this.consent = this.treatmentConsent;
    } else if(this.form == 4) {
      this.header = 'Release';
    } else if(this.form == 5) {
      this.header = 'Release and Waiver';
    } else {
      this.header = 'Braces Removal Consent';
    }

    this.formGroup.get('form').setValue(this.form);
    this.formGroup.get('address').setValue(this.clinic.address ? this.clinic.address : '');
    this.formGroup.get('telephone_no').setValue(this.clinic.contact_number ? this.clinic.contact_number : '');
    this.formGroup.get('mobile_no').setValue(this.clinic.mobile_number ? this.clinic.mobile_number : '');
    this.formGroup.get('clinic_hours').setValue(this.clinic.clinic_hours ? this.clinic.clinic_hours : '');
    this.formGroup.get('website').setValue(this.clinic.website ? this.clinic.website : '');
    this.formGroup.get('email').setValue(this.clinic.email ? this.clinic.email : '');
    this.formGroup.get('patient_id').setValue('');
    this.formGroup.get('dentist_id').setValue('');
    this.formGroup.get('discharge_by_id').setValue('');
    this.formGroup.get('treatment_doctor_id').setValue('');
    this.formGroup.get('released_patient_id').setValue('');
    this.formGroup.get('patient_date').setValue('');
    this.formGroup.get('dentist_date').setValue('');
    this.formGroup.get('patient_signature').setValue('');
    this.formGroup.get('dentist_signature').setValue('');
    this.formGroup.get('witness').setValue('');
    this.formGroup.get('witness_date').setValue('');
    this.formGroup.get('witness_signature').setValue('');
    this.formGroup.get('upper_php').setValue('');
    this.formGroup.get('lower_php').setValue('');
    this.formGroup.get('total_php').setValue('');
    this.formGroup.get('adjustment_fee').setValue('');
    this.formGroup.get('discharge_by').setValue('');
    this.formGroup.get('treatment_doctor').setValue('');
    this.formGroup.get('released_date').setValue('');
    this.formGroup.get('released_patient').setValue('');
    this.formGroup.get('released_address').setValue('');
    this.formGroup.get('others_comments').setValue('');
    this.patientSignature;
    this.dentistSignature;
    this.witnessSignature;
    this.hasPatientSignature = false;
    this.hasDentistSignature = false;
    this.hasWitnessSignature = false;
    this.signature1 = 'Patient\'s Signature (tap icon to add)';
    this.signature2 = 'Dentist\'s Signature (tap icon to add)';
    this.signature3 = 'Witness\'s Signature (tap icon to add)';
    this.signature4 = 'Parent\'s or Guardian\'s Signature (tap icon to add)';
    
    this.errorMessage = null;
    this.triggerErrors({});
    Object.keys(this.formGroup.controls).forEach(key => {
      this.formGroup.controls[key].markAsUntouched();
      this.formGroup.controls[key].markAsPristine();
      this.formGroup.controls[key].setErrors(null);
    });
  }

  getPatient(event) {
    this.patientId = event.option.value.id;
    this.patientName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.formGroup.get('patient_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
  }

  getDentist(event) {
    this.dentistId = event.option.value.id;
    this.dentistName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.formGroup.get('dentist_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
  }

  getTreatmentDoctor(event) {
    this.treatmentDoctorId = event.option.value.id;
    this.treatmentDoctorName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.formGroup.get('treatment_doctor_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
  }

  getDischargingPerson(event) {
    this.dischargingPersonId = event.option.value.id;
    this.dischargingPersonName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.formGroup.get('discharge_by_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
  }

  getReleasedPatient(event) {
    this.releasedPatientId = event.option.value.id;
    this.releasedPatientName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.formGroup.get('released_patient_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
  }

  private _filterPatient(name: string): Patient[] {
    const filterValue = name.toLowerCase();
    return this.patients.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1 || option.middleName.toLowerCase().indexOf(filterValue) > -1);
  }

  private _filterDentist(name: string): Dentist[] {
    const filterValue = name.toLowerCase();
    return this.dentists.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1);
  }

  private _filterTreatmentDoctor(name: string): Dentist[] {
    const filterValue = name.toLowerCase();
    return this.treatmentDoctors.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1);
  }

  private _filterDischargingPerson(name: string): Dentist[] {
    const filterValue = name.toLowerCase();
    return this.dischargingPersons.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1);
  }

  private _filterReleasedPatient(name: string): Patient[] {
    const filterValue = name.toLowerCase();
    return this.patients.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1 || option.middleName.toLowerCase().indexOf(filterValue) > -1);
  }

  displayDentist(dentist?: any) : string | undefined {
    return dentist ? 'Dr. ' + dentist.firstName + (dentist.middleName ? ' ' + dentist.middleName + ' ' : ' ') + dentist.lastName : undefined;
  }

  displayPatient(patient?: any) : string | undefined {
    return patient ? patient.lastName + ', ' + patient.firstName + (patient.middleName ? ' ' + patient.middleName : ' ') : undefined;
  }

  displayTreatmentDoctor(dentist?: any) : string | '' {
    if(dentist ==  null) {
      return '';
    }
    return (typeof dentist === 'object') ? 'Dr. ' + dentist.firstName + (dentist.middleName ? ' ' + dentist.middleName + ' ' : ' ') + dentist.lastName : '';
  }

  displayDischargingPerson(dentist?: any) : string | '' {
    if(dentist ==  null) {
      return '';
    }
    return (typeof dentist === 'object') ? 'Dr. ' + dentist.firstName + (dentist.middleName ? ' ' + dentist.middleName + ' ' : ' ') + dentist.lastName : '';
  }

  displayReleasedPatient(patient?: any) : string | '' {
    if(patient ==  null) {
      return '';
    }
    return (typeof patient === 'object') ? patient.lastName + ', ' + patient.firstName + (patient.middleName ? ' ' + patient.middleName : ' ') : '';
  }

  onFocusPatient() {
    this.patient._onChange("");
    this.patient.openPanel();
  }

  onFocusDentist() {
    this.dentist._onChange(""); 
    this.dentist.openPanel();
  }

  onFocusTreatmentDoctor() {
    this.treatmentDoctor._onChange(""); 
    this.treatmentDoctor.openPanel();
  }

  onFocusDischargingPerson() {
    this.dischargingPerson._onChange(""); 
    this.dischargingPerson.openPanel();
  }

  onFocusReleasedPatient() {
    this.releasedPatient._onChange(""); 
    this.releasedPatient.openPanel();
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  getPatientSignature() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogConfig.data = {
      title: 'Patient\'s Signature'
    }
    this.dialogRef = this.dialog.open(ESignatureComponent, this.dialogConfig).afterClosed().subscribe(res => {
      if(res) {
        this.hasPatientSignature = true;
        this.patientSignature = res;
        this.signature1 = 'Patient\'s Signature';
        this.formGroup.get('patient_date').setValue(new Date());
        this.formGroup.get('patient_signature').setValue(this.patientSignature);
      }
    });
  }

  getDentistSignature() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogConfig.data = {
      title: 'Dentist\'s Signature'
    }
    this.dialogRef = this.dialog.open(ESignatureComponent, this.dialogConfig).afterClosed().subscribe(res => {
      if(res) {
        this.hasDentistSignature = true;
        this.dentistSignature = res;
        this.signature2 = 'Dentist\'s Signature';
        this.formGroup.get('dentist_date').setValue(new Date());
        this.formGroup.get('dentist_signature').setValue(this.dentistSignature);
      }
    });
  }

  getWitnessSignature() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogConfig.data = {
      title: 'Witness\' Signature'
    }
    this.dialogRef = this.dialog.open(ESignatureComponent, this.dialogConfig).afterClosed().subscribe(res => {
      if(res) {
        this.hasWitnessSignature = true;
        this.witnessSignature = res;
        this.signature3 = 'Witness\'s Signature';
        this.signature4 = 'Parent\'s or Guardian\'s Signature';
        this.formGroup.get('witness_date').setValue(new Date());
        this.formGroup.get('witness_signature').setValue(this.witnessSignature);
      }
    });
  }

  onCheck(e, obj) {
    obj.is_checked = (e.checked) ? 1 : 0;
  }

  onCheckTreatments(e, obj) {
    obj.is_checked = (e.checked) ? 1 : 0;
  }

  download() {
    const date1 = this.formGroup.value.patient_date ? moment(this.formGroup.value.patient_date).format('MM/DD/YYYY') : '';
    const date2 = this.formGroup.value.dentist_date ? moment(this.formGroup.value.dentist_date).format('MM/DD/YYYY') : '';
    const date3 = this.formGroup.value.witness_date ? moment(this.formGroup.value.witness_date).format('MM/DD/YYYY') : '';
    const date4 = this.formGroup.value.released_date ? moment(this.formGroup.value.released_date).format('MM/DD/YYYY') : '';

    const FORM_1 = {
      user_clinic_id: this.clinic.id,
      clinic_address: this.formGroup.value.address,
      telephone_no: this.formGroup.value.telephone_no.toString(),
      mobile_no: this.formGroup.value.mobile_no.toString(),
      clinic_hours: this.formGroup.value.clinic_hours,
      website: this.formGroup.value.website,
      email: this.formGroup.value.email,
      patient_id: this.patientId,
      patient_name: this.patientName,
      patient_signature: this.patientSignature,
      patient_date: date1,
      dentist_id: this.dentistId,
      dentist_name: this.dentistName,
      dentist_signature: this.dentistSignature,
      dentist_date: date2,
      witness_name: this.formGroup.value.witness,
      witness_signature: this.witnessSignature,
      witness_date: date3,
      fillings: this.performedTreatments[0].is_checked,
      bridges: this.performedTreatments[1].is_checked,
      crowns: this.performedTreatments[2].is_checked,
      surgery: this.performedTreatments[3].is_checked,
      inlays_onlays: this.performedTreatments[4].is_checked,
      veneers: this.performedTreatments[5].is_checked,
      simple_extraction: this.performedTreatments[6].is_checked,
      dental_implant: this.performedTreatments[7].is_checked,
      impacted_tooth_removal: this.performedTreatments[8].is_checked,
      root_canal_treatment: this.performedTreatments[9].is_checked,
      dentures: this.performedTreatments[10].is_checked,
      flouride_application: this.performedTreatments[11].is_checked,
      x_rays: this.performedTreatments[12].is_checked,
      amalgam_removal: this.performedTreatments[13].is_checked,
      oral_prophylaxis: this.performedTreatments[14].is_checked,
      teeth_whitening: this.performedTreatments[15].is_checked,
    }

    const FORM_2 = {
      user_clinic_id: this.clinic.id,
      clinic_address: this.formGroup.value.address,
      telephone_no: this.formGroup.value.telephone_no.toString(),
      mobile_no: this.formGroup.value.mobile_no.toString(),
      clinic_hours: this.formGroup.value.clinic_hours,
      website: this.formGroup.value.website,
      email: this.formGroup.value.email,
      patient_id: this.patientId,
      patient_name: this.patientName,
      patient_signature: this.patientSignature,
      patient_date: date1,
      dentist_id: this.dentistId,
      dentist_name: this.dentistName,
      dentist_signature: this.dentistSignature,
      dentist_date: date2,
      witness: this.formGroup.value.witness,
      witness_signature: this.witnessSignature,
      witness_date: date3,
      upper_php: this.formGroup.value.upper_php,
      lower_php: this.formGroup.value.lower_php,
      total_php: this.formGroup.value.total_php,
      adjustment_fee: this.formGroup.value.adjustment_fee,
    }

    const FORM_3 = {
      user_clinic_id: this.clinic.id,
      clinic_address: this.formGroup.value.address,
      telephone_no: this.formGroup.value.telephone_no.toString(),
      mobile_no: this.formGroup.value.mobile_no.toString(),
      clinic_hours: this.formGroup.value.clinic_hours,
      website: this.formGroup.value.website,
      email: this.formGroup.value.email,
      patient_id: this.patientId,
      patient_name: this.patientName,
      patient_signature: this.patientSignature,
      patient_date: date1,
      dentist_id: this.dentistId,
      dentist_name: this.dentistName,
      dentist_signature: this.dentistSignature,
      dentist_date: date2,
      witness: this.formGroup.value.witness,
      witness_signature: this.witnessSignature,
      witness_date: date3,
    }

    const FORM_4 = {
      user_clinic_id: this.clinic.id,
      clinic_address: this.formGroup.value.address,
      telephone_no: this.formGroup.value.telephone_no.toString(),
      mobile_no: this.formGroup.value.mobile_no.toString(),
      clinic_hours: this.formGroup.value.clinic_hours,
      website: this.formGroup.value.website,
      email: this.formGroup.value.email,
      patient_id: this.patientId,
      patient_name: this.patientName,
      patient_signature: this.patientSignature,
      patient_date: date1,
      dentist_id: this.dentistId,
      dentist_name: this.dentistName,
      dentist_signature: this.dentistSignature,
      dentist_date: date2,
      witness: this.formGroup.value.witness,
      witness_signature: this.witnessSignature,
      witness_date: date3,
      released_date: date4,
      released_patient: this.releasedPatientName,
      periapical_radiograph: this.diagnosticRecords[0].is_checked,
      panoramic_radiograph: this.diagnosticRecords[1].is_checked,
      transcranial_radiograph: this.diagnosticRecords[2].is_checked,
      photographs: this.diagnosticRecords[3].is_checked,
      dental_cast_models: this.diagnosticRecords[4].is_checked,
      cbct_scans: this.diagnosticRecords[5].is_checked,
      others: this.diagnosticRecords[6].is_checked,
      others_comments: this.formGroup.value.others_comments,
      released_address: this.formGroup.value.released_address,
    }

    const FORM_5 = {
      user_clinic_id: this.clinic.id,
      clinic_address: this.formGroup.value.address,
      telephone_no: this.formGroup.value.telephone_no.toString(),
      mobile_no: this.formGroup.value.mobile_no.toString(),
      clinic_hours: this.formGroup.value.clinic_hours,
      website: this.formGroup.value.website,
      email: this.formGroup.value.email,
      patient_id: this.patientId,
      patient_name: this.patientName,
      patient_signature: this.patientSignature,
      patient_date: date1,
      dentist_id: this.dentistId,
      dentist_name: this.dentistName,
      dentist_signature: this.dentistSignature,
      dentist_date: date2,
      witness: this.formGroup.value.witness,
      witness_signature: this.witnessSignature,
      witness_date: date3,
      treatment_doctor: this.treatmentDoctorName,
      discharge_by: this.dischargingPersonName
    }

    const FORM_6 = {
      user_clinic_id: this.clinic.id,
      clinic_address: this.formGroup.value.address,
      telephone_no: this.formGroup.value.telephone_no.toString(),
      mobile_no: this.formGroup.value.mobile_no.toString(),
      clinic_hours: this.formGroup.value.clinic_hours,
      website: this.formGroup.value.website,
      email: this.formGroup.value.email,
      patient_id: this.patientId,
      patient_name: this.patientName,
      patient_signature: this.patientSignature,
      patient_date: date1,
      dentist_id: this.dentistId,
      dentist_name: this.dentistName,
      dentist_signature: this.dentistSignature,
      dentist_date: date2,
      witness: this.formGroup.value.witness,
      witness_signature: this.witnessSignature,
      witness_date: date3,
    }
    
    const DENTIST_REQUIRED = this.formGroup.get('dentist_id').value == '' ? 'The dentist name field is required.' : '';
    const DENTIST_INVALID = typeof this.formGroup.get('dentist_id').value == 'string' ? 'The dentist name is invalid.' : '';
    const DENTIST_DATE = this.formGroup.get('dentist_date').value == '' ? 'The date signed field is required.' : '';
    const DENTIST_SIGNATURE = this.formGroup.get('dentist_signature').value == '' ? 'The dentist signature field is required.' : '';
    const PATIENT_REQUIRED = this.formGroup.get('patient_id').value == '' ? 'The patient name field is required.' : '';
    const PATIENT_INVALID = typeof this.formGroup.get('patient_id').value == 'string' ? 'The patient name is invalid.' : '';
    const PATIENT_DATE = this.formGroup.get('patient_date').value == '' ? 'The date signed field is required.' : '';
    const PATIENT_SIGNATURE = this.formGroup.get('patient_signature').value == '' ? 'The patient signature field is required.' : '';
    const WITNESS_REQUIRED = this.formGroup.get('witness').value == '' ? 'The witness name field is required.' : '';
    const WITNESS_DATE = this.formGroup.get('witness_date').value == '' ? 'The date signed field is required.' : '';
    const WITNESS_SIGNATURE = this.formGroup.get('witness_signature').value == '' ? 'The witness signature field is required.' : '';
    const RELEASED_PATIENT_REQUIRED = this.formGroup.get('released_patient_id').value == '' ? 'The patient name field is required.' : '';
    const RELEASED_PATIENT_INVALID = typeof this.formGroup.get('released_patient_id').value == 'string' ? 'The patient field is invalid.' : '';
    const TREATMENT_DOCTOR_INVALID = typeof this.formGroup.get('treatment_doctor_id').value == 'string' ? 'The dentist field is invalid.' : '';
    const DISCHARGE_BY_INVALID = typeof this.formGroup.get('discharge_by_id').value == 'string' ? 'The dentist field is invalid.' : '';
    const UPPER_PHP_REQUIRED = this.formGroup.get('upper_php').value == '' ? 'The installation fee (upper) is required.' : '';
    const LOWER_PHP_REQUIRED = this.formGroup.get('lower_php').value == '' ? 'The installation fee (lower) is required.' : '';
    const TOTAL_PHP_REQUIRED = this.formGroup.get('total_php').value == '' ? 'The installation fee (total) is required.' : '';
    
    const CUSTOM_ERROR_A = {
      dentist_date: DENTIST_DATE,
      patient_date: PATIENT_DATE,
      witness_date: WITNESS_DATE,
      dentist_signature: DENTIST_SIGNATURE,
      patient_signature: PATIENT_SIGNATURE,
      witness_signature: WITNESS_SIGNATURE,
      witness: WITNESS_REQUIRED,
      dentist_id: DENTIST_REQUIRED == '' ? DENTIST_INVALID : DENTIST_REQUIRED,
      patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
    };

    const CUSTOM_ERROR_B = {
      dentist_date: DENTIST_DATE,
      patient_date: PATIENT_DATE,
      witness_date: WITNESS_DATE,
      dentist_signature: DENTIST_SIGNATURE,
      patient_signature: PATIENT_SIGNATURE,
      witness_signature: WITNESS_SIGNATURE,
      witness: WITNESS_REQUIRED,
      dentist_id: DENTIST_REQUIRED == '' ? DENTIST_INVALID : DENTIST_REQUIRED,
      patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
      upper_php: UPPER_PHP_REQUIRED,
      lower_php: LOWER_PHP_REQUIRED,
      total_php: TOTAL_PHP_REQUIRED,
    };

    const CUSTOM_ERROR_C = {
      dentist_date: DENTIST_DATE,
      patient_date: PATIENT_DATE,
      witness_date: WITNESS_DATE,
      dentist_signature: DENTIST_SIGNATURE,
      patient_signature: PATIENT_SIGNATURE,
      witness_signature: WITNESS_SIGNATURE,
      witness: WITNESS_REQUIRED,
      dentist_id: DENTIST_REQUIRED == '' ? DENTIST_INVALID : DENTIST_REQUIRED,
      patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
      released_patient_id: RELEASED_PATIENT_REQUIRED == '' ? RELEASED_PATIENT_INVALID : RELEASED_PATIENT_REQUIRED,
    };

    const CUSTOM_ERROR_D = {
      dentist_date: DENTIST_DATE,
      patient_date: PATIENT_DATE,
      witness_date: WITNESS_DATE,
      dentist_signature: DENTIST_SIGNATURE,
      patient_signature: PATIENT_SIGNATURE,
      witness_signature: WITNESS_SIGNATURE,
      witness: WITNESS_REQUIRED,
      dentist_id: DENTIST_REQUIRED == '' ? DENTIST_INVALID : DENTIST_REQUIRED,
      patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
      treatment_doctor_id: TREATMENT_DOCTOR_INVALID,
      discharge_by_id: DISCHARGE_BY_INVALID,
    };

    if(this.form == 1) {
      this.dentalForms.printAndDownloadInformedConsent(FORM_1).subscribe(res => {
        if(res) {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(res);
          a.download = moment().format("YYYYMMDD") + '-INFORMED CONSENT.pdf';
          document.body.appendChild(a);
          a.click();
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'Exporting of PDF failed. Please fill-in all the required fields.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        this.errorMessage = CUSTOM_ERROR_A;
        this.triggerErrors(CUSTOM_ERROR_A);
      }));
    } else if(this.form == 2) {
      this.dentalForms.printAndDownloadOrthodonticConsent(FORM_2).subscribe(res => {
        if(res) {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(res);
          a.download = moment().format("YYYYMMDD") + '-ORTHODONTIC TREATMENT CONSENT.pdf';
          document.body.appendChild(a);
          a.click();
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'Exporting of PDF failed. Please fill-in all the required fields.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        this.errorMessage = CUSTOM_ERROR_B;
        this.triggerErrors(CUSTOM_ERROR_B);
      }));
    } else if(this.form == 3) {
      this.dentalForms.printAndDownloadTMJConsent(FORM_3).subscribe(res => {
        if(res) {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(res);
          a.download = moment().format("YYYYMMDD") + '-TMJ TREATMENT CONSENT.pdf';
          document.body.appendChild(a);
          a.click();
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'Exporting of PDF failed. Please fill-in all the required fields.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        this.errorMessage = CUSTOM_ERROR_A;
        this.triggerErrors(CUSTOM_ERROR_A);
      }));
    } else if(this.form == 4) {
      this.dentalForms.printAndDownloadReleaseConsent(FORM_4).subscribe(res => {
        if(res) {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(res);
          a.download = moment().format("YYYYMMDD") + '-RELEASE.pdf';
          document.body.appendChild(a);
          a.click();
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'Exporting of PDF failed. Please fill-in all the required fields.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        this.errorMessage = CUSTOM_ERROR_C;
        this.triggerErrors(CUSTOM_ERROR_C);
      }));
    } else if(this.form == 5) {
      this.dentalForms.printAndDownloadWaiverConsent(FORM_5).subscribe(res => {
        if(res) {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(res);
          a.download = moment().format("YYYYMMDD") + '-RELEASE AND WAIVER.pdf';
          document.body.appendChild(a);
          a.click();
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'Exporting of PDF failed. Please fill-in all the required fields.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        this.errorMessage = CUSTOM_ERROR_D;
        this.triggerErrors(CUSTOM_ERROR_D);
      }));
    } else if(this.form == 6) {
      this.dentalForms.printAndDownloadBracesRemovalConsent(FORM_6).subscribe(res => {
        if(res) {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(res);
          a.download = moment().format("YYYYMMDD") + '-BRACES REMOVAL CONSENT.pdf';
          document.body.appendChild(a);
          a.click();
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'Exporting of PDF failed. Please fill-in all the required fields.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        this.errorMessage = CUSTOM_ERROR_A
        this.triggerErrors(CUSTOM_ERROR_A);
      }));
    }
  }

  print() {
    const date1 = this.formGroup.value.patient_date ? moment(this.formGroup.value.patient_date).format('MM/DD/YYYY') : '';
    const date2 = this.formGroup.value.dentist_date ? moment(this.formGroup.value.dentist_date).format('MM/DD/YYYY') : '';
    const date3 = this.formGroup.value.witness_date ? moment(this.formGroup.value.witness_date).format('MM/DD/YYYY') : '';
    const date4 = this.formGroup.value.released_date ? moment(this.formGroup.value.released_date).format('MM/DD/YYYY') : '';

    const FORM_1 = {
      user_clinic_id: this.clinic.id,
      clinic_address: this.formGroup.value.address,
      telephone_no: this.formGroup.value.telephone_no.toString(),
      mobile_no: this.formGroup.value.mobile_no.toString(),
      clinic_hours: this.formGroup.value.clinic_hours,
      website: this.formGroup.value.website,
      email: this.formGroup.value.email,
      patient_id: this.patientId,
      patient_name: this.patientName,
      patient_signature: this.patientSignature,
      patient_date: date1,
      dentist_id: this.dentistId,
      dentist_name: this.dentistName,
      dentist_signature: this.dentistSignature,
      dentist_date: date2,
      witness_name: this.formGroup.value.witness,
      witness_signature: this.witnessSignature,
      witness_date: date3,
      fillings: this.performedTreatments[0].is_checked,
      bridges: this.performedTreatments[1].is_checked,
      crowns: this.performedTreatments[2].is_checked,
      surgery: this.performedTreatments[3].is_checked,
      inlays_onlays: this.performedTreatments[4].is_checked,
      veneers: this.performedTreatments[5].is_checked,
      simple_extraction: this.performedTreatments[6].is_checked,
      dental_implant: this.performedTreatments[7].is_checked,
      impacted_tooth_removal: this.performedTreatments[8].is_checked,
      root_canal_treatment: this.performedTreatments[9].is_checked,
      dentures: this.performedTreatments[10].is_checked,
      flouride_application: this.performedTreatments[11].is_checked,
      x_rays: this.performedTreatments[12].is_checked,
      amalgam_removal: this.performedTreatments[13].is_checked,
      oral_prophylaxis: this.performedTreatments[14].is_checked,
      teeth_whitening: this.performedTreatments[15].is_checked,
    }

    const FORM_2 = {
      user_clinic_id: this.clinic.id,
      clinic_address: this.formGroup.value.address,
      telephone_no: this.formGroup.value.telephone_no.toString(),
      mobile_no: this.formGroup.value.mobile_no.toString(),
      clinic_hours: this.formGroup.value.clinic_hours,
      website: this.formGroup.value.website,
      email: this.formGroup.value.email,
      patient_id: this.patientId,
      patient_name: this.patientName,
      patient_signature: this.patientSignature,
      patient_date: date1,
      dentist_id: this.dentistId,
      dentist_name: this.dentistName,
      dentist_signature: this.dentistSignature,
      dentist_date: date2,
      witness: this.formGroup.value.witness,
      witness_signature: this.witnessSignature,
      witness_date: date3,
      upper_php: this.formGroup.value.upper_php,
      lower_php: this.formGroup.value.lower_php,
      total_php: this.formGroup.value.total_php,
      adjustment_fee: this.formGroup.value.adjustment_fee,
    }

    const FORM_3 = {
      user_clinic_id: this.clinic.id,
      clinic_address: this.formGroup.value.address,
      telephone_no: this.formGroup.value.telephone_no.toString(),
      mobile_no: this.formGroup.value.mobile_no.toString(),
      clinic_hours: this.formGroup.value.clinic_hours,
      website: this.formGroup.value.website,
      email: this.formGroup.value.email,
      patient_id: this.patientId,
      patient_name: this.patientName,
      patient_signature: this.patientSignature,
      patient_date: date1,
      dentist_id: this.dentistId,
      dentist_name: this.dentistName,
      dentist_signature: this.dentistSignature,
      dentist_date: date2,
      witness: this.formGroup.value.witness,
      witness_signature: this.witnessSignature,
      witness_date: date3,
    }

    const FORM_4 = {
      user_clinic_id: this.clinic.id,
      clinic_address: this.formGroup.value.address,
      telephone_no: this.formGroup.value.telephone_no.toString(),
      mobile_no: this.formGroup.value.mobile_no.toString(),
      clinic_hours: this.formGroup.value.clinic_hours,
      website: this.formGroup.value.website,
      email: this.formGroup.value.email,
      patient_id: this.patientId,
      patient_name: this.patientName,
      patient_signature: this.patientSignature,
      patient_date: date1,
      dentist_id: this.dentistId,
      dentist_name: this.dentistName,
      dentist_signature: this.dentistSignature,
      dentist_date: date2,
      witness: this.formGroup.value.witness,
      witness_signature: this.witnessSignature,
      witness_date: date3,
      released_date: date4,
      released_patient: this.releasedPatientName,
      periapical_radiograph: this.diagnosticRecords[0].is_checked,
      panoramic_radiograph: this.diagnosticRecords[1].is_checked,
      transcranial_radiograph: this.diagnosticRecords[2].is_checked,
      photographs: this.diagnosticRecords[3].is_checked,
      dental_cast_models: this.diagnosticRecords[4].is_checked,
      cbct_scans: this.diagnosticRecords[5].is_checked,
      others: this.diagnosticRecords[6].is_checked,
      others_comments: this.formGroup.value.others_comments,
      released_address: this.formGroup.value.released_address,
    }

    const FORM_5 = {
      user_clinic_id: this.clinic.id,
      clinic_address: this.formGroup.value.address,
      telephone_no: this.formGroup.value.telephone_no.toString(),
      mobile_no: this.formGroup.value.mobile_no.toString(),
      clinic_hours: this.formGroup.value.clinic_hours,
      website: this.formGroup.value.website,
      email: this.formGroup.value.email,
      patient_id: this.patientId,
      patient_name: this.patientName,
      patient_signature: this.patientSignature,
      patient_date: date1,
      dentist_id: this.dentistId,
      dentist_name: this.dentistName,
      dentist_signature: this.dentistSignature,
      dentist_date: date2,
      witness: this.formGroup.value.witness,
      witness_signature: this.witnessSignature,
      witness_date: date3,
      treatment_doctor: this.treatmentDoctorName,
      discharge_by: this.dischargingPersonName,
    }

    const FORM_6 = {
      user_clinic_id: this.clinic.id,
      clinic_address: this.formGroup.value.address,
      telephone_no: this.formGroup.value.telephone_no.toString(),
      mobile_no: this.formGroup.value.mobile_no.toString(),
      clinic_hours: this.formGroup.value.clinic_hours,
      website: this.formGroup.value.website,
      email: this.formGroup.value.email,
      patient_id: this.patientId,
      patient_name: this.patientName,
      patient_signature: this.patientSignature,
      patient_date: date1,
      dentist_id: this.dentistId,
      dentist_name: this.dentistName,
      dentist_signature: this.dentistSignature,
      dentist_date: date2,
      witness: this.formGroup.value.witness,
      witness_signature: this.witnessSignature,
      witness_date: date3,
    }

    const DENTIST_REQUIRED = this.formGroup.get('dentist_id').value == '' ? 'The dentist name field is required.' : '';
    const DENTIST_INVALID = typeof this.formGroup.get('dentist_id').value == 'string' ? 'The dentist name is invalid.' : '';
    const DENTIST_DATE = this.formGroup.get('dentist_date').value == '' ? 'The date signed field is required.' : '';
    const DENTIST_SIGNATURE = this.formGroup.get('dentist_signature').value == '' ? 'The dentist signature field is required.' : '';
    const PATIENT_REQUIRED = this.formGroup.get('patient_id').value == '' ? 'The patient name field is required.' : '';
    const PATIENT_INVALID = typeof this.formGroup.get('patient_id').value == 'string' ? 'The patient name is invalid.' : '';
    const PATIENT_DATE = this.formGroup.get('patient_date').value == '' ? 'The date signed field is required.' : '';
    const PATIENT_SIGNATURE = this.formGroup.get('patient_signature').value == '' ? 'The patient signature field is required.' : '';
    const WITNESS_REQUIRED = this.formGroup.get('witness').value == '' ? 'The witness name field is required.' : '';
    const WITNESS_DATE = this.formGroup.get('witness_date').value == '' ? 'The date signed field is required.' : '';
    const WITNESS_SIGNATURE = this.formGroup.get('witness_signature').value == '' ? 'The witness signature field is required.' : '';
    const RELEASED_PATIENT_REQUIRED = this.formGroup.get('released_patient_id').value == '' ? 'The patient name field is required.' : '';
    const RELEASED_PATIENT_INVALID = typeof this.formGroup.get('released_patient_id').value == 'string' ? 'The patient field is invalid.' : '';
    const TREATMENT_DOCTOR_INVALID = typeof this.formGroup.get('treatment_doctor_id').value == 'string' ? 'The dentist field is invalid.' : '';
    const DISCHARGE_BY_INVALID = typeof this.formGroup.get('discharge_by_id').value == 'string' ? 'The dentist field is invalid.' : '';
    const UPPER_PHP_REQUIRED = this.formGroup.get('upper_php').value == '' ? 'The installation fee (upper) is required.' : '';
    const LOWER_PHP_REQUIRED = this.formGroup.get('lower_php').value == '' ? 'The installation fee (lower) is required.' : '';
    const TOTAL_PHP_REQUIRED = this.formGroup.get('total_php').value == '' ? 'The installation fee (total) is required.' : '';

    const CUSTOM_ERROR_A = {
      dentist_date: DENTIST_DATE,
      patient_date: PATIENT_DATE,
      witness_date: WITNESS_DATE,
      dentist_signature: DENTIST_SIGNATURE,
      patient_signature: PATIENT_SIGNATURE,
      witness_signature: WITNESS_SIGNATURE,
      witness: WITNESS_REQUIRED,
      dentist_id: DENTIST_REQUIRED == '' ? DENTIST_INVALID : DENTIST_REQUIRED,
      patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
    };

    const CUSTOM_ERROR_B = {
      dentist_date: DENTIST_DATE,
      patient_date: PATIENT_DATE,
      witness_date: WITNESS_DATE,
      dentist_signature: DENTIST_SIGNATURE,
      patient_signature: PATIENT_SIGNATURE,
      witness_signature: WITNESS_SIGNATURE,
      witness: WITNESS_REQUIRED,
      dentist_id: DENTIST_REQUIRED == '' ? DENTIST_INVALID : DENTIST_REQUIRED,
      patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
      upper_php: UPPER_PHP_REQUIRED,
      lower_php: LOWER_PHP_REQUIRED,
      total_php: TOTAL_PHP_REQUIRED,
    };

    const CUSTOM_ERROR_C = {
      dentist_date: DENTIST_DATE,
      patient_date: PATIENT_DATE,
      witness_date: WITNESS_DATE,
      dentist_signature: DENTIST_SIGNATURE,
      patient_signature: PATIENT_SIGNATURE,
      witness_signature: WITNESS_SIGNATURE,
      witness: WITNESS_REQUIRED,
      dentist_id: DENTIST_REQUIRED == '' ? DENTIST_INVALID : DENTIST_REQUIRED,
      patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
      released_patient_id: RELEASED_PATIENT_REQUIRED == '' ? RELEASED_PATIENT_INVALID : RELEASED_PATIENT_REQUIRED,
    };

    const CUSTOM_ERROR_D = {
      dentist_date: DENTIST_DATE,
      patient_date: PATIENT_DATE,
      witness_date: WITNESS_DATE,
      dentist_signature: DENTIST_SIGNATURE,
      patient_signature: PATIENT_SIGNATURE,
      witness_signature: WITNESS_SIGNATURE,
      witness: WITNESS_REQUIRED,
      dentist_id: DENTIST_REQUIRED == '' ? DENTIST_INVALID : DENTIST_REQUIRED,
      patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
      treatment_doctor_id: TREATMENT_DOCTOR_INVALID,
      discharge_by_id: DISCHARGE_BY_INVALID,
    };

    if(this.form == 1) {
      this.dentalForms.printAndDownloadInformedConsent(FORM_1).subscribe(res => {
        if(res) {
          const url = URL.createObjectURL(res);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = url;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'Exporting of PDF failed. Please fill-in all the required fields.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        this.errorMessage = CUSTOM_ERROR_A;
        this.triggerErrors(CUSTOM_ERROR_A);
      }));
    } else if(this.form == 2) {
      this.dentalForms.printAndDownloadOrthodonticConsent(FORM_2).subscribe(res => {
        if(res) {
          const url = URL.createObjectURL(res);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = url;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'Exporting of PDF failed. Please fill-in all the required fields.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        this.errorMessage = CUSTOM_ERROR_B;
        this.triggerErrors(CUSTOM_ERROR_B);
      }));
    } else if(this.form == 3) {
      this.dentalForms.printAndDownloadTMJConsent(FORM_3).subscribe(res => {
        if(res) {
          const url = URL.createObjectURL(res);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = url;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'Print Failed. Please fill-in all the required fields.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        this.errorMessage = CUSTOM_ERROR_A;
        this.triggerErrors(CUSTOM_ERROR_A);
      }));
    } else if(this.form == 4) {
      this.dentalForms.printAndDownloadReleaseConsent(FORM_4).subscribe(res => {
        if(res) {
          const url = URL.createObjectURL(res);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = url;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'Print Failed. Please fill-in all the required fields.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        this.errorMessage = CUSTOM_ERROR_C;
        this.triggerErrors(CUSTOM_ERROR_C);
      }));
    } else if(this.form == 5) {
      this.dentalForms.printAndDownloadWaiverConsent(FORM_5).subscribe(res => {
        if(res) {
          const url = URL.createObjectURL(res);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = url;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'Exporting of PDF failed. Please fill-in all the required fields.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        this.errorMessage = CUSTOM_ERROR_D;
        this.triggerErrors(CUSTOM_ERROR_D);
      }));
    } else if(this.form == 6) {
      this.dentalForms.printAndDownloadBracesRemovalConsent(FORM_6).subscribe(res => {
        if(res) {
          const url = URL.createObjectURL(res);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = url;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'Exporting of PDF failed. Please fill-in all the required fields.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        this.errorMessage = CUSTOM_ERROR_A;
        this.triggerErrors(CUSTOM_ERROR_A);
      }));
    }
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }
}
