import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, MatDatepicker } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { FormControl } from '@angular/forms';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MMMM YYYY',
  },
  display: {
    dateInput: 'MMMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'ark-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class DateFilterComponent implements OnInit, OnChanges {

  @Input() selectedFilter: any;
  @Output() date: EventEmitter<any> = new EventEmitter<any>();
  @Output() month: EventEmitter<any> = new EventEmitter<any>();
  @Output() start: EventEmitter<any> = new EventEmitter<any>();
  @Output() end: EventEmitter<any> = new EventEmitter<any>();
  @Output() weekDays: EventEmitter<any> = new EventEmitter<any>();
  @Output() weekDaysFormat: EventEmitter<any> = new EventEmitter<any>();
  selectedDate = null;
  today: boolean = false;
  week: boolean = false;
  todayDate: any = null;
  weekDate: any = null;
  days: any;
  daysFormat: any;
  startDate: any = null;
  endDate: any = null;
  monthDate: any = null;
  i: number = 0;
  a: number = 0;

  NEW_MONTH_DATE = null;
  SELECTED_MONTH_DATE = null;
  dateSelected = new FormControl(moment());
  isDatePicker = false;

  constructor() { }

  ngOnInit() {}

  ngOnChanges() {
    this.i = 0;
    this.a = 0;
    if (this.selectedFilter == 'today'){
      this.selectedDate = null;
    }
    this.getToday();
    this.getWeek();
    this.getMonth();
  }

  /**
   * Get today date
   */
  getToday() {
    this.today = true;
    if (!this.selectedDate && localStorage.getItem('selectedApp')) {
      const d = JSON.parse(localStorage.getItem('selectedApp'));
      this.todayDate = moment(d.start).format("ddd, MMM DD, YYYY");
      this.selectedDate = d.start;
    } else {
      this.todayDate = moment().format("ddd, MMM DD, YYYY");
    }

    this.date.emit(this.todayDate);
  }

  /**
   * Get previous date
   */
  previousDay() {
    this.i--;
    if (this.selectedDate) {
      this.todayDate = moment(this.selectedDate).add(this.i, 'day').format("ddd, MMM DD, YYYY");
    } else {
      this.todayDate = moment().add(this.i, 'day').format("ddd, MMM DD, YYYY");
    }
    this.date.emit(this.todayDate);
  }

  /**
   * Get next date
   */
  nextDay() {
    this.i++;
    if (this.selectedDate) {
      this.todayDate = moment(this.selectedDate).add(this.i, 'day').format("ddd, MMM DD, YYYY");
    } else {
      this.todayDate = moment().add(this.i, 'day').format("ddd, MMM DD, YYYY");
    }
    this.date.emit(this.todayDate);
  }

  /**
   * Get week
   */
  getWeek() {
    let startOfWeek = moment().startOf('week').format('DD');
    let endOfWeek = moment().endOf('week').format('DD');
    let startMonth = moment().startOf('week').format('MMM');
    let endMonth = moment().endOf('week').format('MMM');

    if(startMonth === endMonth) {
      this.weekDate = startMonth + ' ' + startOfWeek + '-' + endOfWeek + ', ' + moment().format('YYYY');
    } else {
      this.weekDate = startMonth + ' ' + startOfWeek + '-' + endMonth + ' ' + endOfWeek + ', ' + moment().format('YYYY');
    }

    const currentDate = moment();
    const weekStart = currentDate.clone().startOf('week');
    const weekEnd = currentDate.clone().endOf('week');
    const days = [];
    const dayFormat = [];
    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days').format("YYYY-MM-DD"));
      dayFormat.push(moment(weekStart).add(i, 'days').format("dddd MM/DD"));
    }
    this.days = dayFormat;
    this.daysFormat = days;
    this.startDate = currentDate.clone().startOf('week');
    this.endDate = currentDate.clone().endOf('week');
    this.start.emit(moment(this.startDate).format("YYYY-MM-DD"));
    this.end.emit(moment(this.endDate).format("YYYY-MM-DD"));
    this.weekDays.emit(this.days);
    this.weekDaysFormat.emit(this.daysFormat);
  }

  /**
   * Get previous week
   */
  previousWeek() {
    this.a--;
    let startOfWeek = moment().startOf('week').add(this.a, 'week').format('DD');
    let endOfWeek = moment().endOf('week').add(this.a, 'week').format('DD');
    let startMonth = moment().startOf('week').add(this.a, 'week').format('MMM');
    let endMonth = moment().endOf('week').add(this.a, 'week').format('MMM');

    if(startMonth === endMonth) {
      this.weekDate = startMonth + ' ' + startOfWeek + '-' + endOfWeek + ', ' + moment().format('YYYY');
    } else {
      this.weekDate = startMonth + ' ' + startOfWeek + '-' + endMonth + ' ' + endOfWeek + ', ' + moment().format('YYYY');
    }

    const currentDate = moment();
    const weekStart = currentDate.clone().startOf('week');
    const weekEnd = currentDate.clone().endOf('week');
    const days = [];
    const dayFormat = [];
    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days').add(this.a, 'week').format("YYYY-MM-DD"));
      dayFormat.push(moment(weekStart).add(i, 'days').add(this.a, 'week').format("dddd MM/DD"));
    }
    this.days = dayFormat;
    this.daysFormat = days;
    this.startDate = currentDate.clone().startOf('week');
    this.endDate = currentDate.clone().endOf('week');
    this.start.emit(moment(this.startDate).add(this.a, 'week').format("YYYY-MM-DD"));
    this.end.emit(moment(this.endDate).add(this.a, 'week').format("YYYY-MM-DD"));
    this.weekDays.emit(this.days);
    this.weekDaysFormat.emit(this.daysFormat);
  }

  /**
   * Get next week
   */
  nextWeek() {
    this.a++;
    let startOfWeek = moment().startOf('week').add(this.a, 'week').format('DD');
    let endOfWeek = moment().endOf('week').add(this.a, 'week').format('DD');
    let startMonth = moment().startOf('week').add(this.a, 'week').format('MMM');
    let endMonth = moment().endOf('week').add(this.a, 'week').format('MMM');

    if(startMonth === endMonth) {
      this.weekDate = startMonth + ' ' + startOfWeek + '-' + endOfWeek + ', ' + moment().format('YYYY');
    } else {
      this.weekDate = startMonth + ' ' + startOfWeek + '-' + endMonth + ' ' + endOfWeek + ', ' + moment().format('YYYY');
    }

    const currentDate = moment();
    const weekStart = currentDate.clone().startOf('week');
    const weekEnd = currentDate.clone().endOf('week');
    const days = [];
    const dayFormat = [];
    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days').add(this.a, 'week').format("YYYY-MM-DD"));
      dayFormat.push(moment(weekStart).add(i, 'days').add(this.a, 'week').format("dddd MM/DD"));
    }
    this.days = dayFormat;
    this.daysFormat = days;
    this.startDate = currentDate.clone().startOf('week');
    this.endDate = currentDate.clone().endOf('week');
    this.start.emit(moment(this.startDate).add(this.a, 'week').format("YYYY-MM-DD"));
    this.end.emit(moment(this.endDate).add(this.a, 'week').format("YYYY-MM-DD"));
    this.weekDays.emit(this.days);
    this.weekDaysFormat.emit(this.daysFormat);
  }

  /**
   * Get month
   */
  getMonth() {
    this.monthDate = moment();
    
    const currentDate = this.monthDate.format("MMMM YYYY");
    this.month.emit(currentDate);
  }

  /**
   * Get previous month
   */
  previousMonth() {
    this.i = 0;
    this.SELECTED_MONTH_DATE = null;
    if(this.isDatePicker) {
      this.i--;
      const previousMonth = moment(this.NEW_MONTH_DATE).add(this.i, 'month');
      this.NEW_MONTH_DATE = previousMonth;
      this.SELECTED_MONTH_DATE = previousMonth.format("MMMM YYYY");
      this.dateSelected.setValue(previousMonth);
      this.month.emit(this.SELECTED_MONTH_DATE);
    } else {
      this.i--;
      const previousMonth = moment(this.monthDate).add(this.i, 'month');
      this.monthDate = previousMonth;
      this.SELECTED_MONTH_DATE= previousMonth.format("MMMM YYYY");
      this.dateSelected.setValue(previousMonth);
      this.month.emit(this.SELECTED_MONTH_DATE);
    }
  }

  /**
   * Get next month
   */
  nextMonth() {  
    this.i = 0;  
    this.SELECTED_MONTH_DATE = null;
    if(this.isDatePicker) {
      this.i++;
      const nextMonth = moment(this.NEW_MONTH_DATE).add(this.i, 'month');
      this.NEW_MONTH_DATE = nextMonth;
      this.SELECTED_MONTH_DATE = nextMonth.format("MMMM YYYY");
      this.dateSelected.setValue(nextMonth);
      this.month.emit(this.SELECTED_MONTH_DATE);

    } else {
      this.i++;
      const nextMonth = moment(this.monthDate).add(this.i, 'month');
      this.monthDate = nextMonth;
      this.SELECTED_MONTH_DATE = nextMonth.format("MMMM YYYY");
      this.dateSelected.setValue(nextMonth);
      this.month.emit(this.SELECTED_MONTH_DATE);
    }
  }

  chosenYearHandler(normalizedYear: Moment) {
    this.isDatePicker = true;

    const year = this.dateSelected.value;
    year.year(normalizedYear.year());
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.isDatePicker = true;

    const month = this.dateSelected.value;
    month.month(normalizedMonth.month());
    datepicker.close();

    this.NEW_MONTH_DATE = moment(month);

    this.SELECTED_MONTH_DATE = this.NEW_MONTH_DATE.format("MMMM YYYY");
    this.dateSelected.setValue(this.NEW_MONTH_DATE);
    this.month.emit(this.SELECTED_MONTH_DATE);
  }
}

