import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';

@Component({
  selector: 'ark-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit {
  cat_name = '';
  name = false;
  isEmpty = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<AddCategoryComponent>) { }

  ngOnInit() {
    if (this.data !== '') {
      this.cat_name = this.data.name;
      this.isEmpty = false;
    } else {
      this.isEmpty = true;
    }
  }

  closeDialog(trigger){
    if(trigger){
      this.dialogRef.close(trigger);
    }else{
      this.name = true;
    }
  }

  getValue() {
    return this.name;
  }

  close() {
    this.dialogRef.close();
  }

  onEnter(){
    this.name = false;
  }
}
