import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientsComponent } from './components/patients/patients.component';
import { SharedModule } from '../shared/shared.module';
import { PatientsDirectoryService } from './services/patients-directory/patients-directory.service';
import { PatientDetailsComponent } from './components/patient-details/patient-details.component';
import { PatientGeneralInfoComponent } from './components/patient-general-info/patient-general-info.component';
import { ToothChartComponent } from './components/tooth-chart/tooth-chart.component';
import { PerioChartComponent } from './components/perio-chart/perio-chart.component';
import { PhotosComponent } from './components/photos/photos.component';
import { RadiographsComponent } from './components/radiographs/radiographs.component';
import { PatientVisitInfoComponent } from './components/patient-visit-info/patient-visit-info.component';
import { LegendComponent } from './components/tooth-chart/tooth-chart-content/legend/legend.component';
import { ToothComponent } from './components/tooth-chart/tooth-chart-content/tooth/tooth.component';
import { ToothContainerComponent } from './components/tooth-chart/tooth-chart-content/tooth-container/tooth-container.component';
import { ToothConditionComponent } from './components/tooth-chart/tooth-chart-content/tooth-condition/tooth-condition.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCheckboxModule, MatFormFieldModule, MatInputModule, MatSelectModule, MAT_DIALOG_DATA, MatDialogRef, MatDatepickerModule, MatNativeDateModule, MatAutocompleteModule, MatChipsModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { ScrewComponent } from './components/tooth-chart/tooth-chart-content/screw/screw.component';
import { DonutComponent } from './components/tooth-chart/tooth-chart-content/donut/donut.component';
import { PatientProfileComponent } from './components/patient-general-info/general-info/patient-profile/patient-profile.component';
import { PrivateInfoComponent, KeysPipe } from './components/patient-general-info/general-info/private-info/private-info.component';
import { EmergencyContactComponent } from './components/patient-general-info/general-info/emergency-contact/emergency-contact.component';
import { MedicalHistoryComponent } from './components/patient-general-info/general-info/medical-history/medical-history.component';
import { DonutBComponent } from './components/tooth-chart/tooth-chart-content/donut-b/donut-b.component';
import { DonutCComponent } from './components/tooth-chart/tooth-chart-content/donut-c/donut-c.component';
import { DonutDComponent } from './components/tooth-chart/tooth-chart-content/donut-d/donut-d.component';
import { EditPhotoComponent } from './components/photos/edit/edit-photo/edit-photo.component';
import { VisitInfoDetailsComponent } from './components/patient-visit-info/visit-info/visit-info-details/visit-info-details.component';
import { PatientBillingStatementComponent } from './components/patient-visit-info/visit-info/patient-billing-statement/patient-billing-statement.component';
import { PreviousVisitsComponent } from './components/patient-visit-info/visit-info/previous-visits/previous-visits.component';
import { ViewPhotoComponent } from './components/photos/view/view-photo/view-photo.component';
import { ViewRadiographsComponent } from './components/radiographs/view/view-radiographs/view-radiographs.component';
import { EditRadiographComponent } from './components/radiographs/edit/edit-radiograph/edit-radiograph.component';
import { ConfirmUpdateComponent } from '../shared/components/confirm-update/confirm-update.component';
import { PreviousClinicalNotesComponent } from './components/patient-visit-info/visit-info/previous-clinical-notes/previous-clinical-notes.component';
import { AddClinicalNoteComponent } from './components/patient-visit-info/visit-info/previous-clinical-notes/add-clinical-note/add-clinical-note.component';
import { EditClinicalNoteComponent } from './components/patient-visit-info/visit-info/previous-clinical-notes/edit-clinical-note/edit-clinical-note.component';
import { DentalFormRecordsComponent } from './components/patient-visit-info/visit-info/dental-form-records/dental-form-records.component';
import { PatientProfileCameraComponent } from './components/patient-general-info/general-info/patient-profile/patient-profile-camera/patient-profile-camera.component';
import { PatientCameraComponent } from './components/patient-general-info/general-info/patient-profile/patient-profile-camera/patient-camera/patient-camera.component';
import { WebcamModule } from 'ngx-webcam';
import { ConfirmBridgeComponent } from './components/tooth-chart/tooth-chart-content/bridge/confirm-bridge/confirm-bridge.component';
import { UnbridgeComponent } from './components/tooth-chart/tooth-chart-content/bridge/unbridge/unbridge.component';
import { GalleryModule } from  '@ngx-gallery/core';
import { LightboxModule } from  '@ngx-gallery/lightbox';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AddPatientBillingComponent } from './components/patient-visit-info/visit-info/add-patient-billing/add-patient-billing.component';
import { ViewPatientBillingComponent } from './components/patient-visit-info/visit-info/view-patient-billing/view-patient-billing.component';
import { AddPatientBillComponent, NoThousandSeparatorPipe } from './components/patient-visit-info/visit-info/add-patient-bill/add-patient-bill.component';
import { ViewPatientBillComponent, RemoveThousandSeparatorPipe } from './components/patient-visit-info/visit-info/view-patient-bill/view-patient-bill.component';
import { ConfirmBillingAdditionComponent } from '../shared/components/confirm-billing-addition/confirm-billing-addition.component';
import { PerioDataComponent } from './components/perio-chart/perio-chart-content/perio-data/perio-data.component';
import { PerioDetailsComponent } from './components/perio-chart/perio-chart-content/perio-details/perio-details.component';
import { UpperBuccalComponent } from './components/perio-chart/perio-chart-content/upper-buccal/upper-buccal.component';
import { UpperLingualComponent } from './components/perio-chart/perio-chart-content/upper-lingual/upper-lingual.component';
import { LowerLingualComponent } from './components/perio-chart/perio-chart-content/lower-lingual/lower-lingual.component';
import { LowerBuccalComponent } from './components/perio-chart/perio-chart-content/lower-buccal/lower-buccal.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  // suppressScrollX: true
}

@NgModule({
  declarations: [
    PatientsComponent,
    PatientDetailsComponent,
    PatientGeneralInfoComponent,
    ToothChartComponent,
    PerioChartComponent,
    PhotosComponent,
    RadiographsComponent,
    PatientVisitInfoComponent,
    LegendComponent,
    ToothComponent,
    ToothContainerComponent,
    ToothConditionComponent,
    ScrewComponent,
    DonutComponent,
    PatientProfileComponent,
    PrivateInfoComponent,
    EmergencyContactComponent,
    MedicalHistoryComponent,
    KeysPipe,
    DonutBComponent,
    DonutCComponent,
    DonutDComponent,
    EditPhotoComponent,
    EditRadiographComponent,
    VisitInfoDetailsComponent,
    PatientBillingStatementComponent,
    PreviousVisitsComponent,
    ViewPhotoComponent,
    ViewRadiographsComponent,
    PreviousClinicalNotesComponent,
    AddClinicalNoteComponent,
    EditClinicalNoteComponent,
    DentalFormRecordsComponent,
    PatientProfileCameraComponent,
    PatientCameraComponent,
    ConfirmBridgeComponent,
    UnbridgeComponent,
    AddPatientBillingComponent,
    ViewPatientBillingComponent,
    AddPatientBillComponent,
    NoThousandSeparatorPipe,
    RemoveThousandSeparatorPipe,
    ViewPatientBillComponent,
    PerioDataComponent,
    PerioDetailsComponent,
    UpperBuccalComponent,
    UpperLingualComponent,
    LowerLingualComponent,
    LowerBuccalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    RouterModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatChipsModule,
    WebcamModule,
    GalleryModule,
    LightboxModule,
    PerfectScrollbarModule
  ],
  exports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    FormsModule,
    MatCheckboxModule
  ],
  providers: [
    PatientsDirectoryService,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  entryComponents: [
    ToothConditionComponent,
    EditPhotoComponent,
    EditRadiographComponent,
    ViewPhotoComponent,
    ViewRadiographsComponent,
    ConfirmUpdateComponent,
    AddClinicalNoteComponent,
    EditClinicalNoteComponent,
    PatientProfileCameraComponent,
    ConfirmBridgeComponent,
    UnbridgeComponent,
    AddPatientBillingComponent,
    ViewPatientBillingComponent,
    AddPatientBillComponent,
    ViewPatientBillComponent,
    ConfirmBillingAdditionComponent
  ]
})
export class PatientsModule { }
