import { Component, OnInit } from '@angular/core';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { RecallVisitListsComponent } from './recall-visit-lists/recall-visit-lists.component';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';

@Component({
  selector: 'ark-recall-visit',
  templateUrl: './recall-visit.component.html',
  styleUrls: ['./recall-visit.component.scss']
})
export class RecallVisitComponent implements OnInit {

  scheduleVisits = [
    { name: '3 months', value: 3 },
    { name: '6 months', value: 6 },
    { name: '12 months', value: 12 },
  ];
  dialogConfig = new MatDialogConfig();
  dialogRef: any;
  success: boolean = false;
  loading: boolean = false;
  visits = '';
  recallVisit = '';
  isNoResult: boolean = false;
  clinic = null;

  constructor(
    public dialog: MatDialog,
    private patientsDirectory: PatientsDirectoryService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    
  }

  getRecallVisit(value) {
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.patientsDirectory.getRecallVisits(value, this.clinic).subscribe(res => {
      if(res) {
        this.visits = res;
        this.dialogConfig.disableClose = true;
        this.dialogConfig.autoFocus = false;
        this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
        this.dialogConfig.data = {
          value: value,
          response: this.visits
        }
        this.dialogRef = this.dialog.open(RecallVisitListsComponent, this.dialogConfig);
      }
    });
  }
}
