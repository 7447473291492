import { Component, OnInit, OnChanges, HostListener, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/modules/account-settings/services/user/user.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { ConfirmClinicSwitchComponent } from 'src/app/modules/shared/components/confirm-clinic-switch/confirm-clinic-switch.component';

@Component({
  selector: 'ark-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, OnChanges {

  user : any;
  clinics : any;
  activeClinic = null;
  val = null;
  profile : any;
  isAssistant : boolean = false;
  isHidden : boolean = false;
  dialogConfig = new MatDialogConfig();
  dialogRef : any;
  search = '';

  constructor(
    private auth: AuthService,
    private clinic: UserService,
    private _router: Router,
    private dialog: MatDialog,
    private eRef: ElementRef
  ) { 
    this.activeClinic = this.auth.getAuthUser().user_clinic.name;
  }

  ngOnInit() {
    let image = this.auth.getAuthUser().profile_image ? this.auth.getAuthUser().profile_image : "../../../../../assets/image/image-thumbnail.jpg";
    document.getElementById("profile_icon")['src'] = image;

    this.getUserDetail();
    this.getClinics();
  }

  ngOnChanges() {
    this.activeClinic = this.auth.getAuthUser().user_clinic.name;
  }

  getUserDetail() {
    this.profile = this.auth.getAuthUser();
    if(this.profile.role_id === 'Owner/Dentist' || this.profile.role_id === 'Dentist') {
      this.user = 'Dr. ' + this.profile.last_name;
      this.isAssistant = false;
    } else {
      this.user = this.profile.last_name;
      this.isAssistant = true;
    }
  }

  getClinics() {
    this.clinic.viewClinics().subscribe(res => {
      if(res) {
        this.clinics = res['data'].user_clinics;
      }
    })
  }

  logout() {
    let data = {
      token: this.auth.getAuthUser().token
    }
    this.auth.logout(data).subscribe(res => {
      localStorage.removeItem('authUser');
      localStorage.removeItem('token');
      this._router.navigate(["/login"]);
    });
  }

  accountSettings() {
    this._router.navigate(["/account-settings"]);
  }

  dailyTimeRecord() {
    this._router.navigate(["/daily-time-record"]);
  }

  onFocus() {
    this.isHidden = true;
  }

  onBlur() {
    if(this.search != '') {
      this.isHidden = true;
    } else {
      setTimeout(() => {
        this.isHidden = false;
      }, 650);
    }
  }

  searchPatient(val) {
    if(val) {
      this.val = val;
      this._router.navigate(['/search'], { queryParams: { keyword: val } });
    } else {
      this.val = '';
      this._router.navigate(['/dashboard']);
    }
  }

  onClinicSelect(e) {
    this.dialogConfig.data = {
      subject: e.name
    };
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    let dialogRef = this.dialog.open(ConfirmClinicSwitchComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
      if(trigger) {
        this.activeClinic = e.name;
        if(localStorage.getItem('authUser')) {
          let name = JSON.parse(localStorage.getItem('authUser'));
          name.user_clinic = e;
          this.auth.setAuthUser(name);
          this.redirectTo('/dashboard');
        }
      }
    });
  }

  redirectTo(uri: string) {
    this._router.navigateByUrl('/login', {skipLocationChange: true}).then(()=>
    this._router.navigate([uri]));
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.search = '';
    }
  }
}
