import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { ExpensesService } from 'src/app/modules/front-desk/service/expenses/expenses.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ConfirmDeletionComponent } from 'src/app/modules/shared/components/confirm-deletion/confirm-deletion.component';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import * as moment from 'moment';
import { EditOperationalExpensesComponent } from './edit-operational-expenses/edit-operational-expenses.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { keypressValidateNumber, formatNumber } from 'src/app/common/helper';
import { Router } from '@angular/router';
import { count } from 'rxjs-compat/operator/count';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'ark-operational-expenses',
  templateUrl: './operational-expenses.component.html',
  styleUrls: ['./operational-expenses.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class OperationalExpensesComponent implements OnInit, OnChanges {

  @Input() month: any = null; 
  @Input() year: any = null; 
  @Input() data: any = null; 
  @Output() expensesSummary: EventEmitter<any> = new EventEmitter;
  @Output() preSavedData: EventEmitter<any> = new EventEmitter;
  today = new Date();
  clinic = null;
  selectedYear = '';
  selectedMonth = '';
  operationalExpenses = [];
  hasItems: boolean = false;
  isNoResult: boolean = true;
  isDisabled: boolean = false;
  dialogConfig = new MatDialogConfig();
  dialogRef: any;
  paymentTypes = [
    { id: 1, name: 'Cash', value: 'cash' },
    { id: 2, name: 'Cheque', value: 'cheque' }
  ];
  expenseStatus = [
    { id: 0, name: 'Unpaid', value: 'unpaid' },
    { id: 1, name: 'Paid', value: 'paid' }
  ];
  fieldArray: Array<any> = [];
  newAttribute: any = {
    expenses_category_id: 0
  };
  totalDue = 0;
  totalPaid = 0;

  constructor(
    private auth: AuthService,
    private expenses: ExpensesService,
    public dialog: MatDialog,
    private router: Router
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
  }

  ngOnInit() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  ngOnChanges() {
    if(this.month || this.year || this.data) {
      this.selectedMonth =  this.month;
      this.selectedYear =  this.year;
      this.operationalExpenses = this.data;
      if(this.operationalExpenses.length > 0) {
        this.isNoResult = false;
      } else {
        this.isNoResult = true;
      }
      this.getExpenses();
    }
  }

  getExpenses() {
    this.fieldArray = [];
    this.expenses.getOperationalExpenses(this.clinic, this.selectedMonth, this.selectedYear).subscribe(res => {
      if(res) {
        this.operationalExpenses = res.item.data;
        if(this.operationalExpenses.length > 0) {
          this.hasItems = true;
          this.isNoResult = false;
          for (let index = 0; index < this.operationalExpenses.length; index++) {
            this.fieldArray.push([]);
          }
          this.operationalExpenses.forEach((element, i) => {
            element.total_amount_due = 0;
            element.total_amount_paid = 0;
            if(element.expenses) {
              this.totalDue = 0;
              this.totalPaid = 0;
              element.expenses.forEach(el => {
                this.fieldArray[i].push({
                  id: el.id,
                  expenses_category_id: el.expenses_category_id,
                  name: el.name,
                  amount_due: Number(el.amount_due),
                  amount_paid: Number(el.amount_paid),
                  payment_type: el.payment_type,
                  cheque_no: el.cheque_no,
                  date_paid: el.date_paid,
                  status: el.status
                });
                this.totalDue += Number(el.amount_due);
                this.totalPaid += Number(el.amount_paid);
              });
              element.total_amount_due = Number(this.totalDue);
              element.total_amount_paid = Number(this.totalPaid);
            }
          });
          if(localStorage.getItem('operationalExpenses')) {
            const PRE_SAVED_DATA = JSON.parse(localStorage.getItem('operationalExpenses'));
            this.fieldArray = [];
            PRE_SAVED_DATA.forEach(el => {
              this.fieldArray.push(el);
              el.forEach(element => {
                this.totalDue += Number(element.amount_due);
                this.totalPaid += Number(element.amount_paid);
              });
            });
            this.operationalExpenses.forEach(element => {
              element.total_amount_due = Number(this.totalDue);
              element.total_amount_paid = Number(this.totalPaid);
            });
          }
        } else {
          this.hasItems = false;
          this.isNoResult = true;
        }
      } 
    });
  }

  addItem(id, index) {
    this.newAttribute.expenses_category_id = id;
    this.fieldArray[index].push(this.newAttribute);
    this.newAttribute = {
      expenses_category_id: 0
    };
  }

  deleteExpense(id) {
    this.dialogConfig.data = {
      subject: 'delete this expense'
    };
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    let dialogRef = this.dialog.open(ConfirmDeletionComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
      if(trigger) {
        this.expenses.deleteOperationalExpensesCategory(id, this.clinic, this.selectedMonth, this.selectedYear).subscribe(res => {
          if(res) {
            this.operationalExpenses = res.item.data;
            this.expensesSummary.emit(res);
            this.dialogConfig.data = {
              title: 'Success!',
              message: 'Operational expense has been successfully deleted.',
              button: 'Okay',
              event: this.closeOnClick
            };
            let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => this.getExpenses());
            if(this.operationalExpenses.length > 0) {
              this.isNoResult = false;
            } else {
              this.isNoResult = true;
            }
          }
        },(err => {
          this.dialogConfig.data = {
            title: 'Oops!',
            message: err.error.message,
            button: 'Okay',
            event: this.closeOnClick
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }));
      }
    });
  }

  editExpense(id, name) {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogConfig.data = {
      id: id,
      name: name,
      clinic: this.clinic,
      month: +moment().format("M"),
      year: +moment().format("YYYY")
    }
    this.dialogRef = this.dialog.open(EditOperationalExpensesComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(res => {
      if(res) {
        this.operationalExpenses = res.data.item.data;
      }
    });
  }

  save() {
    const category = [];
    let x = '';
    this.fieldArray.forEach(element => {
      element.forEach(element => {
        x = element;
        category.push(element);
      });
    });
    let data = {
      categories: category,
      user_clinic_id: this.clinic,
      month: this.selectedMonth,
      year: this.selectedYear
    }
    if(x['payment_type'] == 'cheque' && x['cheque_no'] == null || x['payment_type'] == 'cheque' && x['cheque_no'] == '') {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'The cheque no. field is required.',
        button: 'Okay',
        event: this.closeOnClick
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    } else {
      this.expenses.saveOperationalExpensesItems(data).subscribe(res => {
        if(res) {
          if(localStorage.getItem('operationalExpenses')) {
            localStorage.removeItem('operationalExpenses')
          }
          this.operationalExpenses = res['data'].item.data;
          this.expensesSummary.emit(res['data']);
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Operational expenses has been successfully saved.',
            button: 'Okay',
            event: this.closeOnClick
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => this.getExpenses());
        }
      },(err => {
        if(Object.keys(err.error.errors).length == 1) {
          Object.keys(err.error.errors).forEach(element => {
            this.dialogConfig.data = {
              title: 'Oops!',
              message: err.error.errors[element],
              button: 'Okay',
              event: this.closeOnClick
            };
            let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
            return;
          });
        } else {
          this.dialogConfig.data = {
            title: 'Oops!',
            message: err.error.message,
            button: 'Okay',
            event: this.closeOnClick
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }
      }));
    }
  }

  update() {
    const category = [];
    let x = '';
    this.fieldArray.forEach(element => {
      element.forEach(element => {
        x = element;
        category.push(element);
      });
    });
    let data = {
      categories: category,
      user_clinic_id: this.clinic,
      month: this.selectedMonth,
      year: this.selectedYear
    }
    if(x['payment_type'] == 'cheque' && x['cheque_no'] == null || x['payment_type'] == 'cheque' && x['cheque_no'] == '') {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'The cheque no. field is required.',
        button: 'Okay',
        event: this.closeOnClick
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    } else {
      this.expenses.saveOperationalExpensesItems(data).subscribe(res => {
        if(res) {
          if(localStorage.getItem('operationalExpenses')) {
            localStorage.removeItem('operationalExpenses')
          }
          this.operationalExpenses = res['data'].item.data;
          this.expensesSummary.emit(res['data']);
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Operational expenses have been successfully updated.',
            button: 'Okay',
            event: this.closeOnClick
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => this.getExpenses());
        }
      },(err => {
        if(Object.keys(err.error.errors).length == 1) {
          Object.keys(err.error.errors).forEach(element => {
            this.dialogConfig.data = {
              title: 'Oops!',
              message: err.error.errors[element],
              button: 'Okay',
              event: this.closeOnClick
            };
            let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
            return;
          });
        } else {
          this.dialogConfig.data = {
            title: 'Oops!',
            message: err.error.message,
            button: 'Okay',
            event: this.closeOnClick
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }
      }));
    }
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  removeItem(x, index, data) {
    if(data === undefined){
      this.fieldArray[x].splice(index, 1);
    } else {
      this.dialogConfig.data = {
        subject: 'remove this item'
      };
      this.dialogConfig.disableClose = true;
      this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
      let dialogRef = this.dialog.open(ConfirmDeletionComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
        if(trigger) {
          this.expenses.deleteOperationalExpensesItem(data.id, this.clinic, this.selectedMonth, this.selectedYear).subscribe(res => {
            if(res) {
              this.operationalExpenses = res.item.data;
              this.expensesSummary.emit(res);
              this.dialogConfig.data = {
                title: 'Success!',
                message: 'Item has been successfully deleted.',
                button: 'Okay',
                event: this.closeOnClick
              };
              let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => this.getExpenses());
            }
          });
        }
      });
    }
  }

  selectionChange(e, x, index) {
    if(e.value == 'cash') {
      this.fieldArray[x][index].cheque_no = 'N/A';
    } else {
      this.fieldArray[x][index].cheque_no = '';
    }
    this.preSavedData.emit(this.fieldArray);
  }

  dateSelected(val, x, index) {
    const date = moment(val).format("YYYY-MM-DD");
    this.fieldArray[x][index].date_paid = date;
    this.preSavedData.emit(this.fieldArray);
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }

  formatNumber(num) {
    return formatNumber(num);
  }

  goToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  clearDate(event, x, index) {
    event.stopPropagation();
    this.fieldArray[x][index].date_paid = null;
  }

  preSaveData(e) {
    // Pre save data when user didn't save/update the new item.
    this.preSavedData.emit(this.fieldArray);
  }
}
