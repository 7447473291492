import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatAutocompleteTrigger, MatAutocomplete } from '@angular/material';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard/dashboard.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { startWith, map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { keypressValidateNumber } from 'src/app/common/helper';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

export interface Dentist {
  id: number;
  firstName: string;
  lastName: string;
}

export interface Procedure {
  id: number;
  name: string;
}

@Component({
  selector: 'ark-add-clinical-note',
  templateUrl: './add-clinical-note.component.html',
  styleUrls: ['./add-clinical-note.component.scss']
})
export class AddClinicalNoteComponent implements OnInit {

  datePipe = new DatePipe('en-US');
  todayAppointments: any;
  errorMessage = null;
  hasError: boolean = false;
  clinic = '';
  dentistId = '';
  selectedProcedure = null;
  unlistedProcedure = null;
  customCollapsedHeight: string = '20px';
  customExpandedHeight: string = '20px';
  dialogConfig = new MatDialogConfig();
  formGroup: FormGroup;
  dentistControl = new FormControl();
  procedureControl = new FormControl();
  dentists: Dentist[] = [];
  procedures: Procedure[] = [];
  filteredDentists: Observable<Dentist[]>;
  filteredProcedures: Observable<Procedure[]>;
  @ViewChild('dentist') matAutocompleteDentist: MatAutocomplete;
  @ViewChild('dentist') dentist: MatAutocompleteTrigger;
  @ViewChild('procedure') procedure: MatAutocompleteTrigger;
  today = new Date();
  inputtedString = '';
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddClinicalNoteComponent>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private dashboard: DashboardService,
    private auth: AuthService,
    private clinicalNote: PatientsDirectoryService
  ) {
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.dashboard.getDentistList(this.clinic).subscribe(res => {
      res.forEach(element => {
        let obj: Dentist = {
          id: element.id, 
          firstName: element.first_name,
          lastName: element.last_name
        };
        this.dentists.push(obj);
      });
    }); 
    this.dashboard.getProcedures(this.clinic).subscribe(res => {
      res.forEach(element => {
        let obj: Procedure = {
          id: element.id, 
          name: element.title
        };
        this.procedures.push(obj);
      });
    });
   }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      date: [''],
      procedure: [''],
      dentist_id: [''],
      clinical_note: [''],
      amount_paid: [''],
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.filteredDentists = this.dentistControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.filterDentist(name) : this.dentists.slice())
      );
    this.filteredProcedures = this.procedureControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.filterProcedure(name) : this.procedures.slice())
      );
  }

  filterDentist(name: string): Dentist[] {
    const filterValue = name.toLowerCase();
    return this.dentists.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1);
  }

  filterProcedure(name: string): Procedure[] {
    const filterValue = name.toLowerCase();
    return this.procedures.filter(option => option.name.toLowerCase().indexOf(filterValue) > -1);
  }

  displayDentist(dentist?: any) : string | undefined {
    return dentist ? 'Dr. ' + dentist.firstName + ' ' + dentist.lastName : undefined;
  }

  displayProcedure(procedure?: any) : string | undefined {
    return procedure ? procedure : undefined;
  }
  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }

  close() {
    this.dialogRef.close();
  }

  getDentist(event) {
    this.dentistId = event.option.value.id;
    this.inputtedString = '';
  }

  createClinicalNotes() {
    let date = this.datePipe.transform(this.formGroup.value.date, 'yyyy-MM-dd');
    let data = {
      user_clinic_id: this.clinic,
      date: date,
      patient_id: this.data.patient_id,
      procedure: this.selectedProcedure ? this.selectedProcedure : this.unlistedProcedure,
      clinical_note: this.formGroup.value.clinical_note,
      amount_paid: this.formGroup.value.amount_paid,
      dentist_id: this.dentistId
      
    }
    if(this.inputtedString != '') {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'The attending dentist field is invalid.',
        button: 'Okay',
        event: this.close
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    } else {
      this.clinicalNote.addPatientClinicalNotes(data).subscribe(res => {
        if(res) {
          this.dialogRef.close(res);
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Patient form has been successfully saved.',
            button: 'Okay',
            event: this.close
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Sorry!',
          message: err.error.message,
          button: 'Okay',
          event: this.close
        };

        if(this.formGroup.value.clinical_note == '' || this.formGroup.value.clinical_note == null) {
          this.hasError = true;
        } else {
          this.hasError = false;
        }
        
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        this.errorMessage = err.error.errors;
        this.triggerErrors(err.error.errors);
      }));
    }
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }
  onFocusProcedure() {
    this.procedure._onChange(""); 
    this.procedure.openPanel();
  }

  getProcedure(event) {
    this.selectedProcedure = event.option.value;
  }

  getNewProcedure(e) {
    this.unlistedProcedure = e;
  }

  onKeydown(event) {
    if(event.keyCode == 8 || event.keyCode == 46) {
      this.inputtedString = '';
    } else {
      this.inputtedString = null;
    }
  }

  onFocusDentist() {
    this.dentist._onChange(""); 
    this.dentist.openPanel();
  }
}
