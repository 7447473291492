import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import { SubscribeService } from 'src/app/modules/subscription/services/subscribe/subscribe.service';

@Component({
  selector: 'ark-customer-agreement',
  templateUrl: './customer-agreement.component.html',
  styleUrls: ['./customer-agreement.component.scss']
})
export class CustomerAgreementComponent implements OnInit {

  dialogConfig = new MatDialogConfig();

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CustomerAgreementComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private subscription: SubscribeService
  ) { }

  ngOnInit() { }

  close(flag = false) {
    this.dialogRef.close(flag);
  }

  termsAndConditions() {
    this.subscription.termsAndConditions().subscribe(res => {
      this.dialogConfig.data = {
        data: res
      };
      this.dialogConfig.disableClose = true;
      this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
      let dialogRef = this.dialog.open(TermsAndConditionsComponent, this.dialogConfig);
    });
  }
}
