import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { PatientService } from 'src/app/modules/front-desk/service/patient/patient.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { keypressValidateNumber } from 'src/app/common/helper';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'ark-medical-history',
  templateUrl: './medical-history.component.html',
  styleUrls: ['./medical-history.component.scss']
})
export class MedicalHistoryComponent implements OnInit { 

  @Input() patient: any;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  allergyCtrl = new FormControl();
  filteredAllergies: Observable<string[]>;
  allergies: string[] = [];
  allAllergies: string[] = [
    'Drug Allergy', 
    'Food Allergy', 
    'Insect Allergy', 
    'Latex Allergy', 
    'Pet Allergy',
    'Pollen Allergy'
  ];
  @ViewChild('allergyInput') allergyInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  dentalHistories = [
    { id: 1, name: 'AIDS', is_checked: 0, value: null },
    { id: 2, name: 'Alcohol', is_checked: 0, value: null },
    { id: 3, name: 'Allergies', is_checked: 0, value: [] },
    { id: 4, name: 'Anemia', is_checked: 0, value: null },
    { id: 5, name: 'Arthritis', is_checked: 0, value: null },
    { id: 6, name: 'Asthma', is_checked: 0, value: null },
    { id: 7, name: 'Bleeding', is_checked: 0, value: null },
    { id: 8, name: 'Breastfeeding', is_checked: 0, value: null },
    { id: 9, name: 'Cancer', is_checked: 0, value: null },
    { id: 10, name: 'Diabetes', is_checked: 0, value: null },
    { id: 11, name: 'Dialysis', is_checked: 0, value: null },
    { id: 12, name: 'Endocrine', is_checked: 0, value: null },
    { id: 13, name: 'Epilepsy', is_checked: 0, value: null },
    { id: 14, name: 'Headaches', is_checked: 0, value: null },
    { id: 15, name: 'Heart Condition', is_checked: 0, value: '' },
    { id: 16, name: 'Hepatitis', is_checked: 0, value: null },
    { id: 17, name: 'HIV', is_checked: 0, value: null },
    { id: 18, name: 'Hospitalization', is_checked: 0, value: '' },
    { id: 19, name: 'Kidney Disease', is_checked: 0, value: null },
    { id: 20, name: 'Lung Condition', is_checked: 0, value: null },
    { id: 21, name: 'Peptic Ulcer', is_checked: 0, value: null },
    { id: 22, name: 'Pregnancy', is_checked: 0, value: null },
    { id: 23, name: 'Psychological Condition', is_checked: 0, value: null },
    { id: 24, name: 'Smoking', is_checked: 0, value: '1 smoke a day' },
    { id: 25, name: 'Teeth Grinding', is_checked: 0, value: null },
    { id: 26, name: 'Thyroid Condition', is_checked: 0, value: null },
    { id: 27, name: 'Tuberculosis', is_checked: 0, value: null },
    { id: 28, name: 'Others', is_checked: 0, value: '' },
  ];
  
  dialogConfig = new MatDialogConfig();
  dialogRef: any;
  historyData: any = [];
  patientId: '';
  errorMessage = '';
  success = false;
  hasAllergy = false;
  formGroup: FormGroup;
  formData: any;
  label = 'Update History';
  medicalHistory = [];
  isEditable = false;
  INPUT_A = '';
  INPUT_B = '';
  INPUT_C = '';
  INPUT_D = '';
  
  constructor(
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private patientsDirectory: PatientsDirectoryService,
    private route: ActivatedRoute,
    private auth: AuthService,
    public dialog: MatDialog,
    private _router: Router,
  ) {
    this.route.params.subscribe(param => {
      this.patientId = param.id
    });
    this.filteredAllergies = this.allergyCtrl.valueChanges.pipe(
      startWith(null),
      map((allergy: string | null) => allergy ? this._filter(allergy) : this.allAllergies.slice()));
   }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      dentalHistories: new FormArray([])
    });
    this.formData = this.patientService.getData();
    let id = this.auth.getAuthUser().user_clinic.id;
    this.patientsDirectory.getPatientInfo(id, this.patientId, id).subscribe(response =>{
      let patient = response['data'][0].medical_and_dental_history;
      this.medicalHistory = JSON.parse(patient.history);
      this.dentalHistories.forEach(element => {
        this.medicalHistory.forEach(el => {
          if(element.id == el.id) {
            element.is_checked = 1;
            element.value = el.value;
          }
        });
      });
      this.addCheckboxes();
      if(this.medicalHistory !== []) {
        let x = this.medicalHistory.filter(e => e.id == 3).map(e => e.value);
        let a = this.medicalHistory.filter(e => e.id == 15).map(e => e.value);
        let b = this.medicalHistory.filter(e => e.id == 18).map(e => e.value);
        let c = this.medicalHistory.filter(e => e.id == 24).map(e => e.value);
        let d = this.medicalHistory.filter(e => e.id == 28).map(e => e.value);
        this.INPUT_A = c[0];
        this.INPUT_B = b[0];
        this.INPUT_C = a[0];
        this.INPUT_D = d[0];

        if(x.length > 0) {
          x[0].forEach(element => {
            this.allergies.push(element);
          });
        } 
      }
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      if ((value || '').trim()) {
        this.allergies.push(value.trim());
        this.dentalHistories[2].value = this.allergies;
      }
      if (input) {
        input.value = '';
      }
    }

    this.allergyCtrl.setValue(null);
  }

  remove(allergy: string): void {
    const index = this.allergies.indexOf(allergy);
    if (index >= 0) {
      this.allergies.splice(index, 1);
      this.dentalHistories[2].value = this.allergies;
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.allergies.push(event.option.viewValue);
    this.allergyInput.nativeElement.value = '';
    this.allergyCtrl.setValue(null);
    
    this.dentalHistories[2].value = this.allergies;
  }

  smoking(event) {
    this.dentalHistories[23].value = event;
  }

  hospitalization(event) {
    this.dentalHistories[17].value = event;
  }

  heartCondition(event) {
    this.dentalHistories[14].value = event;
  }

  others(event) {
    this.dentalHistories[27].value = event;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allAllergies.filter(allergy => allergy.toLowerCase().indexOf(filterValue) === 0);
  }

  addCheckboxes() {
    this.dentalHistories.map((o, i) => {
      const control = new FormControl(o.is_checked === 1);
      (this.formGroup.controls.dentalHistories as FormArray).push(control);
    });
  }

  updatePatient() {
    this.formGroup.value.dentalHistories.map((selected, i) => {
      if(selected == true) {
        this.dentalHistories[i].is_checked = 1;
        this.historyData.push(this.dentalHistories[i]);
      } 
    });
    let clinic = this.auth.getAuthUser().user_clinic.id;
    this.patientsDirectory.getPatientInfo(clinic, this.patientId, clinic).subscribe(response =>{
      let id = response['data'][0].medical_and_dental_history.id;
      let history = JSON.stringify(this.historyData); 
      let data = {
        history: history
      }
      this.patientsDirectory.updateDentalHistory(id, clinic, data).subscribe(res => {
        if(res) {
          this.success = true;
          const name = JSON.parse(localStorage.getItem('patientName'));
          name['allergy'] = res['data'].data[0].allergies;
          localStorage.setItem('patientName', JSON.stringify(name));

          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Patient\'s medical and dental history has been successfully updated.',
            button: 'Okay',
            event: this.closeOnClick
          };
          this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => {
            this.redirectTo(this._router.url);
          });
        }
      });
    });
  }

  redirectTo(uri: string) {
    this._router.navigateByUrl('/patients', {skipLocationChange: true}).then(()=>
    this._router.navigate([uri]));
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }
}
