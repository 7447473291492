import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { keypressValidateNumber } from 'src/app/common/helper'
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { Observable } from 'rxjs';
import { MatAutocompleteTrigger, MatDialogConfig, MatDialog } from '@angular/material';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard/dashboard.service';
import { startWith, map } from 'rxjs/operators';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { PreFilledPrescriptionsComponent } from './pre-filled-prescriptions/pre-filled-prescriptions.component';
import { DentalFormsService } from '../../../services/dental-forms/dental-forms.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import * as moment from 'moment';
import { Router } from '@angular/router';

export interface Patient {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
}

export interface Dentist {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
}

@Component({
  selector: 'ark-rx',
  templateUrl: './rx.component.html',
  styleUrls: ['./rx.component.scss']
})
export class RxComponent implements OnInit {

  clinic = null;
  errorMessage = null;
  form: FormGroup;
  genders = [
    { id : '1', name: 'Male' },
    { id : '2', name: 'Female' }
  ];
  isEmpty: boolean = false;
  hasPrescription: boolean = false;
  patientControl = new FormControl();
  dentistControl = new FormControl();
  patients: Patient[] = [];
  dentists: Dentist[] = [];
  filteredPatients: Observable<Patient[]>;
  filteredDentists: Observable<Dentist[]>;
  patientId = '';
  dentistId = '';
  patientName = '';
  dentistName = '';
  dialogConfig = new MatDialogConfig();
  dialogRef: any;
  @ViewChild('patient') patient: MatAutocompleteTrigger;
  @ViewChild('dentist') dentist: MatAutocompleteTrigger;
  prescriptions: any;
  index = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35]
  
  INPUT_A = '';
  INPUT_B = '';
  INPUT_C = '';
  input = {};
  
  today = new Date(); 

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private dashboard: DashboardService,
    public dialog: MatDialog,
    private patientDirectory: PatientsDirectoryService,
    private forms: DentalFormsService,
    private router: Router
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic;
    this.dashboard.getPatientList(this.clinic.id, this.clinic.id).subscribe(res => {
      res.data.forEach(element => {
        let obj: Patient = {
          id: element.id, 
          firstName: element.first_name, 
          middleName: element.middle_name ? element.middle_name : '', 
          lastName: element.last_name
        };
        this.patients.push(obj);
      });
    });
    this.dashboard.getDentistList(this.clinic.id).subscribe(res => {
      res.forEach(element => {
        let obj: Dentist = {
          id: element.id, 
          firstName: element.first_name,
          middleName: element.middle_name ? element.middle_name : '', 
          lastName: element.last_name
        };
        this.dentists.push(obj);
      });
    });
  }

  ngOnInit() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    
    this.form = this.formBuilder.group({
      clinic_address: [''],
      telephone_no: [''],
      mobile_no: [''],
      clinic_hours: [''],
      website: [''],
      email: [''],
      patient_id: new FormControl('', [Validators.required]),
      patient_sex: [''],
      patient_age: [''],
      patient_address: [''],
      date: ['', [Validators.required]],
      notes: [''],
      dentist_id: new FormControl('', [Validators.required]),
      prc_no: ['', [Validators.required]],
      ptr_no: [''],
    });
    this.form.get('clinic_address').setValue(this.clinic.address ? this.clinic.address : '');
    this.form.get('telephone_no').setValue(this.clinic.contact_number ? this.clinic.contact_number : '');
    this.form.get('mobile_no').setValue(this.clinic.mobile_number ? this.clinic.mobile_number : '');
    this.form.get('clinic_hours').setValue(this.clinic.clinic_hours ? this.clinic.clinic_hours : '');
    this.form.get('website').setValue(this.clinic.website ? this.clinic.website : '');
    this.form.get('email').setValue(this.clinic.email ? this.clinic.email : '');
    // this.disableInputs();

    if(this.form.value.website == null) {
      this.isEmpty = true;
    } else {
      this.isEmpty = false;
    }

    this.filteredPatients = this.form.get('patient_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterPatient(name) : this.patients.slice()),
      );
    this.filteredDentists = this.form.get('dentist_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterDentist(name) : this.dentists.slice())
      );
  }

  disableInputs() {
    this.form.controls['clinic_address'].disable();
    this.form.controls['telephone_no'].disable();
    this.form.controls['mobile_no'].disable();
    this.form.controls['website'].disable();
    this.form.controls['email'].disable();
    this.form.controls['clinic_hours'].disable();
  }

  private _filterPatient(name: string): Patient[] {
    const filterValue = name.toLowerCase();
    return this.patients.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1 || option.middleName.toLowerCase().indexOf(filterValue) > -1);
  }

  private _filterDentist(name: string): Dentist[] {
    const filterValue = name.toLowerCase();
    return this.dentists.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1);
  }

  displayDentist(dentist?: any) : string | undefined {
    return dentist ? 'Dr. ' + dentist.firstName + (dentist.middleName ? ' ' + dentist.middleName + ' ' : ' ') + dentist.lastName : undefined;
  }

  displayPatient(patient?: any) : string | undefined {
    return patient ? patient.lastName + ', ' + patient.firstName + (patient.middleName ? ' ' + patient.middleName : ' ') : undefined;
  }

  onFocusPatient() {
    this.patient._onChange("");
    this.patient.openPanel();
  }

  onFocusDentist() {
    this.dentist._onChange(""); 
    this.dentist.openPanel();
  }

  getPatient(event) {
    this.patientId = event.option.value.id;
    this.patientName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.patientDirectory.getPatientInfo(this.clinic.id, event.option.value.id, this.clinic.id).subscribe(res => {
      if(res) {
        this.getPrescription();
        this.form.get('patient_address').setValue(res['data'][0].patient_private_information.detailed_address ? res['data'][0].patient_private_information.detailed_address : '');
        this.form.get('patient_sex').setValue(res['data'][0].patient_private_information.gender ? res['data'][0].patient_private_information.gender : '');

        let age = moment().diff(res.data[0].patient_private_information.birthdate, 'years');
        this.form.get('patient_age').setValue(age);
      }
    });
    this.form.get('patient_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
  }

  getDentist(event) {
    this.dentistId = event.option.value.id;
    this.dentistName = event.option.value.firstName + (event.option.value.middleName ? ' ' + event.option.value.middleName + ' ' : ' ') + event.option.value.lastName;
    this.dashboard.getDentistInfo(event.option.value.id, this.clinic.id).subscribe(res => {
      if(res) {
        this.form.get('prc_no').setValue(res[0].user.prc_license_no ? res[0].user.prc_license_no : '');
        this.form.get('ptr_no').setValue(res[0].user.ptr_no ? res[0].user.ptr_no : '');  
      }
    });
    this.form.get('dentist_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
  }

  getPrescription() {
    if(this.patientId !== null) {
      this.forms.getPrescription(this.clinic.id, this.patientId).subscribe(res => {
        if(res.prescription) {
          this.prescriptions = [];
          this.hasPrescription = true;
          const data = JSON.parse(res.prescription);
          this.prescriptions = data.prescription;
          this.prescriptions.forEach((element, i) => {
            this.input[i] = {INPUT_A: element.value};
            this.input[i] = {INPUT_B: element.sub_content.label_option.value};
            this.input[i] = {INPUT_C: element.sub_content.desc_option.value};
          });
        } else {
          this.hasPrescription = false;
          this.prescriptions = [];
        }
      });
    }
  }

  addPrescription() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogConfig.data = {
      id : this.patientId,
      data : this.prescriptions,
      notes : this.form.value.notes
    }
    this.dialogRef = this.dialog.open(PreFilledPrescriptionsComponent, this.dialogConfig).afterClosed().subscribe(res => {
      this.getPrescription();
      this.form.get('notes').setValue(res.data.notes);
    });
  }

  inputA(event, index) {
    this.prescriptions[index].value = event;
    this.INPUT_A[index]
  }

  inputB(event, index) {
    this.prescriptions[index].sub_content.label_option.value = event;
  }

  inputC(event, index) {
    this.prescriptions[index].sub_content.desc_option.value = event;
  }

  download() {
    const date = this.form.value.date ? moment(this.form.value.date).format('MM/DD/YYYY') : '';
    const data = JSON.stringify({prescription: this.prescriptions});
    const FORM = {
      user_clinic_id: this.clinic.id,
      clinic_address: this.form.value.clinic_address,
      telephone_no: this.form.value.telephone_no.toString(),
      mobile_no: this.form.value.mobile_no.toString(),
      clinic_hours: this.form.value.clinic_hours,
      website: this.form.value.website,
      email: this.form.value.email, 
      patient_name: this.patientName, 
      patient_address: this.form.value.patient_address, 
      patient_age: this.form.value.patient_age, 
      patient_sex: this.form.value.patient_sex, 
      date: date, 
      prescription: data, 
      notes: this.form.value.notes, 
      doctor_name: this.dentistName, 
      prc_no: this.form.value.prc_no, 
      ptr_no: this.form.value.ptr_no, 
      patient_id: this.patientId, 
      dentist_id: this.dentistId
    }
    this.forms.downloadPrescription(FORM).subscribe(res => {
      if(res) {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(res);
        a.download = moment(this.today).format("YYYYMMDD") + '-PRESCRIPTION.pdf';
        document.body.appendChild(a);
        a.click();
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Exporting of PDF failed. Please fill-in all the required fields.',
        button: 'Okay',
        event: this.close
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      const DATE_REQUIRED = this.form.value.date == '' ? 'The date field is required.' : '';
      const PRC_REQUIRED = this.form.value.prc_no == '' ? 'The prc number field is required.' : '';
      const DENTIST_REQUIRED = this.form.get('dentist_id').value == '' ? 'The dentist name field is required.' : '';
      const PATIENT_REQUIRED = this.form.get('patient_id').value == '' ? 'The patient name field is required.' : '';
      const DENTIST_INVALID = typeof this.form.get('dentist_id').value == 'string' ? 'The dentist name is invalid.' : '';
      const PATIENT_INVALID = typeof this.form.get('patient_id').value == 'string' ? 'The patient name is invalid.' : '';
      this.errorMessage = {
        date: DATE_REQUIRED,
        prc_no: PRC_REQUIRED,
        dentist_id: DENTIST_REQUIRED == '' ? DENTIST_INVALID : DENTIST_REQUIRED,
        patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
      };
      this.triggerErrors({
        date: DATE_REQUIRED,
        prc_no: PRC_REQUIRED,
        dentist_id: DENTIST_REQUIRED == '' ? DENTIST_INVALID : DENTIST_REQUIRED,
        patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
      });
    }));
  }

  print() {
    const date = this.form.value.date ? moment(this.form.value.date).format('MM/DD/YYYY') : '';
    const data = JSON.stringify({prescription: this.prescriptions});
    const FORM = {
      user_clinic_id: this.clinic.id,
      clinic_address: this.form.value.clinic_address,
      telephone_no: this.form.value.telephone_no.toString(),
      mobile_no: this.form.value.mobile_no.toString(),
      clinic_hours: this.form.value.clinic_hours,
      website: this.form.value.website,
      email: this.form.value.email, 
      patient_name: this.patientName, 
      patient_address: this.form.value.patient_address, 
      patient_age: this.form.value.patient_age, 
      patient_sex: this.form.value.patient_sex, 
      date: date, 
      prescription: data, 
      notes: this.form.value.notes, 
      doctor_name: this.dentistName, 
      prc_no: this.form.value.prc_no, 
      ptr_no: this.form.value.ptr_no, 
      patient_id: this.patientId, 
      dentist_id: this.dentistId
    }
    this.forms.printPrescription(FORM).subscribe(res => {
      if(res) {
        const url = URL.createObjectURL(res);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = url;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Print Failed. Please fill-in all the required fields.',
        button: 'Okay',
        event: this.close
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      const DATE_REQUIRED = this.form.value.date == '' ? 'The date field is required.' : '';
      const PRC_REQUIRED = this.form.value.prc_no == '' ? 'The prc number field is required.' : '';
      const DENTIST_REQUIRED = this.form.get('dentist_id').value == '' ? 'The dentist name field is required.' : '';
      const PATIENT_REQUIRED = this.form.get('patient_id').value == '' ? 'The patient name field is required.' : '';
      const DENTIST_INVALID = typeof this.form.get('dentist_id').value == 'string' ? 'The dentist name is invalid.' : '';
      const PATIENT_INVALID = typeof this.form.get('patient_id').value == 'string' ? 'The patient name is invalid.' : '';
      this.errorMessage = {
        date: DATE_REQUIRED,
        prc_no: PRC_REQUIRED,
        dentist_id: DENTIST_REQUIRED == '' ? DENTIST_INVALID : DENTIST_REQUIRED,
        patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
      };
      this.triggerErrors({
        date: DATE_REQUIRED,
        prc_no: PRC_REQUIRED,
        dentist_id: DENTIST_REQUIRED == '' ? DENTIST_INVALID : DENTIST_REQUIRED,
        patient_id: PATIENT_REQUIRED == '' ? PATIENT_INVALID : PATIENT_REQUIRED,
      });
    }));
  }

  goToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  close() {
    this.dialogRef.close();
  }

  triggerErrors(err){
    Object.keys(this.form.controls).forEach(key => {
      if (err[key]) {
        this.form.controls[key].setErrors({'invalid': true});
        this.form.controls[key].markAsTouched();
        this.form.controls[key].markAsDirty();
      }
    });
  }

  dataChecker(event) {
    this.dentistId = '';
    this.form.controls['prc_no'].setValue('');
    this.form.controls['ptr_no'].setValue('');
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }
}
