import { Component, OnInit } from '@angular/core';
import { PatientsDirectoryService } from '../../services/patients-directory/patients-directory.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { SidebarService } from 'src/app/modules/core/services/sidebar/sidebar.service';

@Component({
  selector: 'ark-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss']
})
export class PatientsComponent implements OnInit {

  tabName = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
  ];
  patientList = [];
  patientId = '';
  success = false;
  isNoResult: boolean = true;
  isSidebarMinimized: boolean = false;
  formGroup: FormGroup;
  index = 0;

  constructor(
    private formBuilder: FormBuilder,
    private patientsDirectory: PatientsDirectoryService,
    private auth: AuthService,
    public _router: Router,
    private sidebarService: SidebarService
  ) { 
    if(localStorage.getItem('isSidebarMinimized')) {
      this.isSidebarMinimized = JSON.parse(localStorage.getItem('isSidebarMinimized'));
    }
    this.sidebarService.sidebarState.subscribe((nextValue) => {
      this.isSidebarMinimized = nextValue
    });
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      first_name: ['', null],
      middle_name: ['', null],
      last_name: ['', null],
      email: ['', null],
      mobile_number: ['', null]
    });
    // this.getList({index: 0});
    if(localStorage.getItem('patientIndex')) {
      this.index = Number(localStorage.getItem('patientIndex'));
      this.getList({index: this.index});
      localStorage.removeItem('patientIndex');
    } else {
      this.getList({index: this.index});
    }
  }

  getList(e) {
    let param = [];

    if(this.formGroup.controls['first_name'].value !== null){
      param.push('first_name=' + this.formGroup.controls['first_name'].value);
    }

    if(this.formGroup.controls['middle_name'].value !== null){
      param.push('middle_name=' + this.formGroup.controls['middle_name'].value);
    }

    if(this.formGroup.controls['last_name'].value !== null){
      param.push('last_name=' + this.formGroup.controls['last_name'].value);
    }

    if(this.formGroup.controls['email'].value !== null){
      param.push('email=' + this.formGroup.controls['email'].value);
    }
    
    if(this.formGroup.controls['mobile_number'].value !== null){
      param.push('mobile_number=' + this.formGroup.controls['mobile_number'].value);
    }
    
    this.isNoResult = false;
    let id = this.auth.getAuthUser().user_clinic.id;
    this.patientsDirectory.getPatientList(id, this.tabName[e.index], id).subscribe(res => {
      this.patientList = res.data;
      this.patientId = res['data'].id;
      if(res.data == '') {
        this.isNoResult = true;
      } else {
        this.isNoResult = false;
      }
    });
  }

  openPatientDetails(res) {
    this.patientsDirectory.removeData();
    this._router.navigate(['/patient-details', res.id]);
    const name = {
      first_name: res.first_name,
      last_name: res.last_name,
      middle_name: res.middle_name ? res.middle_name : '',
      email: res.email,
      mobile_number: res.mobile_number,
      birthdate: moment(res.patient_private_information.birthdate).format("YYYY-MM-DD"),
      allergy: res.allergies
    };
    localStorage.setItem('patientName', JSON.stringify(name));
  }
}
