import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { FormBuilder } from '@angular/forms';
import { InventoryService } from 'src/app/modules/front-desk/service/inventory/inventory.service';
import { openDialog } from 'src/app/modules/shared/components/functional/dialogFunction';
import { AddCategoryComponent } from './add-category/add-category.component';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { itemInventory } from 'src/app/common/api';
import { ConfirmDeletionComponent } from 'src/app/modules/shared/components/confirm-deletion/confirm-deletion.component';

@Component({
  selector: 'ark-manage-categories',
  templateUrl: './manage-categories.component.html',
  styleUrls: ['./manage-categories.component.scss']
})
export class ManageCategoriesComponent implements OnInit {
  cat_array = [];
  clinic = null;
  dialogConfig = new MatDialogConfig();
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private auth: AuthService,
  public dialogRef: MatDialogRef<ManageCategoriesComponent>, private fb: FormBuilder,
  private api: InventoryService, private dialog: MatDialog) { }

  ngOnInit() {
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.reloadList();
  }

  closeDialog(e) {
    this.dialogRef.close(e);
  }

  addCategory() {
    openDialog(AddCategoryComponent, '', this.dialog, true, true).afterClosed().subscribe( result => {
      if (result) {
        if (this.cat_array.filter( item => item.name.toLowerCase() == result.toLowerCase()).length > 0) {
          const dialogConfig = {
            title: 'Oops!',
            message: 'Adding category failed. Category already exist.',
            button: 'Okay',
          };
          openDialog(AlertMessageComponent, dialogConfig, this.dialog);
        } else {
          const dialogConfig = {
            title: 'Success!',
            message: 'Category has been successfully saved.',
            button: 'Okay',
          };
          openDialog(AlertMessageComponent, dialogConfig, this.dialog);
          this.cat_array.push({
            name: result,
          });
        }
      }
    });
  }

  removeItem(index, id) {
    this.dialogConfig.data = {
      subject: 'delete this category'
    };
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialog.open(ConfirmDeletionComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
      if(trigger) {
        this.cat_array[index] = {};
        this.cat_array[index].id = id;
        this.cat_array[index].name = '';

        const dialogConfig = {
          title: 'Success!',
          message: 'Category has been successfully deleted.',
          button: 'Okay',
        };
        openDialog(AlertMessageComponent, dialogConfig, this.dialog);
      }
    });
  }

  editItem(index, id) {
    openDialog(AddCategoryComponent, this.cat_array[index], this.dialog, true, true).afterClosed().subscribe( result => {
      if (result) {
        if (this.cat_array.filter( item => item.name.toLowerCase() == result.toLowerCase()).length > 0) {
          if (this.cat_array[index].name != result) {
            const dialogConfig = {
              title: 'Oops!',
              message: 'Updating category failed. Category already exist.',
              button: 'Okay',
            };
            openDialog(AlertMessageComponent, dialogConfig, this.dialog);
          } else {
            this.cat_array[index].name = result;
            const dialogConfig = {
              title: 'Success!',
              message: 'Category has been successfully updated.',
              button: 'Okay',
            };
            openDialog(AlertMessageComponent, dialogConfig, this.dialog);
          }
        } else {
          const dialogConfig = {
            title: 'Success!',
            message: 'Category has been successfully updated.',
            button: 'Okay',
          };
          openDialog(AlertMessageComponent, dialogConfig, this.dialog);
          this.cat_array[index].name = result;
        }
      }
    });
  }

  reloadList() {
    this.api.getAPI('category-directory?user_clinic_id=', this.clinic).subscribe(response => {
      if (response.status_code == 200) {
        this.cat_array = response.data.data;
      }
    });
  }

}
