import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { default as _rollupMoment, Moment } from 'moment';
import * as _moment from 'moment';
import { FinanceService } from '../../services/finance/finance.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { throwError } from 'rxjs';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
  },
};

@Component({
  selector: 'ark-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class FinanceComponent implements OnInit, OnDestroy {

  filters = [
    { name: 'Income Statement Summary', value: 'summary' },
    { name: 'Income vs Expense Graph', value: 'graph' }
  ];
  views = [
    { id: 1, name: 'Income vs Expense', value: 'income-expense' },
    { id: 2, name: 'Income', value: 'income' },
    { id: 3, name: 'Expense', value: 'expense' }
  ];
  view = null;
  url = null;
  selected: string = 'summary';
  summaryView: boolean = true;
  graphView: boolean = false;
  today = new Date(); 
  formGroup: FormGroup;
  clinic = null;
  yearId = null;
  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private finance: FinanceService,
    public dialog: MatDialog
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.formGroup = this.formBuilder.group({
      year: new FormControl(moment()),
      view: [''],
    });
    this.yearId = +moment().format("YYYY");
    this.formGroup.get('view').setValue(1);
    this.view = 1;
  }

  ngOnInit() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  ngOnDestroy() {
    localStorage.removeItem('chart');
  }

  onClickButton(value) {
    this.selected = value;
    if(value == 'summary') {
      this.summaryView = true;
      this.graphView = false;
    } else if (value == 'graph') {
      this.summaryView = false;
      this.graphView = true;
    }
  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const val = this.formGroup.value.year;
    val.year(normalizedYear.year());
    this.formGroup.get('year').setValue(val);
    datepicker.close();
    
    this.yearId = +moment(this.formGroup.value.year).format("YYYY");
  }

  getView(val) {
    this.view = val.value;
  }

  generatePDF() {
    this.finance.downloadAnnualSummary(this.clinic, this.yearId).subscribe(res => {
      if(res) {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(res);
        a.download = moment(this.today).format("YYYYMMDD") + '-ANNUAL INCOME STATEMENT (' + this.yearId + ').pdf';
        document.body.appendChild(a);
        a.click();
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Exporting of PDF failed.',
        button: 'Okay',
        event: this.closeOnClick
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }));
  }

  sendEmail() {
    this.finance.emailAnnualSummary(this.clinic, this.yearId).subscribe(res => {
      if(res) {
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Annual summary of income statement has \nbeen emailed successfully.',
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Sending email failed.',
        button: 'Okay',
        event: this.closeOnClick
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }));
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  downloadGraph() {
    /** Get Chart.js data url */
    if(localStorage.getItem('chart')) {
      this.url = localStorage.getItem('chart');
    }

    /** Get view name  */
    let name = '';
    if(this.view == 1) {
      name = 'Income vs Expense';
    } else if(this.view == 2) {
      name = 'Income';
    } else {
      name = 'Expense';
    }

    /** Send data to be downloaded */
    const data = {
      image: this.url,
      year: this.yearId, 
      filter: name
    }

    this.finance.downloadGraph(this.clinic, data).subscribe(res => {
      if(res) {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(res);
        a.download = moment(this.today).format("YYYYMMDD") + '-FINANCE GRAPH (' + this.yearId + ').pdf';
        document.body.appendChild(a);
        a.click();
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Exporting of PDF failed.',
        button: 'Okay',
        event: this.closeOnClick
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }));
  }

  sendGraph() {
    /** Get Chart.js data url */
    if(localStorage.getItem('chart')) {
      this.url = localStorage.getItem('chart');
    }

    /** Get view name  */
    let name = '';
    if(this.view == 1) {
      name = 'Income vs Expense';
    } else if(this.view == 2) {
      name = 'Income';
    } else {
      name = 'Expense';
    }

    /** Send data to be downloaded */
    const data = {
      image: this.url,
      year: this.yearId, 
      filter: name
    }
    
    this.finance.emailGraph(this.clinic, data).subscribe(res => {
      if(res) {
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Graph report has been emailed successfully.',
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Sending email failed.',
        button: 'Okay',
        event: this.closeOnClick
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }));
  }
}
