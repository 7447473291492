import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { UserService } from '../../services/user/user.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component'; 
import { keypressValidateNumber } from 'src/app/common/helper';
import { AddUserClinicComponent } from './add-user-clinic/add-user-clinic.component';
import { UnsubscribeClinicComponent } from './unsubscribe-clinic/unsubscribe-clinic.component';
import { SubscribeService } from 'src/app/modules/subscription/services/subscribe/subscribe.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ark-clinic',
  templateUrl: './clinic.component.html',
  styleUrls: ['./clinic.component.scss']
})
export class ClinicComponent implements OnInit {

  isOwner: boolean = (localStorage.getItem('isOwner') == 'true'? true : false );
  errorMessage = null;
  success = false;
  formGroup: FormGroup;
  dialogConfig = new MatDialogConfig;
  dialogRef: any;
  clinic = null;
  user_id = null;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private auth: AuthService,
    private userService: UserService,
    private subscription: SubscribeService,
    private _router: Router,
  ) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      address: ['', [Validators.required]],
      clinic_hours: [''],
      website: [''],
      contact_number: ['', [Validators.required]],
      mobile_number: ['']
    });
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.user_id = this.auth.getAuthUser().id;
    this.userService.getClinic(this.clinic, this.clinic).subscribe(res => {
      let clinic = res['data'];
      this.formGroup.get('name').setValue(clinic.name);
      this.formGroup.get('email').setValue(clinic.email);
      this.formGroup.get('address').setValue(clinic.address);
      this.formGroup.get('clinic_hours').setValue(clinic.clinic_hours);
      this.formGroup.get('website').setValue(clinic.website);
      this.formGroup.get('contact_number').setValue(clinic.contact_number);
      this.formGroup.get('mobile_number').setValue(clinic.mobile_number);
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  updateClinic() {
    this.userService.updateClinic(this.clinic, this.clinic, this.formGroup.value).subscribe(res => {
      if (res) {
        this.success = true;
        this.auth.setAuthUser(res['data']);
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Your clinic has been successfully updated.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      } 
    },(err => {
      this.errorMessage = err.error.errors;
      this.triggerErrors(err.error.errors);
    }));
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }

  addClinic() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';

    this.dialogRef = this.dialog.open(AddUserClinicComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(res => {
      if(res) {
        this.ngOnInit();
      }
    });
  }

  unsubscribe() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    let dialogRef = this.dialog.open(UnsubscribeClinicComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
      if(trigger) {
        const data = {
          user_clinic_id: this.clinic,
          user_id: this.user_id
        }
        this.subscription.unsubscribeClinic(data).subscribe(res => {
          if(res) {
            this.success = true;
            this.dialogConfig.data = {
              title: 'Thank you!',
              message: 'You have been successfully unsubscribed this clinic. \nAccount will be logged out at this moment. \n\nAn email has been sent to your inbox. \nIf you don\'t see the email, please check your spam folders.',
              button: 'Okay',
              event: this.closeOnClick
            };
            let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig).afterClosed().subscribe(res => this.logout());
          }
        });
      }
    });
  }

  logout() {
    let data = {
      token: this.auth.getAuthUser().token
    }
    this.auth.logout(data).subscribe(res => {
      localStorage.removeItem('authUser');
      localStorage.removeItem('token');
      this._router.navigate(["/login"]);
    });
  }
}
