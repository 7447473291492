import { Component, OnInit, Input } from "@angular/core";
import { Output, EventEmitter } from "@angular/core";

@Component({
  selector: "ark-perio-data",
  templateUrl: "./perio-data.component.html",
  styleUrls: ["./perio-data.component.scss"],
})
export class PerioDataComponent implements OnInit {
  @Input() perioData = [];
  @Output() data: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onCheckMissing(e, obj) {
    obj.is_checked = e.checked ? 1 : 0;
    this.data.emit(this.perioData);
  }

  onClickImplant(e, obj) {
    obj.is_implant = e ? true : false;
    this.data.emit(this.perioData);
  }

  onChangeValue() {
    this.data.emit(this.perioData);
  }
}
