import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscribeService } from 'src/app/modules/subscription/services/subscribe/subscribe.service';

@Component({
  selector: 'ark-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss']
})
export class VerifyAccountComponent implements OnInit {

  message = '';
  button = '';
  success = false;
  isExpired = false;

  constructor(
    private subscription: SubscribeService,
    private activedRoute: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit() {
    this.verifyToken();
  }
  
  verifyToken(){
    this.subscription.verifyToken(this.activedRoute.params['value'].id).subscribe(res => {
      if(res) {
        this.isExpired = false;
        this.message = 'You have successfully verified your account. \nYou can now start using the services of Dental Pro app. \n\nPlease login using your registered email and password. \n\nThank you!';
        this.button = 'Okay';
      }
    },(err => {
        this.isExpired = true;
        this.message = 'Account verification link has already been used or expired. \n\nGo back to login and click on Register Now to \nregister your account again. \n\nThank you!';
        this.button = 'Back to Login'
    }));
  }

  backToLogin() {
    this._router.navigate(["/login"]);
  }
}
