import { Component, OnInit } from '@angular/core';
import { DirectoryService } from '../../../service/directory/directory.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AddLaboratoryDirectoryComponent } from './add-laboratory-directory/add-laboratory-directory.component';
import { EditLaboratoryDirectoryComponent } from './edit-laboratory-directory/edit-laboratory-directory.component';
import { ConfirmDeletionComponent } from 'src/app/modules/shared/components/confirm-deletion/confirm-deletion.component';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

@Component({
  selector: 'ark-laboratory',
  templateUrl: './laboratory.component.html',
  styleUrls: ['./laboratory.component.scss']
})
export class LaboratoryComponent implements OnInit {

  isNoResult: boolean = true;
  clinic = null;
  laboratoryDirectory = [];
  dialogConfig = new MatDialogConfig();
  dialogRef: any;

  constructor(
    private directory: DirectoryService,
    private auth: AuthService,
    public dialog: MatDialog
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
  }

  ngOnInit() {
    this.getDirectory();
  }

  getDirectory() {
    this.directory.getLaboratoryDirectory(this.clinic).subscribe(res => { 
      if(res) {
        this.laboratoryDirectory = res.data;
        if(this.laboratoryDirectory.length > 0) {
          this.isNoResult = false;
        } else {
          this.isNoResult = true;
        }
      }
    });
  }

  addDirectory() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogRef = this.dialog.open(AddLaboratoryDirectoryComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(res => {
      if(res) {
        this.getDirectory();
      }
    });
  }

  editDirectory(data) {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogConfig.data = {
      data: data
    }
    this.dialogRef = this.dialog.open(EditLaboratoryDirectoryComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(res => {
      if(res) {
        this.getDirectory();
      }
    });
  }

  deleteDirectory(id) {
    this.dialogConfig.data = {
      subject: 'delete this directory'
    };
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    let dialogRef = this.dialog.open(ConfirmDeletionComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
      if(trigger) {
        this.directory.deleteLaboratoryDirectory(id, this.clinic).subscribe(res => {
          if(res) {
            this.getDirectory();
            this.dialogConfig.data = {
              title: 'Success!',
              message: 'Laboratory directory has been successfully removed.',
              button: 'Okay',
              event: this.closeOnClick
            };
            let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
          }
        },(err => {
          this.dialogConfig.data = {
            title: 'Oops!',
            message: 'Deleting this directory is restricted.',
            button: 'Okay',
            event: this.closeOnClick
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }));
      }
    });
  }

  closeOnClick() {
    this.dialogRef.close();
  }
}
