import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatAutocompleteTrigger } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { ConfirmDeletionComponent } from 'src/app/modules/shared/components/confirm-deletion/confirm-deletion.component';
import * as moment from 'moment';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { keypressValidateNumber } from 'src/app/common/helper';

export interface Patient {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
}

export interface Dentist {
  id: number;
  firstName: string;
  lastName: string;
}

@Component({
  selector: 'ark-view-appointment',
  templateUrl: './view-appointment.component.html',
  styleUrls: ['./view-appointment.component.scss']
})
export class ViewAppointmentComponent implements OnInit {

  timeRange = [
    { name: '06:00 AM', value: '06:00' },
    { name: '06:30 AM', value: '06:30' },
    { name: '07:00 AM', value: '07:00' },
    { name: '07:30 AM', value: '07:30' },
    { name: '08:00 AM', value: '08:00' },
    { name: '08:30 AM', value: '08:30' },
    { name: '09:00 AM', value: '09:00' },
    { name: '09:30 AM', value: '09:30' },
    { name: '10:00 AM', value: '10:00' },
    { name: '10:30 AM', value: '10:30' },
    { name: '11:00 AM', value: '11:00' },
    { name: '11:30 AM', value: '11:30' },
    { name: '12:00 PM', value: '12:00' },
    { name: '12:30 PM', value: '12:30' },
    { name: '01:00 PM', value: '13:00' },
    { name: '01:30 PM', value: '13:30' },
    { name: '02:00 PM', value: '14:00' },
    { name: '02:30 PM', value: '14:30' },
    { name: '03:00 PM', value: '15:00' },
    { name: '03:30 PM', value: '15:30' },
    { name: '04:00 PM', value: '16:00' },
    { name: '04:30 PM', value: '16:30' },
    { name: '05:00 PM', value: '17:00' },
    { name: '05:30 PM', value: '17:30' },
    { name: '06:00 PM', value: '18:00' },
    { name: '06:30 PM', value: '18:30' },
    { name: '07:00 PM', value: '19:00' },
    { name: '07:30 PM', value: '19:30' },
    { name: '08:00 PM', value: '20:00' },
    { name: '08:30 PM', value: '20:30' },
    { name: '09:00 PM', value: '21:00' },
    { name: '09:30 PM', value: '21:30' },
    { name: '10:00 PM', value: '22:00' },
    { name: '10:30 PM', value: '22:30' },
    { name: '11:00 PM', value: '23:00' },
    { name: '11:30 PM', value: '23:30' },
    { name: '12:00 AM', value: '24:00' },
  ];
  doctor : any;
  appointment : any;
  patientId : any;
  dentistId : any;
  errorMessage = null;
  user_clinic_id = '';
  id = '';
  header = 'Appointment';
  dialogConfig = new MatDialogConfig();
  form : FormGroup;
  isEditable : boolean = false;
  status : boolean = false;
  success : boolean = false;
  start_time = '';
  end_time = '';
  date = '';
  previousDate = new Date();
  datePipe = new DatePipe('en-US');
  today = new Date(); 
  
  patientControl = new FormControl();
  dentistControl = new FormControl();
  patients: Patient[] = [];
  dentists: Dentist[] = [];
  filteredPatients: Observable<Patient[]>;
  filteredDentists: Observable<Dentist[]>;
  @ViewChild('patient') patient: MatAutocompleteTrigger;
  @ViewChild('dentist') dentist: MatAutocompleteTrigger;

  constructor(
    public dialogRef: MatDialogRef<ViewAppointmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private dashboard: DashboardService,
    private patientsDirectory: PatientsDirectoryService
  ) {
    this.user_clinic_id = this.auth.getAuthUser().user_clinic.id;
    this.doctor = this.data.doctor;
    this.appointment = this.data.patient;
    this.id = this.data.appointment_id;
    this.date = moment(this.appointment.appointment_date).format("YYYY-MM-DD");
    this.start_time = moment(this.appointment.appointment_start).format("HH:mm");
    this.end_time = moment(this.appointment.appointment_end).format("HH:mm");

    this.dashboard.getPatientList(this.user_clinic_id, this.user_clinic_id).subscribe(res => {
      res.data.forEach(element => {
        let obj: Patient = {
          id: element.id, 
          firstName: element.first_name, 
          middleName: element.middle_name, 
          lastName: element.last_name
        };
        this.patients.push(obj);
      });
    });
    this.dashboard.getDentistList(this.user_clinic_id).subscribe(res => {
      res.forEach(element => {
        let obj: Dentist = {
          id: element.id, 
          firstName: element.first_name,
          lastName: element.last_name
        };
        this.dentists.push(obj);
      });
    });
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      patient_id: new FormControl('', [Validators.required]),
      dentist_id: new FormControl('', [Validators.required]),
      procedure: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      date: ['', [Validators.required]],
      start_time: ['', [Validators.required]],
      end_time: ['', [Validators.required]]
    });

    this.filteredPatients = this.form.get('patient_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterPatient(name) : this.patients.slice()),
      );
    this.filteredDentists = this.form.get('dentist_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterDentist(name) : this.dentists.slice())
      );
    this.form.get('patient_id').setValue(
      {
        id: this.appointment.patient.id, 
        firstName: this.appointment.patient.first_name, 
        middleName: this.appointment.patient.middle_name,
        lastName: this.appointment.patient.last_name
      }
    );
    this.form.get('dentist_id').setValue(
      {
        id: this.doctor.id, 
        firstName: this.doctor.first_name, 
        lastName: this.doctor.last_name
      }
    );
    this.form.get('procedure').setValue(this.appointment.procedure);
    this.form.get('mobile').setValue(this.appointment.mobile);
    this.form.get('date').setValue(this.date);
    this.form.get('start_time').setValue(this.start_time);
    this.form.get('end_time').setValue(this.end_time);
    this.disableInput();
  }

  private _filterPatient(name: string): Patient[] {
    const filterValue = name.toLowerCase();
    return this.patients.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1 || option.middleName.toLowerCase().indexOf(filterValue) > -1);
  }

  private _filterDentist(name: string): Dentist[] {
    const filterValue = name.toLowerCase();
    return this.dentists.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1);
  }

  displayDentist(dentist?: any) : string | undefined {
    return dentist ? 'Dr. ' + dentist.firstName + ' ' + dentist.lastName : undefined;
  }

  displayPatient(patient?: any) : string | undefined {
    return patient ? patient.lastName + ', ' + patient.firstName + ' ' + (patient.middleName ? patient.middleName : '') : undefined;
  }

  onFocusPatient() {
    this.patient._onChange("");
    this.patient.openPanel();
  }

  onFocusDentist() {
    this.dentist._onChange(""); 
    this.dentist.openPanel();
  }

  getPatient(event) {
    this.patientId = event.option.value.id;
    this.patientsDirectory.getPatientInfo(this.user_clinic_id, this.patientId, this.user_clinic_id).subscribe(res => {
      this.form.get('mobile').setValue(res['data'][0].mobile_number);
    });
    this.form.get('patient_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
  }

  getDentist(event) {
    this.dentistId = event.option.value.id;
    this.form.get('dentist_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
  }

  updateAppointment() {
    this.status = !this.status;  
    if(this.isEditable) {
      this.disableInput();
      this.isEditable = false;
      this.header = 'Appointment';
    } else {
      this.enableInput();
      this.form.controls['patient_id'].disable();
      this.form.controls['procedure'].disable();
      this.form.controls['mobile'].disable();
      this.isEditable = true;
      this.header = 'Update Appointment';
    }
  }

  update() {
    let appointment_date = this.datePipe.transform(this.form.value.date, 'yyyy-MM-dd');
    let data = {
      user_clinic_id: this.user_clinic_id,
      patient_id: this.form.get('patient_id').value.id ? this.form.get('patient_id').value.id : 0,
      dentist_id: this.form.get('dentist_id').value.id ? this.form.get('dentist_id').value.id : this.form.get('dentist_id').value,
      procedure: this.form.value.procedure ? this.form.value.procedure : this.appointment.procedure,
      mobile: this.form.value.mobile ? this.form.value.mobile.toString() : this.appointment.mobile.toString(),
      date: appointment_date,
      start_time: this.form.value.start_time,
      end_time: this.form.value.end_time,
    }
    this.dashboard.updateAppointment(this.appointment.id, data).subscribe(res => {
      if(res) {
        this.success = true;
        this.dialogRef.close(res);
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Appointment has been successfully updated.',
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Sorry!',
        message: err.error.message,
        button: 'Okay',
        event: this.closeOnClick
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      this.errorMessage = err.error.errors;
      this.triggerErrors(err.error.errors);
    }));
  }

  deleteAppointment() {
    this.dialogConfig.data = {
      subject: 'delete this appointment'
    };
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    let dialogRef = this.dialog.open(ConfirmDeletionComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
      if(trigger) {
        this.dashboard.deleteAppointment(this.id, this.user_clinic_id).subscribe(res => {
          if(res) {
            this.success = true;
            this.dialogRef.close(res);
            this.dialogConfig.data = {
              title: 'Success!',
              message: 'Appointment has been successfully deleted',
              button: 'Okay',
              event: this.closeOnClick
            };
            let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
          }
        });
      }
    });
  }

  triggerErrors(err){
    Object.keys(this.form.controls).forEach(key => {
      if (err[key]) {
        this.form.controls[key].setErrors({'invalid': true});
        this.form.controls[key].markAsTouched();
        this.form.controls[key].markAsDirty();
      }
    });
  }

  disableInput() {
    Object.keys(this.form.controls).forEach(key => {
      this.form.controls[key].disable();
    });
  }

  enableInput() {
    Object.keys(this.form.controls).forEach(key => {
      this.form.controls[key].enable();
    });
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }
}
