import { Component, OnInit, Input } from "@angular/core";
import { Output, EventEmitter } from "@angular/core";

@Component({
  selector: "ark-perio-details",
  templateUrl: "./perio-details.component.html",
  styleUrls: ["./perio-details.component.scss"],
})
export class PerioDetailsComponent implements OnInit {
  @Input() perioData = [];
  @Output() details: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onCheckMissing(e, obj) {
    obj.is_checked = e.checked ? 1 : 0;
    this.details.emit(this.perioData);
  }

  onClickImplant(e, obj) {
    obj.is_implant = e ? true : false;
    this.details.emit(this.perioData);
  }

  onChangeValue() {
    this.details.emit(this.perioData);
  }
}
