import { Injectable } from '@angular/core';
import { catchError, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { openDialog } from 'src/app/modules/shared/components/functional/dialogFunction';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(private http:HttpClient, private dialog: MatDialog) { }

  getAPI(service, data, withError= true, showErrors = false) {
    return this.http.get(environment.apiBase + '/api/' + service + data).pipe(
      catchError(error => {
        if (showErrors) {
          this.ErrorHandler(error);
        }
        if (withError) {
          return of(error);
        } else {
          return of(null);
        }
      }),
      finalize(() => {
        // mDialog.close();
      })
  );
  }

  accessAPI(method, service, data, withError: boolean = true, observer = false, showErrors = true) {
    // const mDialog = openLoader(this.dialog);
    let option = {};
    if (observer) {
      option = {
        withCredentials: true,
        responseType: 'json',
        observe: 'response'
      };
    } else {
      option = {
        withCredentials: false,
        responseType: 'json'
      };
    }

    return this.http[method](environment.apiBase + '/api/' + service, data, option).pipe(
      catchError(error => {
        if (showErrors) {
          this.ErrorHandler(error);
        }
        if (withError) {
          return of(error);
        } else {
          return of(null);
        }
      }),
      finalize(() => {
        // mDialog.close();
      })
    );
}

ErrorHandler(error: HttpErrorResponse) {
  console.log(error);
  if (error.status == 422) {
    const dialogConfig = {
      title: 'System Failure!',
      message: error.error.message,
      button: 'Okay',
    };
    openDialog(AlertMessageComponent, dialogConfig, this.dialog);
  } else if (error.status == 400) {
    const dialogConfig = {
      title: 'Failed!',
      message: error.error.message,
      button: 'Okay',
    };
    openDialog(AlertMessageComponent, dialogConfig, this.dialog);
  }
}
}
