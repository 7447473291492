import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/modules/shared/services/http/http.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { setPrescription, savePrescription, dentalCertificate, postOperationInstruction, informedConsent, tmjConsent, orthodonticConsent, waiverConsent, releaseConsent, bracesRemovalConsent, workAuthCrown, workAuthToothDetails, workAuthDentures, workAuthOrthodontics, covidForm } from 'src/app/common/api';

@Injectable()
export class DentalFormsService extends HttpService {

  constructor(http: HttpClient, private _router: Router) {
    super('',http);
  }

  addPrescription(data) {
    return this.http.put(setPrescription, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  getPrescription(user_clinic_id, id) {
    return this.get(setPrescription + '/?user_clinic_id=' + user_clinic_id + '&patient_id=' + id).map(res => {
      return res['data'];
    });
  }

  downloadPrescription(data) {
    return this.http.post(savePrescription, data, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  printPrescription(data) {
    return this.http.post(savePrescription, data, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  downloadCertificate(data) {
    return this.http.post(dentalCertificate, data, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  printCertificate(data) {
    return this.http.post(dentalCertificate, data, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  emailPOISurgery(data) {
    return this.http.post(postOperationInstruction + '-sugery', data).map(res => {
      return res['data'];
    });
  }

  printPOISurgery(data) {
    return this.http.post(postOperationInstruction + '-sugery', data, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  emailPOITeethWhiteningCustom(data) {
    return this.http.post(postOperationInstruction + '-teeth-whitening', data).map(res => {
      return res['data'];
    });
  }

  printPOITeethWhiteningCustom(data) {
    return this.http.post(postOperationInstruction + '-teeth-whitening', data, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  emailPOITeethWhiteningPreFormed(data) {
    return this.http.post(postOperationInstruction + '-teeth-whitening', data).map(res => {
      return res['data'];
    });
  }

  printPOITeethWhiteningPreFormed(data) {
    return this.http.post(postOperationInstruction + '-teeth-whitening', data, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  emailPOIDentureCare(data) {
    return this.http.post(postOperationInstruction + '-denture-care', data).map(res => {
      return res['data'];
    });
  }

  printPOIDentureCare(data) {
    return this.http.post(postOperationInstruction + '-denture-care', data, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  printAndDownloadInformedConsent(data) {
    return this.http.post(informedConsent, data, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  printAndDownloadOrthodonticConsent(data) {
    return this.http.post(orthodonticConsent, data, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  printAndDownloadTMJConsent(data) {
    return this.http.post(tmjConsent, data, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  printAndDownloadWaiverConsent(data) {
    return this.http.post(waiverConsent, data, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  printAndDownloadReleaseConsent(data) {
    return this.http.post(releaseConsent, data, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  printAndDownloadBracesRemovalConsent(data) {
    return this.http.post(bracesRemovalConsent, data, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  getCrownDetails() {
    return this.get(workAuthToothDetails).map(res => {
      return res['data'];
    });
  }

  printAndDownloadCrownWorkAuth(data) {
    return this.http.post(workAuthCrown, data, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  printAndDownloadDenturesWorkAuth(data) {
    return this.http.post(workAuthDentures, data, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  printAndDownloadOrthodonticsWorkAuth(data) {
    return this.http.post(workAuthOrthodontics, data, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  emailCOVIDForm(data) {
    return this.http.post(covidForm, data).map(res => {
      return res['data'];
    });
  }

  printCOVIDForm(data) {
    return this.http.post(covidForm, data, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }
}