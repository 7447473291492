import { Component, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { DentalFormsService } from '../../../services/dental-forms/dental-forms.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { EmailFormComponent } from './email-form/email-form.component';

@Component({
  selector: 'ark-covid-form',
  templateUrl: './covid-form.component.html',
  styleUrls: ['./covid-form.component.scss']
})
export class CovidFormComponent implements OnInit {

  questions = [
    {
      id: 1,
      question: 'Do you have a fever or above normal temperature?',
      option: '(Yes / No)'
    },
    {
      id: 2,
      question: 'Have you experienced shortness of breathe or had trouble breathing?',
      option: '(Yes / No)'
    },
    {
      id: 3,
      question: 'Do you have a dry cough?',
      option: '(Yes / No)'
    },
    {
      id: 4,
      question: 'Do you have a runny nose?',
      option: '(Yes / No)'
    },
    {
      id: 5,
      question: 'Have you recently lost or had a reduction in your sense of smell?',
      option: '(Yes / No)'
    },
    {
      id: 6,
      question: 'Do you have a sore throat?',
      option: '(Yes / No)'
    },
    {
      id: 7,
      question: 'Have you been in contact with someone who has tested positive for COVID-19?',
      option: '(Yes / No)'
    },
    {
      id: 8,
      question: 'Have you tested for COVID-19?',
      option: '(Yes / No)'
    },
    {
      id: 9,
      question: 'Have you been tested for COVID-19 and are awaiting results?',
      option: '(Yes / No)'
    },
    {
      id: 10,
      question: 'Have you travelled outside the Philippines by air or cruise ship in the past 14 days?',
      option: '(Yes / No)'
    },
    {
      id: 11,
      question: 'Have you travelled within the Philippines by air, bus or train in the past 14 days?',
      option: '(Yes / No)'
    },
    {
      id: 12,
      question: 'Do you have a weakened immune system?',
      option: '(Yes / No)'
    },
    {
      id: 13,
      question: 'Are you currently undergoing treatment for cancer, such as chemotherapy or radiation therapy?',
      option: '(Yes / No)'
    },
    {
      id: 14,
      question: 'Do you take steriods for any condition? Examples of common steriods are Cortisone, Prednisone, Methylprednisone. Contact you physician or our office if not sure. Also, answer \'Yes\' if unsure.',
      option: '(Yes / No)'
    },
    {
      id: 15,
      question: 'Do you have an autoimmune disease such as Lupus, rheumatoid arthritis, multiple sclerosis or psoriasis?',
      option: '(Yes / No)'
    },
    {
      id: 16,
      question: 'Do you have diabetes?',
      option: '(Yes / No)'
    },
    {
      id: 17,
      question: 'If so, do you have to take insulin injections?',
      option: '(Yes / No)'
    },
    {
      id: 18,
      question: 'Do you have asthma or COPD [Chronic obstructive pulmonary disease]?',
      option: '(Yes / No)'
    },
  ]

  genders = [
    { id : '1', name: 'Male' },
    { id : '2', name: 'Female' },
    { id : '3', name: 'N/A' }
  ];

  occupations = [
    { id : '1', name: 'Student' },
    { id : '2', name: 'Healthcare Personnel' },
    { id : '3', name: 'Others' }
  ];

  sender = [
    { id : '1', name: 'Yes - I am the Patient' },
    { id : '2', name: 'No - I am the Companion' },
    { id : '3', name: 'Np - I am the Parent/Guardian' }
  ]

  concerns = [
    { id : '1', name: 'Pain in Teeth and/or associated areas' },
    { id : '2', name: 'Face Swelling' },
    { id : '3', name: 'Oral Prophylaxis' },
    { id : '4', name: 'Resotrations/Root Canal Treatment' },
    { id : '5', name: 'Denture Replacements/Repair' },
    { id : '6', name: 'Others' },
  ]

  informedConsent = [
    {
      consent: "1. I give my full consent to have dental treatment done to me or my child(ren) in this time of pandemic caused by COVID-19 disease."
    },
    {
      consent: "2. I am aware that the virus can be transmitted by contact through surfaces and that it can stay in the air for 5 to 72 hours. I am aware that it is impossible to identify who is probable, suspect or COVID 19 positive. Because of this, treatment options are limited to urgent and emergent care to protect me, other patients and the dental staff."
    },
    {
      consent: "3. I recognize that the clinic is adhering to the strictest infection control protocols for my protection and as such, I agree to cover the fees that this entails."
    },
    {
      consent: "4. I fully understand the risk that because of the nature of the virus, by simply leaving my home, travelling to the clinic, the clinical procedures, and even by simply staying in the dental office, there is a chance of contracting the virus. Should I contract the virus, I hereby agree that I shall not hold the dental office liable."
    },
    {
      consent: "5. I am also giving my consent that in accordance to the IATF rules, my identity shall be revealed for possible contact tracing for the interest and safety of the community."
    },
    {
      consent: "6. I understand that this is a Pre-Screening Procedure prior to my Dental Appointment or Dental Procedure. I understand that upon assessment by the Dentist, I may be given a confirmed appointment or a referral to other Specialist(s) or Healthcare Facility. I have answered everything to the best of my knowledge."
    }
  ]

  dialogConfig = new MatDialogConfig();
  dialogRef: any;
  header = 'COVID-19 Pre-screening Form'
  clinic = null;

  constructor(
    public dialog: MatDialog,
    private auth: AuthService,
    private dentalForms: DentalFormsService,
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic;
  }

  ngOnInit() {}

  download() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogRef = this.dialog.open(EmailFormComponent, this.dialogConfig);
  }

  print() {
    const FORM = {
      user_clinic_id: this.clinic.id,
      address: this.clinic.address ? this.clinic.address : '', 
      telephone_no: this.clinic.contact_number ? this.clinic.contact_number.toString() : '', 
      mobile_no: this.clinic.mobile_number ? this.clinic.mobile_number.toString() : '',
      clinic_hours: this.clinic.clinic_hours ? this.clinic.clinic_hours : '',
      website: this.clinic.website ? this.clinic.website : '',
      email: this.clinic.email ? this.clinic.email : '',
      patient_name: 'Patient',
      patient_email: 'sample@email.com',
      print: true
    };
    this.dentalForms.printCOVIDForm(FORM).subscribe(res => {
      if(res) {
        const url = URL.createObjectURL(res);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = url;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Oops!',
        message: 'Print Failed.',
        button: 'Okay',
        event: this.closeOnClick
      };
      this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }));
  }

  closeOnClick() {
    this.dialogRef.close();
  }
}
