import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ClinicSetupComponent } from 'src/app/modules/account-settings/components/clinic-setup/clinic-setup.component';
import { CreateAppointmentComponent } from '../create-appointment/create-appointment.component';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { SubscribeClinicComponent } from 'src/app/modules/account-settings/components/clinic/subscribe-clinic/subscribe-clinic.component';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { SubscribeService } from 'src/app/modules/subscription/services/subscribe/subscribe.service';

@Component({
  selector: 'ark-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  dialogConfig = new MatDialogConfig();
  clinic = '';
  dialogRef: any;
  success: boolean = false;
  isNoResult: boolean = false;
  appointments: any;
  todayView: boolean = false;
  weekView: boolean = false;
  monthView: boolean = false;
  triggerToday = false;
  selectedFilter = '';
  newAppointment: any;
  date: any;
  month: any;
  startDate: any;
  endDate: any;
  dentistId = null;
  colorFill = null;
  days: any;
  daysFormatted: any;
  user_clinic_id = null;
  colorCollection = [
    '#FEA47F',
    '#25CBF7',
    '#EAB543',
    '#54E6C1',
    '#CAD3C8',
    '#F97F51',
    '#1B9CFC',
    '#F8EFB9',
    '#58B09F',
    '#2C3A47',
    '#B33771',
    '#3A3B98',
    '#FD7272',
    '#9AECDB',
    '#D6A2E8',
    '#6D204F',
    '#182B61',
    '#FC427B',
    '#BDC581',
    '#000000'
  ];

  constructor(
    private dashboard: DashboardService,
    private auth: AuthService,
    private _router: Router,
    public dialog: MatDialog,
    private subscription: SubscribeService
  ) { }

  ngOnInit() {
    if(this.auth.getAuthUser().user_clinic == null || this.auth.getAuthUser().user_clinic.length === 0) {
      setTimeout(() => {
        this.dialogConfig.disableClose = true;
        this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
        this.dialogRef = this.dialog.open(ClinicSetupComponent, this.dialogConfig);
        this.dialogRef.afterClosed().subscribe(res => {
          if(res) {
            this.clinic = this.auth.getAuthUser().user_clinic.id;
            this.todayView = true;
            this.filter('today');
            this.dashboard.getDentistList(this.clinic).subscribe(res => {
              if (res) {
                localStorage.setItem('d-list', JSON.stringify(res));
                const colors = {}
                let i = 0;
                res.forEach( d => {
                  if (i % 20 === 0) {
                    i = 0;
                  }
                  colors[d.id] = '';
                  colors[d.id] = this.colorCollection[i];
                  i++;
                });
                localStorage.setItem('c-collections', JSON.stringify(colors));
                this.todayView = true;
                this.filter('today');
              }
            });
          }
        }, 100);
      });
    } else {
      this.clinic = this.auth.getAuthUser().user_clinic.id;
      this.todayView = true;
      this.filter('today');
      this.dashboard.getDentistList(this.clinic).subscribe(res => {
        if (res) {
          localStorage.setItem('d-list', JSON.stringify(res));
          const colors = {}
          let i = 0;
          res.forEach( d => {
            if (i % 20 === 0) {
              i = 0;
            }
            colors[d.id] = '';
            colors[d.id] = this.colorCollection[i];
            i++;
          });
          localStorage.setItem('c-collections', JSON.stringify(colors));
          this.todayView = true;
          this.filter('today');
        }
      },(err => {
        this.dialogConfig.data = {
          error_message: err.error.message
        }
        this.dialogConfig.disableClose = true;
        this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
        let dialogRef = this.dialog.open(SubscribeClinicComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
          if (trigger) {
            const data = {
              user_clinic_id: this.clinic,
              user_id: this.auth.getAuthUser().id
            }
            this.subscription.subscribeClinic(data).subscribe(res => {
              if (res) {
                this.success = true;
                this.redirectTo('/dashboard');
                this.dialogConfig.data = {
                  title: 'Thank you!',
                  message: 'You have been successfully subscribed this clinic. \n\nAn email has been sent to your inbox. \nIf you don\'t see the email, please check your spam folders.',
                  button: 'Okay',
                  event: this.close
                };
                let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
              }
            },(err => {
              this.dialogConfig.data = {
                title: 'Sorry!',
                message: err.error.message,
                button: 'Okay',
                event: this.close
              };
              let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
            }));
          } else {
            this.logout()
          }
        });
      }));
    }
  }

  logout() {
    let data = {
      token: this.auth.getAuthUser().token
    }
    this.auth.logout(data).subscribe(res => {
      localStorage.removeItem('authUser');
      localStorage.removeItem('token');
      this._router.navigate(["/login"]);
    });
  }

  close() {
    this.dialogRef.close();
  }

  redirectTo(uri: string) {
    this._router.navigateByUrl('/login', {skipLocationChange: true}).then(()=>
    this._router.navigate([uri]));
  }

  /**
   * Add new patient
   */
  addNewPatient() {
    this._router.navigate(['/patient-profile']);
  }

  /**
   * Create appointment
   */
  createAppointment() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogRef = this.dialog.open(CreateAppointmentComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(res => {
      if(res) {
        this.newAppointment = res['data'];
      }
    });
  }

  /**
   * Toggle calendar view
   */
  filter(filter) {
    if(filter == 'today') {
      this.selectedFilter = filter;
      this.todayView = true;
      this.weekView = false;
      this.monthView = false;
    } else if(filter == 'week') {
      this.selectedFilter = filter;
      this.todayView = false;
      this.weekView = true;
      this.monthView = false;
      this.triggerToday = false;
    } else if(filter == 'month') {
      this.selectedFilter = filter;
      this.todayView = false;
      this.weekView = false;
      this.monthView = true;
      this.triggerToday = false;
    }
  }

  /**
   * Get date for today view filter
   */
  getDate(data) {
    this.date = data;
  }

  /**
   * Get start date of the week
   */
  getWeekEndDate(date) {
    this.startDate = date;
  }

  /**
   * Get end date of the week
   */
  getWeekStartDate(date) {
    this.endDate = date;
  }

  /**
   * Get dentist id for week view filter
   */
  id(id) {
    this.dentistId = id;
  }

  /**
   * Get color
   */
  color(color) {
    this.colorFill = color;
  }

  /**
   * Get days of the week
   */
  getWeekDays(days) {
    this.days = days
  }

  /**
   * Format days of the week
   */
  formatDays(days) {
    this.daysFormatted = days;
  }

  /**
   * Get current month
   */
  getMonth(month) {
    this.month = month;
  }

  /**
   * Get Selected Doctor Data
   */
  getSelectedDoctor(data) {
    // console.log(data.start);
    this.triggerToday = true;
    this.filter('today');
  }
}
