import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { InventoryService } from 'src/app/modules/front-desk/service/inventory/inventory.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { element } from '@angular/core/src/render3';
import { ConditionalExpr } from '@angular/compiler';

@Component({
  selector: 'ark-add-stocks',
  templateUrl: './add-stocks.component.html',
  styleUrls: ['./add-stocks.component.scss']
})
export class AddStocksComponent implements OnInit {
  stocks_array = [];
  filteredOptions = [];
  clinic = null;
  quanty_array = {};
  item_array = {};
  ndx = 0;
  ctr = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<AddStocksComponent>,
  private api: InventoryService, private dialog: MatDialog, private auth: AuthService) {
    this.clinic = this.auth.getAuthUser().user_clinic.id;
  }

  ngOnInit() {
    this.stocks_array = [];
    this.getItems('');
  }

  closeDialog(e) {
    if(e){
      this.ctr = 0;
      e.forEach((element, i) => {
          this.quanty_array['element-' + i] = false;
          this.item_array['element-' + i] = false;
      });
      e.forEach((element, i) => {
        if(element.quantity == '' || element.quantity == null ){
          this.quanty_array['element-' + i] = true;
          this.ctr++
        }
        if(element.data == '' || element.data == null ){
          
          this.item_array['element-' + i] = true;
          this.ctr++
        }
      });
      
      if(this.ctr == 0){
        this.dialogRef.close(e);
      }
    }else{
      this.dialogRef.close(e);
    } 
  }

  getValue(e) {
    return this.quanty_array['element-' + e];
  }

  getValueItem(e) {
    return this.item_array['element-' + e];
  }
  addStocks() {
    this.stocks_array.push({
      data: '',
      quantity: '',
    });

    this.quanty_array['element-'+ this.ndx] = false;
    this.item_array['element-'+ this.ndx] = false;
    this.ndx++;
    
  }

  displayFn(value) {
    return value ? value.name  +  (value.brandname ? ' - ' +value.brandname : ' ') +  (value.description? ' - ' +value.description: ' ') : value;
  }

  removeItem(index) {
    this.stocks_array.splice(index, 1);
    delete(this.quanty_array['element-' + index]);
    delete(this.item_array['element-' + index]);
  }

  getFirst(arr, index) {
    const i = arr.findIndex( e => e === this.stocks_array[index].data);
    if (i === -1) {
      this.stocks_array[index].data = arr[0];
    }
  }

  getItems(val) {
    let filter = 'item-directory?user_clinic_id=' + this.clinic + '&item_name=' + val;
    this.api.getAPI(filter, '').subscribe(response => {
      if (response.status_code == 200) {
        this.filteredOptions = response.data.data;
      }
    });
  }

  onEnter(ndx){
    this.item_array['element-'+ ndx] = false;
  }

  inputQuantity(ndx){
      this.quanty_array['element-'+ ndx] = false;
  }
 
}
