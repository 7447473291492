import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/modules/shared/services/http/http.service';
import { register, verifyUser, termsAndConditions, unsubscribe, subscriptionBilling, subscribe } from 'src/app/common/api';

@Injectable()
export class SubscribeService extends HttpService {

  constructor(http: HttpClient, private _router: Router) { 
    super('', http);
  }

  registerUser(data) {
    return this.http.post(register, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  verifyToken(id) {
    return this.http.get(verifyUser + '?token=' + id).map(res => {
      return res['data'];
    });
  }

  termsAndConditions() {
    return this.http.get(termsAndConditions).map(res => {
      return res['data'];
    });
  }

  subscribeClinic(data) {
    return this.http.post(subscribe, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  unsubscribeClinic(data) {
    return this.http.post(unsubscribe, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  subscriptionBilling(user_clinic_id, user_id) {
    return this.http.get(subscriptionBilling + '?user_clinic_id=' + user_clinic_id + '&user_id=' + user_id).map(res => {
      return res['data'];
    });
  }

  subscriptionBillingDetails(user_clinic_id, user_id) {
    return this.http.get(subscriptionBilling + '-details' + '?user_clinic_id=' + user_clinic_id + '&user_id=' + user_id).map(res => {
      return res['data'];
    });
  }
}
