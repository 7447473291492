import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeesService } from '../../services/employees/employees.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';

@Component({
  selector: 'ark-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {

  clinic = null;
  filter = 'assistant';
  isNoResult: boolean = true;
  employees = [];

  constructor(
    private router: Router,
    private employee: EmployeesService,
    private auth: AuthService
  ) { 
  }

  ngOnInit() {
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.employee.getEmployees(this.clinic, this.filter).subscribe(res => {
      if(res) {
        this.employees = res;
        if(this.employees.length > 0) {
          this.isNoResult = false;
        } else {
          this.isNoResult = true;
        }
      }
    });
    localStorage.removeItem('assistantName');
  }

  goToDailyTimeRecord(data) {
    this.router.navigate(['/employees/daily-time-record', data.user_id]);
    const name = data.last_name + ", " + data.first_name;
    localStorage.setItem('assistantName', name);
  }

  goToPayroll(data) {
    this.router.navigate(['/employees/payroll', data.user_id]);
    const name = data.last_name + ", " + data.first_name;
    localStorage.setItem('assistantName', name);
  }
}
