import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PatientService } from '../../../service/patient/patient.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'ark-summary-report',
  templateUrl: './summary-report.component.html',
  styleUrls: ['./summary-report.component.scss']
})
export class SummaryReportComponent implements OnInit, OnChanges {

  today = new Date(); 
  formGroup : FormGroup;
  clinic = null;
  start : any;
  end : any;
  summary = null;
  header = 'Patient Billing Summary Report';
  @Input() dentist: any = null; 
  @Input() from: any = null; 
  @Input() to: any = null; 
  @Input() tab: any = null; 
  totalAmountPaid;
  totalBalances;
  totalBill;  

  constructor(
    private formBuilder: FormBuilder,
    private patient: PatientService,
    private auth: AuthService
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.formGroup = this.formBuilder.group({
      from: [''],
      to: [''],
    });
  }

  ngOnInit() {
    this.formGroup.controls['from'].disable();
    this.formGroup.controls['to'].disable();
    this.formGroup.get('from').setValue(moment(this.today).format("M/D/YYYY"));
    this.formGroup.get('to').setValue(moment(this.today).format("M/D/YYYY"));
    this.start = moment(this.formGroup.value.from).format("YYYY-MM-DD");
    this.end = moment(this.formGroup.value.to).format("YYYY-MM-DD");
    this.getSummary(this.tab);
  }

  ngOnChanges() {
    if(this.from || this.to) {
      this.formGroup.get('from').setValue(moment(this.from).format("M/D/YYYY"));
      this.formGroup.get('to').setValue(moment(this.to).format("M/D/YYYY"));
      this.start = moment(this.from).format("YYYY-MM-DD");
      this.end = moment(this.to).format("YYYY-MM-DD");
      this.getSummary(this.tab);
    }

    if(this.tab == 'billing') {
      this.header = 'Patient Billing Summary Report';
    } else {
      this.header = 'Patient Balance Summary Report';
    }
  }

  getSummary(tab) {
    if(tab == 'billing') {
      this.patient.getPatientBillingList(this.clinic, this.dentist, this.start, this.end, '').subscribe(res => {
        if(res) {
          let amount_paid = 0;
          let balance = 0;
          let total_bill = 0;
          res.patient_info.data.forEach(element => {
            amount_paid += parseFloat(element.amount_paid);
            balance += parseFloat(element.balance);
            total_bill += parseFloat(element.total_bill);
          });
          this.summary = res;
          this.totalAmountPaid = res.total_amount_paid;
          this.totalBalances = res.total_balances;
          this.totalBill = res.total_bill;
        }
      });
    } else {
      this.patient.getPatientBalanceList(this.clinic, this.dentist, this.start, this.end, '').subscribe(res => {
        if(res) {
          let amount_paid = 0;
          let balance = 0;
          let total_bill = 0;
          res.patient_info.data.forEach(element => {
            amount_paid += parseFloat(element.amount_paid);
            balance += parseFloat(element.balance);
            total_bill += parseFloat(element.total_bill);
          });
          this.summary = res;
          this.totalAmountPaid = res.total_amount_paid;
          this.totalBalances = res.total_balances;
          this.totalBill = res.total_bill;
        }
      });
    }
  }
}
