import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/modules/shared/services/http/http.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { incomeStatementSummary, financeGraph, payroll, printPayroll } from 'src/app/common/api';

@Injectable()
export class FinanceService extends HttpService {

  constructor(http: HttpClient, private _router: Router) {
    super('',http);
  }

  getIncomeStatementSummary(id, year) {
    return this.get(incomeStatementSummary + '?user_clinic_id=' + id + '&year=' + year).map(res => {
      return res['data'];
    });
  }

  getFinanceGraph(id, filter, year) {
    return this.get(financeGraph + '?user_clinic_id=' + id + '&filter=' + filter + '&year=' + year).map(res => {
      return res['data'];
    });
  }

  downloadAnnualSummary(user_clinic_id, year) {
    return this.http.get(incomeStatementSummary + "/download" + "?user_clinic_id=" + user_clinic_id  + "&year=" + year, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  downloadMonthlySummary(user_clinic_id, month, year) {
    return this.http.get(incomeStatementSummary + "/download" + "?user_clinic_id=" + user_clinic_id  + "&month=" + month + "&year=" + year, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  emailAnnualSummary(user_clinic_id, year) {
    return this.http.get(incomeStatementSummary + "/download" + "?user_clinic_id=" + user_clinic_id  + "&year=" + year + "&email=true", {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  emailMonthlySummary(user_clinic_id, month, year) {
    return this.http.get(incomeStatementSummary + "/download" + "?user_clinic_id=" + user_clinic_id  + "&month=" + month + "&year=" + year + "&email=true", {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  downloadGraph(id, data) {
    return this.http.post(financeGraph + '-pdf?user_clinic_id=' + id, data, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }
  
  emailGraph(id, data) {
    return this.http.post(financeGraph + '-pdf?user_clinic_id=' + id + '&email=true', data, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  downloadPayroll(user_clinic_id, employee_id, year, month, period) {
    return this.http.get(printPayroll + "?user_clinic_id=" + user_clinic_id  + "&month=" + month + "&year=" + year + "&employee_id=" + employee_id + "&period="+ period, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }
}