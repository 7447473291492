import { Component, OnInit, ViewChild } from '@angular/core';
import { SidebarService } from 'src/app/modules/core/services/sidebar/sidebar.service';

@Component({
  selector: 'ark-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {

  minimizeSidebar: boolean = false;
  event: string[] = [];
  mode = 'side';
  content = document.querySelector("#content");

  @ViewChild('sidemenu') sidemenu: any;

  constructor(
    private sidebarService: SidebarService
  ) { 
    if(localStorage.getItem('isSidebarMinimized')) {
      this.minimizeSidebar = JSON.parse(localStorage.getItem('isSidebarMinimized'));
      this.sidebarService.setSidebarState(this.minimizeSidebar);
    }
  }

  ngOnInit() {
  }

  toggleSidebar() {
    this.minimizeSidebar = !this.minimizeSidebar;
    this.sidebarService.setSidebarState(this.minimizeSidebar);
  }

  changeMode(newMode: string) {
    this.mode = newMode;
  }

  toggle(e, bool, mode, element) {
    if(e.type == 'mouseover') {
      if(element == 'sidemenu') {
        if(mode == 'over' && bool == true) {
          this.sidemenu.open();
        }
      } else if(element == 'content') {
        if(mode == 'over' && bool == false) {
          this.sidemenu.close();
        }
      }
    } 
  }
}

