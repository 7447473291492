import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChooseSubscriptionComponent } from './components/choose-subscription/choose-subscription.component';
import { SubscriptionFormComponent } from './components/subscription-form/subscription-form.component';
import { SharedModule } from '../shared/shared.module';
import { MatCheckboxModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SubscribeService } from './services/subscribe/subscribe.service';

@NgModule({
  declarations: [
    ChooseSubscriptionComponent, 
    SubscriptionFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule
  ],
  entryComponents: [
    ChooseSubscriptionComponent,
    SubscriptionFormComponent
  ],
  providers: [
    SubscribeService
  ]
})
export class SubscriptionModule { }
