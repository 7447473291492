import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { PatientService } from '../../../service/patient/patient.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import * as moment from 'moment';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ark-balances',
  templateUrl: './balances.component.html',
  styleUrls: ['./balances.component.scss']
})
export class BalancesComponent implements OnInit, OnChanges {

  @Input() dentist_id: any = null; 
  @Input() from: any = null; 
  @Input() to: any = null; 
  @Input() search: any = null; 
  balances = [];
  clinic = null;
  start = '';
  end = '';
  isNoResult: boolean = true;

  constructor(
    private auth: AuthService,
    private billing: PatientService,
    private patient: PatientsDirectoryService,
    private _router: Router
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
  }

  ngOnInit() {
    
  }

  ngOnChanges() {
    if(this.dentist_id || this.from || this.to || this.search) {
      this.start =  moment(this.from).format("YYYY-MM-DD");
      this.end =  moment(this.to).format("YYYY-MM-DD");
      this.getList();
    }
  }

  getList() {
    this.billing.getPatientBalanceList(this.clinic, this.dentist_id, this.start, this.end, this.search).subscribe(res => {
      if(res) {
        this.balances = res.patient_info.data;
        if(this.balances.length > 0) {
          this.isNoResult = false;
        } else {
          this.isNoResult = true;
        }
      }
    });
  }

  openPatientDetails(res) {
    this.patient.removeData();
    this._router.navigate(['/patient-details', res.id]);
    const name = {
      first_name: res.first_name,
      last_name: res.last_name,
      middle_name: res.middle_name ? res.middle_name : '',
      email: res.email,
      mobile_number: res.mobile_number,
      birthdate: res.birthdate ? moment(res.birthdate).format("YYYY-MM-DD") : '',
      allergy: res.allergies
    };
    const billing = {
      id: res.visit_info_id,
      tab: 1,
      disabled: false,
    }
    localStorage.setItem('patientName', JSON.stringify(name));
    localStorage.setItem('visitInfo', JSON.stringify(billing));
  }
}
