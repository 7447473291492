import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { DentalFormsService } from 'src/app/modules/dental-office/services/dental-forms/dental-forms.service';

@Component({
  selector: 'ark-pre-filled-prescriptions',
  templateUrl: './pre-filled-prescriptions.component.html',
  styleUrls: ['./pre-filled-prescriptions.component.scss']
})
export class PreFilledPrescriptionsComponent implements OnInit {

  prescription = [];
  clinic = null;
  checked = {};
  hasPrescriptions: boolean = false;
  dialogConfig = new MatDialogConfig();

  PRE_FILLED_PRESCRIPTION = [
    {
      title: {
        name: 'Pediatric Antibiotic Therapy'
      },
      prescription : [
        {
          id: 1,
          name: 'Amoxicillin 125mg/5ml',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  1,
              label: 'bottle(s) 60ml',
              optional: false,
            }, 
            desc_option: {
              value: '',
              desc_id:  1,
              label: 'teaspoon orally every 8hrs for 7days. Do this preferably 10minutes after meals.',
              optional: false,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 2,
          name: 'Amoxicillin 250mg/5ml',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  2,
              label: 'bottle(s) 60ml',
              optional: false,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  2,
              label: 'teaspoon orally every 8hrs for 7days. Do this preferably 10minutes after meals.',
              optional: false,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 3,
          name: 'Clindamycin 125mg/5ml',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  3,
              label: 'bottle(s) 60ml',
              optional: false,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  3,
              label: 'teaspoon orally every 8hrs for 7days. Do this preferably 10minutes after meals.',
              optional: false,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 4,
          name: 'Clindamycin 250mg/5ml',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  4,
              label: 'bottle(s) 60ml',
              optional: false,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  4,
              label: 'teaspoon orally every 8hrs for 7days. Do this preferably 10minutes after meals.',
              optional: false,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 5,
          name: 'Erythromycin 125mg/5ml',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  5,
              label: 'bottle(s) 60ml',
              optional: false,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  5,
              label: 'teaspoon orally every 8hrs for 7days. Do this preferably 10minutes after meals.',
              optional: false,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 6,
          name: 'Erythromycin 250mg/5ml',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  6,
              label: 'bottle(s) 60ml',
              optional: false,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  6,
              label: 'teaspoon orally every 8hrs for 7days. Do this preferably 10minutes after meals.',
              optional: false,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        }
      ]
    },
    {
      title: {
        name: 'Adult Antibiotic Therapy'
      },
      prescription : [
        {
          id: 7,
          name: 'Amoxicillin 250mg',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  7,
              label: '21 Capsules',
              optional: true,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  7,
              label: 'ONE capsule orally every 8hrs for 7days. Do this preferably 10minutes after meals.',
              optional: true,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 8,
          name: 'Amoxicillin 500mg',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  8,
              label: '21 Capsules',
              optional: true,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  8,
              label: 'ONE capsule orally every 8hrs for 7days. Do this preferably 10minutes after meals.',
              optional: true,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 9,
          name: 'Clindamycin 300mg',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  9,
              label: '28 Capsules',
              optional: true,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  9,
              label: 'ONE capsule orally every 6hrs for 7days. Do this preferably 10minutes after meals.',
              optional: true,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 10,
          name: 'Clindamycin 300mg',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  10,
              label: '40 Capsules',
              optional: true,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  10,
              label: 'ONE capsule orally every 6hrs for 10days. Do this preferably 10minutes after meals.',
              optional: true,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 11,
          name: 'Erythromycin 250mg',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  11,
              label: '21 Capsules',
              optional: true,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  11,
              label: 'ONE capsule orally every 8hrs for 7days. Do this preferably 10minutes after meals.',
              optional: true,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 12,
          name: 'Erythromycin 500mg',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  12,
              label: '21 Capsules',
              optional: true,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  12,
              label: 'ONE capsule orally every 8hrs for 7days. Do this preferably 10minutes after meals.',
              optional: true,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 13,
          name: 'Cefalexin 250mg',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  13,
              label: '21 Capsules',
              optional: true,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  13,
              label: 'ONE capsule orally every 8hrs for 7days. Do this preferably 10minutes after meals.',
              optional: true,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 14,
          name: 'Cefalexin 500mg',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  14,
              label: '21 Capsules',
              optional: true,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  14,
              label: 'ONE capsule orally every 8hrs for 7days. Do this preferably 10minutes after meals.',
              optional: true,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        }
      ]
    },
    {
      title: {
        name: 'Pediatric Pain Reliever'
      },
      prescription : [
        {
          id: 15,
          name: 'Mefenamic Acid 50mg/5ml',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  15,
              label: 'bottle(s) 60ml',
              optional: false,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  15,
              label: 'teaspoon orally every 6hrs for 3days or as needed for pain. Do this preferably 10minutes after meals.',
              optional: false,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 16,
          name: 'Mefenamic Acid 500mg susp.',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  16,
              label: 'bottle(s) 60ml',
              optional: false,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  16,
              label: 'teaspoon orally every 6hrs for 3days or as needed for pain. Do this preferably 10minutes after meals.',
              optional: false,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 17,
          name: 'Mefenamic Acid 250mg',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  17,
              label: '6 tablets',
              optional: true,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  17,
              label: 'ONE tablet orally every 6hrs for 2days or as needed for pain. Do this preferably 10minutes after meals.',
              optional: true,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 18,
          name: 'Mefenamic Acid 500mg',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  18,
              label: '6 tablets',
              optional: true,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  18,
              label: 'ONE tablet orally every 6hrs for 2days or as needed for pain. Do this preferably 10minutes after meals.',
              optional: true,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 19,
          name: 'Paracetamol 120mg/5ml',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  19,
              label: 'bottle(s) 60ml',
              optional: false,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  19,
              label: 'teaspoon orally every 6hrs for 3days or as needed for pain. Do this preferably 10minutes after meals.',
              optional: false,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 20,
          name: 'Paracetamol 250mg/5ml',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  20,
              label: 'bottle(s) 120ml',
              optional: false,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  20,
              label: 'teaspoon orally every 6hrs for 3days or as needed for pain. Do this preferably 10minutes after meals.',
              optional: false,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 21,
          name: 'Ibuprofen 100mg/5ml',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  21,
              label: 'bottle(s) 60ml',
              optional: false,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  21,
              label: 'teaspoon orally every 6hrs for 3days or as needed for pain. Do this preferably 10minutes after meals.',
              optional: false,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 22,
          name: 'Ibuprofen 125mg/5ml',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  22,
              label: 'bottle(s) 60ml',
              optional: false,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  22,
              label: 'teaspoon orally every 6hrs for 3days or as needed for pain. Do this preferably 10minutes after meals.',
              optional: false,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        }
      ]
    },
    {
      title: {
        name: 'Adult Pain Reliever'
      },
      prescription : [
        {
          id: 23,
          name: 'Mefenamic Acid 500mg',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  23,
              label: '12 Capsules',
              optional: true,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  23,
              label: 'ONE capsule orally every 6hours for 3days.',
              optional: true,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 24,
          name: 'Ibuprofen 500mg',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  24,
              label: '12 Capsules',
              optional: true,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  24,
              label: 'ONE capsule orally every 6hours for 3days.',
              optional: true,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 25,
          name: 'Celecoxib 90mg',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  25,
              label: '3 Capsules',
              optional: true,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  25,
              label: 'ONE capsule orally once a day for 3days only.',
              optional: true,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        },
        {
          id: 26,
          name: 'Celecoxib 120mg',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  26,
              label: '3 Capsules',
              optional: true,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  26,
              label: 'ONE capsule orally once a day for 3days only.',
              optional: true,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        }
      ]
    },
    {
      title: {
        name: 'Anticoagulant'
      },
      prescription : [
        {
          id: 27,
          name: 'Tranexamic Acid 500mg',
          is_checked: 0,
          checked: false,
          value: '',
          sub_content: {
            label_option: {
              value: '',
              label_id:  27,
              label: '3 Capsules',
              optional: true,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  27,
              label: 'ONE capsule orally every 12hours.',
              optional: true,
              custom_label: {
                custom: false,
                label: ''
              }
            }
          }
        }
      ]
    },
    {
      title: {
        name: 'Medicated Mouth Rinse'
      },
      prescription : [
        {
          id: 28,
          name: 'Chlorhexidine Gluconate 0.12% (Orahex)',
          is_checked: 0,
          checked: false,
          value: null,
          sub_content: {
            label_option: {
              value: '',
              label_id:  28,
              label: 'bottle 120ml',
              optional: false,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  28,
              label: 'ONE capsule orally every 12hours.',
              optional: true,
              custom_label: {
                custom: true,
                label: '5ml every 12 hours for 7-14 days.'
              }
            }
          }
        },
        {
          id: 29,
          name: 'Chlorhexidine Gluconate 0.12% (Orahex)',
          is_checked: 0,
          checked: false,
          value: null,
          sub_content: {
            label_option: {
              value: '',
              label_id:  29,
              label: 'bottle 500ml',
              optional: false,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  29,
              label: 'ONE capsule orally every 12hours.',
              optional: true,
              custom_label: {
                custom: true,
                label: '5ml every 12 hours for 14-28 days.'
              }
            }
          }
        },
        {
          id: 30,
          name: 'Chlorhexidine Gluconate 0.12% (Orahex Forte)',
          is_checked: 0,
          checked: false,
          value: null,
          sub_content: {
            label_option: {
              value: '',
              label_id:  30,
              label: 'bottle 60ml',
              optional: false,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  30,
              label: 'ONE capsule orally every 12hours.',
              optional: true,
              custom_label: {
                custom: true,
                label: '5ml every 12 hours for 3-5 days.'
              }
            }
          }
        },
        {
          id: 31,
          name: 'Chlorhexidine Gluconate 0.12% (Orahex Forte)',
          is_checked: 0,
          checked: false,
          value: null,
          sub_content: {
            label_option: {
              value: '',
              label_id:  31,
              label: 'bottle 120ml',
              optional: false,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  31,
              label: 'ONE capsule orally every 12hours.',
              optional: true,
              custom_label: {
                custom: true,
                label: '5ml every 12 hours for 7-14 days.'
              }
            }
          }
        },
        {
          id: 32,
          name: 'Hexitidine 0.1% (Bactidol)',
          is_checked: 0,
          checked: false,
          value: null,
          sub_content: {
            label_option: {
              value: '',
              label_id:  32,
              label: 'bottle 120ml',
              optional: false,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  32,
              label: 'ONE capsule orally every 12hours.',
              optional: true,
              custom_label: {
                custom: true,
                label: '5ml every 12 hours for 7-14 days.'
              }
            }
          }
        },
        {
          id: 33,
          name: 'Hexitidine 0.1% (Bactidol)',
          is_checked: 0,
          checked: false,
          value: null,
          sub_content: {
            label_option: {
              value: '',
              label_id:  33,
              label: 'bottle 250ml',
              optional: false,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  33,
              label: 'ONE capsule orally every 12hours.',
              optional: true,
              custom_label: {
                custom: true,
                label: '5ml every 12 hours for 7-14 days.'
              }
            }
          }
        },
        {
          id: 34,
          name: 'Povidone Iodine 1% (Oral Betadine)',
          is_checked: 0,
          checked: false,
          value: null,
          sub_content: {
            label_option: {
              value: '',
              label_id:  34,
              label: 'bottle 60ml',
              optional: false,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  34,
              label: 'ONE capsule orally every 12hours.',
              optional: true,
              custom_label: {
                custom: true,
                label: '5ml every 12 hours for 7-14 days.'
              }
            }
          }
        },
        {
          id: 35,
          name: 'Povidone Iodine 1% (Oral Betadine)',
          is_checked: 0,
          checked: false,
          value: null,
          sub_content: {
            label_option: {
              value: '',
              label_id:  35,
              label: 'bottle 120ml',
              optional: false,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  35,
              label: 'ONE capsule orally every 12hours.',
              optional: true,
              custom_label: {
                custom: true,
                label: '5ml every 12 hours for 7-14 days.'
              }
            }
          }
        },
        {
          id: 36,
          name: 'Povidone Iodine 1% (Oral Betadine)',
          is_checked: 0,
          checked: false,
          value: null,
          sub_content: {
            label_option: {
              value: '',
              label_id:  36,
              label: 'bottle 500ml',
              optional: false,
              
            }, 
            desc_option: {
              value: '',
              desc_id:  36,
              label: 'ONE capsule orally every 12hours.',
              optional: true,
              custom_label: {
                custom: true,
                label: '5ml every 12 hours for 14-28 days.'
              }
            }
          }
        }
      ]
    }
  ]

  isChecked = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PreFilledPrescriptionsComponent>,
    public dialog: MatDialog,
    private auth: AuthService,
    public forms: DentalFormsService
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  ngOnInit() {
    if(this.data.data !== undefined) {
      this.PRE_FILLED_PRESCRIPTION.forEach(element => {
        element.prescription.forEach((x, i) => {
          this.data.data.forEach(el => {
            if(x.id == el.id) {
              x.is_checked = 1;
              x.checked = true;
              this.prescription.push(el);
            }   
          });
        });
      });
    }
  }

  onCheck(e, obj) {
    obj.is_checked = (e.checked) ? 1 : 0;
    if(obj.is_checked) {
      this.prescription.push(obj);
    } else {
      this.prescription.splice(this.prescription.findIndex(item => item.id === obj.id), 1);
    }
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const data = {
      user_clinic_id: this.clinic,
      patient_id: this.data.id,
      prescription: JSON.stringify({prescription: this.prescription}),
      notes: this.data.notes
    }
    this.forms.addPrescription(data).subscribe(res => {
      if(res) {
        this.dialogRef.close(res);
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Pre-filled prescription has been successfully saved.',
          button: 'Okay',
          event: this.close
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Sorry!',
        message: err.error.message,
        button: 'Okay',
        event: this.close
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
    }));
  }

  uncheckAll() {
    this.PRE_FILLED_PRESCRIPTION.forEach(element => {
      element.prescription.forEach((x) => {
        x.is_checked = 0;
        x.checked = false;
      });
    });
    this.prescription = [];
  }
}
