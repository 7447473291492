import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AddOperationalExpensesComponent } from './monthly-expenses/operational-expenses/add-operational-expenses/add-operational-expenses.component';
import { ExpensesService } from '../../service/expenses/expenses.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
  },
};

@Component({
  selector: 'ark-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ExpensesComponent implements OnInit {

  filters = [
    { name: 'Monthly Expenses', value: 'monthly' },
    { name: 'Annual Expenses', value: 'annual' }
  ];
  categories = [
    { id: 1, name: 'Operational Expense', value: 'operational-expense' },
    { id: 2, name: 'Direct Cost', value: 'direct-cost' },
    { id: 3, name: 'Doctor\'s Fee', value: 'doctor-fee' }
  ]
  months = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
  ]
  category = 1;
  monthId = +moment().format("M");
  yearId = +moment().format("YYYY");
  data = [];
  selected: string = 'monthly';
  monthlyView: boolean = true;
  annualView: boolean = false;
  today = new Date(); 
  formGroup: FormGroup;
  clinic = null;
  val = '';
  dialogConfig = new MatDialogConfig();
  dialogRef: any;
  summary = null;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private dialog: MatDialog,
    private expenses: ExpensesService
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.formGroup = this.formBuilder.group({
      category: [''],
      month: [''],
      year: new FormControl(moment()),
    });
    this.formGroup.get('category').setValue(1);
    this.formGroup.get('month').setValue(+moment().format("M"));
    this.monthId = +moment().format("M");
    this.yearId = +moment().format("YYYY");
    this.category = 1;
  }

  ngOnInit() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
  }

  onClickButton(value) {
    this.selected = value;
    if(value == 'monthly') {
      this.monthlyView = true;
      this.annualView = false;
    } else if (value == 'annual') {
      this.monthlyView = false;
      this.annualView = true;
    }
  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const val = this.formGroup.value.year;
    val.year(normalizedYear.year());
    this.formGroup.get('year').setValue(val);
    datepicker.close();
    
    this.yearId = +moment(this.formGroup.value.year).format("YYYY");
  }

  getCategory(category) {
    this.category = category.value;
  }

  getMonth(month) {
    this.monthId = month.value;
  }

  getText(val) {
    if(val) {
      this.val = val;
    } else {
      this.val = '';
    }
  }

  addOperationalExpenses() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogConfig.data = {
      clinic: this.clinic,
      month: +moment().format("M"),
      year: +moment().format("YYYY")
    }
    this.dialogRef = this.dialog.open(AddOperationalExpensesComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe(res => {
      if(res) {
        this.data = res.data.item.data;
      }
    });
  }

  getExpensesSummary(val) {
    this.summary = val;
  }

  generatePDF() {
    if(this.selected == 'monthly') {
      if(this.category == 1) {
        this.expenses.downloadOperationalExpenses(this.clinic, this.monthId, this.yearId).subscribe(res => {
          if(res) {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(res);
            a.download = moment(this.today).format("YYYYMMDD") + '-OPERATIONAL EXPENSES (' + moment(this.monthId, 'M').format('MMMM') + ' ' + this.yearId + ').pdf';
            document.body.appendChild(a);
            a.click();
          }
        },(err => {
          this.dialogConfig.data = {
            title: 'Oops!',
            message: 'Exporting of PDF failed.',
            button: 'Okay',
            event: this.closeOnClick
          };
          this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }));
      } else if(this.category == 2) {
        this.expenses.downloadDirectCost(this.clinic, this.monthId, this.yearId).subscribe(res => {
          if(res) {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(res);
            a.download = moment(this.today).format("YYYYMMDD") + '-DIRECT COST (' + moment(this.monthId, 'M').format('MMMM') + ' ' + this.yearId + ').pdf';
            document.body.appendChild(a);
            a.click();
          }
        },(err => {
          this.dialogConfig.data = {
            title: 'Oops!',
            message: 'Exporting of PDF failed.',
            button: 'Okay',
            event: this.closeOnClick
          };
          this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }));
      } else if(this.category == 3) {
        this.expenses.downloadDoctorFee(this.clinic, this.monthId, this.yearId).subscribe(res => {
          if(res) {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(res);
            a.download = moment(this.today).format("YYYYMMDD") + '-DOCTORS FEE (' + moment(this.monthId, 'M').format('MMMM') + ' ' + this.yearId + ').pdf';
            document.body.appendChild(a);
            a.click();
          }
        },(err => {
          this.dialogConfig.data = {
            title: 'Oops!',
            message: 'Exporting of PDF failed.',
            button: 'Okay',
            event: this.closeOnClick
          };
          this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }));
      }
    } else if(this.selected == 'annual') {
      this.expenses.downloadAnnualExpenses(this.clinic, this.yearId).subscribe(res => {
        if(res) {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(res);
          a.download = moment(this.today).format("YYYYMMDD") + '-ANNUAL EXPENSES (' + this.yearId + ').pdf';
          document.body.appendChild(a);
          a.click();
        }
      },(err => {
        this.dialogConfig.data = {
          title: 'Oops!',
          message: 'Exporting of PDF failed.',
          button: 'Okay',
          event: this.closeOnClick
        };
        this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }));
    }
  }

  sendEmail() {
    if(this.selected == 'monthly') {
      if(this.category == 1) {
        this.expenses.emailOperationalExpenses(this.clinic, this.monthId, this.yearId).subscribe(res => {
          if(res) {
            this.dialogConfig.data = {
              title: 'Success!',
              message: 'Operational expenses has been emailed successfully.',
              button: 'Okay',
              event: this.closeOnClick
            };
            let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
          }
        });
      } else if(this.category == 2) {
        this.expenses.emailDirectCost(this.clinic, this.monthId, this.yearId).subscribe(res => {
          if(res) {
            this.dialogConfig.data = {
              title: 'Success!',
              message: 'Direct cost has been emailed successfully.',
              button: 'Okay',
              event: this.closeOnClick
            };
            let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
          }
        });
      } else if(this.category == 3) {
        this.expenses.emailDoctorFee(this.clinic, this.monthId, this.yearId).subscribe(res => {
          if(res) {
            this.dialogConfig.data = {
              title: 'Success!',
              message: 'Doctor\'s fee has been emailed successfully.',
              button: 'Okay',
              event: this.closeOnClick
            };
            let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
          }
        });
      } 
    } else if(this.selected == 'annual') {
      this.expenses.emailAnnualExpenses(this.clinic, this.yearId).subscribe(res => {
        if(res) {
          this.dialogConfig.data = {
            title: 'Success!',
            message: 'Annual expenses has been emailed successfully.',
            button: 'Okay',
            event: this.closeOnClick
          };
          let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
        }
      });
    }
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  getPreSavedData(e) {
    localStorage.setItem('operationalExpenses', JSON.stringify(e));
  }
}
