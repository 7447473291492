import { Component, OnInit, Input, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { FinanceService } from 'src/app/modules/dental-office/services/finance/finance.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'ark-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.scss']
})
export class IncomeComponent implements OnInit, OnChanges {

  @Input() year: any = null;
  @ViewChild('barChart') private chartRef;
  chart: any;
  chartUrl: any;
  data: any;
  clinic = null;
  filter = 'income';

  constructor(
    private finance: FinanceService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    
  }

  ngOnChanges() {
    if(this.year) {
      this.getChart();
    }
  }

  getChart() {
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.finance.getFinanceGraph(this.clinic, this.filter, this.year).subscribe(res => {
      if(res) {
        this.data = res;
      }
      this.chart = new Chart(this.chartRef.nativeElement, {
        type: 'bar',
        data: {
          labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          datasets: [
            {
              label: "Income",
              backgroundColor: "#65c6b9",
              borderColor: "#65c6b9",
              borderWidth: 1,
              data: [
                this.data['1'].income,
                this.data['2'].income,
                this.data['3'].income,
                this.data['4'].income,
                this.data['5'].income,
                this.data['6'].income,
                this.data['7'].income,
                this.data['8'].income,
                this.data['9'].income,
                this.data['10'].income,
                this.data['11'].income,
                this.data['12'].income,
              ]
            }
          ]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                min: 0,
                maxTicksLimit: 8,
                // Include a peso sign in the ticks
                callback: function(value, index, values) {
                  if(parseInt(value) >= 1000){
                    return '₱ ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  } else {
                    return '₱ ' + value;
                  }
                }
              }
            }],
            xAxes: [{
              barPercentage: 1.0,
              categoryPercentage: 0.5
            }]
          },
          maintainAspectRatio: false,
          responsive: true,
          legend: {
            display: false
          },
          tooltips: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            callbacks: {
              label: function(tooltipItem, data) {
                return ' ' + tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }
            },
            xPadding: 10,
            yPadding: 10,
            titleFontFamily: 'News Cycle',
            bodyFontFamily: 'News Cycle',
          },
          animation: {
            onComplete: done
          }
        }
      });

      function done(){
        this.chartUrl = this.chart.canvas.toDataURL();

        localStorage.setItem('chart', this.chartUrl);
      }
    });
  }
}
