import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ark-monthly-expenses',
  templateUrl: './monthly-expenses.component.html',
  styleUrls: ['./monthly-expenses.component.scss']
})
export class MonthlyExpensesComponent implements OnInit, OnChanges {

  @Input() category: any = null;
  @Input() month: any = null;
  @Input() year: any = null;
  @Input() search: any = null; 
  @Input() data: any = null; 
  @Output() expensesSummary: EventEmitter<any> = new EventEmitter;
  @Output() preSavedData: EventEmitter<any> = new EventEmitter;
  operExpenses: boolean = false;
  directCost: boolean = false;
  doctorFee: boolean = false;
  selectedMonth = '';
  selectedYear = '';
  keyword = '';
  expensesData = [];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    if(this.category || this.month || this.year || this.search || this.data) {
      this.selectedMonth = this.month;
      this.selectedYear = this.year;
      this.keyword = this.search;
      this.expensesData = this.data;
      if(this.category == 1) {
        this.operExpenses = true;
        this.directCost = false;
        this.doctorFee = false;
      } else if(this.category == 2) {
        this.operExpenses = false;
        this.directCost = true;
        this.doctorFee = false;
      } else {
        this.operExpenses = false;
        this.directCost = false;
        this.doctorFee = true;
      }
    } 
  }

  getExpensesSummary(val) {
    this.expensesSummary.emit(val);
  }

  getPreSavedData(e) {
    // Pre save data when user didn't save/update the new item.
    this.preSavedData.emit(e);
  }
}
