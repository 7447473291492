import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ark-dental-forms',
  templateUrl: './dental-forms.component.html',
  styleUrls: ['./dental-forms.component.scss']
})
export class DentalFormsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
