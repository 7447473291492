import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ToothConditionComponent } from '../tooth-condition/tooth-condition.component';
import { ActivatedRoute } from '@angular/router';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { mapToothStatus } from 'src/app/common/helper';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { ConfirmBridgeComponent } from '../bridge/confirm-bridge/confirm-bridge.component';
import { UnbridgeComponent } from '../bridge/unbridge/unbridge.component';
import * as moment from 'moment';

@Component({
  selector: 'ark-tooth-container',
  templateUrl: './tooth-container.component.html',
  styleUrls: ['./tooth-container.component.scss']
})
export class ToothContainerComponent implements OnInit, OnChanges {

  firstQuadrant = [
    { id: 11, name: 11, quadrant: 1 },
    { id: 12, name: 12, quadrant: 1 },
    { id: 13, name: 13, quadrant: 1 },
    { id: 14, name: 14, quadrant: 1 },
    { id: 15, name: 15, quadrant: 1 },
    { id: 16, name: 16, quadrant: 1 },
    { id: 17, name: 17, quadrant: 1 },
    { id: 18, name: 18, quadrant: 1 }
  ];
  secondQuadrant = [
    { id: 21, name: 21, quadrant: 2 },
    { id: 22, name: 22, quadrant: 2 },
    { id: 23, name: 23, quadrant: 2 },
    { id: 24, name: 24, quadrant: 2 },
    { id: 25, name: 25, quadrant: 2 },
    { id: 26, name: 26, quadrant: 2 },
    { id: 27, name: 27, quadrant: 2 },
    { id: 28, name: 28, quadrant: 2 }
  ];
  thirdQuadrant = [
    { id: 31, name: 31, quadrant: 3 },
    { id: 32, name: 32, quadrant: 3 },
    { id: 33, name: 33, quadrant: 3 },
    { id: 34, name: 34, quadrant: 3 },
    { id: 35, name: 35, quadrant: 3 },
    { id: 36, name: 36, quadrant: 3 },
    { id: 37, name: 37, quadrant: 3 },
    { id: 38, name: 38, quadrant: 3 }
  ];
  fourthQuadrant = [
    { id: 41, name: 41, quadrant: 4 },
    { id: 42, name: 42, quadrant: 4 },
    { id: 43, name: 43, quadrant: 4 },
    { id: 44, name: 44, quadrant: 4 },
    { id: 45, name: 45, quadrant: 4 },
    { id: 46, name: 46, quadrant: 4 },
    { id: 47, name: 47, quadrant: 4 },
    { id: 48, name: 48, quadrant: 4 }
  ];
  fifthQuadrant = [
    { id: 51, name: 51, quadrant: 5 },
    { id: 52, name: 52, quadrant: 5 },
    { id: 53, name: 53, quadrant: 5 },
    { id: 54, name: 54, quadrant: 5 },
    { id: 55, name: 55, quadrant: 5 }
  ];
  sixthQuadrant = [
    { id: 61, name: 61, quadrant: 6 },
    { id: 62, name: 62, quadrant: 6 },
    { id: 63, name: 63, quadrant: 6 },
    { id: 64, name: 64, quadrant: 6 },
    { id: 65, name: 65, quadrant: 6 }
  ];
  seventhQuadrant = [
    { id: 71, name: 71, quadrant: 7 },
    { id: 72, name: 72, quadrant: 7 },
    { id: 73, name: 73, quadrant: 7 },
    { id: 74, name: 74, quadrant: 7 },
    { id: 75, name: 75, quadrant: 7 }
  ];
  eighthQuadrant = [
    { id: 81, name: 81, quadrant: 8 },
    { id: 82, name: 82, quadrant: 8 },
    { id: 83, name: 83, quadrant: 8 },
    { id: 84, name: 84, quadrant: 8 },
    { id: 85, name: 85, quadrant: 8 }
  ];
  dialogConfig = new MatDialogConfig();
  dialogRef: any;
  patientId = null;
  chartId = null;
  createdAt = null;
  toothChartData = '';
  chartData = '';
  success = false
  status = '';
  id = '';
  clinic = '';
  today = new Date();
  
  bridge = [];
  bridgeStart = null;
  bridgeEnd = null;

  @Output() newChart = new EventEmitter<any>();
  @Input() filterOption: any;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private patientsDirectory: PatientsDirectoryService,
    private auth: AuthService
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.id = this.clinic;
    this.route.params.subscribe(param => {
      this.patientId = param.id
    });
    this.getLatestChart();
  }

  ngOnInit() {
    
  }

  ngOnChanges() {
    if(this.filterOption) {
      this.getChartHistory();
    }
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    this.dialogConfig.disableClose = true;
  }

  updateTooth(data) {
    this.dialogConfig.data = {
      tooth: data,
      patient_id: this.patientId,
      chart_data: this.chartData,
      chart_id: this.chartId,
      created_at: this.createdAt
    };
    this.dialogRef = this.dialog.open(ToothConditionComponent, this.dialogConfig).afterClosed().subscribe(response => {
      if(response) {
        this.success = true;
        this.toothChartData = response.data.data[0]['toothcharts'][0];
        this.chartData = JSON.parse(this.toothChartData['chart']);
        this.chartId = this.toothChartData['id'];
        this.getPatientCharts();
      }
    });
  }

  getChartHistory() {
    this.patientsDirectory.getToothChartHistory(this.id, this.patientId, this.clinic, this.filterOption).subscribe(response => {
      if(response) {
        this.success = true;
        this.toothChartData = response.data[0]['toothcharts'][0];
        this.chartData = JSON.parse(this.toothChartData['chart']);
        this.chartId = this.toothChartData['id'];
        this.createdAt = response.data[0]['toothcharts'][0].created_at;
        // this.dialogConfig.data = {
        //   title: 'Success!',
        //   message: 'Tooth chart has been changed successfully.',
        //   button: 'Okay',
        //   event: this.closeOnClick
        // };
        // this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    });
  }

  getLatestChart() {
    this.patientsDirectory.getLatestChart(this.patientId, this.clinic).subscribe(response => {
      if(response) {
        this.success = true;
        this.toothChartData = response;
        this.chartData = JSON.parse(this.toothChartData['chart']);
        this.chartId = this.toothChartData['id'];
        this.createdAt = response.created_at;
      }
    });
  }

  getPatientCharts() {
    this.patientsDirectory.getPatientCharts(this.patientId, this.clinic).subscribe(response => {
      const chart = response;
      this.newChart.emit(chart);
    });
  }

  toothStatus(status) {
    return mapToothStatus(status);
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  getToothNumber(tooth, start, end) {
    if(this.bridge.indexOf(tooth) === -1) {
      if(this.bridge.length == 2)
        return;

      this.bridge.push(tooth);
      this.bridge.sort();
    } else {
      const _index = this.bridge.indexOf(tooth);
      this.bridge.splice(_index, 1);
    }

    this.bridgeStart = this.bridge[0] ? this.bridge[0] : null;
    this.bridgeEnd = this.bridge.length > 1 ? this.bridge[1] : null;

    if(this.bridgeEnd) {
      setTimeout(() => {
        this.dialogConfig.data = {
          SUBJECT_1: this.bridgeStart,
          SUBJECT_2: this.bridgeEnd,
          DATE_CREATED: moment(this.createdAt).format("YYYY-MM-DD"),
          DATE_TODAY: moment(this.today).format("YYYY-MM-DD"),
        };
        this.dialogConfig.disableClose = true;
        this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
        let dialogRef = this.dialog.open(ConfirmBridgeComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
          if(trigger) {
            const bridge = {
              start: this.bridgeStart,
              end: this.bridgeEnd
            }
            const data = {
              patient_id: this.patientId,
              chart_id: this.chartId ? this.chartId : '',
              bridge: JSON.stringify(bridge),
              unbridge: '',
              user_clinic_id: this.clinic
            }
            this.patientsDirectory.bridgeTooth(data).subscribe(res => {
              if(res) {
                this.bridge = [];
                this.bridgeStart = '';
                this.bridgeEnd = '';
                this.success = true;
                this.toothChartData = res['data'].data[0]['toothcharts'][0];
                this.chartData = JSON.parse(this.toothChartData['chart']);
                this.chartId = this.toothChartData['id'];
                this.dialogConfig.data = {
                  title: 'Success!',
                  message: 'Bridge has been successfully saved.',
                  button: 'Okay',
                  event: this.closeOnClick
                };
                let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
              }
            },(err => {
              this.bridge = [];
              this.bridgeStart = '';
              this.bridgeEnd = '';
              this.dialogConfig.data = {
                title: 'Oops!',
                message: err.error.message,
                button: 'Okay',
                event: this.closeOnClick
              };
              this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
            }));
          } else {
            this.bridge = [];
            this.bridgeStart = '';
            this.bridgeEnd = '';
          }
        });
      }, 300);
    }

    if(tooth === start || tooth === end) {
      this.dialogConfig.data = {
        SUBJECT_1: start,
        SUBJECT_2: end,
        DATE_CREATED: moment(this.createdAt).format("YYYY-MM-DD"),
        DATE_TODAY: moment(this.today).format("YYYY-MM-DD"),
      };
      this.dialogConfig.disableClose = true;
      this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
      let dialogRef = this.dialog.open(UnbridgeComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
        if(trigger) {
          const unbridge = {
            start: start,
            end: end
          }
          const data = {
            patient_id: this.patientId,
            chart_id: this.chartId ? this.chartId : '',
            bridge: '',
            unbridge: JSON.stringify(unbridge),
            user_clinic_id: this.clinic
          }
          this.patientsDirectory.bridgeTooth(data).subscribe(res => {
            if(res) {
              this.bridge = [];
              this.bridgeStart = '';
              this.bridgeEnd = '';
              this.success = true;
              this.toothChartData = res['data'].data[0]['toothcharts'][0];
              this.chartData = JSON.parse(this.toothChartData['chart']);
              this.chartId = this.toothChartData['id'];
              this.dialogConfig.data = {
                title: 'Success!',
                message: 'Bridge has been successfully saved.',
                button: 'Okay',
                event: this.closeOnClick
              };
              let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
            }
          },(err => {
            this.bridge = [];
            this.bridgeStart = '';
            this.bridgeEnd = '';
            this.dialogConfig.data = {
              title: 'Oops!',
              message: err.error.message,
              button: 'Okay',
              event: this.closeOnClick
            };
            this.dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
          }));
        } else {
          this.bridge = [];
          this.bridgeStart = '';
          this.bridgeEnd = '';
        }
      });
    }
  }

  bridgedTooth(tooth) {
    console.log(tooth);
  }
}
