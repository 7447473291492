import { Component, OnInit, OnChanges, Pipe, PipeTransform } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { default as _rollupMoment, Moment } from 'moment';
import * as _moment from 'moment';
import { TimeRecordService } from '../../services/time-record/time-record.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';

@Pipe({name: 'timePipe'})
export class TimePipe implements PipeTransform {
  transform(value: any): any {
    if(value) {
      let hour = (value.split(':'))[0];
      let min = (value.split(':'))[1];
      let part = hour > 12 ? 'PM' : 'AM';
      min = (min+'').length == 1 ? `0${min}` : min;
      hour = hour > 12 ? hour - 12 : hour;
      hour = (hour+'').length == 1 ? `0${hour}` : hour;
      return `${hour}:${min} ${part}`
    }
  }
}

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
  },
};

@Component({
  selector: 'ark-time-record',
  templateUrl: './time-record.component.html',
  styleUrls: ['./time-record.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class TimeRecordComponent implements OnInit, OnChanges {

  months = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
  ];
  selectedYear = +moment().format("YYYY");
  selectedMonth = +moment().format("M");
  month = moment().format("MMMM");
  form: FormGroup;
  loading: boolean = false;
  isNoResult: boolean = true;
  clinic = null;
  id = null;
  records = null;

  constructor(
    private formBuilder: FormBuilder,
    private record: TimeRecordService,
    private auth: AuthService
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.id = this.auth.getAuthUser().id;
    this.form = this.formBuilder.group({
      year: new FormControl(moment()),
      month: [''],
    });
    this.form.get('month').setValue(this.month);
  }

  ngOnInit() {
    this.getTimeRecords();
  }

  ngOnChanges() {
    // this.getTimeRecords();
  }

  getTimeRecords() {
    this.record.getTimeRecords(this.clinic, this.id, this.selectedMonth, this.selectedYear).subscribe(res => {
      if(res.length > 0) {
        this.isNoResult = false;
        this.records = res;
      } else {
        this.isNoResult = true;
      }
    });
  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const val = this.form.value.year;
    val.year(normalizedYear.year());
    this.form.get('year').setValue(val);
    datepicker.close();

    this.selectedYear = +moment(this.form.value.year).format("YYYY");
    this.getTimeRecords();
  }

  getMonth(val) {
    this.form.get('month').setValue(val.name);
    this.selectedMonth = val.id;
    this.getTimeRecords();
  }

  getData(val) {
    this.getTimeRecords();
  }
}
