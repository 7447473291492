import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig, MatAutocomplete } from '@angular/material';
import { keypressValidateNumber } from 'src/app/common/helper';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard/dashboard.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { ConfirmDeletionComponent } from 'src/app/modules/shared/components/confirm-deletion/confirm-deletion.component';
import { FormControl } from '@angular/forms';

export interface Dentist {
  id: number;
  firstName: string;
  lastName: string;
}

export interface Item {
  id: number;
  name: string;
  brandname: string;
  description: string;
}

export interface Treatment {
  id: number;
  name: string;
}

@Component({
  selector: 'ark-view-patient-billing',
  templateUrl: './view-patient-billing.component.html',
  styleUrls: ['./view-patient-billing.component.scss']
})

export class ViewPatientBillingComponent implements OnInit {

  header = 'View Patient Bill';
  isEditable : boolean = false;
  isDoctorChange : boolean = false;
  isItemChange : boolean = false;
  status : boolean = false;
  dialogConfig = new MatDialogConfig();
  clinic: any;
  itemFee: number = 0;
  treatmentFee: number = 0;
  subTotalBilling: number = 0;
  totalBilling: number = 0;
  amount_due: number = 0;
  
  inventories: Item[] = [];
  dentists: Dentist[] = [];
  treatments: Treatment[] = [];
  @ViewChild('inventory') matAutocompleteInventory: MatAutocomplete;
  @ViewChild('dentist') matAutocompleteDentist: MatAutocomplete;

  items = [];
  procedures = [];
  searchTreatment = [];
  searchDentist = [];
  searchItem = [];
  response = null;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ViewPatientBillingComponent>,
    public dialog: MatDialog,
    private auth: AuthService,
    private patients: PatientsDirectoryService,
    private dashboard: DashboardService,
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.patients.getItemDirectoryList(this.clinic).subscribe(res => {
      res.forEach(element => {
        let obj: Item = {
          id: element.items.id, 
          name: element.items.name,
          brandname: element.items.brandname,
          description: element.items.description
        };
        this.inventories.push(obj);
      });
    });
    this.dashboard.getDentistList(this.clinic).subscribe(res => {
      res.forEach(element => {
        let obj: Dentist = {
          id: element.id, 
          firstName: element.first_name,
          lastName: element.last_name
        };
        this.dentists.push(obj);
      });
    });
    this.dashboard.getProcedures(this.clinic).subscribe(res => {
      res.forEach(element => {
        let obj: Treatment = {
          id: element.id, 
          name: element.title
        };
        this.treatments.push(obj);
      });
    });
  }

  ngOnInit() {
    this.initProcedure();
    this.getPatientBill();
    this.searchTreatment = this._treatments('');
    this.searchDentist = this._dentists('');
    this.searchItem = this._items('');
  }

  private _treatments(name: string): Treatment[] {
    const filterValue = name.toLowerCase();
    return this.treatments.filter(option => option.name.toLowerCase().indexOf(filterValue) > -1);
  }

  private _dentists(name: string): Dentist[] {
    const filterValue = name.toLowerCase();
    return this.dentists.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1);
  }

  private _items(name: string): Item[] {
    const filterValue = name.toLowerCase();
    return this.inventories.filter(option => option.name.toLowerCase().indexOf(filterValue) > -1);
  }

  filterTreatment(name: string) {
    this.searchTreatment = this._treatments(name);
  }

  filterDentist(name: string) {
    this.searchDentist = this._dentists(name);
  }

  filterItem(name: string) {
    this.searchItem = this._items(name);
  }

  displayDentist(dentist?: any) : string | undefined {
    return this.matAutocompleteDentist.options.filter(x => x.value === dentist).map(x => x.viewValue)[0] ? this.matAutocompleteDentist.options.filter(x => x.value === dentist).map(x => x.viewValue)[0] : dentist;
  }

  displayItem(item?: any) : string | undefined {
    return this.matAutocompleteInventory.options.filter(x => x.value === item).map(x => x.viewValue)[0] ? this.matAutocompleteInventory.options.filter(x => x.value === item).map(x => x.viewValue)[0] : item;
  }

  initItem() {
    return this.items[0] = [{
      id: '',
      detail_procedure_id: '',
      item_name: '',
      item: '',
      created_at: '',
      deleted_at: '',
      updated_at: '',
      item_id: '',
      item_qty: '',
      item_amount: '',
      item_total: ''
    }];
  }
  
  initProcedure() {
    this.procedures = [{
      tooth_no: '',
      procedure: '',
      procedure_id: '',
      dentist_id: '',
      doctors_fee: '',
      procedure_fee: '',
      total_fees: '',
      sub_total: '',
      items: this.initItem(),
    }];
  }

  addProcedure() {
    this.procedures.push({
      tooth_no: '',
      procedure: '',
      procedure_id: '',
      dentist_id: '',
      doctors_fee: '',
      procedure_fee: '',
      total_fees: '',
      sub_total: '',
      items: this.initItem(),
    });
  }

  addItem(i) {
    this.procedures[i].items.push({
      item_id: '',
      item_qty: '',
      item_amount: '',
      item_total: ''
    });
  }

  removeProcedure(index) {
    this.procedures.splice(index, 1);
  }

  removeItem(e) {
    this.dialogConfig.data = {
      subject: 'delete this item'
    };
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    let dialogRef = this.dialog.open(ConfirmDeletionComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
      if(trigger) {
        this.procedures[e[0]].items.splice([e[1]], 1);

        /**
         * 
         * Update subtotal and total amount
         */
        this.itemFee = 0;
        this.treatmentFee = 0;
        this.totalBilling = 0;
        
        const doctor = this.procedures[e[0]].doctors_fee;
        const procedure = this.procedures[e[0]].procedure_fee;
        this.treatmentFee = +doctor + +procedure;
        this.procedures[e[0]].total_fees = this.treatmentFee;

        if(e.length == 2) {
          this.procedures[e[0]].items.forEach(element => {
            this.itemFee += element.item_total;
          });
          this.procedures[e[0]].sub_total = +this.treatmentFee + +this.itemFee;
        } else {
          this.procedures[e[0]].sub_total = +this.treatmentFee;
        }
        
        this.procedures.forEach(element => {
          this.totalBilling += element.sub_total;
        });
        this.amount_due = +this.totalBilling
      }
    });
  }

  getTotalBill(e) {
    this.itemFee = 0;
    this.treatmentFee = 0;
    this.subTotalBilling = 0;
    this.totalBilling = 0;

    /** Get sum of doctor fee and treatment fee */
    const doctor = this.procedures[e[0]].doctors_fee;
    const procedure = this.procedures[e[0]].procedure_fee;
    this.treatmentFee = +doctor + +procedure;
    this.procedures[e[0]].total_fees = this.treatmentFee;

    /** Get product of item used */
    if(e.length == 2) {
      const quantity = this.procedures[e[0]].items[e[1]].item_qty;
      const amount = this.procedures[e[0]].items[e[1]].item_amount;
      const product = +quantity * +amount;
      this.procedures[e[0]].items[e[1]].item_total = product;
    }

    /** Set value for billing sub total */
    if(e.length == 2) {
      this.procedures[e[0]].items.forEach(element => {
        this.itemFee += element.item_total;
      });
      this.procedures[e[0]].sub_total = +this.treatmentFee + +this.itemFee;
    } else {
      this.procedures[e[0]].items.forEach(element => {
        this.itemFee += element.item_total;
      });
      this.procedures[e[0]].sub_total = +this.treatmentFee + +this.itemFee;
    }
    
    /** Set value for billing grand total */
    this.procedures.forEach(element => {
      this.totalBilling += element.sub_total;
    });
    this.amount_due = +this.totalBilling
  }

  close() {
    this.dialogRef.close(false);
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }

  getUnlistedTreatment(event, i) {
    this.procedures[i].procedure = event;
  }

  getDentist(e) {
    this.isDoctorChange = true;
  }

  getItem(e) {
    this.isItemChange = true;
  }

  getPatientBill() {
    let fees = 0;
    let items = 0;

    /** Set procedures */
    this.procedures[0].tooth_no = this.data.data.tooth_no;
    this.procedures[0].procedure = this.data.data.procedure;
    this.procedures[0].procedure_id = this.data.data.procedure_id;
    this.procedures[0].dentist_id = this.data.data.dentist_id;
    this.procedures[0].doctors_fee = this.data.data.doctors_fee;
    this.procedures[0].procedure_fee = this.data.data.procedure_fee;
    this.procedures[0].total_fees = this.data.data.total_fees;
    this.procedures[0].sub_total = this.data.data.sub_total;
    /** Set items */
    if(this.data.data.procedure_items.length !== 0) {
      this.procedures[0].items = this.data.data.procedure_items;
    } else {
      this.procedures[0].items = [];
    }
    /** Set sub total and grand total */
    if(this.data.data.procedure_items.length !== 0) {
      fees = +this.data.data.total_fees;
      this.data.data.procedure_items.forEach(element => {
        items += element.item_total
      });
      this.procedures[0].sub_total = +items + +fees;
      this.amount_due = +items + +fees;
    } else {
      this.procedures[0].sub_total = +this.data.data.total_fees
      this.amount_due = +this.data.data.total_fees
    }
    /** Set dentist name instead of id for viewing*/
    const name = 'Dr. ' + this.data.data.dentist.first_name + ' ' + this.data.data.dentist.last_name
    this.procedures[0].dentist_id = name;
    /** Set item name instead of id for viewing*/
    if(this.data.data.procedure_items.length !== 0) {
      this.procedures[0].items.forEach(el => {
        el.item_id = (el.item.name ? el.item.name : '') + (el.item.brandname ? ' - ' + el.item.brandname : '') + (el.item.description ? ' - ' + el.item.description : '');
      });
    }
  }

  edit() {
    this.status = !this.status;  
    if(this.isEditable) {
      this.isEditable = false;
      this.header = 'View Patient Bill';
    } else {
      this.isEditable = true;
      this.header = 'Update Patient Bill';
    }
  }

  delete() {
    this.dialogConfig.data = {
      subject: 'delete this bill'
    };
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    let dialogRef = this.dialog.open(ConfirmDeletionComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
      if(trigger) {
        this.patients.deletePatientBill(this.data.data.id, this.data.clinic).subscribe(res => {
          if(res) {
            this.response = res;
            this.dialogRef.close(this.response);
            this.dialogConfig.data = {
              title: 'Success!',
              message: 'Patient bill has been successfully deleted',
              button: 'Okay',
              event: this.close
            };
            let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
          }
        });
      }
    });
  }

  update() {
    /** Set dentist id again */
    if(typeof this.procedures[0].dentist_id === 'string') {
      this.procedures[0].dentist_id = this.data.data.dentist.id;
    }
    /** Set item id again */
    this.procedures[0].items.forEach(el => {
      if(typeof el.item_id === 'string') {
        el.item_id = el.item.id;
      } 
    });

    const data = {
      procedures: this.procedures,
    }
    this.patients.updatePatientBill(this.data.data.id, this.data.clinic, data).subscribe(res => {
      if(res) {
        this.response = res['data'];
        this.dialogRef.close(this.response);
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Patient bill has been successfully updated.',
          button: 'Okay',
          event: this.close
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Sorry!',
        message: err.error.message,
        button: 'Okay',
        event: this.close
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      /** Set dentist name instead of id */
      if(!this.isDoctorChange) {
        if(typeof this.procedures[0].dentist_id !== 'string') {
          const name = 'Dr. ' + this.data.data.dentist.first_name + ' ' + this.data.data.dentist.last_name
          this.procedures[0].dentist_id = name;
        }
      }
      // /** Set item name instead of if */
      if(this.data.data.procedure_items.length !== 0) {
        this.procedures[0].items.forEach(el => {
          if(el.item_id == el.item.id) {
            el.item_id = (el.item.name ? el.item.name : '') + (el.item.brandname ? ' - ' + el.item.brandname : '') + (el.item.description ? ' - ' + el.item.description : '');
          }
        });
      }
    }));
  }
}