import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatAutocompleteTrigger } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { DatePipe } from '@angular/common';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { Observable } from 'rxjs';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { startWith, map } from 'rxjs/operators';
import { keypressValidateNumber } from 'src/app/common/helper';

export interface Patient {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
}

export interface Dentist {
  id: number;
  firstName: string;
  lastName: string;
}

export interface Procedure {
  id: number;
  name: string;
}

@Component({
  selector: 'ark-create-appointment',
  templateUrl: './create-appointment.component.html',
  styleUrls: ['./create-appointment.component.scss']
})
export class CreateAppointmentComponent implements OnInit {

  today = new Date(); 
  errorMessage = null;
  user_clinic_id = '';
  todayAppointments: any;
  success: boolean = false;
  dialogConfig = new MatDialogConfig();
  formGroup: FormGroup;
  datePipe = new DatePipe('en-US');
  timeRange = [
    { name: '06:00 AM', value: '06:00' },
    { name: '06:30 AM', value: '06:30' },
    { name: '07:00 AM', value: '07:00' },
    { name: '07:30 AM', value: '07:30' },
    { name: '08:00 AM', value: '08:00' },
    { name: '08:30 AM', value: '08:30' },
    { name: '09:00 AM', value: '09:00' },
    { name: '09:30 AM', value: '09:30' },
    { name: '10:00 AM', value: '10:00' },
    { name: '10:30 AM', value: '10:30' },
    { name: '11:00 AM', value: '11:00' },
    { name: '11:30 AM', value: '11:30' },
    { name: '12:00 PM', value: '12:00' },
    { name: '12:30 PM', value: '12:30' },
    { name: '01:00 PM', value: '13:00' },
    { name: '01:30 PM', value: '13:30' },
    { name: '02:00 PM', value: '14:00' },
    { name: '02:30 PM', value: '14:30' },
    { name: '03:00 PM', value: '15:00' },
    { name: '03:30 PM', value: '15:30' },
    { name: '04:00 PM', value: '16:00' },
    { name: '04:30 PM', value: '16:30' },
    { name: '05:00 PM', value: '17:00' },
    { name: '05:30 PM', value: '17:30' },
    { name: '06:00 PM', value: '18:00' },
    { name: '06:30 PM', value: '18:30' },
    { name: '07:00 PM', value: '19:00' },
    { name: '07:30 PM', value: '19:30' },
    { name: '08:00 PM', value: '20:00' },
    { name: '08:30 PM', value: '20:30' },
    { name: '09:00 PM', value: '21:00' },
    { name: '09:30 PM', value: '21:30' },
    { name: '10:00 PM', value: '22:00' },
    { name: '10:30 PM', value: '22:30' },
    { name: '11:00 PM', value: '23:00' },
    { name: '11:30 PM', value: '23:30' },
    { name: '12:00 AM', value: '24:00' },
  ];
  dentistId: any;
  patientId: any;
  firstName = null;
  lastName = null;
  middle_name = null;
  selectedProcedure = null;
  unlistedProcedure = null;
  previousDate = new Date();
  patientControl = new FormControl();
  dentistControl = new FormControl();
  procedureControl = new FormControl();
  patients: Patient[] = [];
  dentists: Dentist[] = [];
  procedures: Procedure[] = [];
  filteredPatients: Observable<Patient[]>;
  filteredDentists: Observable<Dentist[]>;
  filteredProcedures: Observable<Procedure[]>;
  @ViewChild('patient') patient: MatAutocompleteTrigger;
  @ViewChild('dentist') dentist: MatAutocompleteTrigger;
  @ViewChild('procedure') procedure: MatAutocompleteTrigger;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateAppointmentComponent>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private auth: AuthService,
    private patientsDirectory: PatientsDirectoryService
  ) {
    this.user_clinic_id = this.auth.getAuthUser().user_clinic.id;
    this.dashboardService.getPatientList(this.user_clinic_id, this.user_clinic_id).subscribe(res => {
      res.data.forEach(element => {
        let obj: Patient = {
          id: element.id, 
          firstName: element.first_name, 
          middleName: element.middle_name ? element.middle_name : '', 
          lastName: element.last_name
        };
        this.patients.push(obj);
      });
    });
    this.dashboardService.getDentistList(this.user_clinic_id).subscribe(res => {
      res.forEach(element => {
        let obj: Dentist = {
          id: element.id, 
          firstName: element.first_name,
          lastName: element.last_name
        };
        this.dentists.push(obj);
      });
    });
    this.dashboardService.getProcedures(this.user_clinic_id).subscribe(res => {
      res.forEach(element => {
        let obj: Procedure = {
          id: element.id, 
          name: element.title
        };
        this.procedures.push(obj);
      });
    });
   }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      patient_id: new FormControl('', [Validators.required]),
      dentist_id: new FormControl('', [Validators.required]),
      procedure: new FormControl('', [Validators.required]),
      mobile: ['', [Validators.required]],
      date: ['', [Validators.required]],
      start_time: ['', [Validators.required]],
      end_time: ['', [Validators.required]]
    });
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';

    this.filteredPatients = this.formGroup.get('patient_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterPatient(name) : this.patients.slice()),
      );
    this.filteredDentists = this.formGroup.get('dentist_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterDentist(name) : this.dentists.slice())
      );
    this.filteredProcedures = this.formGroup.get('procedure').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterProcedure(name) : this.procedures.slice())
      );
  }

  private _filterPatient(name: string): Patient[] {
    const filterValue = name.toLowerCase();
    return this.patients.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1 || option.middleName.toLowerCase().indexOf(filterValue) > -1);
  }

  private _filterDentist(name: string): Dentist[] {
    const filterValue = name.toLowerCase();
    return this.dentists.filter(option => option.lastName.toLowerCase().indexOf(filterValue) > -1 || option.firstName.toLowerCase().indexOf(filterValue) > -1);
  }

  private _filterProcedure(name: string): Procedure[] {
    const filterValue = name.toLowerCase();
    return this.procedures.filter(option => option.name.toLowerCase().indexOf(filterValue) > -1);
  }

  displayDentist(dentist?: any) : string | undefined {
    return dentist ? 'Dr. ' + dentist.firstName + ' ' + dentist.lastName : undefined;
  }

  displayPatient(patient?: any) : string | undefined {
    return patient ? patient.lastName + ', ' + patient.firstName + ' ' + patient.middleName : undefined;
  }

  displayProcedure(procedure?: any) : string | undefined {
    return procedure ? procedure.name : undefined;
  }

  onFocusPatient() {
    this.patient._onChange("");
    this.patient.openPanel();
  }

  onFocusDentist() {
    this.dentist._onChange(""); 
    this.dentist.openPanel();
  }

  onFocusProcedure() {
    this.procedure._onChange(""); 
    this.procedure.openPanel();
  }

  getPatient(event) {
    this.patientId = event.option.value.id;
    this.patientsDirectory.getPatientInfo(this.user_clinic_id, this.patientId, this.user_clinic_id).subscribe(res => {
      this.formGroup.get('mobile').setValue(res['data'][0].mobile_number);
    });
    this.formGroup.get('patient_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
  }

  getDentist(event) {
    this.dentistId = event.option.value.id;
    this.formGroup.get('dentist_id').setValue({
      id: event.option.value.id, 
      firstName: event.option.value.firstName, 
      lastName: event.option.value.lastName,
      middleName: event.option.value.middleName ? event.option.value.middleName : ''
    });
  }

  getProcedure(event) {
    this.selectedProcedure = event.option.value.name;
    this.formGroup.get('procedure').setValue({
      id: event.option.value.id, 
      name: event.option.value.name
    });
  }

  getNewProcedure(e) {
    this.unlistedProcedure = e;
  }

  createAppointment(){  
    if(this.patientId === undefined || this.patientId === 'undefined'){
      if(this.lastName !== null){
        this.patientId = 0;
      }
    }
    let appointment_date = this.datePipe.transform(this.formGroup.value.date, 'yyyy-MM-dd');
    let data = {
      user_clinic_id: this.user_clinic_id,
      patient_id: this.formGroup.get('patient_id').value.id ? this.formGroup.get('patient_id').value.id : this.patientId,
      dentist_id: this.formGroup.get('dentist_id').value.id ? this.formGroup.get('dentist_id').value.id : this.formGroup.get('dentist_id').value,
      procedure: this.selectedProcedure ? this.selectedProcedure : this.unlistedProcedure,
      mobile: this.formGroup.value.mobile.toString(),
      date: appointment_date,
      start_time: this.formGroup.value.start_time,
      end_time: this.formGroup.value.end_time,
      first_name : this.firstName == undefined ? null : this.firstName,
      last_name : this.lastName == undefined ? null : this.lastName
    }
    this.dashboardService.createAppointment(data).subscribe(res => {
      if(res) {
        this.success = true;
        this.dialogRef.close(res);
        this.dialogConfig.data = {
          title: 'Success!',
          message: 'Appointment has been successfully created.',
          button: 'Okay',
          event: this.closeOnClick
        };
        let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      }
    },(err => {
      this.dialogConfig.data = {
        title: 'Sorry!',
        message: err.error.message,
        button: 'Okay',
        event: this.closeOnClick
      };
      let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
      this.errorMessage = err.error.errors;
      this.triggerErrors(err.error.errors);
    }));
  }

  triggerErrors(err){
    Object.keys(this.formGroup.controls).forEach(key => {
      if (err[key]) {
        this.formGroup.controls[key].setErrors({'invalid': true});
        this.formGroup.controls[key].markAsTouched();
        this.formGroup.controls[key].markAsDirty();
      }
    });
  }

  closeOnClick() {
    this.dialogRef.close();
  }
  
  getText(e) {
    var name = e.split(',');
    this.lastName = name[0];
    this.firstName = name[1];
  }

  numberTypeValidation(e) {
    return keypressValidateNumber(e);
  }
}
