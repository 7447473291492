import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ark-undo-button',
  templateUrl: './undo-button.component.html',
  styleUrls: ['./undo-button.component.scss']
})
export class UndoButtonComponent implements OnInit {

  @Input() label: string;
  @Input() type: string;
  @Output() onClick = new EventEmitter<any>();

  constructor() { }

  onClickButton(event) {
    this.onClick.emit(event);
  }

  ngOnInit() {
  }

}
