import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/modules/shared/services/http/http.service';
import { dailyReport } from 'src/app/common/api';

@Injectable({
  providedIn: 'root'
})
export class DailyReportService extends HttpService{

  constructor(http: HttpClient, private _router: Router) { 
    super('', http);
  }

  sendDailyReportEmail(id, day) {
    return this.get(dailyReport + '/send' + '?user_clinic_id=' + id + '&day=' + day).map(res => {
      return res['data'];
    });
  }

  downloadDailyReportPDF(id, day) {
    return this.http.get(dailyReport + '/download' + '?user_clinic_id=' + id + '&day=' + day, {observe: 'response', responseType: 'blob'}).map(res => {
      return new Blob([res.body], {type: res.headers.get('Content-Type')});
    });
  }

  getDailyReportSettings(id) {
    return this.get(dailyReport + '?user_clinic_id=' + id).map(res => {
      return res['data'];
    });
  }

  saveDailyReportSettings(data) {
    return this.http.post(dailyReport, data).map((dataResponse) => {
      return dataResponse;
    });
  }

  updateDailyReportSettings(id, data) {
    return this.http.put(dailyReport + '/' + id, data).map((dataResponse) => {
      return dataResponse;
    });
  }
}
