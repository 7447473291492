import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { PatientService } from '../../../service/patient/patient.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { ExpensesService } from '../../../service/expenses/expenses.service';

@Component({
  selector: 'ark-annual-expenses',
  templateUrl: './annual-expenses.component.html',
  styleUrls: ['./annual-expenses.component.scss']
})
export class AnnualExpensesComponent implements OnInit, OnChanges {

  @Input() month: any = null; 
  @Input() year: any = null; 
  @Input() search: any = null; 
  doctorFees = [];
  fees = [];
  clinic = null;
  selectedYear = '';
  selectedMonth = '';
  isNoResult: boolean = true;

  constructor(
    private auth: AuthService,
    private expenses: ExpensesService
  ) { 
    this.clinic = this.auth.getAuthUser().user_clinic.id;
  }

  ngOnInit() {
    
  }

  ngOnChanges() {
    if(this.month || this.year) {
      this.selectedMonth =  this.month;
      this.selectedYear =  this.year;
      this.getList();
    }
  }

  getList() {
    this.expenses.getAnnualExpenses(this.clinic, this.year).subscribe(res => {
      if(res) {
        let data = [
          {
            month: 'January',
            operation_cost: res['1'].total_amount_due,
            direct_cost: res['1'].direct_cost,
            doctor_fee: res['1'].doctor_fee,
            total_cost: res['1'].total_cost
          },{
            month: 'February',
            operation_cost: res['2'].total_amount_due,
            direct_cost: res['2'].direct_cost,
            doctor_fee: res['2'].doctor_fee,
            total_cost: res['2'].total_cost
          },{
            month: 'March',
            operation_cost: res['3'].total_amount_due,
            direct_cost: res['3'].direct_cost,
            doctor_fee: res['3'].doctor_fee,
            total_cost: res['3'].total_cost
          },{
            month: 'April',
            operation_cost: res['4'].total_amount_due,
            direct_cost: res['4'].direct_cost,
            doctor_fee: res['4'].doctor_fee,
            total_cost: res['4'].total_cost
          },{
            month: 'May',
            operation_cost: res['5'].total_amount_due,
            direct_cost: res['5'].direct_cost,
            doctor_fee: res['5'].doctor_fee,
            total_cost: res['5'].total_cost
          },{
            month: 'June',
            operation_cost: res['6'].total_amount_due,
            direct_cost: res['6'].direct_cost,
            doctor_fee: res['6'].doctor_fee,
            total_cost: res['6'].total_cost
          },{
            month: 'July',
            operation_cost: res['7'].total_amount_due,
            direct_cost: res['7'].direct_cost,
            doctor_fee: res['7'].doctor_fee,
            total_cost: res['7'].total_cost
          },{
            month: 'August',
            operation_cost: res['8'].total_amount_due,
            direct_cost: res['8'].direct_cost,
            doctor_fee: res['8'].doctor_fee,
            total_cost: res['8'].total_cost
          },{
            month: 'September',
            operation_cost: res['9'].total_amount_due,
            direct_cost: res['9'].direct_cost,
            doctor_fee: res['9'].doctor_fee,
            total_cost: res['9'].total_cost
          },{
            month: 'October',
            operation_cost: res['10'].total_amount_due,
            direct_cost: res['10'].direct_cost,
            doctor_fee: res['10'].doctor_fee,
            total_cost: res['10'].total_cost
          },{
            month: 'November',
            operation_cost: res['11'].total_amount_due,
            direct_cost: res['11'].direct_cost,
            doctor_fee: res['11'].doctor_fee,
            total_cost: res['11'].total_cost
          },{
            month: 'December',
            operation_cost: res['12'].total_amount_due,
            direct_cost: res['12'].direct_cost,
            doctor_fee: res['12'].doctor_fee,
            total_cost: res['12'].total_cost
          }
        ]
        this.doctorFees.push(data);
        this.fees = this.doctorFees[0];
        if(this.fees.length > 0) {
          this.isNoResult = false;
        } else {
          this.isNoResult = true;
        }
      }
    });
  }
  enter(e){
    console.log("ni enter na cya");
  }

  leave(e){
    console.log("ni hawa na cya");
  }
}
