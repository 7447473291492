import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material';
import { PatientsDirectoryService } from 'src/app/modules/patients/services/patients-directory/patients-directory.service';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';
import { AlertMessageComponent } from 'src/app/modules/shared/components/alert-message/alert-message.component';
import { ConfirmDeletionComponent } from 'src/app/modules/shared/components/confirm-deletion/confirm-deletion.component';

@Component({
  selector: 'ark-recall-visit-lists',
  templateUrl: './recall-visit-lists.component.html',
  styleUrls: ['./recall-visit-lists.component.scss']
})
export class RecallVisitListsComponent implements OnInit {

  dialogConfig = new MatDialogConfig();
  recallVisit = '';
  visitData = [];
  clinic = '';
  isNoResult: boolean = false;
  visits: any;
  pastDate: boolean = false;
  futureDate: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RecallVisitListsComponent>,
    public dialog: MatDialog,
    private patientsDirectory: PatientsDirectoryService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.clinic = this.auth.getAuthUser().user_clinic.id;
    this.recallVisit = this.data.value;
    this.visitData = this.data.response;
    if(this.visitData.length === 0) {
      this.isNoResult = true;
    }
  }

  closeOnClick() {
    this.dialogRef.close();
  }

  removeVisitInfo(id) {
    this.dialogConfig.data = {
      subject: 'delete this visit detail'
    };
    this.dialogConfig.disableClose = true;
    this.dialogConfig.backdropClass = 'mat-dialog-backdrop';
    let dialogRef = this.dialog.open(ConfirmDeletionComponent, this.dialogConfig).afterClosed().subscribe(trigger => {
      if(trigger) {
        this.patientsDirectory.deleteRecallVisit(id, this.clinic, this.recallVisit).subscribe(res => {
          if(res) {
            this.visitData = res
            if(this.visitData.length === 0) {
              this.isNoResult = true;
            }
            this.dialogConfig.data = {
              title: 'Success!',
              message: 'Visit detail has been successfully removed.',
              button: 'Okay',
              event: this.closeOnClick
            };
            let dialogRef = this.dialog.open(AlertMessageComponent, this.dialogConfig);
          }
        });
      }
    });
  }
}
