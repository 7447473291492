import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/login/services/auth/auth.service';

@Component({
  selector: 'ark-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnChanges {

  @Input() sidebarMinimized;
  panelOpenState: boolean = false;
  panelHeight: string = '48px';
  profile : any;
  isAssistant : boolean = false;
  isMinimized : boolean = false;
  frontDeskPanel : boolean = false;
  dentalOfficePanel : boolean = false;

  constructor(
    private _router: Router,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.getUserDetail();
  }

  ngOnChanges() {
    this.isMinimized = this.sidebarMinimized;
  }

  getUserDetail(){
    this.profile = this.auth.getAuthUser();

    if(this.profile.role_id === 'Owner/Dentist' || this.profile.role_id === 'Dentist') {
      this.isAssistant = false;
    } else {
      this.isAssistant = true;
    }
  }

  togglePanel(panel) {
    if(panel == 'front-desk') {
      this.frontDeskPanel = !this.frontDeskPanel;
    } else if(panel == 'dental-forms') {
      this.dentalOfficePanel = !this.dentalOfficePanel;
    }
  }
}
